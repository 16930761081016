import { Component, OnInit, ViewChild } from '@angular/core';
import { Router , ActivatedRoute } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ImpactAssessmentService } from '../../../impact-assessment.service';
import {ReportConstantsComponent} from '../../../../report-constants/report-constants.component'
@Component({
  selector: 'app-capital-view',
  templateUrl: './capital-view.component.html',
  styleUrls: ['./capital-view.component.css']
})
export class CapitalViewComponent implements OnInit {

    capitalList:any;
    rowCount:number;
    row = [];
    length:number;
    start:number;
    end:number;
    loading:boolean;
    public locale:string;

    constructor(private _router:Router, private impactAssessmentService:ImpactAssessmentService) { 
      this.rowCount = 0;
      this.length = 0;
      this.loading = false;
    }
  
    ngOnInit() {
      this.loading = true;
      this.locale =ReportConstantsComponent.LOCALE;
      this.impactAssessmentService.getCapitalViewDetails().subscribe(data => {
        
        this.capitalList = data;
        this.length=data.length;
        this.rowCount= Math.ceil(this.length / 3)
        for(let i = 1 ; i <= this.rowCount; i++){
          this.row.push(i);
        }
        this.loading = false;
      });
    }
  
    getCapitalViewDrillDownDetails(capitalId:number, subCategoryCode){
      let capitalVO = {};
      capitalVO['capitalId'] = capitalId;
      capitalVO['subCategoryCode'] = subCategoryCode;
      capitalVO['subCategoryId'] = 2;   // takes impact indicators
      this._router.navigate(['/impactAssessment/dashboard/capitalViewDrillDown'], { queryParams: capitalVO , skipLocationChange: true});
    }

    isNumber(val) { return isNaN(+val); }
  }

 