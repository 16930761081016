import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ConfigurationService } from '../../configuration.service';
import { FormBuilder } from '@angular/forms';
import { AddAlternateDatasourceComponent } from '../add-alternate-datasource/add-alternate-datasource.component';
import { AddEditSuccessComponent } from '../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import {MatPaginator} from '@angular/material';
import {MatSort, MatTableDataSource} from '@angular/material';
import { Router } from '@angular/router';
@Component({
  selector: 'app-view-alternate-datasource',
  templateUrl: './view-alternate-datasource.component.html',
  styleUrls: ['./view-alternate-datasource.component.css']
})
export class ViewAlternateDatasourceComponent implements OnInit {

  constantValues: any;
  displayedColumns: any;
  originalColumnNames : any;
  colIndex: number = 0;
  jsonArray: any=[];
  constantsList: any;
  constantTypeId: number;
  top:any;
  paginator:any;
  dataSource: any ;
  @ViewChild('matSort') sort: MatSort;
  displayedColumnsFordataAttributes: any[];
  originalColumnNamesFordataAttributes: any[];
  jsonArrayFordataAttributes: any[];
  displayedColumnsFordataDetails: any[];
  originalColumnNamesFordataDetails: any[];
  jsonArrayFordataDetails: any[];
  alternateDataSourceDataList=[];
  uploadedFileName: any;
  @ViewChild('MatPaginator') set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
}
  @Input() set topWrapper(val) {
    this.top=val;
  }
  @Input() set alternateDataSourceList(val:any) {
    this.alternateDataSourceDataList=val;
    this.dataTablePopulation()
  }
  @Output() alternateDataSourceComponent = new EventEmitter();

ngOnInit(): void {
  
}
  constructor(private router:Router,private configurationService: ConfigurationService, private formBuilder: FormBuilder,private addAlternateDatasourceComponent :  AddAlternateDatasourceComponent, private addEditSuccessComponent: AddEditSuccessComponent) { }

  delete(dataObj) {
    var confirmMessage = "You Want To Delete Alternate Data Source";
  this.addEditSuccessComponent.showConfirmationSwal(confirmMessage,'Yes','No').then((isConfirm) => {
    if (isConfirm.value) {
      
      this.configurationService.deleteAlternateDataSource(dataObj.id).subscribe((data) => {
        var deleteSuccess = 'Deleted Alternate Data Source';
          this.addEditSuccessComponent.showSuccessSwal(deleteSuccess)
            .then((value) => {
          this.addAlternateDatasourceComponent.fetchAlternateDataSourceData();
          this.addAlternateDatasourceComponent.resetAlternateDataForm();
        });
      },
        (error) => { 
          console.log(error);
         });


    }


  });
  }

  edit(dataObj: any) {
    console.log("editing")
    console.log(dataObj)
    this.alternateDataSourceComponent.emit({
      id: dataObj.id,
      name:dataObj.sourceName,
      data: dataObj.methodId,
      format: dataObj.formatId      ,
      links: dataObj.links      ,
      location: dataObj.location      ,
      key:dataObj.key,
      identifierIdList:dataObj.identifierIdList,
      sectorIds:dataObj.sectorIds,
      subsectorIds:dataObj.subsectorIds,
      regionIds:dataObj.regionIds,
      countryIds:dataObj.countryIds,
      fileName:dataObj.fileName,
      fileUploadName:dataObj.fileUploadName
    });
  }

 
  dataTablePopulation() {
    console.log("view alternate details")
    console.log(this.alternateDataSourceDataList)
    this.displayedColumns = [];
    this.originalColumnNames = [];
    this.jsonArray = [];
    var count = 0;
   
   for (let alternateDataSource of this.alternateDataSourceDataList) {
    var newObj = {};
    newObj['id']=alternateDataSource.id;
    newObj['Sno'] = ++count;
    newObj['method']=alternateDataSource.constantsVOData.value
    newObj["methodId"]=alternateDataSource.constantsVOData.id
    newObj['sourceName'] =alternateDataSource.name;
    newObj['format'] = alternateDataSource.constantsVOFormat.value;
    newObj['formatId'] = alternateDataSource.constantsVOFormat.id;
    newObj['links'] = alternateDataSource.links;
    newObj['key']=alternateDataSource.key;
    newObj['dataAttributes'] = '';
    newObj["sectorIds"]=alternateDataSource.sectorIds
    newObj["subsectorIds"]=alternateDataSource.subsectorIds
    newObj["regionIds"]=alternateDataSource.regionIds
    newObj["countryIds"]=alternateDataSource.countryIds
    newObj["fileName"]=alternateDataSource.fileName
    newObj["fileUploadName"]=alternateDataSource.fileUploadName
    let identifierIdList=[]
    let identifierNameList=[]
    for(let identifier of alternateDataSource.alternateDatasourceIdentifierVOList)
    {
      identifierIdList.push(identifier.constantsVO.id)
      identifierNameList.push(identifier.constantsVO.value)
    }
    newObj['identifiers'] =identifierNameList
    newObj["identifierIdList"]=identifierIdList
    newObj['location']=alternateDataSource.location;
    newObj['statusFlag']=alternateDataSource.statusFlag
    this.jsonArray.push(newObj);
 }
    this.displayedColumns = ['Sno','sourceName','method', 'format','links', 'location','dataAttributes','details','identifiers','statusFlag','Actions']
    this.originalColumnNames = ['Sl.No','Source Name','Method of Data Ingesion', 'Format','API Links','Location / Filename','Data Attributes','Details','Identifiers', 'Status Flag','Actions']
    this.dataSource=[];
    this.dataSource = new MatTableDataSource(this.jsonArray);
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }  
  setDataAttributesDetails(element)
  {    
    this.uploadedFileName=element.fileUploadName;
    this.uploadedFileName=element.fileUploadName;
    var alternateId = element.id;
    this.displayedColumnsFordataAttributes = [];
    this.originalColumnNamesFordataAttributes = [];
    this.jsonArrayFordataAttributes=[]
    this.configurationService.listAlternateDatasourceAttribute(alternateId).subscribe((dataAttributesArray) => {
      var count=0;
      for (let i of dataAttributesArray) {
        var newArray = {};
        newArray['Sno'] = ++count;
        newArray['dataAttributeName'] =i.name;
        newArray['dataAttributeType'] = i.type;
        newArray['attributeDetails'] = i.details;
  
       this.jsonArrayFordataAttributes.push(newArray)
      }
      this.originalColumnNamesFordataAttributes= ['Sl.No',"Data Attribute Name","Data Attribute Type","Data Attribute Details"];
      this.displayedColumnsFordataAttributes= ['Sno',"dataAttributeName","dataAttributeType","attributeDetails"];
    
    },
      (error) => { 
        console.log(error);
       }); 
  }
  
gotoDetails(){
  this.router.navigate(['/alternateDetails']);
}

  download(){

    let fileName = this.uploadedFileName;
     window.location.href = this.configurationService.downloadFile() + '/primaryDataAttributedownload?fileUploadName=' + encodeURIComponent(fileName)


  }
}


