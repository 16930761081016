import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseUrlService } from '../../shared/base-url.service';
@Injectable()
export class PerformanceTemplateService {

 
  private parameterConfigbaseUrl = BaseUrlService.PARAMATERCONFIGURATIONBASEURL;
  private baseUrl = BaseUrlService.INDICATORBASEURL;

  constructor(private http: HttpClient) { }
  
  getListOfTemplates(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getPerformanceTemplates`);
  }

  createNewTemplateCheck(): Observable<any> {
    return this.http.get(`${this.baseUrl}/createNewTemplateCheck`);
  }
    
  getTemplateListForSearch(CompanyType :any,status:any): Observable<any> {
    
    if(!status){
      return this.http.get(`${this.baseUrl}/searchPerformanceTemplate?companyTypeId=`+CompanyType);
    }
    else{
    return this.http.get(`${this.baseUrl}/searchPerformanceTemplate?companyTypeId=`+CompanyType+`&status=`+status);
      } 
  }

  getTemplateIndicatorDetails(templateDetailsId):Observable<any>{
    return this.http.get(`${this.baseUrl}/getPerformanceTemplateDetailById/`+templateDetailsId);
  }

  getPerformanceTemplateDetailById(templateDetailsId):Observable<any>{
    return this.http.get(`${this.baseUrl}/getPerformanceTemplateDetailById/`+templateDetailsId);
  }

  
  archiveSelectedTemplates(selectedTemplatesArray:any):Observable<any>{
    let body = selectedTemplatesArray;
    let url = this.baseUrl + '/archivePerformanceTemplates';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);
  }
  
    getCompanyTypes(): Observable<any> {
    return this.http.get(`${this.parameterConfigbaseUrl}/companyTypeList`);
  }


  

  getLoadDataForPerformanceTemplateCreation(): Observable<any> {
    return this.http.get(`${this.baseUrl}/loadDataForPerformanceTemplateCreation`);
  }

  createTemplate(templateForm:any){

    
    let body = JSON.stringify(templateForm); 

    let url = this.baseUrl+'/savePerformanceTemplateDetails';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
       
      })
    };

    return this.http.post(url, body, httpOptions);
  }

submitTemplateIndicators(templateDetailsVO:any){
    
    let body = JSON.stringify(templateDetailsVO); 
    let url = this.baseUrl+'/editTemplateIndicator';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
       
      })
    };

    return this.http.post(url, body, httpOptions); 
  }
  
  getTemplatesByCompanyType(CompanyTypeId): Observable<any> {
    
    return this.http.get(`${this.baseUrl}/getTemplateByCompanyType/`+CompanyTypeId, { responseType: 'text' });
  }


  saveTemplateIndicatorConfigDetails(templateVO,status){
    let url;
    let body = JSON.stringify(templateVO);
    if (status == 'D') {
        url = this.baseUrl+'/savePerformanceTemplateIndicatorConfiguration';
    }
    else{
        url = this.baseUrl+'/submitPerformanceTemplateIndicatorConfiguration';
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };
    return this.http.post(url, body, httpOptions);
  }
  
  deleteIndicator(templateId,indicatorId): Observable<any> {
    
    return this.http.get(`${this.baseUrl}/deleteindicatorfromtemplate/`+templateId+`/`+indicatorId);
  }
  getWebserviceForIndicator(templateIndicatorDetailsId): Observable<any> {
    return this.http.get(`${this.baseUrl}/webserviceForTemplateIndicator/` + templateIndicatorDetailsId);
  }
  submitWebservice(indicatorVO: any) {

    let url = this.baseUrl + '/webserviceForTemplateIndicator';
    let body = JSON.stringify(indicatorVO);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };
    return this.http.post(url, body, httpOptions);
  }
  
   getSchedulesForIndicator(templateIndicatorDetailsId,indicatorTypeId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getSchedulesForIndicator/` + templateIndicatorDetailsId+'/'+indicatorTypeId);
  }
   saveIndicatorDetailsScheduleforTemplate(indicatorVO:any){
    //  console.log('INDICATOR VO'+JSON.stringify(indicatorVO))
      
      let body = JSON.stringify(indicatorVO); 
      let url = this.baseUrl+'/saveIndicatorDetailsScheduleforTemplate';
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
         
        })
      };
  
      return this.http.post(url, body, httpOptions); 
    }
    
    saveBaselineForIndicator(indicatorVO){
      let body = JSON.stringify(indicatorVO); 
      let url = this.baseUrl+'/saveBaseLineFormula';
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
         
        })
      };
  
      return this.http.post(url, body, httpOptions); 
    }

}