import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, ValidationErrors, FormGroupDirective ,ValidatorFn,AbstractControl} from '@angular/forms';
import { AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs';
import { Type } from './type';
import { CustomValidationsService } from '../../../../../shared/Validations/custom-validations.service';
import { AddEditSuccessComponent } from '../../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { IndicatorService } from '../../../../indicator/indicator.service';

@Component({
  selector: 'app-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.css']
})
export class AddQuestionComponent implements OnInit {
  notValidRangeMessage: string = "";
  rangeRequiredMessage: string = "";
  maxScoreRangeMessage:string ="";
  // endRange: number = 0;
  // startRange: number = 0;
  operatorsList: any;
    topWrapper:any;
  @ViewChild ("topWrap") topWrap;
  @ViewChild('invisibleText') invTextER: ElementRef;
  
  responseOption: any;
  qualitativeResponseOptions: any;
  quantitative: boolean = false;
  qualitative: boolean = false;
  questionDataVO: any;
  isSubmit: boolean = false;
  questionsList: any=[];
  questionTypeList: any;
  subFactorVOList: any;
  factorVOList: any;
  questionList: any;
  addQuestionForm: FormGroup;
  width: number = 64;
  values: Array<string> = [];
  options = [];
  operators$ = new BehaviorSubject([]);
  questionTxtList=[];
  selq:any;
  submitvalid: boolean = false;
  nullFlag: boolean = false;
  indicatorList:any=[];
  constructor(private addEditSuccessComponent: AddEditSuccessComponent,private formBuilder: FormBuilder, 
    private assessmentService: AssessmentTemplateMasterdataService,
    private customValidationsService:CustomValidationsService,
    private indicatorService:IndicatorService,) {

    this.addQuestionForm = this.createFormGroup();
  }

  ngOnInit() {
    this.loadMasterDataForForm();
     this.fetchIndicatorList()
   // this.setValidatorsAsPerQuestionType();
    this.topWrapper=this.topWrap;

  }

  fetchIndicatorList()
  {
     this.indicatorService.getListOfAllIndicators().subscribe(data => {
        this.indicatorList = data;
       
     }, (error) => {
        console.log(error);
     });
  }
  loadMasterDataForForm() {
    this.assessmentService.loadDataForQuestion().subscribe(data => {
     
      this.questionDataVO = data;
      this.questionsList = this.questionDataVO.questionVOList;
      this.factorVOList = this.questionDataVO.factorVOList;
      this.subFactorVOList = this.questionDataVO.subFactorVOList;
      this.questionTypeList = this.questionDataVO.questionTypeList;
      this.qualitativeResponseOptions=this.questionDataVO.responseOptionVOList;
      this.operatorsList = this.questionDataVO.operatorList;
      this.setQuestionTextList(0); 

    })

  }

  createFormGroup() {
    return this.formBuilder.group({
      id: [],
      subFactorVO: this.formBuilder.group({
        id: ['', [Validators.required]],
      }),
        factorVO: this.formBuilder.group({
          id: ['',[Validators.required]],
        }),

      
      questionTypeId: ['', Validators.compose([Validators.required])],
      questionText: ['', [Validators.required,Validators.maxLength(250),this.uniqueName(),this.customValidationsService.noWhitespaceValidator]],
      responseName: ['', [Validators.maxLength(100)]],
      quantitativeOperator: [0],
      quantitativeStartValue: [''],
      quantitativeEndValue: [''],
      quantitativeScore:[''],
      indicator:[''],
      responseOptionsVOList: this.formBuilder.array([])

    });

  }

  get formArray() {
    return this.addQuestionForm.get('responseOptionsVOList') as FormArray;
  }


  initOptionRows() {

    return this.formBuilder.group({
      id: [],
      option: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      score: ['', Validators.compose([Validators.required,Validators.max(100),Validators.min(-100), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
      operator: [Validators.compose([Validators.required])]
    });
  }
  checkQuestionType(questionTypeId)
  {
    if (questionTypeId == this.questionDataVO.qualitativeRatingBasedTypeId) {
      this.values = [];
      this.qualitative = true;
      this.quantitative = false;
      this.assessmentService.getQualitativeResponseOptions().subscribe(data => {
        this.qualitativeResponseOptions = data;
      })

    }
    else if (questionTypeId == this.questionDataVO.quantitativeTypeId) {

      this.qualitative = false;
      this.quantitative = true;
    }
    else {

      this.qualitative = false;
      this.quantitative = false;
    }
  }

  defaultValues(questionObj) {
	this.setQuestionTextList(questionObj.id);
    this.addQuestionForm.patchValue(questionObj);
    this.checkQuestionType(questionObj.questionTypeId);
    this.options = [];
    let index = 0;
    var operator;
    var formArrayLastIndex = this.formArray.length - 1;
    while (formArrayLastIndex >= 0) {
      this.formArray.removeAt(formArrayLastIndex);
      formArrayLastIndex--;
    }

    for (let i of questionObj.responseOptionsVOList) {

      var formArrayLastIndex = this.formArray.length - 1;
      this.formArray.push(this.initOptionRows());
      var formArrayLastIndex = this.formArray.length - 1;

      if (i.option.includes("<=")) {
        operator = "<=";
        this.values.push("<=")
      }

      else if (i.option.includes(">=")) {
        operator = ">=";
        this.values.push(">=")

      }
      else
        operator = "<>";

      this.formArray.at(formArrayLastIndex).patchValue({
        id: i.id,
        option: i.option,
        score: i.score,
        operator: operator,
      });

      this.updateOperatorList();
      //label for qualitiative ranges
      this.options[index] = i.option;
      index++;

    }
    
   // this.addQuestionForm['subFactorVO'].controls['id'].setValue(questionObj.subFactorVO.id);
   
  }
  getSubFactors(event) {
    if (event > 0) {
      this.assessmentService.getSubfactorListByFactorId(event).subscribe(data => {
        this.subFactorVOList = data;
      })
      this.addQuestionForm.get('subFactorVO').get('id').setValue(null);
    }
  }
  questionTypeChange(questionTypeID) {

    this.updateOperatorList();
    this.setValidatorsAsPerQuestionType();
    var formArrayLastIndex = this.formArray.length - 1;
    while (formArrayLastIndex >= 0) {
      this.formArray.removeAt(formArrayLastIndex);
      formArrayLastIndex--;

    }
    this.checkQuestionType(questionTypeID);

  }
  getResponseOptions(responseName) {
    let index = 0;
    this.options = [];
    var formArrayLastIndex = this.formArray.length - 1;
    while (formArrayLastIndex >= 0) {
      this.formArray.removeAt(formArrayLastIndex);
      formArrayLastIndex--;
    }

    this.assessmentService.getResponseOptionValuesForResponseOption(responseName).subscribe(data => {
      this.responseOption = data;
      for (let i of this.responseOption) {
        this.formArray.push(this.initOptionRows());
        var formArrayLastIndex = this.formArray.length - 1;
        this.formArray.at(formArrayLastIndex).patchValue(i);
        this.options[index] = i.option;
        index++;
      }

    }
    )
  }
hideErrorMessage(op)
{
 
  this.rangeRequiredMessage = "";
  this.notValidRangeMessage="";
  this.maxScoreRangeMessage = "";
  if(op==1)
  {
  this.addQuestionForm.controls['quantitativeScore'].reset();
  this.addQuestionForm.get('quantitativeEndValue').reset();
  this.addQuestionForm.get('quantitativeStartValue').reset();
  }
}

clearRange()
{
  this.addQuestionForm.get('quantitativeEndValue').setValidators(null);
  this.addQuestionForm.get('quantitativeStartValue').setValidators(null);
  this.addQuestionForm.get('quantitativeEndValue').reset();
  this.addQuestionForm.get('quantitativeStartValue').reset();
  this.addQuestionForm.controls['quantitativeOperator'].reset();
  this.addQuestionForm.controls['quantitativeScore'].reset();
 
}

  getQuantitativeRange() {
    this.nullFlag = false;
    let index = 0;
    this.options = [];
    var operator = this.addQuestionForm.get('quantitativeOperator').value;
    var startValue = parseInt(this.addQuestionForm.get('quantitativeStartValue').value);
    var endValue = parseInt(this.addQuestionForm.get('quantitativeEndValue').value);
var score =this.addQuestionForm.get('quantitativeScore').value;
    var optionToDisplay;
    var validFlag = false;
    var scorevalidFlag = false;
    this.rangeRequiredMessage = "";
    if (operator == "<=") {
      optionToDisplay = operator + endValue;
      if (endValue>=-1000 && endValue<=1000) {
        validFlag = true;
      }
    }
    if (operator == "<>") {
      optionToDisplay = startValue + " - " + endValue;
      if (startValue >=-1000 && endValue <=1000 && startValue < endValue) {
        validFlag = true;
      }
    }
    if (operator == ">=") {
      optionToDisplay = operator + startValue;
      if (startValue>=-1000  && startValue<=1000) {
        validFlag = true;
      }
    }
    if(operator == null)
    {
      this.nullFlag = true;
    }
    if(score>=-100 && score<=100)
    {
      scorevalidFlag=true;
    
    }

    if (validFlag && scorevalidFlag) {

      if (operator != "<>")
        this.values.push(operator);


      this.notValidRangeMessage = "";

      this.formArray.push(this.initOptionRows());

      var formArrayLastIndex = this.formArray.length - 1;

      this.formArray.at(formArrayLastIndex).patchValue({
        option: optionToDisplay,
        operator: operator,
        score: score,
      });

      this.updateOperatorList();
      this.addQuestionForm.controls['quantitativeOperator'].reset();
      this.addQuestionForm.controls['quantitativeStartValue'].reset();
      this.addQuestionForm.controls['quantitativeEndValue'].reset();
      this.addQuestionForm.controls['quantitativeScore'].reset();
      this.submitvalid=true;
      this.notValidRangeMessage ="";
      this.maxScoreRangeMessage = "";
    }
    else
    {
      if(scorevalidFlag== false)
      this.maxScoreRangeMessage = "Enter values between -100 and 100";
      if(validFlag==false)
      this.notValidRangeMessage = "The Values You Entered Are Not A Valid Range";
      this.submitvalid=false;
    }
  }
  setValidatorsAsPerQuestionType() {

    this.addQuestionForm.get('questionTypeId').valueChanges
      .subscribe(questionType => {
        if (questionType == this.questionDataVO.quantitativeTypeId) {

          this.addQuestionForm.get('responseName').setValidators(null);
          this.addQuestionForm.get('responseName').reset();

          if (this.addQuestionForm.get('quantitativeOperator').value == ">=" || this.addQuestionForm.get('quantitativeOperator').value == "<>")
            this.addQuestionForm.get('quantitativeStartValue').setValidators([Validators.required]);
          if (this.addQuestionForm.get('quantitativeOperator').value == "<=" || this.addQuestionForm.get('quantitativeOperator').value == "<>")
            this.addQuestionForm.get('quantitativeEndValue').setValidators([Validators.required]);

        }
        if (questionType == this.questionDataVO.qualitativeRatingBasedTypeId) {

          this.addQuestionForm.get('responseName').setValidators([Validators.required]);
          this.addQuestionForm.get('quantitativeOperator').reset();
          this.addQuestionForm.get('quantitativeStartValue').setValidators(null);
          this.addQuestionForm.get('quantitativeStartValue').reset();
          this.addQuestionForm.get('quantitativeEndValue').setValidators(null);
          this.addQuestionForm.get('quantitativeEndValue').reset();


        }
        this.addQuestionForm.get('responseName').updateValueAndValidity();
        this.addQuestionForm.get('quantitativeOperator').updateValueAndValidity();
        this.addQuestionForm.get('quantitativeStartValue').updateValueAndValidity();
        this.addQuestionForm.get('quantitativeEndValue').updateValueAndValidity();

      });

  }

  onSubmit(formDirective: FormGroupDirective) {
    if (this.quantitative){
     this.getQuantitativeRange();
    }
    if(this.submitvalid || this.nullFlag || this.qualitative )
    {
    this.rangeRequiredMessage =='';
     if (this.quantitative && this.addQuestionForm.get('responseOptionsVOList').value.length == 0)
     this.rangeRequiredMessage = "Please Define The Ranges";

    this.isSubmit = true;
    if (this.addQuestionForm.valid) {
      if (this.quantitative && this.addQuestionForm.get('responseOptionsVOList').value.length == 0)
        this.rangeRequiredMessage = "Please Define The Ranges";
      else {

        this.rangeRequiredMessage = "";
        this.assessmentService.createQuestion(this.addQuestionForm.getRawValue()).subscribe(myform => {
          this.getQuestionsList();

          if (this.addQuestionForm.get('id').value != null)
            var message = "Successfully Edited Question";
          else
            var message = "Successfully Added Question";
          // swal({
          //   title: 'Success!',
          //   text: message,
          //   timer : 2000,
          //   type: 'success',
          //   confirmButtonText: 'Ok'
          // })
          this.addEditSuccessComponent.showSuccessSwal(message)
            .then((value) => {
              this.resetForm();
              formDirective.resetForm();
              
            });
        });
      }
    }
    else {
     

      this.addQuestionForm.get('factorVO').get('id').markAsTouched();
      this.addQuestionForm.get('subFactorVO').get('id').markAsTouched();
      this.addQuestionForm.get('questionText').markAsTouched();
      this.addQuestionForm.get('questionTypeId').markAsTouched();

      // if (this.qualitative) {
      //   this.addQuestionForm.get('responseName').markAsTouched();
      // }

      if (this.quantitative && this.addQuestionForm.get('quantitativeOperator').value != null) {

        // this.addQuestionForm.get('quantitativeOperator').markAsTouched();
        this.addQuestionForm.get('quantitativeStartValue').markAsTouched();
        this.addQuestionForm.get('quantitativeEndValue').markAsTouched();
      }

      return;
    }
  }
  }
  resetForm() {
    this.values = [];
this.hideErrorMessage(0);
    var formArrayLastIndex = this.formArray.length - 1;
    while (formArrayLastIndex >= 0) {
      this.formArray.removeAt(formArrayLastIndex);
      formArrayLastIndex--;
    }
    //Reset to the original-- even if after edit make it as a new entry
    this.addQuestionForm.reset();
    this.topWrap.nativeElement.classList.remove("top-form-wrapper-highlight");
    this.setQuestionTextList(0);
    this.setValidatorsAsPerQuestionType();

  }

  resizeInput(inputText) {

    setTimeout(() => {

      const minWidth = 64;

      if (this.invTextER.nativeElement.offsetWidth > minWidth) {
        this.width = this.invTextER.nativeElement.offsetWidth + 2;
      } else {
        this.width = minWidth;
      }

    }, 0);
  }


  
  // Reform the chosen list
  updateOperatorList() {


    let types = [];
    // For all types check if they were chosen
    this.operatorsList.forEach((type) => {
     

      // if current type in array of chosen
      let selected = this.values.includes(type.value);

      // push current type with its status
      types.push(new Type(type.value, !selected));
    });
    

    // Send messages to mat-option in our template 
    this.operators$.next(types);

  }
  deleteRange(event, index) {
    this.formArray.removeAt(index);
    this.values = this.values.filter(obj => obj !== event.operator);
    this.updateOperatorList();
  }
  getQuestionsList() {
  
    this.assessmentService.getQuestionsList().subscribe((data) => {
      this.questionsList = data;
    });
   
  }

  getFormValidationErrors() {
    Object.keys(this.addQuestionForm.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.addQuestionForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
        });
      }
    });
  }

   public uniqueName(): ValidatorFn {
    return (control:AbstractControl) : {[key:string]:boolean} | null => {
        if (control.value !== undefined && this.questionTxtList.length > 0 && control.value !=null) {
          if( this.questionTxtList.includes((control.value).trim()))
          {
             return { 'unique': true };

          }
        }
        return null;
    };
  }

  setQuestionTextList(editId)
  {
     this.questionTxtList =[];
     if(this.questionsList.length > 0)
     {
       for (let questionVal of this.questionsList)
       {
         if(questionVal.id!=editId)
         {
           this.questionTxtList.push(questionVal.questionText);
         }
       }
     }
  }
}
