import { Injectable } from '@angular/core';
import { BaseUrlService } from '../../shared/base-url.service';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
 
  private baseUrl = BaseUrlService.PARAMATERCONFIGURATIONBASEURL;
 // private uploadUrl=
 // yearList: any = [];
    
  constructor(private http: HttpClient) { }


  getListOfParameter(): Observable<any> {
    return this.http.get(`${this.baseUrl}/parameterConfiguration`);
  }
  getListOfConsignorCompanies(parent_id:any){
    return this.http.get(`http://44.194.154.102:6503/get_company_name?parent_id=`+parent_id);
  }
  createOrUpdateParameterConfiguration(jsonArray:any){
    let body = JSON.stringify(jsonArray); 
    let url = this.baseUrl+'/parameterConfiguration';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
       
      })
    };

    return this.http.post(url, body, httpOptions); 
  }
  
  
	createCompany(addCompanyForm: any) {

    let companyUrlStringList = addCompanyForm.companyUrlList.map(a => a.companyUrl);
    addCompanyForm.companyUrlList = companyUrlStringList;
  
  
  let body = JSON.stringify(addCompanyForm);
 
	let url = this.baseUrl + '/company';
	const httpOptions = {
		headers: new HttpHeaders({
		'Content-Type': 'application/json',
	    })
	  };
	  return this.http.post(url, body, httpOptions);
	}
	
	
	 getListOfCompanies(): Observable<any> {
	  return this.http.get(`${this.baseUrl}/company`);
	}
  getListOfPrimaryDataSources(): Observable<any> {
	  return this.http.get(`${this.baseUrl}/listPrimarySecondaryDataSource/P`);
	}
  getListOfAlternateDataSources(): Observable<any> {
	  return this.http.get(`${this.baseUrl}/listPrimarySecondaryDataSource/A`);
	}
  getListOfCountries():Observable<any>
  {
    return this.http.get(`${this.baseUrl}/getCountryList`);
  }

	getListOfCompanyTypes(): Observable<any> {
	  return this.http.get(`${this.baseUrl}/companyTypeList`);
	}
	
	deleteCompany(id): Observable<any> { 
	  let url = this.baseUrl + '/companydelete/'+id;
	  const httpOptions = {
	    headers: new HttpHeaders({
	      'Content-Type': 'application/json',
	
	    })
	  };
	  return this.http.post(url, null, httpOptions);
	}
	
	getCompanyTypeData():Observable<any>
  {
    return this.http.get(`${this.baseUrl}/companyType`);
  }

  getChildParameterData(paramterDataVO:any){
    let body=JSON.stringify(paramterDataVO);
    let url=this.baseUrl+'/getChildParameterdata';
    const httpOptions={
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post(url,body,httpOptions);
  }



  createCompanyType(companyType:any){
    let body = JSON.stringify(companyType); 
    let url = this.baseUrl+'/companyType';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
       
      })
    };
    return this.http.post(url, body, httpOptions); 
  }
  
  deleteCompanyType(id): Observable<any> {
    return this.http.post(`${this.baseUrl}/companyType/delete/` + id, { responseType: 'text' });
  }

  getYearList() {
    let yearList= [];
    let currentYear = (new Date()).getFullYear();
    let startYear = 2010

    for (let i = startYear; i <= (currentYear + 10); i++) {
      yearList.push(i);
    }

    return yearList;
  }

  upload(formdata): Observable<any>{
  
    const req = new HttpRequest('POST', this.baseUrl+'/uploadCompanyFile', formdata, {
      reportProgress: true,
      responseType: 'text',
      headers: new HttpHeaders({
        "Content-Type": "multipart/form-data"
        })
    });

    return this.http.request(req);
 
  }


  downloadedFile(params) : Observable<any> {
    return this.http.get(`${this.baseUrl}/download`,params);
  }

  downloadFile() : string{
    return this.baseUrl;
  }
 
  download(fileName): Observable<any>{
    return this.http.get(`${this.baseUrl}/download`,fileName);
  }
  searchCompanyDetails(year,companyId): Observable<any> {
	  return this.http.get(`${this.baseUrl}/getCompanyDetails/`+year+'/'+companyId);
  }
  
  deletecompanyDetails(id): Observable<any> { 
	  let url = this.baseUrl + '/deleteCompanyDetails/'+id;
	  const httpOptions = {
	    headers: new HttpHeaders({
	      'Content-Type': 'application/json',
	
	    })
	  };
	  return this.http.post(url, null, httpOptions);
	}
	getFiledetailsOfCompanies(): Observable<any> {
	  return this.http.get(`${this.baseUrl}/fileDetailscompany`);
	}
  createOrUpdateCompanyAdditionalDetails(jsonArray:any){
    let body = JSON.stringify(jsonArray); 
    let url = this.baseUrl+'/companyAdditionalInfo';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
       
      })
    };

    return this.http.post(url, body, httpOptions); 
  }
  getCompanyDetailsForYear(companyId:number,year:number): Observable<any>{
    return this.http.get(`${this.baseUrl}/getCompanyDetailsForYear/`+companyId+'/'+year);

  }

  validateCompanyCode(id,companyCode): Observable<any> {
    let url = this.baseUrl + '/validateCompanyCode/' + id + '/' + companyCode;
    return this.http.get(url);
  }  
  createRatingAgency(ratingAgencyForm: any) {
    let url = this.baseUrl + "/createOrUpdateRatingAgency";
    let body = JSON.stringify(ratingAgencyForm);
    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    let headerOption = {
      headers: headers
    };

    return this.http.post(url, body, headerOption);
  }
  getRatingAgency(): Observable<any> {
    let url = this.baseUrl + "/getRatingAgencyList";
    return this.http.get(url);
  }

  uploadRatingAgencyFile(formdata): Observable<any> {
    const req = new HttpRequest('POST', this.baseUrl+'/uploadRatingAgencyFile', formdata, {
      reportProgress: true,
      responseType: 'text',
      headers: new HttpHeaders({
      "Content-Type": "multipart/form-data"
      })
      });
      
      return this.http.request(req);
  }
    deleteRatingAgency(ratingAgencyId)
  {
      return this.http.delete(`${this.baseUrl}/deleteRatingAgency/`+ ratingAgencyId, { responseType: 'text' });
  }

  createOrUpdatePrimaryDataSource(primaryDataObj)
  {
    
 
    let url = this.baseUrl +'/primaryDatasource';
    let body = JSON.stringify(primaryDataObj);
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
        })
      };
      return this.http.post(url, body, httpOptions);

  }
  fetchAllPrimaryDataSources()
  {
    let url = this.baseUrl + "/listPrimaryDatasource";
    return this.http.get(url);
  }
  deletePrimaryDataSource(id) {

    return this.http.post(`${this.baseUrl}/deletePrimaryDatasource/` + id, { responseType: 'text' });
  }
  createOrUpdateAlternateDataSource(alternateDataObj)
  {
    
 
    let url = this.baseUrl +'/alternateDatasource';
    let body = JSON.stringify(alternateDataObj);
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
        })
      };
      return this.http.post(url, body, httpOptions);

  }
  fetchAllAlternateDataSources()
  {
    let url = this.baseUrl + "/listAlternateDatasource";
    return this.http.get(url);
  }
  deleteAlternateDataSource(id) {

    return this.http.post(`${this.baseUrl}/deleteAlternateDatasource/` + id, { responseType: 'text' });
  }
  uploadPrimaryDataSourceFile(formdata): Observable<any> {
    console.log("sending fileee")
    const req = new HttpRequest("POST", this.baseUrl + "/uploadPrimaryDatasourceFile", formdata, {
      reportProgress: true,
      responseType: "text",
      headers: new HttpHeaders({
        "Content-Type": "multipart/form-data"
        })
    });
    return this.http.request(req);
  }
  uploadAlternateDataSourceFile(formdata): Observable<any> {
    console.log("sending fileee")
    const req = new HttpRequest("POST", this.baseUrl + "/uploadAlternateDatasourceFile", formdata, {
      reportProgress: true,
      responseType: "text",
      headers: new HttpHeaders({
        "Content-Type": "multipart/form-data"
        })
    });
    return this.http.request(req);
  }
  checkPrimarySourceNameValidation(id, sourceName): Observable<any> {
    let url =
      this.baseUrl +
      "/primaryDatasource" +
      "/validateName/" + id + "/" +
       encodeURIComponent(sourceName);
    return this.http.get(encodeURI(url));
  }
  checkAlternateSourceNameValidation(id, sourceName): Observable<any> {
    let url =
      this.baseUrl +
      "/alternateDatasource" +
      "/validateName/" + id + "/" +
       encodeURIComponent(sourceName);
    return this.http.get(encodeURI(url));
  }
  getParameterLevelDetails(parameterId,parameterLevel1Ids)
  {
    let url = this.baseUrl + "/getParameterLevel2ByLevel1/"+parameterId+"/"+parameterLevel1Ids;
    return this.http.get(url);
  }
  getSubParameters(parameterId)
  {
    let url = this.baseUrl + "/getParameterLevel1Data/"+parameterId
    return this.http.get(url);
  } 
  listPrimaryDataAttribute(id): Observable<any> {
    let url = this.baseUrl + "/listPrimaryDatasourceAttribute/"+id;
    return this.http.get(url);
  }
  listAlternateDatasourceAttribute(id): Observable<any> {
    let url = this.baseUrl + "/listAlternateDatasourceAttribute/"+id;
    return this.http.get(url);
  }

  getCompanyAssetDetails(id): Observable<any> {
    let url = this.baseUrl + "/getCompanyAssetDetails/"+id;
    return this.http.get(url);
  }
}


