import { Component, OnInit,ViewChild } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl, FormBuilder, Validators,ValidatorFn, AbstractControl} from '@angular/forms';
import { AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import swal from 'sweetalert2';
import { CustomValidationsService } from '../../../../../shared/Validations/custom-validations.service';
import { AddEditSuccessComponent } from '../../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
@Component({
  selector: 'app-add-panel',
  templateUrl: './add-panel.component.html',
  styleUrls: ['./add-panel.component.css']
})
export class AddPanelComponent implements OnInit {
  
  usersList:any;
  addPanelForm: FormGroup;
  isSubmit: boolean;
  panelList:any=[];
  panelNameList:any=[];
  toggleList:boolean = false;
  topWrapper:any;
  @ViewChild('panelForm') panelForm;
  @ViewChild ("topWrap") topWrap;
  selectText="Select All";
   constructor(private addEditSuccessComponent: AddEditSuccessComponent,private assessementTemlateMasterService:AssessmentTemplateMasterdataService,
    private formBuilder:FormBuilder,private customValidationsService:CustomValidationsService) { 
          this.getPanelList();
      this.addPanelForm = this.createFormGroup();

    }
     
  ngOnInit() {
   this.isSubmit = false;
    this.topWrapper=this.topWrap;
   this.assessementTemlateMasterService.getAlluserList().subscribe(userList=>{
   this.usersList  = userList;
   });

  }

  createFormGroup() {

    return this.formBuilder.group({
      id: [],
      panelName: ['', [Validators.required,Validators.maxLength(100),this.uniqueName(),this.customValidationsService.noWhitespaceValidator]],
      userIds: ['', [Validators.required]]
    })

  }
  onSubmit(){
    this.isSubmit = true;
    if(this.addPanelForm.valid){
      
      this.assessementTemlateMasterService.createPanel(this.addPanelForm.getRawValue()).subscribe(() =>{
          this.getPanelList();
          if(this.addPanelForm.get('id').value!=null) {
            var msg = "Successfully edited Panel";
          }
          else {
            var msg = "Successfully added Panel";
          }
          // swal({
          //         title : 'Success!',
          //         text : msg,
          //         timer: 2000,
          //         type : 'success',
          //         confirmButtonText : 'Ok'
          // })
          this.addEditSuccessComponent.showSuccessSwal(msg)
          .then((value)=>{
             this.resetPanelForm();
          });
         
       
      }
      , (error) => {
        console.log(error);
        // if(error.status==409&&  error.error.includes("could not execute statement"))
        // {
        //   let msg ="Submit Failed due to duplicate value entry";
        //    swal({
        //           title : 'Error!',
        //           text : msg,
        //           timer: 2000,
        //           type : 'error',
        //           confirmButtonText : 'Ok'
        //   }).then((value)=>{
             
            
        //      this.resetPanelForm();

        //   });
        // }

      });
    }
    else{
      this.addPanelForm.get('panelName').markAsTouched();
      this.addPanelForm.get('userIds').markAsTouched();
      return;
    }
  }

  selectAll(element:any,event: any){
  
    this.toggleList = !this.toggleList;
    var valueArray = [];
    if(this.toggleList)
    {
      for(let i of element)
      {
        valueArray.push(i.id);
      }
      this.selectText="Deselect All";
    }
    else{
      this.selectText="Select All";
    }
    this.addPanelForm.controls.userIds.patchValue(valueArray);
  }

  getPanelList() {
    this.assessementTemlateMasterService.getPanelList().subscribe((data) => {
        this.panelList = data;
        this.setPanelName(0);

    }, (error) => {
      console.log(error);
    });
  }
  resetPanelForm() {
    this.panelForm.resetForm();
    this.setPanelName(0);

    this.topWrap.nativeElement.classList.remove("top-form-wrapper-highlight");

  }
  defaultValues(p) {
    this.setPanelName(p.id);
    this.addPanelForm.patchValue(p);
  }

  setPanelName(editId)
  {
     this.panelNameList=[];
    let panelName='';
    if(this.panelList.length > 0)
    {
      for(let panelVal of this.panelList)
      {
        if(panelVal.id!=editId)
        {
          if( panelVal.panelName!=null)
          {
               panelName    =   panelVal.panelName.trim();
             this.panelNameList.push(panelName);
          }
            
        }
       

      }
    }
  }

  public uniqueName(): ValidatorFn {
    return (control:AbstractControl) : {[key:string]:boolean} | null => {
        if (control.value !== undefined && this.panelNameList.length > 0 && control.value !=null) {
          if( this.panelNameList.includes((control.value).trim()))
          {
             return { 'unique': true };

          }
        }
        return null;
    };
  }
}
