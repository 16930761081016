import { Injectable } from '@angular/core';
import {Http, Response , Headers, RequestOptions} from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../../shared/base-url.service';


@Injectable()
export class CompanydetailsService {

  private baseUrl = BaseUrlService.REPORTBASEURL;
  
  private headers = new Headers({'Content-type':'application/json',
  'Authorization': `Bearer ${sessionStorage.getItem('token')}`
});
  private options = new RequestOptions({headers:this.headers});
  private company:any;
  constructor(private _http:Http) { }

  getCompanyIndicatorDetails(level_no:number,company_id:number,company_selected_parameter_id:number,subcategoryId:number){
    return this._http.get(this.baseUrl+'/getCompanyIndicators/'+level_no+'/'+company_id+'/'+company_selected_parameter_id+'/'+subcategoryId,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  getCompanyLevelDetails(level_no:number,parameter_id:number){
    return this._http.get(this.baseUrl+'/getCompanyDetails/'+level_no+'/'+parameter_id,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }
  getiuxFinance(){
    return this._http.get(this.baseUrl+'/getIUXFinanceData/',this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
    
  }
  errorHandler(error:Response){
    console.log("Error");
    return Observable.throw(error || "SERVER_ERROR");
  }

  setter(company:any){
    this.company = company;
  }

  getter(){
    return this.company;
  }
}
