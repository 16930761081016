import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CustomValidationsService {

  constructor() { }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
public fileExtension(control: FormControl)
{
  if(control.value!=null)
  {
     var ext = control.value.name.substring(control.value.name.lastIndexOf('.') + 1);
     if (ext.toLowerCase() == 'pdf') 
        return null;
     else 
       return  { 'fileTypePdf': true };
      
  }
}
public fileSize(control: FormControl)
{
  if(control.value!=null)
  {
    var totalSizeMB = control.value.size / Math.pow(1024,2);
      if (totalSizeMB < 25) 
        return null;
      else 
        return  { 'fileSize': true };
      
  }
}
public imageFileExtension(control: FormControl)
{
  if(control.value!=null&&control.value.name!=null)
  {
     var ext = control.value.name.substring(control.value.name.lastIndexOf('.') + 1);
     if (ext.toLowerCase() == 'png') 
        return null;
     else 
       return  { 'fileTypePng': true };
      
  }
}
public imageFileSize(control: FormControl)
{
  if(control.value!=null&&control.value.size!=null)
  {
    var totalSizeMB = control.value.size / Math.pow(1024,2);
      if (totalSizeMB < 10) 
        return null;
      else 
        return  { 'fileSize': true };
      
  }
}
public fileUpload(control: FormControl)
{
  if(control.value!=null&&control.value!="")  
     return null;
     else 
     return { 'uploadFileRequired': true };  
}
public excelFileExtension(control: FormControl)
{
  if(control.value!=null)
  {
     var ext = control.value.substring(control.value.lastIndexOf('.') + 1);
     if (ext.toLowerCase() == 'xlsx') 
        return null;
     else if (ext.toLowerCase() == 'xls') 
        return null;
     else 
       return  { 'fileTypeXls': true };
      
  }
}
}
