import { Injectable } from '@angular/core';
import {Http, Response , Headers, RequestOptions} from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../../shared/base-url.service';


@Injectable()
export class ParametersService {

  private baseUrl = BaseUrlService.REPORTBASEURL;
  
  private headers = new Headers({'Content-type':'application/json',
  'Authorization': `Bearer ${sessionStorage.getItem('token')}`
});
  private options = new RequestOptions({headers:this.headers});
  private parameters:any;
  constructor(private _http:Http) { }

  getParameters(){
    return this._http.get(this.baseUrl+'/parameterList',this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }
  
  getSubParameters(id:number){
    return this._http.get(this.baseUrl+'/subParameterList/'+id,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  getParameterLevelDetails(level_no:number,parameter_id:number){
    return this._http.get(this.baseUrl+'/listReports/'+level_no+'/'+parameter_id,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  errorHandler(error:Response){
    console.log("Error");
    return Observable.throw(error || "SERVER_ERROR");
  }

  setter(parameters:any){
    this.parameters = parameters;
  }

  getter(){
    return this.parameters;
  }
}
