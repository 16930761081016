import { Component, OnInit, Output, EventEmitter,Input,OnChanges,SimpleChanges ,ViewChild, AfterViewInit  } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import {Http, Response , Headers, RequestOptions} from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataQualityMasterService } from '../../data-quality-master.service';
import { IndicatorMasterService } from '../../../indicator-master/indicator-master.service';
import {MatPaginator} from '@angular/material';
import {MatSort} from '@angular/material';
import swal from 'sweetalert2';
import { AddParityHomogeneityLevelsComponent } from '../add-parity-homogeneity-levels/add-parity-homogeneity-levels.component';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';

@Component({
  selector: 'app-list-parity-homogeneity-levels',
  templateUrl: './list-parity-homogeneity-levels.component.html',
  styleUrls: ['./list-parity-homogeneity-levels.component.css']
})
export class ListParityHomogeneityLevelsComponent implements OnInit {

  parityHomogeneityList: any;
  dataTableFlag: boolean;
  displayedColumns:any;
  originalColumnNames : any;
  constantsList: any;
  flag : boolean = false;
  top:any;
  jsonArray =[];
  constantsValue : any;
  @Input() set topWrapper(val){
    this.top=val;
  }

  @Input() set constantValueGiven(val){
    this.constantsValue=val;
    this.refreshDataTable(); 
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Output() parityHomogeneityComponent = new EventEmitter();
  constructor(private dataQualityService: DataQualityMasterService,private constantsService: IndicatorMasterService,private addParityHomogeneityLevelsComponent: AddParityHomogeneityLevelsComponent,private addEditSuccessComponent: AddEditSuccessComponent ) { }

  ngOnInit() {
    this.getConstantsValueList(0);
  }

 


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); 
    filterValue = filterValue.toLowerCase(); 
   
   }


  dataTablePopulation(constantValues) {
    this.displayedColumns = [];
    this.originalColumnNames = [];
    this.jsonArray = [];

    var count = 0;
    for (let i of constantValues) {
      var newArray = {};
      newArray['Sno'] = ++count;
      newArray['id'] = i.id;
      newArray['constantType'] = i.constantType;
      newArray['value'] = i.value;
      newArray['code'] = i.code;
      this.jsonArray.push(newArray);
    }
    this.displayedColumns = ['Sno', 'constantType', 'value','code', 'Actions']
    this.originalColumnNames = ['Sl.No', 'Type', 'Value','Code', 'Actions']
  }
  

  editParityOption(constantType){

    this.parityHomogeneityComponent.emit({ 
      id:constantType.id, 
      constantType: constantType.constantType, 
      code:constantType.code,
      value:constantType.value });
    
  }

  deleteParityOption(constantType){
  

    swal({
      title: '<p class="swal2-title-warning">Are You Sure?</p>',
      text: "You Want To Delete The Parity/Homogeneity Level",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'

    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.constantsService.deleteConstant(constantType.id).subscribe((errorFlag)=>{
          var message =   'Successfully deleted Parity/Homogeneity value';
          this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
              this.addParityHomogeneityLevelsComponent.resetParityHomogeneityForm();
              this.refreshDataTable();
             
         });
        },(error)=>{

          console.log(error);
    });
  }
});

}

getConstantsValueList(constantType) {
  if (constantType == undefined || constantType == 0) {
    this.dataQualityService.fetchParityHomogeneityConstants().subscribe((data) => {
      this.constantsList = data;
      this.dataTablePopulation(this.constantsList);
    })
  }
  else {
    this.constantsService.getConstantsListByConstantType(constantType).subscribe((data) => {
      this.constantsList = data;
      this.dataTablePopulation(this.constantsList);
    })
  }
}

refreshDataTable() {
  this.dataQualityService.fetchParityHomogeneityConstants().subscribe(data =>{
   this.dataTablePopulation(data);
  });
}

}
