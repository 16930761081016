import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { IndicatorMasterService } from '../../../indicator-master/indicator-master.service';
import { AddIndicatorStackComponent } from '../add-indicator-stack/add-indicator-stack.component';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
@Component({
  selector: 'app-list-indicator-stack',
  templateUrl: './list-indicator-stack.component.html',
  styleUrls: ['./list-indicator-stack.component.css']
})
export class ListIndicatorStackComponent implements OnInit {

  indicatorStacks: any;
  indicatorStackList: any;
  jsonArray: any;
  displayedColumns: any = [];
  dataTableFlag: boolean;
  subCategorySelectedId: any;
  categorySelectedId: any;
  originalColumnNames: any = [];
  top: any;
  @Input() set topWrapper(val) {
    this.top = val;
  }
  @Input() set categorySelected(val) {
    if (!val) val = 0;
    this.categorySelectedId = val;
  }
  @Input() set subCategorySelected(val) {
    if (!val) val = 0;
    this.subCategorySelectedId = val;
  }
  @Input() set indicatorStack(val) {
    this.indicatorStackList = [];
    if (val && val.length > 0) {
      this.indicatorStackList = val;
    }
  }
  @Output() indicatorStackComponent = new EventEmitter();
  constructor(private indicatorMasterService: IndicatorMasterService,
    private indStackComponment: AddIndicatorStackComponent,
    private addEditSuccessComponent: AddEditSuccessComponent) { }

  ngOnInit() {
    this.getListOfIndicatorStacks(0, 0);
  }
  dataTablePopulation(indicatorStacks) {
    this.jsonArray = [];
    var count = 0;
    for (let i of indicatorStacks) {
      var newArray = {};
      newArray['Sno'] = ++count;
      newArray['id'] = i.id;
      newArray['Code'] = i.code;
      newArray['Name'] = i.name;
      newArray['PerformanceArea'] = i.indicatorSubcategoryVO.name;
      newArray['subCategoryId'] = i.indicatorSubcategoryVO.id;
      newArray['category'] = i.indicatorSubcategoryVO.indicatorCategoryVO.code + "-" + i.indicatorSubcategoryVO.indicatorCategoryVO.name;
      newArray['categoryId'] = i.indicatorSubcategoryVO.indicatorCategoryVO.id;
      this.jsonArray.push(newArray);

    }

    this.displayedColumns = ['Sno', 'Code', 'Name', 'category', 'PerformanceArea', 'Actions'];
    this.originalColumnNames = ['Sl.No', 'Code', 'Name', 'Category', 'Performance Area', 'Actions'];
  }
  ngOnChanges(changes: SimpleChanges) {
    this.getListOfIndicatorStacks(this.categorySelectedId, this.subCategorySelectedId);
  }

  getListOfIndicatorStacks(categorySelectedId, subCategorySelectedId) {
    this.indicatorStacks = [];
    if (categorySelectedId == 0 && subCategorySelectedId == 0) {
      if (this.indicatorStackList && this.indicatorStackList.length == 0) {
        this.indicatorMasterService.getListOfIndicatorStacks().subscribe(data => {
          this.indicatorStacks = data;
          this.dataTablePopulation(this.indicatorStacks);
        });
      }
      else {
        this.indicatorStacks = this.indicatorStackList;
        this.dataTablePopulation(this.indicatorStacks);
      }
    }
    else if (categorySelectedId != 0 && subCategorySelectedId == 0) {
      this.indicatorMasterService.getIndicatorStackByCategoryId(categorySelectedId).subscribe(data => {
        this.indicatorStacks = data;
        this.dataTablePopulation(this.indicatorStacks);
      });
    }
    else if (subCategorySelectedId != 0) {
      this.indicatorMasterService.getIndicatorStackBySubcategoryId(subCategorySelectedId).subscribe(data => {
        this.indicatorStacks = data;
        this.dataTablePopulation(this.indicatorStacks);
      });
    }

  }

  delete(indStackObj) {
    var confirmMessage = "You Want To Delete Indicator Stack";
    this.addEditSuccessComponent.showConfirmationSwal(confirmMessage, 'Yes', 'No').then((isConfirm) => {
      if (isConfirm.value) {
        this.indicatorMasterService.deleteIndicatorStack(indStackObj.id).subscribe((errorFlag) => {
          if (errorFlag == "0") {
            var deleteSuccess = 'Deleted Indicator Stack';
            this.addEditSuccessComponent.showSuccessSwal(deleteSuccess)
              .then((value) => {
                this.getListOfIndicatorStacks(0, 0);
                this.indStackComponment.resetIndStackForm();
              });
          }
          else {
            var failMessage = 'Indicator Stack already in use.  It cannot be deleted';
            this.addEditSuccessComponent.showFailedSwal(failMessage, 'Failed');
          }
        });
      }
    });
  }
  edit(indStackObj) {
    this.indicatorStackComponent.emit({ id: indStackObj.id, subCategorySelected: indStackObj.subCategoryId, code: indStackObj.Code, name: indStackObj.Name, categoryId: indStackObj.categoryId });
  }

}
