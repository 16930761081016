import { Injectable } from '@angular/core';
import {Http, Response , Headers, RequestOptions} from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../../../shared/base-url.service';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  private baseUrl = BaseUrlService.REPORTBASEURL;
  private headers = new Headers({'Content-type':'application/json'});
  private options = new RequestOptions({headers:this.headers});
  constructor(private http:HttpClient) { }

  fetchNewsDetails(companyId:number){
    return this.http.get(this.baseUrl+'/newsDetails/'+companyId);
  }
  fetchMonthlyReportedNews(companyId:number){
    return this.http.get(this.baseUrl+'/fetchYearlyReportedNews/'+companyId);
  }
  fetchTimelineBasedNews(companyId:number,startDate:String,endDate:String){
  return this.http.get(this.baseUrl+'/fetchTimelineBasedNews?companyId='+companyId+'&periodStartDate='+startDate+'&periodEndDate='+endDate+'')
  }
  postNewsStatus(id,status)
{
  let url = this.baseUrl + "/fetchNegativeStatus/" + id+"/"+status;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.http.post(url, null, httpOptions);
}
}
