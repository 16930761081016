import { Injectable } from '@angular/core';
import {Http, Response , Headers, RequestOptions} from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../../shared/base-url.service';

@Injectable({
  providedIn: 'root'
})
export class GpsDashboardService {
  private baseUrl = BaseUrlService.REPORTBASEURL;
  private headers = new Headers({'Content-type':'application/json'});
  private options = new RequestOptions({headers:this.headers});
  constructor(private http:HttpClient) { }
  fetchMapDetails():Observable<any>{
    return this.http.get(this.baseUrl+'/getCountryDetails');
  }
  fetchCountryIndDetails(countryId:number){
    return this.http.get(this.baseUrl+'/getCountryIndicatorDetails/'+countryId);
}
}
