import { Component, OnInit,ViewChild } from '@angular/core';
import { PerformanceFrameworkHigherService } from '../performance-framework-higher.service';
import { MatTableDataSource, MatPaginator, MatSort} from '@angular/material';
import { Router,ActivatedRoute, NavigationExtras } from '@angular/router';
//import {DatePipe} from '@angular/common';
@Component({
  selector: 'app-list-active-performance-framework-templates',
  templateUrl: './list-active-performance-framework-templates.component.html',
  styleUrls: ['./list-active-performance-framework-templates.component.css']
})
export class ListActivePerformanceFrameworkTemplatesComponent implements OnInit {
 
  selectedParameter: any;
  frameworkStatus: any;
  allTemplates: any;
  jsonArray: any=[];
  displayedColumns: any=[];
  dataSource:any=[];
  selectedTemplateIds: any = [];
  anyOneSelected: boolean = false;
  jsonArrayPopup: any=[];
  displayedColumnsPopup: any=[];
  dataSourcePopUp: any=[];
  templateDetails: any;
  templateIndicatorDetailsArray: any;
  createdDate: any;
  indFlag: boolean;
  companyTypeName: any;
  templateName: any;
  basicTemplatedetails:any =[];
  parameterId:any;
  levelId:any;
  levelName:any;
  frameworkId:any;
  paginator:any;
  paginatorPopup:any;
  @ViewChild('matSort') sort: MatSort;
  @ViewChild('MatPaginator') set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  setDataSourceAttributes() {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
  }

  @ViewChild('matSort1') sort1: MatSort;
  @ViewChild('MatPaginator1') set matPaginator1(mp: MatPaginator) {
    this.paginatorPopup = mp;
    this.setDataSourceAttributes1();
  }
  setDataSourceAttributes1() {
      this.dataSourcePopUp.paginator = this.paginatorPopup;
      this.dataSourcePopUp.sort = this.sort1;
  }
 
  constructor(private performanceFrameworkHigheService: PerformanceFrameworkHigherService, private router:Router,private activatedRoute: ActivatedRoute) 
  { 
    this.activatedRoute.queryParams.subscribe(params => {
      this.parameterId = params.parameterId,this.levelId = params.levelId,this.frameworkId=params.frameworkId,this.levelName = params.name,this.frameworkStatus=params.frameworkStatus,this.selectedParameter=params.selectedParameter;
      
    }) 
  }

  ngOnInit() 
  {
   
    let templateRequestVO={};
    templateRequestVO['activetemplateDetails'] = true;
    this.performanceFrameworkHigheService.getTemplateDetails(templateRequestVO).subscribe(data => {
    this.allTemplates = data; 
    this.dataTablePopulation(this.allTemplates);  
    }, (error) => {
      console.log(error);

    });

  }

  dataTablePopulation(templates) 
  {
    this.displayedColumns = [];
    let templateList=[];
    let indicatorList=[];
    if (templates) 
    {
      indicatorList = templates.indicatorList;
      templateList = templates.templateList;
    } 
    else
    this.displayedColumns = [];
    this.jsonArray = [];
    for (let template of templateList) 
    {   
        var newArray = {};
        newArray['id'] = template.id;
        if(template.companyTypeVO)
        {
           newArray['CompanyType'] = template.companyTypeVO.name;
        }
        else
        {
           newArray['CompanyType'] = '-';
        }
        newArray['PerformanceTemplate'] = template.name;
        newArray['Status'] = template.status;
        newArray['CreatedOn'] = template.createdDate;
        this.jsonArray.push(newArray);
   }
  this.displayedColumns = ['CheckBoxSelection', 'CompanyType', 'PerformanceTemplate', 'CreatedOn']; 
  this.dataSource = [];
  this.dataSource = new MatTableDataSource(this.jsonArray);

 }

  toggleCheckBox(templateId){
    return (this.selectedTemplateIds.indexOf(templateId) != -1) ? true : false;
  }

 getSelectedTemplate(event, templateId)
  {
   
      if(event.checked) 
      {
        this.selectedTemplateIds.push(templateId);
      } 
      else
      {
        let index = this.selectedTemplateIds.indexOf(templateId);
        this.selectedTemplateIds.splice(index,1);
      }
       if(this.selectedTemplateIds && this.selectedTemplateIds.length>0)
      {
          this.anyOneSelected=true;
      }
      else
      {
        this.anyOneSelected=false;
      }
}
copyTemplate(parameterId,levelId)
{


 let parameterDetails = {};
 parameterDetails['parameterId'] = parameterId;
 parameterDetails['levelId'] = levelId;
 parameterDetails['frameworkId'] = this.frameworkId;
 parameterDetails['name'] = this.levelName;
 parameterDetails['selectedTemplates'] = this.selectedTemplateIds;
 parameterDetails['frameworkStatus']=this.frameworkStatus;
 parameterDetails['selectedParameter']=this.selectedParameter;
 this.router.navigate(['/performanceFrameworkIndicators'], { queryParams: parameterDetails, skipLocationChange: true });



}

getDetails(id, createdDate, companyTypeName, status) {

  let templateRequestVO={};
  templateRequestVO['templateDetailsByID'] = true;
  templateRequestVO['templateId'] = id;
  this.performanceFrameworkHigheService.getTemplateDetails(templateRequestVO).subscribe(data => {
  this.templateDetails = data;
  this.dataTableDetailsForPopup(this.templateDetails, createdDate, companyTypeName, status)

   }, (error) => {
    console.log(error);

  });
}
applyFilter(filterValue: string) 
{
  filterValue = filterValue.trim();
  filterValue = filterValue.toLowerCase();
  this.dataSource.filter = filterValue;
 

}
applyFilterPopup(filterValue: string) {
  filterValue = filterValue.trim();
  filterValue = filterValue.toLowerCase();
  this.dataSourcePopUp.filter = filterValue;

 
}
dataTableDetailsForPopup(templateDetails, createdDate, companyTypeName, status) 
{
  
  if(status=='A')
  {
     status = 'Active';
  }
  else if(status=='D')
  {
     status = 'Draft';
  }
  else
  {
     status = 'Archived';
  }
  this.basicTemplatedetails=[];
  this.basicTemplatedetails['name'] =templateDetails.templateVO.name;
  this.basicTemplatedetails['companytype'] = companyTypeName;
  this.basicTemplatedetails['status'] = status;
  this.basicTemplatedetails['date'] = createdDate;
  this.templateIndicatorDetailsArray = templateDetails.templateVO.indicatorVOList;
	//data table for indicators
   this.displayedColumnsPopup = [];
   this.jsonArrayPopup = [];
  for (let indicator of this.templateIndicatorDetailsArray) {
    var inddetailsArray = {};
    inddetailsArray['indicatorCode'] = indicator.code;
    inddetailsArray['indicatorName'] = indicator.name;
    inddetailsArray['indicatorType'] = indicator.indicatorType.value;
    if(indicator.indicatorDetailsVO.unitOfMeasurementVO)
    {
      inddetailsArray['RatingScaleOrUnit'] = indicator.indicatorDetailsVO.unitOfMeasurementVO.code+"-"+indicator.indicatorDetailsVO.unitOfMeasurementVO.name;
    }
    else if(indicator.indicatorDetailsVO.ratingGroupVO)
    {
      inddetailsArray['RatingScaleOrUnit'] = indicator.indicatorDetailsVO.ratingGroupVO.name;
    }
    else
    {
      inddetailsArray['RatingScaleOrUnit'] = "-";
    }
    
    if (indicator.indicatorConfigurationVO.frequency== null)
    inddetailsArray['frequencyOfMeasurement'] = null;    
    else
    inddetailsArray['frequencyOfMeasurement'] = indicator.indicatorConfigurationVO.frequency.value;
   if(indicator.indicatorDetailsVO!=null)  
   {
      if(indicator.indicatorDetailsVO.mandatory==1)
      {
        inddetailsArray['mandatoryFlag'] = 'Yes';
      }     
      else
      {  
        inddetailsArray['mandatoryFlag'] = 'No';
      }
      if(indicator.indicatorDetailsVO.critical==1)  
      {
        inddetailsArray['criticalFlag'] = 'Yes';
      } 
      else
      { 
        inddetailsArray['criticalFlag'] = 'No';
      }
  }
  if (indicator.indicatorConfigurationVO.source== null)  
  inddetailsArray['source'] = null;
  else
  inddetailsArray['source'] = indicator.indicatorConfigurationVO.source.value;
  this.jsonArrayPopup.push(inddetailsArray);
  }

  this.displayedColumnsPopup = ['indicatorCode', 'indicatorName', 'indicatorType','RatingScaleOrUnit', 'frequencyOfMeasurement', 'mandatoryFlag', 'criticalFlag', 'source']
  this.dataSourcePopUp=[];
  this.dataSourcePopUp = new MatTableDataSource(this.jsonArrayPopup);


}


}
