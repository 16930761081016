import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidationsService } from '../../../../shared/Validations/custom-validations.service';
import { BaseUrlService } from '../../../../shared/base-url.service';
import { AddEditSuccessComponent } from '../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { SearchIndicatorCommonComponent } from '../../../search-indicator-common/search-indicator-common.component';
import { GenerateSchedulesService } from '../../generate-schedules.service';
import { PerformanceFrameworkHigherService } from '../performance-framework-higher.service';


@Component({
  selector: 'app-add-framework-indicators',
  templateUrl: './add-framework-indicators.component.html',
  styleUrls: ['./add-framework-indicators.component.css']
})
export class AddFrameworkIndicatorsComponent implements OnInit {

  baselineValueFlag: boolean;
  baselineYearList: any;
  baselineFormGroup: FormGroup;
  formulaTypeFlag: string;
  currentYear: boolean;
  valueList: any = [];

  unitOfMeasurement: String = "";
  selectedParameter: any;
  frameworkStatus: any;
  selectedTemplates: any = [];
  response: any;
  frequencyList: any;
  indicatorConfigurationVO: any;
  indicatorVOList: any[];
  jsonArray: any = [];
  webservice: any;
  invalid: boolean;
  scheduleForm: any = {};
  finalTargetFormGroup: FormGroup;
  indRatingFlag: boolean;
  addScheduleForm: FormGroup;
  schedulesList: any;
  viewWebservice: boolean;
  viewSchedule: boolean = false;
  indicatorSelected: any = {};
  webserviceForm: FormGroup;
  endYearList: any;
  startYearList: any;
  dateList: any;
  endDateList: any;
  flag: boolean = true;
  my_Class7: string;
  my_Class4: string;
  my_Class5: string;
  my_Class = 'col-lg-12';
  indTable: string = "mat-table mat-table-padding";
  currentRowId: any;
  sourceList: any;
  users: any;
  isWebservice = [];
  displayedColumns: string[];
  frameworkIndicatorFormGroup: any = {};
  isAligned = [];
  performanceFramework: any;
  dataSource: any = [];
  levelName: any;
  frameworkId: any;
  levelId: any;
  parameterId: any;
  public yearSelect: boolean = false;
  selectFlag: boolean = false;
  public basePlaceholder = "Baseline Value";
  @ViewChild("addScheduleFormRef") addScheduleFormRef;
  @ViewChild("webServiceFormRef") webServiceFormRef;
  @ViewChild("baseModal") baseModal: ElementRef;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('baseChildren') baseChildren: ElementRef;
  public loading = false;
  constructor(private renderer: Renderer2, private elementRef: ElementRef, private baseUrlService: BaseUrlService, private generateSchedulesService: GenerateSchedulesService, private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private higherLevelService: PerformanceFrameworkHigherService, private router: Router, private dialog: MatDialog, private addEditSuccessComponent: AddEditSuccessComponent, private customValidationsService: CustomValidationsService) {
    this.renderer.listen('window', 'click', (e: Event) => {

      if (this.toggleButton) {
        if (e.target !== this.toggleButton.nativeElement && e.target !== this.baseModal.nativeElement) {
          if ((e.srcElement && e.srcElement.classList.contains("baseChildren")) == false 
          && this.selectFlag == false && document.getElementById("baseline-modal")) {
            document.getElementById("baseline-modal").classList.remove("base-display");
          }
        }
        this.selectFlag = false;
      }
    });
    this.activatedRoute.queryParams.subscribe(params => {

      this.parameterId = params.parameterId, this.levelId = params.levelId, this.frameworkId = params.frameworkId, this.levelName = params.name, this.selectedParameter = params.selectedParameter;
      this.frameworkStatus = params.frameworkStatus;
      if (params.selectedTemplates)
        this.selectedTemplates = params.selectedTemplates;

    })
  }

  // @ViewChild(MatPaginator) paginator: MatPaginator;

  // @ViewChild(MatSort) sort: MatSort;
  paginator: any;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.viewSchedule == true || this.viewWebservice == true) {
      this.selectedRow();
    }
  }
  @ViewChild(MatSort) sort: MatSort;
  ngOnInit() {
    let sourceRequestVO = {};
    let frequencyRequestVO = {};

    this.getUsersList();
    sourceRequestVO = this.generateSchedulesService.getSourceRequestVO();

    this.generateSchedulesService.getIndicatorMasterData(sourceRequestVO).subscribe(data => {
      this.sourceList = data.constantsVOList;
    });

    frequencyRequestVO = this.generateSchedulesService.getFrequencyRequestVO();
    this.generateSchedulesService.getIndicatorMasterData(frequencyRequestVO).subscribe(data => {
      this.frequencyList = data.constantsVOList;
    });


    if (this.frameworkId > 0 && this.frameworkStatus != "R") {

      this.higherLevelService.fetchPerformanceFrameworkDetailsById(this.frameworkId).subscribe(data => {
        this.performanceFramework = data;
        this.performanceFramework.indicatorVOList.forEach(indicator => {
          this.frameworkIndicatorFormGroup[indicator.id] = this.createIndicatorDetailsFormGroup(indicator);
          this.isAligned[indicator.id] = indicator.indicatorConfigurationVO.aligned;
          if (this.isAligned[indicator.id] == 'Y') {
            this.frameworkIndicatorFormGroup[indicator.id].get('source').disable();
            this.frameworkIndicatorFormGroup[indicator.id].get('responsibleUser').disable();
          }

          else {
            this.frameworkIndicatorFormGroup[indicator.id].get('source').enable();
            this.frameworkIndicatorFormGroup[indicator.id].get('responsibleUser').enable();
          }
          if (indicator.indicatorConfigurationVO.aligned == 'Y')
            this.frameworkIndicatorFormGroup[indicator.id].controls['aligned'].setValue(true);
          else
            this.frameworkIndicatorFormGroup[indicator.id].controls['aligned'].setValue(false);
          if (indicator.indicatorConfigurationVO != null && indicator.indicatorConfigurationVO.source != null) {

            this.frameworkIndicatorFormGroup[indicator.id].controls['source'].setValue(indicator.indicatorConfigurationVO.source);
            this.isWebservice[indicator.id] = indicator.indicatorConfigurationVO.source.code;
          }

          this.frameworkIndicatorFormGroup[indicator.id].controls['responsibleUser'].setValue(indicator.indicatorConfigurationVO.responsibleUser);

        })

        this.fetchDataTable(this.performanceFramework.indicatorVOList);

      })

    }
    else {

      this.jsonArray = [];
      this.performanceFramework = {};
      this.performanceFramework['frameworkId'] = 0;
      this.performanceFramework['indicatorVOList'] = [];
      this.getSelectedTemplatesIndicators();

    }
  }
  getSelectedTemplatesIndicators() {
    if (this.selectedTemplates.length > 0) {
      let templateRequestVO = {};
      templateRequestVO['selectedTemplateIndicators'] = true;
      templateRequestVO['templateIds'] = this.selectedTemplates;
      this.higherLevelService.getTemplateDetails(templateRequestVO).subscribe(data => {
        //for each indicator           
        data.indicatorList.forEach(indicator => {

          this.frameworkIndicatorFormGroup[indicator.id] = this.createIndicatorDetailsFormGroup(indicator);
          let indicatorConfigurationVO = {};
          let source = {};
          source['id'] = 0;
          indicatorConfigurationVO['source'] = source;
          indicator['indicatorConfigurationVO'] = indicatorConfigurationVO;
          this.performanceFramework.indicatorVOList.push(indicator);
        });

        this.fetchDataTable(this.performanceFramework.indicatorVOList);

      });
    }
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    if (this.dataSource.filteredData.length == 0) {
      this.flag = true;
    } else {
      this.flag = false;
    }
  }

  createIndicatorDetailsFormGroup(indicator) {
    return this.formBuilder.group({
      id: [indicator.id],
      aligned: [],
      source: ['', [Validators.required]],
      responsibleUser: ['', [Validators.required]],
    })
  }
  fetchDataTable(indicatorVOList) {
    this.displayedColumns = [];
    this.jsonArray = [];

    for (let indicator of indicatorVOList) {
      var newArray = {};
      newArray['indicatorCode'] = indicator.code;
      newArray['indicatorName'] = indicator.name;
      if (indicator.subcategoryVO != null)
        newArray['performanceArea'] = indicator.subcategoryVO.name;
      if (indicator.indicatorType != null)
        newArray['indicatorTypeId'] = indicator.indicatorType.id;
      if (indicator.indicatorConfigurationVO != null) {
        newArray['aligned'] = indicator.indicatorConfigurationVO.aligned;
        newArray['source'] = indicator.indicatorConfigurationVO.source;
        newArray['responsibleUser'] = indicator.indicatorConfigurationVO.responsibleUser;
        if (indicator.indicatorDetailsVO.mandatory == 1)
          newArray['mandatory'] = true
        else
          newArray['mandatory'] = false;
        if (indicator.indicatorDetailsVO.critical == 1)
          newArray['critical'] = true
        else
          newArray['critical'] = false;
        newArray['frameworkIndicatorDetailId'] = indicator.indicatorConfigurationVO.frameworkIndicatorDetailId;

      }
      newArray['schedule'] = '';
      newArray['actions'] = '';
      newArray['id'] = indicator.id;


      this.jsonArray.push(newArray);
    }

    this.refreshDataTableDataSource();

  }
  refreshDataTableDataSource() {
    if (this.jsonArray.length > 0)
      this.flag = false;
    else
      this.flag = true;
    this.displayedColumns = ['mandatory', 'indicatorCode', 'indicatorName', 'performanceArea', 'aligned', 'source', 'responsibleUser', 'schedule', 'actions'];
    this.dataSource = [];
    this.dataSource = new MatTableDataSource(this.jsonArray);
    this.setDataSourceAttributes();

  }
  deleteIndicator(indicator) {
    var confirmMessage = "You Want To Delete This Indicator From Framework";
    this.addEditSuccessComponent.showConfirmationSwal(confirmMessage, 'Yes', 'No').then((isConfirm) => {
      if (isConfirm.value) {
        this.paginator.pageIndex = 0;
        if (this.frameworkId > 0 && indicator.frameworkIndicatorDetailId > 0) {
          this.higherLevelService.deleteFrameworkIndicator(indicator.frameworkIndicatorDetailId).subscribe(data => {
            const index = this.jsonArray.indexOf(this.jsonArray.find(x => x.id == indicator.id), 0);
            if (index > -1) {
              this.jsonArray.splice(index, 1);
              this.performanceFramework.indicatorVOList = this.performanceFramework.indicatorVOList.filter(x => x.id != indicator.id);
              this.refreshDataTableDataSource();
            }

            this.isWebservice.splice(indicator.id, 1);

          });
        }
        else {
          const index = this.jsonArray.indexOf(this.jsonArray.find(x => x.id == indicator.id), 0);
          if (index > -1) {
            this.jsonArray.splice(index, 1);
            this.performanceFramework.indicatorVOList = this.performanceFramework.indicatorVOList.filter(x => x.id != indicator.id);
            this.refreshDataTableDataSource();
          }
        }

      }
    });

    this.toggle_class('otherSource');
  }

  isAlignedClick(event, id) {
    if (event.checked) {
      this.isAligned[id] = true;
      this.frameworkIndicatorFormGroup[id].get('source').disable();
      this.frameworkIndicatorFormGroup[id].get('responsibleUser').disable();
    }
    else {
      this.isAligned[id] = false;
      this.frameworkIndicatorFormGroup[id].get('source').enable();
      this.frameworkIndicatorFormGroup[id].get('responsibleUser').enable();
    }
  }
  getUsersList() {
    let masterRequestVO;
    masterRequestVO = {
      'userList': true
    };
    this.baseUrlService.getListOfUsers(masterRequestVO).subscribe(data => {
      this.response = data;
      this.users = this.response.userVOList;
    });
  }

  getRowId(rowId) {
    this.currentRowId = rowId;

  }
  //function to change the class from style1 to style2 when clicked
  toggle_class(toggleFlg: any) {

    if (toggleFlg == "webservice" || toggleFlg == "schedule") {
      if (this.my_Class == "col-lg-12") {
        this.my_Class = "col-lg-8 col-md-8";
        this.my_Class4 = "col-lg-4 col-md-4 no-pad-right";
        this.my_Class5 = "col-lg-4 col-md-4 no-pad-right";
        this.my_Class7 = "ind-table-wrap";
        this.indTable = "mat-table";
      }
    }
    else {
      this.my_Class = "col-lg-12";
      this.my_Class5 = "display-none";
      this.my_Class4 = "display-none";
      this.indTable = "mat-table-padding mat-table";
      let indDivs = document.querySelectorAll('.selected-arrow');
      if (indDivs.length) {
        for (let i = 0; i < indDivs.length; i++) {
          indDivs[i].classList.remove('selected-arrow');
        }
      }
    }
  }
  updatePerformanceFrameworkForIndicator(indicator, saveStatus) {
    this.unitOfMeasurement = "";
    this.loading = true;
    if (this.addScheduleFormRef) {
      this.addScheduleFormRef.resetForm();
    }
    let yearList = this.generateSchedulesService.getYearList();
    this.startYearList = yearList;
    this.endYearList = yearList;
    this.indicatorVOList = [];
    if (this.frameworkIndicatorFormGroup[indicator.id].value.source && this.frameworkIndicatorFormGroup[indicator.id].value.source.code == "WS") {
      this.frameworkIndicatorFormGroup[indicator.id].get('responsibleUser').setValidators(null);
      this.frameworkIndicatorFormGroup[indicator.id].get('responsibleUser').updateValueAndValidity();

    }

    if (this.frameworkIndicatorFormGroup[indicator.id].valid) {
      this.indicatorSelected = this.performanceFramework.indicatorVOList.find(e => e.id === indicator.id);
      //this.selectedRow();

      if (this.indicatorSelected) {

        if (this.frameworkIndicatorFormGroup[this.indicatorSelected.id].controls['aligned'].value)
          this.indicatorSelected.indicatorConfigurationVO.aligned = 'Y';
        else
          this.indicatorSelected.indicatorConfigurationVO.aligned = 'N';
        if (this.frameworkIndicatorFormGroup[this.indicatorSelected.id].controls['source'].value != "")
          this.indicatorSelected.indicatorConfigurationVO.source = this.frameworkIndicatorFormGroup[this.indicatorSelected.id].controls['source'].value;
        //if(this.frameworkIndicatorFormGroup[this.indicatorSelected.id].controls['responsibleUser'].value!="")
        this.indicatorSelected.indicatorConfigurationVO.responsibleUser = this.frameworkIndicatorFormGroup[this.indicatorSelected.id].controls['responsibleUser'].value;
        this.indicatorVOList.push(this.indicatorSelected);
        this.viewSchedule = true;
        this.viewWebservice = false;
        this.toggle_class('schedule');
        this.schedulesList = null;
        this.addScheduleForm = this.createScheduleGenerationFormGroup();
        if (this.indicatorSelected.indicatorType && this.indicatorSelected.indicatorType.code == "QU") {
          if (this.indicatorSelected.indicatorDetailsVO && this.indicatorSelected.indicatorDetailsVO.unitOfMeasurementVO) {
            this.unitOfMeasurement = this.indicatorSelected.indicatorDetailsVO.unitOfMeasurementVO.code;
            this.unitOfMeasurement = "(" + this.unitOfMeasurement + ")";
          }

        }

        if (!this.indicatorSelected.indicatorConfigurationVO.intermediateTarget) {

          this.finalTargetFormGroup = this.createFinalTargetFormGroup();

          if (this.indicatorSelected.indicatorConfigurationVO.target == 'M') {
            if (this.indicatorSelected.indicatorType.code == "QR")//qualitative
            {
              this.finalTargetFormGroup.controls['finalTarget'].setValidators([Validators.required]);
            }

            else if (this.indicatorSelected.indicatorType.code == "QT")//textual
            {
              this.finalTargetFormGroup.controls['finalTarget'].setValidators([Validators.required, this.customValidationsService.noWhitespaceValidator]);
            }

            else//quantitative
            {
              this.finalTargetFormGroup.controls['finalTarget'].setValidators([Validators.required, this.customValidationsService.noWhitespaceValidator, Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)]);

            }
          }
          else {
            if (this.indicatorSelected.indicatorType.code == "QU")
              this.finalTargetFormGroup.controls['finalTarget'].setValidators([Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)]);

          }
        }

      }
      this.createOrUpdatePerformanceFramework(true, saveStatus);

    }
    else {
      this.loading = false;
      if (this.frameworkIndicatorFormGroup[indicator.id].value.source == "" && !this.frameworkIndicatorFormGroup[indicator.id].value.aligned) { this.frameworkIndicatorFormGroup[indicator.id].controls['source'].setValue(''); this.frameworkIndicatorFormGroup[indicator.id].get('source').markAsTouched(); }
      if (this.frameworkIndicatorFormGroup[indicator.id].value.responsibleUser == "" && !this.frameworkIndicatorFormGroup[indicator.id].value.aligned && this.frameworkIndicatorFormGroup[indicator.id].value.source.code != "WS") { this.frameworkIndicatorFormGroup[indicator.id].controls['responsibleUser'].setValue(''); this.frameworkIndicatorFormGroup[indicator.id].get('responsibleUser').markAsTouched(); }
      this.toggle_class("otherSource")
    }
  }

  createOrUpdatePerformanceFramework(clickFromSchedule: boolean, saveStatus) {
    this.invalid = false;
    let parameterDataVO = {};
    parameterDataVO['id'] = this.levelId;
    parameterDataVO['name'] = this.levelName;
    let parameterVO = {};
    parameterVO['id'] = this.parameterId;
    parameterDataVO['parameterVO'] = parameterVO;

    if (!clickFromSchedule) {
      if (this.performanceFramework != null && this.performanceFramework.indicatorVOList.length == 0) {
        this.invalid = true;
        var message = "Please Add Indicators For The Framework";
        this.addEditSuccessComponent.showFailedSwal(message, 'Failed');
      }
      else {
        if (this.frameworkStatus != "R")
          this.performanceFramework.frameworkId = this.frameworkId;
        this.performanceFramework.frameworkStatus = saveStatus;
        parameterDataVO['performanceFrameworkVO'] = this.performanceFramework;
        this.performanceFramework.indicatorVOList.forEach(indicator => {

          if (saveStatus == 'A') {
            if (this.frameworkIndicatorFormGroup[indicator.id].value.source == 0 && !this.frameworkIndicatorFormGroup[indicator.id].value.aligned) {
              this.frameworkIndicatorFormGroup[indicator.id].controls['source'].setValue('');
              this.frameworkIndicatorFormGroup[indicator.id].get('source').markAsTouched();
              this.invalid = true;
            }

            if (this.frameworkIndicatorFormGroup[indicator.id].value.responsibleUser == "" && !this.frameworkIndicatorFormGroup[indicator.id].value.aligned && this.frameworkIndicatorFormGroup[indicator.id].value.source.code != "WS") {
              this.frameworkIndicatorFormGroup[indicator.id].controls['responsibleUser'].setValue('');
              this.frameworkIndicatorFormGroup[indicator.id].get('responsibleUser').markAsTouched();
              this.invalid = true;
            }
          }
          if (this.frameworkIndicatorFormGroup[indicator.id].controls['aligned'].value)
            indicator.indicatorConfigurationVO.aligned = 'Y';
          else
            indicator.indicatorConfigurationVO.aligned = 'N';
          indicator.indicatorConfigurationVO.responsibleUser = this.frameworkIndicatorFormGroup[indicator.id].controls['responsibleUser'].value;

          if (this.frameworkIndicatorFormGroup[indicator.id].controls['source'].value != "")
            indicator.indicatorConfigurationVO.source = this.frameworkIndicatorFormGroup[indicator.id].controls['source'].value;


        })

      }

    }
    else {

      let performanceFrameworkVO = {};
      if (this.frameworkStatus == "R")
        performanceFrameworkVO['frameworkId'] = 0;
      else
        performanceFrameworkVO['frameworkId'] = this.frameworkId;
      performanceFrameworkVO['frameworkStatus'] = saveStatus;
      parameterDataVO['performanceFrameworkVO'] = performanceFrameworkVO;
      performanceFrameworkVO['indicatorVOList'] = this.indicatorVOList;
      //first time  save by clicking schedule
      if (this.frameworkStatus == "A") {
        let additionalIndicators = this.performanceFramework.indicatorVOList.filter(obj => obj.id != this.indicatorVOList[0].id);
        additionalIndicators.forEach(indicator => {
          performanceFrameworkVO['indicatorVOList'].push(indicator);
        });

      }


    }
    if (!this.invalid) {

      this.higherLevelService.submitPerformanceFramework(parameterDataVO).subscribe(data => {
        if (clickFromSchedule) {
          let newFrameworkDetails: any = data;
          this.frameworkId = newFrameworkDetails.frameworkId;


          let indicatorId = this.indicatorSelected.id;
          this.indicatorSelected = newFrameworkDetails.indicatorVOList.find(e => e.id === indicatorId);
          if (this.indicatorSelected.indicatorType.code == "QR") this.indRatingFlag = true;
          else this.indRatingFlag = false;
          //Active f/w first time edit
          if (this.frameworkStatus == "A") {
            // this.fetchDataTable(newFrameworkDetails.indicatorVOList);
            this.performanceFramework = newFrameworkDetails;
          }
          else {
            //////
            const index = this.performanceFramework.indicatorVOList.indexOf(this.performanceFramework.indicatorVOList.find(x => x.id == this.indicatorVOList[0].id), 0);
            if (index > -1) {

              this.performanceFramework.indicatorVOList.splice(index, 1, this.indicatorSelected);
            }
            //////
            //this.performanceFramework.indicatorVOList = this.performanceFramework.indicatorVOList.filter(obj => obj.id != this.indicatorVOList[0].id);
            // this.performanceFramework.indicatorVOList.push(this.indicatorSelected);
            this.fetchDataTable(this.performanceFramework.indicatorVOList);


          }

          this.frameworkStatus = newFrameworkDetails.frameworkStatus;
          this.frameworkIndicatorFormGroup[indicatorId].controls['responsibleUser'].setValue(this.indicatorSelected.indicatorConfigurationVO.responsibleUser);
          if (this.indicatorSelected.indicatorConfigurationVO.indicatorStartdate != null) {
            let sYear = new Date(this.indicatorSelected.indicatorConfigurationVO.indicatorStartdate);
            let startYear = Number(sYear.getFullYear());
            this.addScheduleForm.get('startYear').setValue(startYear);
          }
          else
            this.addScheduleForm.get('startYear').setValue('');

          if (this.indicatorSelected.indicatorConfigurationVO.indicatorEnddate != null) {
            let eYear = new Date(this.indicatorSelected.indicatorConfigurationVO.indicatorEnddate);
            let endYear = Number(eYear.getFullYear());
            this.addScheduleForm.get('endYear').setValue(endYear);
          }
          else
            this.addScheduleForm.get('endYear').setValue('');
          if (this.indicatorSelected.indicatorConfigurationVO.frequency != null)
            this.addScheduleForm.get('frequency').setValue(this.indicatorSelected.indicatorConfigurationVO.frequency);
          this.populateDate(this.indicatorSelected.indicatorConfigurationVO.frequency);
          // get schedule details to populate with new id
          if (this.viewSchedule) {
            this.higherLevelService.getSchedulesForIndicator(this.indicatorSelected.indicatorConfigurationVO.frameworkIndicatorDetailId, this.indicatorSelected.indicatorType.id).subscribe(data => {
              this.schedulesList = data;
              let i = 0;
              this.schedulesList.forEach(schedule => {

                this.scheduleForm[i++] = this.createScheduleFormGroup(schedule);
                if (this.indicatorSelected.indicatorConfigurationVO.intermediateTarget) {
                  var abstractControl: AbstractControl = this.scheduleForm[i - 1];

                  if (abstractControl instanceof FormGroup) {

                    (<FormGroup>abstractControl).addControl('targetValue', new FormControl({ value: '' }));
                    if (this.indicatorSelected.indicatorConfigurationVO.target == 'M') {
                      if (this.indicatorSelected.indicatorType.code == "QR")//qualitative
                        this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.required]);
                      else if (this.indicatorSelected.indicatorType.code == "QT")//textual
                        this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.required, this.customValidationsService.noWhitespaceValidator]);
                      else //quantitative case
                        this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.required, this.customValidationsService.noWhitespaceValidator, Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)]);
                    }
                    else {
                      if (this.indicatorSelected.indicatorType.code == "QU")
                        this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)]);
                    }
                    if (this.indRatingFlag)
                      this.scheduleForm[i - 1].controls['targetValue'].setValue(Number(schedule.targetValue));
                    else
                      this.scheduleForm[i - 1].controls['targetValue'].setValue(schedule.targetValue);
                    // if (this.scheduleForm[i - 1].controls['targetValue'].value == 0) { this.scheduleForm[i - 1].controls['targetValue'].setValue(''); }
                  }
                }
                else {
                  this.scheduleForm[i - 1].removeControl('targetValue');
                  if (this.indRatingFlag)
                    this.finalTargetFormGroup.controls['finalTarget'].setValue(Number(this.indicatorSelected.indicatorConfigurationVO.finalTarget));
                  else
                    this.finalTargetFormGroup.controls['finalTarget'].setValue(this.indicatorSelected.indicatorConfigurationVO.finalTarget);

                  if (this.finalTargetFormGroup.controls['finalTarget'].value == 0) { this.finalTargetFormGroup.controls['finalTarget'].setValue(''); }

                }
              })
              this.selectedRow();
            })
          }

          // get schedule details to populate with new id ends
          //get web service details with new detail id
          if (this.viewWebservice) {
            this.higherLevelService.getWebserviceForIndicator(this.indicatorSelected.indicatorConfigurationVO.frameworkIndicatorDetailId).subscribe(data => {
              this.indicatorConfigurationVO = data;
              this.webservice = this.indicatorConfigurationVO.webServiceVO;
              if (this.webservice != null && this.webservice.webserviceParameterVOList != null) {
                if (this.webservice.webserviceParameterVOList.length > 0) {
                  this.deleteRow(0);
                }
                this.webservice.webserviceParameterVOList.forEach(parameter => {
                  this.addNewRow();
                })
                this.webserviceForm.patchValue(this.webservice);
              }
              this.selectedRow();
            })
          }

          //get web service details with new detail id ends
          this.loading = false;
        }
        else {

          var message;
          if (saveStatus == 'D')
            message = "Successfully Saved Performance Framework";
          else
            message = "Successfully submitted Performance Framework";
          this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
            if (saveStatus == 'A') {
              let selectedParameterDetails = {};
              selectedParameterDetails['selectedParameter'] = this.selectedParameter;
              this.router.navigate(['/performanceFrameworkHigherLevel'], { queryParams: selectedParameterDetails, skipLocationChange: true });
            }
            else {
              this.higherLevelService.fetchPerformanceFrameworkDetailsById(this.frameworkId).subscribe(data => {
                this.performanceFramework = data;
                this.fetchDataTable(this.performanceFramework.indicatorVOList);
              });
            }


          });

        }
      });
      //this.loading=false;
    }


  }
  showIndicatorPopup() {
    this.viewSchedule = false;
    this.viewWebservice = false;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = "80%";
    dialogConfig.data = { 'nonMandatoryFlag': false };

    const dialogRef = this.dialog.open(SearchIndicatorCommonComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {

      if (data != undefined) {

        for (let indicator of data) {

          var newIndicatorList = {};
          if (!this.jsonArray.find(x => x.id == indicator.id)) {

            newIndicatorList['indicatorCode'] = indicator.code;
            newIndicatorList['indicatorName'] = indicator.name;
            newIndicatorList['performanceArea'] = indicator.performanceArea;
            newIndicatorList['indicatorTypeId'] = indicator.indicatorTypeId;
            newIndicatorList['aligned'] = false;
            newIndicatorList['source'] = null;
            newIndicatorList['responsibleUser'] = '';
            newIndicatorList['mandatory'] = indicator.mandatory;
            newIndicatorList['critical'] = indicator.critical;
            newIndicatorList['schedule'] = '';
            newIndicatorList['actions'] = '';
            newIndicatorList['id'] = indicator.id;


            this.frameworkIndicatorFormGroup[indicator.id] = this.createIndicatorDetailsFormGroup(indicator);
            let indicatorVO = {};
            indicatorVO['code'] = indicator.code;
            indicatorVO['name'] = indicator.name;
            indicatorVO['id'] = indicator.id;
            let indicatorType = {};
            indicatorType['id'] = indicator.indicatorTypeId;
            indicatorVO['indicatorType'] = indicatorType;
            let indicatorConfigurationVO = {};
            let source = {};
            source['id'] = 0;
            indicatorConfigurationVO['source'] = source;

            let indicatorDetailsVO = {};
            if (indicator.mandatory)
              indicatorDetailsVO['mandatory'] = 1;
            else
              indicatorDetailsVO['mandatory'] = 2;
            if (indicator.critical)
              indicatorDetailsVO['critical'] = 1;
            else
              indicatorDetailsVO['critical'] = 2;
            indicatorVO['indicatorDetailsVO'] = indicatorDetailsVO;
            indicatorVO['indicatorConfigurationVO'] = indicatorConfigurationVO;

            this.performanceFramework.indicatorVOList.push(indicatorVO);

            this.jsonArray.push(newIndicatorList);
          }
        }
      }
      this.refreshDataTableDataSource();

    });

  }

  createScheduleGenerationFormGroup() {
    return this.formBuilder.group({
      frequency: ['', Validators.required],
      startYear: ['', Validators.required],
      endYear: ['', Validators.required],
    })
  }
  createScheduleFormGroup(schedule) {
    return this.formBuilder.group({
      scheduleStartDate: [schedule.scheduleStartDate],
      scheduleEndDate: [schedule.scheduleEndDate],

    })
  }
  populateDate(frequencyOfMeasurement) {

    this.scheduleForm = {};
    this.schedulesList = null;
    if (frequencyOfMeasurement)
      this.dateList = this.generateSchedulesService.getDateList(frequencyOfMeasurement.code);
    else
      this.dateList = null;
    this.endDateList = this.dateList;

    if (this.dateList != null) {
      let startFrequency = this.indicatorSelected.indicatorConfigurationVO.start.toString();
      let endFrequency = this.indicatorSelected.indicatorConfigurationVO.end.toString();

      var abstractControl: AbstractControl = this.addScheduleForm;
      if (abstractControl instanceof FormGroup) {
        (<FormGroup>abstractControl).addControl('start', new FormControl({ value: '' }, Validators.required));
        (<FormGroup>abstractControl).addControl('end', new FormControl({ value: '' }, Validators.required));

      }
      if (this.indicatorSelected.indicatorConfigurationVO.frequency != null) {
        if (frequencyOfMeasurement.id == this.indicatorSelected.indicatorConfigurationVO.frequency.id) {
          this.addScheduleForm.controls['start'].setValue(startFrequency);
          this.addScheduleForm.controls['end'].setValue(endFrequency);

        }
        else {
          this.addScheduleForm.controls['start'].setValue('');
          this.addScheduleForm.controls['end'].setValue('');
        }

      }
      else {
        this.addScheduleForm.controls['start'].setValue('');
        this.addScheduleForm.controls['end'].setValue('');
      }

    }
    else {
      this.addScheduleForm.removeControl('start');
      this.addScheduleForm.removeControl('end');
    }
  }
  generateSchedules() {
    this.scheduleForm = {};
    this.schedulesList = null;

    if (this.addScheduleForm.valid) {
      let startDateVal = Number(this.addScheduleForm.value.start);
      let endDateVal = Number(this.addScheduleForm.value.end);
      if (this.addScheduleForm.value.startYear > this.addScheduleForm.value.endYear || (this.addScheduleForm.value.startYear == this.addScheduleForm.value.endYear && endDateVal < startDateVal)) {
        var failMessage = " Please Select Valid Date Periods";
        this.addEditSuccessComponent.showFailedSwal(failMessage, 'Failed');
      }
      else {
        this.generateSchedulesService.generateSchedules(this.addScheduleForm.value).subscribe(data => {
          let indicatorConfigurationVO: any = data;
          this.schedulesList = indicatorConfigurationVO.scheduleVOList;
          let i = 0;
          this.schedulesList.forEach(schedule => {
            this.scheduleForm[i++] = this.createScheduleFormGroup(schedule);
            if (this.indicatorSelected.indicatorConfigurationVO.intermediateTarget) {

              var abstractControl: AbstractControl = this.scheduleForm[i - 1];

              if (abstractControl instanceof FormGroup) {

                (<FormGroup>abstractControl).addControl('targetValue', new FormControl({ value: '' }));
                if (this.indicatorSelected.indicatorConfigurationVO.target == 'M') {
                  if (this.indicatorSelected.indicatorType.code == "QR")//qualitative
                    this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.required]);
                  else if (this.indicatorSelected.indicatorType.code == "QT")//textual
                    this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.required, this.customValidationsService.noWhitespaceValidator]);
                  else //quantitative case
                    this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.required, this.customValidationsService.noWhitespaceValidator, Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)]);
                }
                else {
                  if (this.indicatorSelected.indicatorType.code == "QU") {
                    if (this.indicatorSelected.indicatorDetailsVO && this.indicatorSelected.indicatorDetailsVO.unitOfMeasurementVO) {
                      this.unitOfMeasurement = this.indicatorSelected.indicatorDetailsVO.unitOfMeasurementVO.code;
                      this.unitOfMeasurement = "(" + this.unitOfMeasurement + ")";
                    }

                    this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)]);
                  }

                }
                if (this.indRatingFlag)
                  this.scheduleForm[i - 1].controls['targetValue'].setValue(Number(schedule.targetValue));
                else
                  this.scheduleForm[i - 1].controls['targetValue'].setValue(schedule.targetValue);
                if (this.scheduleForm[i - 1].controls['targetValue'].value == 0) { this.scheduleForm[i - 1].controls['targetValue'].setValue(''); }
              }
            }
          })
        })
      }

    }


  }
  saveIndicatorSchedule() {
    this.invalid = false;
    let i = 0;
    if (!this.indicatorSelected.indicatorConfigurationVO.intermediateTarget) {
      if (!this.finalTargetFormGroup.valid) {
        this.invalid = true;
        this.finalTargetFormGroup.get('finalTarget').markAsTouched();
      }
      if (this.finalTargetFormGroup.value.finalTarget != "")
        this.indicatorSelected.indicatorConfigurationVO.finalTarget = this.finalTargetFormGroup.value.finalTarget;
    }
    if (this.indicatorSelected.indicatorConfigurationVO.intermediateTarget) {
      this.schedulesList.forEach(schedule => {
        schedule.targetValue = this.scheduleForm[i++].value.targetValue;
        if (!this.scheduleForm[i - 1].valid) {
          this.invalid = true;
          this.scheduleForm[i - 1].get('targetValue').markAsTouched();
        }
      })
    }

    this.indicatorSelected.indicatorConfigurationVO.scheduleVOList = this.schedulesList;

    this.indicatorSelected.indicatorConfigurationVO.frequencyId = this.addScheduleForm.value.frequency.id;
    this.indicatorSelected.indicatorConfigurationVO.start = this.addScheduleForm.value.start;
    this.indicatorSelected.indicatorConfigurationVO.end = this.addScheduleForm.value.end;

    if (!this.invalid) {
      this.higherLevelService.saveIndicatorSchedules(this.indicatorSelected.indicatorConfigurationVO).subscribe(data => {

        var message = "Successfully saved Indicator Targets against schedules";
        this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
        });
      })
    }
  }

  populateWebservice(source, indicator) {

    this.viewWebservice = false;
    this.toggle_class('otherSource');
    this.isWebservice[indicator.id] = source.code;
    if (source.code == "WS") {
      this.frameworkIndicatorFormGroup[indicator.id].get('responsibleUser').setValidators(null);
      this.frameworkIndicatorFormGroup[indicator.id].get('responsibleUser').updateValueAndValidity();
      this.updatePerformanceFrameworkForIndicator(indicator, 'D');
      this.viewWebservice = true;
      this.viewSchedule = false;
      this.toggle_class('webservice');
      this.indicatorSelected = this.performanceFramework.indicatorVOList.find(e => e.id === indicator.id);
      this.webserviceForm = this.createWebserviceFormGroup();

    }
    else {
      this.frameworkIndicatorFormGroup[indicator.id].get('responsibleUser').setValidators([Validators.required]);
      this.frameworkIndicatorFormGroup[indicator.id].get('responsibleUser').updateValueAndValidity();
      this.toggle_class('otherSource');
    }

  }
  createWebserviceFormGroup() {
    return this.formBuilder.group({

      id: [0],
      url: ['', [Validators.required, this.customValidationsService.noWhitespaceValidator]],
      username: ['', [Validators.required, this.customValidationsService.noWhitespaceValidator]],
      password: ['', [Validators.required, this.customValidationsService.noWhitespaceValidator]],
      webserviceParameterVOList: this.formBuilder.array([this.initParameterRows()])
    })
  }
  initParameterRows() {
    return this.formBuilder.group({
      parameterName: ['', [Validators.required, this.customValidationsService.noWhitespaceValidator]],
      parameterValue: ['', [Validators.required, this.customValidationsService.noWhitespaceValidator]]

    });
  }
  addNewRow() {
    const controls = this.formArray;
    this.formArray.push(this.initParameterRows());
  }

  deleteRow(index: number) {
    this.formArray.removeAt(index);
  }

  get formArray() {
    return this.webserviceForm.get('webserviceParameterVOList') as FormArray;
  }

  submitWebservice() {

    if (this.webserviceForm.valid) {
      let indicatorConfigurationVO = {};
      indicatorConfigurationVO['frameworkIndicatorDetailId'] = this.indicatorSelected.indicatorConfigurationVO.frameworkIndicatorDetailId;
      indicatorConfigurationVO['webServiceVO'] = this.webserviceForm.value;
      this.higherLevelService.submitWebservice(indicatorConfigurationVO).subscribe(data => {
        var message = "Successfully Saved Webservice Configuration";
        this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
        });
        this.webServiceFormRef.resetForm();
        this.toggle_class('otherSource');
      })
    }
  }
  generateYearChange(value) {
    let selectedStartYearIndex = this.startYearList.indexOf(value);
    this.endYearList = this.startYearList.slice(selectedStartYearIndex);
    this.addScheduleForm.get('endYear').reset();

  }

  createFinalTargetFormGroup() {
    return this.formBuilder.group({
      finalTarget: [],

    })
  }
  selectedRow() {
    let indDivs = document.querySelectorAll('.selected-arrow');
    if (indDivs.length) {
      for (let i = 0; i < indDivs.length; i++) {
        indDivs[i].classList.remove('selected-arrow');
      }
    }
    if (this.currentRowId && document.getElementById("indMatRow" + this.currentRowId))
      document.getElementById("indMatRow" + this.currentRowId).classList.add("selected-arrow");
  }
  ascOrder = (a, b) => {
    return Number(a.key) < Number(b.key) ? -1 : 1;
  }
  compareFn(t1, t2): boolean {
    return t1 && t2 ? t1.id === t2.id : t1 === t2;
  }


  baselineConfig() {
    this.baselineFormGroup = this.createBaselineFormGroup();
    let yearList = [];
    this.currentYear = false;
    let currentYear = (new Date()).getFullYear();
    let startYear = currentYear - 10;
    for (let i = startYear; i <= currentYear; i++) {
      yearList.push(String(i));

    }
    yearList.push("CY");
    this.baselineYearList = yearList;
    this.setValueList();
    if (this.indicatorSelected.indicatorConfigurationVO.baselineFormula) {
      this.baselineFormGroup.controls['year'].setValue(this.indicatorSelected.indicatorConfigurationVO.baselineFormula.year);
      if (this.indicatorSelected.indicatorConfigurationVO.baselineFormula.formulaType == "C") {
        this.currentYear = true;
        this.baselineFormGroup.controls['data'].setValue(Number(this.indicatorSelected.indicatorConfigurationVO.baselineFormula.value));
      }
    }
    if (this.indicatorSelected.indicatorConfigurationVO.baseLine) {
      this.baselineValueFlag = true;
      this.baselineFormGroup.controls['baselineValue'].setValue(this.indicatorSelected.indicatorConfigurationVO.baseLine);
    }

  }


  setValueList() {
    for (let i = 1; i <= 10; i++) {
      this.valueList.push(i);
    }

  }
  yearChanged(event, flag) {
    this.selectFlag = true;
    if (flag == 0) {
      this.currentYear = false;
      this.baselineValueFlag = false;
      this.formulaTypeFlag = "D";
      if (event.value == "CY") {
        this.currentYear = true;
        this.formulaTypeFlag = "C";
      }
    }
  }
  baselineSelect(event) {
    if (event.target) {
      event.target.classList.add("baseChildren");
    }
  }
  createBaselineFormGroup() {
    return this.formBuilder.group({
      year: [],
      data: [],
      baselineValue: ['', [Validators.required]]
    })
  }

  saveBaselineConfiguration() {
    this.setBaslineValues();

    this.higherLevelService.saveBaselineForIndicator(this.indicatorSelected.indicatorConfigurationVO).subscribe(data => {

      var message = "Successfully saved BaseLine";
      this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {

      });
    })

  }
  setBaslineValues() {
    var baselineFormula = new Object();
    baselineFormula["formulaType"] = this.formulaTypeFlag;
    baselineFormula["year"] = this.baselineFormGroup.value.year.toString();
    if (this.formulaTypeFlag == "C") {
      baselineFormula["value"] = this.baselineFormGroup.value.data.toString();
      baselineFormula["operator"] = "-";

    }

    this.indicatorSelected.indicatorConfigurationVO.baseLine = this.baselineFormGroup.value.baselineValue;
    this.indicatorSelected.indicatorConfigurationVO.baselineFormula = baselineFormula;
  }
  getBaselineValue() {

    this.setBaslineValues();
    this.higherLevelService.getBaselineValue(this.indicatorSelected).subscribe(data => {
      this.baselineValueFlag = true;
      if (data != null) {
        this.baselineFormGroup.controls['baselineValue'].setValue(data);
      }
      else {

        this.baselineFormGroup.controls['baselineValue'].setValue(null);
        this.basePlaceholder = "Baseline value not available";

      }
    })
  }
  toggleBaseline() {
    if (document.getElementById("baseline-modal")) {
      document.getElementById("baseline-modal").classList.add("base-display");
    }
  }
  changeErrorMsg() {
    this.basePlaceholder = "Baseline Value";
  }
}

