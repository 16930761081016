import { Component, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { MatDialog,MatDialogConfig } from '@angular/material';
import { CompanyService } from '../../company.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { OwlanalyticsRatingAgencyComponent} from '../owlanalytics-rating-agency/owlanalytics-rating-agency.component';
import { SustainanalyticRatingAgencyComponent} from '../sustainanalytic-rating-agency/sustainanalytic-rating-agency.component';
import { SrayRatingAgencyComponent} from '../sray-rating-agency/sray-rating-agency.component';
import { MediaService } from '../../../MediaDashboard/components/media.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-integrated-dashboard',
  templateUrl: './integrated-dashboard.component.html',
  styleUrls: ['./integrated-dashboard.component.css']
})
export class IntegratedDashboardComponent implements OnInit {

  public companyDetails:any;
  public doughnutData: any;
  public doughnutChartType = 'doughnut';
  public chartColors: any;
  public chartOptions: any;
  gaugemap: any = {};
  public company_id:any;
  public overallESGChartColor: Array<any> = [];
  public overallESGData: any = [];
  companyRegionNames: any;
  companySectorNames: any;
  companyCountryNames: any;
  companyName: any;
  companySubSectorNames: any;
  companyIndicatorList: any;
  environmentIndicators: any =[];
  socialIndicators: any =[];
  governanceIndicators: any =[];
  ratingAgencyList: any;
  dialogConfig = new MatDialogConfig();
  environmentVal : any =0 ;
  socialVal : any = 0;
  governanceVal : any =0;
  public sentimentScore:any;
  public newsData: any = [];
  ratingAgencyName: any;
  ratingFlag: number =0;
  ratingAgencyDate:any;
  srayRatingAgencyList: any[];
  yearList:any=[];
  constructor(private companyService:CompanyService,private _router: Router, private activatedRoute: ActivatedRoute,private dialog: MatDialog,private mediaDashboardService: MediaService) {
    this.activatedRoute.params.subscribe(params => { this.company_id = params.id });
   }

  ngOnInit() {
    this.yearList=this.getYearList();
    this.getCompanyIndicators();
    this.companyService.getCompanyRatingAgencyDetails(this.company_id).subscribe((result)=>{

      if(result)
      this.companyDetails = result;
      else
      this.companyDetails = null;
      if(this.companyDetails){
        if(this.companyDetails.environment!=null)
        this.environmentVal=(this.companyDetails.environment).toFixed(2);
        if(this.companyDetails.social!=null)
        this.socialVal = (this.companyDetails.social).toFixed(2);
        if(this.companyDetails.governance!=null)
        this.governanceVal = (this.companyDetails.governance).toFixed(2);
      }
     

       this.overallESGData = [this.companyDetails.overallEsgQuotient, (1 - this.companyDetails.overallEsgQuotient)];
      this.overallESGChartColor = this.chartColorESG(this.companyDetails.overallEsgQuotient);
      this.draw();
      if(this.companyDetails.ratingAgencyMappingVO.ratingAgencyVO)
      this.ratingAgencyName=this.companyDetails.ratingAgencyMappingVO.ratingAgencyVO.name;
      if(this.companyDetails.length > 0)
      this.companyName = this.companyDetails.companyName;
          for (let companyParameters of this.companyDetails.parameterVO) {
            var str = companyParameters.parameterList; 
            str = str.replace(/"/g, '');
            str = str.replace(/{/g, '');
            str = str.replace(/}/g, '');
            if(companyParameters.parameterName == "Region")
              this.companyRegionNames = str;
            if(companyParameters.parameterName == "Sector")
              this.companySectorNames = str;
            if(companyParameters.parameterName == "Country")
              this.companyCountryNames = str;
              if(companyParameters.parameterName == "Sub Sector")
              this.companySubSectorNames = str;
          }
          this.ratingAgencyList=[];
          this.srayRatingAgencyList=[];
          for(let ratingAgency of this.companyDetails.ratingAgencyMappingVO){
 
            let jsonArray={};
            let srayArray ={};
            let dates;
            // if(ratingAgency.ratingAgencyVO.id==3){
            //   srayArray['ratingAgencyId'] = ratingAgency.ratingAgencyVO.id;
            //   srayArray['ratingAgencyName'] = ratingAgency.ratingAgencyVO.name;
            //   srayArray['ratingAgencyImage'] = ratingAgency.ratingAgencyVO.imgFileName;
            //   srayArray['ratingType'] = ratingAgency.ratingAgencyVO.ratingType
            //   srayArray['dates'] = ratingAgency.ratingAgencyVO.dates;
            //   if(ratingAgency.companyRatingAgencyDetails){
            //     srayArray['esgScore']=ratingAgency.companyRatingAgencyDetails.esgScore;
            //   }
            //   else{
            //     srayArray['esgScore']="";
            //   }
            //   this.srayRatingAgencyList.push(srayArray);
            // }
            // else{
              jsonArray['ratingAgencyId'] = ratingAgency.ratingAgencyVO.id;
              jsonArray['ratingAgencyName'] = ratingAgency.ratingAgencyVO.name;
              jsonArray['ratingAgencyImage'] = ratingAgency.ratingAgencyVO.imgFileName;
              if(ratingAgency.companyRatingAgencyDetails){
                jsonArray['ratingType'] = ratingAgency.companyRatingAgencyDetails.ratingTypeValue;
                jsonArray['esgScore']=ratingAgency.companyRatingAgencyDetails.esgScore;
                jsonArray["ratingTypeValue"]=ratingAgency.companyRatingAgencyDetails.ratingTypeValue;
              }
              else{
                jsonArray['ratingType'] = "";
                jsonArray['esgScore']="";
                jsonArray["ratingTypeValue"]="";
              }
              
              this.ratingAgencyList.push(jsonArray);
            //}
          }

         this.srayRatingAgencyList.sort((a, b) => new Date(b.dates).getTime() - new Date(a.dates).getTime());
         console.log("dddd")
         console.log(this.srayRatingAgencyList)
         console.log(this.ratingAgencyList)

    },(error)=>{
      console.log(error);
    })
    this.mediaDashboardService.fetchNewsDetails(this.company_id).subscribe(data => {
      this.newsData = data;
     
      //Get latest news
      let sentimentVal=this.newsData.score;
      if(sentimentVal!=null){
        this.sentimentScore=sentimentVal.toFixed(2);
      }
      
       //Sentiment Score
      this.drawSentimentArc();
    });
  

  }


  doughnutChartESGData(esg: number) {
    if (esg > 1)
      esg = 1;
    this.doughnutData = [esg, (1 - esg)];
    return this.doughnutData;
  }
  chartColorESG(esg: number) {
    if (esg >= 0.8) {
      this.chartColors = [{ backgroundColor: ["#3e7d14", "#ebebeb"] }];
    }
    else if (esg >= 0.6 && esg <= 0.79) {
      this.chartColors = [{ backgroundColor: ["#93e45b", "#ebebeb"] }];
    }
    else if (esg >= 0.4 && esg <= 0.59) {
      this.chartColors = [{ backgroundColor: ["#bfbfbf", "#ebebeb"] }];
    }
    else if (esg >= 0.2 && esg <= 0.39) {
      this.chartColors = [{ backgroundColor: ["#595959", "#ebebeb"] }];
    }
    else if (esg >= 0 && esg <= 0.19) {
      this.chartColors = [{ backgroundColor: ["#000000", "#ebebeb"] }];
    }
    return this.chartColors;
  }

  OptionsESG() {
    var per = 60;
    this.chartOptions = {
      responsive: true,
      cutoutPercentage: per,
      animation: false,
    };
    return this.chartOptions;
  }
draw() {
    var self = this;
    var gauge = function (container, configuration) {

      var config = {
        size: 140,
        clipWidth: 100,
        clipHeight: 90,
        ringInset: 17,
        ringWidth: 17,

        pointerWidth: 9,
        pointerTailLength: 5,
        pointerHeadLengthPercent: 0.6,

        minValue: -3,
        maxValue: 3,

        minAngle: -90,
        maxAngle: 92,

        transitionMs: 850,

        majorTicks: 5,
        labelFormat: d3.format('.1f'),
        labelInset: 10,
        arcColorFn: d3.scaleOrdinal().range(['#000000', '#595959', '#bfbfbf', '#93e45b', '#3e7d14'])

      };
      var range = undefined;
      var r = undefined;
      var pointerHeadLength = undefined;
      var value = 0;

      var svg = undefined;
      var arc = undefined;
      var scale = undefined;
      var ticks = undefined;
      var tickData = undefined;
      var pointer = undefined;

      var donut = d3.pie();

      function deg2rad(deg) {
        return deg * Math.PI / 180;
      }

      function newAngle(d) {
        var ratio = scale(d);
        var newAngle = config.minAngle + (ratio * range);
        return newAngle;
      }

      function configure(configuration) {
        var prop = undefined;
        for (prop in configuration) {
          config[prop] = configuration[prop];
        }

        range = config.maxAngle - config.minAngle;
        r = config.size / 2;
        pointerHeadLength = Math.round(r * config.pointerHeadLengthPercent);

        // a linear scale this.gaugemap maps domain values to a percent from 0..1
        scale = d3.scaleLinear()
        .range([-3, 3])
          .domain([config.minValue, config.maxValue]);

        ticks = scale.ticks(config.majorTicks);
        tickData = d3.range(config.majorTicks).map(function () { return 1 / config.majorTicks; });

        arc = d3.arc()
          .innerRadius(r - config.ringWidth - config.ringInset)
          .outerRadius(r - config.ringInset)
          .startAngle(function (d, i) {
            var ratio = d * i;
            if (i == 0)
              ratio = 0;
            else if (i == 1)
              ratio = 0.20
            else if (i == 2)
              ratio = 0.40
            else if (i == 3)
              ratio = 0.60
            else if (i == 4)
              ratio = 0.80
            return deg2rad(config.minAngle + (ratio * range));
          })
          .endAngle(function (d, i) {
            var ratio = d * (i + 1);
            if (i == 0)
              ratio = 0.20
            else if (i == 1)
              ratio = 0.40
            else if (i == 2)
              ratio = 0.60
            else if (i == 3)
              ratio = 0.80
            else if (i == 4)
              ratio = 1
            return deg2rad(config.minAngle - 1 + (ratio * range));
          });
      }
      self.gaugemap.configure = configure;

      function centerTranslation() {
        return 'translate(' + r + ',' + r + ')';
      }

      function isRendered() {
        return (svg !== undefined);
      }
      self.gaugemap.isRendered = isRendered;

      function render(newValue) {
        svg = d3.select(container)
          .append('svg:svg')
          .attr('class', 'gauge')
          .attr('width', config.clipWidth)
          .attr('height', config.clipHeight);

        var centerTx = centerTranslation();

        var arcs = svg.append('g')
          .attr('class', 'arc')
          .attr('transform', centerTx);

        arcs.selectAll('path')
          .data(tickData)
          .enter().append('path')
          .attr('fill', function (d, i) {
            return config.arcColorFn(d * i);
          })
          .attr('d', arc);

        var lg = svg.append('g')
          .attr('class', 'label')
          .attr('transform', centerTx);
        lg.selectAll('text')
          .data(ticks)
          .enter().append('text')
          .attr('transform', function (d) {
            var ratio = scale(d);
            var newAngle = config.minAngle + (ratio * range);
            return 'rotate(' + newAngle + ') translate(-10,' + (config.labelInset - r) + ')';
          })
          .text(config.labelFormat);

        var lineData = [[config.pointerWidth / 2, 0],
        [0, -pointerHeadLength],
        [-(config.pointerWidth / 2), 0],
        [0, config.pointerTailLength],
        [config.pointerWidth / 2, 0]];
        var pointerLine = d3.line().curve(d3.curveLinear)
        var pg = svg.append('g').data([lineData])
          .attr('class', 'pointer')
          .attr('transform', centerTx);

        pointer = pg.append('path')
          .attr('d', pointerLine/*function(d) { return pointerLine(d) +'Z';}*/)
          .attr('transform', 'rotate(' + config.minAngle + ')');

        update(newValue === undefined ? 0 : newValue);
      }
      self.gaugemap.render = render;
      function update(newValue, newConfiguration?) {
        if (newConfiguration !== undefined) {
          configure(newConfiguration);
        }
        var ratio = scale(newValue);
        var newAngle = config.minAngle + (ratio * range);
        pointer.transition()
          .duration(config.transitionMs)
          .ease(d3.easeElastic)
          .attr('transform', 'rotate(' + newAngle + ')');
      }
      self.gaugemap.update = update;

      configure(configuration);

      return self.gaugemap;
    };

    var epowerGauge = gauge('#e-power-gauge', {
      size: 130,
      clipWidth: 160,
      clipHeight: 90,
      ringWidth: 20,
      maxValue: 1,
      transitionMs: 10000,
    });
    epowerGauge.render(this.companyDetails.environment);

    var spowerGauge = gauge('#s-power-gauge', {
      size: 130,
      clipWidth: 160,
      clipHeight: 90,
      ringWidth: 20,
      maxValue: 1,
      transitionMs: 10000,
    });
    spowerGauge.render(this.companyDetails.social);

    var gpowerGauge = gauge('#g-power-gauge', {
      size: 130,
      clipWidth: 160,
      clipHeight: 90,
      ringWidth: 20,
      maxValue: 1,
      transitionMs: 10000,
    });
    gpowerGauge.render(this.companyDetails.governance);

  }

  media(companyDetails){
   this._router.navigate(['/dashboard/mediaDashboard'], {
    queryParams: {
      "name" :companyDetails.companyName,
      "id" :companyDetails.id,
      "image" :companyDetails.companyImgFileName,
      "sector" :this.companySectorNames,
      "subSector" :this.companySubSectorNames,
      "region" :this.companyRegionNames,
      "subRegion" :this.companyCountryNames,
      "revenue" : companyDetails.revenue},
    skipLocationChange: true
  });
  }
  esgDetails(companyDetails){
    this._router.navigate(['/performanceManagement/dashboard/companyDetails'], {
      queryParams: {
        "name" :companyDetails.companyName,
        "id" :companyDetails.id,
        "image" :companyDetails.companyImgFileName,
        "sector" :this.companySectorNames,
        "subSector" :this.companySubSectorNames,
        "region" :this.companyRegionNames,
        "subRegion" :this.companyCountryNames,
        "environment" :companyDetails.environment,
        "social" :companyDetails.social,
        "governance" :companyDetails.governance,
        "overallEsgQuotient" :companyDetails.overallEsgQuotient,
        "esgColorStatus":companyDetails.esgColorStatus,
        "revenue" : companyDetails.revenue},
      skipLocationChange: true
    });
    //this._router.navigate(['/performanceManagement/dashboard/companyDetails/'+companyId]);
  }
  getCompanyIndicators(){
    this.companyService.getCompanySelectedIndicators(this.company_id).subscribe((companyIndicatorList)=>{
      this.companyIndicatorList = companyIndicatorList;
      for (let companyIndicators of companyIndicatorList) {
        if(companyIndicators.esgType == 1){
          this.environmentIndicators.push(companyIndicators);
        }
        else if(companyIndicators.esgType == 2){
          this.socialIndicators.push(companyIndicators);
        }
        else if(companyIndicators.esgType == 3){
          this.governanceIndicators.push(companyIndicators);
        }
      }
      this.environmentIndicators=this.environmentIndicators.slice(0,3)
      this.socialIndicators=this.socialIndicators.slice(0,3)
      this.governanceIndicators=this.governanceIndicators.slice(0,3)

       
    },(error)=>{
      console.log(error);
    })
  
  
  }
  showIndustryComparisonPopup(companyId,ratingAgencyVO,companyName){
    console.log("ratingAgencyVO")
    console.log(ratingAgencyVO)
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    if(ratingAgencyVO.ratingAgencyName=='sustainalytics'){
      dialogConfig.width = "100%";
      dialogConfig.data = { 'companyId': true };
      dialogConfig.data = { 'companyId': companyId , 'ratingAgencyId': ratingAgencyVO.ratingAgencyId,'companyName':companyName,'ratingAgencyName':ratingAgencyVO.ratingAgencyName};
      const dialogRef = this.dialog.open(SustainanalyticRatingAgencyComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(data => {
      let popUpdetails: boolean = true
    
     
      });
    }
    else if(ratingAgencyVO.ratingAgencyName=='MSCI' || ratingAgencyVO.ratingAgencyName=='S&P' || ratingAgencyVO.ratingAgencyName=='Rep Risk'){
        dialogConfig.width = "100%";
        dialogConfig.data = { 'companyId': true };
        dialogConfig.data = { 'companyId': companyId , 'ratingAgencyId': ratingAgencyVO.ratingAgencyId,'companyName':companyName,'ratingAgencyName':ratingAgencyVO.ratingAgencyName};
        const dialogRef = this.dialog.open(OwlanalyticsRatingAgencyComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(data => {
        let popUpdetails: boolean = true
        });
      }
    
    else{
      let lastUpdatedDate = this.formatDate(ratingAgencyVO.dates);
      dialogConfig.width = "80%";
      dialogConfig.data = { 'companyId': true };
      dialogConfig.data = { 'companyId': companyId , 'ratingAgencyId': ratingAgencyVO.ratingAgencyId,'companyName':companyName,'ratingAgencyName':ratingAgencyVO.ratingAgencyName,'lastUpdatedDate' : lastUpdatedDate};
      const dialogRef = this.dialog.open(SrayRatingAgencyComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(data => {
      let popUpdetails: boolean = true
    
     
      });
    }
   
   
    
  }

  drawSentimentArc() {
    document.querySelector('div#sentiment-gauge').innerHTML = "";
    var self = this;
    var gauge = function (container, configuration) {

      var config = {
        size: 150,
        clipWidth: 100,
        clipHeight: 90,
        ringInset: 17,
        ringWidth: 17,

        pointerWidth: 9,
        pointerTailLength: 5,
        pointerHeadLengthPercent: 0.6,

        minValue: -3,
        maxValue: 3,

        minAngle: -90,
        maxAngle: 92,

        transitionMs: 850,

        majorTicks: 6,
        labelFormat: d3.format('.1f'),
        labelInset: 10,
        arcColorFn: d3.scaleOrdinal().range(['#f36750', '#da8368', '#da8368', '#49e2e7', '#3cbbe4', '#788de6'])

      };
      var range = undefined;
      var r = undefined;
      var pointerHeadLength = undefined;
      var value = 0;

      var svg = undefined;
      var arc = undefined;
      var scale = undefined;
      var ticks = undefined;
      var tickData = undefined;
      var pointer = undefined;

      var donut = d3.pie();

      function deg2rad(deg) {
        return deg * Math.PI / 180;
      }

      function newAngle(d) {
        var ratio = scale(d);
        var newAngle = config.minAngle + (ratio * range);
        return newAngle;
      }

      function configure(configuration) {
        var prop = undefined;
        for (prop in configuration) {
          config[prop] = configuration[prop];
        }

        range = config.maxAngle - config.minAngle;
        r = config.size / 2;
        pointerHeadLength = Math.round(r * config.pointerHeadLengthPercent);

        // a linear scale this.gaugemap maps domain values to a percent from 0..1
        scale = d3.scaleLinear()
          .range([0, 1])
          .domain([config.minValue, config.maxValue]);

        ticks = scale.ticks(config.majorTicks);
        tickData = d3.range(config.majorTicks).map(function () { return 1 / config.majorTicks; });

        arc = d3.arc()
          .innerRadius(r - config.ringWidth - config.ringInset)
          .outerRadius(r - config.ringInset)
          .startAngle(function (d, i) {
            var ratio = d * i;
            if (i == 0)
            ratio = 0;
            else if (i == 1)
            ratio = 0.1667
            else if (i == 2)
            ratio = 0.334
            else if (i == 3)
            ratio = 0.501
            else if (i == 4)
            ratio = 0.668
            else if (i == 5)
            ratio = 0.835
            return deg2rad(config.minAngle + (ratio * range));
            })
            .endAngle(function (d, i) {
            var ratio = d * (i + 1);
            if (i == 0)
            ratio = 0.1667
            else if (i == 1)
            ratio =0.334
            else if (i == 2)
            ratio = 0.501
            else if (i == 3)
            ratio = 0.668
            else if (i == 4)
            ratio = 0.835
            else if (i == 5)
            ratio = 1
            return deg2rad(config.minAngle - 1 + (ratio * range));
            });
      }
      self.gaugemap.configure = configure;

      function centerTranslation() {
        return 'translate(' + r + ',' + r + ')';
      }

      function isRendered() {
        return (svg !== undefined);
      }
      self.gaugemap.isRendered = isRendered;

      function render(newValue) {
        svg = d3.select(container)
          .append('svg:svg')
          .attr('class', 'gauge')
          .attr('width', config.clipWidth)
          .attr('height', config.clipHeight);

        var centerTx = centerTranslation();

        var arcs = svg.append('g')
          .attr('class', 'arc')
          .attr('transform', centerTx);

        arcs.selectAll('path')
          .data(tickData)
          .enter().append('path')
          .attr('fill', function (d, i) {
            return config.arcColorFn(d * i);
          })
          .attr('d', arc);

        var lg = svg.append('g')
          .attr('class', 'label')
          .attr('transform', centerTx);
        lg.selectAll('text')
          .data(ticks)
          .enter().append('text')
          .attr('transform', function (d) {
            var ratio = scale(d);
            var newAngle = config.minAngle + (ratio * range);
            return 'rotate(' + newAngle + ') translate(-10,' + (config.labelInset - r) + ')';
          })
          .text(config.labelFormat);

        var lineData = [[config.pointerWidth / 2, 0],
        [0, -pointerHeadLength],
        [-(config.pointerWidth / 2), 0],
        [0, config.pointerTailLength],
        [config.pointerWidth / 2, 0]];
        var pointerLine = d3.line().curve(d3.curveLinear)
        var pg = svg.append('g').data([lineData])
          .attr('class', 'pointer')
          .attr('transform', centerTx);

        pointer = pg.append('path')
          .attr('d', pointerLine/*function(d) { return pointerLine(d) +'Z';}*/)
          .attr('transform', 'rotate(' + config.minAngle + ')');

        update(newValue === undefined ? 0 : newValue);
      }
      self.gaugemap.render = render;
      function update(newValue, newConfiguration?) {
        if (newConfiguration !== undefined) {
          configure(newConfiguration);
        }
        var ratio = scale(newValue);
        var newAngle = config.minAngle + (ratio * range);
        pointer.transition()
          .duration(config.transitionMs)
          .ease(d3.easeElastic)
          .attr('transform', 'rotate(' + newAngle + ')');
      }
      self.gaugemap.update = update;

      configure(configuration);

      return self.gaugemap;
    };

    var sentiGauge = gauge('#sentiment-gauge', {
      size: 250,
      clipWidth: 250,
      clipHeight: 135,
      ringWidth: 20,
      maxValue: 3,
      transitionMs: 10000,
    });
    let sentimentVal=this.newsData.score;
    if(sentimentVal!=null){
      this.sentimentScore=sentimentVal.toFixed(2);
    }
    
    sentiGauge.render(this.sentimentScore);
  }
  formatDate(inputDate) {
    var pipe = new DatePipe('en-US'); // Use your own locale
    let newdate = new Date(inputDate).toISOString().split('T')[0];
    return pipe.transform(newdate, 'dd-MM-yyyy');

  }
  getYearList() {
    let currentYear = new Date().getFullYear();
    let years=[];
    for (let i = currentYear; i <= currentYear + 88; i++) {
    years.push(i);
    }
    return years;
    }
    openUrl()
    {let url="http://3.214.54.217:5910/"
    window.open(url,"_blank");
    }
    openTaxomyUrl(){

      let url="https://xd.adobe.com/view/065e9b26-2f87-499f-bdcb-4e3fb0718ced-530d/?fullscreen"
      
      window.open(url,"_blank");
      
      }
}