import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { IndicatorMasterService } from '../../indicator-master.service';
import { BaseUrlService } from '../../../../shared/base-url.service';
import { AddSdgComponent } from '../add-sdg/add-sdg.component';
@Component({
  selector: 'app-list-sdg',
  templateUrl: './list-sdg.component.html',
  styleUrls: ['./list-sdg.component.css']
})
export class ListSdgComponent implements OnInit {
  displayedColumns: string[];
  jsonArray = [];
  originalColumnNames: string[];
  top: any;
  standardizedSDGCodes : any = [];
  @Input() set topWrapper(val) {
    this.top = val;
  }

  @Output() sdgComponent = new EventEmitter();
  @Input() set sustainableDevGoalsList(val) {
    this.fetchDataTable(val);
    }
  constructor(private baseUrlService:BaseUrlService, private indicatorMasterService: IndicatorMasterService, private addEditSuccessComponent: AddEditSuccessComponent,
              private addSdgComponent:AddSdgComponent) { }

  ngOnInit() {

    this.standardizedSDGCodes = this.baseUrlService.getListOfStandardizedSDGCodes();
    
  }
  fetchDataTable(sdgValues) {
    
    this.jsonArray = [];
    
    this.displayedColumns = [];
    this.originalColumnNames = [];
    var count = 0;
    
    for (let i of sdgValues) {
      var newArray = {};
      newArray['Sno'] = ++count;
      newArray['id'] = i.id;
      newArray['code'] = i.code;
      newArray['name'] = i.name;
      
      if(this.standardizedSDGCodes.includes(i.code)){
        newArray['editDelete'] = 'B'; //both disable delete and edit
      }
      
      this.jsonArray.push(newArray);
    }

    this.displayedColumns = ['Sno', 'code', 'name', 'Actions']
    this.originalColumnNames = ['Sl.No', 'Code', 'Name', 'Actions']

  }

  delete(sdgObj) {
    var confirmMessage = "You Want To Delete Reporting Frameworks and Standards";
    this.addEditSuccessComponent.showConfirmationSwal(confirmMessage,'Yes','No').then((isConfirm) => {
      if (isConfirm.value) {
        this.indicatorMasterService.deleteSDG(sdgObj.id).subscribe((data) => {
            var deleteSuccess = 'Deleted Reporting Frameworks and Standards';
            this.addEditSuccessComponent.showSuccessSwal(deleteSuccess)
            .then((value) => {
              this.getSustainableDevGoalsList();
              this.addSdgComponent.resetSDGForm();
            });
        });
      }
    });
  }

  edit(sdgObj) {
    this.sdgComponent.emit({ id: sdgObj.id, code: sdgObj.code, name: sdgObj.name });
  }
  getSustainableDevGoalsList() {

    this.indicatorMasterService.getSDGs().subscribe(data => {

      if (data) {
        this.fetchDataTable(data);
      }
    });

  }
}

