import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, AbstractControl, AbstractControlOptions, ValidationErrors, FormGroupDirective } from '@angular/forms';
import { CustomValidationsService } from '../../../../shared/Validations/custom-validations.service';
import { IndicatorMasterService } from '../../indicator-master.service';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-sdg',
  templateUrl: './add-sdg.component.html',
  styleUrls: ['./add-sdg.component.css']
})
export class AddSdgComponent implements OnInit {

  addSustainableDevGoalsForm: FormGroup;
  sustainableDevGoals: any = [];
  topWrapper: any;
  @ViewChild("topWrap") topWrap;
  @ViewChild('sdgForm') sdgForm;

  constructor(private indicatorMasterService: IndicatorMasterService, private formBuilder: FormBuilder, private customValidationsService: CustomValidationsService, private addEditSuccessComponent: AddEditSuccessComponent) {
    this.addSustainableDevGoalsForm = this.createFormGroup();
  }

  ngOnInit() {
    this.topWrapper = this.topWrap;
    this.getSustainableDevGoalsList();
  }
  createFormGroup() {
    return this.formBuilder.group({
      id: [],
      code: ['', [Validators.required, Validators.maxLength(50), this.customValidationsService.noWhitespaceValidator], this.uniqueSdgCodeValidator()],
      name: ['', [Validators.required, Validators.maxLength(1000), this.customValidationsService.noWhitespaceValidator]],

    }, { updateOn: 'blur' })

  }

  uniqueSdgCodeValidator() {
    return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      var id = 0
      if (this.addSustainableDevGoalsForm && this.addSustainableDevGoalsForm.controls.id.value) {
        id = this.addSustainableDevGoalsForm.controls.id.value;
      }

      return this.indicatorMasterService.checkSdgCodeValidation(id, c.value).pipe(
        map(val => {

          console.log(val)
          if (val == 1) {
            return { 'uniqueCode': true };
          }
        })
      );
    };

  }

  uniqueSdgNameValidator() {
    return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      var id = 0
      if (this.addSustainableDevGoalsForm && this.addSustainableDevGoalsForm.controls.id.value) {
        id = this.addSustainableDevGoalsForm.controls.id.value;
      }
      return this.indicatorMasterService.checkSdgNameValidation(id, c.value).pipe(
        map(val => {
          console.log(val)
          if (val == 1) {
            return { 'uniqueName': true };
          }
        })
      );
    };

  }


  onSubmit() {

    if (this.addSustainableDevGoalsForm.valid) {

      this.indicatorMasterService.createSDG(this.addSustainableDevGoalsForm.value).subscribe((myForm) => {
        var message;
        if (this.addSustainableDevGoalsForm.get('id').value != null)
          message = "Successfully Edited Reporting Frameworks and Standards";
        else
          message = "Successfully Added Reporting Frameworks and Standards";
        this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {

          this.resetSDGForm();
          this.getSustainableDevGoalsList();


        });
      });
    }
  }
  resetSDGForm() {

    this.sdgForm.resetForm();
    this.topWrap.nativeElement.classList.remove("top-form-wrapper-highlight");

  }
  defaultValues(p) {

    this.addSustainableDevGoalsForm.patchValue(p);
  }
  getSustainableDevGoalsList() {
    this.sustainableDevGoals = [];
    this.indicatorMasterService.getSDGs().subscribe(data => {
      this.sustainableDevGoals = data;
    });

  }


}




