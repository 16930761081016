import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import swal from 'sweetalert2';
import { MatTableDataSource, MatPaginator, MatSort, MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatOption } from '@angular/material';
import { Router } from '@angular/router';
import { CustomValidationsService } from '../../../../../shared/Validations/custom-validations.service';
import { CompanyComplianceDrilldownComponent } from '../../../../report/ComplianceDashboard/components/company-compliance-drilldown/company-compliance-drilldown.component';
@Component({
  selector: 'app-add-assessment-template',
  templateUrl: './add-assessment-template.component.html',
  styleUrls: ['./add-assessment-template.component.css']
})
export class AddAssessmentTemplateComponent implements OnInit {
  questionSelectedObjs: Array<any> = [];
  displayedColumns: string[];
  dataSource: any;
  dataTableFlag: boolean = true;
  templateForm: FormGroup;
  factorList: any;
  subFactorList: any;
  questionList: any;
  initialQuestionList: any;
  templateData: any = [];
  addedQuestionList: any = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selectedQuestionCount: number;
  templateNamesList: any = [];
  factorIds: any = [];
  subFactorIds: any = [];
  //new fields added nidhin /Niya
  templateVO: any;
  selectFactorText: any
  selectSubFactorText: any
  @ViewChild('ev1') ev1: MatOption
  checkedBox: boolean;
  subSectionChecked: boolean;
  constructor(private _fb: FormBuilder, private assessmentTemplateService: AssessmentTemplateMasterdataService, private router: Router, private customValidationsService: CustomValidationsService) { }

  ngOnInit() {
    this.selectFactorText = "Select All"
    this.selectSubFactorText = "Select All"
    this.selectedQuestionCount = 0;
    this.checkedBox = false
    this.subSectionChecked = false
    this.assessmentTemplateService.loadMasterDataForTemplate().subscribe(data => {
      var masterData: any = data;
      this.factorList = masterData.factorVOList;
      this.subFactorList = masterData.subFactorVOList;
      this.initialQuestionList = masterData.questionVOList;
      this.fetchDataTable(this.initialQuestionList);
      this.getTemplateList()

      for (let factor of this.factorList) {
        this.factorIds.push(factor.id)
      }
    })
    this.templateForm = this.createFormGroup();
  }

  createFormGroup() {
    return this._fb.group({
      id: [0],
      templateName: ['', [Validators.compose([Validators.required]), this.customValidationsService.noWhitespaceValidator, this.uniqueName()]],
      factorVOList: [''],
      subFactorVOList: ['']


    })
  }

  getTemplateList() {
    this.assessmentTemplateService.getTemplateList().subscribe((data) => {
      let templateList = data;
      if (templateList.length > 0) {


        for (let templateVal of templateList) {
          this.templateNamesList.push(templateVal.templateName);
        }
      }
    }, (error) => {
      console.log(error);
    });
  }

  public uniqueName(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && this.templateNamesList.length > 0 && control.value != null) {
        if (this.templateNamesList.includes((control.value).trim())) {
          return { 'unique': true };

        }
      }
      return null;
    };
  }
  selectAllFactors(ev) {

    if (ev._selected) {
      this.templateForm.patchValue({
        factorVOList: this.factorIds
      })
      ev._selected = true;
      this.selectFactorText = "Deselect All"
      this.selectedQuestionCount = 0;
    }
    else {
      this.templateForm.patchValue({
        factorVOList: []
      })
      this.checkedBox = false
      this.selectFactorText = "Select All"
      this.selectedQuestionCount = 0;
      this.ev1.deselect()
      this.templateForm.patchValue({
        subFactorVOList: []
      })
      this.selectSubFactorText = "Select All"
    }
  }
  selectAllSubFactors(ev1) {
    let subfactorIds = []
    if (ev1._selected) {
      for (let i = 0; i < this.subFactorList.length; i++) {
        subfactorIds.push(this.subFactorList[i].id)
      }
      this.templateForm.patchValue({
        subFactorVOList: subfactorIds
      })
      this.selectSubFactorText = "Deselect All"
      ev1._selected = true;
      this.selectedQuestionCount = 0;
      this.subSectionChecked = true
    }
    else {
      this.templateForm.patchValue({
        subFactorVOList: []
      })
      this.checkedBox = false
      this.selectSubFactorText = "Select All"
      this.selectedQuestionCount = 0;
      this.subSectionChecked = false
    }
  }
  factorChange(event) {
    if (event && event.length > 0) {
      this.ev1.deselect()
      this.assessmentTemplateService.loadSubFactorsforTheSelectedFactors(event).subscribe(data => {
        this.subFactorList = data;
        let subFactorIds = []
        let l = Object.keys(data).length
        for (let i = 0; i < l; i++) {
          subFactorIds.push(data[i].id)
        }
        this.subFactorChange(subFactorIds)
      })

      this.templateForm.patchValue({
        subSectionVOList: []
      })
      this.selectSubFactorText = "Select All"
    }
    this.selectedQuestionCount = 0;
    this.questionSelectedObjs = [];

    this.fetchDataTable(this.initialQuestionList);
    this.checkedBox = false
  }

  subFactorChange(event) {
    console.log("subfactor change")
    console.log(event)
    if (event && event.length > 0) {
      this.assessmentTemplateService.loadQuestionsforTheSelectedSubfactors(event).subscribe(data => {
        this.questionList = data;
        this.selectedQuestionCount = 0;
        this.questionSelectedObjs = [];
        this.fetchDataTable(this.questionList)
        this.subSectionChecked = true
      })
    }
    else {
      this.subSectionChecked = false
      this.fetchDataTable(this.initialQuestionList);
    }
    this.checkedBox = false

  }

  fetchDataTable(questionList) {
    console.log("fetchtable")
    console.log(questionList)
    var jsonArray = [];
    this.displayedColumns = [];
    var count = 0;
    for (let i of questionList) {
      var newArray = {};
      newArray['Sno'] = ++count;
      newArray['Question'] = i.questionText;
      newArray['id'] = i.id;
      newArray['checked']=i.checked
      jsonArray.push(newArray);
    }
    console.log("jsonarray")
    console.log(jsonArray)
    this.displayedColumns = ['checkbox', 'Sno', 'Question']
    this.dataSource = new MatTableDataSource(jsonArray);
    setTimeout(() => this.dataSource.paginator = this.paginator);
    this.dataSource.sort = this.sort;
    if (this.dataSource.filteredData.length != 0)
      this.dataTableFlag = true;
    else
      this.dataTableFlag = false;
  }

  populateDatatoQuestionForm(questionList) {
    let count = 0;
    this.questionSelectedObjs = []
    for (let i of questionList) {
      let element = {}
      element['Sno'] = count
      element['id'] = i.id
      element['primaryQuestionFlag'] = 'Y';
      element['skipLogicFlag'] = 'N';
      element['responseOptionsVOList'] = null;
      element['checked'] = true
      element['Question'] = i.questionText
      element['questionText'] = i.questionText
      count++
      this.questionSelectedObjs.push(element);
    }

  }
  selectAllQuestions(event) {
    if (event.checked && !this.subSectionChecked) {
      this.populateDatatoQuestionForm(this.initialQuestionList)
      this.checkedBox = true
      console.log(this.questionSelectedObjs)
      this.fetchDataTable(this.questionSelectedObjs)
      this.selectedQuestionCount = this.questionSelectedObjs.length;
    }
    else if (event.checked && this.subSectionChecked) {
      this.populateDatatoQuestionForm(this.questionList)
      this.checkedBox = true
      this.fetchDataTable(this.questionSelectedObjs)
      this.selectedQuestionCount = this.questionSelectedObjs.length;
    }
    else if (!event.checked && this.subSectionChecked) {
      this.checkedBox = event.checked;
      for (var i = 0; i < this.questionSelectedObjs.length; i++) {
        this.questionSelectedObjs[i].checked = event.checked;
      }
      this.fetchDataTable(this.questionSelectedObjs)
      this.questionSelectedObjs = [];
      this.selectedQuestionCount = this.questionSelectedObjs.length;
    }
    else {
      this.checkedBox = false
      this.questionSelectedObjs = []
      this.fetchDataTable(this.initialQuestionList)
      this.selectedQuestionCount = this.questionSelectedObjs.length;
    }
  }
  onChange(element: any, isChecked: any) {

    if (isChecked.checked) {
      element['primaryQuestionFlag'] = 'Y';
      element['skipLogicFlag'] = 'N';
      element['responseOptionsVOList'] = null;
      element['checked'] = true
      if (!this.questionSelectedObjs.includes(element)) {
        this.questionSelectedObjs.push(element);
      }

    }
    else {
      let index = this.questionSelectedObjs.indexOf(element);
      this.questionSelectedObjs.splice(index, 1);
    }

    this.selectedQuestionCount = this.questionSelectedObjs.length;

  }

  onNext() {
    if (this.templateForm.valid) {

      let templateDetails = {};
      //templateDetails['templateFormValue']=JSON.stringify(this.templateForm.value);
      //templateDetails['questionSelectedObjs']=JSON.stringify(this.questionSelectedObjs);
      templateDetails['refreshFlag'] = false;
      //Route to the second page with the reqd details
      let templateVO = {};
      //  let questionVOList = [];
      //  templateVO['id'] = 0;
      //  templateVO['saveFlag'] = 'Y';
      //  templateVO['templateName'] = this.templateForm.value.templateName;
      // for (let question of this.questionSelectedObjs) {
      //       let questionVO = {};
      //       questionVO['id'] = question.id;
      //       questionVO['primaryQuestionFlag'] = 'Y'; 
      //       questionVO['skipLogicFlag']='N';
      //       questionVO['responseOptionsVOList'] = null;
      //       questionVO['criticalFlag'] = 'N';
      //       questionVOList.push(questionVO);
      //     }


      //  templateVO['questionVOList'] = questionVOList;
      let viridiTreeNode = {};
      let viridiAssessmentTemplateQuestionList = [];
      viridiTreeNode['nodeData'] = null;
      viridiTreeNode['parentNode'] = null;
      for (let question of this.questionSelectedObjs) {
        let childViridiTreeNode = {};
        let questionVO = {};
        let assessmentTemplateQuestionVO = {};
        questionVO['id'] = question.id;
        questionVO['primaryQuestionFlag'] = 'Y';
        questionVO['skipLogicFlag'] = 'N';
        questionVO['criticalFlag'] = 'N';
        assessmentTemplateQuestionVO['questionVO'] = questionVO;
        assessmentTemplateQuestionVO['responseOptionVO'] = null;
        childViridiTreeNode['nodeData'] = assessmentTemplateQuestionVO;
        childViridiTreeNode['parentNode'] = null;
        childViridiTreeNode['childNodes'] = null;
        viridiAssessmentTemplateQuestionList.push(childViridiTreeNode);
      }
      viridiTreeNode['childNodes'] = viridiAssessmentTemplateQuestionList;
      templateVO['templateQuestionNode'] = viridiTreeNode;
      templateVO['saveFlag'] = 'Y';
      templateVO['templateName'] = this.templateForm.value.templateName;
      console.log("templateVO")
      console.log(templateVO)
      this.assessmentTemplateService.createTemplate(templateVO).subscribe(templateVO => {
        console.log("backedn dataa")
        console.log(templateVO)
        this.templateVO = templateVO
        // templateDetails['templateId']= this.templateData.id; 
        templateDetails['id'] = this.templateVO.id;
        templateDetails['templateName'] = this.templateVO.templateName;
        templateDetails['templateQuestionNode'] = JSON.stringify(this.templateVO.templateQuestionNode);
        this.router.navigate(['/assessmentTemplateAdd'], { queryParams: templateDetails, skipLocationChange: true });



      })



      // this.router.navigate(['/listassessmentTemplatequestions'],{queryParams:templateDetails,skipLocationChange: true});

    }
    else {
      this.templateForm.get('templateName').markAsTouched();
      return;
    }
  }
  resetForm() {
    this.templateForm.reset();
    this.fetchDataTable(this.initialQuestionList);
    this.questionSelectedObjs = [];
    this.selectedQuestionCount = 0;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;

  }

}


