import { Injectable } from '@angular/core';
import { BaseUrlService } from '../../shared/base-url.service';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class FundService {

  private baseUrl = BaseUrlService.FUNDBASEURL;
  private parameterBaseUrl = BaseUrlService.PARAMATERCONFIGURATIONBASEURL;


  constructor(private http: HttpClient) { }

  getFundList():Observable<any>
  {
    return this.http.get(`${this.baseUrl}/fund`);
  }

  fetchCompanyList(parameterRequestVO)
  {
     let body = JSON.stringify(parameterRequestVO);
    let url = this.parameterBaseUrl + '/getParameterData';
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
        })
      };
      return this.http.post(url, body, httpOptions);
  }

  createOrUpdateFundDetails(fundForm)
  {
    let body = JSON.stringify(fundForm);
    let url = this.baseUrl + '/createOrUpdateFund';
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
        })
      };
      return this.http.post(url, body, httpOptions);

  }
  deleteFund(fundId) {
    let url = this.baseUrl + '/deleteFundDetails/' + fundId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };
    return this.http.post(url, null, httpOptions);
  }
  
  validateIsinCode(fundId,isinCode)
  {
    let url = this.baseUrl + '/fund/validateCode/' + fundId + '/' + isinCode;
    return this.http.get(url);
  }
  validateFundName(fundId,fundName)
  {
    let url = this.baseUrl + '/fund/validatFundName/' + fundId + '/' + fundName;
    return this.http.get(url);
  }
}
