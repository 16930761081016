import { Component, OnInit } from '@angular/core';
import { Router , ActivatedRoute } from '@angular/router';
import { ParametersService } from '../../parameters.service';
import { RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import {ReportConstantsComponent} from '../../../report-constants/report-constants.component'

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.css']
})
export class ParametersComponent implements OnInit {
  
    public parameters:any;
    public currency:any;
    public assetArray=[];
    public ownershipArray=[];
  locale: string;
    constructor(private _parametersService:ParametersService , private _router:Router) {}

    ngOnInit() {
      
      this.currency =ReportConstantsComponent.CURRENCY;
        this._parametersService.getParameters().subscribe((parameters)=>{
          this.parameters = parameters;
          console.log(this.parameters)
          this.assetArray.push(this.parameters[3]);
          this.ownershipArray.push(this.parameters[2])
          },(error)=>{
          console.log(error);
        })

        
    }

    drillDown(id:number){
      console.log("drilldown")
      console.log(id)
      this._router.navigate(['/performanceManagement/dashboard/subParameters/'+id]);
    }
    

}