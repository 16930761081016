import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
import { MatTableDataSource, MatPaginator, MatSort, MatTable, MatTreeNestedDataSource } from '@angular/material';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { debug } from 'util';
import { NestedTreeControl } from '@angular/cdk/tree';
import { HttpResponse } from '@angular/common/http';
import { AddEditSuccessComponent } from '../../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { Console } from 'console';

interface QuestionNode {
  id: number;
  name: string;
  optionList: any;
  questionType: string;
  skipLogicFlag: string;
  fileName: string;
  fileUploadName: string;
  children?: QuestionNode[];
}


@Component({
  selector: 'app-load-record-page',
  templateUrl: './load-record-page.component.html',
  styleUrls: ['./load-record-page.component.css']
})

export class LoadRecordPageComponent implements OnInit {

  data: any = [];
  assessmentId: any;
  userId: any;
  questionVOList: any;
  assessmentVO: any;
  transactionName: string = '';
  initiatedBy: string = '';
  initiatedDate: string = '';
  treeControl = new NestedTreeControl<QuestionNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<QuestionNode>();
  questionList: any = [];
  factorVOList: any = [];
  currentFactorId: any;
  selectedFactor: any;
  remarksCheckBox: any = []
  @ViewChild('uploadFileTxt') uploadFileTxt: ElementRef;
  childData: any;
  parentChildrenObj: any = {}
  listOfChildren: any = []
  assesssmentStatus: any;
  isSubmitted: boolean = false;
  displayedColumnsForHistory = [];
  originalColumnNamesForHistory = [];
  jsonArrayForHistory = []
  companyAssessmentPanelMemberDetailsId: any;
  parentChildrenObject:any={};
  constructor(private addEditSuccessComponent: AddEditSuccessComponent, public router: Router,
    private activatedRoute: ActivatedRoute,
    private assessementTemlateMasterService: AssessmentTemplateMasterdataService,
    private formBuilder: FormBuilder) {
    this.activatedRoute.params.subscribe(params => {
      this.assessmentId = params.assessmentId
      this.userId = params.userId
    })
  }

  ngOnInit() {
    var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    if(base64regex.test(this.assessmentId))
    {
      this.assessmentId = atob(this.assessmentId)
      this.userId = atob(this.userId)
    }
  
    this.assessementTemlateMasterService.getAssessmentById(this.assessmentId, this.userId)
      .subscribe((data) => {
        console.log("record dataa")
        console.log(data)
        this.assessmentVO = data;
        this.transactionName = this.assessmentVO.companyVO.companyName;
        this.initiatedBy = this.assessmentVO.initiatedBy;
        this.initiatedDate = this.assessmentVO.initiatedDateString;
        this.questionVOList = this.assessmentVO.assessmentResponseVO.questionList;
        this.factorVOList = data["assessmentResponseVO"].factorVOList;
        this.assesssmentStatus = data["completedStatus"]
        if (this.assesssmentStatus != 'R' && this.assesssmentStatus != 'O') {
          this.isSubmitted = true;
        }
        this.companyAssessmentPanelMemberDetailsId = data["companyAssessmentPanelMemberDetailsId"]
        this.selectedFactor = this.factorVOList[0];
        this.currentFactorId = this.factorVOList[0].id;
        for (let factor of this.factorVOList) {
          for (let subFactor of factor.subFactorVOList) {
            this.populateDataToQuestionForm(subFactor.questionVOList, this.data);
          }
        }
        console.log("this.questionList")
        console.log(this.questionList)
      });
  }

  hasChild = (_: number, node: QuestionNode) => !!node.children && node.children.length > 0;

  populateDataToQuestionForm(questionVOList, data) {
    let questionId;
    let children: any = [];
    let fileName = '';
    let fileUploadName = '';
    for (let question of questionVOList || []) {
      questionId = question.templateQuestionId;
      if (question.questionType == "Quantitative") {
        this.questionList[questionId] = this.createQuestionListQuantitativeForm(questionId);
      }
      else {
        this.questionList[questionId] = this.createQuestionListQualitativeForm(questionId);
      }
      if (question.responseOptionVO != null) {
        children = [];
        this.populateDataForEdit(question, children);
        fileName = question.responseOptionVO.fileName;
        fileUploadName = question.responseOptionVO.fileUploadName;
        this.questionList[question.templateQuestionId].patchValue({
          responseOptionVO: {
            id: question.responseOptionVO.id,
            qunatitativeScore: question.responseOptionVO.responseName,
            fileName: fileName,
            fileUploadName: fileUploadName,
            remarks: question.responseOptionVO.remarks,
            verificationRemarks: question.responseOptionVO.verificationRemarks,
            verificationStatus: question.responseOptionVO.verificationStatus
          }
        });
      }
      data.push({
        'id': questionId,
        'templateQuestionId': questionId,
        'questionText': question.questionText,
        'primaryQuestionFlag': 'Y',
        'responseOptionsVOList': question.responseOptionsVOList,
        'questionType': question.questionType,
        'skipLogicFlag': question.skipLogicFlag,
        'fileName': fileName,
        'fileUploadName': fileUploadName,
        'children': question["children"]
      });
    }

    return data;

  }

  populateDataForEdit(question, children) {
    let responseOption;
    let responseOptionVO = question.responseOptionVO;
    let responseOptionList;

    if (question.questionType == "Quantitative") {

      this.assessementTemlateMasterService.fetchQuestionListForQuantitativeData(question.templateQuestionId, responseOptionVO.responseName.trim(), this.userId, this.assessmentId)
        .subscribe((data) => {
          responseOption = data;
          this.createSubQuestionListQuantitative(question, responseOptionVO.responseName.trim())
          this.populateDataToQuestionForm(responseOption.questionVOList, children);
        });
    }
    else {
      responseOptionList = question.responseOptionsVOList;
      responseOption = responseOptionList.filter(responseOption => responseOption.id == responseOptionVO.id);
      this.createSubQuestionListQualitative(question, responseOption[0].id)
      this.populateDataToQuestionForm(responseOption[0].questionVOList, children);
    }

  }

  createQuestionListQualitativeForm(questionId) {

    return this.formBuilder.group({
      id: [questionId],
      type: [1],
      responseOptionVO: this.formBuilder.group({
        id: ['', Validators.required],
        fileName: [],
        fileUploadName: [],
        remarks: [],
        verificationStatus: [],
        verificationRemarks: []
      })
    });


  }

  createQuestionListQuantitativeForm(questionId) {

    return this.formBuilder.group({
      id: [questionId],
      type: [2],
      responseOptionVO: this.formBuilder.group({
        qunatitativeScore: [, [Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.required]],
        fileName: [],
        fileUploadName: [],
        remarks: [],
        verificationStatus: [],
        verificationRemarks: []
      })
    });


  }


  createSubQuestionListQuantitative(node, score) {
    if (this.questionList[node.templateQuestionId].valid) {
      let responseOption;
      let children: any = [];
      node["children"] = [];
      if (node.skipLogicFlag == "Y") {
        if (score != null && score.length > 0) {
          setTimeout(() => {
            this.assessementTemlateMasterService.fetchQuestionListForQuantitativeData(
              node.templateQuestionId, score, this.userId, this.assessmentId
            )
              .subscribe(data => {
                this.childData = data
                responseOption = data;
                children = this.populateDataToQuestionForm(
                  responseOption.questionVOList,
                  children
                );
                node["children"] = children;
               this.removeDataFromQuestionList(children,node)
              });
          }, 10);
        }
      }
    }
  }





  createSubQuestionListQualitative(question, responseOptionId) {

    let responseOptionList;
    let responseOption;
    let children: any = [];
    question["children"] = [];
    if (question.skipLogicFlag == "Y") {

      responseOptionList = question.responseOptionsVOList;
      responseOption = responseOptionList.filter(
        responseOption => responseOption.id == responseOptionId
      );

      children = this.populateDataToQuestionForm(
        responseOption[0].questionVOList,
        children
      );
      question["children"] = children;
      this.removeDataFromQuestionList(children,question)
    }
    else {
      question["children"] = []
    }
  }
  removeDataFromQuestionList(children,question) {
    console.log("template question id")
    console.log(question.templateQuestionId)
    console.log(children)

    if (children.length != 0) {
      for (let i = 0; i < children.length; i++) {
        this.parentChildrenObj[question.templateQuestionId]=children[i].templateQuestionId
        // this.listOfChildren.push(children[i].templateQuestionId)
      }
    }
    else {
      if (children.length==0) {
        if(question.templateQuestionId in this.parentChildrenObj)
        {
          this.questionList[this.parentChildrenObj[question.templateQuestionId]]=null
          delete this.parentChildrenObj[question.templateQuestionId]
        }
        // for (let i = 0; i < this.listOfChildren.length; i++) {
        //   this.questionList[this.listOfChildren[i]]=null
        //   console.log( this.questionList)
        // }
      }
    }
    console.log(this.parentChildrenObj)
  }

  refreshMatTree() {
    this.dataSource.data = null;
    this.dataSource.data = this.data;
  }

  onSubmit() {
    console.log("submit this.questionList")
    console.log(this.questionList)
    
    let questionListValue = [];
    let isError = false;
    for (let index in this.questionList) {
      if (this.questionList[index] != null) {
     

        if (this.questionList[index].value.type == 1 && this.questionList[index].value.responseOptionVO.id == '') {
          isError = true;
          this.questionList[index].controls.responseOptionVO.controls.id.markAsTouched();
        }
        if (this.questionList[index].value.type == 2 && this.questionList[index].value.responseOptionVO.qunatitativeScore == null ||
          this.questionList[index].value.type == 2 && this.questionList[index].value.responseOptionVO.qunatitativeScore == '') {
          isError = true;
          this.questionList[index].controls.responseOptionVO.controls.qunatitativeScore.markAsTouched();
          this.questionList[index].controls.responseOptionVO.controls.qunatitativeScore.markAsDirty();
        }
        if ((this.questionList[index].value.type == 1 && this.questionList[index].value.responseOptionVO.id != '') ||
          (this.questionList[index].value.type == 2 && this.questionList[index].value.responseOptionVO.qunatitativeScore != ""))
          questionListValue.push(this.questionList[index].value);
      }

    }
   console.log(isError)
    let assessmentResponseVO = {};
    assessmentResponseVO['userId'] = this.userId;
    assessmentResponseVO['assessmentId'] = this.assessmentId;
    assessmentResponseVO['saveFlag'] = 'N';
    assessmentResponseVO['questionList'] = questionListValue;
    console.log(questionListValue)
    if (!isError) {
      this.assessementTemlateMasterService.submitPanelMemberEvaluation(assessmentResponseVO).subscribe((myform) => {
        var message = "Successfully Submitted the Assessment. Pending Verification  by the Bank. Will be notified on Successful verification or Bank will revert for any additional details if required";
        swal({
          title: 'Success!',
          text: message,
          type: 'success',
          customClass: 'swal-wide',
          width: '800px',
          confirmButtonText: 'Ok'
        }).then((value) => {
          this.isSubmitted = true;
          // this.router.navigate(['/assessments']);
        });
        this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
          // this.router.navigate(['/assessments']);
          this.isSubmitted = true;
        });
      }, (error) => {
        console.log(error);
      });
    }
  }

  handleFileInput(files: FileList, node) {

    let file = files.item(0);
    let showName = file.name;
    let fileName = new Date().getTime() + "__" + file.name;
    // document.getElementById(upID).innerHTML= showName;
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    formdata.append('fileUploadName', fileName);
    formdata.append('companyCode', this.assessmentVO.companyVO.companyCode);
    formdata.append('assessmentId', this.assessmentId.toString());
    this.assessementTemlateMasterService.upload(formdata).subscribe(event => {
      if (event instanceof HttpResponse) {

        this.questionList[node.templateQuestionId].controls.responseOptionVO.patchValue({
          fileName: file.name,
          fileUploadName: fileName
        })

      }
    });
  }

  downloadAttachmentFile(templateQuestionId) {

    let fileUpload = this.questionList[templateQuestionId].controls.responseOptionVO.controls.fileUploadName.value;
    let filename = this.assessmentVO.companyVO.companyCode + "/" + this.assessmentId + "/" + fileUpload
    if (fileUpload != null && fileUpload != "") {

      window.location.href =
        this.assessementTemlateMasterService.getBaseUrl() +
        "/evidenceFiledownload?fileUploadName=" +
        encodeURIComponent(filename)
    }

  }
  loadfactors(factorId) {
    this.currentFactorId = factorId;
    let factorList = {};
    factorList = this.assessmentVO["assessmentResponseVO"].factorVOList.filter(
      factorVO => factorVO.id == factorId
    );

    this.selectedFactor = factorList[0];

  }
  showHistory(question) {

    this.displayedColumnsForHistory = [];
    this.originalColumnNamesForHistory = [];
    this.jsonArrayForHistory = []
    this.assessementTemlateMasterService.getDetailsForHistoryPopUp(this.companyAssessmentPanelMemberDetailsId, question.id).subscribe((data: any) => {
      var count = 0;

      for (let i of data) {
        var newArray = {};
        newArray['Sno'] = ++count;
        newArray['response'] = i.responseText;
        newArray['remarks'] = i.remarks;
        newArray['filename'] = i.fileName == null ? '---' : i.fileName;
        newArray['verificationRemarks'] = i.verificationRemarks == null ? "---" : i.verificationRemarks;
        newArray['verificationStatus'] = i.verificationStatus=='D'?'---':(i.verificationStatus=='V'?"Verified":"Additional Info");
        this.jsonArrayForHistory.push(newArray)
      }
      this.originalColumnNamesForHistory = ['S.No', "Response", "Remarks", "Filename", "Verification Remarks", "Verification Status"];
      this.displayedColumnsForHistory = ['Sno', "response", "remarks", "filename", "verificationRemarks", "verificationStatus"];

    },
      (error) => {
        console.log(error);
      });
  }

}







