import { Component, OnInit, Output,EventEmitter, Input, ViewChild, OnChanges, SimpleChanges  } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import {AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import { MatTableDataSource } from '@angular/material';
import swal from 'sweetalert2';
import {AddPanelComponent} from '../add-panel/add-panel.component';
import { AddEditSuccessComponent } from '../../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
@Component({
  selector: 'app-list-panel',
  templateUrl: './list-panel.component.html',
  styleUrls: ['./list-panel.component.css']
})
export class ListPanelComponent implements OnInit {

  panelList :  any;
  originalColumnNames : any;
  displayedColumns: any;
  colIndex: number = 0;
  jsonArray: any;
  userNames :String;
  fetchedpanelList:any =[];
  top:any;


  
  @Output() panelComponent = new EventEmitter();
  @Input() set topWrapper(val){
    this.top=val;
  }
  @Input() set panelDataList(val){

    this.fetchedpanelList = val;
    this.setPanelData(this.fetchedpanelList)
   
  }
  
  constructor(private addEditSuccessComponent: AddEditSuccessComponent,private assessmentTemplateService : AssessmentTemplateMasterdataService,
      private formBuilder : FormBuilder,private addPanelComponent:AddPanelComponent) { }

  ngOnInit() {
  
  }
  setPanelData(panelList){
    this.displayedColumns = [];
    this.originalColumnNames = [];
    this.jsonArray = [];
    var count = 0;
    for(let panel of panelList){
      var newArray= {};
      this.userNames = "";
     
      newArray['Sno'] = ++ count;
      newArray['id'] = panel.id;
      newArray['panelName'] = panel.panelName;
      newArray['editDelete'] =panel.editDelete;
      var userArray = [];
      for(let userVO of panel.userVOList){
        if(this.userNames!='undefined' && this.userNames){
        this.userNames = this.userNames+" ,"+userVO.name; 
        }
        else{
          this.userNames = userVO.name; 
        }
        userArray.push(userVO.id);
  
        }
      newArray['userIdArray']= userArray;
      newArray['userNames'] = this.userNames;
      this.jsonArray.push(newArray);
     }
      this.displayedColumns = ['Sno', 'panelName', 'userNames', 'Actions']
      this.originalColumnNames = ['Sl.No', 'Panel Name', 'User Name', 'Actions']
     
  }
   
  deletePanel(panelObj) {
    swal({
      title: '<p class="swal2-title-warning">Are You Sure?</p>',
      text: "You Want To Delete Panel",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'

    }).then((isConfirm) => {
      if (isConfirm.value) {

        
        this.assessmentTemplateService.deletePanel(panelObj.id).subscribe((data) => {
          // swal({
          //   title: 'Success!',
          //   text: 'Deleted Panel',
          //   timer: 2000,
          //   type: 'success',
          //   confirmButtonText: 'Ok'
          // })
          let message="Deleted Panel";
          this.addEditSuccessComponent.showSuccessSwal(message)
          .then((value) => {
            this.addPanelComponent.resetPanelForm();
            this.assessmentTemplateService.getPanelList().subscribe((data)=>{
              this.panelList = data;
              this.setPanelData(this.panelList)
              
               });
          });
        },
          (error) => { 
            console.log(error);
           });


      }


    });

  }

  editPanel(panelObj) {
    this.panelComponent.emit({ id: panelObj.id, panelName: panelObj.panelName, userIds: panelObj.userIdArray });
  }



}
