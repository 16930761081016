import { Component, OnInit, ViewChild } from '@angular/core';
import { DataQualityMasterService } from '../../data-quality-master.service';
import { DataAcqCommonService } from '../../data-acq-common.service';
import { FormGroup, FormArray, FormBuilder, Validators,FormControl } from '@angular/forms';
import {Observable, concat} from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import swal from 'sweetalert2';
import { callLifecycleHooksChildrenFirst } from '@angular/core/src/view/provider';
import { CustomValidationsService } from '../../../../shared/Validations/custom-validations.service';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { IndicatorMasterService } from '../../../indicator-master/indicator-master.service';



@Component({
  selector: 'app-add-parity-homogeneity-levels',
  templateUrl: './add-parity-homogeneity-levels.component.html',
  styleUrls: ['./add-parity-homogeneity-levels.component.css']
})
export class AddParityHomogeneityLevelsComponent implements OnInit {

    addParityHomogeneityForm: FormGroup;
    parityHomogeneityList : any=[];
    filteredOptions: Observable<string[]>;
    isEdit :boolean =false;
    constants: any;
    constantsList: any;
    constantType: String
    constantSelected: string;
    firstConstantVal:string;
    topWrapper:any;
    @ViewChild ("topWrap") topWrap;
    @ViewChild('parityHomogeneityForm') parityHomogeneityForm;

    constructor(private formBuilder: FormBuilder, private dataQualityMasterService: DataQualityMasterService, private constantsService: IndicatorMasterService,private customValidationsService:CustomValidationsService,private addEditSuccessComponent: AddEditSuccessComponent) {
      
    }
    getAllConstantTypes=[
      {"TYPE": "HOMOGENEITY"},
      {"TYPE": "PARITY"},
      ]
    defaultSelectType=[
      {"TYPE": "HOMOGENEITY"}
    ]
    defaultCat;
  
    ngOnInit() {
      
      this.defaultCat= this.defaultSelectType[0].TYPE;
      this.topWrapper=this.topWrap;
      this.getConstantList(0);
      this.addParityHomogeneityForm = this.formBuilder.group({
        optionRows: this.formBuilder.array([this.createFormGroup()])
      });
      this.manageNameControl(0);
    }

    get formArray() {
      return this.addParityHomogeneityForm.get('optionRows') as FormArray;
    }

   createFormGroup() {
     this.getConstantList(0);
      return this.formBuilder.group({
        id: [0],
        code: ['', [Validators.required,Validators.maxLength(50), Validators.pattern('^[a-zA-Z ]*$'),this.customValidationsService.noWhitespaceValidator]],
        value: ['', [Validators.required,Validators.maxLength(100), Validators.pattern('^[a-zA-Z ]*$'),this.customValidationsService.noWhitespaceValidator]],
        constantType: ['', [Validators.required]],
      });
    }
 

    addNewRow() {
      const controls = this.formArray;
      
      this.formArray.push(this.createFormGroup());
      this.manageNameControl(controls.length-1)
    }

    deleteRow(index: number) {
      this.formArray.removeAt(index);
      this.manageNameControl(0)
    }

    refreshList()
    {
      this.manageNameControl(0)
    }
  
    manageNameControl(index) {
      var arrayControl = this.addParityHomogeneityForm.get('optionRows') as FormArray;
      this.filteredOptions = arrayControl.at(index).get('constantType').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );

    }

    private _filter(value: any) {
      let filterValue = '';
      if(value){
        value = value.toString();
        filterValue = value.toLowerCase();
      }
      return this.parityHomogeneityList.filter(value => value.toLowerCase().includes(filterValue));
    }
    constantTypeChange(){
      this.firstConstantVal = this.addParityHomogeneityForm.get('optionRows').value[0].constantType;
    }
    onSubmit(){
    
      if(this.addParityHomogeneityForm.valid )
      {
      
        this.dataQualityMasterService.addParityHomogeneityConstants(this.addParityHomogeneityForm.get('optionRows').value).subscribe((myform)=>{


          if(this.isEdit)
            var message = "Successfully Edited Parity/Homogeneity value";
          else
            var message = "Successfully Added Parity/Homogeneity value";
            this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
              this.resetParityHomogeneityForm();
              this.getConstantList(0);
          //    this.manageNameControl(0);
          this.firstConstantVal=this.addParityHomogeneityForm.get('optionRows').value[0].constantType;
           });
      },(error)=>{
            console.log(error);
          });
        }
        else{
          
          let formArrayLastIndex = this.formArray.length;
          let index = 0;
          while (index !== formArrayLastIndex) {
            this.addParityHomogeneityForm.get('optionRows').get(index.toString()).get('constantType').markAsTouched();
            this.addParityHomogeneityForm.get('optionRows').get(index.toString()).get('code').markAsTouched();
            this.addParityHomogeneityForm.get('optionRows').get(index.toString()).get('value').markAsTouched();
            index++;
          }
          return;
        }
    }

    defaultValues(defaultArray)
    {
      this.isEdit = true;
      var formArrayLastIndex = this.formArray.length-1;
      while (formArrayLastIndex !== 0) {
        this.formArray.removeAt(formArrayLastIndex);
        formArrayLastIndex--;
      }
      this.formArray.at(0).patchValue(defaultArray);
      
    }

    resetParityHomogeneityForm() {

      this.isEdit = false;
      var formArrayLastIndex = this.formArray.length-1;
      while (formArrayLastIndex !== 0) {
        this.formArray.removeAt(formArrayLastIndex);
        formArrayLastIndex--;
      }
      this.parityHomogeneityForm.resetForm();
      this.topWrap.nativeElement.classList.remove("top-form-wrapper-highlight");
    }
   
    getConstantList(editId) {
      if(editId == 0){
        this.dataQualityMasterService.fetchParityHomogeneityConstants().subscribe(data => {
          this.constants = data;
        });
    }
    else{
      this.constantsService.getConstantsListByConstantType(this.constantSelected).subscribe(data => {
          });
    }
    }

}
