import { Component, OnInit } from '@angular/core';
import {FormGroup, ValidatorFn, AbstractControl} from '@angular/forms';
import {FormControl, FormBuilder, Validators  } from '@angular/forms';
import swal from 'sweetalert2';
import {PerformanceRecordService} from '../performance-record.service';
import { AddEditSuccessComponent } from '../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';

@Component({
  selector: 'app-performance-record-configuration',
  templateUrl: './performance-record-configuration.component.html',
  styleUrls: ['./performance-record-configuration.component.css']
})
export class PerformanceRecordConfigurationComponent implements OnInit {

  performanceRecordConfigurationForm: FormGroup;
  performanceRecordConfiguration: any;
    companyReportFrequency: any;
    response:any;
    constantsVOList:any;
    public freqMeasurmentList:any;
    public freq:number = null;
    
    constructor(private frameworkService: PerformanceRecordService, private formBuilder: FormBuilder,private addEditSuccessComponent: AddEditSuccessComponent) {
      this.performanceRecordConfigurationForm = this.createFormGroup();
        
     }
    ngOnInit() {
      this.loadPerformanceRecordConfiguration();
    }  

    loadPerformanceRecordConfiguration(){
      this.frameworkService.getperformanceRecordConfiguration().subscribe(performanceRecordConfiguration=>{
        this.performanceRecordConfiguration =performanceRecordConfiguration;
        this.performanceRecordConfigurationForm.patchValue(this.performanceRecordConfiguration);
        this.constantsVOList = performanceRecordConfiguration.constantsVOList;
        if(performanceRecordConfiguration.id == 0){
          this.performanceRecordConfigurationForm.get('companyReportFrequency').patchValue(this.constantsVOList[0].id);
        }
      });
    }

    createFormGroup() { 

       return this.formBuilder.group({
        id:[],
        companyReportFrequency:[],
        indicatorAlertPrompt:['', [Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
        indicatorReminderInterval: ['', [Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
        indicatorEscalationPrompt:['', [Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
        indicatorEscalationInterval:['', [Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
        noOfReminders: ['', [Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
        noOfEscaltion: ['', [Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
        gracePeriod: ['', [Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
        });
   
    }
  
    onSubmit() {  
      if(this.performanceRecordConfigurationForm.valid)
      {
        this.frameworkService.createRecordConfiguration(this.performanceRecordConfigurationForm.value).subscribe((myform)=>{
  
        if(this.performanceRecordConfigurationForm.get('id').value!=0)
            var message="Successfully Edited Configuration ";
           else
            var message = "Successfully Added Configuration";   
            this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
              this.resetForm();
            });	  
        },(error)=>{
          console.log(error);
        });
      }
    }

    resetForm() { 
        this.loadPerformanceRecordConfiguration();
    }

}
