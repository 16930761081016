import { Component, OnInit, Output,EventEmitter, Input, ViewChild, OnChanges, SimpleChanges  } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import {AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import { AddFactorComponent } from '../add-factor/add-factor.component';
import { AddEditSuccessComponent } from '../../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';


@Component({
  selector: 'app-list-factor',
  templateUrl: './list-factor.component.html',
  styleUrls: ['./list-factor.component.css']
})
export class ListFactorComponent implements OnInit {

  dataTableFlag: boolean;
  factorList:any=[];
  constantValues: any;
  displayedColumns: any;
  originalColumnNames : any;
  colIndex: number = 0;
  jsonArray: any;
  flag: any;
  top:any;
  
  @Input() set topWrapper(val){
    this.top=val;
  }
  @Input() set factorDataList(val) {
   this.factorList = val;
   this.setFactorData(this.factorList);
  }
  
  @Output() factorComponent = new EventEmitter();


  constructor(private assessmentTemplateService : AssessmentTemplateMasterdataService,  private addEditSuccessComponent: AddEditSuccessComponent,private addFactorComp :  AddFactorComponent,  private formBuilder : FormBuilder) { }

  ngOnInit() {
  
  }

  setFactorData(factorList)
  {
    this.displayedColumns = [];
    this.originalColumnNames = [];
    this.jsonArray = [];
    var count = 0;
    for (let i of factorList) {
      var newArray = {};
      newArray['Sno'] = ++count;
      newArray['id'] = i.id;
      newArray['Code'] = i.code;
      newArray['Name'] = i.name;
      newArray['editDelete'] =i.editDelete;
      this.jsonArray.push(newArray);
  
    }

    this.displayedColumns = ['Sno', 'Code', 'Name', 'Actions']
    this.originalColumnNames = ['Sl.No', 'Code', 'Name', 'Actions']
  }


  editFactor(factorObj){
    this.factorComponent.emit({id : factorObj.id, factorCode : factorObj.Code, factorName : factorObj.Name})

  }
 
  deleteFactor(factorObj) {
    var confirmMessage = "You Want To Delete Factor";
    this.addEditSuccessComponent.showConfirmationSwal(confirmMessage,'Yes','No').then((isConfirm) => {
      if (isConfirm.value) {
        
        this.assessmentTemplateService.deleteFactor(factorObj.id).subscribe((data) => {
          var deleteSuccess = 'Deleted Factor';
            this.addEditSuccessComponent.showSuccessSwal(deleteSuccess)
              .then((value) => {
            this.addFactorComp.getFactorList();
            this.addFactorComp.resetfactorForm();
          });
        },
          (error) => { 
            console.log(error);
           });


      }


    });
  }
}
