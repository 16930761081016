import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { IndicatorMasterService } from '../../indicator-master.service';
import { AddEditSuccessComponent } from '../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import{  AddSubcategoryComponent } from '../add-subcategory/add-subcategory.component';
import { BaseUrlService } from '../../../../shared/base-url.service';

@Component({
  selector: 'app-list-subcategory',
  templateUrl: './list-subcategory.component.html',
  styleUrls: ['./list-subcategory.component.css']
})
export class ListSubcategoryComponent implements OnInit {

  categoryId: number;
  subCategories: any;
  displayedColumns: any;
  originalColumnNames: any;
  top: any;
  jsonArray : any= [];
  standardizedSubCategoryCodes : any = [];

  constructor(private baseUrlService:BaseUrlService, private indicatorMasterService: IndicatorMasterService, private addEditSuccessComponent: AddEditSuccessComponent, private addSubCategoryComponent : AddSubcategoryComponent) { }

  ngOnInit() {

    this.standardizedSubCategoryCodes = this.baseUrlService.getListOfStandardizedPerformanceAreaCodes();
  }
  
  @Input() set topWrapper(val) {
    this.top = val;
  }
  @Input() set categorySelected(val) {
    this.categoryId = val;
  }

  @Output() subCategoryComponent = new EventEmitter();

  ngOnChanges(changes: SimpleChanges) {

    if (changes['categorySelected']) {
      this.getSubCategoryList(changes['categorySelected'].currentValue);
    }
  }

  getSubCategoryList(categoryId) {

    this.indicatorMasterService.getSubCategories(categoryId).subscribe(data => {
      this.subCategories = data;
      if (this.subCategories.length != 0)
        this.dataTablePopulation(this.subCategories);
      else
        this.jsonArray = null;
    });
  }

  dataTablePopulation(subCategories) {

    this.displayedColumns = [];
    this.originalColumnNames = [];
    
    var count = 0;
    this.jsonArray=[];
    for (let subcategory of subCategories) {

      var newArray = {};
      newArray['Sno'] = ++count;
      newArray['id'] = subcategory.id;
      newArray['Code'] = subcategory.code;
      newArray['Name'] = subcategory.name;
      if(subcategory.indicatorCategoryVO){
        newArray['indCategoryCode'] = subcategory.indicatorCategoryVO.code;
        newArray['indCategoryId'] = subcategory.indicatorCategoryVO.id;
        newArray['Category'] = subcategory.indicatorCategoryVO.name;
      }

      if(this.standardizedSubCategoryCodes.includes(subcategory.code)){
        newArray['editDelete'] = 'B'; //both disable delete and edit
      }
      this.jsonArray.push(newArray);
    }
    this.displayedColumns = ['Category', 'Code', 'Name', 'Actions'];
    this.originalColumnNames = ['Category', 'Performance Area Code', 'Performance Area Name', 'Actions'];
  }

  edit(subCategoryObj){
    this.subCategoryComponent.emit({ id:subCategoryObj.id, indCategoryId: subCategoryObj.indCategoryId, code: subCategoryObj.Code, name:subCategoryObj.Name, categoryCode: subCategoryObj.indCategoryCode, categoryName:subCategoryObj.Category });
  }

  delete(subCategoryObj){  
    let confirmMessage = "You Want To Delete The Performance Area";
    this.addEditSuccessComponent.showConfirmationSwal(confirmMessage, 'Yes', 'No').then((isConfirm) => {
      if(isConfirm.value == true){
        this.indicatorMasterService.deleteSubCategory(subCategoryObj.id).subscribe((value)=>{
            let message = "Deleted Performance Area";
            this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
                this.getSubCategoryList(0);
                this.addSubCategoryComponent.resetSubcategoryForm();
            });
        });
      }
    });
  }
}