import { Component, OnInit, EventEmitter, Output,ViewChild,SimpleChanges,OnChanges,Input } from '@angular/core';
import { IndicatorMasterService } from '../../../indicator-master/indicator-master.service';
import { AddUnitOfMeasurementComponent } from '../add-unit-of-measurement/add-unit-of-measurement.component';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component'; 

@Component({
  selector: 'app-list-unit-of-measurement',
  templateUrl: './list-unit-of-measurement.component.html',
  styleUrls: ['./list-unit-of-measurement.component.css']
})
export class ListUnitOfMeasurementComponent implements OnInit {
  unitofmeasurements: any;
  displayedColumns : any;
  originalColumnNames : any;
  jsonArray:any;
  top:any;
  @Input() set topWrapper(val){
    this.top=val;
  }
  @Output() unitOfMeasurement = new EventEmitter();

  @Input() groupSelected;

  constructor(private addEditSuccessComponent: AddEditSuccessComponent,private indicatorMasterService:IndicatorMasterService,private unitofmeasurementComponent :  AddUnitOfMeasurementComponent) { }

  ngOnInit() { 
    this.getUnitGroupList(0);  
   } 

  ngOnChanges(changes: SimpleChanges) {
    if (changes['groupSelected']) {      
      this.getUnitGroupList(changes['groupSelected'].currentValue);   
    }
  }

  edit(unitOfMeasurementObj){  

      this.unitOfMeasurement.emit({ id:unitOfMeasurementObj.id, code: unitOfMeasurementObj.code, name: unitOfMeasurementObj.name,unitGroupVO:unitOfMeasurementObj.unitGroupVO
      });      
  }

  delete(unitOfMeasurementObj) {
    var message ="You Want To Delete Unit of Measurement";
    this.addEditSuccessComponent.showConfirmationSwal(message,"Yes","No").then((isConfirm) => {
      if (isConfirm.value) {
        this.indicatorMasterService.deleteUnitofMeasurement(unitOfMeasurementObj.id).subscribe((data) => {
          this.addEditSuccessComponent.showSuccessSwal("Deleted Unit of Measurement").then((value) => {
            this.getUnitGroupList(0);
            this.unitofmeasurementComponent.resetUnitOfMeasurementForm();
        
          });
        });
      }
    });
}
getUnitGroupList(groupSelected){
  this.indicatorMasterService.getunitOfMeasurementList(groupSelected).subscribe(data => {      
    this.unitofmeasurements = data;  
  
    if(data){
       this.fetchDataTable(this.unitofmeasurements);
    }      
  });
}

fetchDataTable(unitofmeasurements){
  this.displayedColumns = [];
  this.originalColumnNames = [];
  this.jsonArray = [];
  var count =0;
  for( let i of unitofmeasurements){
    
    
    var newArray = {};
    newArray['Sno'] = ++count;
    newArray['id'] = i.id;
    newArray['code'] = i.code;
    newArray['name'] = i.name;
    newArray['unitGroupVO'] = i.unitGroupVO;
    newArray['unitGroupName'] = i.unitGroupVO.name;
    this.jsonArray.push(newArray);
    
   }
  this.displayedColumns=['Sno','code','name','unitGroupName','Actions']
  this.originalColumnNames =['Sl.No','Code','Name','Unit Group Name','Actions']
}

}
