import { Component, OnInit , Input , Output ,EventEmitter} from '@angular/core';
import swal from 'sweetalert2';
import { IndicatorMasterService } from '../../../indicator-master/indicator-master.service';
import { AddDatapointComponent } from '../add-datapoint/add-datapoint.component';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';

@Component({
  selector: 'app-list-datapoint',
  templateUrl: './list-datapoint.component.html',
  styleUrls: ['./list-datapoint.component.css']
})
export class ListDatapointComponent implements OnInit {
  jsonArray: any[];
  originalColumnNames: any[];
  displayedColumns: any[];
  dataPoints: any;
  top: any;
  @Input() set topWrapper(val) {
    this.top = val;
  }
  @Input() set dataPointList(val) {
    this.dataPoints = val;
    this.dataTablePopulation(this.dataPoints);
  }
  @Output() dataPointComponent = new EventEmitter();
  constructor(private indicatorMasterDataService: IndicatorMasterService, private addDataPointComponent: AddDatapointComponent, private addEditSuccessComponent: AddEditSuccessComponent) { }

  ngOnInit() {
 }
  dataTablePopulation(dataPoints) {
    this.displayedColumns = [];
    this.originalColumnNames = [];
    this.jsonArray = [];
    var count = 0;
    for (let i of dataPoints) {
      var newArray = {};
      newArray['Sno'] = ++count;
      newArray['id'] = i.id;
      newArray['Code'] = i.datapointCode;
      newArray['Name'] = i.name;
      newArray['editDelete'] = i.editDelete;
      this.jsonArray.push(newArray);

    }
    this.displayedColumns = ['Sno', 'Code', 'Name', 'Actions']
    this.originalColumnNames = ['Sl.No', 'Code', 'Name', 'Actions']
  }
  edit(dataPointObj) {
    this.dataPointComponent.emit({ id: dataPointObj.id, datapointCode: dataPointObj.Code, name: dataPointObj.Name })

  }
  
  delete(dataPointObj) {
    var confirmMessage = "You Want To Delete Data Point";
    this.addEditSuccessComponent.showConfirmationSwal(confirmMessage,'Yes','No').then((isConfirm) => {
      if (isConfirm.value) {
        this.indicatorMasterDataService.deleteDataPoint(dataPointObj.id).subscribe((data) => {
            var deleteSuccess = 'Deleted Data Point';
            this.addEditSuccessComponent.showSuccessSwal(deleteSuccess)
              .then((value) => {
              this.addDataPointComponent.getDataPointList();
            	this.addDataPointComponent.resetDataPointForm();
              });
        },(error)=>{
  
        });
      }
    });
  }

}
