import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MatTableDataSource, MatPaginator, MatSort } from "@angular/material";

import { Observable } from "rxjs";
import {
  FormControl,
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  NgForm,
  AbstractControl,
  AbstractControlOptions,
  ValidationErrors,
  FormGroupDirective,
} from "@angular/forms";
import swal from "sweetalert2";
import { ConfigurationService } from "../../configuration.service";
import { CustomValidationsService } from "../../../../shared/Validations/custom-validations.service";
import { AddEditSuccessComponent } from "../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component";
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from "@angular/material";
import { AppDateAdapter, APP_DATE_FORMATS } from "../../../commonFunctions/Script/date.adapter";
import { BaseUrlService } from "../../../../shared/base-url.service";
import { HttpResponse, HttpParameterCodec, HttpUrlEncodingCodec, HttpErrorResponse } from "@angular/common/http";
import { DataAcqCommonService } from "../../../../../../src/app/modules/data-quality-master/data-acq-common.service";
import { map } from "rxjs/operators";
import { ParametersService } from "../../../report/ParameterDashboard/parameters.service";
import { ConsoleLogger } from "@aws-amplify/core";
import { forEach } from "@angular/router/src/utils/collection";
@Component({
  selector: "app-add-company",
  templateUrl: "./add-company.component.html",
  styleUrls: ["./add-company.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS,
    },
  ],
})
export class AddCompanyComponent implements OnInit {
  sisterCompaniesToDisableList: any = [];
  sisterCompanyList: any;
  parityList: any;
  homogenityList: any;
  addCompanyForm: FormGroup;
  companyTypes: any;
  users: any;
  response: any;
  yearList: any;
  codeValue: string;
  companyList: any = [];
  sectorList: any;
  topWrapper: any;
  companyUrl: string[];
  updateCompany: boolean;
  public totalfiles: Array<File>;
  companywithLatestFiles: any = [];
  recentYears: string;
  public numOfyears = 5;
  displayedColumnswithLatestfiles: any;
  dataSourcewithLatestfiles: any = [];
  jsonArraywithLatestfiles: any;
  top: any;
  public loading = false;
  filter: string;
  subSectorList: any;
  countryList: any;
  regionList: any;
  identifiersList = ["LEI", "ISIN", "NAME"];

  @ViewChild("topWrap") topWrap;
  @ViewChild("companyForm") companyForm;
  @ViewChild(MatSort) sort: MatSort;
  paginator: any;
  countryIds: any[];
  @ViewChild("MatPaginator") set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSourcewithLatestfiles.paginator = this.paginator;
    this.dataSourcewithLatestfiles.sort = this.sort;
  }
  constructor(
    private dataAcqCommonService: DataAcqCommonService,
    private configurationService: ConfigurationService,
    private formBuilder: FormBuilder,
    private customValidationsService: CustomValidationsService,
    private addEditSuccessComponent: AddEditSuccessComponent,
    private baseUrlService: BaseUrlService,
    private _parametersService: ParametersService
  ) {
    this.addCompanyForm = this.createFormGroup();
  }

  ngOnInit() {
    this.totalfiles = [];
    //this.subSectorList=[]
    this.yearList = this.configurationService.getYearList();
    this.getCompanyTypeList();
    this.getUsersList();
    this.companiesList();
    this.topWrapper = this.topWrap;
    this.updateCompany = false;
    this.getHomogenityValues();
    this.getParityValues();
    this.getSectorList();
    //this.getCountryList();
    this.getRegionList();
  }
  getCompanyTypeList() {
    this.configurationService.getListOfCompanyTypes().subscribe(
      data => {
        this.companyTypes = data;
      },
      error => {
        console.log(error);
      }
    );
  }
  getSectorList() {
    console.log("getSectorList");
    this.configurationService.getSubParameters(1).subscribe(
      parameterLevel => {
        this.sectorList = parameterLevel;
        console.log(this.sectorList);
      },
      error => {
        console.log(error);
      }
    );
  }
  getCountryList() {
    this.configurationService.getListOfCountries().subscribe(
      data => {
        console.log("country list");
        console.log(data);
        this.countryList = data;
      },
      error => {
        console.log(error);
      }
    );
  }
  onSectorChange(parameterId) {
    console.log("onSectorChange");
    this.subSectorList = [];
    console.log(parameterId);
    this.configurationService.getParameterLevelDetails(4, parameterId).subscribe(
      parameterLevel => {
        console.log("SUbb Sectorlist");
        console.log(this.addCompanyForm.controls.subsectorId.value);
        console.log(parameterLevel);
        this.subSectorList = parameterLevel;
      },
      error => {
        console.log(error);
      }
    );
  }
  getUsersList() {
    let masterRequestVO;
    masterRequestVO = {
      userList: true,
    };
    this.baseUrlService.getListOfUsers(masterRequestVO).subscribe(
      data => {
        this.response = data;
        this.users = this.response.userVOList;
      },
      error => {
        console.log(error);
      }
    );
  }
  companiesList() {
    this.configurationService.getListOfCompanies().subscribe(
      data => {
        this.companyList = data;
        this.sisterCompanyList = data;
      },
      error => {
        console.log(error);
      }
    );
  }

  createFormGroup() {
    return this.formBuilder.group(
      {
        id: [],
        companyCode: [
          "",
          [Validators.required, Validators.maxLength(25), this.customValidationsService.noWhitespaceValidator],
          this.uniqueCodeValidator(),
        ],
        companyName: [
          "",
          [Validators.required, Validators.maxLength(250), this.customValidationsService.noWhitespaceValidator],
        ],
        sectorId: ["", [Validators.required]],
        subsectorId: ["", [Validators.required]],
        countryId: ["", [Validators.required]],
        companyTypeVO: this.formBuilder.group({ code: [], name: [], id: [] }),
        responsibleUser: this.formBuilder.group({ name: [], userName: [], id: [] }),
        companyUrlList: this.formBuilder.array([this.getCompanyUrl()]),
        companyDetailsVOList: this.formBuilder.array([this.getCompanyDetailsVO()]),
        parentCompanyVO: this.formBuilder.group({ id: "" }),
        homogenityVO: this.formBuilder.group({ id: [{ value: "", disabled: true }] }),
        sisterCompaniesVOList: this.formBuilder.array([this.getSisterCompaniesForm()]),
        regionId: ["", [Validators.required]],
        lei: ["", [Validators.required]],
        isin: ["", [Validators.required]],
        primaryEmail: [
          "",
          [
            Validators.required,
            this.customValidationsService.noWhitespaceValidator,
            Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
          ],
        ],
        secondaryEmail: [
          "",
          [
            Validators.required,
            this.customValidationsService.noWhitespaceValidator,
            Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
          ],
        ],
      },
      { updateOn: "blur" }
    );
  }

  getCompanyDetailsVO() {
    return this.formBuilder.group({
      id: [0],
      year: [""],
      fileName: [""],
      fileUploadName: [""],
    });
  }
  getSisterCompaniesForm() {
    return this.formBuilder.group({
      id: [""],
      parityVO: this.formBuilder.group({ id: [{ value: "", disabled: true }] }),
    });
  }

  //Removing Validations since its not required and patching default value in submit method (onSubmit()) and removed required attribute in html
  getCompanyUrl() {
    return this.formBuilder.group({
      //[Validators.required, this.customValidationsService.noWhitespaceValidator, Validators.pattern(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)]
      companyUrl: [""],
    });
  }

  addNewRow() {
    const controls = this.formArray;
    this.formArray.push(this.getCompanyDetailsVO());
  }

  deleteRow(index: number, fileUpload: any) {
    this.formArray.removeAt(index);
    if (this.formArray.length == 0)
      //deleted all the file fields to retain one
      this.addNewRow();
    if (this.totalfiles[index]) this.totalfiles.splice(index, 1);
    // if(fileUpload.value.id!=0)
    // {
    //   this.configurationService.deletecompanyDetails(fileUpload.value.id).subscribe(event => {
    //     swal({
    //       title: 'Success!',
    //       text: 'Deleted file',
    //       timer: 2000,
    //       type: 'success',
    //       confirmButtonText: 'Ok'
    //     })
    //     });
    // }
  }

  addNewUrlRow() {
    const controlsUrl = this.formUrlArray;
    this.formUrlArray.push(this.getCompanyUrl());
  }

  deleteUrlRow(index: number) {
    this.formUrlArray.removeAt(index);
  }

  get formUrlArray() {
    return this.addCompanyForm.get("companyUrlList") as FormArray;
  }

  get formArray() {
    return this.addCompanyForm.get("companyDetailsVOList") as FormArray;
  }
  public handleFileInput(fileInput: any, index: number) {
    //document.getElementById("uploadFile"+index).classList.remove("display-none")
    // document.getElementById("uploadFile"+index).classList.add("display-block")
    document.getElementById("uploadFile" + index).innerHTML = fileInput.item(0).name;
    this.addCompanyForm.controls.companyDetailsVOList["controls"][index].controls.fileName.value = fileInput.item(0);
    this.addCompanyForm.controls.companyDetailsVOList["controls"][index].controls.fileName.setValidators([
      this.customValidationsService.fileExtension,
      this.customValidationsService.fileSize,
    ]);
    this.addCompanyForm.controls.companyDetailsVOList["controls"][index].controls.fileName.updateValueAndValidity();
    if (this.updateCompany) document.getElementById("uploadedFile" + index).classList.add("display-none");
    if (this.addCompanyForm.controls.companyDetailsVOList["controls"][index].controls.year.value == "")
      // on edit validation is not hapening bcoz value is "" to restrict that
      this.addCompanyForm.controls.companyDetailsVOList["controls"][index].controls.year.value = null;
    this.addCompanyForm.controls.companyDetailsVOList["controls"][index].controls.year.setValidators([
      Validators.required,
    ]);
    this.addCompanyForm.controls.companyDetailsVOList["controls"][index].controls.year.updateValueAndValidity();
    this.totalfiles[index] = fileInput.item(0);
  }
  download(fileUpload) {
    let fileName = this.codeValue + "/" + String(fileUpload.year) + "/" + fileUpload.fileUploadName;
    window.location.href =
      this.configurationService.downloadFile() +
      "/download?filename=" +
      encodeURIComponent(fileName) +
      "&actualName=" +
      encodeURIComponent(fileUpload.fileName);
  }
  defaultValues(companyObj) {
    console.log("companyObj");
    console.log(companyObj);
    this.totalfiles = [];
    this.updateCompany = true;
    this.addCompanyForm.patchValue({
      id: companyObj.id,
      companyCode: companyObj.companyCode,
      companyName: companyObj.companyName,
      parentCompanyVO: companyObj.parentCompanyVO,
      homogenityVO: companyObj.homogenityVO,
      sectorId: companyObj.sectorId,
      subsectorId: companyObj.subsectorId,
      countryId: companyObj.countryId,
      regionId: companyObj.regionId,
      lei: companyObj.lei,
      isin: companyObj.isin,
      primaryEmail: companyObj.primaryEmail,
      secondaryEmail: companyObj.secondaryEmail,
    });
    if (this.companyTypes.some(e => e.id === companyObj.companyTypeVO.id)) {
      // if company type removed then restricting patching
      this.addCompanyForm.patchValue({ companyTypeVO: companyObj.companyTypeVO });
    }
    if (this.users.some(e => e.name === companyObj.responsibleUser.name)) {
      this.addCompanyForm.patchValue({ responsibleUser: companyObj.responsibleUser });
    }

    this.addCompanyForm.removeControl("companyDetailsVOList");
    this.addCompanyForm.addControl("companyDetailsVOList", this.formBuilder.array([]));
    companyObj.companyDetailsVOList.forEach(parameter => {
      this.addNewRow();
    });
    if (companyObj.companyDetailsVOList.length == 0) this.addNewRow();
    this.formArray.patchValue(companyObj.companyDetailsVOList);
    this.addCompanyForm.removeControl("companyUrlList");
    this.addCompanyForm.addControl("companyUrlList", this.formBuilder.array([]));
    let companyList = [];
    companyObj.companyUrlList.forEach(parameter => {
      this.addNewUrlRow();

      companyList.push({ companyUrl: parameter });
    });
    companyObj.companyUrlList = companyList;
    this.formUrlArray.patchValue(companyObj.companyUrlList);
    this.codeValue = companyObj.companyCode;
    this.addCompanyForm.removeControl("sisterCompaniesVOList");
    this.addCompanyForm.addControl("sisterCompaniesVOList", this.formBuilder.array([]));
    if (companyObj.sisterCompaniesVOList.length == 0) {
      this.addNewSisterCompanyRow();
    }
    this.sisterCompanyList = this.sisterCompanyList.filter(company => company.id != companyObj.id);
    companyObj.sisterCompaniesVOList.forEach(sisterCompany => {
      this.addNewSisterCompanyRow();
      var formArrayLastIndex = this.formCompanyArray.length - 1;
      this.formCompanyArray
        .at(formArrayLastIndex)
        .get("id")
        .valueChanges.subscribe(sisterCompanyId => {
          if (sisterCompanyId > 0) this.formCompanyArray.at(formArrayLastIndex).get("parityVO").enable();
          this.sisterCompaniesToDisableList.push(sisterCompanyId);
        });

      this.formCompanyArray.at(formArrayLastIndex).patchValue({
        id: sisterCompany.id,
        parityVO: sisterCompany.parityVO,
      });
    });
  }

  onSubmit() { 

    console.log(this.addCompanyForm.value) 

    if (this.addCompanyForm.valid) { 

      if(this.addCompanyForm.controls.parentCompanyVO!=null && this.addCompanyForm.controls.parentCompanyVO.get('id').value==0) 

      { 

        this.addCompanyForm.value.parentCompanyVO=null 

      } 

  

      if (this.addCompanyForm.value.sisterCompaniesVOList != null && this.addCompanyForm.value.sisterCompaniesVOList.length==1) { 

        let sisterCompanyObj=this.addCompanyForm.value.sisterCompaniesVOList.find(sisterCompanyObj=>sisterCompanyObj.id==0) 

        if(sisterCompanyObj){ 

          this.addCompanyForm.value.sisterCompaniesVOList=[]; 

        } 

      } 

      if (this.addCompanyForm.value.companyDetailsVOList != null) { 

        let yearFileList = []; 

        this.addCompanyForm.value.companyDetailsVOList.forEach((element, index) => { 

          if (element.year != null && element.year != "")//to remove 0 entry to database if single row 

          { 

            if (this.totalfiles[index])//file uploaded 

            { 

              const formdata: FormData = new FormData(); 

              element.fileName = this.totalfiles[index].name; 

              let uploadName = (this.totalfiles[index].name).split(' ').join('_'); 

              element.fileUploadName = new Date().getTime() + "__" + uploadName; 

              formdata.append('file', this.totalfiles[index]); 

              formdata.append('fileName', element.fileUploadName); 

              formdata.append('year', element.year); 

              formdata.append('code', this.addCompanyForm.value.companyCode); 

              this.configurationService.upload(formdata).subscribe(event => { 

                if (event instanceof HttpResponse) { 

                  //  this.fileName = this.totalfiles[index].name;; 

                  //  this.fileUploadName = new Date().getTime()+"__"+ this.totalfiles[index].name;; 

                } 

              }); 

            } 

            yearFileList.push(element); 

          } 

  

        }); 

        this.addCompanyForm.value.companyDetailsVOList = yearFileList; 

      } 

      console.log("this.addCompanyForm.value"); 

      console.log(this.addCompanyForm.value); 

      this.configurationService.createCompany(this.addCompanyForm.value).subscribe((myform) => { 

        if (this.addCompanyForm.get('id').value != null) 

          var message = "Successfully Edited Company "; 

        else 

          var message = "Successfully Added Company"; 

        this.addEditSuccessComponent.showSuccessSwal(message).then((value) => { 

          this.resetCompanyForm(); 

  

        }); 

  

      }, (error) => { 

  

        console.log(error); 

      }); 

    } 

  } 
  resetCompanyForm() {
    this.companyForm.resetForm();
    this.totalfiles = [];
    this.addCompanyForm.removeControl("companyDetailsVOList");
    this.addCompanyForm.addControl("companyDetailsVOList", this.formBuilder.array([]));
    this.addNewRow();
    this.addCompanyForm.removeControl("companyUrlList");
    this.addCompanyForm.addControl("companyUrlList", this.formBuilder.array([]));
    this.addNewUrlRow();
    this.addCompanyForm.removeControl("sisterCompaniesVOList");
    this.addCompanyForm.addControl("sisterCompaniesVOList", this.formBuilder.array([]));
    this.addNewSisterCompanyRow();
    this.sisterCompaniesToDisableList = [];
    this.companiesList();
  }
  resetReportDetails() {
    this.filter = "";
  }
  getAllFiles() {
    this.loading = true;
    this.recentYears = this.getLastYears(this.numOfyears);
    this.configurationService.getFiledetailsOfCompanies().subscribe(
      data => {
        this.loading = false;
        this.companywithLatestFiles = data;
        this.fetchDataTableForFiles(this.companywithLatestFiles);
      },
      error => {}
    );
  }
  fetchDataTableForFiles(companies) {
    this.displayedColumnswithLatestfiles = [];
    this.dataSourcewithLatestfiles = [];
    let jsonArray = [];
    this.displayedColumnswithLatestfiles.push("Name");
    for (let k = 0; k < this.numOfyears; k++) {
      this.displayedColumnswithLatestfiles.push(String(this.recentYears[k]));
    }
    for (let i of companies) {
      var newArray = {};
      newArray["id"] = i.id;
      newArray["Name"] = i.companyName;
      for (let k = 0; k < this.numOfyears; k++) {
        newArray[String(this.recentYears[k])] = i.yearWiseFile[this.recentYears[k]];
        if (i.yearWiseFile[this.recentYears[k]] != null)
          newArray[String(this.recentYears[k])] = i.yearWiseFile[this.recentYears[k]].join(",");
      }
      jsonArray.push(newArray);
    }
    this.dataSourcewithLatestfiles = new MatTableDataSource(jsonArray);
    console.log(this.dataSourcewithLatestfiles);
  }

  getLastYears(num) {
    let years: any = [];
    let currentYear = new Date().getFullYear();
    for (let i = 0; i < num; i++) {
      years[i] = currentYear - i;
    }
    return years;
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSourcewithLatestfiles.filter = filterValue;
  }
  // clearFile(index : number)
  // {

  //     //  this.formArray[index].controls.fileName.value="";
  //     //  this.formArray[index].controls.fileUploadName="";
  //     //  if(this.updateCompany)
  //     //  {
  //     //   this.addCompanyForm.value.companyDetailsVOList[index].fileName = "";
  //     //   this.addCompanyForm.value.companyDetailsVOList[index].fileUploadName = "";
  //     //  }
  //     // document.getElementById("uploadFile"+index).classList.add("display-none")
  //     // document.getElementById("uploadFile"+index).classList.remove("display-block")

  //         //  console.log('thuis form array')

  //         //  console.log('formmmmm')
  //           console.log( this.addCompanyForm.value.companyDetailsVOList)
  //           console.log(this.formArray)
  //      if(this.updateCompany)
  //      {
  //         document.getElementById("uploadedFile"+index).classList.add("display-none")
  //        // this.formArray.controls[index].controls.fileName.value="";
  //        // this.formArray[index].controls.fileUploadName="";
  //         if(this.updateCompany)
  //         {
  //           this.addCompanyForm.value.companyDetailsVOList[index].fileName = "";
  //           this.addCompanyForm.value.companyDetailsVOList[index].fileUploadName = "";
  //         }
  //         this.addCompanyForm.controls.companyDetailsVOList['controls'][index].controls.fileName.setValidators([Validators.required]);
  //         this.addCompanyForm.controls.companyDetailsVOList['controls'][index].controls.fileName.updateValueAndValidity();

  //      }

  // }

  addNewSisterCompanyRow() {
    //const controlsUrl = this.formCompanyArray;
    this.formCompanyArray.push(this.getSisterCompaniesForm());
  }

  deleteSisterCompanyRow(index: number) {
    let indexOfCompany = this.sisterCompaniesToDisableList.indexOf(this.formCompanyArray.at(index).get("id").value);
    this.sisterCompaniesToDisableList.splice(indexOfCompany, 1);
    this.formCompanyArray.removeAt(index);
  }

  get formCompanyArray() {
    return this.addCompanyForm.get("sisterCompaniesVOList") as FormArray;
  }

  getHomogenityValues() {
    let homogenity = this.dataAcqCommonService.getHomogeneity();
    this.dataAcqCommonService.getDataAcqMasterData(homogenity).subscribe(data => {
      this.homogenityList = data.constantsVOList;
    });
  }
  getParityValues() {
    let parity = this.dataAcqCommonService.getParity();
    this.dataAcqCommonService.getDataAcqMasterData(parity).subscribe(data => {
      this.parityList = data.constantsVOList;
    });
  }
  onParentCompanyChange(companyId) {
    if (companyId > 0) {
      // this.addCompanyForm.controls.homogenityVO.enable();
      this.sisterCompanyList = this.companyList.filter(sisterCompany => sisterCompany.id != companyId);
    } else {
      //this.addCompanyForm.controls.homogenityVO.disable();
    }
  }
  onSisterCompanyChange(companyId, formControl) {
    if (companyId > 0) {
      formControl.enable();
      this.sisterCompaniesToDisableList = [];
      this.addCompanyForm.controls.sisterCompaniesVOList.value.forEach(element => {
        this.sisterCompaniesToDisableList.push(element.id);
      });
    }
  }
  uniqueCodeValidator() {
    return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      var id = 0;
      if (this.addCompanyForm && this.addCompanyForm.controls.id.value) {
        id = this.addCompanyForm.controls.id.value;
      }

      return this.configurationService.validateCompanyCode(id, c.value).pipe(
        map(val => {
          if (val == 1) {
            return { uniqueCode: true };
          }
        })
      );
    };
  }
  getRegionList() {
    console.log("getRegionList");
    this.configurationService.getSubParameters(2).subscribe(
      parameterLevel => {
        console.log("regionList list");
        console.log(parameterLevel);
        this.regionList = parameterLevel;
        console.log(this.regionList);
      },
      error => {
        console.log(error);
      }
    );
  }
  onRegionChange(parameterId) {
    this.countryList = [];
    console.log("onRegionChange222");
    console.log(parameterId);
    if (parameterId != null) {
      this.configurationService.getParameterLevelDetails(5, parameterId).subscribe(
        parameterLevel => {
          console.log("countries");
          console.log(parameterLevel);
          this.countryIds = [];
          this.countryList = parameterLevel;
          for (let country of this.countryList) {
            this.countryIds.push(country.id);
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  }
}
