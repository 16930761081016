import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ImpactAssessmentService } from '../../../impact-assessment.service';
import { TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { ReportConstantsComponent } from '../../../../report-constants/report-constants.component';
import { CompanyService } from '../../../../CompanyDashboard/company.service';

@Component({
  selector: 'app-company-level-drill-down',
  templateUrl: './company-level-drill-down.component.html',
  styleUrls: ['./company-level-drill-down.component.css']
})
export class CompanyLevelDrillDownComponent implements OnInit {

  companyVOList: any = [];
  currentLevelindicatorList: any = [];
  currentLevelName: string;
  jsonArrayCurrentLevelList = [];
  jsonArrayCompanyList = [];
  jsonArrayCompanyIndicatorList = [];
  displayedColumnsCompanyList = [];
  displayedColumnsCompanyIndicators = [];
  displayedColumnsCurrentLevelIndicator = [];
  dataSourceCurrentLevelIndicator: any = [];
  dataSourceCompanyIndicators: any = [];
  dataSourceCurrentLevelIndicatorPopUp: any = [];
  displayColCompany = [];
  id: number;
  company_id: any;
  targetFlagTrans = [];
  listTransIndicatorsSize = [];
  companyData = [];
  doughnutData: any;
  chartOptions: any;
  chartColors: any;
  doughnutChartType: string;
  flag: boolean;
  levelNo: number;
  parameterId: any;
  loading: boolean;
  currency: string;
  locale: string;
  jsonArray: any;
  dataSourceTrans: any;
  displayedColumns: any;
  indArr: any;
  dataSourcePopUp: any;
  dataSourceCompanyList: any;
  targetFlagCompanyIndicatorPopUp: any;
  impactFlag: number;
  impactName: string;
  capitalId: any;
  sdgId: any;
  subCatId: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  constructor(private companyDataService : CompanyService,private _impactAssessmentService: ImpactAssessmentService, private _router: Router, private activatedRoute: ActivatedRoute) {
    this.displayedColumnsCompanyList = ['companyDetails'];
    this.doughnutChartType = 'doughnut';
    this.loading = false;
    this.impactName = this.activatedRoute.snapshot.queryParamMap.get('impactName');
    let impactFlag = this.activatedRoute.snapshot.queryParamMap.get('impactFlag');
    this.impactFlag = +impactFlag;
    let num = this.activatedRoute.snapshot.queryParamMap.get('levelNo');
    this.levelNo = +num;
    this.parameterId = this.activatedRoute.snapshot.queryParamMap.get('parameterId');
    this.capitalId = this.activatedRoute.snapshot.queryParamMap.get('capitalId');
    this.sdgId = this.activatedRoute.snapshot.queryParamMap.get('sdgId');

    let subCatIdVal = this.activatedRoute.snapshot.queryParamMap.get('subCategoryId');
    this.subCatId = +subCatIdVal;
  }

  ngOnInit() {
    this.currency = ReportConstantsComponent.CURRENCY;
    this.locale = ReportConstantsComponent.LOCALE;
    this.loading = true;
    this._impactAssessmentService.getCompanyLevelDetails(this.levelNo, this.parameterId, this.capitalId, this.sdgId, this.subCatId).subscribe((data) => {
      let parameterLevelVOList = data.parameterLevelVOList;
      this.currentLevelName = parameterLevelVOList[0].levelName;
      this.currentLevelindicatorList = parameterLevelVOList[0].indicatorVO;
      this.companyVOList = data.companyVOList;
      this.dataPopulateCurrentLevelIndicator(0);
      this.dataTablePopulationCompanyList(this.companyVOList);
      this.loading = false;
    })
  }

  dataPopulateCurrentLevelIndicator(flag) {

    let indicatorList = this.currentLevelindicatorList;
    this.jsonArrayCurrentLevelList = [];
    
    let index = 1;
    if (flag == 0) {
      if (Object.keys(indicatorList) != null && Object.keys(indicatorList).length > 0) {
        indicatorList = indicatorList.slice(0, 3);
      }
    }
    for (let currentLevelInd of indicatorList) {
      let newArray = {};
      newArray['id'] = currentLevelInd.id;
      newArray['Indicator'] = currentLevelInd.indicatorName;
      newArray['Actual'] = currentLevelInd.actual;
      newArray['UnitOfmeasurement'] = '-';
      if (currentLevelInd.unitOfMeasurement != null && currentLevelInd.unitOfMeasurement.length > 0) {
        newArray['UnitOfmeasurement'] = currentLevelInd.unitOfMeasurement;
      }
      newArray['Sno'] = index;
      newArray['Critical'] = currentLevelInd.critical;
      newArray['Year'] = currentLevelInd.year;
      index++;
      this.jsonArrayCurrentLevelList.push(newArray);
    }

    if (flag == 0) {
      this.dataSourceCurrentLevelIndicator = new MatTableDataSource(this.jsonArrayCurrentLevelList);
    }
    else {
      this.dataSourceCurrentLevelIndicatorPopUp = [];
      this.dataSourceCurrentLevelIndicatorPopUp = new MatTableDataSource(this.jsonArrayCurrentLevelList);
      this.dataSourceCurrentLevelIndicatorPopUp.paginator = this.paginator;
    }

    this.displayedColumnsCurrentLevelIndicator = ['SDGCritical', 'SDGIndicator', 'SDGUnitOfmeasurement', 'SDGActual']
  }

  getCompanyIndicatorForPopUp(companyId, targetFlagCheck) {
    this._impactAssessmentService.getCompanyIndicatorDetails(this.levelNo, companyId, this.parameterId, 1, this.capitalId, this.sdgId).subscribe((companyIndicatorList) => {
      this.dataTablePopulationCompanyIndicators(companyIndicatorList, 0, targetFlagCheck);
    })
  }

  displayedColumnsCompany(targetCheck) {

    let displayedColumnsTransanction = [];
    if (targetCheck) {
      return displayedColumnsTransanction = ['Critical', 'Indicator', 'UnitOfmeasurement', 'Target', 'Actual', 'Achievement', 'Year']
    }
    else {
      return displayedColumnsTransanction = ['Critical', 'Indicator', 'UnitOfmeasurement', 'Actual', 'Year']
    }
  }

  goToClick(element:any){

    this.companyDataService.getCompanyRatingAgencyDetails(element.companyId).subscribe((result)=>{
      let companyRegionNames;
      let companySectorNames;
      let companyCountryNames;
      let companySubSectorNames;
      for (let companyParameters of result.parameterVO) {
        var str = companyParameters.parameterList; 
        str = str.replace(/"/g, '');
        str = str.replace(/{/g, '');
        str = str.replace(/}/g, '');
        if(companyParameters.parameterName == "Region")
          companyRegionNames = str;
        if(companyParameters.parameterName == "Sector")
          companySectorNames = str;
        if(companyParameters.parameterName == "Country")
          companyCountryNames = str;
          if(companyParameters.parameterName == "Sub Sector")
          companySubSectorNames = str;
      }
      this._router.navigate(['/performanceManagement/dashboard/companyDetails'], {
        queryParams: {
          "name" :result.companyName,
          "id" :result.id,
          "image" :result.companyImgFileName,
          "sector" :companySectorNames,
          "subSector" :companySubSectorNames,
          "region" :companyRegionNames,
          "subRegion" :companyCountryNames,
          "environment" :result.environment,
          "social" :result.social,
          "governance" :result.governance,
          "overallEsgQuotient" :result.overallEsgQuotient,
          "esgColorStatus":result.esgColorStatus,
          "revenue" : result.revenue},
        skipLocationChange: true
      });
      });
  //  this._router.navigate(['/performanceManagement/dashboard/companyDetails/'+id]);
  }

  dataTablePopulationCompanyList(companyDetails) {

    this.jsonArrayCompanyList = [];
    let sno = 1;
    for (let company of companyDetails) {
      this.company_id = company.id;

      let companyIndicatorDetails = company.indicatorVO;
      this.dataSourceCompanyIndicators[company.id] = this.dataTablePopulationCompanyIndicators(companyIndicatorDetails, 1, company.targetFlag);
      this.displayedColumnsCompanyIndicators[company.id] = this.displayedColumnsCompany(company.targetFlag);
      this.targetFlagTrans[company.id] = company.targetFlag;
      if (companyIndicatorDetails) {
        if(Object.keys(companyIndicatorDetails) != null){
          this.listTransIndicatorsSize[company.id] = 0;
          this.listTransIndicatorsSize[company.id] = Object.keys(companyIndicatorDetails).length;
        }
      }
      else {
        this.listTransIndicatorsSize[company.id] = 0;
      }

      let countryName = '-';
      let subSectorName = '-';
      var newArray = {};
      newArray['sno'] = sno;
      newArray['companyId'] = company.id;
      newArray['companyName'] = company.companyName;
      for (let companyParameters of company.parameterVO) {
        var str = companyParameters.parameterList;
        str = str.replace(/"/g, '');
        str = str.replace(/{/g, '');
        str = str.replace(/}/g, '');
        if (companyParameters.parameterName == "Country") {
          countryName = str;
        }
        if (companyParameters.parameterName == "Sub Sector") {
          subSectorName = str;
        }
      }
      newArray['companyCountryNames'] = countryName;
      newArray['companySubSectorNames'] = subSectorName;
      newArray['companyName'] = company.companyName;
      newArray['esg'] = company.esg;
      newArray['esgColorStatus'] = company.esgColorStatus;
      this.jsonArrayCompanyList.push(newArray);
      sno++;
    }
    this.dataSourceCompanyList = new MatTableDataSource(this.jsonArrayCompanyList);
  }

  dataTablePopulationCompanyIndicators(companyIndicatorDetails, flag, targetFlagCompany) {

    this.dataSourceTrans = [];
    this.dataSourcePopUp = [];
    this.displayedColumns = [];
    this.jsonArray = [];
    this.indArr = [];

    if (companyIndicatorDetails) {
      if (flag)
        this.indArr = companyIndicatorDetails.slice(0, 5);
      else
        this.indArr = companyIndicatorDetails;
    }
    let sno = 1;
    for (let indicator of this.indArr) {
      var newArray = {};
      newArray['Sno'] = sno;
      newArray['Id'] = indicator.id;
      newArray['Indicator'] = indicator.indicatorName;
      if (targetFlagCompany) {
        if (indicator.targetFlag === true)
          newArray['Target'] = indicator.target;
        else
          newArray['Target'] = "-";
        newArray['AchievementColorCode'] = indicator.achievementColorStatus;
        newArray['Achievement'] = String(indicator.achievement);
      }
      newArray['Actual'] = indicator.actual;
      newArray['UnitOfmeasurement'] = '-';
      if (indicator.unitOfMeasurement != null && indicator.unitOfMeasurement.length > 0) {
        newArray['UnitOfmeasurement'] = indicator.unitOfMeasurement;
      }
      newArray['Critical'] = indicator.critical;
      newArray['Year'] = indicator.year;

      this.jsonArray.push(newArray);
      sno++;
    }

    if (flag) {
      this.dataSourceTrans = new MatTableDataSource(this.jsonArray);
      return this.dataSourceTrans;
    }
    else {
      this.dataSourcePopUp = new MatTableDataSource(this.jsonArray);
      this.targetFlagCompanyIndicatorPopUp = targetFlagCompany;
      this.displayedColumns = this.displayedColumnsCompany(targetFlagCompany);
    }
  }

  OptionsESG() {
    this.chartOptions = {
      responsive: true,
      cutoutPercentage: 70,
    };
    return this.chartOptions;
  }

  OptionsAchievement() {
    this.chartOptions = {
      responsive: true,
      cutoutPercentage: 40,
    };
    return this.chartOptions;
  }

  doughnutChartESGData(esg: number) {
    if (esg > 1)
      esg = 1;
    this.doughnutData = [esg, (1 - esg)];
    return this.doughnutData;
  }

  doughnutChartData(percentage: number) {
    if (percentage > 100)
      percentage = 100;
    this.doughnutData = [percentage, (100 - percentage)];
    return this.doughnutData;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSourceCompanyList.filter = filterValue;

    if (this.dataSourceCompanyList.filteredData.length == 0) {
      this.flag = true;
    } else {
      this.flag = false;
    }
  }

  chartColor(colorStatus: number) {

    /* 3=> green , 2=> amber , 1 => red */
    if (colorStatus == 3)
      this.chartColors = [{ backgroundColor: ["#34a853", "#dddddd"] }];
    else if (colorStatus == 2)
      this.chartColors = [{ backgroundColor: ["#f39700", "#dddddd"] }];
    else
      this.chartColors = [{ backgroundColor: ["#ea4335", "#dddddd"] }];

    return this.chartColors;
  }

  isNumber(val) { return isNaN(+val); }
}