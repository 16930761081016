import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ImpactAssessmentService } from '../../../impact-assessment.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { ReportConstantsComponent } from '../../../../report-constants/report-constants.component'

@Component({
  selector: 'app-sdg-view-drill-down',
  templateUrl: './sdg-view-drill-down.component.html',
  styleUrls: ['./sdg-view-drill-down.component.css']
})
export class SdgViewDrillDownComponent implements OnInit {

  sdgVO: any;
  sectorVO: any;
  sdgName: string;
  jsonArraySDGList = [];
  jsonArraySectorList = [];
  jsonArraySectorIndicatorList = [];
  displayedColumnsSectorList = [];
  displayedColumnsSectorIndicators = [];
  displayedColumnsSdgIndicator = [];
  dataSourceSdgIndicator: any = [];
  dataSourceSectorIndicators: any = [];
  dataSourceSectorIndicatorsPopup: any = [];
  dataSourceSdgIndicatorPopUp: any = [];
  dataSourceSector: any = [];
  dataSourceSectorPopUp: any = [];
  displayColSector = [];
  sectorId: number;
  sectorData = [];
  doughnutData: any;
  chartOptions: any;
  chartColors: any;
  doughnutChartType: string;
  flag: boolean;
  sdgId: any;
  loading: boolean;
  public currency: string;
  public locale: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  constructor(private _impactAssessmentService: ImpactAssessmentService, private _router: Router, private activatedRoute: ActivatedRoute) {
    this.displayedColumnsSectorList = ['sector'];
    this.doughnutChartType = 'doughnut';
    this.loading = false;
    this.sdgId = this.activatedRoute.snapshot.queryParamMap.get('sdgId');
  }

  ngOnInit() {
    this.currency = ReportConstantsComponent.CURRENCY;
    this.locale = ReportConstantsComponent.LOCALE;
    this.loading = true;
    this._impactAssessmentService.getSDGDrillDownDetails(this.sdgId).subscribe((data) => {

      if (data) {
        this.sdgVO = data.sdgViewVO;
        this.sectorVO = data.sectorVOList;
        this.dataPopulateSDGIndicator(0);
        this.dataTablePopulationSectorList();
        if (this.sdgVO) {
          this.sdgName = this.sdgVO.sdgName;
        }
      }
      this.loading = false;
    })
  }

  dataPopulateSDGIndicator(flag) {

    let indicatorListSdg = this.sdgVO.indicatorVOList;
    this.jsonArraySDGList = [];
    this.dataSourceSdgIndicatorPopUp = [];
    let index = 1;
    if (flag == 0) {
      if (Object.keys(indicatorListSdg).length > 0) {
        indicatorListSdg = indicatorListSdg.slice(0, 4);
      }
    }
    for (let sdgInd of indicatorListSdg) {
      let newArray = {};
      newArray['id'] = sdgInd.id;
      newArray['Indicator'] = sdgInd.indicatorName;
      newArray['Actual'] = sdgInd.actual;
      newArray['UnitOfmeasurement'] = '-';
      if (sdgInd.unitOfMeasurement != null && sdgInd.unitOfMeasurement.length > 0) {
        newArray['UnitOfmeasurement'] = sdgInd.unitOfMeasurement;
      }
      newArray['Sno'] = index;
      newArray['Critical'] = sdgInd.critical;
      // newArray['Year']=sdgInd.year;

      index++;
      this.jsonArraySDGList.push(newArray);
    }
    if (flag == 0) {
      this.dataSourceSdgIndicator = new MatTableDataSource(this.jsonArraySDGList);
    }
    else {
      this.dataSourceSdgIndicatorPopUp = new MatTableDataSource(this.jsonArraySDGList);
      this.dataSourceSdgIndicatorPopUp.paginator = this.paginator;
    }

    this.displayedColumnsSdgIndicator = ['SDGCritical', 'SDGIndicator', 'SDGUnitOfmeasurement', 'SDGActual']
  }

  dataPopulateSectorIndicator(indicatorListSector, targetFlag, sectorId, popupFlag) {
    this.jsonArraySectorIndicatorList = [];
    this.sectorId = sectorId;
    let indArr = [];
    let index = 1;
    if (indicatorListSector) {
      if (popupFlag == 0)
        indArr = indicatorListSector.slice(0, 4);
      else
        indArr = indicatorListSector;
    }

    for (let indicator of indArr) {
      var newArray = {};
      newArray['Sno'] = index;
      newArray['Indicator'] = indicator.indicatorName;
      newArray['Actual'] = indicator.actual;
      newArray['UnitOfmeasurement'] = '-';
      if (indicator.unitOfMeasurement != null && indicator.unitOfMeasurement.length > 0) {
        newArray['UnitOfmeasurement'] = indicator.unitOfMeasurement;
      }
      newArray['Critical'] = indicator.critical;
      newArray['Year'] = indicator.year;

      if (targetFlag) {
        if (indicator.achievement == null || indicator.achievement == null)
          newArray['Achievement'] = 0;
        else
          newArray['Achievement'] = String(indicator.achievement);
        newArray['AchievementColorCode'] = indicator.achievementColorStatus;

        if (indicator.targetFlag === true)
          newArray['Target'] = indicator.target;
        else
          newArray['Target'] = "-";
      }

      this.jsonArraySectorIndicatorList.push(newArray);
      index++;
    }

    if (popupFlag == 0) {
      let dataSourceList = new MatTableDataSource(this.jsonArraySectorIndicatorList);
      return dataSourceList;
    }
    else {
      this.dataSourceSectorPopUp = new MatTableDataSource(this.jsonArraySectorIndicatorList);
      this.displayColSector = this.displayedColumnsSector(targetFlag);
    }
  }

  getSectorIndicatorForPopUp(sectorId, targetFlagCheck) {
    this.dataPopulateSectorIndicator(this.sectorData[sectorId], targetFlagCheck, sectorId, 1);
  }

  sectorAvailable: boolean = false;
  dataTablePopulationSectorList() {

    let sectorDetails = this.sectorVO;
    this.jsonArraySectorList = [];
    let incrementFlag = 1;

    for (let sectors of sectorDetails) {
      
      let sectorIndicatorDetails = [];
      sectorIndicatorDetails = sectors.indicatorVOReportList;
      this.sectorData[sectors.sectorId] = sectorIndicatorDetails;
      this.dataSourceSectorIndicators[sectors.sectorId] = this.dataPopulateSectorIndicator(sectorIndicatorDetails, sectors.targetFlag, sectors.sectorId, 0);
      this.displayedColumnsSectorIndicators[sectors.sectorId] = this.displayedColumnsSector(sectors.targetFlag);

      if (Object.keys(sectorIndicatorDetails) != null && Object.keys(sectorIndicatorDetails).length > 0) {
        this.sectorAvailable = true;
        var newArray = {};
        newArray['sno'] = incrementFlag;
        newArray['sectorIndSize'] = Object.keys(sectorIndicatorDetails).length;
        newArray['sectorId'] = sectors.sectorId;
        newArray['sectorName'] = sectors.sectorName;
        newArray['countryCount'] = sectors.noOfCountries;
        newArray['companyCount'] = sectors.noOfActiveCompanies;
        newArray['companyCount'] = sectors.noOfActiveCompanies;
        newArray['investment'] = sectors.totalInvestment;
        newArray['targetFlagSector'] = sectors.targetFlag;
        this.jsonArraySectorList.push(newArray);
        incrementFlag++;
      }
    }
    this.dataSourceSector = new MatTableDataSource(this.jsonArraySectorList);
    
  }

  displayedColumnsSector(targetCheck) {

    let dispColSector = [];
    if (targetCheck) {
      return dispColSector = ['Critical', 'Indicator', 'UnitOfmeasurement', 'Target', 'Actual', 'Achievement', 'Year']
    }
    else {
      return dispColSector = ['Critical', 'Indicator', 'UnitOfmeasurement', 'Actual', 'Year']
    }
  }

  doughnutChartData(percentage: number) {
    if (percentage > 100)
      percentage = 100;
    this.doughnutData = [percentage, (100 - percentage)];
    return this.doughnutData;
  }

  OptionsAchievement() {
    this.chartOptions = {
      responsive: true,
      cutoutPercentage: 40,
    };
    return this.chartOptions;
  }

  chartColor(colorStatus: number) {

    /* 3=> green , 2=> amber , 1 => red */
    if (colorStatus == 3)
      this.chartColors = [{ backgroundColor: ["#34a853", "#dddddd"] }];
    else if (colorStatus == 2)
      this.chartColors = [{ backgroundColor: ["#f39700", "#dddddd"] }];
    else
      this.chartColors = [{ backgroundColor: ["#ea4335", "#dddddd"] }];

    return this.chartColors;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSourceSector.filter = filterValue;
    if (this.dataSourceSector.filteredData.length == 0) {
      this.flag = true;
    } else {
      this.flag = false;
    }
  }

  getParameterDrillDownDetails(levelNo: number, parameterId: number) {
    let paramData = {};
    paramData['levelNo'] = levelNo;
    paramData['parameterId'] = parameterId;
    paramData['sdgId'] = this.sdgId;
    paramData['capitalId'] = 0;
    paramData['impactFlag'] = 2;
    paramData['impactName'] = this.sdgName;
    paramData['subCategoryId'] = 0;   // takes both impact and performance mgmt indicators
    this._router.navigate(['/impactAssessment/dashboard/parameter-wise-drilldown'], { queryParams: paramData, skipLocationChange: true });
  }
  isNumber(val) { return isNaN(+val); }
}