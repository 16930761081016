import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PdfToTextService {

 //private readonly url = "http://10.32.9.131:6503/pdf_to_text_conversion?filename=sustainability-report-en-2021.pdf&pageno=102";
 private readonly url = "http://10.32.9.131:6503";

 constructor(private http: HttpClient) { };

 pdfToText (fileName: string, pageNo: string ,file : any): Observable<any> {
  // return this.http.get(this.url, { responseType: 'text' });
   return this.http.get(this.url+'/pdf_to_text_conversion?filename='+fileName+'&pageno='+pageNo+'&file='+file, { responseType: 'blob' });
 };
}
