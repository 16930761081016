import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { Router, NavigationExtras } from '@angular/router';
import * as d3 from 'd3';
import { CompanyService } from '../../CompanyDashboard/company.service';
import { InvestorDashBoardServiceService } from '../../InvestorDashboard/investor-dash-board-service.service';
import { ReportConstantsComponent } from '../../report-constants/report-constants.component';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'app-banker-dashboard',
  templateUrl: './banker-dashboard.component.html',
  styleUrls: ['./banker-dashboard.component.css']
})
export class BankerDashboardComponent implements OnInit {

  investorId: number = 1;
  public investorData;
  investorDetails: any = [];
  displayedColumns: any;
  dataSourceAllValues: any = [];
  totalCompanyCount: any;
  doughnutChartType = 'doughnut';
  doughnutData: any;
  chartColors: any;
  chartOptions: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: any = [];
  fetchedData: any;
  gaugemap: any = {};
  public loading = false;
  public environmentESGGauge: any;
  public socialESGGauge: any;
  public governanceESGGauge: any;
  public tabFlag = 1;
  public currency: string;
  public investorEsgHistory: any;
  public esgHistoryChartOptions: any;
  public esgHistoryChartData: any;
  public esgHistoryChartLabels: any;
  public monthESG;
  public esgValues: any = [];
  public drilldownFlag = 0;
  barChartData: ChartDataSets[];
  public privateCompanies=["Elwood Technologies","Pyramid Analytics","Zolar Constructions","Wagestream","BVNK"]
  public unlistedCompanies=["Piston Group","White & Case LLP","Hogan Lovells"]
  public barChartLabels = ["Services","Agriculture","Infrastructure","Transportation","Power Generation"
  ]
  public barChartLegend = false;
  public barChartType: ChartType = 'bar';
  public colors = [
    {
      backgroundColor:["#ea655a","#ea655a","#ea655a",
      "#ea655a","#ea655a"]
    },
    {
      backgroundColor:["#97d05b","#97d05b","#97d05b",
      "#97d05b","#97d05b"]
    },
    
  ];
  pieChartData: ChartDataSets[];
  public pieChartLabels= ["High Risk","Low Risk"
  ]
  public pieChartLegend = false;
  public pieChartType: ChartType = 'pie';
  public pieColors = [
    {
      backgroundColor:["#ea655a","#97d05b"]
    }, 
  ];
  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio:false,
    scales: { xAxes: [{
     ticks:
      {
        beginAtZero:true,
        maxRotation:0,
        minRotation:0,
      },
    gridLines:{
      display:false
    }
    }], yAxes: [{
      scaleLabel: {
        display: true,
        labelString:'Financed Amount(m€)'
       },
       ticks:
       {
         beginAtZero:true,
       },
    }] },
  }
  public pieChartOptions: ChartOptions = {
    // cutoutPercentage: 80,
    tooltips: {
      callbacks: {
        title: function (tooltip, data) {
          console.log(tooltip)
          console.log(data['datasets'][0]['data'][tooltip[0]['index']])
          
          if (tooltip[0]['index'] == 1) {
            return "Low Risk " + ":" + data['datasets'][0]['data'][tooltip[0]['index']] + "%"
          }
          else if (tooltip[0]['index'] == 0) {
            return "High Risk " + ":" + data['datasets'][0]['data'][tooltip[0]['index']] + "%"
          }
        },
        label: function (tooltip, data) {
          return " "
        }
      }
    },
  }
  constructor(private companyDataService: CompanyService,private investorDashboardService: InvestorDashBoardServiceService, private dialog: MatDialog, private _router: Router) { }

  ngOnInit() {
    this.barChartData = [
      { data: [23,90,49,23,43],label:'High Risk',type:'bar',barThickness:16,barPercentage:0.5,},
      { data: [80,23,50,45,83],label:'Low Risk',type:'bar',barThickness:16,barPercentage:0.5},
      ];
      
      this.pieChartData = [
        { data: [44.8,55.2]},
        ];
    this.currency = ReportConstantsComponent.CURRENCY;
    this.investorDashboardService.fetchInvestorDetailsById(this.investorId).subscribe(data => {
      this.investorData = data;
      this.environmentESGGauge = this.investorData.latestEnvironmentQuotient;
      this.socialESGGauge = this.investorData.latestSocialQuotient;
      this.governanceESGGauge = this.investorData.latestGovernanceQuotient;
      this.draw();
      this.generateDataTable(this.investorData.companyVOList, 1);
     
    })
  }
  generateDataTable(dataArrray, tabFg) {

    this.displayedColumns = ['column'];
    let columnNo = 1;
    this.fetchedData = [];
    this.totalCompanyCount = dataArrray.length;
    let dataArr = [];
    if (tabFg == 1) {
      let count = 0;
      for (let investors of dataArrray) {
        var beneficiaries = {};
        beneficiaries['id'] = investors.id;
        beneficiaries['companyName'] = investors.companyName;
        beneficiaries['companyImgFileName'] = investors.companyImgFileName;
        beneficiaries['overallEsgQuotient'] = investors.overallEsgQuotient;
        beneficiaries['investmentValue'] = investors.investmentPercentage;
        beneficiaries['investmentPercentage'] = (investors.investmentPercentage) * 100;
        beneficiaries['parameterName'] = 'Sector';
        beneficiaries['parameterVal'] = '-';
        for (let companyParameters of investors.parameterVO) {
          var str = companyParameters.parameterList;
          str = str.replace(/"/g, '');
          str = str.replace(/{/g, '');
          str = str.replace(/}/g, '');
          if (companyParameters.parameterName == "Sector") {
            beneficiaries['parameterVal'] = str;
          }
        }
        beneficiaries['investmentAmount'] = investors.investmentAmount;
        beneficiaries['columnNo'] = columnNo;
        dataArr.push(beneficiaries)
        count++;
      }
    }
    else if(tabFg==2){ //flag 2 unlisted  Wagestream  BVNK

      let count = 1;
      let i=0;
      for (let funds of dataArrray) {
        if(i<=2){
          
        var funding = {};
        funding['id'] = funds.id;
        funding['fundname'] = funds.name;
        funding['cmpname']=this.unlistedCompanies[i];
        
        funding['fundisin'] = funds.isinCode;
        funding['annual'] = funds.latestAnnualReturn;
        if(count==1)
        {
          funding['esg'] = 0.64
        }
        else{
          funding['esg'] = funds.latestESGQuotient;
        }
      
        funding['columnNo'] = columnNo;
        funding['rangepointer'] = 1;
        funding['count'] = count;
        if (funds.latestAnnualReturn >= 0 && funds.latestAnnualReturn <= 8) {
          funding['rangepointer'] = 1;
        }
        else if (funds.latestAnnualReturn > 8 && funds.latestAnnualReturn <= 16) {
          funding['rangepointer'] = 2;
        }
        else if (funds.latestAnnualReturn > 16 && funds.latestAnnualReturn <= 24) {
          funding['rangepointer'] = 3;
        }
        else if (funds.latestAnnualReturn >= 25) {
          funding['rangepointer'] = 4;
        }
        dataArr.push(funding)
        count++;
      }i++;
      }
    }
    else if(tabFg==3){ //private

      let count = 1;
      let i=0;
      for (let funds of dataArrray) {
        var funding = {};
        funding['id'] = funds.id;
        funding['fundname'] = funds.name;
        funding['cmpname']=this.privateCompanies[i];
        i++;
        funding['fundisin'] = funds.isinCode;
        funding['annual'] = funds.latestAnnualReturn;
        if(count==1)
        {
          funding['esg'] = 0.64
        }
        else{
          funding['esg'] = funds.latestESGQuotient;
        }
      
        funding['columnNo'] = columnNo;
        funding['rangepointer'] = 1;
        funding['count'] = count;
        if (funds.latestAnnualReturn >= 0 && funds.latestAnnualReturn <= 8) {
          funding['rangepointer'] = 1;
        }
        else if (funds.latestAnnualReturn > 8 && funds.latestAnnualReturn <= 16) {
          funding['rangepointer'] = 2;
        }
        else if (funds.latestAnnualReturn > 16 && funds.latestAnnualReturn <= 24) {
          funding['rangepointer'] = 3;
        }
        else if (funds.latestAnnualReturn >= 25) {
          funding['rangepointer'] = 4;
        }
        dataArr.push(funding)
        count++;
    }
    }
    this.dataSource = [];
    this.dataSource = new MatTableDataSource(Array.of(dataArr));
    this.loading = false;
  }
  OptionsESG() {
    var per = 70;
    this.chartOptions = {
      responsive: true,
      cutoutPercentage: per,
      animation: false,
    };
    return this.chartOptions;
  }

  doughnutChartESGData(esg: number) {
    if (esg > 1)
      esg = 1;
    this.doughnutData = [esg, (1 - esg)];
    return this.doughnutData;
  }

  chartColorESG(esgColorStatus: number) {

    if (esgColorStatus >= 0.8) {
      this.chartColors = [{ backgroundColor: ["#3e7d14", "#ebebeb"] }];
    }
    else if (esgColorStatus >= 0.6 && esgColorStatus <= 0.79) {
      this.chartColors = [{ backgroundColor: ["#93e45b", "#ebebeb"] }];
    }
    else if (esgColorStatus >= 0.4 && esgColorStatus <= 0.59) {
      this.chartColors = [{ backgroundColor: ["#bfbfbf", "#ebebeb"] }];
    }
    else if (esgColorStatus >= 0.2 && esgColorStatus <= 0.39) {
      this.chartColors = [{ backgroundColor: ["#595959", "#ebebeb"] }];
    }
    else if (esgColorStatus >= 0 && esgColorStatus <= 0.19) {
      this.chartColors = [{ backgroundColor: ["#000000", "#ebebeb"] }];
    }

    return this.chartColors;
  }
  investmenChartColorESG(esgColorStatus: number) {

    if (esgColorStatus) {
      this.chartColors = [{ backgroundColor: ["#1ED2C1", "#ebebeb"] }];
    }

    return this.chartColors;
  }


  draw() {
    var self = this;
    var gauge = function (container, configuration) {

      var config = {
        size: 150,
        clipWidth: 100,
        clipHeight: 90,
        ringInset: 17,
        ringWidth: 17,

        pointerWidth: 9,
        pointerTailLength: 5,
        pointerHeadLengthPercent: 0.6,

        minValue: 0,
        maxValue: 1,

        minAngle: -90,
        maxAngle: 92,

        transitionMs: 850,

        majorTicks: 5,
        labelFormat: d3.format('.1f'),
        labelInset: 10,
        arcColorFn: d3.scaleOrdinal().range(['#000000', '#595959', '#bfbfbf', '#93e45b', '#3e7d14'])
        //  arcColorFn: d3.interpolateHsl(d3.rgb('#ea4335'), d3.rgb('#34a853'))
      };
      var range = undefined;
      var r = undefined;
      var pointerHeadLength = undefined;
      var value = 0;

      var svg = undefined;
      var arc = undefined;
      var scale = undefined;
      var ticks = undefined;
      var tickData = undefined;
      var pointer = undefined;

      var donut = d3.pie();

      function deg2rad(deg) {
        return deg * Math.PI / 180;
      }

      function newAngle(d) {
        var ratio = scale(d);
        var newAngle = config.minAngle + (ratio * range);
        return newAngle;
      }

      function configure(configuration) {
        var prop = undefined;
        for (prop in configuration) {
          config[prop] = configuration[prop];
        }

        range = config.maxAngle - config.minAngle;
        r = config.size / 2;
        pointerHeadLength = Math.round(r * config.pointerHeadLengthPercent);

        // a linear scale this.gaugemap maps domain values to a percent from 0..1
        scale = d3.scaleLinear()
          .range([0, 1])
          .domain([config.minValue, config.maxValue]);

        ticks = scale.ticks(config.majorTicks);
        tickData = d3.range(config.majorTicks).map(function () { return 1 / config.majorTicks; });

        arc = d3.arc()
          .innerRadius(r - config.ringWidth - config.ringInset)
          .outerRadius(r - config.ringInset)
          .startAngle(function (d, i) {
            var ratio = d * i;
            // if(i==0)
            //   ratio = 0;
            // else if(i==1)
            //   ratio=0.25
            // else if(i==2)
            //   ratio=0.75

            if (i == 0)
              ratio = 0;
            else if (i == 1)
              ratio = 0.20
            else if (i == 2)
              ratio = 0.40
            else if (i == 3)
              ratio = 0.60
            else if (i == 4)
              ratio = 0.80
            return deg2rad(config.minAngle + (ratio * range));
          })
          .endAngle(function (d, i) {
            var ratio = d * (i + 1);
            // if(i==0)
            //  ratio = 0.25;
            // else if(i==1)
            //  ratio=0.75
            // else if(i==2)
            //  ratio=1

            if (i == 0)
              ratio = 0.20
            else if (i == 1)
              ratio = 0.40
            else if (i == 2)
              ratio = 0.60
            else if (i == 3)
              ratio = 0.80
            else if (i == 4)
              ratio = 1
            return deg2rad(config.minAngle - 1 + (ratio * range));
          });
      }
      self.gaugemap.configure = configure;

      function centerTranslation() {
        return 'translate(' + r + ',' + r + ')';
      }

      function isRendered() {
        return (svg !== undefined);
      }
      self.gaugemap.isRendered = isRendered;

      function render(newValue) {
        svg = d3.select(container)
          .append('svg:svg')
          .attr('class', 'gauge')
          .attr('width', config.clipWidth)
          .attr('height', config.clipHeight);

        var centerTx = centerTranslation();

        var arcs = svg.append('g')
          .attr('class', 'arc')
          .attr('transform', centerTx);

        arcs.selectAll('path')
          .data(tickData)
          .enter().append('path')
          .attr('fill', function (d, i) {
            return config.arcColorFn(d * i);
          })
          .attr('d', arc);

        var lg = svg.append('g')
          .attr('class', 'label')
          .attr('transform', centerTx);
        lg.selectAll('text')
          .data(ticks)
          .enter().append('text')
          .attr('transform', function (d) {
            var ratio = scale(d);
            var newAngle = config.minAngle + (ratio * range);
            return 'rotate(' + newAngle + ') translate(-10,' + (config.labelInset - r) + ')';
          })
          .text(config.labelFormat);

        var lineData = [[config.pointerWidth / 2, 0],
        [0, -pointerHeadLength],
        [-(config.pointerWidth / 2), 0],
        [0, config.pointerTailLength],
        [config.pointerWidth / 2, 0]];
        var pointerLine = d3.line().curve(d3.curveLinear)
        var pg = svg.append('g').data([lineData])
          .attr('class', 'pointer')
          .attr('transform', centerTx);

        pointer = pg.append('path')
          .attr('d', pointerLine/*function(d) { return pointerLine(d) +'Z';}*/)
          .attr('transform', 'rotate(' + config.minAngle + ')');

        update(newValue === undefined ? 0 : newValue);
      }
      self.gaugemap.render = render;
      function update(newValue, newConfiguration?) {
        if (newConfiguration !== undefined) {
          configure(newConfiguration);
        }
        var ratio = scale(newValue);
        var newAngle = config.minAngle + (ratio * range);
        pointer.transition()
          .duration(config.transitionMs)
          .ease(d3.easeElastic)
          .attr('transform', 'rotate(' + newAngle + ')');
      }
      self.gaugemap.update = update;

      configure(configuration);

      return self.gaugemap;
    };

    var epowerGauge = gauge('#e-power-gauge', {
      size: 200,
      clipWidth: 215,
      clipHeight: 130,
      ringWidth: 20,
      maxValue: 1,
      transitionMs: 10000,
    });
    epowerGauge.render(this.environmentESGGauge);

    var spowerGauge = gauge('#s-power-gauge', {
      size: 200,
      clipWidth: 215,
      clipHeight: 130,
      ringWidth: 20,
      maxValue: 1,
      transitionMs: 10000,
    });
    spowerGauge.render(this.socialESGGauge);

    var gpowerGauge = gauge('#g-power-gauge', {
      size: 200,
      clipWidth: 215,
      clipHeight: 130,
      ringWidth: 20,
      maxValue: 1,
      transitionMs: 10000,
    });
    gpowerGauge.render(this.governanceESGGauge);

  }
  tabClick(flag) {
    this.tabFlag = flag;
    if (flag == 1) {
      this.generateDataTable(this.investorData.companyVOList, flag);
      if (document.getElementById("inv_fund"))
        document.getElementById("inv_fund").classList.remove('investor-tab-active')
      if (document.getElementById("inv_company"))
        document.getElementById("inv_company").classList.add('investor-tab-active')
        if (document.getElementById("inv_fundunlisted"))
        document.getElementById("inv_fundunlisted").classList.remove('investor-tab-active')
    }
    else if(flag==2) { //unlisted
      this.generateDataTable(this.investorData.fundVOList, flag);
      if (document.getElementById("inv_company"))
        document.getElementById("inv_company").classList.remove('investor-tab-active')
      if (document.getElementById("inv_fund"))
        document.getElementById("inv_fund").classList.remove('investor-tab-active')
        if (document.getElementById("inv_fundunlisted"))
        document.getElementById("inv_fundunlisted").classList.add('investor-tab-active')

    }
    else { //private
      this.generateDataTable(this.investorData.fundVOList, flag);
      if (document.getElementById("inv_company"))
        document.getElementById("inv_company").classList.remove('investor-tab-active')
      if (document.getElementById("inv_fund"))
        document.getElementById("inv_fund").classList.add('investor-tab-active')
        if (document.getElementById("inv_fundunlisted"))
        document.getElementById("inv_fundunlisted").classList.remove('investor-tab-active')
    }
  }

  showCompanyESGHistory(esgArray) {
    this.esgHistoryChartData = [];
    var durationString = "Year";
    var monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    this.investorEsgHistory = this.investorData.investorYearWiseDetailsVOList;
    var esg = new Array();
    var duration = new Array();
    if (esgArray == 0) {
      this.drilldownFlag = 0;
      for (let esgData of this.investorEsgHistory) {
        esg.push(esgData.averageESGQuotient);
        duration.push(esgData.year);
      }

    }
    else {
      var i = 0;
      this.drilldownFlag = 1;
      var durationString = "Month";
      this.esgValues = [];
      var esgDatas = esgArray[0].linkedHashMapForMonths;
      Object.keys(esgArray[0].linkedHashMapForMonths).map(key => {
        this.esgValues.push({ data: esgDatas[key] });
      });
      for (let esgData of this.esgValues) {
        esg.push(esgData.data);
        duration.push(monthArr[i]);
        i++;
      }
    }

    this.esgHistoryChartOptions = {
      responsive: true,
      animation: false,
      scales: {
        xAxes: [{
          display: true,
          gridLines: {
            display: false,
            drawTicks: true,
          },
          scaleLabel: {
            display: true,
            labelString: durationString
          },
        }],
        yAxes: [{
          display: true,
          gridLines: {
            display: false,
            drawTicks: true
          },
          scaleLabel: {
            display: true,
            labelString: 'ESG Quotient'
          },
          ticks: {
            beginAtZero: true,
            steps: 10,
            stepValue: 5,
            min: 0,
            max: 1
          }
        }]
      },
      title: {
        display: false,
        text: 'ESG Quotients'
      }
    };

    this.esgHistoryChartLabels = duration;
    this.esgHistoryChartData = [{ 'dataSet': [{ data: esg }] }];


  }
  public esgHistoryColor: Array<any> = [
    { // green
      backgroundColor: 'rgb(217,241,226)',
      borderColor: 'rgb(64,187,110)',
      pointBackgroundColor: 'rgb(255,255,255)',
      pointBorderColor: 'rgb(32,176,141)',
      pointHoverBackgroundColor: 'rgb(255,255,255)',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
      pointRadius: 6,
    }
  ];
  public esgHistoryLegend: boolean = false;
  public esgHistoryType: string = 'line';
  public chartClicked(e: any): void {
    var pointSelectedIs = e.active[0]._index;
    let clickedYear = this.esgHistoryChartLabels[pointSelectedIs]
    this.monthESG = this.investorData.investorMonthWiseDetailsVOList.filter(obj => obj.year == clickedYear);
    this.showCompanyESGHistory(this.monthESG);
  }
  getFundDrillDownDetails(fundId: number) {
    let fundVO = {};
    fundVO['fundId'] = fundId;
    this._router.navigate(['/dashboard/fundDrilldown'], { queryParams: fundVO, skipLocationChange: true });
  }

  getAssessmentDetails(companyName)
  {
    
    let navigationExtras: NavigationExtras = {
      queryParams: { 
       companyName:companyName},
      skipLocationChange: true
    };
    this._router.navigate(['dashboard/assessmentDashboard'],navigationExtras)
  }
  goToClick(id: any) {
    console.log("127")
     this._router.navigate(['/performanceManagement/dashboard/integratedCompany/'+id]);
    // this.companyDataService.getCompanyRatingAgencyDetails(id).subscribe((result)=>{
    //   let companyRegionNames;
    //   let companySectorNames;
    //   let companyCountryNames;
    //   let companySubSectorNames;
    //   for (let companyParameters of result.parameterVO) {
    //     var str = companyParameters.parameterList; 
    //     str = str.replace(/"/g, '');
    //     str = str.replace(/{/g, '');
    //     str = str.replace(/}/g, '');
    //     if(companyParameters.parameterName == "Region")
    //       companyRegionNames = str;
    //     if(companyParameters.parameterName == "Sector")
    //       companySectorNames = str;
    //     if(companyParameters.parameterName == "Country")
    //       companyCountryNames = str;
    //       if(companyParameters.parameterName == "Sub Sector")
    //       companySubSectorNames = str;
    //   }
    //   this._router.navigate(['/performanceManagement/dashboard/companyDetails'], {
    //     queryParams: {
    //       "name" :result.companyName,
    //       "id" :result.id,
    //       "image" :result.companyImgFileName,
    //       "sector" :companySectorNames,
    //       "subSector" :companySubSectorNames,
    //       "region" :companyRegionNames,
    //       "subRegion" :companyCountryNames,
    //       "environment" :result.environment,
    //       "social" :result.social,
    //       "governance" :result.governance,
    //       "overallEsgQuotient" :result.overallEsgQuotient,
    //       "esgColorStatus":result.esgColorStatus,
    //       "revenue" : result.revenue},
    //     skipLocationChange: true
    //   });
    //   });

  }

  goTocompanyRecord(){
  let navigationExtras: NavigationExtras = {

    queryParams: {

                  "flag" : 'B',
                  "companyId":470
                },

    skipLocationChange: true

  };

  this._router.navigate(['/companyPerformanceRecord'],navigationExtras)

}
  /* FOR DEMO CODE BELOW*/

  // demo purpose - begin
  // public tabFlag = 1;
  // constructor(public _router: Router) { }
  // ngOnInit() {
  // }
  // tabClickDemo(flag) {
  //   if (flag == 1) {
  //     this.tabFlag = 1;
  //     document.getElementById('fund').classList.remove('investor-tab-active')
  //     document.getElementById('company').classList.add('investor-tab-active')
  //   }
  //   else {
  //     this.tabFlag = 2;
  //     document.getElementById('company').classList.remove('investor-tab-active')
  //     document.getElementById('fund').classList.add('investor-tab-active')
  //   }
  // }
  // showCompanies() {
  //   this._router.navigate(['/companyReportDashboard']);
  // }
  // showfunds() {
  //   this._router.navigate(['/dashboard/fundDrilldown']);
  // }
  // demo purpose - end

}
