import { Component, OnInit, Output, EventEmitter,Input,OnChanges,SimpleChanges ,ViewChild, AfterViewInit  } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import {Http, Response , Headers, RequestOptions} from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import {MatPaginator} from '@angular/material';
import {MatSort} from '@angular/material';
import swal from 'sweetalert2';
import { AddResponseOptionsComponent } from '../add-response-options/add-response-options.component';
import { AddEditSuccessComponent } from '../../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
@Component({
  selector: 'app-list-response-options',
  templateUrl: './list-response-options.component.html',
  styleUrls: ['./list-response-options.component.css']
})
export class ListResponseOptionsComponent implements OnInit {

  responseOptionsList: any;
  dataTableFlag: boolean;
  displayedColumns:any;
  originalColumnNames : any;
  flag : boolean = false;
  top:any;
  jsonArray =[];
  @Input() set topWrapper(val){
    this.top=val;
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() set responseOptions(val) {
    var jsonArray;
    var allResponses = val;
  
    if (allResponses == undefined) {
      jsonArray = [];
    } else {
      jsonArray = this.generateDataTableDetails(allResponses);
    }
    this.dataTablePopulation(jsonArray);
  }

  @Output() responseOptionComponent = new EventEmitter();
  constructor(private addEditSuccessComponent: AddEditSuccessComponent,private assessmentService: AssessmentTemplateMasterdataService,private addResponseOptionsComponent: AddResponseOptionsComponent ) { }

  ngOnInit() {
    
  }

  

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); 
    filterValue = filterValue.toLowerCase(); 
   
   }

   generateDataTableDetails(responseOptions){
  
  
      this.displayedColumns=[];
      this.originalColumnNames = [];
      this.jsonArray = [];
      var count =0;
      let index = 1;
      for( let i of responseOptions){
        
        var newArray = {};
        
        newArray['id'] = i.id;
        newArray['Sno'] = index;
        newArray['responseName'] = i.responseName;
        newArray['option'] = i.option;
        newArray['score'] = i.score;
        newArray['editDelete'] =i.editDelete;
        index++;
       this.jsonArray.push(newArray);
        
      }

      return this.jsonArray;
  }
  

  dataTablePopulation(jsonArray) {

    this.displayedColumns=['Sno','responseName','option','score','Actions'];
    this.originalColumnNames = ['Sl.No','Response Name', 'Option', 'Score', 'Actions']
       
  }

  editResponseOption(optionObj){

    this.responseOptionComponent.emit({ id:optionObj.id, responseName: optionObj.responseName, option: optionObj.option,score:optionObj.score });
    
  }

  deleteResponseOption(resposneOptionObj){
  

    swal({
      title: '<p class="swal2-title-warning">Are You Sure?</p>',
      text: "You Want To Delete The Performance Area",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'

    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.assessmentService.deleteResponseOption(resposneOptionObj.id).subscribe((errorFlag)=>{
              // swal({
              //     title: 'Success!',
              //     text: 'Deleted Response Option',
              //     type: 'success',
              //     timer : 2000,
              //     confirmButtonText: 'Ok'
              //   })
              let msg="Deleted Response Option";
                this.addEditSuccessComponent.showSuccessSwal(msg)
                .then((value) => {
                
                  this.refreshDataTable();
                  this.addResponseOptionsComponent.resetResponseForm();
                 
                  this.addResponseOptionsComponent.loadReponseOptions();
                  
                });
        },(error)=>{

          console.log(error);
    });
  }
});

}

refreshDataTable() {

  this.assessmentService.loadResponseOptions().subscribe(data =>{
   
    var jsonArrayForDataTable = [];
    jsonArrayForDataTable = this.generateDataTableDetails(data);
    this.dataTablePopulation(jsonArrayForDataTable);
  });
}

}
