import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, FormControl, FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import swal from 'sweetalert2';
import { PerformanceTemplateService } from '../performance-template.service';
import { AddEditSuccessComponent } from '../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { CustomValidationsService } from '../../../shared/Validations/custom-validations.service';
import { GenerateSchedulesService } from '../../../shared/generate-schedules.service';

@Component({
  selector: 'app-add-performance-template-conf',
  templateUrl: './add-performance-template-conf.component.html',
  styleUrls: ['./add-performance-template-conf.component.css']
})
export class AddPerformanceTemplateConfComponent implements OnInit {

  baselineYearList: any;
  formulaTypeFlag: string;
  currentYear: boolean;
  currentRowId: any;
  webservice: any;
  // indicatorSelected: any;
  weightageList: any = [];
  frequencyList: any;
  mandatoryList: any;
  criticalList: any;
  sourceList: any;
  targetList: any;
  yes: number = 1;
  no: number = 2;
  templateDetailsId: number;
  my_Class4: string;
  configFormGroup: any = {};
  templateVO: any = [];
  indicatorList: any = [];
  companyTypeName: string;
  public dataSource: any = [];
  displayedColumns: any;
  templateName: any;
  status: any;
  applyToExisting: string = '';
  isWebservice = [];
  viewWebservice: boolean = false;
  viewSchedule: boolean = false;
  left_ind_box = 'col-lg-12';
  webserviceForm: FormGroup;
  view_schedule: string;
  ind_inner_box: string;
  view_webservice: string;
  addScheduleForm: FormGroup;
  startYearList: any;
  endYearList: any;
  scheduleForm: any = {};
  schedulesList: any;
  dateList: any;
  endDateList: any;
  indicatorSelected: any = {};
  indRatingFlag: boolean;
  finalTargetFormGroup: FormGroup;
  invalid: boolean;
  intermediate: number = 0;
  flag: boolean = true;
  public loading = false;
  baselineFormGroup: FormGroup;
  selectFlag: boolean = false;
  public basePlaceholder = "Baseline Value";
  @ViewChild("addScheduleFormRef") addScheduleFormRef;
  @ViewChild("webServiceFormRef") webServiceFormRef;
  @ViewChild("baseModal") baseModal: ElementRef;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('baseChildren') baseChildren: ElementRef;


  constructor(private renderer: Renderer2, private elementRef: ElementRef, private swalComponent: AddEditSuccessComponent,
    private performanceTemplateService: PerformanceTemplateService, private customValidationsService: CustomValidationsService,
    private generateSchedulesService: GenerateSchedulesService,
    private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private router: Router) {
    this.renderer.listen('window', 'click', (e: Event) => {

      if (this.toggleButton) {
        if (e.target !== this.toggleButton.nativeElement && e.target !== this.baseModal.nativeElement) {
          if ((e.srcElement && e.srcElement.classList.contains("baseChildren")) == false
            && this.selectFlag == false && document.getElementById("baseline-modal")) {
            document.getElementById("baseline-modal").classList.remove("base-display");
          }
        }
        this.selectFlag = false;
      }
    });
    this.templateVO = JSON.parse(this.activatedRoute.snapshot.queryParamMap.get('templateVO'));
  }
  paginator: any;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  setDataSourceAttributes() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    if (this.viewSchedule == true || this.viewWebservice == true) {
      let indDivs = document.querySelectorAll('.selected-arrow');
      if (indDivs.length) {
        for (let i = 0; i < indDivs.length; i++) {
          indDivs[i].classList.remove('selected-arrow');
        }
      }
      this.selectedRow();
    }
  }
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.getIndicatorVOList();
    this.setWeightageList();
  }
  setWeightageList() {
    for (let i = 1; i <= 10; i++) {
      this.weightageList.push(i);
    }

  }

  createFormGroup() {

    return this.formBuilder.group({
      frameworkIndicatorDetailId: [],
      target: [],
      mandatory: [],
      critical: [],
      weightage: ['', [Validators.compose([Validators.required])]],
      source: ['', [Validators.compose([Validators.required])]]
    });
  }

  getIndicatorVOList() {

    this.configFormGroup = {};
    this.indicatorList = this.templateVO['indicatorVOList'];
    this.templateName = this.templateVO['name'];
    this.templateDetailsId = this.templateVO['id'];
    this.applyToExisting = this.templateVO['applyToExisting'];

    if (this.templateVO['companyTypeVO'] != null) {
      this.companyTypeName = this.templateVO['companyTypeVO'].name;
    }

    for (let i = 0; i < this.indicatorList.length; i++) {

      let indicatorConfigurationVO = this.indicatorList[i].indicatorConfigurationVO;
      this.frequencyList = indicatorConfigurationVO.frequencyList;
      this.sourceList = indicatorConfigurationVO.sourceList;

      if (indicatorConfigurationVO.source) {
        this.isWebservice[this.indicatorList[i].id] = indicatorConfigurationVO.source.code;
      }


      //   this.configFormGroup[this.indicatorList[i].id].controls['source'].setValue(indicatorConfigurationVO.source);
      this.configFormGroup[this.indicatorList[i].id] = this.createFormGroup();
      this.configFormGroup[this.indicatorList[i].id].patchValue(indicatorConfigurationVO);
    }
    this.fetchDataTable();

  }

  fetchDataTable() {

    this.displayedColumns = [];
    let jsonArray = [];
    for (let indicator of this.indicatorList) {
      let newArray = {};
      let indicatorConfigurationVO = indicator.indicatorConfigurationVO;
      newArray['indicatorId'] = indicator.id;
      newArray['Indicator'] = indicator.name;
      newArray['templateIndicatorId'] = indicator.templateIndicatorId;
      if (indicatorConfigurationVO != null) {
        newArray['Target'] = indicatorConfigurationVO.target;
        newArray['Mandatory'] = indicatorConfigurationVO.mandatory;
        newArray['Critical'] = indicatorConfigurationVO.critical;
        newArray['Weightage'] = indicatorConfigurationVO.weightage;
        newArray['Source'] = indicatorConfigurationVO.source;
        newArray['intermediateTarget'] = indicatorConfigurationVO.intermediateTarget;
      }
      jsonArray.push(newArray);
    }
    this.dataSource = [];
    if (jsonArray.length > 0)
      this.flag = false;
    else
      this.flag = true;
    this.displayedColumns = ['Indicator', 'Target', 'Mandatory', 'Critical', 'Weightage', 'Source', 'Schedule']
    this.dataSource = new MatTableDataSource(jsonArray);
    this.setDataSourceAttributes();

  }


  back() {
    this.router.navigate(['/loadtemplateCreation/'], { queryParams: { "templateId": this.templateDetailsId }, skipLocationChange: true });
  }

  saveOrSubmitCall(isSubmit) {

    if (isSubmit) {

      this.status = 'A';

      if (this.applyToExisting === '') {
        let message = "Apply the template changes to existing companies (if any)";
        let yesMsg = "Yes, Apply to existing";
        let noMsg = "No, Apply to new";
        this.swalComponent.showConfirmationSwal(message, yesMsg, noMsg).then((isConfirm) => {

          this.applyToExisting = 'N';
          if (isConfirm.value) {
            this.applyToExisting = 'Y';
          }
          this.saveOrSubmitTemplateIndicatorConfig();
        });
      }
      else {
        this.saveOrSubmitTemplateIndicatorConfig();
      }
    }
    else {
      this.status = 'D';
      this.saveOrSubmitTemplateIndicatorConfig();
    }
  }

  saveOrSubmitTemplateIndicatorConfig() {

    let jsonTemplateVOArray = [];
    let templateVOJson: any = {};
    let message;

    for (let i = 0; i < this.indicatorList.length; i++) {
      let indicatorListVO = {};
      indicatorListVO["id"] = this.indicatorList[i].id;
      indicatorListVO["name"] = this.indicatorList[i].name;
      indicatorListVO["templateIndicatorId"] = this.indicatorList[i].templateIndicatorId;
      let indicatorConfigurationVO = {};
      indicatorConfigurationVO = this.configFormGroup[this.indicatorList[i].id].value;
      indicatorConfigurationVO["baselineFormula"] = this.indicatorList[i].indicatorConfigurationVO.baselineFormula;
      indicatorListVO["indicatorConfigurationVO"] = indicatorConfigurationVO;
      //indicatorListVO["indicatorConfigurationVO"] = this.configFormGroup[this.indicatorList[i].id].value;

      jsonTemplateVOArray.push(indicatorListVO);
    }

    templateVOJson.id = this.templateDetailsId;
    templateVOJson.name = this.templateName;
    templateVOJson.applyToExisting = this.applyToExisting;
    templateVOJson.indicatorVOList = jsonTemplateVOArray;


    let formGroupValid = this.checkIndicatorFormValidation();

    if (formGroupValid) {
      this.performanceTemplateService.saveTemplateIndicatorConfigDetails(templateVOJson, this.status).subscribe((success) => {
        if (this.status === 'D') {
          message = "Successfully saved the template details.";
        }
        else {
          message = "Successfully submitted the template details.";
        }
        this.swalComponent.showSuccessSwal(message).then((isConfirm) => {
          if (this.status !== 'D') {
            this.router.navigate(['/performanceTemplates']);
          }
        });
      }, (error) => {
        console.log(error);
      });
    }


  }

  checkIndicatorFormValidation() {
    let formValidFlag = true;
    for (let i = 0; i < this.indicatorList.length; i++) {
      let source = this.configFormGroup[this.indicatorList[i].id].controls.source;
      if (source.value == 0) source.setValue(null);

      if ((source.value == null)) {
        source.markAsTouched();
        formValidFlag = false;
      }
    }

    return formValidFlag;

  }
  populateWebservice(source, indicator) {
    // this.viewWebservice=false;

    this.toggle_class('otherSource');
    this.isWebservice[indicator.indicatorId] = source.code;
    if (source.code == "WS") {
      this.viewWebservice = true;
      this.viewSchedule = false;
      this.toggle_class('webservice');
      this.indicatorSelected = this.indicatorList.find(e => e.id === indicator.indicatorId);
      this.webserviceForm = this.createWebserviceFormGroup();

      this.performanceTemplateService.getWebserviceForIndicator(this.indicatorSelected.templateIndicatorId).subscribe(data => {
        this.webservice = data;
        if (this.webservice) {
          if (this.webservice.webserviceParameterVOList) {
            this.deleteRow(0);
            this.webservice.webserviceParameterVOList.forEach(parameter => {
              this.addNewRow();
            })

          }
          this.webserviceForm.patchValue(this.webservice);

        }
        let indDivs = document.querySelectorAll('.selected-arrow');
        if (indDivs.length) {
          for (let i = 0; i < indDivs.length; i++) {
            indDivs[i].classList.remove('selected-arrow');
          }
        }
        this.selectedRow();
      })
    }
    else {
      this.toggle_class('otherSource');
    }

  }
  compareFn(t1, t2): boolean {
    return t1 && t2 ? t1.id === t2.id : t1 === t2;
  }

  //function to change the class from style1 to style2 when clicked
  toggle_class(toggleFlg: any) {
    if (toggleFlg == "webservice" || toggleFlg == "schedule") {
      if (this.left_ind_box == "col-lg-12") {
        this.left_ind_box = "col-lg-8 col-md-8";
        this.view_schedule = "col-lg-4 col-md-4 sch no-pad-right";
        this.view_webservice = "col-lg-4 col-md-4 web no-pad-right";
        this.ind_inner_box = "ind-table-wrap";
      }
      else {
        if (toggleFlg == "schedule") {
          this.view_webservice = "display-none";
        }
        else {
          this.view_schedule = "display-none";
        }
      }
    }
    else {
      this.left_ind_box = "col-lg-12";
      this.view_webservice = "display-none";
      this.view_schedule = "display-none";
      let indDivs = document.querySelectorAll('.selected-arrow');
      if (indDivs.length) {
        for (let i = 0; i < indDivs.length; i++) {
          indDivs[i].classList.remove('selected-arrow');
        }
      }
    }

  }
  addNewRow() {
    const controls = this.formArray;
    this.formArray.push(this.initParameterRows());
  }
  deleteRow(index: number) {
    this.formArray.removeAt(index);
  }

  get formArray() {
    return this.webserviceForm.get('webserviceParameterVOList') as FormArray;
  }
  initParameterRows() {
    return this.formBuilder.group({
      parameterName: ['', [Validators.required, this.customValidationsService.noWhitespaceValidator]],
      parameterValue: ['', [Validators.required, this.customValidationsService.noWhitespaceValidator]]
    });
  }
  createWebserviceFormGroup() {
    return this.formBuilder.group({
      id: [0],
      url: ['', [Validators.required, this.customValidationsService.noWhitespaceValidator]],
      username: ['', [Validators.required, this.customValidationsService.noWhitespaceValidator]],
      password: ['', [Validators.required, this.customValidationsService.noWhitespaceValidator]],
      indicatorDetailsId: this.indicatorSelected.companyIndicatorDetailsId,
      webserviceParameterVOList: this.formBuilder.array([this.initParameterRows()])
    })
  }

  selectedRow() {

    if (this.currentRowId && document.getElementById("indMatRow" + this.currentRowId))
      document.getElementById("indMatRow" + this.currentRowId).classList.add("selected-arrow");
  }
  showArrow(rowId) {
    this.currentRowId = rowId;


  }

  submitWebservice() {

    if (this.webserviceForm.valid) {
      let indicatorVO = {};
      let indicatorConfigurationVO = {};
      indicatorConfigurationVO['webServiceVO'] = this.webserviceForm.value;
      indicatorVO['templateIndicatorId'] = this.indicatorSelected.templateIndicatorId;
      indicatorVO['indicatorConfigurationVO'] = indicatorConfigurationVO;
      this.performanceTemplateService.submitWebservice(indicatorVO).subscribe(data => {
        var message = "Successfully Saved Webservice Configuration";
        this.swalComponent.showSuccessSwal(message).then((value) => {
        });
        this.webServiceFormRef.resetForm();
        this.toggle_class('otherSource');
      })
    }
  }

  /* Schedule update */

  updateScheduleTemplateIndicator(indicator) {
    if (this.addScheduleFormRef) {
      this.addScheduleFormRef.resetForm();
    }
    this.intermediate = 0;
    this.viewWebservice = false;
    this.loading = true;
    this.toggle_class('schedule');
    this.viewSchedule = true;
    let yearList = this.generateSchedulesService.getYearList();
    this.startYearList = yearList;
    this.endYearList = yearList;

    if (this.configFormGroup[indicator.indicatorId].valid) {
      this.indicatorSelected = this.indicatorList.find(e => e.id === indicator.indicatorId);

      if (this.indicatorSelected.indicatorType.code == "QR")
        this.indRatingFlag = true;
      else
        this.indRatingFlag = false;
      this.addScheduleForm = this.createScheduleGenerationFormGroup();

      if (this.indicatorSelected != null && this.indicatorSelected.indicatorConfigurationVO != null) {

        this.indicatorSelected.indicatorConfigurationVO.target = indicator.Target;

        if (this.indicatorSelected.indicatorConfigurationVO.indicatorStartdate != null) {
          let sYear = new Date(this.indicatorSelected.indicatorConfigurationVO.indicatorStartdate);
          let startYear = Number(sYear.getFullYear());

          this.addScheduleForm.get('startYear').setValue(startYear);
        }
        else
          this.addScheduleForm.get('startYear').setValue('');
        if (this.indicatorSelected.indicatorConfigurationVO.indicatorEnddate != null) {
          let eYear = new Date(this.indicatorSelected.indicatorConfigurationVO.indicatorEnddate);
          let endYear = Number(eYear.getFullYear());
          this.addScheduleForm.get('endYear').setValue(endYear);
        }
        else
          this.addScheduleForm.get('endYear').setValue('');
        if (this.indicatorSelected.indicatorConfigurationVO.frequency != null) {

          this.addScheduleForm.get('frequency').setValue(this.indicatorSelected.indicatorConfigurationVO.frequency);

        }
        this.addScheduleForm.get('intermediateTarget').setValue(this.indicatorSelected.indicatorConfigurationVO.intermediateTarget);

        this.populateDate(this.indicatorSelected.indicatorConfigurationVO.frequency, false);
        this.performanceTemplateService.getSchedulesForIndicator(this.indicatorSelected.templateIndicatorId, this.indicatorSelected.indicatorType.id).subscribe(data => {

          this.schedulesList = data;
          let i = 0;

          if (data.length > 0) {
            this.intermediate = 1;
          }

          this.indicatorSelected.indicatorConfigurationVO.scheduleVOList = [];
          this.indicatorSelected.indicatorConfigurationVO.scheduleVOList = this.schedulesList;
          this.schedulesList.forEach(schedule => {
            this.scheduleForm[i++] = this.createScheduleFormGroup(schedule);
            if (this.intermediate == 1) {
              if (this.indicatorSelected.indicatorConfigurationVO.intermediateTarget && schedule.targetValue) {
                var abstractControl: AbstractControl = this.scheduleForm[i - 1];

                if (abstractControl instanceof FormGroup) {
                  (<FormGroup>abstractControl).addControl('targetValue', new FormControl({ value: '' }));


                  if (this.indicatorSelected.indicatorConfigurationVO.target == 'M') {
                    if (this.indicatorSelected.indicatorType.code == "QR")//qualitative
                      this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.required]);
                    else if (this.indicatorSelected.indicatorType.code == "QT")//textual
                      this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.required, this.customValidationsService.noWhitespaceValidator]);
                    else //quantitative case
                      this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.required, this.customValidationsService.noWhitespaceValidator, Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)]);

                  }
                  else {
                    if (this.indicatorSelected.indicatorType.code == "QU")
                      this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)]);
                  }
                  if (this.indRatingFlag)
                    this.scheduleForm[i - 1].controls['targetValue'].setValue(Number(schedule.targetValue));
                  else
                    this.scheduleForm[i - 1].controls['targetValue'].setValue(schedule.targetValue);
                  // if (this.scheduleForm[i - 1].controls['targetValue'].value == 0) { this.scheduleForm[i - 1].controls['targetValue'].setValue(''); }
                }
              }
              else {
                this.finalTargetFormGroup = this.createFinalTargetFormGroup();
                this.scheduleForm[i - 1].removeControl('targetValue');

                if (this.indRatingFlag)
                  this.finalTargetFormGroup.controls['finalTarget'].setValue(Number(this.indicatorSelected.indicatorConfigurationVO.finalTarget));
                else
                  this.finalTargetFormGroup.controls['finalTarget'].setValue(this.indicatorSelected.indicatorConfigurationVO.finalTarget);

                if (this.finalTargetFormGroup.controls['finalTarget'].value == 0) { this.finalTargetFormGroup.controls['finalTarget'].setValue(''); }

              }
            }

          })


          let indDivs = document.querySelectorAll('.selected-arrow');
          if (indDivs.length) {
            for (let i = 0; i < indDivs.length; i++) {
              indDivs[i].classList.remove('selected-arrow');
            }
          }
          this.selectedRow();
        });
      }
    }
    else {
      this.viewSchedule = false;
      if (!this.configFormGroup[indicator.indicatorId].value.source) {
        this.configFormGroup[indicator.indicatorId].controls['source'].setValue('');
        this.configFormGroup[indicator.indicatorId].get('source').markAsTouched();
      }
      if (!this.configFormGroup[indicator.indicatorId].value.weightage) {
        this.configFormGroup[indicator.indicatorId].controls['weightage'].setValue('');
        this.configFormGroup[indicator.indicatorId].get('weightage').markAsTouched();
      }
    }

  }
  generateYearChange(value) {
    let selectedStartYearIndex = this.startYearList.indexOf(value);
    this.endYearList = this.startYearList.slice(selectedStartYearIndex);

    this.addScheduleForm.get('endYear').reset();

  }
  populateDate(frequencyOfMeasurement, status) {

    this.scheduleForm = {};
    this.schedulesList = null;
    //  this.intermediate = 0;
    this.dateList = null;

    if (frequencyOfMeasurement) {

      this.dateList = this.generateSchedulesService.getDateList(frequencyOfMeasurement.code);
    }


    this.endDateList = this.dateList;
    if (this.dateList != null) {
      let startFrequency = this.indicatorSelected.indicatorConfigurationVO.start.toString();
      let endFrequency = this.indicatorSelected.indicatorConfigurationVO.end.toString();

      var abstractControl: AbstractControl = this.addScheduleForm;
      if (abstractControl instanceof FormGroup) {
        (<FormGroup>abstractControl).addControl('start', new FormControl({ value: '' }, Validators.required));
        (<FormGroup>abstractControl).addControl('end', new FormControl({ value: '' }, Validators.required));
      }

      if (this.indicatorSelected.indicatorConfigurationVO.frequency != null) {
        if (frequencyOfMeasurement.id == this.indicatorSelected.indicatorConfigurationVO.frequency.id) {
          this.addScheduleForm.controls['start'].setValue(startFrequency);
          this.addScheduleForm.controls['end'].setValue(endFrequency);
        }
        else {
          this.addScheduleForm.controls['start'].setValue('');
          this.addScheduleForm.controls['end'].setValue('');
        }
      }
      else {
        this.addScheduleForm.controls['start'].setValue('');
        this.addScheduleForm.controls['end'].setValue('');
      }

    }
    else {
      this.addScheduleForm.removeControl('start');
      this.addScheduleForm.removeControl('end');
    }
  }
  createScheduleGenerationFormGroup() {

    return this.formBuilder.group({
      frequency: ['', Validators.required],
      intermediateTarget: [],
      startYear: ['', Validators.required],
      endYear: ['', Validators.required],
    })
  }

  createScheduleFormGroup(schedule) {
    return this.formBuilder.group({
      scheduleStartDate: [schedule.scheduleStartDate],
      scheduleEndDate: [schedule.scheduleEndDate],

    })
  }
  createFinalTargetFormGroup() {
    return this.formBuilder.group({
      finalTarget: [],

    })
  }
  baselineConfig() {
    this.baselineFormGroup = this.createBaselineFormGroup();
    let yearList = [];
    this.currentYear = false;
    let currentYear = (new Date()).getFullYear();
    let startYear = currentYear - 10;
    for (let i = startYear; i <= currentYear; i++) {
      yearList.push(String(i));

    }
    yearList.push("CY");
    this.baselineYearList = yearList;
    this.setWeightageList();
    if (this.indicatorSelected.indicatorConfigurationVO.baselineFormula) {
      this.baselineFormGroup.controls['year'].setValue(this.indicatorSelected.indicatorConfigurationVO.baselineFormula.year);
      if (this.indicatorSelected.indicatorConfigurationVO.baselineFormula.formulaType == "C") {
        this.currentYear = true;
        this.baselineFormGroup.controls['data'].setValue(Number(this.indicatorSelected.indicatorConfigurationVO.baselineFormula.value));
      }
    }
  }
  yearChanged(event, flag) {
    this.selectFlag = true;
    if (flag == 0) {
      this.currentYear = false;
      this.formulaTypeFlag = "D";
      if (event.value == 'CY') {
        this.currentYear = true;
        this.formulaTypeFlag = "C";
      }
    }
  }

  createBaselineFormGroup() {
    return this.formBuilder.group({
      year: [],
      data: []
    })
  }
  saveBaselineConfiguration() {
    var baselineFormula = new Object();
    baselineFormula["formulaType"] = this.formulaTypeFlag;
    baselineFormula["year"] = this.baselineFormGroup.value.year.toString();
    if (this.formulaTypeFlag == "C") {
      baselineFormula["value"] = this.baselineFormGroup.value.data.toString();
      baselineFormula["operator"] = "-";

    }
    this.indicatorSelected.indicatorConfigurationVO.baselineFormula = baselineFormula;
    const index = this.indicatorList.indexOf(this.indicatorList.find(x => x.id == this.indicatorSelected.id), 0);
    if (index > -1) {
      this.indicatorList.splice(index, 1, this.indicatorSelected);
    }
    this.performanceTemplateService.saveBaselineForIndicator(this.indicatorSelected).subscribe(data => {

      var message = "Successfully saved BaseLine";
      this.swalComponent.showSuccessSwal(message).then((value) => {

      });
    })
  }
  generateSchedules() {

    this.scheduleForm = {};
    this.schedulesList = null;
    if (this.addScheduleForm.valid) {
      let startDateVal = Number(this.addScheduleForm.value.start);
      let endDateVal = Number(this.addScheduleForm.value.end);
      if (this.addScheduleForm.value.startYear > this.addScheduleForm.value.endYear || (this.addScheduleForm.value.startYear == this.addScheduleForm.value.endYear && endDateVal < startDateVal)) {
        var failMessage = " Please Select Valid Date Periods";
        this.swalComponent.showFailedSwal(failMessage, 'Failed');
      }
      else {
        this.generateSchedulesService.generateSchedules(this.addScheduleForm.value).subscribe(data => {
          let indicatorConfigurationVO: any = data;
          this.indicatorSelected.indicatorConfigurationVO.intermediateTarget = this.addScheduleForm.value.intermediateTarget;
          this.intermediate = 1;


          if (!this.addScheduleForm.value.intermediateTarget) {
            this.finalTargetFormGroup = this.createFinalTargetFormGroup();

            if (this.indicatorSelected.indicatorConfigurationVO.target == 'M') {
              if (this.indicatorSelected.indicatorType.code == "QR")//qualitative
              {
                this.finalTargetFormGroup.controls['finalTarget'].setValidators([Validators.required]);
              }

              else if (this.indicatorSelected.indicatorType.code == "QT")//textual
              {
                this.finalTargetFormGroup.controls['finalTarget'].setValidators([Validators.required, this.customValidationsService.noWhitespaceValidator]);
              }

              else//quantitative
              {
                this.finalTargetFormGroup.controls['finalTarget'].setValidators([Validators.required, this.customValidationsService.noWhitespaceValidator, Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)]);

              }
            }
            else {
              if (this.indicatorSelected.indicatorType.code == "QU")
                this.finalTargetFormGroup.controls['finalTarget'].setValidators([Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)]);
            }
          }

          this.schedulesList = indicatorConfigurationVO.scheduleVOList;
          let i = 0;
          this.schedulesList.forEach(schedule => {
            this.scheduleForm[i++] = this.createScheduleFormGroup(schedule);


            if (this.addScheduleForm.value.intermediateTarget) {
              var abstractControl: AbstractControl = this.scheduleForm[i - 1];
              if (abstractControl instanceof FormGroup) {
                (<FormGroup>abstractControl).addControl('targetValue', new FormControl({ value: '' }));
                if (this.indicatorSelected.indicatorConfigurationVO.target == 'M') {
                  if (this.indicatorSelected.indicatorType.code == "QR")//qualitative
                    this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.required]);
                  else if (this.indicatorSelected.indicatorType.code == "QT")//textual
                    this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.required, this.customValidationsService.noWhitespaceValidator]);
                  else //quantitative case
                    this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.required, this.customValidationsService.noWhitespaceValidator, Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)]);
                }
                else {

                  if (this.indicatorSelected.indicatorType.code == "QU")
                    this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)]);
                }


                if (this.indRatingFlag)
                  this.scheduleForm[i - 1].controls['targetValue'].setValue(Number(schedule.targetValue));
                else
                  this.scheduleForm[i - 1].controls['targetValue'].setValue(schedule.targetValue);
                if (this.scheduleForm[i - 1].controls['targetValue'].value == 0) { this.scheduleForm[i - 1].controls['targetValue'].setValue(''); }
              }
            }
          })
        })
      }
    }
  }
  intermediateTargetChanged(event) {
    this.intermediate = 0;

    if (this.indicatorSelected.indicatorConfigurationVO.intermediateTarget == event.checked) {
      this.intermediate = 1;
    }

  }

  saveIndicatorSchedule() {
    this.invalid = false;
    let i = 0;

    if (!this.indicatorSelected.indicatorConfigurationVO.intermediateTarget) {
      if (!this.finalTargetFormGroup.valid) {
        this.invalid = true;
        this.finalTargetFormGroup.get('finalTarget').markAsTouched();
      }
      if (this.finalTargetFormGroup.value.finalTarget != "")
        this.indicatorSelected.indicatorConfigurationVO.finalTarget = this.finalTargetFormGroup.value.finalTarget;
    }
    if (this.indicatorSelected.indicatorConfigurationVO.intermediateTarget) {
      this.schedulesList.forEach(schedule => {
        schedule.targetValue = this.scheduleForm[i++].value.targetValue;
        if (!this.scheduleForm[i - 1].valid) {
          this.invalid = true;
          this.scheduleForm[i - 1].get('targetValue').markAsTouched();
        }
      })
    }

    this.indicatorSelected.indicatorConfigurationVO.scheduleVOList = this.schedulesList;
    this.indicatorSelected.indicatorConfigurationVO.frequencyId = this.addScheduleForm.value.frequency.id;
    this.indicatorSelected.indicatorConfigurationVO.start = this.addScheduleForm.value.start;
    this.indicatorSelected.indicatorConfigurationVO.startYear = this.addScheduleForm.value.startYear;
    this.indicatorSelected.indicatorConfigurationVO.endYear = this.addScheduleForm.value.endYear;
    this.indicatorSelected.indicatorConfigurationVO.end = this.addScheduleForm.value.end;
    this.indicatorSelected.indicatorConfigurationVO.indicatorStartdate = this.schedulesList[0].scheduleStartDate;
    this.indicatorSelected.indicatorConfigurationVO.indicatorEnddate = this.schedulesList[this.schedulesList.length - 1].scheduleEndDate;
    this.indicatorSelected.indicatorConfigurationVO.frequency = this.addScheduleForm.value.frequency;
    this.indicatorSelected.indicatorConfigurationVO.intermediateTarget = this.addScheduleForm.value.intermediateTarget;

    if (!this.invalid) {

      this.performanceTemplateService.saveIndicatorDetailsScheduleforTemplate(this.indicatorSelected).subscribe(data => {

        var message = "Successfully saved Indicator Targets against schedules";
        this.swalComponent.showSuccessSwal(message).then((value) => {
          // this.fetchDataTable();
          //this.toggle_class('otherSource');
        });
      })

    }
  }
  ascOrder = (a, b) => {
    return Number(a.key) < Number(b.key) ? -1 : 1;
  }
  applyFilter(filterValue: string) {
    this.toggle_class('otherSource');
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    if (this.dataSource.filteredData.length == 0) {
      this.flag = true;
    } else {
      this.flag = false;
    }
  }
  baselineSelect(event) {
    if (event.target)
      event.target.classList.add("baseChildren");
  }
  toggleBaseline() {
    if (document.getElementById("baseline-modal"))
      document.getElementById("baseline-modal").classList.add("base-display");
  }
}