import { Component, OnInit, Inject } from '@angular/core';
import { CompanyService } from '../../company.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import * as d3 from 'd3';
@Component({
  selector: 'app-owlanalytics-rating-agency',
  templateUrl: './owlanalytics-rating-agency.component.html',
  styleUrls: ['./owlanalytics-rating-agency.component.css']
})
export class OwlanalyticsRatingAgencyComponent implements OnInit {
  public doughnutData: any;
  public doughnutChartType = 'doughnut';
  public chartColors: any;
  public chartOptions: any;
  public overallESGChartColor: Array<any> = [];
  loading: boolean;
  companyRatingmappingDetails: any;
  jsonArray: any[];
  dataSource: any;
  displayedColumns: string[];
 industryRank:any;
 universalRank:any;
 esgScore:any;
 ratingAgencyName:any;
 companyId :any;
 ratingAgencyId:any;
 esgBorderFlag=1;
 companyName:any;

 public gaugemap: any = {};
 public overallESGData: any = [];
  level1TotalCount: any;
  level2TotalCount: any;
  rankLevel1: any;
  rankLevel2: any;
  environmentESG: any;
  socialESG: any;
  governanceESG: any;
  rankLevelName1: any;
  rankLevelName2: any;
  environmentESGVal: any;
  socialESGVal: any;
  governanceESGVal: any;
  eSGChartColor: any;
  eSGChartColorENV: any;
  eSGChartColorSOC: any;
  eSGChartColorGOV: any;
  ratingType: any;
  image: any;
 constructor( private companyService:CompanyService,private dialogRef: MatDialogRef<OwlanalyticsRatingAgencyComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any) 
  {
    this.loading=false;
   }

  ngOnInit() {
    this.ratingAgencyName = this.data.ratingAgencyName;
    this.companyId = this.data.companyId;
    console.log("ratingggggggg")
    console.log( this.ratingAgencyName)
    console.log(this.companyId)
    this.companyName = this.data.companyName;
    this.ratingAgencyId = this.data.ratingAgencyId;
    this.fetchCompanyRatingAgencingMappingDetails();
  }

 
  fetchCompanyRatingAgencingMappingDetails() {

     this.companyService.fetchCompanyComparisonDetails(this.companyId,this.ratingAgencyId).subscribe(data => {
    this.companyRatingmappingDetails = data.companyRatingAgencyDetails; 
    this.image=data.ratingAgencyVO.imgFileName;
    console.log("this.companyRatingmappingDetails")
    console.log(this.companyRatingmappingDetails)
    if(this.companyRatingmappingDetails){
    this.ratingType=this.companyRatingmappingDetails.ratingType;
    this.esgScore=this.companyRatingmappingDetails.esgScore;
    this.rankLevelName1=this.companyRatingmappingDetails.regionalIndustryRankText;
    this.rankLevelName2=this.companyRatingmappingDetails.regionalSectorRankText;
    this.rankLevel1=this.companyRatingmappingDetails.regionalIndustryRank;
    this.rankLevel2=this.companyRatingmappingDetails.regionalSectorRank;
    this.environmentESG=[this.companyRatingmappingDetails.environment,(100 - this.companyRatingmappingDetails.environment )];
    this.socialESG=[this.companyRatingmappingDetails.social , (100 - this.companyRatingmappingDetails.social )];
    this.governanceESG=[this.companyRatingmappingDetails.governance , (100 - this.companyRatingmappingDetails.governance )];
    this.environmentESGVal=this.companyRatingmappingDetails.environment;
    this.socialESGVal=this.companyRatingmappingDetails.social;
    this.governanceESGVal=this.companyRatingmappingDetails.governance;
    this.level1TotalCount=this.companyRatingmappingDetails.regionalIndustryRankCount;
    this.level2TotalCount=this.companyRatingmappingDetails.regionalSectorRankCount;
    this.eSGChartColorENV = this.chartColorESG(this.environmentESGVal);
    this.eSGChartColorSOC = this.chartColorESG(this.socialESGVal);
    this.eSGChartColorGOV = this.chartColorESG(this.governanceESGVal);
    
    } 
       },(error)=>{
         console.log(error);
        
       });
    }

    closePopup(): void {
      this.dialogRef.close();
    }
  
    doughnutChartESGData(esg: number) {
      if (esg > 1)
        esg = 1;
      this.doughnutData = [esg, (1 - esg)];
      return this.doughnutData;
    }
    chartColorESG(esg: number) {
      
      if (esg >= 80) {
        this.chartColors = [{ backgroundColor: ["#3e7d14", "#ebebeb"] }];
      }
      else if (esg >= 60 && esg <= 79) {
        this.chartColors = [{ backgroundColor: ["#93e45b", "#ebebeb"] }];
      }
      else if (esg >= 40 && esg <= 59) {
        this.chartColors = [{ backgroundColor: ["#bfbfbf", "#ebebeb"] }];
      }
      else if (esg >= 20 && esg <= 39) {
        this.chartColors = [{ backgroundColor: ["#595959", "#ebebeb"] }];
      }
      else if (esg >= 0 && esg <= 19) {
        this.chartColors = [{ backgroundColor: ["#000000", "#ebebeb"] }];
      }
      return this.chartColors;
    }
  
    OptionsESG() {
      var per = 60;
      this.chartOptions = {
        responsive: true,
        cutoutPercentage: per,
        animation: false,
      };
      return this.chartOptions;
    }
  
}
