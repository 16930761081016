import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-relationship-dashboard',
  templateUrl: './relationship-dashboard.component.html',
  styleUrls: ['./relationship-dashboard.component.css']
})
export class RelationshipDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
