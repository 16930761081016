import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyService } from '../../company.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-company-list-details',
  templateUrl: './company-list-details.component.html',
  styleUrls: ['./company-list-details.component.css']
})
export class CompanyListDetailsComponent implements OnInit {

  companyDetails:any;
  doughnutChartType = 'doughnut';
  doughnutData: any;
  chartColors: any;
  chartOptions: any;
  flag: boolean;
  companyRegionNames = [];
  companySectorNames = [];
  lastColumn:number;
  totalCompanyCount:number;
  rows:number;
  displayedColumns:any;
  dataSource:any;
  dataSourceLabels:any;
  dataSrc:any;
  emptyFlag:boolean;
  showflag:boolean;
  loading:boolean;
  dataLabels = [];
  displayPaginatorClass:any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private _companyService:CompanyService, private _router: Router) { 
    this.showflag = true;  
    this.loading = false;
  }

  ngOnInit() {

    this.loading = true;
    this._companyService.getAllCompanies().subscribe((companyDetails)=>{
      this.companyDetails = companyDetails;
      this.totalCompanyCount = companyDetails.length;
      let newArray = {};
      this.displayedColumns=['column'];
      let dashboardArray = [];  
      let data = [];
      this.emptyFlag = true;
      let columnNo = 1;
      let count = 0;
      for (let companyDetailsListing of companyDetails) {
        this.emptyFlag = false;
        this.companyRegionNames[companyDetailsListing.id] = '-';
        this.companySectorNames[companyDetailsListing.id] = '-'; 
        for (let companyParameters of companyDetailsListing.parameterVO) {
          var str = companyParameters.parameterList; 
          str = str.replace(/"/g, '');
          str = str.replace(/{/g, '');
          str = str.replace(/}/g, '');
          if(companyParameters.parameterName == "Region"){
            this.companyRegionNames[companyDetailsListing.id] = str;
          }
          if(companyParameters.parameterName == "Sector"){
            this.companySectorNames[companyDetailsListing.id] = str;
          }
        }
        newArray = {}
        newArray['companyId'] = companyDetailsListing.id;
        newArray['companyName'] = companyDetailsListing.companyName;
        newArray['overallESGQuotient'] = companyDetailsListing.overallEsgQuotient;
        newArray['esgColorStatus'] = companyDetailsListing.esgColorStatus;
        newArray['imageFileName'] = companyDetailsListing.companyImgFileName;
        newArray['columnNo'] = columnNo;
        data.push(newArray);
        this.dataLabels.push(newArray);
        count++;
        if(columnNo % 20 == 0){
          dashboardArray.push(data);
          columnNo = 1;
          data = [];
        }
        else{
          if(count == this.totalCompanyCount){
            dashboardArray.push(data);
          }
          else{
            columnNo++;
          }
        }
      }
      this.dataSourceLabels = new MatTableDataSource(this.dataLabels);
      this.dataSource = [];
      this.dataSource = new MatTableDataSource(dashboardArray);
      this.dataSource.paginator = this.paginator;
      this.showPaginator();
      this.loading = false;
      
    })
  }

  showPaginator(){
    this.displayPaginatorClass = "paginator-show";
  }

  hidePaginator(){
    this.displayPaginatorClass = "paginator-hide";
  }

  applyFilter(filterValue: string) {
      
      let dataSrc = [];
      if(filterValue.length == 0){
        this.showflag = true;
        this.showPaginator();
      }
      else{
        this.showflag = false;  
        this.hidePaginator();
      }
      
      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      
      this.dataSourceLabels.filter = filterValue;
      if (this.dataSourceLabels.filteredData.length == 0) {
        this.flag = true;
      } 
      else {
        this.flag = false;
        dataSrc.push(this.dataSourceLabels.filteredData)
        this.dataSrc = new MatTableDataSource(dataSrc);
      }
  }

  OptionsESG(flag) {
      var per = 50;
      this.chartOptions = {
        responsive: true,
        cutoutPercentage: per,
        animation:false,
      };
      return this.chartOptions;
  }

  doughnutChartESGData(esg: number) {
  	if(esg > 1)
      esg = 1;
    this.doughnutData = [esg, (1 - esg)];
    return this.doughnutData;
  }

  chartColorESG(esgColorStatus: number) {

    /* 3=> green , 2=> amber , 1 => red */
    if (esgColorStatus == 3)
      this.chartColors = [{ backgroundColor: ["#34a853", "#dddddd"] }];
    else if (esgColorStatus == 2)
      this.chartColors = [{ backgroundColor: ["#f39700", "#dddddd"] }];
    else
      this.chartColors = [{ backgroundColor: ["#ea4335", "#dddddd"] }];  

    return this.chartColors;
  }

  // goToClick(id:any){
  //   this._router.navigate(['/performanceManagement/dashboard/companyDetails/'+id]);
  // }
  
  goToClick(id:any){
    this._router.navigate(['/performanceManagement/dashboard/integratedCompany/'+id]);
  }
}