import { Component, OnInit } from '@angular/core';
import { AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import { Router,ActivatedRoute } from '@angular/router';
import {FormControl, FormBuilder, Validators,FormGroup  } from '@angular/forms';
import { identifierName } from '@angular/compiler';
import swal from 'sweetalert2';
import { isEmpty } from 'rxjs/operators';
import { AddEditSuccessComponent } from '../../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
@Component({
  selector: 'app-template-settings',
  templateUrl: './template-settings.component.html',
  styleUrls: ['./template-settings.component.css']
})
export class TemplateSettingsComponent implements OnInit {

  templatId:number;
  templateData:any = [];
  showFactor:any = [];
  isVisible:any = [];
  showsubFactor :any = [];
  isVisibleQuestion:any =[];
  factorList:any=[];
  questionVOList:any  =[];
  questionList:any=[];
  subFactorList:any = [];
  factorListForm: any ={};
  subfactorListForm:any={};
  questionListForm:any ={};
  recordFoundFlag:boolean;
  weightageList:any=[];
  statusFlg: string;
  constructor(private addEditSuccessComponent: AddEditSuccessComponent,private assessementService:AssessmentTemplateMasterdataService
  , private activatedRoute: ActivatedRoute,private router:Router,
  private formBuilder: FormBuilder) { 
  }

  ngOnInit() {
    this.statusFlg="N";
      this.activatedRoute.params.subscribe(params => { this.templatId = params.templateId });
    this.activatedRoute.queryParams.subscribe(values => {
      this.statusFlg = values.statusFlg;}) 
    this.setWeightageList();
    this.assessementService.getTemplateSettings(this.templatId).subscribe((data) => {
      this.templateData = data;
      this.factorList = this.templateData.factorVOList
      if( this.factorList.length >0)
      {
        this.recordFoundFlag =true;
      }
      else
      {
        this.recordFoundFlag =false;

      }
    for(let factor of this.templateData.factorVOList){
      
      this.factorListForm[factor.id]= this.createForm(factor.id,factor.minScore,factor.weightage);
        for(let subfactor of factor.subFactorVOList){
          this.subfactorListForm[subfactor.id]= this.createForm(subfactor.id,subfactor.minScore,subfactor.weightage);  

          for(let question of subfactor.questionVOList){
             this.questionListForm[question.id] = this.ceateFormForQuestion(question.id,question.weightage,question.criticalFlag);  
          }
        }


    }

  }, (error) => {
    console.log(error);
  });

  }
  setWeightageList()
  {
    for(let i=1;i<=10;i++)
    {
      this.weightageList.push(i);
    }
  }
  createForm(id,minScore,weightage) {
   
      return this.formBuilder.group({
          id:[id],
          minScore:[minScore,Validators.compose([Validators.required,Validators.pattern('^[0-9]*$') ])],
          weightage:[weightage,[Validators.required]]
         
    });
  }
  ceateFormForQuestion(id,weightage,criticalFlag) {
   if(criticalFlag=='Y'){
    criticalFlag='true';
   }
   else{
    criticalFlag='false';
   }
    return this.formBuilder.group({
        id:[id],
        weightage:[weightage,[Validators.required]],
        criticalFlag:[criticalFlag]
  });
}
  toggleFactor(factorId,factorVOList,i) {
  
  for(let factor of factorVOList){
    if(factor.id == factorId ){
     
       this.subFactorList = factor.subFactorVOList;
      
      if(this.subFactorList.length>0){

      
      if(this.showFactor[i]){
        this.showFactor[i]= false;
      }
      else{
        this.showFactor[i]= true;
      }
      if(this.isVisible[i]){
        this.isVisible[i]= false;
      }
      else{
        this.isVisible[i]= true;
      }
    }
    }
  
  }
}
toggleSubFactor(subfactorId,subFactorVOList,index){
 
  for(let subFactor of subFactorVOList){
    if(subFactor.id == subfactorId ){
     
      this.questionList= subFactor.questionVOList;
      if(this.questionList.length>0){
      
     
      if(this.showsubFactor[index]){
        this.showsubFactor[index]= false;
      }
      else{
        this.showsubFactor[index]= true;
      }
      if(this.isVisibleQuestion[index]){
        this.isVisibleQuestion[index]= false;
      }
      else{
        this.isVisibleQuestion[index]= true;
      }
    }
     
    }

  }
  
}
onSubmit(){
  
  let factorListArray = [];
  let subfactorListArray = [];
  let questionListArray = [];
  let submitStatusForFactor =[];
  let submitStatusForSubFactor=[];
  let submitStatusForQuestion=[];

    for (let factor in this.factorListForm) {
      if(this.factorListForm[factor].valid)
      {
          factorListArray.push((this.factorListForm[factor].value));
          submitStatusForSubFactor[factor] =true;
      }
      else
      {
          submitStatusForSubFactor[factor] =false;
        this.factorListForm[factor].controls['weightage'].markAsTouched();
       this.factorListForm[factor].controls['minScore'].markAsTouched();


      }   
    }
    for(let subfactor in this.subfactorListForm){
     if(this.subfactorListForm[subfactor].valid)
     {
        subfactorListArray.push(this.subfactorListForm[subfactor].value)
        submitStatusForFactor[subfactor] =true;

     }
     else
     {
        submitStatusForFactor[subfactor] =false;
      this.subfactorListForm[subfactor].controls['weightage'].markAsTouched();
      this.subfactorListForm[subfactor].controls['minScore'].markAsTouched();

     }
    }
    for(let question in this.questionListForm){
      if(this.questionListForm[question].valid)
      {
        if(this.questionListForm[question].get('criticalFlag').value == true)
        {
          this.questionListForm[question].get('criticalFlag').setValue('Y');
        }
        else{
          this.questionListForm[question].get('criticalFlag').setValue('N');
        }
        questionListArray.push(this.questionListForm[question].value)
        submitStatusForQuestion[question]=true;
      } 
      else
      {
        submitStatusForQuestion[question] =false;
        this.questionListForm[question].controls['weightage'].markAsTouched();



      }
    }
    let submitInvalidStatusForQuestion  = submitStatusForQuestion.filter(status =>status === false);
    let submitInvalidStatusForFactor    = submitStatusForFactor.filter(status =>status === false);
    let submitInvalidStatusForSubFactor = submitStatusForSubFactor.filter(status =>status === false);
    if(submitInvalidStatusForQuestion.length ==0 && submitInvalidStatusForFactor.length==0 && submitInvalidStatusForSubFactor.length==0)
    {
          let factorSubFactorQuestionListVO ={};
          factorSubFactorQuestionListVO['factorVOList'] = factorListArray;
          factorSubFactorQuestionListVO['subFactorVOList'] = subfactorListArray;
          factorSubFactorQuestionListVO['questionVOList'] =  questionListArray;
          factorSubFactorQuestionListVO['templateId'] =  this.templatId;
          this.assessementService.addTemplateConfiguration(factorSubFactorQuestionListVO).subscribe((myform)=>{
            
            var message = "Successfully Submitted Template Configuration";
            // swal({
            //     title: 'Success!',
            //     text: message,
            //     type: 'success',
            //     confirmButtonText: 'Ok'
            //   })
            this.addEditSuccessComponent.showSuccessSwal(message)
            .then((value) => {
                if(this.statusFlg=='Y'){
                  this.router.navigate(['/listAssessmentCompanies']);
                }
                else
                  this.router.navigate(['/listTemplate']); 
      
              });
            },(error)=>{
              console.log(error);
            });

    }
   
  }

}
