import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ParametersService } from "../../parameters.service";
import { RoutesRecognized } from "@angular/router";
import { filter, pairwise } from "rxjs/operators";
import { ReportConstantsComponent } from "../../../report-constants/report-constants.component";

@Component({
  selector: "app-subparameters",
  templateUrl: "./subparameters.component.html",
  styleUrls: ["./subparameters.component.css"],
})
export class SubParametersComponent implements OnInit {
  public parameterLevel: any;
  public tab: any;
  public doughnutChartType = "doughnut";
  public doughnutData: any;
  public chartColors: any;
  public chartOptions: any;
  public levelName: any = null;
  public targetFlag: any = false;
  public nextLevelName: any;
  public currency: string;
  public imagesArray:any=[]
  constructor(
    private _parametersService: ParametersService,
    private _router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe(params => (this.tab = params.id));
  }

  ngOnInit() {
    this.currency = ReportConstantsComponent.CURRENCY;
    this.imagesArray=['mortgages.png','realstate.png','equity.png','debt.png','projectfinance.png','businessloan.png']
    this._parametersService.getSubParameters(this.tab).subscribe(
      parameterLevel => {
     
        this.parameterLevel=parameterLevel
       
        for (let parameterDetails of parameterLevel) {
          this.levelName = parameterDetails.levelName;
          if (this.levelName == "Ownership type") {
            this.levelName = "Diversity, Equity & Inclusion (DEI)";
          }
          if (this.levelName == "Asset class") {
            this.levelName = "Financed Emission";
          }
          let nextLevelNameValue = parameterDetails.nextLevelName;
          this.nextLevelName = nextLevelNameValue === null || "" || undefined ? "--" : parameterDetails.nextLevelName;
          this.targetFlag = parameterDetails.targetFlag;
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  drillDown(levelNo: number, id: number, subParamCount: number) {
    if (subParamCount > 0) {
      this._router.navigate(["/performanceManagement/dashboard/listReports/" + levelNo + "/" + id]);
    } else if (subParamCount == 0) {
      this._router.navigate(["/performanceManagement/dashboard/companyDetails/" + levelNo + "/" + id]);
    }
  }

  doughnutChartData(percentage: number) {
    if (percentage > 100) percentage = 100;
    this.doughnutData = [percentage, 100 - percentage];
    return this.doughnutData;
  }

  OptionsESG() {
    this.chartOptions = {
      responsive: true,
      cutoutPercentage: 70,
    };
    return this.chartOptions;
  }

  doughnutChartESGData(esg: number) {
    if (esg > 1) esg = 1;
    this.doughnutData = [esg, 1 - esg];
    return this.doughnutData;
  }

  chartColorESG(esgColorStatus: number) {
    /* 3=> green , 2=> amber , 1 => red */
    if (esgColorStatus == 3) this.chartColors = [{ backgroundColor: ["#34a853", "#dddddd"] }];
    else if (esgColorStatus == 2) this.chartColors = [{ backgroundColor: ["#f39700", "#dddddd"] }];
    else this.chartColors = [{ backgroundColor: ["#ea4335", "#dddddd"] }];

    return this.chartColors;
  }
}
