import { Component, OnInit,ViewChild } from '@angular/core';
import { PerformanceFrameworkHigherService } from '../performance-framework-higher.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-view-archives',
  templateUrl: './view-archives.component.html',
  styleUrls: ['./view-archives.component.css']
})
export class ViewArchivesComponent implements OnInit {

  parameterId:number;
  levelId:number;
  frameworkArchiveList: any;
  dataSource: any ;
  displayedColumns: any; 
  jsonArray: any = [];
  levelName:string;
  flag:boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private performanceFrameworkService: PerformanceFrameworkHigherService,private activatedRoute: ActivatedRoute,private router:Router) 
  {
      this.activatedRoute.queryParams.subscribe(params => { this.parameterId = params.parameterId,this.levelId = params.levelId,
      this.levelName = params.levelName });

 }

  ngOnInit() 
  {

    this.performanceFrameworkService.getArchievedFrameworkforHigher(this.parameterId,this.levelId).subscribe((data)=>{
      this.frameworkArchiveList = data;
      this.dataTablePopulation(this.frameworkArchiveList);

   });
  }

  dataTablePopulation(archiveValues) 
  {
    if (archiveValues.length == 0)
    this.flag = true;
    else
    this.flag = false;

  // Data table  //

  this.displayedColumns = [];
  let jsonArray = [];

  var count = 0;
  for (let archiveVal of archiveValues) {
    
    var newArray = {};
    newArray['Sno'] = ++count;
    newArray['versionNumber'] = archiveVal.frameworkVersion;
    newArray['id'] = archiveVal.frameworkId;
    newArray['startDate'] = archiveVal.createdDateString;
    newArray['updatedDate'] = archiveVal.lastUpdatedDate;

    jsonArray.push(newArray); 

  }

  this.displayedColumns = ['Sno','versionNumber', 'startDate', 'updatedDate', 'Actions']
  this.dataSource=[];
  this.dataSource = new MatTableDataSource(jsonArray);
  this.dataSource.paginator = this.paginator;
  this.dataSource.sort = this.sort;

}
applyFilter(filterValue: string) {
  filterValue = filterValue.trim();
  filterValue = filterValue.toLowerCase();
  this.dataSource.filter = filterValue;  
}
redirectToViewFrameworkPage(frameworkId){
  let frameworkDetails = {};
  frameworkDetails['versionId'] = frameworkId;
  frameworkDetails['redirectFromArchievePage'] =true;
  frameworkDetails['parameterId']=this.parameterId;
  frameworkDetails['levelId']=this.levelId;
 
  this.router.navigate(['/viewFrameworkDetails'], { queryParams: frameworkDetails, skipLocationChange: true });
}
}
