import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ImpactAssessmentService } from '../../../impact-assessment.service';
import {ReportConstantsComponent} from '../../../../report-constants/report-constants.component'
@Component({
  selector: 'app-report-framework-standards',
  templateUrl: './report-framework-standards.component.html',
  styleUrls: ['./report-framework-standards.component.css']
})
export class ReportFrameworkStandardsComponent implements OnInit {

  sdgList:any;
  sliceList:any;
  loading:boolean;
  public locale:string;
  constructor(private _impactAssessmentService:ImpactAssessmentService,  private _router: Router) {this.loading = false; }

  ngOnInit() {

    this.locale =ReportConstantsComponent.LOCALE;
    this.loading = true;
    this._impactAssessmentService.getSDGList().subscribe((sdgDetails)=>{
      this.sdgList = sdgDetails;
      console.log('sdggg')
      console.log(this.sdgList)
      this.loading = false;
    });
    
  }
  toggleIndicator(id, indicatorId) {
    //Collapse all open indicator details
    var scheduleRows = document.querySelectorAll('.display-block');
    var scheduleindicatorRows = document.querySelectorAll('.collapse_arrow-2');
    if (scheduleRows.length > 0) {
      for (let i = 0; i < scheduleRows.length; i++) {
        scheduleRows[i].classList.remove('display-block');
        scheduleRows[i].classList.add('display-none');
      }
    }

    if (document.getElementById("rep" + id) && document.getElementById("rep" + id).className == "expand_arrow-2") {
      document.getElementById("rep" + id).classList.remove("expand_arrow-2");
      document.getElementById("rep" + id).classList.add("collapse_arrow-2");

      if (document.getElementById("indicators" + id)) {
        document.getElementById("indicators" + id).classList.remove("display-none");
        document.getElementById("indicators" + id).classList.add("display-block");
      }

      
    }
    else {
      if (document.getElementById("rep" + id)) {
        document.getElementById("rep" + id).classList.remove("collapse_arrow-2");
        document.getElementById("rep" + id).classList.add("expand_arrow-2");
      }

      if (document.getElementById("indicators" + id)) {
        document.getElementById("indicators" + id).classList.remove("display-block");
        document.getElementById("indicators" + id).classList.add("display-none");
      }


    }
    if (scheduleindicatorRows.length > 0) {
      for (let i = 0; i < scheduleindicatorRows.length; i++) {
        scheduleindicatorRows[i].classList.remove('collapse_arrow-2');
        scheduleindicatorRows[i].classList.add('expand_arrow-2');
      }
    }

  }
  downloadIndicatorDetails(sdgId){
    
      //window.location.href = this._impactAssessmentService.getBaseUrl()+"/downloadRegReporting?sdgId="+encodeURIComponent(sdgId); 
     
      let url = this._impactAssessmentService.getBaseUrl()+'/downloadRegReporting/'+sdgId;
    window.open(url, "_blank");
  }
}
