import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges } from '@angular/core';
import { DataAcqCommonService } from '../../data-quality-master/data-acq-common.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { PerformanceRecordService } from '../performance-record.service';
import { AddEditSuccessComponent } from '../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import swal from 'sweetalert2';
import { DocumentPopupComponent } from '../document-popup/document-popup.component';

@Component({
  selector: 'app-schedule-wise-record',
  templateUrl: './schedule-wise-record.component.html',
  styleUrls: ['./schedule-wise-record.component.css']
})
export class ScheduleWiseRecordComponent implements OnInit {

  dataFromLevelId: any;
  toolTipToShow = [];
  userName: string = "Tony George";
  companyIndicatorDetailsVOList: any;
  indicatorVOList: any = [];
  companyTypeData: any = [];
  selectedCompanyId: number;
  companyVOList: any = [];
  scheduleFormGroupArray: any = [];
  dpScheduleGroupArray: any = [];
  selectedCompanyVO: any = [];
  selectedIndicatorId: any;
  dpschedule: any;
  selectedindicatorVO: any;
  fileName: string;
  fileUploadName: string;
  UploadNameList = [];
  dbStoredName = [];
  schedulleForm: any;
  fileArray = [];
  fileNameArray = [];
  showName: any;
  dpFileList: { [key: string]: string };
  fileNames = [];
  currentScheduleId: any = 0;
  currentArrowScheduleId: any = 0;
  loading: boolean;
  companyId: any;
  scheduleYear: any;
  secondaryFlag: boolean = false;
  recordDetails: any;
  companyName: any;
  @ViewChild('uploadFileTxt') uploadFileTxt: ElementRef;
  @ViewChild('filePart') filePart: ElementRef;

  fileNamesMap: Map<string, string> = new Map<string, string>();
  constructor(private dataAcqCommonService: DataAcqCommonService, private performanceRecordService: PerformanceRecordService,
    private formBuilder: FormBuilder, private dialog: MatDialog, private activatedRoute: ActivatedRoute, private addEditSuccessComponent: AddEditSuccessComponent) {

    if (this.activatedRoute.snapshot.queryParamMap.get('id')) {
      this.companyId = this.activatedRoute.snapshot.queryParamMap.get('id');
      this.scheduleYear = this.activatedRoute.snapshot.queryParamMap.get('year');
      this.companyName=this.activatedRoute.snapshot.queryParamMap.get('name');
    }
    else {
      this.companyId = 0;
    }
    this.loading = false;
  }
  @Input() text: string;

  ngOnChanges(changes: SimpleChanges) {

    if (changes['text']) {

    }
  }
  ngOnInit() {

    this.getPerformanceRecord();

  }

  transform(fileName) {
    return fileName.substr(fileName.indexOf('__') + 2, fileName.length);
  }
  getPerformanceRecord() {
    this.loading = true;
    let performanceRecordDataVo={}
    performanceRecordDataVo['companyId']=this.companyId;
    performanceRecordDataVo['responsibleUser']=this.userName;
    performanceRecordDataVo['year']=this.scheduleYear;
    this.performanceRecordService.fetchPerformanceRecordBySchedule(performanceRecordDataVo).subscribe((data) => {
      this.recordDetails = data;
      console.log( this.recordDetails);
      this.scheduleYear = this.scheduleYear;
      this.selectedCompanyVO = this.recordDetails.companyIndicatorDetailsVOList;
      this.indicatorVOList = this.recordDetails.companyIndicatorDetailsVOList;
      // console.log( this.indicatorVOList);
      this.selectedCompanyId = this.companyId;
      this.createIndicatorFormGroups();
      this.tabClick(1);
      this.loading = false;
      
    });
  }
  createIndicatorFormGroups() {
    this.scheduleFormGroupArray = [];
    let indicatorList = this.indicatorVOList
    for (let indicator of indicatorList) {
      // console.log(indicator);
      this.scheduleFormGroupArray[indicator.companyIndicatorScheduleId] = this.createFormGroup(indicator);
    }
//     console.log("scheduleFormGroupArray");
// console.log(this.scheduleFormGroupArray);
  }
  createFormGroup(indicator) {
   
    let indicatorId = indicator.indicatorId;
    let actualValue = null;
    let validate = Validators.pattern(/^(?!\s+$).+/);
    if (indicator.actual)
      actualValue = indicator.actual;
    let actualRemarks = indicator.actualRemarks;
    let unitofmeasurementId;
    if (indicator.indicatorVO.indicatorDetailsVO) {
      if (indicator.indicatorVO.indicatorDetailsVO.unitOfMeasurementVOList && indicator.unitOfMeasurementId)
        unitofmeasurementId = indicator.unitOfMeasurementId;
    }
    else {
      unitofmeasurementId = 0;
    }
if(indicator.indicatorVO!=null){
    if (indicator.indicatorVO.indicatorType.code == "QR" || indicator.indicatorVO.indicatorType.code == "QU") {
      if (indicator.actual) {
        actualValue = Number(indicator.actual);
      }
      validate = Validators.pattern(/^\-?[0-9]\d{0,9}(\.\d{1,2})?%?$/);
    }
  }
   // if(indicator.indicatorScheduleSubmitFlag=='N' || indicator.indicatorScheduleSubmitFlag=='Z'){
    
      this.schedulleForm =
      this.formBuilder.group({
        actualValue: [actualValue, validate],
        actualRemarks: [actualRemarks, Validators.pattern(/^(?!\s+$).+/)],
        originalFileName: [indicator.fileName],
        evidenceUploadFileName: [indicator.fileUploadName],
        submitFlag: [0],
        scheduleId: [indicator.companyIndicatorScheduleId],
        updatedBy: [this.userName],
        companyIndicatorScheduleId: [indicator.companyIndicatorScheduleId],
        indicatorId: indicatorId,
        targetValue: [indicator.targetValue],
        indSchSubmitFlag: [indicator.indicatorScheduleSubmitFlag],
        unitOfMeasurementVO: this.formBuilder.group({
          id: [unitofmeasurementId]
        }),
        indicatorCalculationVO: []
      });
      
    // }
    // else{
     
    //   this.schedulleForm =
    //   this.formBuilder.group({
    //     actualValue: [{ value: actualValue, disabled: true }, validate],
    //     actualRemarks: [{ value: actualRemarks, disabled: true }, Validators.pattern(/^(?!\s+$).+/)],
    //     originalFileName: [indicator.fileName],
    //     evidenceUploadFileName: [indicator.fileUploadName],
    //     submitFlag: [0],
    //     scheduleId: [indicator.companyIndicatorScheduleId],
    //     updatedBy: [this.userName],
    //     companyIndicatorScheduleId: [indicator.companyIndicatorScheduleId],
    //     indicatorId: indicatorId,
    //     targetValue: [indicator.targetValue],
    //     indSchSubmitFlag: [indicator.indicatorScheduleSubmitFlag],
    //     unitOfMeasurementVO: this.formBuilder.group({
    //       id: [{ value: unitofmeasurementId, disabled: true }]
    //     }),
    //     indicatorCalculationVO: []
    //   });
    // }
//     console.log("scheduleform");
// console.log(this.schedulleForm);

    return this.schedulleForm;
  }





  handleFileInput(event, scheduleId, dataPointId, upID, dpMappingId, indicatorId) {

    const file = event.target.files[0];
    let fileName = new Date().getTime() + "__" + file.name;
    let showName = file.name;
    document.getElementById(upID).innerHTML = showName;

    this.fileArray.push(file);
    this.fileNameArray.push(fileName);

    if (dataPointId > 0) {
      if (document.getElementById('dpfilePart1' + dpMappingId + indicatorId)) {
        document.getElementById('dpfilePart1' + dpMappingId + indicatorId).classList.remove("display-none");
        document.getElementById('dpfilePart1' + dpMappingId + indicatorId).classList.add("display-block");
      }
      document.getElementById(upID).innerHTML = showName;
      if (document.getElementById('dpfilePart' + dpMappingId + indicatorId)) {
        document.getElementById('dpfilePart' + dpMappingId + indicatorId).classList.add("display-none");
      }
      if (this.fileNamesMap.has(dataPointId + "_" + scheduleId)) {
        this.fileNamesMap.delete(dataPointId + "_" + scheduleId);
        this.fileNamesMap.set(dataPointId + "_" + scheduleId, fileName);
      }
      else {
        this.fileNamesMap.set(dataPointId + "_" + scheduleId, fileName);

      }
    }
    else {
      if (document.getElementById('filePart1' + scheduleId)) {
        document.getElementById('filePart1' + scheduleId).classList.remove("display-none");
      }
      if (document.getElementById('filePart' + scheduleId)) {
        document.getElementById('filePart' + scheduleId).classList.add("display-none");
      }
      if (this.scheduleFormGroupArray[scheduleId] && this.scheduleFormGroupArray[scheduleId] != undefined) {
        this.scheduleFormGroupArray[scheduleId].get('originalFileName').setValue(showName);
        this.scheduleFormGroupArray[scheduleId].get('evidenceUploadFileName').setValue(fileName);
        // console.log(this.scheduleFormGroupArray);
      }
    }
   

  }
  submitForm(submitFlag) {
    let submitValid = false;
    let indicatorsList = [];
    let indicatorVOList = {};
   console.log(this.scheduleFormGroupArray.value);
    for (let indicator in this.scheduleFormGroupArray) {
      var formGroupArrayValues = [];
      let formDetails = this.scheduleFormGroupArray[indicator].value;
      if (this.scheduleFormGroupArray[indicator] && this.scheduleFormGroupArray[indicator] != undefined) {
        let indicatorVO = {};
        let indicatorConfigurationVO = {};
        let datapointVOList = {};
        let indicatorType = {};
        let indicatorDetailsVO = {};
        indicatorVO['id'] = formDetails.indicatorId;

        this.scheduleFormGroupArray[indicator].get('submitFlag').setValue(submitFlag);
        if (submitFlag == 2)
          this.scheduleFormGroupArray[indicator].get('indSchSubmitFlag').setValue('Y');
        else
          this.scheduleFormGroupArray[indicator].get('indSchSubmitFlag').setValue('N');

        let scheduleFormGroup: FormGroup;
        scheduleFormGroup = this.scheduleFormGroupArray[indicator];
        if (scheduleFormGroup.get('actualValue').value == "")
          scheduleFormGroup.get('actualValue').setValue(null);
        indicatorConfigurationVO['scheduleVO'] = scheduleFormGroup.getRawValue();
        if (this.scheduleFormGroupArray[indicator].valid) {
          submitValid = true;
        } else {
          submitValid = false;
          break;
        }
        indicatorVO['indicatorConfigurationVO'] = indicatorConfigurationVO;
        
        indicatorsList.push(indicatorVO);
      }
      indicatorVOList['indicatorVOList'] = indicatorsList;
      
    }
    if (submitValid) {
      this.loading = true;
     
      this.performanceRecordService.createIndecatorScheduleWise(indicatorVOList['indicatorVOList']).subscribe(() => {
        this.loading = false;
        if (this.fileArray.length > 0) {
          const formData: any = new FormData();
          const files: Array<File> = this.fileArray;
          const filenames: Array<String> = this.fileNameArray;
          for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
            formData.append('fileName', filenames[i]);
          }
          this.performanceRecordService.uploadPerfomanceRecordFile(formData).subscribe(() => {
          });
        }
        swal({
          title: 'Success!',
          text: 'Updated Performance Record',
          type: 'success',
          confirmButtonText: 'Ok'
        })
        let msg = "Updated Performance Record";
        this.addEditSuccessComponent.showSuccessSwal(msg)
          .then((value) => {

            this.fileNamesMap.clear();
            this.fileNameArray = [];
            this.fileArray = []

          });
       

      });
    }

  }
  download(fileName) {
    
  
    window.location.href = this.performanceRecordService.downloadPerformanceRecordFile() + '/downloadPerfomanceRecordFile?filename=' + encodeURIComponent(fileName);

  }


  deleteScheduleFile(scheduleId, schedule) {
    if (document.getElementById('filePart1' + scheduleId)) {
      document.getElementById('filePart1' + scheduleId).classList.add("display-none");
    }
    if (document.getElementById('filePart' + scheduleId)) {
      document.getElementById('filePart' + scheduleId).classList.add("display-none");
    }


    schedule.evidenceUploadFileName = null;
    schedule.originalFileName = null;

    if (this.scheduleFormGroupArray[scheduleId] && this.scheduleFormGroupArray[scheduleId] != undefined) {
      this.scheduleFormGroupArray[scheduleId].get('originalFileName').setValue(null);
      this.scheduleFormGroupArray[scheduleId].get('evidenceUploadFileName').setValue(null);
    }





  }
  deleteDatapointScheduleFile(companyIndicatorDatapointMapping, datapointScheduleId, dpid, indicatorId) {
    if (document.getElementById('dpfilePart1' + companyIndicatorDatapointMapping + indicatorId)) {
      document.getElementById('dpfilePart1' + companyIndicatorDatapointMapping + indicatorId).classList.remove("display-block");
      document.getElementById('dpfilePart1' + companyIndicatorDatapointMapping + indicatorId).classList.add("display-none");
    }
    if (document.getElementById('dpfilePart' + companyIndicatorDatapointMapping + indicatorId)) {
      document.getElementById('dpfilePart' + companyIndicatorDatapointMapping + indicatorId).classList.add("display-none");
    }

    for (let dp in this.dpScheduleGroupArray) {
      if (dp == dpid) {
        let newdpSchedule = this.dpScheduleGroupArray[dpid];
        for (let dpScheduleId in newdpSchedule) {
          if (newdpSchedule[dpScheduleId] && newdpSchedule[dpScheduleId] != undefined && newdpSchedule[dpScheduleId].get('scheduleId').value == datapointScheduleId) {
            if (this.fileNamesMap.has(dpid + "_" + datapointScheduleId)) {
              this.fileNamesMap.delete(dpid + "_" + datapointScheduleId);
            }
            newdpSchedule[dpScheduleId].get('evidenceUploadFileName').setValue(null);
            newdpSchedule[dpScheduleId].get('originalFileName').setValue(null);

          }
        }
      }
    }
  }
  showDocPopup(event, dpId, flag,  formGrp, indicatorVO) {
    this.selectedCompanyId
    let endDate = indicatorVO.scheduleEndDate;
    let index = indicatorVO.companyIndicatorScheduleId;
    // document.getElementById(this.currentArrowScheduleId).classList.add("show-left-arrow");
    let offsetLeft = 0;
    let offsetTop = 0;
    let popupTop = 0;

    let el = event.srcElement;
    let indicatorName = indicatorVO.indicatorName;
    let indicatorCode = indicatorVO.indicatorName;
    let indicatorType = indicatorVO.indicatorVO.indicatorType.code;
    let ratingGroupValuesVOList = [];
    if (indicatorType == "QR")
      ratingGroupValuesVOList = indicatorVO.indicatorVO.indicatorDetailsVO.ratingGroupVO.ratingGroupValuesVOList;
      let year = endDate.substring(endDate.length - 4, endDate.length);
    while (el) {
      offsetLeft += el.offsetLeft;
      offsetTop += el.offsetTop;
      el = el.parentElement;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = 1;
    this.dialog.closeAll();

    const dialogRef = this.dialog.open(DocumentPopupComponent, {
      data: { index: index, flag: flag, dpId: dpId, indicatorId: indicatorVO.indicatorId, indicatorName: indicatorName, indicatorCode: indicatorCode, selectedCompanyVO: this.selectedCompanyVO, year: this.scheduleYear, formgroup: formGrp, scheduleVO: indicatorVO, indicatorType: indicatorType, ratingGroupValuesVOList: ratingGroupValuesVOList , companyId : this.selectedCompanyId,scheduleYear : this.scheduleYear},
      position: {
        right: '2.6rem'
      }
    });
  }
  calculateDatapointAggregation(indId, scheduleId, formGrp) {

    let indicatorVO = {};
    let indicatorConfigurationVO = {};
    let indicatorType = {};
    indicatorVO['id'] = indId;
    indicatorVO['code'] = this.selectedindicatorVO.code
    indicatorType['code'] = this.selectedindicatorVO.indicatorType.code;
    indicatorVO['indicatorType'] = indicatorType;
    indicatorConfigurationVO['scheduleId'] = scheduleId;
    indicatorVO['indicatorConfigurationVO'] = indicatorConfigurationVO;
    indicatorVO['indicatorDetailsVO'] = this.selectedindicatorVO.indicatorDetailsVO;
    this.performanceRecordService.getCalculatedActualsFromDatapoint(indicatorVO).subscribe((indicatorVORes) => {
      let calculatedActual = indicatorVORes['indicatorConfigurationVO'].scheduleVOList[0].actualValue;
      formGrp.patchValue({
        actualValue: calculatedActual
      });
    });
  }
  editActualValue(scheduleForm) {
    scheduleForm.get('actualValue').enable();
    scheduleForm.get('secondaryData').enable();
  }
  tabClick(value) { var scheduleRows = document.querySelectorAll('.sub-factor-row');
  var tabsRow = document.querySelectorAll('.schedule-tab');
  if (scheduleRows.length > 0) {
    for (let i = 0; i < scheduleRows.length; i++) {
      scheduleRows[i].classList.remove('display-block');
      scheduleRows[i].classList.add('display-none');
    }
  }
  if (tabsRow.length > 0) {
    for (let i = 0; i < tabsRow.length; i++) {
      tabsRow[i].classList.remove('schedule-tab-active');
    }
  }
  document.getElementById("tab" + value).classList.add("schedule-tab-active");
  if (value == 1) {
    var envRows = document.querySelectorAll('#ENV');
    if (envRows.length > 0) {
      for (let i = 0; i < envRows.length; i++) {
        envRows[i].classList.remove('display-none');
        envRows[i].classList.add('display-block');
      }
    }
  else {
    document.getElementById("noInd").classList.remove("display-none");
    document.getElementById("noInd").classList.add("display-block");
  }
  }
  else if (value == 2) {
    var socRows = document.querySelectorAll('#SOC');
      if (socRows.length > 0) {
        for (let i = 0; i < socRows.length; i++) {
          socRows[i].classList.remove('display-none');
          socRows[i].classList.add('display-block');
        }
      }
    else {
      document.getElementById("noInd").classList.remove("display-none");
      document.getElementById("noInd").classList.add("display-block");
    }
  }
  else {
      var govRows = document.querySelectorAll('#GOV');
      if (govRows.length > 0) {
        for (let i = 0; i < govRows.length; i++) {
          govRows[i].classList.remove('display-none');
          govRows[i].classList.add('display-block');
        }
      }
    else {
      document.getElementById("noInd").classList.remove("display-none");
      document.getElementById("noInd").classList.add("display-block");
    }
  }
}
}
