import { HttpClient } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseUrlService } from '../../shared/base-url.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IndicatorMasterService {

  private baseUrl = BaseUrlService.INDICATORBASEURL;

  constructor(private http: HttpClient) { }

  getCategories(): Observable<any> {
    return this.http.get(`${this.baseUrl}/category`);
  }

  getSubCategories(categoryId: number): Observable<any> {
    if (categoryId)
      return this.http.get(`${this.baseUrl}/subcategoryBycategory/` + categoryId);
    else
      return this.http.get(`${this.baseUrl}/subcategory`);
  }

  deleteSubCategory(subCategoryId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/subcategory/` + subCategoryId, { responseType: 'text' });
  }

  createSubCategory(subcategoryform: any) {

    let body = JSON.stringify(subcategoryform);
    let url = this.baseUrl + '/subcategory';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);
  }

  getSDGs(): Observable<any> {
    return this.http.get(`${this.baseUrl}/sdg`);
  }
  createSDG(sdgForm: any) {


    let body = JSON.stringify(sdgForm);
    let url = this.baseUrl + '/sdg';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);


  }
  deleteSDG(sdgId: number) {

    let url = this.baseUrl + '/sdg/' + sdgId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, null, httpOptions);


  }

  //Constants

  createConstant(addConstantForm: any) {
    let body = JSON.stringify(addConstantForm);
    let url = this.baseUrl + '/constants';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post(url, body, httpOptions);
  }

  deleteConstant(id): Observable<any> {

    let url = this.baseUrl + '/constants/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, null, httpOptions);
  }

  getConstantsList(): Observable<any> {
    return this.http.get(`${this.baseUrl}/constants`);
  }


  getConstantsListByConstantType(constantType): Observable<any> {
    return this.http.get(`${this.baseUrl}/constants/` + constantType);
  }

  // Indicator Stack

  getListOfIndicatorStacks(): Observable<any> {
    return this.http.get(`${this.baseUrl}/stack`);
  }
  getIndicatorStackByCategoryId(categoryId: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/stackByCategory/` + categoryId);
  }
  getIndicatorStackBySubcategoryId(subCategoryId: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/stackBySubcategory/` + subCategoryId);
  }
  createIndicatorStack(indicatorStackForm: any): Observable<any> {
    let body = JSON.stringify(indicatorStackForm);
    let url = this.baseUrl + '/stack';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);

  }
  deleteIndicatorStack(indStackId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/stack/` + indStackId, { responseType: 'text' });
  }

  // Unit Of Measurement
  getunitOfMeasurementList(groupSelected): Observable<any> {

    if (groupSelected)
      return this.http.get(`${this.baseUrl}/unitOfMeasurement/` + groupSelected);
    else
      return this.http.get(`${this.baseUrl}/unitOfMeasurement`);
  }


  createUnitOfMeasurement(addUnitOfMeasurementForm: any) {

    let body = JSON.stringify(addUnitOfMeasurementForm);
    let url = this.baseUrl + '/unitOfMeasurement';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);
  }
  deleteUnitofMeasurement(id): Observable<any> {

    return this.http.delete(`${this.baseUrl}/unitOfMeasurement/` + id, { responseType: 'text' });
  }


  //Rating Group
  createRatingGroup(ratingGroupform: any) {
    let url = this.baseUrl + '/ratingGroup';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };
    return this.http.post(url, ratingGroupform, httpOptions);
  }
  getListOfRatingGroups(): Observable<any> {
    return this.http.get(`${this.baseUrl}/ratingGroup`);
  }
  createRatingGroupValue(ratingGroupValueform: any) {
    let body = JSON.stringify(ratingGroupValueform);
    let url = this.baseUrl + '/ratingValue';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post(url, body, httpOptions);
  }
  deleteRatingGroup(id): Observable<any> {
    let url = this.baseUrl + '/ratingGroupDelete/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };
    return this.http.post(url, null, httpOptions);
  }
  deleteRatingGroupValue(id): Observable<any> {
    let url = this.baseUrl + '/ratingValueDelete/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };
    return this.http.post(url, null, httpOptions);
  }
  //Datapoint
  createDataPoint(dataPointForm: any): Observable<any> {
    let body = JSON.stringify(dataPointForm);
    let url = this.baseUrl + '/createOrUpdateDatapoints';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post(url, body, httpOptions);
  }
  getListOfDataPoints(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getDataPoints`);
  }

  deleteDataPoint(dataPointId: number): Observable<any> {
    let url = this.baseUrl + '/deleteDatapoint/' + dataPointId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };
    return this.http.post(url, null, httpOptions);
  }


  //IndicatorDatapoint Mapping

  tagIndicatorToDataPoints(indicatorDataPointForm: any): Observable<any> {
    let body = JSON.stringify(indicatorDataPointForm);
    let url = this.baseUrl + '/indicatorDatapointMapping';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.post(url, body, httpOptions);
  }
  getTaggedDataPointsForIndicator(): Observable<any> {
    return this.http.get(`${this.baseUrl}/indicatorDatapointMapping`);
  }


  createUnitGroup(addUnitGroupForm: any) {

    let body = JSON.stringify(addUnitGroupForm);
    let url = this.baseUrl + '/unitGroup';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);
  }

  getunitGroupList(): Observable<any> {
    return this.http.get(`${this.baseUrl}/unitGroup`);
  }

  deleteUnitGroup(id): Observable<any> {

    let url = this.baseUrl + '/unitGroup/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };
    return this.http.post(url, null, httpOptions);
  }

  //Indicator keywords

  getListOfAllIndicators(): Observable<any> {
    return this.http.get(`${this.baseUrl}/indicator`);
  }

  fetchIndicatorKeywords(): Observable<any> {
    return this.http.get(`${this.baseUrl}/indicatorKeywords`);
  }

  createIndicatorKeyword(indicatorKeywordList: any) {
    let body = JSON.stringify(indicatorKeywordList);
    let url = this.baseUrl + '/indicatorKeywords';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };
    return this.http.post(url, body, httpOptions);
  }

  checkSdgNameValidation(id, sdgName): Observable<any> {
    let url = this.baseUrl + '/sdg/validateName/' + id + '/' + sdgName;
    return this.http.get(url);
  }

  checkRatingGrpNameValidation(id, ratingGrpName): Observable<any> {
    let url = this.baseUrl + '/ratingGroup/validateName/' + id + '/' + ratingGrpName;
    return this.http.get(url);
  }

  checkUnitGrpCodeValidation(id, unitGrpCode): Observable<any> {
    let url = this.baseUrl + '/unitGroup/validateCode/' + id + '/' + unitGrpCode;
    return this.http.get(url);
  }

  checkUnitGrpNameValidation(id, unitGrpName): Observable<any> {
    let url = this.baseUrl + '/unitGroup/validateName/' + id + '/' + encodeURI(unitGrpName);
    return this.http.get(url);
  }

  checkUnitOfMeasurementCodeValidation(id, unitOfMeasurementCode): Observable<any> {
    let url = this.baseUrl + '/unitOfMeasurement/validateCode/' + id + '/' + unitOfMeasurementCode;
    return this.http.get(url);
  }

  checkUnitOfMeasurementNameValidation(id, unitOfMeasurementCode): Observable<any> {
    let url = this.baseUrl + '/unitOfMeasurement/validateName/' + id + '/' + unitOfMeasurementCode;
    return this.http.get(url);

  }
  checkDatapointNameValidation(id, datapointName): Observable<any> {
    let url = this.baseUrl + '/datapoint/validateName/' + id + '/' + datapointName;
    return this.http.get(url);

  }
  checkSubcategoryCodeValidation(id, subCategoryCode): Observable<any> {
    let url = this.baseUrl + '/subcategory/validateCode/' + id + '/' + subCategoryCode;
    return this.http.get(url);

  }
  checkSubcategoryNameValidation(id, subCategoryName): Observable<any> {
    let url = this.baseUrl + '/subcategory/validateName/' + id + '/' + subCategoryName;
    return this.http.get(url);

  }
  checkIndicatorStackCodeValidation(id, subCategoryCode): Observable<any> {
    let url = this.baseUrl + '/stack/validateCode/' + id + '/' + subCategoryCode;
    return this.http.get(url);

  }
  checkIndicatorStackNameValidation(id, subCategoryName): Observable<any> {
    let url = this.baseUrl + '/stack/validateName/' + id + '/' + subCategoryName;
    return this.http.get(url);

  }
  checkUniqueConstantValidation(id, constantType, code, value): Observable<any> {
    let url = this.baseUrl + '/constants/validateConstants/' + id + '/' + constantType + '/' + code + '/' + value;
    return this.http.get(url);
  }
  
    checkSdgCodeValidation(id, sdgCode): Observable<any> {
    let url = this.baseUrl + '/sdg/validateCode/' + id + '/' + sdgCode;
    return this.http.get(url);
  }

}
