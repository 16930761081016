import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ImpactAssessmentService } from '../../../impact-assessment.service';
import {ReportConstantsComponent} from '../../../../report-constants/report-constants.component'

@Component({
  selector: 'app-sdg-view',
  templateUrl: './sdg-view.component.html',
  styleUrls: ['./sdg-view.component.css']
})
export class SdgViewComponent implements OnInit {

  sdgList:any;
  sliceList:any;
  loading:boolean;
  public locale:string;
  
  constructor(private _impactAssessmentService:ImpactAssessmentService,  private _router: Router) { 
    this.loading = false;
  }

  ngOnInit() {
    this.locale =ReportConstantsComponent.LOCALE;
    this.loading = true;
    this._impactAssessmentService.getSDGList().subscribe((sdgDetails)=>{
      this.sdgList = sdgDetails;
      this.loading = false;
    });
    this.sliceList=[0,6,12];
  }

  getSDGDrillDownDetails(sdgId:number){
    let sdgVO = {};
    sdgVO['sdgId'] = sdgId;
    sdgVO['subCategoryId'] = 0;   // takes both impact and performance mgmt indicators
    this._router.navigate(['/impactAssessment/dashboard/sdgViewDrillDown'], { queryParams: sdgVO , skipLocationChange: true});
  }
    isNumber (val){return isNaN(+val);}
}
