import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AddRatingAgencyComponent } from '../add-rating-agency/add-rating-agency.component';
import swal from 'sweetalert2';
import { ConfigurationService } from '../../configuration.service';

@Component({
  selector: 'app-list-rating-agency',
  templateUrl: './list-rating-agency.component.html',
  styleUrls: ['./list-rating-agency.component.css']
})
export class ListRatingAgencyComponent implements OnInit {
 ratingAgencyList: any[]
  jsonArray: any[]
  displayedColumns: any;
  originalColumnNames: any;
  top: any;

  @Input() set topWrapper(val) {
    this.top = val;
  }
  @Input() set ratingAgencyDataList(val) {
    this.ratingAgencyList = val;
    this.createJsonArray(this.ratingAgencyList)
  }

  @Output() addRatingAgencyListener = new EventEmitter<any>();

  get ratingAgencyDataList() {
    return this.ratingAgencyList;
  }

  constructor(private configurationService: ConfigurationService, private addRatingAgencyComponent: AddRatingAgencyComponent) { }

  ngOnInit() {
    this.createJsonArray(this.ratingAgencyList);

  }
  createJsonArray(ratingAgencyList) {
    this.displayedColumns = [];
    this.originalColumnNames = [];
    this.jsonArray = [];
    var i = 0;

    for (let ratingAgency of ratingAgencyList) {
      var arrays = {}
      arrays['Sno'] = ++i;
      arrays['id'] = ratingAgency.id;
      arrays['ratingType'] = ratingAgency.ratingType;
      arrays['name'] = ratingAgency.name;
      arrays['filename']=ratingAgency.filename;
      arrays['editDelete'] = ratingAgency.editDelete;
      this.jsonArray.push(arrays);
    }
    this.displayedColumns = ['Sno', 'ratingType', 'name','filename', 'Actions']
    this.originalColumnNames = ['Sno', 'Rating Type', 'Name','File Name', 'Actions']


  }
  
  downloadImage(ratingAgency)
  {
    let fileName =ratingAgency.filename;
    window.location.href =this.configurationService.downloadFile() +"/downloadRatingAgencyFile?filename=" +
      encodeURIComponent(fileName) +
      "&actualName=" +
      encodeURIComponent(ratingAgency.filename);
  }

  editRatingAgency(ratingAgency) {
console.log()
    var arrays = { id: ratingAgency.id, ratingType: ratingAgency.ratingType, name: ratingAgency.name,filename:ratingAgency.filename }
    this.addRatingAgencyListener.emit(arrays);
  }

  deleteRatingAgency(ratingAgency) {

    var arrays = {
      id: ratingAgency.id,
      ratingType: ratingAgency.ratingType,
      name: ratingAgency.name,
      filename:ratingAgency.filename
    }
    swal({
      title: '<p class="swal2-title-warning">Are You Sure?</p>',
      text: "You Want To Delete Rating Agency",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'



    })
    .then((isConfirm) => {
      if (isConfirm.value) {
        this.configurationService.deleteRatingAgency(ratingAgency.id).subscribe((data) => {
          swal({
            title: 'Success!',
            text: 'Deleted Rating Agency',
            type: 'success',
            timer: 2000,
            confirmButtonText: 'Ok'
          }).then((value) => {
            this.addRatingAgencyComponent.getRatingAgencyList();
          });

        }, (error) => {
          console.log(error);
        });
      }

    });
  }

}
