import { Component, OnInit, ViewChild } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { CustomValidationsService } from '../../../../shared/Validations/custom-validations.service';
import swal from 'sweetalert2';
import { IndicatorMasterService } from '../../indicator-master.service';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-add-constants',
  templateUrl: './add-constants.component.html',
  styleUrls: ['./add-constants.component.css']
})
export class AddConstantsComponent implements OnInit {
  uniqueConstantType: any;
  constants: any;
  constantsList: any;
  addConstantForm: FormGroup;
  constantType: String
  constantValue: String;
  constantCode: String;
  constantSelected: number;
  topWrapper: any;

  @ViewChild("topWrap") topWrap;
  @ViewChild("constantForm") constantForm;
  constructor(private constantService: IndicatorMasterService, private formBuilder: FormBuilder, private customValidationsService: CustomValidationsService, private addEditSuccessComponent: AddEditSuccessComponent) {
    this.addConstantForm = this.createFormGroup();
  }

  ngOnInit() {
    this.topWrapper = this.topWrap;
  }

  getConstantList(editId) {
    if (this.constantSelected === undefined) {
      this.constantService.getConstantsList().subscribe(data => {
        this.constants = data;
        this.uniqueConstantType = this.getUniqueValues(data, "constantType");
      });
    }
    else {
      this.constantService.getConstantsListByConstantType(this.constantSelected).subscribe(data => {
      });
    }
  }

  getUniqueValues(array, key) {
    var result = new Set();
    array.forEach(function (item) {
      if (item.hasOwnProperty(key)) {
        result.add(item[key]);
      }
    });
    return result;
  }

  defaultValues(p) {
    // setting default values for the form
    this.getConstantList(p.id);
    this.addConstantForm.patchValue({
      id: p.id,
      constantType: p.constantType,
      value: p.value,
      code: p.code,
    });
  }

  onSubmit() {
    this.constantSelected = this.addConstantForm.get('constantType').value;
    var message;


    if (this.addConstantForm.valid && this.constantSelected != 0 && this.constantSelected != null) {

      var id = 0;

      if (this.addConstantForm && this.addConstantForm.controls.id.value) {
        id = this.addConstantForm.controls.id.value;
      }
      // this.constantService.checkUniqueConstantValidation(id, this.addConstantForm.controls.constantType.value, this.addConstantForm.controls.code.value, this.addConstantForm.controls.value.value).subscribe(val => {


      //   if (val == 1) {
      //     message = "Same code and value already exists for this constant type";
      //     var title = "Failure!";
      //     this.addEditSuccessComponent.showFailedSwal(message, title);

      //   }
      //   else {
          this.getConstantList(0);

          this.constantService.createConstant(this.addConstantForm.getRawValue()).subscribe((statusFlag) => {
            message = "Successfully Added Constant Value";
            if (this.addConstantForm.get('id').value != 0) {
              message = "Successfully Edited Constant Value"
            }
            this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
              this.resetConstantForm();
            });
          }, (error) => {
            console.log(error);
          });

        //}

     // });

    }






  }




  //   uniqueConstantCodeAndValueCheck():Observable<any> {
  //     var id = 0;

  //     if (this.addConstantForm && this.addConstantForm.controls.id.value) {
  //       id = this.addConstantForm.controls.id.value;
  //     }
  //   return  this.constantService.checkUniqueConstantValidation(id, this.addConstantForm.controls.constantType.value,this.addConstantForm.controls.code.value,this.addConstantForm.controls.value.value).pipe(
  //         map(val => {

  //          return val;

  //    }));

  // }

  //  }
  resetConstantForm() {
    //Reset to the original-- even if after edit make it as a new entry
    this.constantForm.resetForm();
    this.topWrap.nativeElement.classList.remove("top-form-wrapper-highlight");
  }


  createFormGroup() {
    this.getConstantList(0);
    return this.formBuilder.group({
      id: [0],
      code: ['', [Validators.required, Validators.maxLength(50), this.customValidationsService.noWhitespaceValidator]],
      value: ['', [Validators.required, Validators.maxLength(1000), this.customValidationsService.noWhitespaceValidator]],
      constantType: ['', [Validators.required]],
    });
  }
  constantTypeChange() {
    this.constantSelected = this.addConstantForm.get('constantType').value;
  }


}
