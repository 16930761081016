import { Component, OnInit } from '@angular/core';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { Router } from '@angular/router';
//import { AwsAmplifyService } from '../../shared/aws-amplify.service';
Amplify.configure({

  Auth:{
  
  mandatorySignIn:true,
  
  region: 'us-east-1',
  
  userPoolId: 'us-east-1_eNPqKstgB',
  
  userPoolWebClientId:'15fln0eoof7savtndng6fqlpoq',
  
  authenticationFlowType:'USER_PASSWORD_AUTH'
  
  }
  }); 
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

 // constructor(private awsAmplifyService : AwsAmplifyService) { }
 public username="test";
 public password="password";
  show: boolean= false;
  constructor(private router: Router) { }

  ngOnInit() {
    
  }
  showSignIn(){
    this.show=false;
  }
  showSignUp(){
    this.show=true;
  }
  async  signUp() {
      try {
        var username=(<HTMLInputElement>document.getElementById("signusername")).value;
        var password=(<HTMLInputElement>document.getElementById("signpassword")).value;
        var email=(<HTMLInputElement>document.getElementById("email")).value;
          const { user } = await Auth.signUp({
              username ,
              password,
              attributes: {
                email         // optional
              //  phone_number,   // optional - E.164 number convention
                // other custom attributes 
            }
          });
          console.log(user);
      } catch (error) {
          console.log('error signing up:', error);
      }
  }
async  signIn() {
  try {
    this.username=(<HTMLInputElement>document.getElementById("username")).value;
    this.password=(<HTMLInputElement>document.getElementById("password")).value;
    console.log("username")
    console.log(this.username)
    console.log(this.password)
      const user = await Auth.signIn(this.username, this.password);
      console.log(user)
      if(user){
        this.router.navigate(['/performanceManagement/dashboard/parameters']);
      }
  } catch (error) {
      console.log('error signing in', error);
  }
}


async  signOut() {
  try {
      await Auth.signOut({ global: true });
  } catch (error) {
      console.log('error signing out: ', error);
  }
}

// login(){
//   Auth.signIn(this.username,this.password).then(user => {
//     if(user){
//       alert("hii")
//     }
//   })
// }

}
