import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, AbstractControl, FormGroup, FormControl } from '@angular/forms';
import { PerformanceFrameworkCompanyService } from '../performance-framework-company.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Router } from '@angular/router';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
@Component({
  selector: 'app-list-company-framework',
  templateUrl: './list-company-framework.component.html',
  styleUrls: ['./list-company-framework.component.css']
})
export class ListCompanyFrameworkComponent implements OnInit {

  checkBoxSelectionForm: FormGroup;
  dataSource: any=[];
  displayedColumns: any[];
  listCompanyVO: any;
  listCompanyType: any;
  companiesList: any = [];
  listCompanyTypeVO: any;
  searchCompanyForm: any;
  paginator:any;
  checkboxSelectedFlag:boolean =false;
    @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }  
  public loading = false;
  constructor(private router:Router,private companyFrameworkService: PerformanceFrameworkCompanyService, private formBuilder: FormBuilder,
              private addEditSuccessComponent:AddEditSuccessComponent) {

   this.searchCompanyForm = this.createFormGroup();
   this.checkBoxSelectionForm = this.createCheckBoxFormGroup();
  }

  ngOnInit() {
    this.getAllCompanyTypes();
    this.getCompanyList();
  }
  createFormGroup() {
    return this.formBuilder.group({
      companyTypeVO: this.formBuilder.group({
        id: [],
      }),
      companyName: [],
    });
  }

  createCheckBoxFormGroup() {
    return this.formBuilder.group({
      id: [],
    }); 
  }
  getAllCompanyTypes() {
    let parameterRequestVO;
    parameterRequestVO={
      'companyTypeList':true
    };

    this.companyFrameworkService.getAllCompanyTypes(parameterRequestVO).subscribe(data => {
           this.listCompanyTypeVO= data.companyTypeVOList;
    }, (error) => {
      console.log(error);
    });

  }

  getCompanyList() {
  
    this.companyFrameworkService.getAllCompanies().subscribe(data => {
      this.dataTablePopulation(data);
}, (error) => {
 console.log(error);
});
  }

  dataTablePopulation(companiesList) {
    this.displayedColumns = [];
    var jsonArray = [];
    this.dataSource = [];

    for (let i of companiesList) {
      var newArray = {};
      let frameworkId: any = 0;
      let frameworkStatus:string='';

      if (i.performanceframeworkVO != null) {
        frameworkId = i.performanceframeworkVO.frameworkId;
        frameworkStatus = i.performanceframeworkVO.frameworkStatus;

        var abstractControl: AbstractControl = this.checkBoxSelectionForm;
        if (abstractControl instanceof FormGroup) {
          (<FormGroup>abstractControl).addControl(frameworkId, new FormControl({ value: '', disabled: true }));
        }

        if (frameworkStatus === "A") {

          this.checkBoxSelectionForm.controls[frameworkId].enable();
          newArray['nonEditable'] = false;
        }
        else if (frameworkStatus === "D") {

          this.checkBoxSelectionForm.controls[frameworkId].disable();
          newArray['nonEditable'] = false;
        }

        else {

          newArray['nonEditable'] = true;

          this.checkBoxSelectionForm.controls[frameworkId].disable();
        }
       
      

      }
      else {
        var abstractControl: AbstractControl = this.checkBoxSelectionForm;
        if (abstractControl instanceof FormGroup) {
          (<FormGroup>abstractControl).addControl(frameworkId, new FormControl({ value: '', disabled: true }));
        }
        newArray['nonEditable'] = true;

        this.checkBoxSelectionForm.controls[frameworkId].disable();
      }

      newArray['id'] = i.id;
      newArray['checkBoxSelection'] = false;
      newArray['code'] = i.companyCode;
      newArray['name'] = i.companyName;
      newArray['period'] = (i.startDate && i.endDate)?(i.startDate+" To "+ i.endDate):"-";
      newArray['companyTypeId'] = i.companyTypeVO.id;
      newArray['companyTypeName'] =  i.companyTypeVO.name;;
      newArray['frameworkId'] = frameworkId;
      newArray['frameworkStatus'] = frameworkStatus;
      jsonArray.push(newArray);
    }
    this.loading=false; 
    this.displayedColumns = ['checkBoxSelection','code', 'name', 'actions']
    this.dataSource =[];
    this.dataSource = new MatTableDataSource(jsonArray);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }
  onSearch() {
    
    if (this.searchCompanyForm.value.companyName != null && this.searchCompanyForm.value.companyTypeVO.id != null ||
      this.searchCompanyForm.value.companyName == null && this.searchCompanyForm.value.companyTypeVO.id != null ||
      this.searchCompanyForm.value.companyName != null && this.searchCompanyForm.value.companyTypeVO.id == null) {

      this.companyFrameworkService.getCompaniesForSearch(this.searchCompanyForm.value).subscribe(data => {

        this.dataTablePopulation(data);
      }, (error) => {
        console.log(error);
      })
    }
   
  }
  reset() {
    if (this.searchCompanyForm.value.companyName != null && this.searchCompanyForm.value.companyTypeVO.id != null ||
      this.searchCompanyForm.value.companyName == null && this.searchCompanyForm.value.companyTypeVO.id != null ||
      this.searchCompanyForm.value.companyName != null && this.searchCompanyForm.value.companyTypeVO.id == null) {
      this.loading = true;
      this.getAllCompanyTypes();
      this.getCompanyList();
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  
  }
    addFrameworkIndicatorConfiguration(companyDetails){
      
       this.router.navigate(['/addCompanyIndicatorsComponent'],{queryParams:companyDetails, skipLocationChange: true});
    }
  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  } 

  setButtonStatusOnCheckboxSelection()
  {
     let checkboxSelectedValues = this.checkBoxSelectionForm.value;
     this.checkboxSelectedFlag = false;
    for (var i in checkboxSelectedValues) {
      if (checkboxSelectedValues[i] == true)
        this.checkboxSelectedFlag = true;
    }
  }

   onSubmit() {
    var selectedFrameworksIds: any = [];
    let checkboxSelectedValues = this.checkBoxSelectionForm.value;
    for (var i in checkboxSelectedValues) {
      if (checkboxSelectedValues[i] == true)
        selectedFrameworksIds.push(i);
    }

    this.companyFrameworkService.archiveSelectedFrameworks(selectedFrameworksIds).subscribe(data => {
      let message = "Successfully Archived Selected Frameworks";
       this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
         this.checkBoxSelectionForm.reset();
         this.checkboxSelectedFlag =false;
          this.getCompanyList();
       });
    });
   }   

   redirectToViewFrameworkPage(companyDetails)
   {
       let frameworkDetails = {};
       frameworkDetails['frameworkId'] = companyDetails.frameworkId;
       frameworkDetails['redirectFromArchievePage'] =false;
      frameworkDetails['redirectFromCopyCompanyPage'] =false;
            frameworkDetails['companyId'] = companyDetails.id;

       this.router.navigate(['/viewCompanyFramework'], { queryParams: frameworkDetails, skipLocationChange: true });

   }

  }


