import { Injectable } from '@angular/core';
import {Http, Response , Headers, RequestOptions} from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../../shared/base-url.service';

@Injectable()
export class PeerCompanyService {

  private baseUrl = BaseUrlService.REPORTBASEURL;
  
  private headers = new Headers({'Content-type':'application/json',
  'Authorization': `Bearer ${sessionStorage.getItem('token')}`
});
  private options = new RequestOptions({headers:this.headers});
  private peerCompany:any;

  constructor(private _http:Http) { }

  getPeerCompanyList(){
    return this._http.get(this.baseUrl+'/listPeerGroups/',this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  getPeerCompanyDetails(id:number){
    return this._http.get(this.baseUrl+'/peerCompanyDetails/'+id,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  getPeerCompanyIndicatorList(peerId:number){
    return this._http.get(this.baseUrl+'/peerCompanyIndicatorList/'+peerId,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  getPeerCompanySelectedIndicators(peerId:number,esgType:String,indList:any,chartType:any){
    return this._http.get(this.baseUrl+'/peerCompanySelectedIndicators/'+peerId+'/'+esgType+'/'+indList+'/'+chartType,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }
  errorHandler(error:Response){
    console.log("Error");
    return Observable.throw(error || "SERVER_ERROR");
  }

  setter(peerCompany:any){
    this.peerCompany = peerCompany;
  }

  getter(){
    return this.peerCompany;
  }
}
