import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, NgForm, AbstractControl, ValidationErrors } from '@angular/forms';
import { CategoryKeywordService } from '../category-keyword.service';

import swal from 'sweetalert2';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { CustomValidationsService } from '../../../shared/Validations/custom-validations.service';

@Component({
  selector: 'app-add-category-keyword',
  templateUrl: './add-category-keyword.component.html',
  styleUrls: ['./add-category-keyword.component.css']
})
export class AddCategoryKeywordComponent implements OnInit {
  addCategoryKeywordForm: FormGroup;
  categoryId: any = 0;
  @ViewChild("categoryForm") categoryForm;
  @ViewChild("topWrap") topWrap;
  topWrapper: any;
  categoryKeywordList: any = [];
  constructor(private formBuilder: FormBuilder, private categoryKeywordService: CategoryKeywordService, private customValidationsService: CustomValidationsService ) { this.createCategoryKeywordForm(); }

  ngOnInit() {
    this.topWrapper = this.topWrap;
    this.getCategoryKeywordList();
  }

  createCategoryKeywordForm() {
    this.addCategoryKeywordForm = this.formBuilder.group({
      id: [0],
        categoryName: ['', [Validators.required,this.customValidationsService.noWhitespaceValidator], this.UniqueCategoryNameValidator()],
      categoryKeywordVOList: this.formBuilder.array([this.getCategoryKeywordVO()]),
    }, { updateOn: 'blur' })
  }

  getCategoryKeywordVO() {
    return this.formBuilder.group({
      id:[0],
      keyword: ["", [Validators.required, this.customValidationsService.noWhitespaceValidator]]
    });
  }
  addNewKeywordRow() {
    const controls = this.formKeywordArray;
    this.formKeywordArray.push(this.getCategoryKeywordVO());
  }
  get formKeywordArray() {
    return this.addCategoryKeywordForm.get("categoryKeywordVOList") as FormArray;
  }
 
  deleteKeywordRow(index: number,keyword:any) {
    
    this.formKeywordArray.removeAt(index);

    if (this.formKeywordArray.length == 0)
      this.addNewKeywordRow();
   
    if (keyword.value.id != 0) {
      this.categoryKeywordService
        .deleteCategoryKeyword(keyword.value.id)
        .subscribe(event => {
          swal({
            title: "Success!",
            text: "Deleted keyword",
            timer: 2000,
            type: "success",
            confirmButtonText: "Ok"
          });
        });
    }
    this.getCategoryKeywordList();
  }
  onSubmit() {
    if (this.addCategoryKeywordForm.valid) {
      console.log(this.addCategoryKeywordForm);
      this.categoryKeywordService.createCategoryKeyword(this.addCategoryKeywordForm.value).subscribe(
        data => {
          console.log(data);
          if (this.addCategoryKeywordForm.get("id").value != null)
            var message = "Successfully Edited Category ";
          else var message = "Successfully Added Category";
          swal({
            title: "Success!",
            text: message,
            type: "success",
            timer: 2000,
            confirmButtonText: "Ok"
          }).then(value => {
            this.getCategoryKeywordList();
            this.resetCategoryForm();

          });
        },
        error => {
          console.log(error);
        }
      );
    }
  }
  resetCategoryForm() {
    this.categoryForm.resetForm();
    this.addCategoryKeywordForm.removeControl("categoryKeywordVOList");
    this.addCategoryKeywordForm.addControl(
      "categoryKeywordVOList",
      this.formBuilder.array([])
    );
    this.addNewKeywordRow();
    if (this.topWrapper && this.topWrapper.nativeElement)
      this.topWrapper.nativeElement.classList.remove("top-form-wrapper-highlight");
    this.categoryId = 0;
  }

  getCategoryKeywordList() {
    this.categoryKeywordService.getcategoryKeywordList().subscribe(data => {
      this.categoryKeywordList = data;
    })
  }

  setCategoryKeywordValues(categoryKeyword) {
    this.categoryId = categoryKeyword.id;
    this.addCategoryKeywordForm.patchValue({
      id: categoryKeyword.id,
      categoryName:categoryKeyword.categoryName
    });
    console.log(categoryKeyword.categoryKeywordVOList);
    this.addCategoryKeywordForm.removeControl("categoryKeywordVOList");
    this.addCategoryKeywordForm.addControl(
      "categoryKeywordVOList",
      this.formBuilder.array([])
    );
    console.log(categoryKeyword.categoryKeywordVOList);
    categoryKeyword.categoryKeywordVOList.forEach(parameter => {
      this.addNewKeywordRow();
    });
    if (categoryKeyword.categoryKeywordVOList.length == 0) this.addNewKeywordRow();
    this.formKeywordArray.patchValue(categoryKeyword.categoryKeywordVOList);
  }

  UniqueCategoryNameValidator() {

    return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      var id = 0
      if (this.addCategoryKeywordForm && this.addCategoryKeywordForm.controls.id.value) {
        id = this.addCategoryKeywordForm.controls.id.value;
      }
      return this.categoryKeywordService.checkCategoryNameValidation(id, c.value).pipe(
        map(data => {
          if (data != null) {
            if (data.length > 0) {
              return { 'uniqueName': true };
            }
          }
        })
      );


    };


  }
}
