import { Component, OnInit, ViewChild } from '@angular/core';
import { IndicatorMasterService } from '../../../indicator-master/indicator-master.service';
import { CustomValidationsService } from '../../../../shared/Validations/custom-validations.service';
import { FormBuilder, FormGroup, Validators, NgForm, AbstractControl, AbstractControlOptions, ValidationErrors, FormGroupDirective } from '@angular/forms';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-add-unit-of-measurement',
  templateUrl: './add-unit-of-measurement.component.html',
  styleUrls: ['./add-unit-of-measurement.component.css']
})
export class AddUnitOfMeasurementComponent implements OnInit {

  addUnitOfMeasurementForm: FormGroup;
  defaultValuesArray: any;
  unitGroups: any;
  groupSelected: number;
  topWrapper: any;

  @ViewChild("topWrap") topWrap;
  @ViewChild("unitofMeasurementForm") unitofMeasurementForm;

  constructor(private addEditSuccessComponent: AddEditSuccessComponent, private indicatorMasterService: IndicatorMasterService, private formBuilder: FormBuilder, private customValidationsService: CustomValidationsService) {

    this.addUnitOfMeasurementForm = this.createFormGroup();

  }
  ngOnInit() {
    this.getUnitGroups();
    this.topWrapper = this.topWrap;
  }
  createFormGroup() {

    return this.formBuilder.group({

      code: ['', [Validators.required, Validators.maxLength(50), this.customValidationsService.noWhitespaceValidator], this.uniqueCodeValidator()],
      name: ['', [Validators.required, Validators.maxLength(1000), this.customValidationsService.noWhitespaceValidator]],
      id: [],
      unitGroupVO: this.formBuilder.group({
        code: [],
        name: [],
        id: ['', Validators.compose([Validators.required])],
      })

    }, { updateOn: 'blur' });

  }

   uniqueCodeValidator()
  {
      return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      var id = 0
      if (this.addUnitOfMeasurementForm && this.addUnitOfMeasurementForm.controls.id.value) {
        id = this.addUnitOfMeasurementForm.controls.id.value;
      }
      
      return this.indicatorMasterService.checkUnitOfMeasurementCodeValidation(id, c.value).pipe(
        map(val => {

          console.log(val)
          if (val == 1) {
            return { 'uniqueCode': true };
          }
        })
      );
    };

  }

  uniqueNameValidator()
  {
    return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      var id = 0
      if (this.addUnitOfMeasurementForm && this.addUnitOfMeasurementForm.controls.id.value) {
        id = this.addUnitOfMeasurementForm.controls.id.value;
      }
      
      return this.indicatorMasterService.checkUnitOfMeasurementNameValidation(id, c.value).pipe(
        map(val => {

          console.log(val)
          if (val == 1) {
            return { 'uniqueName': true };
          }
        })
      );
    };


  }

  getUnitGroups() {
    this.indicatorMasterService.getunitGroupList().subscribe(data => {
      this.unitGroups = data;
    }, (error) => {
      console.log(error);
    });
  }
  unitGroupChange() {

    this.groupSelected = this.addUnitOfMeasurementForm.controls.unitGroupVO.get('id').value;
  }
  onSubmit() {
    if (this.addUnitOfMeasurementForm.valid) {

      this.indicatorMasterService.createUnitOfMeasurement(this.addUnitOfMeasurementForm.value).subscribe((myform) => {
        if (this.addUnitOfMeasurementForm.get('id').value != null)
          var message = "Successfully Edited Unit of Measurement";
        else
          var message = "Successfully Added Unit of Measurement";
        this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
          this.resetUnitOfMeasurementForm();
        });


      }, (error) => {
        console.log(error);
      });
    }

  }
  resetUnitOfMeasurementForm() {

    //Reset to the original-- even if after edit make it as a new entry
    this.unitofMeasurementForm.resetForm();
    if(this.topWrap && this.topWrap.nativeElement){
      this.topWrap.nativeElement.classList.remove("top-form-wrapper-highlight");
    }

  }
  defaultValues(p) {
    this.defaultValuesArray = p;
    this.addUnitOfMeasurementForm.patchValue(p);

  }

}
