import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-like-minded-band-profile',
  templateUrl: './like-minded-band-profile.component.html',
  styleUrls: ['./like-minded-band-profile.component.css']
})
export class LikeMindedBandProfileComponent implements OnInit {

  public tabFlag=1;
  constructor(public _router : Router) { }

  ngOnInit() {
  }

  tabClick(flag){
    if(flag==1){
      this.tabFlag=1;
      document.getElementById('fund').classList.remove('investor-tab-active')
      document.getElementById('company').classList.add('investor-tab-active')
    }
    else{
      this.tabFlag=2;
      document.getElementById('company').classList.remove('investor-tab-active')
      document.getElementById('fund').classList.add('investor-tab-active')
    }
  }
  showCompanies(){
  
      this._router.navigate(['/companyReportDashboard']);
   
  }
  showfunds(){
    this._router.navigate(['/dashboard/fundDrilldown']);
  }


}
