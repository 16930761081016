import { Component, OnInit,Output, EventEmitter, Input, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { ParametersService } from '../../../ParameterDashboard/parameters.service';
import { FormControl,FormGroup,FormBuilder,Validators} from '@angular/forms';
import { ComplianceDashboardService} from '../../compliance-dashboard.service';
import {MatTableDataSource} from '@angular/material';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-company-compliance-dashboard',
  templateUrl: './company-compliance-dashboard.component.html',
  styleUrls: ['./company-compliance-dashboard.component.css']
})
export class CompanyComplianceDashboardComponent implements OnInit {

  regionData:any=[];
  countryData:any=[];
  sectorData:any;
  subSectorData:any=[];
  parameter_id:number;
  companyComplianceForm:FormGroup;
  displayedColumns:any;
  dashboardDataArrayInside:any = [];
  dataSource:any;
  data:any;
  periodData:any=[];
  regionSelectedIds:any = [];
  sectorSelectedIds:any = [];
  toggleList:boolean;
  toggleListRegion:boolean;
  toggleListCountry:boolean;
  toggleListSector:boolean;
  toggleListSubsector:boolean;
  isSubmit: boolean;
  loading :boolean;
  emptyFlag:boolean;
  selectReg = "Select All";
  selectCon = "Select All";
  selectSec = "Select All";
  selectSubsec = "Select All";
  selectPeriod="Overall";
  constructor(private formBuilder: FormBuilder, private complianceDashboardService:ComplianceDashboardService) { 

      this.loading = false;
      this.isSubmit = false;
      this.toggleList = false;
      this.toggleListRegion = false;
      this.toggleListCountry = false;
      this.toggleListSector = false;
      this.toggleListSubsector = false;
      this.emptyFlag = false;
      this.companyComplianceForm = this.formBuilder.group({
          regionIds:[],
          countryIds:[],
          sectorIds:[],
          subSectorIds:[],
          yearIds:['',Validators.required]
      })
  }

  ngOnInit() {
      
      this.complianceDashboardService.getCompanyComplianceFilters().subscribe(result=>{
        this.regionData = result['regionList'];
        this.sectorData = result['sectorList'];
        this.periodData = result['yearList'];
      })
      this.dashboardDataArrayInside= [];
  }

  fetchNextLevel(value)
  {
    this.companyComplianceForm.get("countryIds").reset();
    this.countryData= [];
  
      if(value==0 || value.length > 0){
        this.selectAllRegions();
        value = this.regionSelectedIds;
      }
      if(value.length > 0)
      {
        let childParameterLevelData:any=[];
        this.loading = true;
        this.complianceDashboardService.fetchParameterLevelList(value).subscribe((parameterLevel)=>{
            childParameterLevelData = parameterLevel;
            if(childParameterLevelData!=null){
              for( let parameterDetails of childParameterLevelData){
                  this.countryData.push({
                    "id":parameterDetails.id,
                    "name":parameterDetails.name
                  }) 
                }
            }
            this.loading = false;
          })
      
    }
  }

  fetchSubSectorLevel(value)
  {
      this.companyComplianceForm.get("subSectorIds").reset();
      this.subSectorData=[];
          if(value==0 || value.length > 0){
          this.selectAllSector();
          value = this.sectorSelectedIds;
        }

        if(value.length > 0){
          let childParameterLevelData:any=[];
          this.loading = true;
          this.complianceDashboardService.fetchParameterLevelList(value).subscribe((parameterLevel)=>{
              childParameterLevelData = parameterLevel;
              if(childParameterLevelData!=null){
                for( let parameterDetails of childParameterLevelData){
                    this.subSectorData.push({
                      "id":parameterDetails.id,
                      "name":parameterDetails.name
                    }) 
                }
              }
              this.loading = false;
            })
        }
      
  }
  
  reset(){
    this.dashboardDataArrayInside= []; 
    this.isSubmit=false;
    this.emptyFlag = true;
  }

  onSubmit() { 
    
    if( this.companyComplianceForm.valid)
    {
        this.isSubmit=true;
        this.loading = true;
        this.emptyFlag = false;
        this.complianceDashboardService.submitCompanyCompliance(this.companyComplianceForm.value).subscribe((data) => {
            this.dashboardDataArrayInside= []; 
            this.dashboardDataArrayInside=data;
            this.loading = false;
        });
    }
  
  }

  selectAll()
  {
    this.toggleList= !this.toggleList;
    let valueArray = [];
    if(this.toggleList)
    {
      for(let i of this.periodData)
      {
        valueArray.push(i);
      }
      this.selectPeriod="Deselect All";
    }
    else{
      this.selectPeriod="Select All";
    }
    this.companyComplianceForm.get("yearIds").patchValue(valueArray);
  }

  selectAllRegions()
  {
    this.toggleListRegion= !this.toggleListRegion;
    let regionArray = [];
    this.regionSelectedIds = [];
    if(this.toggleListRegion)
    {
      for(let i of this.regionData )
      {
        regionArray.push(i.id);
        this.regionSelectedIds.push(i.id);
      }
      this.selectReg="Deselect All";
    }
    else{
      this.selectReg="Select All";
    }
    this.companyComplianceForm.get("regionIds").patchValue(regionArray);
  }

  resetListView(){
    this.dashboardDataArrayInside= []; 
    this.isSubmit=false;
  }

  selectAllCountry()
  {
    this.toggleListCountry= !this.toggleListCountry;
    let countryArray = [];
    if(this.toggleListCountry)
    {
      for(let i of this.countryData )
      {
        countryArray.push(i.id);
      }
      this.selectCon="Deselect All";
    }
    else{
      this.selectCon="Select All";
    }
    this.companyComplianceForm.get("countryIds").patchValue(countryArray);
  }

  selectAllSector()
  {
    this.toggleListSector= !this.toggleListSector;
    let sectorArray = [];
    this.sectorSelectedIds = [];
    if(this.toggleListSector)
    {
      for(let i of this.sectorData )
      {
        sectorArray.push(i.id);
        this.sectorSelectedIds.push(i.id);
      }
      this.selectSec="Deselect All";
    }
    else{
      this.selectSec="Select All";
    }
    this.companyComplianceForm.get("sectorIds").patchValue(sectorArray);
  }

  selectAllSubSector()
  {
    this.toggleListSubsector= !this.toggleListSubsector;
    let subSectorArray = [];
    if(this.toggleListSubsector)
    {
      for(let i of this.subSectorData )
      {
        subSectorArray.push(i.id);
      }
      this.selectSubsec="Deselect All";
    }
    else{
      this.selectSubsec="Select All";
    }
    this.companyComplianceForm.get("subSectorIds").patchValue(subSectorArray);
  }


}
