import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { PerformanceRecordService } from "../performance-record.service";
import { BaseUrlService } from "../../../shared/base-url.service";
import {
  Component,
  OnInit,
  AfterViewInit,
  Inject,
  Output,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef
} from "@angular/core";

import {
  NgxGalleryOptions,
  NgxGalleryImage,
  NgxGalleryAnimation,
  NgxGalleryComponent
} from "ngx-gallery";
import { EventEmitter } from "events";
import { DataAcqCommonService } from "../../data-quality-master/data-acq-common.service";
import { ListDatasourceQualityLevelsComponent } from "../../data-quality-master/list-datasource-quality-levels/list-datasource-quality-levels.component";
import { FormBuilder, FormGroup } from "@angular/forms";
@Component({
  selector: "app-document-popup",
  templateUrl: "./document-popup.component.html",
  styleUrls: ["./document-popup.component.css"]
})
export class DocumentPopupComponent implements OnInit, AfterViewInit {
  ratingGroupValuesVOList: any;
  indicatorType: any;
  indicatorTextBoxForm: FormGroup;
  dataLevelsToLevelName: any;
  dataLevelsArray: any[];
  levelSelected: string;
  apportionedIndicatorValue: any;
  dataFromLevelId: any;
  dataLevel: any;
  indDpSchId: any;
  searchActualId: any;
  imgArray: any;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  galleryZoom: NgxGalleryAnimation[];
  imageArray = [];
  urlArray = [];
  companyCode: any;
  year: any;
  indicatorName: String;
  indicatorCode: string;
  fileList: any;
  serachResultList: any;
  searchString: any;
  flaskUrl = BaseUrlService.FLASKBASEURL;
  webSearch: boolean;
  loading = false;
  listText: boolean = false;
  textArray: any = [];
  dataSourceArray: any = [];
  textval: any;
  indicatorId: any;
  companyId: any;
  indicatorMatchesVO: any = [];
  dataSourcesMatchesVO:any = [];
  companyName: any;
  textArrayList: any = [];
  peerCompanyList: any = [];
  sisterCompanyList: any = [];
  currentCompany: any;
  offset: any;
  i: any = 0;
  indexArray:any=[];
  companyDetailsVO: any = [];
  fileNameList: any = [];
  fileUploadNameList: any = [];
  loadingImage:boolean;
  galleryImagesMap: {};
  fileDetails: any = [];
  q: any =0;
  public updateBtnStatus:any=true;
  public datasourceDetailsArray:any=[];
  public integration
  @ViewChildren("gallery") ngxgallery = new QueryList<NgxGalleryComponent>();
 
  constructor(
    private formBuilder: FormBuilder,
    private dataAcqCommonService: DataAcqCommonService,
    private performanceRecordService: PerformanceRecordService,
    private dialogRef: MatDialogRef<DocumentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  @Output() docComponent = new EventEmitter();

  ngOnInit() {
    this.galleryImagesMap={}
    this.dataLevelsArray = ["CL", "PL", "SSL", "PG"];
    this.dataLevelsToLevelName = {
      CL: "Party Level",
      PL: "Parent Level",
      SSL: "Alternate Data Source",
      PG: "Peer Group Level"
    };
    this.indicatorId = this.data.indicatorId;
    this.indicatorCode = this.data.indicatorCode;
    this.indicatorType = this.data.indicatorType;
    this.ratingGroupValuesVOList = this.data.ratingGroupValuesVOList;
    // this.companyId = this.data.selectedCompanyVO.id;
    //this.companyName = this.data.selectedCompanyVO.companyName;
    this.galleryImages=[]
    this.loadingImage=false;
    this.year = this.data.year;
    this.dataLevel = this.data.dataLevel;
    if (this.dataLevel != "CL") {
      this.indicatorTextBoxForm = this.createIndicatorTextBoxForm();
    }
    this.getLevelAndCompanyNameToDisplay();
    this.getDataLevelId();
    let LoadMore = false;
   //this.openText(LoadMore);
   this.openDatasources();
    this.galleryOptions = [
      {
        width: "453px",
        height: "400px",
        thumbnailsColumns: 5,
        imageAnimation: NgxGalleryAnimation.Slide,
        previewZoom: true,
        previewRotate: true
      },
      {
        breakpoint: 800,
        width: "100%",
        height: "600px",
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      {
        breakpoint: 400
      }
    ];
  }
  ngAfterViewInit() {
    console.log(`ngAfterViewInit - ngx gallery  is ${this.ngxgallery}`);
  }

  closePopup(): void {
    this.galleryImages=[]
    this.galleryImagesMap={}
    this.indexArray=[]
    document
      .getElementById("searchDoc" + this.data.index)
      .classList.remove("show-left-arrow");
    this.dialogRef.close();
    // document
    //   .getElementById(this.indDpSchId + this.data.index)
    //   .classList.remove("display-block");
    // document
    //   .getElementById(this.indDpSchId + this.data.index)
    //   .classList.remove("si-left-margin");

    // document
    //   .getElementById(this.indDpSchId + this.data.index)
    //   .classList.add("display-none");
  }
  openPdf(): void {
    this.integration=false;
    this.webSearch = true;
    this.listText = false;
    this.serachResultList = [];

    this.performanceRecordService
      .fetchFilenames(this.companyId, this.data.year)
      .subscribe(data => {
        this.companyDetailsVO = data;
        this.fileNameList = this.companyDetailsVO.fileNameList;
        this.fileUploadNameList = this.companyDetailsVO.fileUploadNameList;
        let i = 0;
        this.urlArray = [];
        if (this.fileNameList) {
          for (let file of this.fileNameList) {
            this.urlArray.push({
              name: file,
              url:
                this.flaskUrl +
                "/get_pdf?code=" +
                this.data.selectedCompanyVO.companyCode +
                "&year=" +
                this.year +
                "&name=" +
                this.fileUploadNameList[i]
            });
            i = i + 1;
          }
        }
      });
  }

  openText(loadMore) {
    console.log("open text")
    console.log(this.loading)
    this.integration=false;
    this.webSearch = true;
    this.listText = true;
    this.urlArray = [];
    this.serachResultList = [];
     let fileDetailsArray;
    let textArray;
    if(!loadMore){
    this.q=0;
    this.i=0;
    this.fileDetails=[];
    this.textArray=[];
  }
    loadMore ? (this.offset += 5) : (this.offset = 0);
    // if(!loadMore&&this.offset>0){
    //   this.offset -= 5;
    // }

    if (this.textArray.length == 0 || loadMore) {
      this.loading=true;
      this.performanceRecordService
        .fetchIndicatorMatches(
          this.indicatorId,
          this.year,
          this.companyId,
          this.offset
        )
        .subscribe(data => {
          
          this.loading=false;
          this.indicatorMatchesVO = data;
          console.log(this.indicatorMatchesVO);
          textArray = this.indicatorMatchesVO.matchingSentences;
          fileDetailsArray=this.indicatorMatchesVO.fileDetails;
          this.imgArray = this.indicatorMatchesVO.highlightedImages;
          fileDetailsArray.forEach(fileDetail => {
            this.fileDetails[this.q] = fileDetail;
            this.q = this.q + 1;
          });
          textArray.forEach(text => {
            let j = 0;
            let eachEntry = [];
            let textSplit = [];

            textSplit[j] = text.split(" ");
            eachEntry[0] = textSplit;
            this.textArray[this.i] = eachEntry;
            this.i = this.i + 1;
          });
          console.log(this.textArray);
          console.log(this.fileDetails)
          console.log(this.loading)
          // for (let image of this.imgArray) {
          //   let srcPreviewLink = {
          //     small: "data:image/png;base64," + image,
          //     medium: "data:image/png;base64," + image,
          //     big: "data:image/png;base64," + image
          //   };

          //   this.imageArray.push(srcPreviewLink);
          // }
          // this.galleryImages = this.imageArray;
           this.loading=false;
        });
    }
  }

  openPdfimageGallery() {
    this.webSearch = false;
    this.listText = false;
    this.integration=false;
  }
  openPdfWindow(url): void {
    window.open(url, "_blank");
  }
  loadCrawledData(): void {
    this.loading = true;
    this.urlArray = [];
    // searchenginesearch
    this.searchString =
      "https://duckduckgo.com/?q=" +
      this.data.indicatorName +
      "  " +
      this.data.selectedCompanyVO.companyName +
      " " +
      this.data.year;
    var requestObject = { url: this.searchString };
    var scrapyRequestVO = {
      spider_name: "searchengineIndicator",
      request: requestObject
    };

    //companyUrlsearch indicator
    // this.webSearch = true;
    // let companyUrl = this.data.selectedCompanyVO.companyUrlList[0];
    // // var metadata = {"indicator":this.data.indicatorName};
    // var metadata = {
    //   indicator: this.data.indicatorName,
    //   year: this.data.year,
    //   company_name: this.data.selectedCompanyVO.companyName
    // };

    // var requestObject = { url: companyUrl, meta: metadata };
    // var scrapyRequestVO = {
    //   spider_name: "indicatorsearchrest",
    //   request: requestObject
    // };
    this.performanceRecordService
      .getIndicatorCrawledData(scrapyRequestVO)
      .subscribe(
        data => {
          this.serachResultList = data.items;
          this.loading = false;
          this.webSearch = false;
          this.listText = false;
        },
        error => {
          this.loading = false;
        }
      );
  }

  populateValue($event, dataLevel) {
    let value = $event.target.innerHTML;
    if (dataLevel == "CL") {
      if (this.data.indicatorType == "QU") {
        value = parseFloat(value.replace(/,/g, ''));
      }
      this.data.scheduleVO.indicatorCalculationVO.dataFromLevelFlag = dataLevel;
      this.data.scheduleVO.actualValue = value;
      if (this.data.indicatorType == "QU" && !(isNaN(value))) {

        this.data.formgroup.patchValue({
          actualValue: value
        });
      }


    } else {
      if (this.data.indicatorType == "QU")
        this.indicatorTextBoxForm.controls.glValue.patchValue(value);
    }
  }
  // showHighlightedImage(index) {
  //   debugger;

  //   this.webSearch = false;
  //   this.listText = false;
  //   this.ngxgallery.toArray()[0].show(index);
  // }
  showHighlightedImage(index) {
    
    let isIndexPresent=false
    if(!this.indexArray.includes(index))
    {
      isIndexPresent=true;
      this.indexArray.push(index)
    }
    this.loadingImage=true;
    this.webSearch = false;
    this.listText = false;
    if(isIndexPresent){
     this.performanceRecordService.getEncodedHighlightedImage(this.indicatorMatchesVO.highlightedImages[index]).subscribe
    ({
      next:(data) =>{
        let srcPreviewLink={}
         srcPreviewLink = {
                small: "data:image/jpg;base64," + data[0],
                medium: "data:image/jpg;base64," + data[0],
                big: "data:image/jpg;base64," + data[0]
              };
              this.galleryImagesMap[index]=srcPreviewLink
              this.galleryImages.unshift(this.galleryImagesMap[index])
              console.log("if galleryImages");
              console.log(this.galleryImages);
              this.ngxgallery.toArray()[0].show(0);
              this.loadingImage=false;
      },
      error:(e) =>
      {
         console.log("error occured")
         this.loadingImage=false
      }
    });
  } 
  else 
  {
    const foundIdx = this.galleryImages.findIndex(el => el.small==this.galleryImagesMap[index].small)
    // this.galleryImages.unshift(this.galleryImages.splice(foundIdx, 1)[0]);
    // console.log("else galleryImages");
    // console.log(this.galleryImages);
    //this.galleryImages.splice(foundIdx,1)
    this.galleryImages.unshift(this.galleryImagesMap[index])
    //this.galleryImages.splice(foundIdx,1)
    this.ngxgallery.toArray()[0].show(0);
    this.loadingImage=false;
  }
  }
  apportionIndicatorValue() {
    //let glValue = this.indicatorTextBoxForm.controls.glValue.value;
   // if (this.dataLevel == "CL")
   //value.replace(/,/g, '')
  
     let glValue = this.indicatorTextBoxForm.controls.glValue.value.toString().replace(/[,;:\s]/g, '');

    this.data.scheduleVO.indicatorCalculationVO.dataFromLevelFlag = this.dataLevel;
    if (this.dataLevel == "PL") {
      this.data.scheduleVO.indicatorCalculationVO.parentCompanyVO.indicatorValueOtherSources = glValue;
      this.data.scheduleVO.indicatorCalculationVO.parentCompanyVO.sourceId = 1; //to be changed
    } else if (this.dataLevel == "SSL") {
      let sisterCompanyObj = this.data.scheduleVO.indicatorCalculationVO.sisterCompanyVOList.find(
        sisterCompanyObj => sisterCompanyObj.id == this.companyId
      );
      sisterCompanyObj.indicatorValueOtherSources = glValue;
      sisterCompanyObj.sourceId = 1; //to be changed
      if (this.data.indicatorType == "QR") {
        this.data.scheduleVO.indicatorCalculationVO.sisterCompanyVOList.forEach(
          sisterCompanyVO => {
            sisterCompanyVO.sourceId = 1;
            if (sisterCompanyVO.id != this.companyId) {
              sisterCompanyVO.indicatorValueOtherSources = null;
            }
          }
        );
      }
    } else if (this.dataLevel == "PG") {
      let peerCompanyObj = this.data.scheduleVO.indicatorCalculationVO.peerCompanyVOList.find(
        peerCompanyObj => peerCompanyObj.id == this.companyId
      );
      peerCompanyObj.indicatorValueOtherSources = glValue;
      peerCompanyObj.sourceId = 1; //to be changed
      if (this.data.indicatorType == "QR") {
        this.data.scheduleVO.indicatorCalculationVO.peerCompanyVOList.forEach(
          peerCompanyVO => {
            peerCompanyVO.sourceId = 1;
            if (peerCompanyVO.id != this.companyId) {
              peerCompanyVO.indicatorValueOtherSources = null;
            }
          }
        );
      }
    } else glValue = null;
    let indicatorCalculationVO = {
      companyId: this.data.selectedCompanyVO.id,
      year: this.year,
      indicatorCode: this.indicatorCode,
      groupLevelValue: glValue,
      sisterCompanyVOList: this.data.scheduleVO.indicatorCalculationVO
        .sisterCompanyVOList,
      peerCompanyVOList: this.data.scheduleVO.indicatorCalculationVO
        .peerCompanyVOList,
      dataFromLevelFlag: this.dataLevel,
      dataFromlevelId: this.data.scheduleVO.indicatorCalculationVO
        .dataFromlevelId,
      parentCompanyVO: this.data.scheduleVO.indicatorCalculationVO
        .parentCompanyVO, //to be checked
      sourceId: 1 //to be changed
    };

    this.performanceRecordService
      .getApprotionedIndicatorValue(indicatorCalculationVO)
      .subscribe(data => {
        this.apportionedIndicatorValue = data;
        if (
          this.data.indicatorType == "QR" ||
          this.data.indicatorType == "QU"
        ) {
          this.apportionedIndicatorValue = Number(
            this.apportionedIndicatorValue
          );
        }

        this.data.scheduleVO.actualValue = this.apportionedIndicatorValue;
        this.data.formgroup.patchValue({
          actualValue: this.apportionedIndicatorValue,
          indicatorCalculationVO: indicatorCalculationVO
        });
      });
  }
  getDataLevelId() {

    let indicatorRequestVO = {};
    indicatorRequestVO["isContantVOBasedOnType"] = true;
    indicatorRequestVO["constantCode"] = this.dataLevel;
    indicatorRequestVO["constantLabel"] = "DATA_LEVEL";
    this.dataAcqCommonService
      .getDataAcqMasterData(indicatorRequestVO)
      .subscribe(data => {
        this.dataFromLevelId = data.constantvo.id;
        this.data.scheduleVO.indicatorCalculationVO.dataFromlevelId = this.dataFromLevelId;
      });
    this.data.scheduleVO.indicatorCalculationVO.dataFromLevelFlag = this.dataLevel;

  }
  showNextLevel() {
    var index;
    var nextLevel;
    index = this.dataLevelsArray.indexOf(this.dataLevel) + 1;
    if (index == this.dataLevelsArray.length) index = 0;

    nextLevel = this.dataLevelsArray[index];
    this.dataLevel = nextLevel;
    if ((nextLevel == 'PL' && this.data.scheduleVO.indicatorCalculationVO.parentCompanyVO.id == 0) || (nextLevel == 'SSL' && this.data.scheduleVO.indicatorCalculationVO.sisterCompanyVOList.length == 0) || (nextLevel == 'PG' && this.data.scheduleVO.indicatorCalculationVO.peerCompanyVOList.length == 0)) {
      index += 1;
      this.showNextLevel();

    }

    if (nextLevel != "CL") {
      this.indicatorTextBoxForm = this.createIndicatorTextBoxForm();
    }

    this.getDataLevelId();
    this.getLevelAndCompanyNameToDisplay();
    let LoadMore = false;
    this.textArray = [];
    this.imageArray = [];
    this.galleryImages = [];
    this.i = 0;
    this.openText(LoadMore);
  }
  getLevelAndCompanyNameToDisplay() {
    console.log(this.data.scheduleVO)
    this.sisterCompanyList = this.data.scheduleVO.indicatorCalculationVO.sisterCompanyVOList;
    this.peerCompanyList = this.data.scheduleVO.indicatorCalculationVO.peerCompanyVOList;
    this.levelSelected = this.dataLevelsToLevelName[this.dataLevel];
    switch (this.dataLevel) {
      case "CL":
        this.companyId = this.data.selectedCompanyVO.id;
        this.companyName = this.data.selectedCompanyVO.companyName;

        break;
      case "PL":
        this.companyId = this.data.scheduleVO.indicatorCalculationVO.parentCompanyVO.id;
        this.companyName = this.data.scheduleVO.indicatorCalculationVO.parentCompanyVO.companyName;

        if (
          this.data.indicatorType == "QR" ||
          this.data.indicatorType == "QU"
        ) {

          this.data.scheduleVO.indicatorCalculationVO.parentCompanyVO.indicatorValueOtherSources = Number(
            this.data.scheduleVO.indicatorCalculationVO.parentCompanyVO
              .indicatorValueOtherSources
          );
        }
        if (!(isNaN(this.data.scheduleVO.indicatorCalculationVO.parentCompanyVO
          .indicatorValueOtherSources))) {
          this.indicatorTextBoxForm.controls.glValue.patchValue(
            this.data.scheduleVO.indicatorCalculationVO.parentCompanyVO
              .indicatorValueOtherSources
          );
        }

        break;
      case "SSL":
        this.companyId = this.data.scheduleVO.indicatorCalculationVO.sisterCompanyVOList[0].id;
        this.companyName = this.data.scheduleVO.indicatorCalculationVO.sisterCompanyVOList[0].companyName;

        if (
          this.data.indicatorType == "QR" ||
          this.data.indicatorType == "QU"
        ) {
          this.data.scheduleVO.indicatorCalculationVO.sisterCompanyVOList[0].indicatorValueOtherSources = Number(
            this.data.scheduleVO.indicatorCalculationVO.sisterCompanyVOList[0]
              .indicatorValueOtherSources
          );
        }
        if (!(isNaN(this.data.scheduleVO.indicatorCalculationVO.sisterCompanyVOList[0]
          .indicatorValueOtherSources))) {
          this.indicatorTextBoxForm.controls.glValue.patchValue(
            this.data.scheduleVO.indicatorCalculationVO.sisterCompanyVOList[0]
              .indicatorValueOtherSources
          );
        }

        break;
      case "PG":
        this.companyId = this.data.scheduleVO.indicatorCalculationVO.peerCompanyVOList[0].id;
        this.companyName = this.data.scheduleVO.indicatorCalculationVO.peerCompanyVOList[0].companyName;
        if (
          this.data.indicatorType == "QR" ||
          this.data.indicatorType == "QU"
        ) {
          this.data.scheduleVO.indicatorCalculationVO.peerCompanyVOList[0].indicatorValueOtherSources = Number(
            this.data.scheduleVO.indicatorCalculationVO.peerCompanyVOList[0]
              .indicatorValueOtherSources
          );
        }
        if (!(isNaN(this.data.scheduleVO.indicatorCalculationVO.sisterCompanyVOList[0]
          .indicatorValueOtherSources))) {
          this.indicatorTextBoxForm.controls.glValue.patchValue(
            this.data.scheduleVO.indicatorCalculationVO.peerCompanyVOList[0]
              .indicatorValueOtherSources
          );
        }

        break;
      default:
        this.companyId = this.data.selectedCompanyVO.id;
        this.companyName = this.data.selectedCompanyVO.companyName;
        break;
    }
    this.currentCompany = this.dataLevel + this.companyName;
  }
  showNextPeerOrSister() {
    this.indicatorTextBoxForm = this.createIndicatorTextBoxForm();

    let index;
    if (this.dataLevel == "SSL") {
      index =
        this.data.scheduleVO.indicatorCalculationVO.sisterCompanyVOList.indexOf(
          this.data.scheduleVO.indicatorCalculationVO.sisterCompanyVOList.find(
            x => x.id == this.companyId
          ),
          0
        ) + 1;

      if (
        index ==
        this.data.scheduleVO.indicatorCalculationVO.sisterCompanyVOList.length
      )
        index = 0;
      if (index > -1) {
        this.companyId = this.data.scheduleVO.indicatorCalculationVO.sisterCompanyVOList[
          index
        ].id;
        this.companyName = this.data.scheduleVO.indicatorCalculationVO.sisterCompanyVOList[
          index
        ].companyName;
        if (
          this.data.indicatorType == "QR" ||
          this.data.indicatorType == "QU"
        ) {
          this.data.scheduleVO.indicatorCalculationVO.sisterCompanyVOList[
            index
          ].indicatorValueOtherSources = Number(
            this.data.scheduleVO.indicatorCalculationVO.sisterCompanyVOList[
              index
            ].indicatorValueOtherSources
          );
        }
        if (!(isNaN(this.data.scheduleVO.indicatorCalculationVO.sisterCompanyVOList[index]
          .indicatorValueOtherSources))) {
          this.indicatorTextBoxForm.controls.glValue.patchValue(
            this.data.scheduleVO.indicatorCalculationVO.sisterCompanyVOList[index]
              .indicatorValueOtherSources
          );
        }

      }
    }

    if (this.dataLevel == "PG") {
      index =
        this.data.scheduleVO.indicatorCalculationVO.peerCompanyVOList.indexOf(
          this.data.scheduleVO.indicatorCalculationVO.peerCompanyVOList.find(
            x => x.id == this.companyId
          ),
          0
        ) + 1;

      if (
        index ==
        this.data.scheduleVO.indicatorCalculationVO.peerCompanyVOList.length
      )
        index = 0;
      if (index > -1) {
        this.companyId = this.data.scheduleVO.indicatorCalculationVO.peerCompanyVOList[
          index
        ].id;
        this.companyName = this.data.scheduleVO.indicatorCalculationVO.peerCompanyVOList[
          index
        ].companyName;
        if (
          this.data.indicatorType == "QR" ||
          this.data.indicatorType == "QU"
        ) {
          this.data.scheduleVO.indicatorCalculationVO.peerCompanyVOList[
            index
          ].indicatorValueOtherSources = Number(
            this.data.scheduleVO.indicatorCalculationVO.peerCompanyVOList[index]
              .indicatorValueOtherSources
          );
        }
        if (!(isNaN(this.data.scheduleVO.indicatorCalculationVO.peerCompanyVOList[index]
          .indicatorValueOtherSources))) {
          this.indicatorTextBoxForm.controls.glValue.patchValue(
            this.data.scheduleVO.indicatorCalculationVO.peerCompanyVOList[index]
              .indicatorValueOtherSources
          );

        }

      }
    }
    let LoadMore = false;
    this.textArray = [];
    this.imageArray = [];
    this.galleryImages = [];
    this.i = 0;
    this.openText(LoadMore);
    this.currentCompany = this.dataLevel + this.companyName;
  }
  createIndicatorTextBoxForm() {
    return this.formBuilder.group({
      glValue: []
    });
  }
  checkCheckBoxvalue(event,index){
    var fileDetailsVO={};
    if(this.fileDetails[index].id>0&&this.fileDetails[index].id!=null)
    {
      fileDetailsVO["id"]=this.fileDetails[index].id;
    }
    else{
      fileDetailsVO["id"]=0;
    }
    fileDetailsVO["companyId"]=this.companyId;
    fileDetailsVO["indicatorId"]=this.indicatorId;
    fileDetailsVO["year"]=this.year;
    fileDetailsVO["index"]=this.fileDetails[index].index;
    fileDetailsVO["flag"]=event.checked;
    this.performanceRecordService.createOrDeleteFileDetails(fileDetailsVO).subscribe(data => {
       this.openText(false);
    });
  }

  openDatasources(){
   
    this.integration=true;
    this.listText = false;
    this.webSearch = true;
    
    this.urlArray = [];
    this.serachResultList = [];
     let fileDetailsArray=[];
    let dataSourceArray;
    let valuesArray;
    let unitsArray;
  


      this.loading=true;
      this.performanceRecordService
        .fetchDatasourceMatches(
          this.indicatorId,
          this.year,
          this.companyId
        )
        .subscribe(data => {
          console.log(this.indicatorId);
          console.log(this.year);
          console.log(this.companyId);
          this.loading=false;
          this.datasourceDetailsArray=[];
          let i=0;
          
          this.dataSourcesMatchesVO = data;
          console.log("this.dataSourcesMatchesVO");
          console.log(this.dataSourcesMatchesVO);
          dataSourceArray = this.dataSourcesMatchesVO.matchingSentences;
          fileDetailsArray=this.dataSourcesMatchesVO.fileDetails;
          valuesArray=this.dataSourcesMatchesVO.values;
          unitsArray=this.dataSourcesMatchesVO.units;
          fileDetailsArray.forEach(fileDetail => {
            this.fileDetails[this.q] = fileDetail;
            this.q = this.q + 1;
          });
  
          console.log(dataSourceArray);
          console.log(valuesArray);
          console.log(unitsArray);
          console.log("unitsArray fileDetails");
          console.log(this.fileDetails)
          console.log(this.loading)
          for( let i=0; i<dataSourceArray.length;i++){
            var newArray = {};
      newArray['datasourceName'] = dataSourceArray[i];
      newArray['value'] = valuesArray[i];
      newArray['unit'] = unitsArray[i];
      if(this.fileDetails[i].flag==true)
      newArray['checkFlag'] = true;
      else
      newArray['checkFlag'] = false;
      this.datasourceDetailsArray.push(newArray);
          }
          console.log(this.datasourceDetailsArray)
           this.loading=false;
        });
    
   
  }
  dataSourceChange(event,datasourceName){

    var fileDetailsVO={};
    fileDetailsVO["companyId"]=this.companyId;
    fileDetailsVO["indicatorId"]=this.indicatorId;
    fileDetailsVO["year"]=this.year;
    fileDetailsVO["datasource"]=datasourceName;
    fileDetailsVO["flag"]=event.checked;
    this.performanceRecordService.createOrDeleteFileDetailsDataSource(fileDetailsVO).subscribe(data => {
       console.log("created file detials")
    });
  //   if(element.checked==false){

  //     this.updateBtnStatus=false;
  //   }
    
  // else{
  //   this.updateBtnStatus=true;
  // }
  
}
}
