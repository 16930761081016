import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges } from '@angular/core';
import { PerformanceRecordService } from '../performance-record.service';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import swal from 'sweetalert2';
import { MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { DocumentPopupComponent } from '../document-popup/document-popup.component';
import { Pipe, PipeTransform } from '@angular/core';
import { AddEditSuccessComponent } from '../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import {
  zoomInUpOnEnterAnimation,
  zoomOutUpOnLeaveAnimation,
} from 'angular-animations';
import { DataAcqCommonService } from '../../data-quality-master/data-acq-common.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-load-performance-record-indicator-list',
  templateUrl: './load-performance-record-indicator-list.component.html',
  styleUrls: ['./load-performance-record-indicator-list.component.css'],
  animations: [
    zoomInUpOnEnterAnimation({ anchor: 'imgOne', delay: 50 }),
    zoomInUpOnEnterAnimation({ anchor: 'imgTwo', delay: 300 }),
    zoomInUpOnEnterAnimation({ anchor: 'imgThree', delay: 550 }),
    zoomInUpOnEnterAnimation({ anchor: 'imgFour', delay: 800 }),
    zoomOutUpOnLeaveAnimation({ anchor: 'imgOut1', delay: 500 }),
    zoomOutUpOnLeaveAnimation({ anchor: 'imgOut2', delay: 500 }),
    zoomOutUpOnLeaveAnimation({ anchor: 'imgOut3', delay: 500 }),
    zoomOutUpOnLeaveAnimation({ anchor: 'imgOut4', delay: 500 }),
  ]

})
@Pipe({ name: 'extension' })

export class LoadPerformanceRecordIndicatorListComponent implements OnInit, PipeTransform {
  dataFromLevelId: any;
  toolTipToShow = [];
  userName: string = "Tony George";
  performanceRecordVO: any;
  indicatorVOList: any;
  selectedCompanyId: number;
  companyVOList: any = [];
  scheduleFormGroupArray: any = [];
  dpScheduleGroupArray: any = [];
  selectedCompanyVO: any;
  selectedIndicatorId: any;
  dpschedule: any;
  selectedindicatorVO: any;
  fileName: string;
  fileUploadName: string;
  UploadNameList = [];
  dbStoredName = [];
  schedulleForm: any;
  fileArray = [];
  fileNameArray = [];
  showName: any;
  dpFileList: { [key: string]: string };
  fileNames = [];
  currentScheduleId: any = 0;
  currentArrowScheduleId: any = 0;
  loading: boolean;
  @ViewChild('uploadFileTxt') uploadFileTxt: ElementRef;
  @ViewChild('filePart') filePart: ElementRef;
  routingFlag:any;
  selectedIndicator="esg"
  IndicatorList=[
    {value:"esg",name:"ESG Indicators"},
    {value:"other",name:"Other Indicators"}
  ]
  fileNamesMap: Map<string, string> = new Map<string, string>();
  constructor(private dataAcqCommonService: DataAcqCommonService, private performanceRecordService: PerformanceRecordService,
    private formBuilder: FormBuilder, private dialog: MatDialog, private addEditSuccessComponent: AddEditSuccessComponent,private activatedRoute: ActivatedRoute) {
    this.loading = false;
  }
  @Input() text: string;
  indicatorList:any=[]
  ngOnChanges(changes: SimpleChanges) {

    if (changes['text']) {
      // console.log(changes['text'].currentValue);
    }
  }
  ngOnInit() {

    this.getPerformanceRecord();
    this.activatedRoute.queryParams.subscribe(params => {
      console.log("paramssss")
      console.log(params)
      console.log(params['flag'])
      if(Object.keys(params).length!=0)
      {
        this.selectedCompanyId =Number(params['companyId']);
      }
      
      else if(Object.keys(params).length==0)
      {
        console.log("undefined")
        this.selectedCompanyId = 19
      }
    })
  }

  transform(fileName) {
    return fileName.substr(fileName.indexOf('__') + 2, fileName.length);
  }
  getPerformanceRecord() {
    this.loading = true;
     let performanceRecordDataVo={}
    performanceRecordDataVo['responsibleUser']=this.userName;
      this.performanceRecordService.fetchPerformanceRecord(performanceRecordDataVo).subscribe((data) => {
        console.log("backend data")
        console.log(data)
      this.performanceRecordVO = data;
      this.companyVOList = this.performanceRecordVO.companyVOList;
      //this.selectedCompanyVO = this.performanceRecordVO.companyVOList[0];
      console.log("this.companyVOList");
      console.log( this.companyVOList);
      this.onCompanyChange(this.selectedCompanyId)

      // if(this.routingFlag=='B')
      // {
      //   this.selectedCompanyId = 470;
      //   this.onCompanyChange(470);
      //   //this.selectedCompanyVO = this.performanceRecordVO.companyVOList[35];
      //   //this.indicatorVOList = this.performanceRecordVO.companyVOList[35].performanceframeworkVO.indicatorVOList;
      // }
      // else if(this.routingFlag=='N'){
      //   this.selectedCompanyId = 466;
      //   this.onCompanyChange(466);
        // this.selectedCompanyVO = this.performanceRecordVO.companyVOList[0];
        // this.selectedCompanyId = this.performanceRecordVO.companyVOList[0].id;
        // this.indicatorVOList = this.performanceRecordVO.companyVOList[0].performanceframeworkVO.indicatorVOList;
        // this.indicatorList = this.performanceRecordVO.companyVOList[0].performanceframeworkVO.indicatorVOList;
       // this.onIndicatorChange(this.selectedIndicator)
        // this.createScheduleDataPointFormGroups();
        // this.loading = false;
      // }
      //this.indicatorVOList = this.performanceRecordVO.companyVOList[0].performanceframeworkVO.indicatorVOList;
      //this.loading = false;
    });
  }
  toggleIndicator(id, indicatorId) {
    this.dialog.closeAll();
    this.dpScheduleGroupArray = [];
    //Collapse all open indicator details
    var scheduleRows = document.querySelectorAll('.display-block');
    var scheduleindicatorRows = document.querySelectorAll('.collapse_arrow-2');
    if (scheduleRows.length > 0) {
      for (let i = 0; i < scheduleRows.length; i++) {
        scheduleRows[i].classList.remove('display-block');
        scheduleRows[i].classList.add('display-none');
      }
    }

    if (document.getElementById("indicator" + id) && document.getElementById("indicator" + id).className == "expand_arrow-2") {
      document.getElementById("indicator" + id).classList.remove("expand_arrow-2");
      document.getElementById("indicator" + id).classList.add("collapse_arrow-2");

      if (document.getElementById("schedule" + id)) {
        document.getElementById("schedule" + id).classList.remove("display-none");
        document.getElementById("schedule" + id).classList.add("display-block");
      }

      this.selectedIndicatorId = indicatorId;
      for (let indicator of this.indicatorVOList)
        if (indicator.id == indicatorId) {
          this.selectedindicatorVO = this.indicatorVOList[id];
        }
    }
    else {
      if (document.getElementById("indicator" + id)) {
        document.getElementById("indicator" + id).classList.remove("collapse_arrow-2");
        document.getElementById("indicator" + id).classList.add("expand_arrow-2");
      }

      if (document.getElementById("schedule" + id)) {
        document.getElementById("schedule" + id).classList.remove("display-block");
        document.getElementById("schedule" + id).classList.add("display-none");
      }


    }
    if (scheduleindicatorRows.length > 0) {
      for (let i = 0; i < scheduleindicatorRows.length; i++) {
        scheduleindicatorRows[i].classList.remove('collapse_arrow-2');
        scheduleindicatorRows[i].classList.add('expand_arrow-2');
      }
    }
    for (let indicator of this.indicatorVOList) {
      for (let dataPoint of indicator.datapointVOList) {
        if (document.getElementById(dataPoint.id + indicator.id)) {
          document.getElementById(dataPoint.id + indicator.id).classList.remove("collapse-minus");
          document.getElementById(dataPoint.id + indicator.id).classList.add("expand-plus");
        }
      }
    }
  }
  onIndicatorChange(indicator)
  {
    console.log("indicator length")
    console.log(this.indicatorList.length)
    console.log(this.indicatorList)
    if(indicator=="other" && this.selectedCompanyId==466){
      let indicatorList=[...this.indicatorList]
      this.indicatorVOList=indicatorList.slice(indicatorList.length-7)
    }
    else if(indicator=="esg" && this.selectedCompanyId==466)
    {
      let indicatorList=[...this.indicatorList]
      this.indicatorVOList=indicatorList.slice(0,indicatorList.length-7)
    }
    this.createScheduleDataPointFormGroups();
   
  }
  onCompanyChange(companyId) {
    console.log(companyId)
    this.dialog.closeAll();
    this.loading = true;
    this.dpScheduleGroupArray = [];
    let performanceRecordDataVo={};
    performanceRecordDataVo['companyId']=companyId;
    performanceRecordDataVo['responsibleUser']=this.userName;
    this.performanceRecordService.fetchPerformanceRecordBycompanyId(performanceRecordDataVo)
      .subscribe((data) => {
        console.log("seleted data")
        console.log(data)
        this.selectedCompanyId = companyId;
        for (let company of this.performanceRecordVO.companyVOList) {
          if (this.selectedCompanyId == company.id) {
            this.selectedCompanyVO = company;
          }
        }
        this.indicatorVOList = data;
        this.indicatorList=data
        this.onIndicatorChange(this.selectedIndicator)
    
        this.loading = false;
      });
  }
  createScheduleDataPointFormGroups() {
    this.scheduleFormGroupArray = [];
    let indicatorList = this.indicatorList
    for (let indicator of indicatorList) {
      console.log("indicator id",indicator.id)
      for (let scheduleVO of indicator.indicatorConfigurationVO.scheduleVOList) {
        this.scheduleFormGroupArray[scheduleVO.scheduleId] = this.createFormGroup(scheduleVO, indicator);
        this.getToolTipToShow(scheduleVO, indicator);
      }

    }
    if(this.selectedCompanyId==466){
     this.calculateGHGIntensity(38200,202)
    }
  

  }
  createFormGroup(schedule, indicator) {
    let indicatorId = indicator.id;
    let actualValue = null;
    let validate = Validators.pattern(/^(?!\s+$).+/);
    
    if (schedule.actualValue)
      actualValue = schedule.actualValue;
    
    let actualRemarks = schedule.actualRemarks;
    let unitofmeasurementId;
    if (schedule.unitOfMeasurementVO && schedule.unitOfMeasurementVO.id) {
      unitofmeasurementId = schedule.unitOfMeasurementVO.id;
    }
    else {
      unitofmeasurementId = 0;
    }
    if (indicator.indicatorType.code == "QR" || indicator.indicatorType.code == "QU") {
      if (schedule.actualValue) {
        actualValue = Number(schedule.actualValue);
      }
      validate = Validators.pattern(/^\-?[0-9]\d{0,12}(\.\d{1,2})?%?$/);
      if(indicatorId==13)
      {
        validate= Validators.pattern(/^(?!\s+$).+/)
      }
    }
    this.schedulleForm =
      this.formBuilder.group({
        actualValue: [actualValue, validate],
        actualRemarks: [actualRemarks, Validators.pattern(/^(?!\s+$).+/)],
        originalFileName: [schedule.originalFileName],
        evidenceUploadFileName: [schedule.evidenceUploadFileName],
        submitFlag: [0],
        scheduleId: [schedule.scheduleId],
        updatedBy: [this.userName],
        companyIndicatorScheduleId: [schedule.companyIndicatorScheduleId],
        indicatorId: indicatorId,
        targetValue: [schedule.targetValue],
        unitOfMeasurementVO: this.formBuilder.group({
          id: [unitofmeasurementId]
        }),
        indicatorCalculationVO: []
      });

    return this.schedulleForm;
  }

  createDataPointScheduleFormGroup(dataPoint, indicator) {

    var dpScheduleformGroupArrayValues = [];
    for (let dataPointscheduleVO of dataPoint.scheduleVOList) {
      let actualValue = null;
      let validate = Validators.pattern(/^(?!\s+$).+/);
      if (dataPointscheduleVO.actualValue)
        actualValue = dataPointscheduleVO.actualValue;
      let actualRemarks = dataPointscheduleVO.actualRemarks;
      let unitofmeasurementId;
      if (dataPointscheduleVO.unitOfMeasurementVO && dataPointscheduleVO.unitOfMeasurementVO.id) {
        unitofmeasurementId = dataPointscheduleVO.unitOfMeasurementVO.id;
      }
      else {
        unitofmeasurementId = 0;
      }
      if (indicator.indicatorType.code == "QR" || indicator.indicatorType.code == "QU") {
        if (dataPointscheduleVO.actualValue) {
          actualValue = Number(dataPointscheduleVO.actualValue);
        }
        validate = Validators.pattern(/^\-?[0-9]\d{0,12}(\.\d{1,2})?%?$/);
      }
      let dpScheduleform = this.formBuilder.group({
        scheduleId: [dataPointscheduleVO.companyIndicatorScheduleId],
        scheduleStartDate: [dataPointscheduleVO.scheduleStartDate],
        scheduleEndDate: [dataPointscheduleVO.scheduleEndDate],
        actualValue: [actualValue, validate],
        actualRemarks: [actualRemarks, Validators.pattern(/^(?!\s+$).+/)],
        companyIndicatorScheduleId: [dataPointscheduleVO.companyIndicatorScheduleId],
        companyIndicatorDatapointMapping: [dataPointscheduleVO.companyIndicatorDatapointMapping],
        targetValue: [dataPointscheduleVO.targetValue],
        originalFileName: [dataPointscheduleVO.originalFileName],
        evidenceUploadFileName: [dataPointscheduleVO.evidenceUploadFileName],
        unitOfMeasurementVO: this.formBuilder.group({
          id: [unitofmeasurementId]
        })

      })

      dpScheduleformGroupArrayValues.push(dpScheduleform);
    }
    return dpScheduleformGroupArrayValues;
  }
  toggleDP(dataPoint, indicator) {
    let indicatorId = indicator.id;
    let dataPointID = dataPoint.id;
    if (document.getElementById(dataPointID + indicatorId) &&
      document.getElementById(dataPointID + indicatorId).className == "expand-plus") {
      document.getElementById(dataPointID + indicatorId).classList.remove("expand-plus");
      document.getElementById(dataPointID + indicatorId).classList.add("collapse-minus");
      if (document.getElementById("dpschedule" + dataPointID + indicatorId)) {
        document.getElementById("dpschedule" + dataPointID + indicatorId).classList.remove("display-none");
        document.getElementById("dpschedule" + dataPointID + indicatorId).classList.add("display-block");
        document.getElementById("dpschedule" + dataPointID + indicatorId).classList.add("bg-grey");
      }
      this.dpScheduleGroupArray[dataPointID] = this.createDataPointScheduleFormGroup(dataPoint, indicator);

    }
    else {
      if (document.getElementById(dataPointID + indicatorId)) {
        document.getElementById(dataPointID + indicatorId).classList.remove("collapse-minus");
        document.getElementById(dataPointID + indicatorId).classList.add("expand-plus");
      }
      if (document.getElementById("dpschedule" + dataPointID + indicatorId)) {
        document.getElementById("dpschedule" + dataPointID + indicatorId).classList.remove("display-block");
        document.getElementById("dpschedule" + dataPointID + indicatorId).classList.add("display-none");
      }
    }
  }



  handleFileInput(event, scheduleId, dataPointId, upID, dpMappingId, indicatorId) {

    const file = event.target.files[0];
    let fileName = new Date().getTime() + "__" + file.name;
    let showName = file.name;
    document.getElementById(upID).innerHTML = showName;

    this.fileArray.push(file);
    this.fileNameArray.push(fileName);

    if (dataPointId > 0) {
      if (document.getElementById('dpfilePart1' + dpMappingId + indicatorId)) {
        document.getElementById('dpfilePart1' + dpMappingId + indicatorId).classList.remove("display-none");
        document.getElementById('dpfilePart1' + dpMappingId + indicatorId).classList.add("display-block");
      }
      document.getElementById(upID).innerHTML = showName;
      if (document.getElementById('dpfilePart' + dpMappingId + indicatorId)) {
        document.getElementById('dpfilePart' + dpMappingId + indicatorId).classList.add("display-none");
      }
      if (this.fileNamesMap.has(dataPointId + "_" + scheduleId)) {
        this.fileNamesMap.delete(dataPointId + "_" + scheduleId);
        this.fileNamesMap.set(dataPointId + "_" + scheduleId, fileName);
      }
      else {
        this.fileNamesMap.set(dataPointId + "_" + scheduleId, fileName);

      }
    }
    else {
      if (document.getElementById('filePart1' + scheduleId)) {
        document.getElementById('filePart1' + scheduleId).classList.remove("display-none");
      }
      if (document.getElementById('filePart' + scheduleId)) {
        document.getElementById('filePart' + scheduleId).classList.add("display-none");
      }
      if (this.scheduleFormGroupArray[scheduleId] && this.scheduleFormGroupArray[scheduleId] != undefined) {
        this.scheduleFormGroupArray[scheduleId].get('originalFileName').setValue(showName);
        this.scheduleFormGroupArray[scheduleId].get('evidenceUploadFileName').setValue(fileName);
      }
    }


  }
  submitForm(submitFlag) {
    var formGroupArrayValues = [];
    let submitValid = false;
    let indicatorList = this.indicatorVOList;
    var dpformGroupArrayValues = [];
    for (let schedule in this.scheduleFormGroupArray) {
      if (this.scheduleFormGroupArray[schedule] && this.scheduleFormGroupArray[schedule] != undefined) {
        this.scheduleFormGroupArray[schedule].get('submitFlag').setValue(submitFlag);
        if (this.scheduleFormGroupArray[schedule].get('indicatorId').value == this.selectedIndicatorId) {
          formGroupArrayValues.push(this.scheduleFormGroupArray[schedule].value);
          if (this.scheduleFormGroupArray[schedule].valid) {
            submitValid = true;
          } else {
            submitValid = false;
            break;
          }
        }
      }
    }

    var dataPointVOGroupArrayValues = [];
    var dataPointVO = {};
    for (let dpid in this.dpScheduleGroupArray) {
      var dataPointVO = {};
      var dataPointVOScheduleGroupArrayValues = [];
      let newdpSchedule = this.dpScheduleGroupArray[dpid];
      for (let dpScheduleId in newdpSchedule) {
        if (this.dpScheduleGroupArray[dpid][dpScheduleId].valid) {
          submitValid = true;
        } else {
          submitValid = false;
          break;
        }
        if (newdpSchedule[dpScheduleId] && newdpSchedule[dpScheduleId] != undefined) {
          let scheduleId = newdpSchedule[dpScheduleId].get('scheduleId').value;
          if (this.fileNamesMap && this.fileNamesMap != undefined && this.fileNamesMap.has(dpid + "_" + scheduleId)) {
            let fileName = this.fileNamesMap.get(dpid + "_" + scheduleId);
            let showName = this.transform(fileName);
            newdpSchedule[dpScheduleId].get('evidenceUploadFileName').setValue(fileName);
            newdpSchedule[dpScheduleId].get('originalFileName').setValue(showName);
          }
        }

        dataPointVOScheduleGroupArrayValues.push(newdpSchedule[dpScheduleId].value);

      }

      for (let dataPoint of this.selectedindicatorVO.datapointVOList) {
        if (dataPoint.id == dpid) {
          dataPointVO['indicatorDataPointMappingId'] = dataPoint.indicatorDataPointMappingId;
        }
      }

      dataPointVO['id'] = dpid;
      dataPointVO['scheduleVOList'] = dataPointVOScheduleGroupArrayValues;

      dataPointVOGroupArrayValues.push(dataPointVO);

    }


    let indicatorVO = {};
    let indicatorConfigurationVO = {};
    let datapointVOList = {};
    let indicatorType = {};
    let indicatorDetailsVO = {};
    indicatorVO['id'] = this.selectedIndicatorId;
    indicatorType['code'] = this.selectedindicatorVO.indicatorType.code;
    indicatorVO['indicatorType'] = indicatorType;
    indicatorConfigurationVO['finalTarget'] = this.selectedindicatorVO.indicatorConfigurationVO.finalTarget;
    indicatorConfigurationVO['scheduleVOList'] = formGroupArrayValues;
    indicatorConfigurationVO['frameworkIndicatorDetailId'] = this.selectedindicatorVO.indicatorConfigurationVO.frameworkIndicatorDetailId;

    indicatorVO['indicatorConfigurationVO'] = indicatorConfigurationVO;
    indicatorVO['datapointVOList'] = dataPointVOGroupArrayValues;
    indicatorVO['companyId'] = this.selectedCompanyId;
    indicatorVO['indicatorDetailsVO'] = this.selectedindicatorVO.indicatorDetailsVO;
    console.log("this indicator ")
     console.log(indicatorVO)
    if (submitValid) {
      this.performanceRecordService.createIndicatorSchedules(indicatorVO).subscribe(() => {

        // swal({
        //   title: 'Success!',
        //   text: 'Updated Performance Record',
        //   type: 'success',
        //   confirmButtonText: 'Ok'
        // })
        let msg = "Updated Performance Record";
        this.addEditSuccessComponent.showSuccessSwal(msg)
          .then((value) => {
            this.onCompanyChange(this.selectedCompanyId);
            this.fileNamesMap.clear();
            this.fileNameArray = [];
            this.fileArray = []

          });
        if (this.fileArray.length > 0) {
          const formData: any = new FormData();
          const files: Array<File> = this.fileArray;
          const filenames: Array<String> = this.fileNameArray;
          for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
            formData.append('fileName', filenames[i]);
          }
          this.performanceRecordService.upload(formData).subscribe(() => {
          });
        }

      });
    }

  }
  download(fileName,indicatorCode) {
    console.log(indicatorCode)
    console.log(this.selectedCompanyVO.companyCode)
    let fileUrl =
    this.selectedCompanyVO.companyCode +
    "/" +
    indicatorCode +
    "/" +
    fileName;
  
   window.location.href =  this.performanceRecordService.downloadFile() +"/download?filename=" +encodeURIComponent(fileUrl)
  

 }


  deleteScheduleFile(scheduleId, schedule) {
    if (document.getElementById('filePart1' + scheduleId)) {
      document.getElementById('filePart1' + scheduleId).classList.add("display-none");
    }
    if (document.getElementById('filePart' + scheduleId)) {
      document.getElementById('filePart' + scheduleId).classList.add("display-none");
    }


    schedule.evidenceUploadFileName = null;
    schedule.originalFileName = null;

    if (this.scheduleFormGroupArray[scheduleId] && this.scheduleFormGroupArray[scheduleId] != undefined) {
      this.scheduleFormGroupArray[scheduleId].get('originalFileName').setValue(null);
      this.scheduleFormGroupArray[scheduleId].get('evidenceUploadFileName').setValue(null);
    }





  }
  deleteDatapointScheduleFile(companyIndicatorDatapointMapping, datapointScheduleId, dpid, indicatorId) {
    if (document.getElementById('dpfilePart1' + companyIndicatorDatapointMapping + indicatorId)) {
      document.getElementById('dpfilePart1' + companyIndicatorDatapointMapping + indicatorId).classList.remove("display-block");
      document.getElementById('dpfilePart1' + companyIndicatorDatapointMapping + indicatorId).classList.add("display-none");
    }
    if (document.getElementById('dpfilePart' + companyIndicatorDatapointMapping + indicatorId)) {
      document.getElementById('dpfilePart' + companyIndicatorDatapointMapping + indicatorId).classList.add("display-none");
    }

    for (let dp in this.dpScheduleGroupArray) {
      if (dp == dpid) {
        let newdpSchedule = this.dpScheduleGroupArray[dpid];
        for (let dpScheduleId in newdpSchedule) {
          if (newdpSchedule[dpScheduleId] && newdpSchedule[dpScheduleId] != undefined && newdpSchedule[dpScheduleId].get('scheduleId').value == datapointScheduleId) {
            if (this.fileNamesMap.has(dpid + "_" + datapointScheduleId)) {
              this.fileNamesMap.delete(dpid + "_" + datapointScheduleId);
            }
            newdpSchedule[dpScheduleId].get('evidenceUploadFileName').setValue(null);
            newdpSchedule[dpScheduleId].get('originalFileName').setValue(null);

          }
        }
      }
    }




  }







  showDocPopup(level, event, dpId, flag, indicatorId, formGrp, scheduleVO) {
    this.selectedCompanyId
    let endDate = scheduleVO.scheduleEndDate;
    let index = scheduleVO.scheduleId;
    document.getElementById(this.currentArrowScheduleId).classList.add("show-left-arrow");
    let offsetLeft = 0;
    let offsetTop = 0;
    let popupTop = 0;

    let el = event.srcElement;
    let indicatorName = this.selectedindicatorVO.name;
    let indicatorCode = this.selectedindicatorVO.code;
    let indicatorType = this.selectedindicatorVO.indicatorType.code;
    let ratingGroupValuesVOList = [];
    if (indicatorType == "QR")
      ratingGroupValuesVOList = this.selectedindicatorVO.indicatorDetailsVO.ratingGroupVO.ratingGroupValuesVOList;
    let year = endDate.substring(endDate.length - 4, endDate.length);
    while (el) {
      offsetLeft += el.offsetLeft;
      offsetTop += el.offsetTop;
      el = el.parentElement;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = 1;
    this.dialog.closeAll();

    const dialogRef = this.dialog.open(DocumentPopupComponent, {
      data: { dataLevel: level, index: index, flag: flag, dpId: dpId, indicatorId: indicatorId, indicatorName: indicatorName, indicatorCode: indicatorCode, selectedCompanyVO: this.selectedCompanyVO, year: year, formgroup: formGrp, scheduleVO: scheduleVO, indicatorType: indicatorType, ratingGroupValuesVOList: ratingGroupValuesVOList },
      position: {
        right: '2.6rem'
      }
    });
  }

  showLevelIcons(event, scheduleId) {
    if (this.currentScheduleId != 1) {
      this.currentScheduleId = scheduleId;
      if (event.currentTarget.id)
        this.currentArrowScheduleId = event.currentTarget.id
    }
  }
  calculateGHGIntensity(scheduleId,indicatorId)
  {
    var newLine = "\r\n"
    if(this.selectedCompanyId==466)
    {
      if(indicatorId==6 || indicatorId==202)
      {
        if(scheduleId==38198 || scheduleId==38200)
        {
          if(this.scheduleFormGroupArray[38198].value.actualValue!='' && this.scheduleFormGroupArray[38200].value.actualValue!='')
          {
            let actualValue=(this.scheduleFormGroupArray[38198].value.actualValue)/(this.scheduleFormGroupArray[38200].value.actualValue)
            this.scheduleFormGroupArray[38297].controls.actualValue.setValue(actualValue.toFixed(2));
            let toolTip = "Data From Level : CL" 
            toolTip += newLine;
            toolTip += "Indicator Value :" + actualValue.toFixed(2).toString();
            toolTip += newLine;
            toolTip += "GHG emissions intensity  = Direct greenhouse gas emissions (Scope 1)/Turn Over";
            this.toolTipToShow[38297] = toolTip;
           console.log(  this.toolTipToShow[38297])
         
          }
        }
      }
      if(indicatorId==201 || indicatorId==204 ||indicatorId==202)
      {
        if(scheduleId==38288 || scheduleId==38309 ||  scheduleId==38200)
        {
          if(this.scheduleFormGroupArray[38288].value.actualValue!='' && this.scheduleFormGroupArray[38309].value.actualValue!=''
          && this.scheduleFormGroupArray[38200].value.actualValue!='')
          {
           let empCount=this.scheduleFormGroupArray[38288].value.actualValue
           let assetValue=this.scheduleFormGroupArray[38309].value.actualValue
           let revenue=this.scheduleFormGroupArray[38200].value.actualValue
           
           if((empCount>500) && (assetValue>20 || revenue>40))
           {
            this.scheduleFormGroupArray[38260].controls.actualValue.setValue(1);

           }
           else 
           {
            this.scheduleFormGroupArray[38260].controls.actualValue.setValue(2);
           }
          }
          else 
          {
            this.scheduleFormGroupArray[38260].controls.actualValue.setValue(2);
          }
          let toolTip = "Formula=Employee Count >500 AND (Total Assets > 20MEuro) OR (Turn Over >40MEuro) " 
          // toolTip += newLine;
          // toolTip += "Indicator Value :" + actualValue.toFixed(2).toString();
          // toolTip += newLine;
          //toolTip += "GHG emissions intensity  = Direct greenhouse gas emissions (Scope 1)/EU Taxonomy Eligible Revenue";
          this.toolTipToShow[38260] = toolTip;
        //  console.log(  this.toolTipToShow[38297])
        }
      }
    }
  }
  getToolTipToShow(scheduleVO, indicatorVO) {
    
    let sourceId = 1;//to be changed
    var newLine = "\r\n"
    if (scheduleVO && scheduleVO.actualValue != this.scheduleFormGroupArray[scheduleVO.scheduleId].controls.actualValue.value) {
      scheduleVO.actualValue = this.scheduleFormGroupArray[scheduleVO.scheduleId].controls.actualValue.value;
      scheduleVO.indicatorCalculationVO.dataFromLevelFlag = "CL";
      scheduleVO.indicatorCalculationVO.sourceId = 1;

      let indicatorRequestVO = {};
      indicatorRequestVO["isContantVOBasedOnType"] = true;
      indicatorRequestVO["constantCode"] = "CL";
      indicatorRequestVO["constantLabel"] = "DATA_LEVEL";
      this.dataAcqCommonService
        .getDataAcqMasterData(indicatorRequestVO)
        .subscribe(data => {
          this.dataFromLevelId = data.constantvo.id;
          scheduleVO.indicatorCalculationVO.dataFromlevelId = this.dataFromLevelId;
        });


      scheduleVO.indicatorCalculationVO.sourceId = 1;//tobe changed
      this.scheduleFormGroupArray[scheduleVO.scheduleId].patchValue({

        indicatorCalculationVO: scheduleVO.indicatorCalculationVO
      });
    }
    if (scheduleVO && scheduleVO.indicatorCalculationVO.dataFromLevelFlag != null) {
      let toolTip = "Data From Level : " + scheduleVO.indicatorCalculationVO.dataFromLevelFlag;
      toolTip += newLine;
      switch (scheduleVO.indicatorCalculationVO.dataFromLevelFlag) {
        case "CL":
          let indicatorValueToShow;
          toolTip += newLine;
          if (indicatorVO.indicatorType.code == "QR") {
            let ratingGroupValuesObject = indicatorVO.indicatorDetailsVO.ratingGroupVO.ratingGroupValuesVOList.find(ratingGroupObj => ratingGroupObj.id == scheduleVO.actualValue)
            if (ratingGroupValuesObject)
              indicatorValueToShow = ratingGroupValuesObject.description;
          }
          else {
            if (scheduleVO.actualValue)
              indicatorValueToShow = scheduleVO.actualValue.toString();
          }
          toolTip += "Indicator Value :" + indicatorValueToShow;
         
          scheduleVO.indicatorCalculationVO.sourceId = 1;

          break;
        case "PL":
          toolTip += newLine;
          if (indicatorVO.indicatorType.code == "QR") {
            let ratingGroupValuesObject = indicatorVO.indicatorDetailsVO.ratingGroupVO.ratingGroupValuesVOList.find(ratingGroupObj => ratingGroupObj.id == scheduleVO.indicatorCalculationVO.parentCompanyVO.indicatorValueOtherSources)
            if (ratingGroupValuesObject)
              indicatorValueToShow = ratingGroupValuesObject.description;
          }
          else {
            indicatorValueToShow = scheduleVO.indicatorCalculationVO.parentCompanyVO.indicatorValueOtherSources
          }
          toolTip += "Indicator Value :" + indicatorValueToShow;
          scheduleVO.indicatorCalculationVO.parentCompanyVO.sourceId = 1;
          break;
        case "SSL":
          for (let sisterCompanyVO of scheduleVO.indicatorCalculationVO.sisterCompanyVOList) {
            if (sisterCompanyVO.indicatorValueOtherSources != null) {
              toolTip += newLine;
              toolTip += "Sister Company :" + sisterCompanyVO.companyName;
              toolTip += newLine;
              if (indicatorVO.indicatorType.code == "QR") {
                let ratingGroupValuesObject = indicatorVO.indicatorDetailsVO.ratingGroupVO.ratingGroupValuesVOList.find(ratingGroupObj => ratingGroupObj.id == sisterCompanyVO.indicatorValueOtherSources)
                if (ratingGroupValuesObject)
                  indicatorValueToShow = ratingGroupValuesObject.description;
              }
              else {
                indicatorValueToShow = sisterCompanyVO.indicatorValueOtherSources
              }
              toolTip += "Indicator Value :" + indicatorValueToShow;
              scheduleVO.indicatorCalculationVO.sisterCompanyVOList.forEach(
                sisterCompanyVO => {
                  sisterCompanyVO.sourceId = 1;

                });
            }

          }
          break;
        case "PG":
          for (let peerCompanyVO of scheduleVO.indicatorCalculationVO.peerCompanyVOList) {
            if (peerCompanyVO.indicatorValueOtherSources != null) {
              toolTip += newLine;
              toolTip += "Peer Company :" + peerCompanyVO.companyName;
              toolTip += newLine;
              if (indicatorVO.indicatorType.code == "QR") {
                let ratingGroupValuesObject = indicatorVO.indicatorDetailsVO.ratingGroupVO.ratingGroupValuesVOList.find(ratingGroupObj => ratingGroupObj.id == peerCompanyVO.indicatorValueOtherSources)
                indicatorValueToShow = ratingGroupValuesObject.description;
              }
              else {
                indicatorValueToShow = peerCompanyVO.indicatorValueOtherSources
              }
              toolTip += "Indicator Value :" + indicatorValueToShow;
              scheduleVO.indicatorCalculationVO.peerCompanyVOList.forEach(
                peerCompanyVO => {
                  peerCompanyVO.sourceId = 1;

                });
            }

          }
          break;
      }
      this.toolTipToShow[scheduleVO.scheduleId] = toolTip;

    }

  }
}