import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { IndicatorService } from '../indicator.service';
import swal from 'sweetalert2';
import { AddEditSuccessComponent } from '../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';

@Component({
  selector: 'app-list-indicator',
  templateUrl: './list-indicator.component.html',
  styleUrls: ['./list-indicator.component.css']
})
export class ListIndicatorComponent implements OnInit {
  displayedColumns:any;
  originalColumnNames : any;
  indicatorFlag:any;
  jsonArray =[];
  fetchedIndicatorList:any =[];
  @Input() set indicatorList(indicatorList){
    if(indicatorList)
    { 
      this.dataTablePopulation(indicatorList);
    
  
    }  
  }
  constructor(private indicatorService:IndicatorService,private router:Router,private addEditSuccessComponent: AddEditSuccessComponent ) { }

  ngOnInit() {
    this.indicatorFlag=true;
  }

  dataTablePopulation(indicatorList)
  {
        let count =0;
       this.jsonArray=[];
      
      for( let i of indicatorList){
        
        let newArray = {};
        
        newArray['id'] = i.id;
        newArray['Code'] = i.code;
        newArray['Name'] = i.name;
        newArray['PerformanceArea'] = i.subcategoryVO.code+"-"+i.subcategoryVO.name;
        newArray['IndicatorStack'] = i.indicatorStackVO.name;
        newArray['indCategoryCode']=i.indicatorStackVO.code;
        newArray['indCategoryId']=i.subcategoryVO.id;
        newArray['category'] = i.categoryVO.code+"-"+i.categoryVO.name;
        if(i.indicatorDetailsVO.unitOfMeasurementVO)
        {
           newArray['unitOfMeasurement'] = i.indicatorDetailsVO.unitOfMeasurementVO.code+"-"+i.indicatorDetailsVO.unitOfMeasurementVO.name;
        }
        else if(i.indicatorDetailsVO.ratingGroupVO)
        {
           newArray['unitOfMeasurement'] = i.indicatorDetailsVO.ratingGroupVO.name;

        }
        else
        {
           newArray['unitOfMeasurement'] = "-";

        }
        
        this.jsonArray.push(newArray);
      }
     
    this.displayedColumns=['category','PerformanceArea','IndicatorStack','Code','Name','unitOfMeasurement','Actions']
    this.originalColumnNames=['Category','Performance Area','Indicator Stack','Code','Name','Unit of Measurement / Rating Group','Actions'];
   }
  edit(indicatorObj){
    this.router.navigate(['/addIndicator/'+indicatorObj.id],{ skipLocationChange: true});
  }

  calculate(indicatorObj)
  {
    console.log("claculat")
    console.log(indicatorObj)
    let navigationExtras: NavigationExtras = {
      queryParams: { 
       indicatorId:indicatorObj.id,
      
      },
      skipLocationChange: true
    };
    this.router.navigate(['/linkIndicator'],navigationExtras)
  }

  delete(indicatorObj) {
    var confirmMessage = "You Want To Delete Indicator";
    this.addEditSuccessComponent.showConfirmationSwal(confirmMessage,'Yes','No').then((isConfirm) => {
      if (isConfirm.value) {
        this.indicatorService.deleteIndicator(indicatorObj.id).subscribe((data) => {
            var deleteSuccess = 'Deleted Indicator';
            this.addEditSuccessComponent.showSuccessSwal(deleteSuccess)
              .then((value) => {
                   this.fetchIndicatorList();
              });
        },(error)=>{
          console.log(error);
        });
      }
    });
  }

  fetchIndicatorList()
  {
     this.indicatorService.getListOfAllIndicators().subscribe(data => {
        let indicatorList = data;
        this.dataTablePopulation(indicatorList);
     }, (error) => {
        console.log(error);
     });
  }


  
}
