import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource,MatDialog,MatDialogConfig } from '@angular/material';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { CustomValidationsService } from '../../../../../shared/Validations/custom-validations.service';
import { AddMoreQuestionsComponent } from '../add-more-questions/add-more-questions.component';                         
import { AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import { SearchQuestionsComponent } from '../search-questions/search-questions.component';
import swal from 'sweetalert2';
import { AddEditSuccessComponent } from '../../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';

interface AssessmentQuestionNode {

  childNodes?: AssessmentQuestionNode[];
  nodeData?: any[];
  parentNode?:AssessmentQuestionNode;

}
@Component({
  selector: 'app-assessment-question-template',
  templateUrl: './assessment-question-template.component.html',
  styleUrls: ['./assessment-question-template.component.css']
})
export class AssessmentQuestionTemplateComponent implements OnInit {
 
  treeControl = new NestedTreeControl<AssessmentQuestionNode>(node => node.childNodes);
  dataSource = new MatTreeNestedDataSource<AssessmentQuestionNode>();
  assessmentQuestionTemplateVOList: any ;
  data:any;
  templateId:any;
  selectedTreeNode:any;
  templateQuestionNode:string;
  templateQuestions:any;
  templateVO:any;
  templateForm: FormGroup;
  templateName:any;
  selectedQuestionIdList:any=[];
  selectedPrimaryQuestionList:any=[];
  selectedChildQuestionList:any=[];
  responseOptions: any=[];
  childQuestionSelected: any;
  dialogConfig = new MatDialogConfig();
  selectedQuestionNode: any;
  savedQuestionNode:any;
  activeElement:any; 
  assessmentQuestionList={};
  constructor(private addEditSuccessComponent: AddEditSuccessComponent,private activatedRoute: ActivatedRoute,private _fb: FormBuilder,private customValidationsService:CustomValidationsService,private dialog: MatDialog, private assessmentTemplateService: AssessmentTemplateMasterdataService, private router: Router) {
     this.templateId = this.activatedRoute.snapshot.queryParamMap.get('id');
     this.templateQuestionNode = this.activatedRoute.snapshot.queryParamMap.get('templateQuestionNode');
     this.templateName =  this.activatedRoute.snapshot.queryParamMap.get('templateName');
     console.log("this.templateQuestionNode")
     console.log(this.templateQuestionNode)
   }

  ngOnInit() {
    this.templateForm = this.createFormGroup();
    this.templateQuestions= JSON.parse(this.templateQuestionNode);
    this.assessmentQuestionTemplateVOList=this.templateQuestions.childNodes;
    this.dataSource.data = this.assessmentQuestionTemplateVOList;
    this.treeControl.dataNodes = this.assessmentQuestionTemplateVOList;
    this.templateForm.controls['templateName'].setValue(this.templateName);
    this.getResponseOptionsForQuestion(this.assessmentQuestionTemplateVOList[0]);
  }  
  hasChild = (_: number, node: AssessmentQuestionNode) => !!node.childNodes && node.childNodes.length > 0;
  createFormGroup(): any {
    return this._fb.group({
      templateName:['', [Validators.compose([Validators.required]),this.customValidationsService.noWhitespaceValidator]],
 })
  }

 addMoreQuestion() {   
  if( this.templateForm.get('templateName').value =="")
  {
   
     let msg="Please give a Template name";
     let title="Error";
      //  swal({
      //   title: 'Error',
      //   text: msg,
      //   type: 'error',
      //   confirmButtonText: 'Ok',
      // })
      this.addEditSuccessComponent.showFailedSwal(msg,title);

  }
  else
  {
      this.fetchAllSelectedPrimaryQuestionForTemplate();
      this.dialogConfig.disableClose = true;
      this.dialogConfig.width = "80%";
      this.dialogConfig.data = { idList:this.selectedPrimaryQuestionList };
      const dialogRef = this.dialog.open(AddMoreQuestionsComponent, this.dialogConfig); 
      dialogRef.afterClosed().subscribe(data => {
        if(data!=undefined){  
            let  selectedQuestions = [];
            selectedQuestions=JSON.parse(data.questionSelectedObjs);//only selected question should come
            this.savePrimaryQuestion(selectedQuestions);   
            } 
      }); 
  }  
  } 
 savePrimaryQuestion(selectedPrimaryQuestions)
  {       
         let templateVO={};
          let viridiTreeNode={};
          let viridiAssessmentTemplateQuestionList = [];
          viridiTreeNode['nodeData']=null;	
          viridiTreeNode['parentNode']=null;	
          for (let primaryQuestionViridiTreeNode of selectedPrimaryQuestions) {        
              viridiAssessmentTemplateQuestionList.push(primaryQuestionViridiTreeNode);
          }
          viridiTreeNode['childNodes']=viridiAssessmentTemplateQuestionList;	
          templateVO['templateQuestionNode'] =viridiTreeNode;
          templateVO['saveFlag'] = 'Y';
          templateVO['id'] =  this.templateId ;
          templateVO['templateName'] = this.templateForm.value.templateName;
          this.assessmentTemplateService.createTemplate(templateVO).subscribe(templateVO => {
          this.templateVO=templateVO
          this.assessmentQuestionTemplateVOList=[];
          for(let viridiTreeNode of this.templateVO.templateQuestionNode.childNodes )
          {
            this.assessmentQuestionTemplateVOList.push(viridiTreeNode);            
           }
           this.dataSource.data= this.assessmentQuestionTemplateVOList;
          });
  } 
//onclick of skip logic checkbox-> to show the response options for question
getResponseOptionsForQuestion(viridiTreeNode: any) {
  const currentLevel =this.treeControl.getChildren
  this.activeElement = viridiTreeNode.nodeData.id;
  this.childQuestionSelected = [];
  this.assessmentTemplateService.getResponseOptionsForQuestion(viridiTreeNode.nodeData.questionVO.id).subscribe(data => {
    this.responseOptions = data;
    let childQuestions;
    if (this.templateId > 0) {
    this.assessmentTemplateService.getChildQuestionsOnTemplateQuestionId(viridiTreeNode.nodeData.id).subscribe(data => {
      childQuestions = data;
      if(this.responseOptions.length>0 &&childQuestions!=null &&childQuestions.childNodes[0] && childQuestions.childNodes[0].childNodes.length > 0)
      {
        let responseIds = this.responseOptions.map(item => item.id);
     
        viridiTreeNode['childNodes']=null;
        viridiTreeNode['childNodes']=childQuestions.childNodes[0].childNodes;
        this.childQuestionSelected=[];
        this.childQuestionSelected= viridiTreeNode['childNodes'];

        for (let childViridiTreeNode of childQuestions.childNodes[0].childNodes) {
             
              this.assessmentQuestionList[childViridiTreeNode.nodeData.id]=viridiTreeNode;

             }
                
        } 
      else
      {
        viridiTreeNode.nodeData.questionVO.skipLogicFlag ='N';
        viridiTreeNode.childNodes=null

      }
    
    })
  }
  })
  this.selectedQuestionNode = viridiTreeNode;
  this.treeControl.expand(this.selectedQuestionNode);
 

}
showQuestionPopup(index, questionSelected, responseOptionId) {
    if( this.templateForm.get('templateName').value =="")
    {
    
      let msg="Please give a Template name";
      let title="Error";
        // swal({
        //   title: 'Error',
        //   text: msg,
        //   type: 'error',
        //   confirmButtonText: 'Ok',
        // })
        this.addEditSuccessComponent.showFailedSwal(msg,title);

    }
    else
    {
      this.selectedChildQuestionList=[];
      this.fetchChildSelectedForParent(questionSelected);
      this.dialogConfig.disableClose = true;
      this.dialogConfig.width = "80%";
      this.dialogConfig.data = { parentQuestionNode: this.selectedQuestionNode, idList: this.selectedChildQuestionList, selectedQuestion: questionSelected, index: index, responseOptionId: responseOptionId};
      const dialogRef = this.dialog.open(SearchQuestionsComponent, this.dialogConfig);
      dialogRef.afterClosed().subscribe(data => {
        this.childQuestionSelected=[];
        this.childQuestionSelected = data;
        this.selectedQuestionNode['childNodes']=this.childQuestionSelected;
        this.selectedQuestionNode['nodeData']['questionVO']['skipLogicFlag']='Y';
        this.treeControl.expand(this.selectedQuestionNode);
      });
    }
  }

  addSubQuestionForOption ()
{  
          let templateVO={};
          let viridiAssessmentTemplateQuestionList = [];    
          let assessmentQuestionTemplateVOListTemp=[];
          templateVO['templateQuestionNode'] =this.selectedQuestionNode;
          templateVO['saveFlag'] = 'Y';
          templateVO['id'] =  this.templateId ;
          templateVO['templateName'] = this.templateForm.value.templateName;
          this.assessmentTemplateService.createSubQuestion(templateVO).subscribe(//need to change template vo getting treenode
            selectedTreeNode => {      
              this.savedQuestionNode=selectedTreeNode;
              this.selectedQuestionNode['childNodes']=null;
              this.selectedQuestionNode['childNodes']=this.savedQuestionNode.childNodes[0].childNodes;
              for( let childNode of  this.selectedQuestionNode.childNodes)
              {
                this.assessmentQuestionList[childNode.nodeData.id]=this.selectedQuestionNode;
              }
              this.childQuestionSelected=[];
              this.childQuestionSelected= this.selectedQuestionNode['childNodes'];
              this.refreshMatTree(); 
              this.treeControl.expand(this.selectedQuestionNode);
          });
         
          




}

 onSaveOrSubmit(saveFlag,isSubquestion) {


  if( this.assessmentQuestionTemplateVOList.length ==0)
  {
  
    let msg="Please select atleast one  question";
    let title="Error";
      // swal({
      //   title: 'Error',
      //   text: msg,
      //   type: 'error',
      //   confirmButtonText: 'Ok',
      // })
      this.addEditSuccessComponent.showFailedSwal(msg,title);

  }
else{
   let templateVO = {};
   templateVO['id']=this.templateId;
   templateVO['saveFlag'] =saveFlag;
   templateVO['templateName'] = this.templateForm.get('templateName').value;
   let viridiTreeNode={};
   viridiTreeNode['parentNode']=null;	
   viridiTreeNode['childNodes']=this.assessmentQuestionTemplateVOList;;	
   templateVO['templateQuestionNode'] =viridiTreeNode;
   this.assessmentTemplateService.createTemplate(templateVO).subscribe(templateVO => {
    this.templateVO=templateVO
    if(saveFlag =='Y')
    var msg = "Successfully saved the template";
    else{
      if(this.templateId > 0) {
        var msg = "Successfully updated the template.";
      }  
    }
    // swal({
    //   title: 'Success!',
    //   text: msg,
    //   timer: 2000,
    //   type: 'success',
    //   confirmButtonText: 'Ok'
    // }) 
    this.addEditSuccessComponent.showSuccessSwal(msg)
    .then((value) => {
      
         if(saveFlag =='N')
         {
           this.router.navigate(['/listTemplate']); 
         }

      });
 });
}
 }
  removeQuestion(viridiTreeNode,isSubquestion)
  {
     let primaryQuestionFlag=viridiTreeNode.nodeData.questionVO.primaryQuestionFlag;
     let deletedQuestionId =viridiTreeNode.nodeData.id;
     let selectedQuestionId;
     let isSameFlag='N';
     let parentNode;     
     let parentNodeId; 
     let isParentNodeSelected='N'    

      if(isSubquestion=='N'  && primaryQuestionFlag=='N'  )
      {       
         selectedQuestionId = this.selectedQuestionNode.nodeData.id;
        parentNode=this.assessmentQuestionList[deletedQuestionId];
        if(parentNode)
        {
          parentNodeId=parentNode.nodeData.id;
          if(selectedQuestionId==parentNodeId)
          {
            isParentNodeSelected='Y'
          }
        }
      }
      swal({
        title: '<p class="swal2-title-warning">Are You Sure?</p>',
        text: "You Want To Delete  Question",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
  
      }).then((isConfirm) => {
        if (isConfirm.value) 
        {
        if (this.templateId > 0 && deletedQuestionId!=undefined )
              {  
      
                 this.assessmentTemplateService.deleteTemplateQuestion( this.templateId,deletedQuestionId).subscribe(data => {
               })

               if(isSubquestion=='Y')
               {
                 this.selectedQuestionNode['childNodes']= this.selectedQuestionNode['childNodes'].filter(quest => quest.nodeData.id !==deletedQuestionId);
                 this.childQuestionSelected= this.childQuestionSelected.filter(quest => quest.nodeData.id !==deletedQuestionId);
                   if(this.selectedQuestionNode.childNodes.length==0)
                   {
                        this.childQuestionSelected=[];
                        this.selectedQuestionNode['childNodes']=[];
                        this.selectedQuestionNode['skipLogicFlag']='N';
                   }
                   this.refreshMatTree();   
                  this.expand(this.selectedQuestionNode);    
               }
               else
               {
                   if(selectedQuestionId==deletedQuestionId)//comes for both primaryflag and skip questions
                   {
                     isSameFlag='Y'
                     this.childQuestionSelected=[];//ddelete right hand side
                     this.responseOptions =[];
                   }
                    
                   if(primaryQuestionFlag=='Y')//primaryflag
                   {
                       //if the primary question is the selected question the childquestion should be deleted
                       this.assessmentQuestionTemplateVOList= this.assessmentQuestionTemplateVOList.filter(quest => quest.nodeData.id !==deletedQuestionId);
                       this.data=this.assessmentQuestionTemplateVOList;
                        this.refreshMatTree();
                        if(this.assessmentQuestionTemplateVOList.length==0)
                        {
                         this.childQuestionSelected=[];//ddelete right hand side
                         this.responseOptions =[];
                        }
                     }
                   else//inner question
                   {
                       //if the deleted question is the selected question the childquestion should be deleted
                       // if the deleted question is the chhild of selected question remove from childquestionselected  remove from parent children
                       // remove from parent children-common
                      
                       parentNode.childNodes= parentNode.childNodes.filter(quest => quest.nodeData.id !==deletedQuestionId);
                       
                       if(parentNode.childNodes.length==0)
                        {
                         parentNode.nodeData.questionVO.skipLogicFlag='N'
                        }
                       if(isParentNodeSelected=='Y')
                       {               
                         this.childQuestionSelected= this.childQuestionSelected.filter(quest => quest.nodeData.id !==deletedQuestionId);
                        }
                       this.refreshMatTree();       
                       this.expand(parentNode);    
           
                     }
               }      
                        var msg = "Successfully deleted Question";
                 
                        //  swal({
                        //    title: 'Success',
                        //    text: msg,
                        //    type: 'success',
                        //    confirmButtonText: 'Ok',
                        //  })
                        this.addEditSuccessComponent.showSuccessSwal(msg);
              }
      
  
            }
      
            }
          );
    }
    fetchAllSelectedQuestionForTemplate()
    {
        for( let viridiTreeNode of this.assessmentQuestionTemplateVOList)
        {
            this.selectedQuestionIdList.push(viridiTreeNode.nodeData.questionVO.id);
            if(viridiTreeNode.childNodes!=null&& viridiTreeNode.childNodes.length>0)
            {
                this.fetchChildSelected(viridiTreeNode.childNodes)
            }
        }
    }
    fetchAllSelectedPrimaryQuestionForTemplate()
    {
        for( let viridiTreeNode of this.assessmentQuestionTemplateVOList)
        {
            this.selectedPrimaryQuestionList.push(viridiTreeNode.nodeData.questionVO.id);
        }
    }
    fetchChildSelected(ViridiChildNode){
     for( let  childTreeNode of ViridiChildNode)
        {
           this.selectedQuestionIdList.push(childTreeNode.nodeData.questionVO.id);
           this.selectedChildQuestionList.push(childTreeNode.nodeData.questionVO.id);
            if(childTreeNode.childNodes!=null && childTreeNode.childNodes.length>0)
            {
                this.fetchChildSelected(childTreeNode.childNodes)
            }
        }
    }
     
    fetchChildSelectedForParent(ViridiChildNode){
      for( let  childTreeNode of ViridiChildNode)
         {
            this.selectedChildQuestionList.push(childTreeNode.nodeData.questionVO.id);
         }
     } 
     refreshMatTree() {
      this.dataSource.data = null;
      this.dataSource.data = this.assessmentQuestionTemplateVOList;
    }
expand(node)
{
  //this.getResponseOptionsForQuestion(node);
  this.treeControl.expand(node);
}
collapse(node){
 // this.getResponseOptionsForQuestion(node);
  this.treeControl.collapse(node);
  this.treeControl.isExpandable;

} 
  }

