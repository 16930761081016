import { async, ComponentFixture, TestBed } from '@angular/core/testing';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PerformanceTemplateService } from '../performance-template.service';

@Component({
  selector: 'app-search-performance-template',
  templateUrl: './search-performance-template.component.html',
  styleUrls: ['./search-performance-template.component.css']
})
export class SearchPerformanceTemplateComponent implements OnInit {

  templateList: any = [];

  companyTypeList: any;
  loading: boolean;

  @ViewChild("status") status;
  @ViewChild("companyType") companyType;
  @ViewChild("topWrap") topWrap;

  constructor(private templateService: PerformanceTemplateService) {
    this.loading = false;
  }

  ngOnInit() {
    this.loadcompanyTypeList();
  }

  loadcompanyTypeList() {

    this.templateService.getCompanyTypes().subscribe(data => {
      this.companyTypeList = data;

    }, (error) => {
      console.log(error);
    });

  }

  getTemplateListForSearch(companyTypeSelected, statusSelected) {
    this.templateService.getTemplateListForSearch(companyTypeSelected, statusSelected).subscribe(data => {
      this.templateList = data;
      this.loading = false;
    }, (error) => {
      console.log(error);
    });
  }

  onSubmit() {
    this.loading = true;
    let statusSelected = null;
    if (this.status.value) {
      statusSelected = this.status.value
    }
    let companyTypeSelected = this.companyType.value;
    if (!companyTypeSelected) {
      companyTypeSelected = 0;
    }
    this.getTemplateListForSearch(companyTypeSelected, statusSelected);

  }
  resetForm() {
    if (this.topWrap && this.topWrap.nativeElement)
      this.topWrap.nativeElement.classList.remove("top-form-wrapper-highlight");
    this.companyType.value = null;
    this.status.value = null;
    this.getTemplateListForSearch(0, this.status.value);
  }

}
