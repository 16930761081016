import { Component, OnInit, ViewChild } from '@angular/core';
import { IndicatorMasterService } from '../../../indicator-master/indicator-master.service';
import { CustomValidationsService } from '../../../../shared/Validations/custom-validations.service';
import { FormBuilder, FormGroup, Validators, NgForm, AbstractControl, AbstractControlOptions, ValidationErrors, FormGroupDirective } from '@angular/forms';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-indicator-stack',
  templateUrl: './add-indicator-stack.component.html',
  styleUrls: ['./add-indicator-stack.component.css']
})
export class AddIndicatorStackComponent implements OnInit {

  @ViewChild("topWrap") topWrap;
  @ViewChild('indicatorStackForm') indicatorStackForm;
  addIndicatorStackForm: FormGroup;
  category: any;
  subCategories: any;
  subCategorySelected: number;
  topWrapper: any;
  categoryId: any;
  indicatorStack: any;

  constructor(private formBuilder: FormBuilder, private indicatorMasterService: IndicatorMasterService,
    private customValidationsService: CustomValidationsService,
    private addEditSuccessComponent: AddEditSuccessComponent) {
    this.addIndicatorStackForm = this.createFormGroup();
    this.getCategoryList();
  }
  ngOnInit() {
    this.subCategorySelected = 0;
    this.categoryId = 0;
    this.getListOfIndicatorStacks();
    this.topWrapper = this.topWrap;
  }
  getCategoryList() {
    this.indicatorMasterService.getCategories().subscribe(data => {
      this.category = data;
    });
  }
  createFormGroup() {
    return this.formBuilder.group({
      code: ['', [Validators.required, Validators.maxLength(50), this.customValidationsService.noWhitespaceValidator], this.uniqueCodeValidator()],
      name: ['', [Validators.required, Validators.maxLength(1000), this.customValidationsService.noWhitespaceValidator]],
      id: [],
      indicatorCategoryVO: this.formBuilder.group({
        name: [],
        code: [],
        id: ['', [Validators.required]],
      }),
      indicatorSubcategoryVO: this.formBuilder.group({
        name: [],
        code: [],
        id: ['', [Validators.required]],
      })
    }, { updateOn: 'blur' })

  }
  getSubCategories(categorySelected) {
    if (categorySelected) {
      this.indicatorMasterService.getSubCategories(categorySelected).subscribe(data => {
        this.subCategories = data;
      }, (error) => {
        console.log(error);
      })
    }
  }
  onCategoryChange(categoryId) {
    this.categoryId = categoryId;
    if (categoryId != 0) {
      this.getSubCategories(this.categoryId);
      this.addIndicatorStackForm.get('indicatorSubcategoryVO').get('id').setValue(null);
    }
  }
  subCategoryIdChange() {
    this.subCategorySelected = this.addIndicatorStackForm.get('indicatorSubcategoryVO').get('id').value;
  }
  onSubmit() {

    if (this.addIndicatorStackForm.valid) {
      this.indicatorMasterService.createIndicatorStack(this.addIndicatorStackForm.value).subscribe((myform) => {
        if (this.addIndicatorStackForm.get('id').value != null)
          var message = "Successfully Edited Indicator Stack";
        else
          var message = "Successfully Added Indicator Stack";
        this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
          this.resetIndStackForm();
        });
      }, (error) => {
        console.log(error);
      });
    }
  }
  defaultValues(p) {
    this.getSubCategories(p.categoryId);
    this.addIndicatorStackForm.patchValue({
      id: p.id,
      code: p.code,
      name: p.name,
      indicatorCategoryVO: {
        id: p.categoryId,
      },
      indicatorSubcategoryVO: {
        id: p.subCategorySelected,
      }
    })
  }
  resetIndStackForm() {
    this.indicatorStackForm.resetForm();
    this.subCategorySelected = 0;
    this.categoryId = 0;
    if (this.topWrap && this.topWrap.nativeElement) {
      this.topWrap.nativeElement.classList.remove("top-form-wrapper-highlight");
    }
    this.getListOfIndicatorStacks();
  }

  getListOfIndicatorStacks() {
    this.indicatorStack = [];
    this.indicatorMasterService.getListOfIndicatorStacks().subscribe(data => {
      this.indicatorStack = data;
    });
  }

  uniqueCodeValidator() {
    return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      var id = 0
      if (this.addIndicatorStackForm && this.addIndicatorStackForm.controls.id.value) {
        id = this.addIndicatorStackForm.controls.id.value;
      }

      return this.indicatorMasterService.checkIndicatorStackCodeValidation(id, c.value).pipe(
        map(val => {
          if (val == 1) {
            return { 'uniqueCode': true };
          }
        })
      );
    };

  }

  uniqueNameValidator() {
    return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      var id = 0
      if (this.addIndicatorStackForm && this.addIndicatorStackForm.controls.id.value) {
        id = this.addIndicatorStackForm.controls.id.value;
      }
      return this.indicatorMasterService.checkIndicatorStackNameValidation(id, c.value).pipe(
        map(val => {
          console.log(val)
          if (val == 1) {
            return { 'uniqueName': true };
          }
        })
      );
    };


  }

}
