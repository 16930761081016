import { Component, OnInit, ElementRef } from '@angular/core';
import { SlideInOutAnimation } from '../../../../assets/animation/animation';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [SlideInOutAnimation],
  host: {
    '(document:click)': 'handleClick($event)',
  },
})
export class HeaderComponent implements OnInit {
  animationState = 'out';
  showMenu: any = [];
  modalRef: BsModalRef;
  modalService: BsModalService;
  elementRef: any;
  menuArrow:boolean=false;
  constructor(myElement: ElementRef , public myapp: AppComponent) {
    this.elementRef = myElement;
  }

  ngOnInit() {
  }
  showAlert(alertStr) {
    const list = [];

    list.push(alertStr);
    const config = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: true,
      windowClass: 'print-modal-window'
    };
    // this.modalRef= this.modalService.show(this.menu);
  }
  showMenuValues(menuID) {
    this.showMenu[menuID] = !this.showMenu[menuID];
  }
  handleClick(event) {
    var clickedComponent = event.target;
    var inside = false;
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
      }
      clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if (inside) {
      // console.log("Inside");
    } else {
      this.animationState = 'out';
      this.menuArrow=false;
    }
  }
  removeArrow(){
    this.menuArrow=false;
  }
  
  showArrow(){
    this.menuArrow=true;
  }
   signOut() {
    this.myapp.signOut();
    
  }
}
