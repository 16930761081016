import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { BaseUrlService } from './base-url.service';
@Injectable({
  providedIn: 'root'
})
export class GenerateSchedulesService {


  frequencyList: any = [];
  private baseUrl = BaseUrlService.PERFORMANCEFRAMEWORKBASEURL;
  private indicatorWrapperUrl = BaseUrlService.INDICATORBASEURL;
  dateList: any;
  startYearList: any = [];

  constructor(private http: HttpClient) { }

  getSourceRequestVO() {
    let sourceRequestVO = {};
    sourceRequestVO['constants'] = true;
    sourceRequestVO['constantLabel'] = "INDICATOR_SOURCE";

    return sourceRequestVO;
  }
  getFrequencyRequestVO() {
    let frequencyRequestVO = {};
    frequencyRequestVO['constants'] = true;
    frequencyRequestVO['constantLabel'] = "INDICATOR_FREQUENCY";

    return frequencyRequestVO;
  }
  getYearList() {

    let currentYear = (new Date()).getFullYear();
    let startYear = 2010
    this.startYearList =[];

    for (let i = startYear; i <= (currentYear + 10); i++) {
      this.startYearList.push(i);
    }

    return this.startYearList;
  }
  getDateList(frequencyOfMeasurement) {

    if (frequencyOfMeasurement == "MONTHLY") {
      this.dateList = {
        1: 'January', 2: 'February', 3: 'March', 4: 'April', 5: 'May', 6: 'June', 7: 'July',
        8: 'August', 9: 'September', 10: 'October', 11: 'November', 12: 'December'
      };

    }
    else if (frequencyOfMeasurement == "QUARTERLY") {
      this.dateList = { 1: 'Q1', 2: 'Q2', 3: 'Q3', 4: 'Q4' };
    }
    else if (frequencyOfMeasurement == "HALF YEARLY") {
      this.dateList = { 1: 'HY1', 2: 'HY2' };
    }
    else {
      this.dateList = null;
    }
   
    return this.dateList;
  }

  getIndicatorMasterData(indicatorRequestVO): Observable<any> {

    let url = this.indicatorWrapperUrl + '/getIndicatorMasterData';
    let body = JSON.stringify(indicatorRequestVO);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);
  }
  generateSchedules(schedules): Observable<any> {

    let body = JSON.stringify(schedules);
    let url = this.baseUrl + '/generateSchedules';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);

  }

}
