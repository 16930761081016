import { Component, OnInit } from '@angular/core';
import { AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import { Router,ActivatedRoute } from '@angular/router';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatDialog, MatDialogConfig, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SearchQuestionsComponent } from '../search-questions/search-questions.component';
import { AddMoreQuestionsComponent } from '../add-more-questions/add-more-questions.component';
import swal from 'sweetalert2';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AddEditSuccessComponent } from '../../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
interface QuestionNode {
  id: number;
  name: string;
  templateQuestionId:number;
  childFlag:boolean;
  children?: QuestionNode[];
}
@Component({
  selector: 'app-edit-assessment-template-questions',
  templateUrl: './edit-assessment-template-questions.component.html',
  styleUrls: ['./edit-assessment-template-questions.component.css']
})
export class EditAssessmentTemplateQuestionsComponent implements OnInit {
  selectionquestionsJsonObj: any;
  id: any;
  questionList: any;
  selectedParentQuestion: any;
  templateData:any = [];
  treeControl = new NestedTreeControl<QuestionNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<QuestionNode>();
  data: any = [];
  childQuestionSelected: any;
  dialogConfig = new MatDialogConfig();
  selectedQuestionNode: any;
  selectedQuestions: string;
  responseOptions: any;
  templateId: any;
  templateForm: FormGroup;
  constructor(private addEditSuccessComponent: AddEditSuccessComponent,private assessementService:AssessmentTemplateMasterdataService,  private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,  private router: Router,private _fb: FormBuilder) {    this.templateForm = this.createFormGroup();    }
  
    ngOnInit() {
      this.activatedRoute.params.subscribe(params => { this.templateId = params.templateId }); 
      this.assessementService.editTemplateDetailsById(this.templateId).subscribe((data) => {
        this.templateData = data;
        let count=1;
        let childArray:any;
        for (let question of this.templateData.questionVOList) {
          if(question.skipLogicFlag=='N')
          {
            childArray=[];
          }
          else
            childArray=[{'childFlag':question.skipLogicFlag}]
            this.data.push({ 'id': question.id, 'name': question.questionText, 'primaryQuestionFlag': 'Y' ,
            'skipLogicFlag':question.skipLogicFlag, 'responseOptionsVOList':question.responseOptionsVOList,'count':count,'children':childArray});
            this.dataSource.data = this.data;
            this.selectionquestionsJsonObj =  this.data;
            count++;
        }
    this.templateForm.controls['templateName'].setValue(this.templateData.templateName);
    this.loadResponseOptionOnLoad(this.data[0]);
    }, (error) => {
      console.log(error);
    });
    }
    createFormGroup(){
      return this._fb.group({
        id:0,
        templateName:['', Validators.compose([Validators.required])],
      })
    }
    loadResponseOptionOnLoad(question: any)
    {
      this.getResponseOptionsForQuestion(question);
    
    }
    hasChild = (_: number, node: QuestionNode) => !!node.children && node.children.length > 0;

  displaySubQuestion(node) {
    
    this.selectedParentQuestion = {};
    let index = this.data.indexOf(node);
    let children: any = [];
    let childQuestions;
    let childArray:any;
    if (this.templateId > 0) {

      this.assessementService.getChildQuestions(node.id, this.templateId).subscribe(data => {
        childQuestions = data;
        let count=1;

        for (let question of childQuestions) {
       
          if(question.skipLogicFlag=='N')
          {
          childArray=[];
          }
          else
          childArray=[{'childFlag':question.skipLogicFlag}]
          children.push({ 'id': question.id, 'name': question.questionText, 'primaryQuestionFlag': 'N','count':count,'children':childArray });
        
          this.id = question.id;
          this.selectedParentQuestion['id'] = question.id;
          this.selectedParentQuestion['Question'] = question.name;
          this.selectedParentQuestion['primaryQuestionFlag'] = question.primaryQuestionFlag;
          this.selectedParentQuestion['criticalFlag'] = 'N';
          count++;

        }
        node['children'] = children;
        this.dataSource.data = null;
        this.dataSource.data = this.data;
        this.treeControl.expand(node);


      })
    }
  }
      addMoreQuestion() {
      var qstnIdList = [];
  //to remove child question on the main list
      for (let i of this.dataSource.data) {
        if(i.children != null){
          this.getChildIdList(i.children,qstnIdList);
        }
        qstnIdList.push(i.id);
      }
  
  
  
      this.dialogConfig.disableClose = true;
      this.dialogConfig.width = "80%";
  
      this.dialogConfig.data = { idList: qstnIdList, selectedQuestion: this.templateData.questionVOList };
      const dialogRef = this.dialog.open(AddMoreQuestionsComponent, this.dialogConfig);
  
      dialogRef.afterClosed().subscribe(data => {
       if(data!=undefined){
          this.selectedQuestions=data.questionSelectedObjs;
          this.templateData.questionVOList = JSON.parse(this.selectedQuestions);
  
          for (let question of this.templateData.questionVOList) {
            this.data.push({ 'id': question.id, 'name': question.Question, 'primaryQuestionFlag': 'Y' ,'criticalFlag':'N','skipLogicFlag':'N'});
            this.dataSource.data = this.data;
          }
          this.selectionquestionsJsonObj=this.data;
        }
  
      });
  
  
    }
    
    getChildIdList(child,qstnIdList) {
      for(let i of child){
        qstnIdList.push(i.id);
        if(i.children!=null){
          this.getChildIdList(i.children,qstnIdList);
        }
      }
    }

 //onclick of skip logic checkbox-> to show the response options for question
 getResponseOptionsForQuestion(question: any) {

  this.childQuestionSelected = [];
  this.selectedParentQuestion = {};
  // this.activeElement = question.id;
  this.assessementService.getResponseOptionsForQuestion(question.id).subscribe(data => {
    this.responseOptions = data;
    let childQuestions;
    if (this.templateId > 0) {
    this.assessementService.getChildQuestions(question.id, this.templateId).subscribe(data => {
      childQuestions = data;
  
      if(this.responseOptions.length>0)
      {
        let responseIds = this.responseOptions.map(item => item.id);
        for (let child of childQuestions) {
          var newArray = {};
          newArray['id'] = child.id;
          newArray['Question'] = child.questionText;
          newArray['ResponseOptionId'] = child.responseOptionVO.id;
          for (let option of this.responseOptions) {
            if (child.responseOptionVO.id== option.id) {
              newArray['index'] = responseIds.indexOf(option.id);
              this.childQuestionSelected.push(newArray);
            }
          }
    
        }
      }

   
      // for (let question of childQuestions) {

      //   children.push({ 'id': question.id, 'name': question.questionText, 'primaryQuestionFlag': 'N' ,'count' : count });
      //   this.id = question.id;
       
      // }
    })
  }
    
 
    this.id = question.id;
    this.selectedQuestionNode = question;
    this.selectedParentQuestion['id'] = question.id;
    this.selectedParentQuestion['Question'] = question.name;
    this.selectedParentQuestion['primaryQuestionFlag'] = question.primaryQuestionFlag;
    this.selectedParentQuestion['criticalFlag'] = 'N';
  })



}


   //skip question for option
   addSubQuestionForOption(questionSelected) {
    this.selectedParentQuestion.skipLogicFlag = 'Y';

    this.selectedParentQuestion.questionId = questionSelected.id;
    let responseOptinVO = {};
    var responseOptionJsonObj: any;
    let childQuestionVOList = [];
    let responseOptionVOList = [];
    for (let i of questionSelected) {
      let childQuestionVO = {};
      childQuestionVO['id'] = i.id;
      childQuestionVO['skipLogicFlag'] = 'N';
      childQuestionVO['primaryQuestionFlag'] = 'N';
      childQuestionVO['criticalFlag'] = 'N';
      childQuestionVO['Question'] = i.Question;
      if (responseOptionVOList.length > 0) {
        let responseIds = responseOptionVOList.map(item => item.id);

        if (responseIds.filter(obj => obj === i.ResponseOptionId).length > 0) {
          childQuestionVOList.push(childQuestionVO);

        }
        else {
          childQuestionVOList = [];
          responseOptinVO = {};
          responseOptinVO['id'] = i.ResponseOptionId;
          childQuestionVOList.push(childQuestionVO);
          responseOptinVO['questionVOList'] = childQuestionVOList;
          responseOptionVOList.push(responseOptinVO);
        }

      }
      else {
        childQuestionVOList = [];
        responseOptinVO = {};
        responseOptinVO['id'] = i.ResponseOptionId;
        childQuestionVOList.push(childQuestionVO);
        responseOptinVO['questionVOList'] = childQuestionVOList;
        responseOptionVOList.push(responseOptinVO);
      }
    }


    this.selectedParentQuestion.responseOptionsVOList = responseOptionVOList;
    

    if (this.selectedParentQuestion['primaryQuestionFlag'] == 'Y') {
      this.selectionquestionsJsonObj = this.selectionquestionsJsonObj.filter(obj => obj.id != this.selectedParentQuestion.id);
      this.selectionquestionsJsonObj.push(this.selectedParentQuestion);
      this.onSaveOrSubmit('Y');
    }
    else {
      for (let primaryQuestionVO of this.selectionquestionsJsonObj) {
      
        if(primaryQuestionVO.responseOptionsVOList!=undefined){
        if (primaryQuestionVO.responseOptionsVOList != null && primaryQuestionVO.responseOptionsVOList.length > 0) {
          this.searchForQuestionVO(this.selectedParentQuestion, primaryQuestionVO );
        }
      }


      }

    }
   

  }
  searchForQuestionVO(selectedQnObj, questionVO) {

    let responseOptionId;
    let questionExist;
    if(questionVO.responseOptionsVOList!=undefined){
    for (let responseOptionVO of questionVO.responseOptionsVOList) {
      responseOptionId = responseOptionVO.id;
      if(responseOptionVO.questionVOList!=undefined){
      if (responseOptionVO.questionVOList != null && responseOptionVO.questionVOList.length > 0) {

        questionExist = selectedQnObj => responseOptionVO.questionVOList.some(({ questionObj }) => questionObj == selectedQnObj)

        if (questionExist) {

          responseOptionVO.questionVOList = responseOptionVO.questionVOList.filter(obj => obj.id != selectedQnObj.id);
           responseOptionVO.questionVOList.push(this.selectedParentQuestion);
           this.onSaveOrSubmit('Y');
       }
        else {

          for (let questionVO of responseOptionVO.questionVOList) {
            this.searchForQuestionVO(selectedQnObj, questionVO);
          }
        }
      }
    }
    }
  }
  }



  onSaveOrSubmit(saveFlag) {
    if (this.templateForm.valid) {

    let message;
    let templateVO = {};
    templateVO['saveFlag'] = 'N';
    if (saveFlag == 'Y') {
      //templateVO['saveFlag'] = 'Y';
      message = "Successfully Saved The Template";
    }

    else {
     // templateVO['saveFlag'] = 'N';
      message = "Successfully Updated The Template";
    }

    templateVO['id'] = this.templateId;
    templateVO['templateName'] = this.templateForm.get('templateName').value;


    let questionVOList = [];

    for (let question of this.selectionquestionsJsonObj) {
      let questionVO = {};
      questionVO['id'] = question.id;
      questionVO['primaryQuestionFlag'] = question.primaryQuestionFlag;
      if(question.responseOptionsVOList==undefined)
      questionVO['skipLogicFlag']='N';
      else
      questionVO['skipLogicFlag'] = question.skipLogicFlag;
      if (question.skipLogicFlag = 'Y')
      {
         questionVO['responseOptionsVOList'] = question.responseOptionsVOList;
      }
      else
        questionVO['responseOptionsVOList'] = null;
      questionVO['criticalFlag'] = 'N';
      questionVOList.push(questionVO);

    }
   
    templateVO['questionVOList'] = questionVOList;
    this.questionList = questionVOList;
    this.assessementService.createTemplate(templateVO).subscribe(templateId => {
      
      // swal({
      //   title: 'Success!',
      //   text: message,
      //   type: 'success',
      //   confirmButtonText: 'Ok'
      // })
      this.addEditSuccessComponent.showSuccessSwal(message)
        .then((value) => {

          
          let objToRefreshMatTree = {};

          objToRefreshMatTree['templateFormValue'] = this.templateData;
          objToRefreshMatTree['questionSelectedObjs'] = this.selectionquestionsJsonObj;
          objToRefreshMatTree['refreshFlag'] = true;
          objToRefreshMatTree['templateId'] = this.templateId;
          this.dataSource.data = this.data;
          if(this.selectedQuestionNode!=undefined)
          this.displaySubQuestion(this.selectedQuestionNode) ;
          if(saveFlag=='Y')
          this.router.navigate(['/editTemplateData/'+ this.templateId], { queryParams: objToRefreshMatTree, skipLocationChange: true });
          else
          this.router.navigate(['/listTemplate']);
        });

    })
  }
  }
  showQuestionPopup(index, questionSelected, responseOptionId) {
    var qstnIdList = [];
//the same questions will not get added as sub question for another parent question
    // for (let i of this.dataSource.data) {
    //   if(i.children != null){
    //     this.getChildIdList(i.children,qstnIdList);
    //   }
    //   qstnIdList.push(i.id);
    // }

    for (let i of this.selectionquestionsJsonObj) {
      qstnIdList.push(i.questionId);
    }

    this.dialogConfig.disableClose = true;
    this.dialogConfig.width = "80%";

    this.dialogConfig.data = { id: this.id, idList: qstnIdList, selectedQuestion: questionSelected, index: index, responseOptionId: responseOptionId };
    const dialogRef = this.dialog.open(SearchQuestionsComponent, this.dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.childQuestionSelected = data;

    });

  }
  resetForm(questionSelected) {
    this.childQuestionSelected = [];
  }
  collapse(node){
	  this.treeControl.collapse(node);
  } 

  removeQuestion(questionObj) {
    swal({
      title: '<p class="swal2-title-warning">Are You Sure?</p>',
      text: "You Want To Delete Sub Question",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'

    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.childQuestionSelected = this.childQuestionSelected.filter(order => order.id !== questionObj.id);
        var msg = "Successfully deleted Question";
        // swal({
        //   title: 'Success',
        //   text: msg,
        //   type: 'success',
        //   confirmButtonText: 'Ok',
        // })
       this.addEditSuccessComponent.showSuccessSwal(msg);


      }
    });
  }
  removePrimaryQuestion(questionObj)
  {
    swal({
      title: '<p class="swal2-title-warning">Are You Sure?</p>',
      text: "You Want To Delete Question",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'

    }).then((isConfirm) => {
      if (isConfirm.value) {

        if (this.templateId > 0)
        {
        this.assessementService.deleteTemplateQuestion( this.templateId,questionObj.id).subscribe(data => {
         
        })
      }
              var msg = "Successfully deleted Question";

          this.data =   this.dataSource.data.filter(data => data.id !== questionObj.id); 
 

          if(questionObj.id==this.selectedQuestionNode.id)// if the deleting question is the active question
          {
            this.childQuestionSelected = [];
            this.responseOptions=[];
          }  
          this.dataSource.data = null;
          this.dataSource.data = this.data;
          this.selectionquestionsJsonObj = this.data;
       
        // swal({
        //   title: 'Success',
        //   text: msg,
        //   type: 'success',
        //   confirmButtonText: 'Ok',
        // })
        this.addEditSuccessComponent.showSuccessSwal(msg);


      }
    });
  }
}