import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ConfigurationService } from '../configuration.service';
import {MatPaginator} from '@angular/material';
import {MatSort, MatTableDataSource} from '@angular/material';
import { NavigationExtras, Router } from '@angular/router';
import { ParametersService } from '../../../modules/report/ParameterDashboard/parameters.service';
import {CompanyService} from '../../report/CompanyDashboard/company.service';

@Component({
  selector: 'app-list-company-view',
  templateUrl: './list-company-view.component.html',
  styleUrls: ['./list-company-view.component.css']
})
export class ListCompanyViewComponent implements OnInit {
 
  listViewCompanyForm:any;
  yearList:any;
  monthList =["January","February","March","April","May","June","July","August","September","October","November","December"];
  listingStatus=["Yes","No"]
  countryList:any=[];
  displayedColumns: any;
  originalColumnNames : any;
  displayedColumnsForTaxonomy: any;
  originalColumnNamesForTaxonomy : any;
  displayedColumnsForFinancials: any;
  originalColumnNamesForFinancials : any;
  displayedColumnsForSustainable: any;
  originalColumnNamesForSustainable : any;
  displayedColumnsForAssetDetails:any;
  originalColumnNamesForAssetDetails:any;
  jsonArrayForAssetDetails:any=[];
  jsonArray: any=[];
  jsonArrayForTaxonomy:any=[]
  jsonArrayForFinancials:any=[]
  jsonArrayForSustainable:any=[]
  dataSource: any ;
  paginator:any;
  companyDetails=[]
  public companyESGDetails:any;
  financialList:any=[]
  taxonomyList:any=[]
  financialFlag:any;
  taxonomyFlag:any;
  metlifeFlag:any;
  defaultYear:'2023';
  @ViewChild('matSort') sort: MatSort;
  companyList: any;
  metlifeList: any[];
  public dataSourcePopUpAsset :any = [];
  public displayedColumnsAsset =[];
  public showIcons=false;
  
 
  @ViewChild('MatPaginator') set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
}
  @ViewChild("topWrap") topWrap;
  @ViewChild("primaryDataForm") viewCompanyForm;
  constructor(private companyService:CompanyService,private _parametersService: ParametersService,private formBuilder: FormBuilder, private configurationService: ConfigurationService,private router:Router) {
    this.listViewCompanyForm = this.createFormGroup();
   }

  ngOnInit() {
    //this.onRegionChange(2);
    this.getCompanyList();
    // let year=2010;
    // let currentYear=new Date().getFullYear()
    // this.yearList=[]
    // for(let i=year;i<=currentYear;i++)
    // {
    //   this.yearList.push(i);
    // }

    // this.configurationService.getListOfCountries().subscribe((data) =>
    // {
    //   console.log(data)
    //   //this.countryList=data
    //   this.onRegionChange(1);
    //   let year=2010;
    //   let currentYear=new Date().getFullYear()
    //   this.yearList=[]
    //   for(let i=year;i<=currentYear;i++)
    //   {
    //     this.yearList.push(i);
    //   }
    // })

    
    // this.financialList=["Non FI","Y","173000","147500","238895","Y"]
    this.financialList=[{2022:"Non FI",2021:"Non FI"},
    {2022:"Y",2021:"Y"},
    {2022:"173000",2021:"166090"},
    {2022:"147500",2021:"123500"},
    {2022:"238895",2021:"216695"},
    {2022:"Y",2021:"Y"}]
this.taxonomyList=[4.5,7.5,0.5,1,7.5,4,1,1];
this.metlifeList=[{2022:"Y",2021:"Y"},
{2022:"94",2021:"94"}]
    // this.dataTablePopulation(this.companyList)
   
  }
  getCompanyList() {
    this.configurationService.getListOfCompanies().subscribe(data => {
      this.companyList = data;
      console.log("this company list")
      console.log(this.companyList)
      this.dataTablePopulation(this.companyList)
    }, (error) => {
      console.log(error);
    });
  }
  getAssetDetails(element){
    console.log(element);
    this.configurationService.getCompanyAssetDetails(element.id).subscribe(data => {
     let  assetDetails = data;
      console.log("this Asset details")
      console.log(assetDetails)
      this.assetDetailsDataTablePopulation(assetDetails)
    }, (error) => {
      console.log(error);
    });
  }
  assetDetailsDataTablePopulation(assetDetails){
    this.displayedColumnsForAssetDetails = [];
    this.originalColumnNamesForAssetDetails = [];
    this.jsonArrayForAssetDetails = [];

    var count = 0;
    for (let i of assetDetails) {
      var newArray = {};
      newArray['Sno'] = ++count;
      newArray['id']=i.id;
      newArray['metlife_assetid']=i.metlife_assetid
      // newArray['cusip'] = i.cusip;
      // newArray['isin'] = i.isin;
      // newArray['sedol'] = i.sedol;
      // newArray['asset_desc'] =i.asset_desc===''?'--':i.asset_desc;
      newArray['core_sector_level1code'] = i.core_sector_level1code
      newArray['domicile_country_code']=i.domicile_country_code
      newArray['risk_country_code']=i.risk_country_code
      newArray['market_value_usd']=((i.market_value_usd)/1000000).toFixed(2) 
      newArray['financed_emission']=i.financed_emission;
      newArray['core_sector_level2code']=i.core_sector_level2code
      newArray['core_sector_level3code']=i.core_sector_level3code
      newArray['core_sector_level4code']=i.core_sector_level4code
      newArray['data_quality']=i.data_quality===''?'--':i.data_quality
      newArray['equator_principal']=i.equator_principal===''?'--':i.equator_principal
      newArray['msff']=i.msff===''?'--':i.msff
      
      this.jsonArrayForAssetDetails.push(newArray);
   }
    this.displayedColumnsForAssetDetails = ['Sno','metlife_assetid','core_sector_level1code','domicile_country_code','risk_country_code','market_value_usd','financed_emission','core_sector_level2code','core_sector_level3code','data_quality','equator_principal','msff']  
    this.originalColumnNamesForAssetDetails = ['Sl.No','MetLife Asset-Id','Core Sector Level1 Code','Domicile Country Code','Risk Country Code','MarketValue USD','Financed Emission','Core Sector level2code','Core Sector level3code','Data Quality','Equator Principal','MSFF']
    // this.displayedColumnsForAssetDetails = ['Sno','metlife_assetid','cusip','isin', 'sedol','asset_desc','core_sector_level1code','domicile_country_code','risk_country_code','market_value_usd','core_sector_level2code','core_sector_level3code','data_quality','financed_emission','equator_principal','msff']  
    // this.originalColumnNamesForAssetDetails = ['Sl.No','MetLife Asset-Id','CUSIP','ISIN','SEDOL','Asset Desc','Core Sector Level1 Code','Domicile Country Code','Risk Country Code','MarketValue USD','Core Sector level2code','Core Sector level3code','Data Quality','Financed Emission','Equator Principal','MSFF']
 
  }
  createFormGroup()
  {
    return this.formBuilder.group({
      country:[158],
      listingStatus:["Yes"]
    });
  }
  dataTablePopulation(companyDetails) {
    this.displayedColumns = [];
    this.originalColumnNames = [];
    this.jsonArray = [];
 
    var count = 0;
    for (let i of companyDetails) {
      var newArray = {};
     
      newArray['Sno'] = ++count;
      newArray['id']=i.id;
      newArray['CompanyName']=i.companyName
      newArray['LEI'] = i.lei;
      newArray['FINonFI'] = i.FINFI;
      newArray['ISIN'] = i.isin;
      // newArray['ParentCompany'] = i.parentCompanyVO.companyName;
      newArray['ParentLei'] = i.parentLEI

      newArray['Top20Flag']=i.TopFlag
      newArray['NFRDFlag']=i.NFRDFLag
      newArray['FinancialTaxonomyInfo']=''
      newArray['ExposureDetails']=''
      newArray['DataCapture']=''
      newArray['ESGInfo']=''
      this.jsonArray.push(newArray);
   }
    this.displayedColumns = ['Sno','CompanyName','LEI','ISIN','FinancialTaxonomyInfo','ESGInfo','ExposureDetails','DataCapture']
    this.originalColumnNames = ['Sl.No','Company Name','LEI','ISIN','Financial/Taxonomy Info','ESG Info','Exposure Details','Data Capture']
    this.dataSource=[];
    this.dataSource = new MatTableDataSource(this.jsonArray);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }  

  onSubmit()
  {
    console.log(this.listViewCompanyForm.value)
  }
  toggleFinancialTaxonomyInfo(flag)
  {
    if(flag=='F')
    {
      this.financialFlag=true;
      this.taxonomyFlag=false;
      this.metlifeFlag=false;
    }
    else if(flag=='T'){
      this.financialFlag=false;
      this.taxonomyFlag=true;
      this.metlifeFlag=false;
    }
    else if(flag=='M'){
      this.financialFlag=false;
      this.taxonomyFlag=false;
      this.metlifeFlag=true;
    }
    this.setTaxonomyAndFinancialDetails(flag)
  }
  setTaxonomyAndFinancialDetails(flag)
  {
    this.displayedColumnsForTaxonomy = [];
    this.originalColumnNamesForTaxonomy = [];
    this.displayedColumnsForFinancials=[]
    this.originalColumnNamesForFinancials=[];
    this.displayedColumnsForSustainable=[];
    this.originalColumnNamesForSustainable=[];
    this.jsonArrayForFinancials = [];
    this.jsonArrayForTaxonomy=[]
    this.jsonArrayForSustainable=[]
    if(flag=='F'){
    var count = 0;
    var index=0;
    this.originalColumnNamesForFinancials= ['FI / Non FI', 'Listing Status', 'Employee Count','Turnover(In MEuro)','Total Assets(In MEuro)','NFRD']
    for (let i of this.financialList) {
      var newArray = {};
      newArray['Sno'] = ++count;
      newArray['heading'] =this.originalColumnNamesForFinancials[index]
      newArray['value'] = i[2022];
      newArray['value1'] = i[2021];
      index++;
     this.jsonArrayForFinancials.push(newArray)
    }
    this.displayedColumnsForFinancials = ['Sno','heading','value','value1']
    this.originalColumnNamesForFinancials= ['Sl.No',"Financial Info","2022","2021"]
  }
  else if(flag=='T'){
    var count = 0;
    index=0;
    this.originalColumnNamesForTaxonomy= ['EU Taxonomy Mitigation Eligible Turnover Percentage',
    'EU Taxonomy Mitigation Eligible Capex Percentage', 
    'EU Taxonomy Mitigation Alignment Turnover Percentage',
    'EU Taxonomy Mitigation Alignment Capex Percentage',
    'EU Taxonomy Adaption Eligible Turnover Percentage',
    'EU Taxonomy Adaption Eligible Capex Percentage',
    'EU Taxonomy Adaption Alignment Turnover Percentage',
    'EU Taxonomy Adaption Alignment Capex Percentage',
    ]
    for (let i of this.taxonomyList) {
      var newArray = {};
      newArray['heading'] =this.originalColumnNamesForTaxonomy[index];
      newArray["value"]=i
      newArray['Sno'] = ++count;
      index++;
      this.jsonArrayForTaxonomy.push(newArray);
    }

    this.displayedColumnsForTaxonomy = ['Sno','heading','value']
    this.originalColumnNamesForTaxonomy= ['Sl.No',"Taxonomy Info","Value"]
  }
  else if(flag=='M'){
    var count = 0;
    var index=0;
    this.originalColumnNamesForSustainable= ['Eligibility for Metlife Sustainable Finance', '% Revenue from Clean activities']
    for (let i of this.metlifeList) {
      var newArray = {};
      newArray['Sno'] = ++count;
      newArray['heading'] =this.originalColumnNamesForSustainable[index]
      newArray['value'] = i[2022];
      newArray['value1'] = i[2021];
      index++;
     this.jsonArrayForSustainable.push(newArray)
    }
    this.displayedColumnsForSustainable = ['Sno','heading','value','value1']
    this.originalColumnNamesForSustainable= ['Sl.No',"Information","2022","2021"]
  }
  else{
    
  }
  console.log("this.jsonArrayForFinancials")
  console.log(this.jsonArrayForSustainable)
}
gotoperformancerecord(id){
  let navigationExtras: NavigationExtras = {

    queryParams: {

                  "flag" : 'N',
                  "companyId":id
                },

    skipLocationChange: true

  };

  this.router.navigate(['/companyPerformanceRecord'],navigationExtras)
 
}
onRegionChange(parameterId)

{

 this.countryList=[]

 console.log("paremtersss region s")

  console.log(parameterId)

  this._parametersService.getParameterLevelDetails(1,parameterId).subscribe((parameterLevel)=>{

    console.log("countries")

    console.log(parameterLevel[0].parametersVO)

    for(let i=0 ; i<parameterLevel[0].parametersVO.length;i++){

      let newObj={}

      newObj["id"]=parameterLevel[0].parametersVO[i].id

      newObj["name"]=parameterLevel[0].parametersVO[i].parameterName

      this.countryList.push(newObj);

    }

   

  },(error)=>{

    console.log(error);

})

}
  gotoCompanyDashboard(companyName) {
    this.companyService.getAllCompanies().subscribe((companyDetails) => {
      this.companyList = companyDetails;
     let companyId = this.companyList.filter(obj => obj.companyName == companyName);
      if (companyId[0].id) {
        this.companyService.getCompanyRatingAgencyDetails(companyId[0].id).subscribe((result) => {
          if (result) {
            this.companyESGDetails = result;
            let parameterVO = this.companyESGDetails.parameterVO;
            let region;
            let country;
            let sector;
            let subsector;
            for (let companyParameters of parameterVO) {
              var str = companyParameters.parameterList;
              str = str.replace(/"/g, '');
              str = str.replace(/{/g, '');
              str = str.replace(/}/g, '');
              if (companyParameters.parameterName == "Region")
                region = str;
              if (companyParameters.parameterName == "Sector")
                sector = str;
              if (companyParameters.parameterName == "Country")
                country = str;
              if (companyParameters.parameterName == "Sub Sector")
                subsector = str;
            }


            const url = this.router.serializeUrl(this.router.createUrlTree(['/performanceManagement/dashboard/companyDetails'], {
              queryParams: {
                "name": this.companyESGDetails.companyName,
                "id": this.companyESGDetails.id,
                "image": this.companyESGDetails.companyImgFileName,
                "sector": sector,
                "subSector": subsector,
                "region": region,
                "subRegion": country,
                "environment": this.companyESGDetails.environment,
                "social": this.companyESGDetails.social,
                "governance": this.companyESGDetails.governance,
                "overallEsgQuotient": this.companyESGDetails.overallEsgQuotient,
                "esgColorStatus": this.companyESGDetails.esgColorStatus,
                "revenue": this.companyESGDetails.revenue
              }
            }));
            window.open(url, '_blank');
            //window.open(url,'_blank')
          }
          else {
            this.companyESGDetails = null;
          }

        });
      }
    });
  }
downloadCompanyList(){
  let link = document.createElement("a");
        link.download = "CompanyList.csv";
        link.href = "assets/CompanyList.csv";
        link.click();
        link.remove();
}
getAssetForCompanyPopUp(assetDetailsOfCompany: any){
  console.log("assetDetailsOfCompany");
  console.log(assetDetailsOfCompany);
  this.dataSourcePopUpAsset = [];
  this.displayedColumnsAsset =[];
  // this.jsonArrayAsset=[];
  //console.log("compnayid"+companyId);
  this.displayedColumnsAsset = ['Sno','metlife_assetId',  'core_sector_level1Code', 'core_sector_level2Code','description','domicile_country_code','risk_country_code','market_value_USD','financed_emission','data_quality','year','equator_principal','msff']
  // let sno = 1;
  // for (let asset of assetDetailsOfCompany) {
  //   var newArray = {};
  //   newArray['Sno'] = sno;
  //   newArray['metlife_assetId'] = asset.metlife_assetid;
  //   newArray['cusip'] = asset.cusip;
  //   newArray['isin'] = asset.isin;
  //   newArray['sedol'] = asset.sedol;
  //   newArray['asset_desc'] = asset.asset_desc;
  //   newArray['core_sector_level1Code'] = asset.core_sector_level1code;
  //   newArray['core_sector_level2Code'] = asset.core_sector_level2code;
  //   newArray['description'] = asset.description;
  //   newArray['domicile_country_code'] = asset.domicile_country_code;
  //   newArray['risk_country_code'] = asset.risk_country_code;
  //   newArray['market_value_USD'] = asset.market_value_usd;
  //   newArray['financed_emission']=asset.financed_emission;
  //   newArray['data_quality']=asset.data_quality;
  //   newArray['year']=asset.year;
  //   newArray['equator_principal']=asset.equator_principal;
  //   if(asset.metlife_assetid=="247361ZN1")
  //   newArray['msff']='Yes';
  //   else{
  //     newArray['msff']='No';
  //   }
   
 
  //   this.jsonArrayAsset.push(newArray);
  //   console.log("json->")
  //   console.log(this.jsonArrayAsset)
  //   sno++;
  // }
  // console.log("json->======")
  // console.log(this.jsonArrayAsset)
 
 let jsonArray=[
    {
        "Sno": 1,
        "metlife_assetId": "BME1RUMQ7",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 2066368.78,
        "financed_emission": 145,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 2,
        "metlife_assetId": "BME1RULX3",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 19934600.46,
        "financed_emission": 1398,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 3,
        "metlife_assetId": "BME1DKYL2",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 7763217.61,
        "financed_emission": 544,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 4,
        "metlife_assetId": "BME1ALRF4",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 11603069.35,
        "financed_emission": 814,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 5,
        "metlife_assetId": "37833100",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Equity",
        "core_sector_level2Code": "Common Stock",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 842137539.1,
        "financed_emission": 59097,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 6,
        "metlife_assetId": "037833ER7",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 5441907.56,
        "financed_emission": 381,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 7,
        "metlife_assetId": "037833EQ9",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 563323.2,
        "financed_emission": 39,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },

 {
        "Sno": 8,
        "metlife_assetId": "037833EP1",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 8865944.72,
        "financed_emission": 622,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 9,
        "metlife_assetId": "037833EN6",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 3444339.38,
        "financed_emission": 241,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 10,
        "metlife_assetId": "037833EL0",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 8664909.43,
        "financed_emission": 608,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 11,
        "metlife_assetId": "037833EK2",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 14883535.09,
        "financed_emission": 1044,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 12,
        "metlife_assetId": "037833EJ5",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 166328.31,
        "financed_emission": 11,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 13,
        "metlife_assetId": "037833EG1",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 921473.91,
        "financed_emission": 64,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 14,
        "metlife_assetId": "037833EF3",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 17054022.73,
        "financed_emission": 1196,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 15,
        "metlife_assetId": "037833EE6",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 3121709.91,
        "financed_emission": 219,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 16,
        "metlife_assetId": "037833ED8",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 17012285.63,
        "financed_emission": 1193,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 17,
        "metlife_assetId": "037833EB2",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 10959081.69,
        "financed_emission": 769,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 18,
        "metlife_assetId": "037833EA4",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 3385251.13,
        "financed_emission": 237,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 19,
        "metlife_assetId": "037833DZ0",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 7576217.34,
        "financed_emission": 531,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 20,
        "metlife_assetId": "037833DY3",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 818836.26,
        "financed_emission": 57,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 21,
        "metlife_assetId": "037833DW7",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 15302042.11,
        "financed_emission": 1073,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 22,
        "metlife_assetId": "037833DU1",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 851393.71,
        "financed_emission": 59,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 23,
        "metlife_assetId": "037833DQ0",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 501372.29,
        "financed_emission": 35,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 24,
        "metlife_assetId": "037833DP2",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 3131746.35,
        "financed_emission": 219,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 25,
        "metlife_assetId": "037833DK3",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 1887437.94,
        "financed_emission": 132,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 26,
        "metlife_assetId": "037833DG2",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 31882780.96,
        "financed_emission": 2237,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 27,
        "metlife_assetId": "037833DF4",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 194886.66,
        "financed_emission": 13,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },

 {
        "Sno": 27,
        "metlife_assetId": "037833DF4",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 194886.66,
        "financed_emission": 13,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 28,
        "metlife_assetId": "037833DD9",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 29781671.94,
        "financed_emission": 2089,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 29,
        "metlife_assetId": "037833DB3",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 8331997.54,
        "financed_emission": 584,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 30,
        "metlife_assetId": "037833CX6",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 130382.53,
        "financed_emission": 9,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 31,
        "metlife_assetId": "037833CU2",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 1479496.36,
        "financed_emission": 103,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 32,
        "metlife_assetId": "037833CJ7",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 3319841.91,
        "financed_emission": 232,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 33,
        "metlife_assetId": "037833CH1",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 7588163.01,
        "financed_emission": 532,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 34,
        "metlife_assetId": "037833CG3",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 988341.23,
        "financed_emission": 69,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 35,
        "metlife_assetId": "037833CD0",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 27943302.35,
        "financed_emission": 1960,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 36,
        "metlife_assetId": "037833BZ2",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 723380.89,
        "financed_emission": 50,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 37,
        "metlife_assetId": "037833BY5",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 26453413.62,
        "financed_emission": 1856,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 38,
        "metlife_assetId": "037833BX7",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 218100429,
        "financed_emission": 15305,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 39,
        "metlife_assetId": "037833BW9",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 469805.78,
        "financed_emission": 32,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 40,
        "metlife_assetId": "037833BH2",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 30063313.45,
        "financed_emission": 2109,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 41,
        "metlife_assetId": "037833BG4",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 22265329.33,
        "financed_emission": 1562,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 42,
        "metlife_assetId": "037833BA7",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 53170988,
        "financed_emission": 3731,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 43,
        "metlife_assetId": "037833AZ3",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 19391445.6,
        "financed_emission": 1360,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 44,
        "metlife_assetId": "037833AT7",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 15997629.59,
        "financed_emission": 1122,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 45,
        "metlife_assetId": "037833AS9",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 197663.87,
        "financed_emission": 13,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 46,
        "metlife_assetId": "037833AL4",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 143432107.18,
        "financed_emission": 10065,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    },
    {
        "Sno": 47,
        "metlife_assetId": "037833AK6",
        "cusip": null,
        "isin": null,
        "sedol": null,
        "asset_desc": null,
        "core_sector_level1Code": "Public Corporates",
        "core_sector_level2Code": "Investment Grade",
        "domicile_country_code": "US",
        "risk_country_code": "US",
        "market_value_USD": 22238551.54,
        "financed_emission": 1560,
        "data_quality": 2,
        "year": 2022,
        "equator_principal": "N",
        "msff": "No"
    }
  ]
 this.dataSourcePopUpAsset = new MatTableDataSource(jsonArray);
}
 
}
