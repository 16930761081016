import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidationsService } from '../../../../shared/Validations/custom-validations.service';
import { ConfigurationService } from '../../configuration.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { of } from 'rxjs/observable/of';
import swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';

export interface TreeData {
  nodeData: any;
  childNodes?: TreeData[];
}

@Component({
  selector: 'app-add-company-type',
  templateUrl: './add-company-type.component.html',
  styleUrls: ['./add-company-type.component.css']
})
export class AddCompanyTypeComponent implements OnInit {

  companyTypeForm: FormGroup;
  treeData: TreeData[];
  getChildren = (node: TreeData) => of(node.childNodes);
  treeControl = new NestedTreeControl(this.getChildren);
  parameterData = {};
  allSelected:any = [];
  hashMapForParamData = new Object();
  selectText = {};
  @ViewChild('transTypeForm') transTypeForm;
  constructor(private formBuilder: FormBuilder,
    private customValidationsService: CustomValidationsService,
    private configurationService: ConfigurationService, private router: Router,
    private addEditSuccessComponent: AddEditSuccessComponent) { }

  ngOnInit() {

    this.getData();
    this.companyTypeForm = this.createFormGroup();

  }

  createFormGroup() {
    return this.formBuilder.group({
      id: [0],
      code: ['', [Validators.required, Validators.maxLength(100), , this.customValidationsService.noWhitespaceValidator]],
      name: ['', [Validators.required, , this.customValidationsService.noWhitespaceValidator]]

    });
  }

  getData() {

    this.configurationService.getCompanyTypeData().subscribe(data => {

      this.treeData = data.parameterTree.childNodes;
        for(let node of this.treeData)
        {
         this.allSelected[node.nodeData.id] =false;
         this.selectText[node.nodeData.id] ="Select All";
        }
    });

  }

  hasChild(_: number, node: TreeData) {
    return node.childNodes != null && node.childNodes.length > 0;
  }

  getChildParameterData(parameterDataIds, node) {
    
    let parameterDataVO = {};
    let parameterVO = {}
    if (node != null && node.childNodes != null) {
      let childNode = node.childNodes[0];

      if(childNode && childNode.nodeData){
          parameterVO['id'] = childNode.nodeData.id;
          parameterDataVO['levelNumber'] = childNode.nodeData.levelNumber;
          parameterDataVO['parameterVO'] = parameterVO;
          parameterDataVO['parameterDataIds'] = parameterDataIds;

          this.configurationService.getChildParameterData(parameterDataVO).subscribe((parameterData) => {
            childNode.nodeData.parameterDataVOList = parameterData;
          });
      }
    }
  }

  selectAll(parameterDataIds, node) {
    var valueArray = [];
    this.allSelected[node.nodeData.id] = !this.allSelected[node.nodeData.id];

    if (this.allSelected[node.nodeData.id]) {
      for (let i of node.nodeData.parameterDataVOList) {
        valueArray.push(i.id);
      }
      this.selectText[node.nodeData.id]="Deselect All";
    }
    else{
      this.selectText[node.nodeData.id]="Select All";
    }
    this.parameterData[node.nodeData.id] = valueArray;
    this.getChildParameterData(valueArray, node);
  }

  onSubmit() {
    if (this.companyTypeForm.valid) {
      var companyType = this.companyTypeForm.value;
      var parameterDataArray = new Object();
      for (let parameter of this.treeData) {
        this.hashMapForParamData = new Object();
        this.childParameters(parameter);
        parameterDataArray[parameter.nodeData.id] = this.hashMapForParamData;
      }

      companyType['data'] = parameterDataArray;
      this.configurationService.createCompanyType(companyType).subscribe((result) => {
        var message = "Successfully Added Industry Group";
        this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
          this.resetCompanyTypeForm();
          this.router.navigate(['/companyTypes']);
        });
      }, (error) => {
        console.log(error);
      });
    }
    else{
      this.companyTypeForm.get('code').markAsTouched();
      this.companyTypeForm.get('name').markAsTouched();
    }
      
  }

  childParameters(parameter) {

    if (this.parameterData[parameter.nodeData.id]) {
      this.hashMapForParamData = new Object();
      this.hashMapForParamData[parameter.nodeData.id] = this.parameterData[parameter.nodeData.id];
    }
    if (parameter.childNodes != null && parameter.childNodes.length > 0)
      this.childParameters(parameter.childNodes[0]);
  }

  resetCompanyTypeForm() {
    this.router.navigate(['/companyTypes']);
    this.transTypeForm.resetForm();
    this.parameterData = [];
  
  }
}
