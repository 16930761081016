import { Component, OnInit } from '@angular/core';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { AddEditSuccessComponent } from './modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { from } from 'rxjs';
Amplify.configure({

  Auth: {

    mandatorySignIn: true,

    region: 'us-east-1',

    userPoolId: 'us-east-1_eNPqKstgB',

    userPoolWebClientId: '15fln0eoof7savtndng6fqlpoq',

    authenticationFlowType: 'USER_PASSWORD_AUTH'

  }  
});


@Component({
  selector: 'app-root',
  // template: `

  //   <app-header></app-header>
  //  <app-sidebar></app-sidebar>
  //   <router-outlet></router-outlet>

  //   <app-footer></app-footer>
  // `,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent  implements OnInit{
  title = 'app';
  public login = false;
  private userPool;
  public username = "";
  public password = "";
  show: boolean = false;
  constructor(public router: Router, private addEditSuccessComponent: AddEditSuccessComponent) {

   }

  ngOnInit() {
this.isUserLoggedIn();
  }
  showSignIn() {
    this.show = false;
  }
  showSignUp() {
    this.show = true;
  }
  async  signUp() {
    try {
      var username = (<HTMLInputElement>document.getElementById("signusername")).value;
      var password = (<HTMLInputElement>document.getElementById("signpassword")).value;
      var email = (<HTMLInputElement>document.getElementById("email")).value;
      const { user } = await Auth.signUp({
        username,
        password,
        attributes: {
          email         // optional
          //  phone_number,   // optional - E.164 number convention
          // other custom attributes 
        }
      });
      this.addEditSuccessComponent.showSuccessSwal("Sign Up Successful!")
      .then((value) => {
        this.login = false;
      });
    } catch (error) {
      this.addEditSuccessComponent.showFailedSwal("Sign Up Failed!","SignUp")
      .then((value) => {
        this.login = false;
      });
      console.log('error signing up:', error);
    }
  }
  async  signIn() {
    try {
      this.username = (<HTMLInputElement>document.getElementById("username")).value;
      this.password = (<HTMLInputElement>document.getElementById("password")).value;
      
      var user = await Auth.signIn(this.username, this.password);
      console.log(user)
      console.log(user.pool);
      await Auth.currentSession().then(res=>{
        let accessToken = res.getAccessToken()
        let jwt = accessToken.getJwtToken()
        //You can print them to see the full objects
        console.log(`myAccessToken: ${JSON.stringify(accessToken)}`)
        console.log(`myJwt: ${jwt}`)
        sessionStorage.setItem('token', jwt)
      })
      if (user) {
        this.addEditSuccessComponent.showSuccessSwal("Login Successful")
          .then((value) => {
            
            this.login = true;
            this.router.navigate(['/performanceManagement/dashboard/parameters']);
            sessionStorage.setItem('username', this.username)
             return true;
          });

      }
    } catch (error) {
      this.addEditSuccessComponent.showFailedSwal("Login Failed", "Login")
        .then((value) => {
          this.login = false;
          document.getElementById("username").innerText = "";
          document.getElementById("password").innerText = "";
          
        });
      console.log('error signing in', error);
    }
  }


  async  signOut() {
    try {
      await Auth.signOut({ global: true });
      sessionStorage.removeItem('username')
      this.login=false;
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  isUserLoggedIn() {
    let user = sessionStorage.getItem('username')
    if(user){
      this.login=true;
    }
    console.log(!(user === null))
    return !(user === null)
  }


}

export function getToken(userPool) {
  var currentUser = userPool.getCurrentUser();
  console.log("currentUser"+currentUser);
  if (!currentUser) {
    // redirect to login page
  } else {
    return from(
      new Promise((resolve, reject) => {
        currentUser.getSession((err, session) => {
          if (err) {
            reject(err);
          } else if (!session.isValid()) {
            resolve(null);
          } else {
            console.log("elseeeeeeeeeeee"+session.getIdToken().getJwtToken())
          resolve(session.getIdToken().getJwtToken());
          }
        });
      })
    )
  }
}