import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router, ActivatedRoute } from '@angular/router';

import { AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import { FormGroup, FormBuilder,Validators,ValidatorFn,AbstractControl } from '@angular/forms';
import swal from 'sweetalert2';
import { MatDialog, MatDialogConfig, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SearchQuestionsComponent } from '../search-questions/search-questions.component';
import { AddMoreQuestionsComponent } from '../add-more-questions/add-more-questions.component';                         
import { CustomValidationsService } from '../../../../../shared/Validations/custom-validations.service';
import { AddEditSuccessComponent } from '../../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
interface QuestionNode {
  id: number;
  name: string;
  children?: QuestionNode[];
  parentNode?:QuestionNode;
  templateQuestionId:number,
  responseOptionsVOList:any[];
  responseOptionVO:any[];
  activeFlag:string;
}
/**
 * @title Tree with nested nodes
 */
@Component({
  selector: 'app-list-assessment-template-questions',
  templateUrl: './list-assessment-template-questions.component.html',
  styleUrls: ['./list-assessment-template-questions.component.css'],
})
export class ListAssessmentTemplateQuestionsComponent {
  selectionquestionsJsonObj: any;
  selectedQuestions: string;
  treeControl = new NestedTreeControl<QuestionNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<QuestionNode>();
  data: any = [];
 
  templateFormValue: any;
  responseOptions: any=[];
  templateId: any;
  id: any;
  divNo: any;
  jsonArray: any = [];
  responseArray: any = [];
  displayedColumns: any;
  selectedParentQuestion: any;
  matTableDataSource: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  childQuestionSelected: any;
  dialogConfig = new MatDialogConfig();
  selectedQuestionNode: any;
  questionList: any[];
  selected:any; 
  parentQuestion : any;
  activeElement:any; 
  templateData:any=[];
  allQuestions:any = [];
  templateFormData:any=[];
  templateForm: FormGroup;
  templateNamesList:any=[];
  constructor(private addEditSuccessComponent: AddEditSuccessComponent,private changeDetectorRef: ChangeDetectorRef, private router: Router, private activatedRoute: ActivatedRoute, private assessmentTemplateService: AssessmentTemplateMasterdataService, private dialog: MatDialog,private _fb: FormBuilder,private customValidationsService:CustomValidationsService) {
      this.templateId=this.activatedRoute.snapshot.queryParamMap.get('templateId');    

 }

  ngOnInit() {
   this.templateForm = this.createFormGroup();
   this.getTemplateList();
    this.assessmentTemplateService.editTemplateDetailsById(this.templateId).subscribe((data) => { 
      this.templateData=data;
      this.templateForm.controls['templateName'].setValue(this.templateData.templateName);
      this.setTemplateDataToDataSource(this.templateData);
      this.activeElement='Y';
     
      if(this.data.length>0)     
        this.loadResponseOptionOnLoad(this.data[0]);
      });

  }
    getTemplateList() {
    this.assessmentTemplateService.getTemplateList().subscribe((data) => {
       let templateList = data;
       if(templateList.length > 0)
       {
          
          for(let templateVal of templateList)
          {
            if(this.templateId!=templateVal.id)
            {
               this.templateNamesList.push(templateVal.templateName);


            }
          }
       }
    }, (error) => {
      console.log(error);
    });
  }


  // loadPage()
  // {

  //   this.assessmentTemplateService.editTemplateDetailsById(this.templateId).subscribe((data) => { 
  //     this.templateData=data;       
  //     this.setTemplateDataToDataSource(this.templateData);
  //     });

  // }
  createFormGroup(){
    return this._fb.group({
         templateName:['', [Validators.compose([Validators.required]),this.customValidationsService.noWhitespaceValidator,this.uniqueName()]],
    })
  }
  loadResponseOptionOnLoad(question: any)
  {
    this.getResponseOptionsForQuestion(question);
  
  }
  setTemplateDataToDataSource(templateData)
  {
   this.data=[];
    for(let question of this.templateData.questionVOList)
    {
      let questionData;
      questionData={ 'id': question.id,'templateQuestionId':question.templateQuestionId ,'name': question.questionText, 'primaryQuestionFlag': 'Y' ,
      'skipLogicFlag':question.skipLogicFlag, 'responseOptionsVOList':question.responseOptionsVOList,'criticalFlag':'Y'};
      this.data.push(questionData);

      
    }
    
    this.dataSource.data =null;
    this.dataSource.data = this.data;
  }


  hasChild = (_: number, node: QuestionNode) => !!node.children && node.children.length > 0;
	isActive(item) {	
	    return this.selected === item;
	};
	collapse(node){
    this.treeControl.collapse(node);
    this.treeControl.isExpandable
  } 
 //onclick of skip logic checkbox-> to show the response options for question
 getResponseOptionsForQuestion(question: any) {
  this.childQuestionSelected = [];
  this.selectedParentQuestion = {};  
  this.assessmentTemplateService.getResponseOptionsForQuestion(question.id).subscribe(data => {
    this.responseOptions = data;

    let childQuestions;
    if (this.templateId > 0) {
    this.assessmentTemplateService.getChildQuestionsOnTemplateQuestionId(question.templateQuestionId).subscribe(data => {
      childQuestions = data;
   
      if(this.responseOptions.length>0 && childQuestions.length > 0)
      {
        let responseIds = this.responseOptions.map(item => item.id);
        for (let question of childQuestions) {
          
          var   responseOptionVO={'id':question.responseOptionVO.id};
          var newArray =  { 'id': question.id, 'name': question.questionText,'responseOptionVO':responseOptionVO,'templateQuestionId':question.templateQuestionId, 'primaryQuestionFlag': 'N' ,'criticalFlag':question.criticalFlag,'skipLogicFlag':question.skipLogicFlag,'parentNode':question,'activeFlag':''};
          this.childQuestionSelected.push(newArray);    
        }
      } 
      else
      {
         question.skipLogicFlag ='N';
         question.children=[];

      }
    })
  }
   if( this.selectedQuestionNode!=undefined)
   {    
       this.selectedQuestionNode.activeFlag='';     
   }

    this.selectedQuestionNode = question;
    this.selectedQuestionNode.activeFlag='Y';
    this.displaySubQuestion(question)
   // this.activeElement = question.id;

  })
}
displaySubQuestion(node) {
 // this.activeElement = node.id;
  this.selectedParentQuestion = {};
  let index = this.data.indexOf(node);
  let children: any = [];
  let childQuestions;
  let childArray:any;  
  if (this.templateId > 0) {
    this.assessmentTemplateService.getChildQuestionsOnTemplateQuestionId(node.templateQuestionId).subscribe(data => {
      childQuestions = data;
     
      let count=1;
      for (let question of childQuestions) {
        if(question.skipLogicFlag=='N')
        {
        childArray=[];
        }
        else
        childArray=[{'childFlag':question.skipLogicFlag}]
        var   responseOptionVO={'id':question.responseOptionVO.id};
        children.push({ 'id': question.id,'responseOptionVO':responseOptionVO, 'name': question.questionText,'criticalFlag':question.criticalFlag,'templateQuestionId':question.templateQuestionId, 'primaryQuestionFlag': 'N','count':count,'children':childArray,'skipLogicFlag':question.skipLogicFlag,'activeFlag':'','parentNode':node });

        count++;

      }
      if(children.length>0)
      {

        node.children = children;
        let responseIds=[];
         responseIds = this.responseOptions.map(item => item.id);
        let responseOptionVOList = [];
        for (let responseId of responseIds) {
          let responseOptionVO = {};
          let responseChildQuestions= [];
          responseChildQuestions =node.children.filter(obj => obj.responseOptionVO.id==responseId );
          responseOptionVO['id'] = responseId;       
          responseOptionVO['questionVOList'] = responseChildQuestions;
          responseOptionVOList.push(responseOptionVO);
          }
        this.selectedQuestionNode.responseOptionsVOList = responseOptionVOList;
       


      }
      this.dataSource.data = null;
      this.dataSource.data = this.data; 
      this.treeControl.expand(this.selectedQuestionNode);
      
     
        }
      )}
}
  //skip question for option
  addSubQuestionForOption(questionSelected) {
	this.selectedQuestionNode.skipLogicFlag='Y'
    let responseIds=[];
    responseIds = this.responseOptions.map(item => item.id);
    let responseOptionVOList = [];
    for (let responseId of responseIds) {
      let responseOptionVO = {};
      let responseChildQuestions= [];
      responseChildQuestions = this.childQuestionSelected.filter(obj => obj.responseOptionVO.id==responseId );
      responseOptionVO['id'] = responseId;       
      responseOptionVO['questionVOList'] = responseChildQuestions;
      responseOptionVOList.push(responseOptionVO);
       }
       this.selectedQuestionNode.responseOptionsVOList = responseOptionVOList;
        this.data=this.dataSource.data;

    if (this.selectedQuestionNode.primaryQuestionFlag == 'Y') {
      this.data = this.data.filter(obj => obj.templateQuestionId != this.selectedQuestionNode.templateQuestionId);
      this.data.push(this.selectedQuestionNode);
    }
    else {
      for (let primaryQuestionVO of this.data) {

        if(primaryQuestionVO.responseOptionsVOList!=undefined){
        if (primaryQuestionVO.responseOptionsVOList != null && primaryQuestionVO.responseOptionsVOList.length > 0) {
          this.searchForQuestionVO(this.selectedQuestionNode, primaryQuestionVO );
        }
      }


      }

    }
       this.addSubquestionToTemplate();

  }

  searchForQuestionVO(selectedQnObj, questionVO) {

    let responseOptionId;
    let questionExist;
    if(questionVO.responseOptionsVOList!=undefined){
    for (let responseOptionVO of questionVO.responseOptionsVOList) {
      responseOptionId = responseOptionVO.id;
      if(responseOptionVO.questionVOList!=undefined){
      if (responseOptionVO.questionVOList != null && responseOptionVO.questionVOList.length > 0) {
        // questionExist = selectedQnObj => responseOptionVO.questionVOList.some( questionObj  => questionObj.id == selectedQnObj.id)
        questionExist = responseOptionVO.questionVOList.filter(questionObj=> questionObj.templateQuestionId==selectedQnObj.templateQuestionId);
        if(responseOptionVO.questionVOList.filter(questionObj=> questionObj.templateQuestionId==selectedQnObj.templateQuestionId).length > 0)
        {
            responseOptionVO.questionVOList = responseOptionVO.questionVOList.filter(obj => obj.id != selectedQnObj.id);
           responseOptionVO.questionVOList.push(this.selectedQuestionNode);
        }
        else {

          for (let questionVO of responseOptionVO.questionVOList) {
            this.searchForQuestionVO(selectedQnObj, questionVO);
          }
        }
      }
    }
    }
  }
  }
 addSubquestionToTemplate()
 {

  let templateVO = {};
     let templatePrimaryVO:any=[];
      let parentNode;
      let questionVOList = [];
      templateVO['id']=this.templateId;
      templateVO['saveFlag'] ='N';
      templateVO['templateName'] =  this.templateForm.get('templateName').value;     
      this.selectedQuestionNode.children=null; 
      if(this.selectedQuestionNode.parentNode!=undefined && this.selectedQuestionNode.parentNode!=null)
      {
        parentNode=this.selectedQuestionNode.parentNode;
        this.selectedQuestionNode.parentNode=null;
      }
      questionVOList.push(this.selectedQuestionNode);
        //      if(this.selectedQuestionNode!=undefined && this.selectedQuestionNode.responseOptionsVOList!=undefined && this.selectedQuestionNode.responseOptionsVOList.length>0)
        //      {

        //       for( let responseOptionVO of this.selectedQuestionNode.responseOptionsVOList)
        //           {

        //              if(responseOptionVO.questionVOList!=undefined && responseOptionVO.questionVOList.length>0 )
        //              {

        //               for( let questionVO of responseOptionVO.questionVOList)
        //                   {             
                            
                                                      
        //                         questionVOList.push(questionVO);
                                                         
        //              }
        //           }

        //      }   
        //  }
            
    templateVO['questionVOList'] = questionVOList;
    templateVO['parentTemplateQuestionId']=this.selectedQuestionNode.templateQuestionId;
    this.assessmentTemplateService.createSkipQuestion(templateVO).subscribe(templateData => { 
       if(parentNode!=null)
       {
          this.selectedQuestionNode.parentNode  = parentNode;

       }
     this.dataSource.data = null;
     this.dataSource.data = this.data;
      this.displaySubQuestion(this.selectedQuestionNode);      
      
    });
    
 }
  addMoreQuestion() {    
    if( this.templateForm.get('templateName').value =="")
    {
     
        let msg="Please give a Template name";
        let title="Error";
      //    swal({
      //     title: 'Error',
      //     text: msg,
      //     type: 'error',
      //     confirmButtonText: 'Ok',
      //   })
        this.addEditSuccessComponent.showFailedSwal(msg,title);

    }
    else
    {
    this.fetchAllSelectedQuestion();
    this.dialogConfig.disableClose = true;
    this.dialogConfig.width = "80%";
    this.dialogConfig.data = { idList:this.allQuestions, selectedQuestion: this.templateData.questionVOList };
    const dialogRef = this.dialog.open(AddMoreQuestionsComponent, this.dialogConfig); 
    dialogRef.afterClosed().subscribe(data => {
      if(data!=undefined){  
          let  selectedQuestions = [];
          selectedQuestions=JSON.parse(data.questionSelectedObjs);
          this.savePrimaryQuestion(selectedQuestions);
      
      } 
     });
     }
    }
     savePrimaryQuestion(selectedQuestions)
     {
      let templateVO = {};
     let templatePrimaryVO:any=[];


      let questionVOList = [];
      templateVO['id']=this.templateId;
      templateVO['saveFlag'] ='N';
     
      templateVO['templateName'] =  this.templateForm.get('templateName').value;
      for (let question of   selectedQuestions) {
        questionVOList.push(question);

     }
     templateVO['questionVOList'] = questionVOList;

     let alreadyPresentPrimaryQuestion = this.data.map(item => item.templateQuestionId);

     this.assessmentTemplateService.createTemplatePrimaryQuestion(templateVO).subscribe(templateData => { 
      templatePrimaryVO=templateData;
       for(let question of templatePrimaryVO.questionVOList)
      {
        let questionData;
        if(alreadyPresentPrimaryQuestion.indexOf(question.templateQuestionId) === -1) {
          questionData={ 'id': question.id,'templateQuestionId':question.templateQuestionId ,'name': question.questionText, 'primaryQuestionFlag': 'Y' ,
          'skipLogicFlag':question.skipLogicFlag, 'responseOptionsVOList':question.responseOptionsVOList,'criticalFlag':'Y'};
          this.data.push(questionData); 
        }     
        
      }    
      this.dataSource.data = this.data;

  
    });
    }
  fetchAllSelectedQuestion()
  {
    var qstnIdList = [];
    //to remove child question on the main list
        for (let i of this.dataSource.data) {
          // if(i.children != null){
          //   this.getChildIdList(i.children,qstnIdList);
          // }
          qstnIdList.push(i.id);
        }
       this.allQuestions=qstnIdList;
  }
  getChildIdList(child,qstnIdList) {
    for(let i of child){
      qstnIdList.push(i.id);
      if(i.children!=null){
        this.getChildIdList(i.children,qstnIdList);
      }
    }
  }

  


  onSaveOrSubmit(saveFlag,isSubquestion) {
    var i=0;
    let templateVO = {};
    templateVO['id']=this.templateId;
    templateVO['saveFlag'] =saveFlag;
    templateVO['templateName'] = this.templateForm.get('templateName').value;
    this.data=this.dataSource.data;     
	let questionVOList = [];
    for (let question of   this.data) {//iterate over primary question
       question.templateQuestionId=0;
         questionVOList.push(question);//adding primary question       
          let descendants= this.treeControl.getDescendants(question)
         for( let descendant of descendants)
         {
           descendant.parentNode=null;
          descendant.templateQuestionId=0;
          var children =descendant.children;
          if(children!=null && children.length > 0 )
          {
          let childrenIds = children.map(item => item.id);

            if(descendant.responseOptionsVOList!=undefined && descendant.responseOptionsVOList.length>0)
           {

               for( let responseOptionVO of descendant.responseOptionsVOList)
                   {

                      if(responseOptionVO.questionVOList!=undefined && responseOptionVO.questionVOList.length>0 )
                      {

                       for( let questionVO of responseOptionVO.questionVOList)
                           {                      
                            
                              // if(childrenIds.indexOf(questionVO.id) <= -1)
                              // {   
                                  questionVO.templateQuestionId=0;  
                                  questionVO.children=null;   
                                  questionVO.parentNode=null;                 
                              //    questionVOList.push(questionVO);
                              // }                             
                        }
                   }

              }   
          }
         }
                   descendant.children=null;

            }
                     question.children=null;
 
  }
  templateVO['questionVOList'] = questionVOList;
  if((this.data.length==0 && saveFlag=='N') || templateVO['templateName']=='')
  {
    var msg;
    if(  templateVO['templateName']=='')
       msg="Please give a Template name";
    else if(this.data.length==0 && saveFlag=='N')
      msg="Atleast one Question is required for template";
// swal({
//   title: 'Error',
//   text: msg,
//   type: 'error',
//   confirmButtonText: 'Ok',
// })
let title="Error";
this.addEditSuccessComponent.showFailedSwal(msg,title);
  }
    else
    {
    this.assessmentTemplateService.createTemplate(templateVO).subscribe(templateData => { 
      this.templateData=[];      
      this.templateData=templateData;
       if(saveFlag =='Y')
      var msg = "Successfully saved the template";
      else{
        if(this.templateId > 0) {
          var msg = "Successfully updated the template.";
        }
        else {
          var msg = "Successfully created the template.Please set the weightage.";
        }
      }
      
      // swal({
      //   title: 'Success!',
      //   text: msg,
      //   timer: 2000,
      //   type: 'success',
      //   confirmButtonText: 'Ok'
      // })  
      this.addEditSuccessComponent.showSuccessSwal(msg)
      .then((value) => {
        if(isSubquestion=='N')
        {
           if(saveFlag =='N')
           {
             this.router.navigate(['/listTemplate']);  

           }

        }
            
        else{
          if(this.selectedQuestionNode!=undefined)
          this.displaySubQuestion(this.selectedQuestionNode) ;
        }
        
      

      });      
    });

    }

   
}


    



  showQuestionPopup(index, questionSelected, responseOptionId) {
    
	this.fetchAllSelectedQuestion();
    this.dialogConfig.disableClose = true;
    this.dialogConfig.width = "80%";
    this.dialogConfig.data = { id: this.selectedQuestionNode.id, idList: this.allQuestions, selectedQuestion: questionSelected, index: index, responseOptionId: responseOptionId};
    const dialogRef = this.dialog.open(SearchQuestionsComponent, this.dialogConfig);

    dialogRef.afterClosed().subscribe(data => {
      this.childQuestionSelected=[];
      this.childQuestionSelected = data;

    });
 
  }
  resetForm(questionSelected) {
  }

  removeQuestion(questionObj) {

    swal({
      title: '<p class="swal2-title-warning">Are You Sure?</p>',
      text: "You Want To Delete Sub Question",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'

    }).then((isConfirm) => {
      if (isConfirm.value) {
      
        if(this.selectedQuestionNode.children!=undefined) //if this already saved as children
        {

            this.selectedQuestionNode.children=this.selectedQuestionNode.children.filter(quest => quest.id !== questionObj.id);
            this.dataSource.data = null;
            this.dataSource.data = this.data;
            this.treeControl.expand(this.selectedQuestionNode);
          }
        this.childQuestionSelected = this.childQuestionSelected.filter(order => order.id !== questionObj.id);
        var msg = "Successfully deleted Question";
        // swal({
        //   title: 'Success',
        //   text: msg,
        //   type: 'success',
        //   confirmButtonText: 'Ok',
        // })
        this.addEditSuccessComponent.showSuccessSwal(msg);


      }
    });
  }
  removePrimaryQuestion(questionObj,isSelectedQuestion)
  {
    
      if(this.data.length==1)

      {
        let msg="Atleast one Question is required for template";
        let title="Error";
            // swal({
            //   title: 'Error',
            //   text: msg,
            //   type: 'error',
            //   confirmButtonText: 'Ok',
            // })
            this.addEditSuccessComponent.showFailedSwal(msg,title)
  }
else{
  let msg="You Want To Delete Question";
    // swal({
    //   title: '<p class="swal2-title-warning">Are You Sure?</p>',
    //   text: "You Want To Delete Question",
    //   type: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Yes',
    //   cancelButtonText: 'No'

    // })
    
    this.addEditSuccessComponent.showConfirmationSwal(msg,'Yes','No')
    .then((isConfirm) => {
      if (isConfirm.value) {
        if (this.templateId > 0 && questionObj.templateQuestionId!=undefined )
        {
         

           this.assessmentTemplateService.deleteTemplateQuestion( this.templateId,questionObj.templateQuestionId).subscribe(data => {
         })
      }
              var msg = "Successfully deleted Question";
       
          if(isSelectedQuestion=='N')
          {
            if(questionObj.primaryQuestionFlag=='Y')
            {
              this.data =   this.dataSource.data.filter(data => data.id !== questionObj.id);
              if(questionObj.templateQuestionId==this.selectedQuestionNode.templateQuestionId)// if the deleting question is the active question
              {
                this.childQuestionSelected = [];
                this.responseOptions=[];
              }  
              else if(this.treeControl.getDescendants(questionObj).filter(quest => quest.activeFlag === 'Y')!=null)
              {
                this.childQuestionSelected = [];
                this.responseOptions=[];
              }
              this.dataSource.data = null;
              this.dataSource.data = this.data;            
            }
            else
            {
                if(questionObj.parentNode!=null)
                {
                  questionObj.parentNode.children =     questionObj.parentNode.children.filter(data => data.templateQuestionId !== questionObj.templateQuestionId);
                  if( questionObj.parentNode.children.length==0 )
                  {
                     questionObj.parentNode.skipLogicFlag='N'
                  }
                   let count=1;
                for( let node of  questionObj.parentNode.children )
                {
                  node.count=count;
                  count++;
                }
                  if(questionObj.templateQuestionId==this.selectedQuestionNode.templateQuestionId)
                  {
                    this.childQuestionSelected = [];
                    this.responseOptions=[];
                  }
                  if(questionObj.parentNode.templateQuestionId==this.selectedQuestionNode.templateQuestionId)
                  {
                   if(questionObj.templateQuestionId!=undefined)
                     this.childQuestionSelected = this.childQuestionSelected.filter(order => order.templateQuestionId !== questionObj.templateQuestionId);
                    else
                    this.childQuestionSelected = this.childQuestionSelected.filter(order => order.id !== questionObj.id);

                  }
                  if(questionObj.parentNode.responseOptionsVOList!=null && questionObj.responseOptionVO.id!=undefined)
                  {
                        let responseOptionVOListNew = [];

                      let responseOptionVOList = questionObj.parentNode.responseOptionsVOList;
                      for(let responseOptionVO of responseOptionVOList )
                      {

                          if(responseOptionVO.id==questionObj.responseOptionVO.id)
                          {
                              let responseChildQuestions= [];
                              responseChildQuestions= responseOptionVO.questionVOList.filter(data => data.templateQuestionId !== questionObj.templateQuestionId);
                              responseOptionVO.questionVOList=responseChildQuestions;
                              responseOptionVOListNew.push(responseOptionVO);
                          }
                          else
                          {
                            responseOptionVOListNew.push(responseOptionVO);
                          }
  
                          
                      }
                        questionObj.parentNode.responseOptionsVOList=responseOptionVOListNew;
                   
                    

                  }
                 
                }
            }
            
          }
          else if(isSelectedQuestion=='Y')
          {
            if(this.selectedQuestionNode.children!=undefined) //if this already saved as children
            {
              if(this.selectedQuestionNode.children.length>0)
              {
                this.selectedQuestionNode.children=this.selectedQuestionNode.children.filter(quest => quest.id !== questionObj.id);
                  if(this.selectedQuestionNode.children.length==0 )
                  {
                     this.selectedQuestionNode.skipLogicFlag='N'
                  }
                 let count=1;
                for( let node of this.selectedQuestionNode.children)
                {
                  node.count=count;
                  count++;
                }
               
                this.dataSource.data = null;
                this.dataSource.data = this.data;
                this.treeControl.expand(this.selectedQuestionNode);

              }
              }
              if(questionObj.templateQuestionId!=undefined)
               this.childQuestionSelected = this.childQuestionSelected.filter(order => order.templateQuestionId !== questionObj.templateQuestionId);
              else
               this.childQuestionSelected = this.childQuestionSelected.filter(order => order.id !== questionObj.id);

              if(this.selectedQuestionNode.responseOptionsVOList!=null && questionObj.responseOptionVO.id!=undefined)
                  {
                        let responseOptionVOListNew = [];

                      let responseOptionVOList = this.selectedQuestionNode.responseOptionsVOList;
                      for(let responseOptionVO of responseOptionVOList )
                      {

                          if(responseOptionVO.id==questionObj.responseOptionVO.id)
                          {
                              let responseChildQuestions= [];
                              responseChildQuestions= responseOptionVO.questionVOList.filter(data => data.id !== questionObj.id);
                              responseOptionVO.questionVOList=responseChildQuestions;
                              responseOptionVOListNew.push(responseOptionVO);
                          }
                          else
                          {
                            responseOptionVOListNew.push(responseOptionVO);
                          }
  
                          
                      }
                        this.selectedQuestionNode.responseOptionsVOList=responseOptionVOListNew;

                  }

          }
          
          this.dataSource.data = null;
          this.dataSource.data = this.data;
          this.selectionquestionsJsonObj = this.data;
      
        // swal({
        //   title: 'Success',
        //   text: msg,
        //   type: 'success',
        //   confirmButtonText: 'Ok',
        // })
        this.addEditSuccessComponent.showSuccessSwal(msg);


      }
    });


  }
 

  }
    public uniqueName(): ValidatorFn {
    return (control:AbstractControl) : {[key:string]:boolean} | null => {
        if (control.value !== undefined && this.templateNamesList.length > 0 && control.value !=null) {
          if( this.templateNamesList.includes((control.value).trim()))
          {
             return { 'unique': true };

          }
        }
        return null;
    };
  }

}