import { Component, OnInit } from '@angular/core';
import {MatPaginator} from '@angular/material';
import {MatSort, MatTableDataSource} from '@angular/material';
@Component({
  selector: 'app-alternate-details',
  templateUrl: './alternate-details.component.html',
  styleUrls: ['./alternate-details.component.css']
})
export class AlternateDetailsComponent implements OnInit {
 

  displayedColumns = [];
  originalColumnNames = [];
  jsonArray = [];

  constructor() { }

  ngOnInit() {
    this.setData();
  }


  setData()
  {    
    let dataDetailsArray=[
      {
      id:'1511036',
      year:'2019',
      dqScore:'5',
      emissionFactorUnit:'tCO2e/M. Euro',
      activityVariable:'Assets',
      region:'Europe',
      country:'Bulgaria',
      state:'-',
      classCode:'S1',
      classLvl1:'Agriculture, hunting, forestry & fishing',
      classLvl2:'Sector Average',
      scope1:'1230.57',
      scope2:'58.39',
      scope3:'1232.29',

    },
    {
      id:'1511037',
      year:'2019',
      dqScore:'5',
      emissionFactorUnit:'tCO2e/M. Euro',
      activityVariable:'Assets',
      region:'Europe',
      country:'Bulgaria',
      state:'-',
      classCode:'S2',
      classLvl1:'Mining & quarrying',
      classLvl2:'Sector Average',
      scope1:'1632.58',
      scope2:'160.82',
      scope3:'456.85',

    },
    {
      id:'1511038',
      year:'2019',
      dqScore:'5',
      emissionFactorUnit:'tCO2e/M. Euro',
      activityVariable:'Assets',
      region:'Europe',
      country:'Bulgaria',
      state:'-',
      classCode:'S3',
      classLvl1:'Food production, beverages & tobacco',
      classLvl2:'Sector Average',
      scope1:'107.40',
      scope2:'179.18',
      scope3:'1078.05',

    },
    {
      id:'1511039',
      year:'2019',
      dqScore:'5',
      emissionFactorUnit:'tCO2e/M. Euro',
      activityVariable:'Assets',
      region:'Europe',
      country:'Bulgaria',
      state:'-',
      classCode:'S4',
      classLvl1:'Textiles, leather & wearing apparel',
      classLvl2:'Sector Average',
      scope1:'15.74',
      scope2:'53.30',
      scope3:'399.93',
     
    },
    {
      id:'1511040',
      year:'2019',
      dqScore:'5',
      emissionFactorUnit:'tCO2e/M. Euro',
      activityVariable:'Assets',
      region:'Europe',
      country:'Bulgaria',
      state:'-',
      classCode:'S5',
      classLvl1:'Wood, paper & publishing',
      classLvl2:'Sector Average',
      scope1:'23.04',
      scope2:'52.00',
      scope3:'340.01',
    },
    {
      id:'1223579',
      year:'2020',
      dqScore:'2',
      emissionFactorUnit:'lb CO2e/MWh',
      activityVariable:'Electricity (MWh)',
      region:'North America',
      country:'United States of America',
      state:'Alaska',
      classCode:'Energy',
      classLvl1:'Electricity use',
      classLvl2:'Sector Average',
      scope1:'-',
      scope2:'966.1',
      scope3:'-',
    },
    {
      id:'1223580',
      year:'2020',
      dqScore:'2',
      emissionFactorUnit:'lb CO2e/MWh',
      activityVariable:'Electricity (MWh)',
      region:'North America',
      country:'United States of America',
      state:'Alabama',
      classCode:'Energy',
      classLvl1:'Electricity use',
      classLvl2:'Sector Average',
      scope1:'-',
      scope2:'721.1',
      scope3:'-',
    },
	   {
      id:'1223581',
      year:'2020',
      dqScore:'2',
      emissionFactorUnit:'lb CO2e/MWh',
      activityVariable:'Electricity (MWh)',
      region:'North America',
      country:'United States of America',
      state:'Arkansas',
      classCode:'Energy',
      classLvl1:'Electricity use',
      classLvl2:'Sector Average',
      scope1:'-',
      scope2:'951.3',
      scope3:'-',
    },
	{
      id:'1223582',
      year:'2020',
      dqScore:'2',
      emissionFactorUnit:'lb CO2e/MWh',
      activityVariable:'Electricity (MWh)',
      region:'North America',
      country:'United States of America',
      state:'Arizona',
      classCode:'Energy',
      classLvl1:'Electricity use',
      classLvl2:'Sector Average',
      scope1:'-',
      scope2:'737',
      scope3:'-',
    },
	{
      id:'1223583',
      year:'2020',
      dqScore:'2',
      emissionFactorUnit:'lb CO2e/MWh',
      activityVariable:'Electricity (MWh)',
      region:'North America',
      country:'United States of America',
      state:'California',
      classCode:'Energy',
      classLvl1:'Electricity use',
      classLvl2:'Sector Average',
      scope1:'-',
      scope2:'453',
      scope3:'-',
    },
    {
      id:'121376',
      year:'2021',
      dqScore:'2',
      emissionFactorUnit:'kgCO2e/kWh',
      activityVariable:'WTT electricity generation',
      region:'Europe',
      country:'Bulgaria',
      state:'-',
      classCode:'Energy',
      classLvl1:'-',
      classLvl2:'-',
      scope1:'-',
      scope2:'0.13229',
      scope3:'-',
    },
   {
      id:'121437',
      year:'2021',
      dqScore:'2',
      emissionFactorUnit:'kgCO2e/kWh',
      activityVariable:'WTT electricity generation',
      region:'Europe',
      country:'Bulgaria',
      state:'-',
      classCode:'Energy',
      classLvl1:'-',
      classLvl2:'-',
      scope1:'-',
      scope2:'0.0137',
      scope3:'-',
    },
	{
      id:'1213378',
      year:'2021',
      dqScore:'2',
      emissionFactorUnit:'gCO2e/kWh',
      activityVariable:'Electricity (kWh)',			
      region:'Europe',
      country:'Bulgaria',
      state:'-',
      classCode:'',
      classLvl1:'Energy',
      classLvl2:'Electricity use',
      scope1:'-',
      scope2:'354.33',
      scope3:'-',
    },
	{
      id:'1243479',
      year:'2021',
      dqScore:'2',
      emissionFactorUnit:'gCO2e/kWh',
      activityVariable:'Electricity (kWh)',			
      region:'Asia-Pacific',
      country:'Myanmar',
      state:'-',
      classCode:'',
      classLvl1:'Energy',
      classLvl2:'Electricity use',
      scope1:'-',
      scope2:'348.84',
      scope3:'-',
    },
	{
      id:'1253380',
      year:'2021',
      dqScore:'2',
      emissionFactorUnit:'gCO2e/kWh',
      activityVariable:'Electricity (kWh)',			
      region:'Africa',
      country:'Burundi',
      state:'-',
      classCode:'',
      classLvl1:'Energy',
      classLvl2:'Electricity use',
      scope1:'-',
      scope2:'250',
      scope3:'-',
    },
     
  ]
    this.displayedColumns = [];
    this.originalColumnNames = [];
    this.jsonArray=[]
    var count = 0;
    var index=0;
    for (let i of dataDetailsArray) {
      var newArray = {};
      newArray['id'] = i.id;
      newArray['year'] =i.year;
      newArray['dqScore'] = i.dqScore;
      newArray['emissionFactorUnit'] = i.emissionFactorUnit;
      newArray['activityVariable'] = i.activityVariable;
      newArray['region'] =i.region;
      newArray['country'] = i.country;
      newArray['state'] = i.state;
      newArray['classCode'] = i.classCode;
      newArray['classLvl1'] =i.classLvl1;
      newArray['classLvl2'] = i.classLvl2;
      newArray['scope1'] = i.scope1;
      newArray['scope2'] =i.scope2;
      newArray['scope3'] = i.scope3;
      console.log("newArray")
      console.log(newArray)
     this.jsonArray.push(newArray)
    }
      
    this.originalColumnNames= ["ID","Year","DataQuality Score","EmissionFactor Unit","Activity Variable","Region","Country","State","Classification Code","Classification Level1","Classification Level2","Scope1","Scope2","Scope3"];
    this.displayedColumns=["id","year","dqScore","emissionFactorUnit","activityVariable","region","country","state","classCode","classLvl1","classLvl2","scope1","scope2","scope3"];
    console.log("jsonArrayF")
    console.log(this.jsonArray)
  }


  
}
