import { Component, OnInit, ViewChild, Input, SimpleChanges } from '@angular/core';
import { AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';

@Component({
  selector: 'app-list-subfactor-user-mapping',
  templateUrl: './list-subfactor-user-mapping.component.html',
  styleUrls: ['./list-subfactor-user-mapping.component.css']
})
export class ListSubfactorUserMappingComponent implements OnInit {

  dataTableFlag: boolean;
  jsonArray: any[];
  displayedColumns: any[];
  originalColumnNames : any;
  subFactorSelectedId: any;
  subFactorUserMappingData: any;


  @Input() set subFactorSelected(val) {
    this.subFactorSelectedId = val;
  }

  constructor(private assessmentTemplateService: AssessmentTemplateMasterdataService, ) { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['subFactorSelected'] && changes['subFactorSelected'].currentValue != null) {
      this.getSubFactorUserMappingList(changes['subFactorSelected'].currentValue);

    }
     else{
     this.getSubFactorUserMappingList(0);
    }
  }

  getSubFactorUserMappingList(subFactorId) {
    this.assessmentTemplateService.fetchSubFactorUserMapping(subFactorId).subscribe(data => {
      this.subFactorUserMappingData = data;
      this.dataTablePopulation(this.subFactorUserMappingData);

    }, (error) => {
      console.log(error);
    })
  }

  dataTablePopulation(subFactorUserMappingData) {
    this.displayedColumns = [];
    this.originalColumnNames = [];
    this.jsonArray = [];
    let count = 0;
    for (let i of subFactorUserMappingData) {
      let taggeduser: string = '';
      if (i.userVOList != null && i.userVOList.length > 0) {
        let newArray = {};
        let userList = i.userVOList;
        newArray['id'] = i.id;
        newArray['Sno'] = ++count;
        newArray['SubfactorName'] = i.subFactorName;
        for (let j = 0; j < userList.length; j++) {
          if (j != userList.length - 1)
            taggeduser += userList[j].name + ',';
          else
            taggeduser += userList[j].name;
        }
        newArray['TaggedUsers'] = taggeduser;
        this.jsonArray.push(newArray);

      }
    }

    this.displayedColumns = ['Sno', 'SubfactorName', 'TaggedUsers'];
    this.originalColumnNames = ['Sl.No', 'Subfactor Name', 'Tagged Users'];
  }


}
