import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../configuration.service';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material';
import { AddEditSuccessComponent } from '../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';

interface ParameterNode {

  childNodes?: ParameterNode[];
  nodeData?: any[];

}
@Component({
  selector: 'app-framework-configuration',
  templateUrl: './framework-configuration.component.html',
  styleUrls: ['./framework-configuration.component.css']
})
export class FrameworkConfigurationComponent implements OnInit {

  parameterVOList: any = [];
  parameters: any;
  treeControl = new NestedTreeControl<ParameterNode>(node => node.childNodes);
  dataSource = new MatTreeNestedDataSource<ParameterNode>();
  selected: any;
  constructor(private parameterDataService: ConfigurationService, private formBuilder: FormBuilder,private addEditSuccessComponent: AddEditSuccessComponent) { }

  ngOnInit() {
    this.getparameterList();
  }
  hasChild = (_: number, node: ParameterNode) => !!node.childNodes && node.childNodes.length > 0;
  isActive(item) {
    return this.selected === item;
  };
  collapse(node) {
    this.treeControl.collapse(node);
    this.treeControl.isExpandable
  }
  getparameterList() {

    this.parameterDataService.getListOfParameter().subscribe(listParams => {
      this.parameters = listParams.childNodes;

    });
  }

  submitConfiguration() {
    this.parameterVOList = [];
    for (let parameterVO of this.parameters) {
      this.setParameterVO(parameterVO);
    }
    this.parameterDataService.createOrUpdateParameterConfiguration(this.parameterVOList).subscribe((myform) => {
      var message = "Successfully updated parameter configuration";   
      this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
        this.getparameterList();
      });
    }, (error) => {
      console.log(error);
    });

  }
  setParameterVO(parameterVO): any {
    let parameter = {
      'id': parameterVO.nodeData.id,
      'parameterConfigurationVO': parameterVO.nodeData.parameterConfigurationVO
    }
    this.parameterVOList.push(parameter);
    if (parameterVO.childNodes.length > 0) {
      for (let childParam of parameterVO.childNodes) {
        this.setParameterVO(childParam);
      }
    }
  }

  resetFrameworkConfigForm() {
    this.getparameterList();
  }
}