import { Component, OnInit,Input } from '@angular/core';
import { AbstractControlDirective, AbstractControl, ValidatorFn } from '@angular/forms';
import { NullInjector } from '@angular/core/src/di/injector';
@Component({
  selector: 'app-show-errors',
  templateUrl: './show-errors.component.html',
  styleUrls: ['./show-errors.component.css']
})
export class ShowErrorsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
 private static readonly errorMessages = {
    'required': () => '',
    'minlength': (params) => 'The min number of characters required is ' + params.requiredLength+'. ',
    'maxlength': (params) => 'The max allowed number of characters is ' + params.requiredLength+'. ',
    'pattern': (params) => 'Wrong format. ',
    'unique' : () => 'Please provide a unique value. ',
    'whitespace': () => '',
    'dateCheck': () => 'Start Date must be lesser than End Date',
    'min' : (params) => 'The min allowed value is ' + params.min+'. ',
    'max' : (params) => 'The max allowed value is ' + params.max+'. ' , 
    'uniqueCode':()=>'Please enter unique Code',
    'uniqueName':()=>'Please enter unique Name',

  };
  
  @Input()
  private control: AbstractControlDirective | AbstractControl;

  shouldShowErrors(): boolean {
    return this.control &&
      this.control.errors &&
      (this.control.dirty || this.control.touched);
  }

  listOfErrors(): string[] {
    return Object.keys(this.control.errors)
      .map(field => this.getMessage(field, this.control.errors[field]));
  }

  private getMessage(type: string, params: any) {
    return ShowErrorsComponent.errorMessages[type](params);
  }
}