import { Component, OnInit, ViewChild } from '@angular/core';
import { DataQualityMasterService } from '../../data-quality-master.service';
import { AddEditSuccessComponent } from '../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { FormBuilder, FormArray, Validators } from '@angular/forms';
import { DataAcqCommonService } from '../../data-acq-common.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';

@Component({
  selector: 'app-add-datalevel-scores',
  templateUrl: './add-datalevel-scores.component.html',
  styleUrls: ['./add-datalevel-scores.component.css']
})
export class AddDatalevelScoresComponent implements OnInit {

  isSubmitFlag: boolean = false;
  dataLevelScores: any;
  sisIndex: any;
  index: any;
  dataLevelScoreForm: any;
  dataLevelList: any = [];
  parityList: any = [];
  homogeneityList: any = [];
  parity: any;
  dataLevelsForm: any;
  reqValidation: boolean = true;
  isValid: boolean;
  constructor(private dataQualityService: DataQualityMasterService, private addEditSuccessComponent: AddEditSuccessComponent,
    private formBuilder: FormBuilder, private dataAcqCommonService: DataAcqCommonService) {

    this.getDataLevelList();
    this.fetchDataLevelScore();
  }
  ngOnInit() {  
  }

  fetchDataLevelScore() {
    this.dataQualityService.fetchDataLevelScore().subscribe(data => {
      this.dataLevelScores = data;
      let homogeneArray = [];
      let parityArray = [];
      for (let dataLevels of this.dataLevelScores) {

        if (dataLevels.homogeneity) {

          let homogeneity = {
            "id": dataLevels.id,
            "homogeneId": dataLevels.homogeneity,
            "dataLevelHomogeneScore": dataLevels.score
          }
          homogeneArray.push(homogeneity)

        }
        else if (dataLevels.parity) {

          let parity = {
            "id": dataLevels.id,
            "parityId": dataLevels.parity,
            "dataLevelParityScore": dataLevels.score
          }
          parityArray.push(parity)

        }
      }

      let dataSourceListObj = [];
    
        for (let dataLevels of this.dataLevelScores) {

          let dataLevelList;
          if (dataLevels.homogeneity) {
            if (!dataSourceListObj.some((item) => item.levelId == dataLevels.levelId)) {
              dataLevelList = {
                "id": "",
                "levelId": dataLevels.levelId,
                "dataLevelScore": 0,
                "dataParentLevel": homogeneArray,
                "dataSisterLevel": []
              }
            }
          }
          else if (dataLevels.parity) {
            if (!dataSourceListObj.some((item) => item.levelId == dataLevels.levelId)) {
              dataLevelList = {
                "id": "",
                "levelId": dataLevels.levelId,
                "dataLevelScore": 0,
                "dataParentLevel": [],
                "dataSisterLevel": parityArray
              }
            }
          }
          else {
            dataLevelList = {
              "id": dataLevels.id,
              "levelId": dataLevels.levelId,
              "dataLevelScore": dataLevels.score,
              "dataParentLevel": [],
              "dataSisterLevel": []
            }
          }
          if (dataLevelList) {
            dataSourceListObj.push(dataLevelList);
          }
        }
        let list = [];
        this.dataLevelsForm.dataLevel.forEach(level => {
          dataSourceListObj.forEach(source => {
            if (level.levelId == source.levelId) {
              list.push(source);
            }
          })
        })
        var level = {
          "dataLevel": list,
        }
        this.dataLevelScoreForm.patchValue(level);
        console.log("patched list")
        console.log(this.dataLevelScoreForm)
      
    })
  }

  getDataLevelList() {

    let dataLevel = this.dataAcqCommonService.getDataLevels();
    this.dataAcqCommonService.getDataAcqMasterData(dataLevel).subscribe(data => {
      this.dataLevelList = data.constantsVOList;
      this.dataLevelScoreForm = this.createFormGroup();
      const controlsUrl = this.formArray;
      for (let data of this.dataLevelList) {
        this.formArray.push(this.getDataSourceList(data));
        if (data.code == 'PL') {
          this.getHomogeneity();
        }
        else if (data.code == 'SL') {
          this.getParity();
        }
      }
      console.log(this.dataLevelScoreForm)
      this.dataLevelsForm = this.dataLevelScoreForm.value;
    })
  }



  
  getParity() {
    let parity = this.dataAcqCommonService.getParity();
    this.dataAcqCommonService.getDataAcqMasterData(parity).subscribe(data => {
      this.parityList = data.constantsVOList;
      for (let parity of this.parityList) {
        this.sisterLevels(this.sisIndex).push(this.getDataSisterLevel(parity));

      }
    })
  }

  getHomogeneity() {

    let homogeneity = this.dataAcqCommonService.getHomogeneity();
    this.dataAcqCommonService.getDataAcqMasterData(homogeneity).subscribe(data => {
      this.homogeneityList = data.constantsVOList;
      for (let homogene of this.homogeneityList) {
        this.parentLevels(this.index).push(this.getDataParentLevel(homogene));

      }
    })
  }


  createFormGroup() {
    return this.formBuilder.group({
      dataLevel: this.formBuilder.array([]),
    });
  }

  getDataSourceList(data) {
    return this.formBuilder.group({
      id: [''],
      levelId: [data.id],
      dataLevelScore: ['', [ Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      dataParentLevel: this.formBuilder.array([]),
      dataSisterLevel: this.formBuilder.array([]),
    });
  }

  getDataSisterLevel(parity) {
    return this.formBuilder.group({
      id: [],
      parityId: [parity.id],
      dataLevelParityScore: ['', [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
    });
  }
  getDataParentLevel(homogene) {
    return this.formBuilder.group({
      id: [],
      homogeneId: [homogene.id],
      dataLevelHomogeneScore: ['',[ Validators.pattern(/^-?(0|[1-9]\d*)?$/)]]
    });
  }


  get formArray() {
    return this.dataLevelScoreForm.get('dataLevel') as FormArray;
  }

  parentLevels(index) {
    this.index = index
    return this.dataLevelScoreForm.get('dataLevel').at(index).get("dataParentLevel") as FormArray;
  }

  sisterLevels(index) {
    this.sisIndex = index
    return this.dataLevelScoreForm.get('dataLevel').at(index).get("dataSisterLevel") as FormArray;
  }

  onSubmit() {
    if (this.dataLevelScoreForm.valid) {
      this.isSubmitFlag = true;
      this.saveDatalLevelConfig();
    }
    else {
      if (this.dataLevelScoreForm.status == "INVALID") {
        this.reqValidation = false;
      }
    }

  }
  saveDatalLevelConfig() {
    let dataLevelScoreList = [];
    console.log("form")
    console.log(this.dataLevelScoreForm)
    for (let data of this.dataLevelScoreForm.value.dataLevel) {
      let dataAcqMasterVO;
      if (data.dataLevelScore) {
        dataAcqMasterVO = {};
        dataAcqMasterVO["levelId"] = data.levelId,
          dataAcqMasterVO["id"] = data.id,
          dataAcqMasterVO["score"] = data.dataLevelScore
        dataLevelScoreList.push(dataAcqMasterVO)
      }
      else if (data.dataParentLevel.length > 0) {
        for (let parentData of data.dataParentLevel) {
          dataAcqMasterVO = {};
          dataAcqMasterVO["levelId"] = data.levelId,
            dataAcqMasterVO["id"] = parentData.id,
            dataAcqMasterVO["homogeneity"] = parentData.homogeneId,
            dataAcqMasterVO["score"] = parentData.dataLevelHomogeneScore

          dataLevelScoreList.push(dataAcqMasterVO)
        }
      }
      else if (data.dataSisterLevel.length > 0) {
        for (let sisterData of data.dataSisterLevel) {
          dataAcqMasterVO = {};
          dataAcqMasterVO["levelId"] = data.levelId,
            dataAcqMasterVO["id"] = sisterData.id,
            dataAcqMasterVO["parity"] = sisterData.parityId,
            dataAcqMasterVO["score"] = sisterData.dataLevelParityScore

          dataLevelScoreList.push(dataAcqMasterVO)
        }

      }
    }
    console.log("SavedForm")
    console.log(dataLevelScoreList)
    this.dataQualityService.createOrUpdateDataLevelScore(dataLevelScoreList).subscribe(data => {
      var message;
      if (this.isSubmitFlag) {
        message = "Successfully configured Data level Scores"
      }
      else {
        message = "Successfully saved Data level Score"
      }
      this.addEditSuccessComponent.showSuccessSwal(message);

    });
  }
  toggleSubfactor(id) {
    if (document.getElementById("level" + id).className == "expand_arrow-2") {
      document.getElementById("level" + id).classList.remove("expand_arrow-2");
      document.getElementById("level" + id).classList.add("collapse_arrow-2");
      document.getElementById("sub" + id).classList.remove("display-none");
      document.getElementById("sub" + id).classList.add("display-block");
    }
    else {
      document.getElementById("level" + id).classList.remove("collapse_arrow-2");
      document.getElementById("level" + id).classList.add("expand_arrow-2");
      document.getElementById("sub" + id).classList.remove("display-block");
      document.getElementById("sub" + id).classList.add("display-none");
    }
  }

  hideErrorMessage(targetValue) {
    this.isValid = false;
    var numbers = new RegExp(/^[0-9]+$/);
    if (numbers.test(targetValue) && this.dataLevelScoreForm.status == "VALID") {
      this.isValid = true;
    }
  }
}
