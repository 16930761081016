import { Injectable } from '@angular/core';
import { BaseUrlService } from '../../shared/base-url.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class DataAcqCommonService {
  private indicatorWrapperUrl = BaseUrlService.INDICATORBASEURL;

  constructor(private http: HttpClient) { }

  getDataQltyLevels() {
    let dataQltyLevel = {};
    dataQltyLevel['constants'] = true;
    dataQltyLevel['constantLabel'] = "DATA_SOURCE";

    return dataQltyLevel;
  }

  getDataLevels() {
    let dataQltyLevel = {};
    dataQltyLevel['constants'] = true;
    dataQltyLevel['constantLabel'] = "DATA_LEVEL";

    return dataQltyLevel;
  }

  getParity() {
    let dataQltyLevel = {};
    dataQltyLevel['constants'] = true;
    dataQltyLevel['constantLabel'] = "PARITY";

    return dataQltyLevel;
  }

  getHomogeneity() {
    let dataQltyLevel = {};
    dataQltyLevel['constants'] = true;
    dataQltyLevel['constantLabel'] = "HOMOGENEITY";

    return dataQltyLevel;
  }

  getDataAcqMasterData(indicatorRequestVO): Observable<any> {

    let url = this.indicatorWrapperUrl + '/getIndicatorMasterData';
    let body = JSON.stringify(indicatorRequestVO);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);
  }
}
