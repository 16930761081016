import { Component, OnInit,Input,Inject } from '@angular/core';
import { AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import {FormControl, FormBuilder,FormGroup,Validators } from '@angular/forms';
import swal from 'sweetalert2';
import {MatDialogRef,MatDialog,MAT_DIALOG_DATA} from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AddEditSuccessComponent } from '../../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';


@Component({
  selector: 'app-initiate-assessment',
  templateUrl: './initiate-assessment.component.html',
  styleUrls: ['./initiate-assessment.component.css']
})
export class InitiateAssessmentComponent implements OnInit {

  initiateAssessmentForm:FormGroup;
  companyId:number;
  panelList:any=[];
  templateList:any=[];
  isSubmit:boolean =false;
  companyEmailArray:any=[]
  
  constructor(private addEditSuccessComponent: AddEditSuccessComponent,private router: Router,private assessmentRecordService:AssessmentTemplateMasterdataService,
              private formBuilder:FormBuilder,private activatedRoute: ActivatedRoute,
              private dialogRef:MatDialogRef<InitiateAssessmentComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any  ) {
      this.companyId =data;
      this.initiateAssessmentForm = this.createFormGroup();

  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.fetchAssessmentFormDetails();
  }
  createFormGroup()
  {
    return this.formBuilder.group({
      'panelSelect':['',Validators.required],
      'templateSelect':['',Validators.required],
      'companyEmail':['',Validators.required]
    })
  }

  fetchAssessmentFormDetails()
  {
    this.assessmentRecordService.fetchCompanyAssessmentInitiationDetails(this.companyId).subscribe(data => {
     let companyAssessmentDetails:any =[];
     companyAssessmentDetails = data;
     console.log("initiate assessment data")
     console.log(data)
     if(companyAssessmentDetails)
     {
        if(companyAssessmentDetails.panelVOList.length>0)
        this.panelList = companyAssessmentDetails.panelVOList;
        if(companyAssessmentDetails.templateVOList.length>0)
        this.templateList = companyAssessmentDetails.templateVOList;
        if(companyAssessmentDetails.companyVO.primaryEmail!=null && companyAssessmentDetails.companyVO.secondaryEmail!=null){
          this.companyEmailArray.push(companyAssessmentDetails.companyVO.primaryEmail);
          this.companyEmailArray.push(companyAssessmentDetails.companyVO.secondaryEmail)
        }
     }
    })
  }

 onSubmit()
 {
   this.isSubmit = true;

   let assessmentVO:any = {};
   let panelVO:any={};
   let templateVO:any={};
   let companyVO:any={};
   panelVO = this.initiateAssessmentForm.controls['panelSelect'].value;
   let email:any;
   email=this.initiateAssessmentForm.controls['companyEmail'].value;
   let templateDetails = this.initiateAssessmentForm.controls['templateSelect'].value;
   templateVO = templateDetails;
   
   companyVO.id = this.companyId;
   assessmentVO.panelVO = panelVO;
   assessmentVO.templateVO =templateVO;
   assessmentVO.companyVO = companyVO;
   assessmentVO.email = email;
   console.log("submitt")
   console.log(assessmentVO)
    if(templateDetails.weightageStatus !='N' && this.initiateAssessmentForm.valid){
            this.assessmentRecordService.initiateAssessment(assessmentVO).subscribe((data)=>{
              var message = "Successfully initiated Assessment and Sent to the Company";
              swal({
                title: 'Success!',
                text: message,
                type: 'success',
                confirmButtonText: 'OK'
              }).then((value) => {
                location.reload();
              });  
              this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
                location.reload();
              });
      })
    }
else if(templateDetails.weightageStatus =='N'){
  var message = "Please set weightage for this template";
  var title="Weightage Not Set!";
              swal({
                title: 'Weightage Not Set!',
                text: message,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              }).then((isConfirm) => {
                if (isConfirm.value) {
                  this.dialogRef.close();
                  
                  this.router.navigate(['/templateSettingsData/'+templateVO.id], { queryParams: {"statusFlg" :'Y'},skipLocationChange: true});
                }
               
              }); 
              this.addEditSuccessComponent.showFailedSwal(message,title).then((isConfirm) =>{
                if (isConfirm.value) {
                     this.dialogRef.close();
                      
                    this.router.navigate(['/templateSettingsData/'+templateVO.id], { queryParams: {"statusFlg" :'Y'},skipLocationChange: true});
                }
              });

              
}

 }

}
