import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { BaseUrlService } from '../../../shared/base-url.service';

@Injectable({
  providedIn: 'root'
})
export class PerformanceFrameworkCompanyService {
 
  
  private baseUrl = BaseUrlService.PERFORMANCEFRAMEWORKBASEURL;
  private parameterBaseUrl = BaseUrlService.PARAMATERCONFIGURATIONBASEURL;
  private indicatorBaseUrl = BaseUrlService.INDICATORBASEURL;
  constructor(private http: HttpClient) { }

  getAllCompanyTypes(parameterRequestVO): Observable<any> {
    let body = JSON.stringify(parameterRequestVO);
    let url = this.parameterBaseUrl + '/getParameterData';
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
        })
      };
      return this.http.post(url, body, httpOptions);
  
  }
  getAllCompanies(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getFrameworkCompanyDetails`);
  }
    fetchIndicatorbyCompanyType(companyId,status): Observable<any> {
    return this.http.get(`${this.baseUrl}/fetchIndicatorbyCompanyType/`+companyId+'/'+status);
  }
 
  getCompaniesForSearch(searchCompanyForm: any): Observable<any> {
    let body = JSON.stringify(searchCompanyForm);
    let url = this.baseUrl + '/searchCompany';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);
  }
  fetchFrameworkDetails(frameworkId):Observable<any>{
    return this.http.get(`${this.baseUrl}/viewFramework/`+frameworkId);
  }

  getIndicatorsFromActiveCompany(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getIndicatorsFromActiveCompany/`+companyId);
  }
  
  getArchievedFrameworkforCompany(companyId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getArchievedFrameworkforCompany/` + companyId);
  }

  archiveSelectedFrameworks(selectedFrameworksIds): Observable<any> {
    let body = selectedFrameworksIds;
    let url = this.baseUrl + '/archieveFrameworkDetailsforCompany';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);
  }
  createOrUpdateCompanyFrameworkConfiguration(companyVO): Observable<any>{
    let body = JSON.stringify(companyVO);
    let url = this.baseUrl + '/createOrUpdateCompanyFrameworkConfiguration';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };
    return this.http.post(url, body, httpOptions);
  }

   saveCompanyIndicatorSchedules(indicatorConfigurationVO: any) {
    let body = JSON.stringify(indicatorConfigurationVO);
    let url = this.baseUrl + '/createOrUpdateCompanyIndicatorSchedules';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);
  }
  deleteSeletedIndicator(frameworkIndicatorDetailsId:number):Observable<any>
  {
    let body = JSON.stringify(frameworkIndicatorDetailsId);
    let url = this.baseUrl + '/deleteSeletedIndicator';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }) 
    }; 
    return this.http.post(url, body, httpOptions);

  }

  
  getIndicatorDetails(indicatorRequestVO): Observable<any> {
    let body = JSON.stringify(indicatorRequestVO);
    let url = this.indicatorBaseUrl + '/getIndicatorMasterData';
    const httpOptions = {
      headers: new HttpHeaders({
      'Content-Type': 'application/json',
        })
      };
      return this.http.post(url, body, httpOptions);

  } 
  
  fetchSchedulesForIndicator(frameworkIndicatorDetailsId,indicatorTypeId): Observable<any> {
    return this.http.get(`${this.baseUrl}/fetchSchedulesForCompanyIndicator/` + frameworkIndicatorDetailsId+'/'+indicatorTypeId);
  }
  
  getWebserviceForIndicator(frameworkIndicatorDetailsId): Observable<any> {
    return this.http.get(`${this.baseUrl}/webserviceForCompany/` + frameworkIndicatorDetailsId);
  }

  submitWebservice(indicatorConfigurationVO: any) {

    let url = this.baseUrl + '/webserviceForCompany';
    let body = JSON.stringify(indicatorConfigurationVO);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };
    return this.http.post(url, body, httpOptions);
  }
  saveBaselineForIndicator(indicatorConfigVO){
    let body = JSON.stringify(indicatorConfigVO); 
    let url = this.baseUrl+'/saveBaseLineConfigurationForCompany';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
       
      })
    };

    return this.http.post(url, body, httpOptions); 
  }
  getBaselineValue(indicatorVO): Observable<any> {
    let body = JSON.stringify(indicatorVO); 
    let url = this.baseUrl+'/getBaselineValueForCompany';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
       
      })
    };
    return this.http.post(url, body, httpOptions); 
  }
}
