import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ImpactAssessmentService } from '../../../impact-assessment.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { ReportConstantsComponent } from '../../../../report-constants/report-constants.component';

@Component({
  selector: 'app-parameter-level-drill-down',
  templateUrl: './parameter-level-drill-down.component.html',
  styleUrls: ['./parameter-level-drill-down.component.css']
})
export class ParameterLevelDrillDownComponent implements OnInit {

  currentLevelVO: any = [];
  subParamCount = [];
  nextLevelMetaName: string;
  parameterVO: any;
  currentLevelName: string;
  jsonArrayCurrentLevelList = [];
  jsonArrayParameterList = [];
  jsonArrayParameterIndicatorList = [];
  displayedColumnsParameterList = [];
  displayedColumnsParameterIndicators = [];
  displayedColumnsCurrentLevelIndicator = [];
  dataSourceCurrentLevelIndicator: any = [];
  dataSourceParameterIndicators: any = [];
  dataSourceSectorIndicatorsPopup: any = [];
  dataSourceCurrentLevelIndicatorPopUp: any = [];
  dataSourceParameter: any = [];
  dataSourceParameterPopUp: any = [];
  displayColParameter = [];
  id: number;
  parameterData = [];
  doughnutData: any;
  chartOptions: any;
  chartColors: any;
  doughnutChartType: string;
  flag: boolean;
  levelNo: number;
  parameterId: any;
  capitalId: any;
  sdgId: any;
  loading: boolean;
  currency: string;
  locale: string;
  impactFlag: number;
  impactName: string;
  data: any = [];
  subCatId: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  constructor(private _impactAssessmentService: ImpactAssessmentService, private _router: Router, private activatedRoute: ActivatedRoute) {
    this.displayedColumnsParameterList = ['parameter'];
    this.doughnutChartType = 'doughnut';
    this.loading = false;
    this.impactName = this.activatedRoute.snapshot.queryParamMap.get('impactName');
    let impactFlag = this.activatedRoute.snapshot.queryParamMap.get('impactFlag');
    this.impactFlag = +impactFlag;
    let num = this.activatedRoute.snapshot.queryParamMap.get('levelNo');
    this.levelNo = +num;
    this.parameterId = this.activatedRoute.snapshot.queryParamMap.get('parameterId');
    this.capitalId = this.activatedRoute.snapshot.queryParamMap.get('capitalId');
    this.sdgId = this.activatedRoute.snapshot.queryParamMap.get('sdgId');

    let subCatIdVal = this.activatedRoute.snapshot.queryParamMap.get('subCategoryId');
    this.subCatId = +subCatIdVal;
  }

  ngOnInit() {
    this.currency = ReportConstantsComponent.CURRENCY;
    this.locale = ReportConstantsComponent.LOCALE;
    this.loading = true;
    this.subParamCount = [];

    this._impactAssessmentService.getParameterLevelDetailsList(this.levelNo, this.parameterId, this.capitalId, this.sdgId, this.subCatId).subscribe((data) => {
      if (data) {
        this.data = data[0];
        this.currentLevelVO = this.data.parameterDetails;
        this.parameterVO = this.data.parametersVO;
        this.dataPopulateCurrentLevelIndicator(0);
        this.dataTablePopulationParameterList();
        if (this.currentLevelVO) {
          this.currentLevelName = this.currentLevelVO.parameterName;
        }
      }
      this.loading = false;
    })
  }

  dataPopulateCurrentLevelIndicator(flag) {

    let indicatorList = this.currentLevelVO.indicatorVO;
    this.jsonArrayCurrentLevelList = [];
    let index = 1;
    if (flag == 0) {
      if (Object.keys(indicatorList)!= null && Object.keys(indicatorList).length > 0) {
        indicatorList = indicatorList.slice(0, 3);
      }
    }
    for (let currentLevelInd of indicatorList) {
      let newArray = {};
      newArray['id'] = currentLevelInd.id;
      newArray['Indicator'] = currentLevelInd.indicatorName;
      newArray['Actual'] = currentLevelInd.actual;
      newArray['UnitOfmeasurement'] = '-';
      if (currentLevelInd.unitOfMeasurement != null && currentLevelInd.unitOfMeasurement.length > 0) {
        newArray['UnitOfmeasurement'] = currentLevelInd.unitOfMeasurement;
      }
      newArray['Sno'] = index;
      newArray['Critical'] = currentLevelInd.critical;
      newArray['Year'] = currentLevelInd.year;

      index++;
      this.jsonArrayCurrentLevelList.push(newArray);
    }

    if (flag == 0) {
      this.dataSourceCurrentLevelIndicator = new MatTableDataSource(this.jsonArrayCurrentLevelList);
    }
    else {
      this.dataSourceCurrentLevelIndicatorPopUp = [];
      this.dataSourceCurrentLevelIndicatorPopUp = new MatTableDataSource(this.jsonArrayCurrentLevelList);
      this.dataSourceCurrentLevelIndicatorPopUp.paginator = this.paginator;
    }

    this.displayedColumnsCurrentLevelIndicator = ['SDGCritical', 'SDGIndicator', 'SDGUnitOfmeasurement', 'SDGActual']
  }

  dataPopulateParameterIndicator(indicatorListParam, targetFlag, parameterId, popupFlag) {
    this.jsonArrayParameterIndicatorList = [];
    this.parameterId = parameterId;
    let indArr = [];
    let index = 1;
    if (indicatorListParam) {
      if (popupFlag == 0)
        indArr = indicatorListParam.slice(0, 4);
      else
        indArr = indicatorListParam;
    }
    for (let indicator of indArr) {
      var newArray = {};
      newArray['Sno'] = index;
      newArray['Indicator'] = indicator.indicatorName;
      newArray['Actual'] = indicator.actual;
      newArray['UnitOfmeasurement'] = '-';
      if (indicator.unitOfMeasurement != null && indicator.unitOfMeasurement.length > 0) {
        newArray['UnitOfmeasurement'] = indicator.unitOfMeasurement;
      }
      newArray['Year'] = indicator.year;

      if (targetFlag) {
        if (indicator.achievement == null || indicator.achievement == null)
          newArray['Achievement'] = 0;
        else
          newArray['Achievement'] = String(indicator.achievement);
        newArray['AchievementColorCode'] = indicator.achievementColorStatus;

        if (indicator.targetFlag === true)
          newArray['Target'] = indicator.target;
        else
          newArray['Target'] = "-";
        newArray['Critical'] = indicator.critical;
      }

      this.jsonArrayParameterIndicatorList.push(newArray);
      index++;
    }
    if (popupFlag == 0) {
      let dataSourceList = new MatTableDataSource(this.jsonArrayParameterIndicatorList);
      return dataSourceList;
    }
    else {
      this.dataSourceParameterPopUp = new MatTableDataSource(this.jsonArrayParameterIndicatorList);
      this.displayColParameter = this.displayedColumnsParameter(targetFlag);
    }
  }

  getParameterIndicatorForPopUp(parameterId, targetFlagCheck) {
    this.dataPopulateParameterIndicator(this.parameterData[parameterId], targetFlagCheck, parameterId, 2);
  }

  dataTablePopulationParameterList() {

    let parameterDetails = this.parameterVO;
    this.jsonArrayParameterList = [];
    let incrementFlag = 1;
    for (let param of parameterDetails) {
      let parameterIndicatorDetails = [];
      parameterIndicatorDetails = param.indicatorVO;
      this.parameterData[param.id] = parameterIndicatorDetails;
      this.subParamCount[param.id] = param.noSubParameters;
      this.dataSourceParameterIndicators[param.id] = this.dataPopulateParameterIndicator(parameterIndicatorDetails, param.targetFlag, param.id, 0);
      this.displayedColumnsParameterIndicators[param.id] = this.displayedColumnsParameter(param.targetFlag);

      if (Object.keys(parameterIndicatorDetails) != null && Object.keys(parameterIndicatorDetails).length > 0) {
        var newArray = {};
        newArray['sno'] = incrementFlag;
        newArray['paramIndSize'] = Object.keys(parameterIndicatorDetails).length;
        newArray['id'] = param.id;
        newArray['parameterName'] = param.parameterName;
        newArray['countryCount'] = param.noOfCountries;
        newArray['companyCount'] = param.noActiveCompanies;
        newArray['companyCount'] = param.noActiveCompanies;
        newArray['investment'] = param.totalInvestment;
        newArray['targetFlagSector'] = param.targetFlag;
        this.nextLevelMetaName = param.levelName;
        this.jsonArrayParameterList.push(newArray);
        incrementFlag++;
      }
    }
    this.dataSourceParameter = new MatTableDataSource(this.jsonArrayParameterList);
    // this.dataSourceParameter.paginator = this.paginator.toArray()[0];
  }

  displayedColumnsParameter(targetCheck) {

    let dispColSector = [];
    if (targetCheck) {
      return dispColSector = ['Critical', 'Indicator', 'UnitOfmeasurement', 'Target', 'Actual', 'Achievement', 'Year']
    }
    else {
      return dispColSector = ['Critical', 'Indicator', 'UnitOfmeasurement', 'Actual', 'Year']
    }
  }

  doughnutChartData(percentage: number) {
    if (percentage > 100)
      percentage = 100;
    this.doughnutData = [percentage, (100 - percentage)];
    return this.doughnutData;
  }

  OptionsAchievement() {
    this.chartOptions = {
      responsive: true,
      cutoutPercentage: 40,
    };
    return this.chartOptions;
  }

  chartColor(colorStatus: number) {

    /* 3=> green , 2=> amber , 1 => red */
    if (colorStatus == 3)
      this.chartColors = [{ backgroundColor: ["#34a853", "#dddddd"] }];
    else if (colorStatus == 2)
      this.chartColors = [{ backgroundColor: ["#f39700", "#dddddd"] }];
    else
      this.chartColors = [{ backgroundColor: ["#ea4335", "#dddddd"] }];

    return this.chartColors;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSourceParameter.filter = filterValue;
    if (this.dataSourceParameter.filteredData.length == 0) {
      this.flag = true;
    } else {
      this.flag = false;
    }
  }

  getParameterDrillDownDetails(parameterId: number) {

    if (this.subParamCount[parameterId] > 0) {
      this.loading = true;
      this.levelNo = this.levelNo + 1;
      this._impactAssessmentService.getParameterLevelDetailsList(this.levelNo, parameterId, this.capitalId, this.sdgId, this.subCatId).subscribe((data) => {
        if (data) {
          this.data = data[0];
          this.currentLevelVO = this.data.parameterDetails;
          this.parameterVO = this.data.parametersVO;
          this.dataPopulateCurrentLevelIndicator(0);
          this.dataTablePopulationParameterList();
          if (this.currentLevelVO) {
            this.currentLevelName = this.currentLevelVO.parameterName;
          }
        }
        this.loading = false;
      })
    }
    else {
      let transVO = {};
      this.levelNo = this.levelNo + 1;
      transVO['levelNo'] = this.levelNo;
      transVO['parameterId'] = parameterId;
      transVO['impactFlag'] = this.impactFlag;
      transVO['impactName'] = this.impactName;
      transVO['capitalId'] = this.capitalId;
      transVO['sdgId'] = this.sdgId;
      transVO['subCategoryId'] = this.subCatId;
      this._router.navigate(['/impactAssessment/dashboard/company-wise-drilldown'], { queryParams: transVO, skipLocationChange: true });
    }
  }

  isNumber(val) { return isNaN(+val); }
}