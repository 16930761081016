import { Component, OnInit ,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, AbstractControl, AbstractControlOptions, ValidationErrors, FormGroupDirective } from '@angular/forms';
import { IndicatorMasterService } from '../../indicator-master.service';
import { CustomValidationsService } from '../../../../shared/Validations/custom-validations.service';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component'; 
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-rating-group',
  templateUrl: './add-rating-group.component.html',
  styleUrls: ['./add-rating-group.component.css']
})
export class AddRatingGroupComponent implements OnInit {

  addRatingGroupForm: FormGroup;
  
  editRatingGroup:boolean;
  topWrapper:any;
  errorMsg:string = '';

  @ViewChild ("topWrap") topWrap;
  constructor(private addEditSuccessComponent: AddEditSuccessComponent,private masterService: IndicatorMasterService, private formBuilder: FormBuilder,private customValidationsService:CustomValidationsService) {
    this.addRatingGroupForm = this.createFormGroup();
    
   }
  ngOnInit() {
    this.topWrapper=this.topWrap;
    
    //For add/update button
    this.editRatingGroup = false;
  }

  onSubmit(){
    
   
    if(this.addRatingGroupForm.valid){
     
      this.masterService.createRatingGroup(this.addRatingGroupForm.value).subscribe((myform)=>{
        if(this.addRatingGroupForm.get('id').value!=null)
          var message="Successfully edited Rating Group";
        else
          var message = "Successfully added Rating Group";
          this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
          location.reload(); 
        });
       
      
    },(error)=>{
      this.errorMsg = error.error.message;
    });

    }
    
  }
  
  defaultValues(p)
  {
    this.editRatingGroup = true;
    this.addRatingGroupForm.patchValue({
      id:p.id,
      name:p.name,
      
    });
  }

  reset(){
    this.errorMsg = '';
    this.editRatingGroup = false;
    this.topWrap.nativeElement.classList.remove("top-form-wrapper-highlight");
  }

  createFormGroup(){
    return this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(1000), this.customValidationsService.noWhitespaceValidator]],
      id:[]
    }, { updateOn: 'blur' })
  }

uniqueRatingGrpNameValidator() {
    return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      var id = 0
      if (this.addRatingGroupForm && this.addRatingGroupForm.controls.id.value) {
        id = this.addRatingGroupForm.controls.id.value;
      }      
      return this.masterService.checkRatingGrpNameValidation(id, c.value).pipe(
        map(val => {
          console.log(val)
          if (val == 1) {
            return { 'uniqueName': true };
          }
        })
      );
    };

  }



}
