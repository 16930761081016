import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { BaseUrlService } from '../../../shared/base-url.service';

@Injectable({
  providedIn: 'root'
})
export class PerformanceFrameworkHigherService {
  private baseUrl = BaseUrlService.PERFORMANCEFRAMEWORKBASEURL;
  private indicatorWrapperUrl = BaseUrlService.INDICATORBASEURL;

  constructor(private http: HttpClient) { }

  getFrameworkApplicableParameters(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getallFrameworkApplicablelevels`);

  }
  getFrameworkDetails(frameworkId): Observable<any> {
    return this.http.get(`${this.baseUrl}/viewFrameworkDetailsById/` + frameworkId);
  }
  getSchedulesForIndicator(frameworkIndicatorDetailsId,indicatorTypeId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getSchedulesForIndicator/` + frameworkIndicatorDetailsId+'/'+indicatorTypeId);
  }

  getConfigurationAndDataForParameter(parameterId: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/getParameterConfigurationDetailsById/` + parameterId);
  }
  fetchPerformanceFrameworkDetailsById(frameworkId: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/viewFrameworkDetailsById/` + frameworkId)
  }
  getListOfUsers(): Observable<any> {
    return this.http.get(BaseUrlService.MASTERBASEURL + `/listUsers`);
  }

  getWebserviceForIndicator(frameworkIndicatorDetailsId): Observable<any> {
    return this.http.get(`${this.baseUrl}/getWebserviceForHigherLevel/` + frameworkIndicatorDetailsId);
  }
  
  getArchievedFrameworkforHigher(parameterId: number, levelId: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/getArchievedFrameworkforHigher/` + parameterId + '/' + levelId);

  }

  submitWebservice(indicatorConfigurationVO: any) {

    let url = this.baseUrl + '/createOrUpdateHigherLevelWebservice';
    let body = JSON.stringify(indicatorConfigurationVO);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);
  }
  saveIndicatorSchedules(indicatorVO: any) {
    let body = JSON.stringify(indicatorVO);
    let url = this.baseUrl + '/saveIndicatorDetailsScheduleforHigher';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);
  }

  submitPerformanceFramework(parameterDataVO: any) {


    let body = JSON.stringify(parameterDataVO);
    console.log(body)
    let url = this.baseUrl + '/createorUpdatePerformanceFramework';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);
  }
 
    getActiveListOfTemplates(templateRequestVO): Observable<any> {
    let url = this.indicatorWrapperUrl + '/getTemplateData';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }) 
    }; 
    return this.http.post(url, templateRequestVO, httpOptions);

  }
  getTemplateDetails(templateRequestVO):Observable<any>
  {
    let url = this.indicatorWrapperUrl + '/getTemplateData';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }) 
    }; 
    return this.http.post(url, templateRequestVO, httpOptions);

  }

  deleteFrameworkIndicator(frameworkIndicatorDetailsId:number):Observable<any>
  {
    let body = JSON.stringify(frameworkIndicatorDetailsId);
    let url = this.baseUrl + '/deleteFrameworkIndicatorDetailsById';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }) 
    }; 
    return this.http.post(url, body, httpOptions);

  }
   archiveSelectedFrameworks(selectedFrameworksArray: any): Observable<any> {


    let body = selectedFrameworksArray;
    let url = this.baseUrl + '/archieveFrameworkDetailsforHigher';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);
  }
  saveBaselineForIndicator(indicatorConfigVO){
    let body = JSON.stringify(indicatorConfigVO); 
    let url = this.baseUrl+'/saveBaseLineConfigurationForParameter';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
       
      })
    };

    return this.http.post(url, body, httpOptions); 
  }
  getBaselineValue(indicatorVO): Observable<any> {
    let body = JSON.stringify(indicatorVO); 
    let url = this.baseUrl+'/getBaselineValueForParameter';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
       
      })
    };
    return this.http.post(url, body, httpOptions); 
  }
}
