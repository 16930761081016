import { Component, OnInit,ViewChild } from '@angular/core';
import {AssessmentTemplateMasterdataService} from '../../../assessment-template-masterdata.service';
import {MatTableDataSource,MatDialog,MatDialogConfig} from '@angular/material';
import {MatPaginator} from '@angular/material';
import {MatSort} from '@angular/material';
import swal from 'sweetalert2';
import { InitiateAssessmentComponent } from '../initiate-assessment/initiate-assessment.component';
import {DatePipe} from '@angular/common';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-list-assessment-companies',
  templateUrl: './list-assessment-companies.component.html',
  styleUrls: ['./list-assessment-companies.component.css']
})
export class ListAssessmentCompaniesComponent implements OnInit {

  assessmentCompanyList :  any;
  jsonArray:any;
  dataSource: any;
  displayedColumns : any;
  flag : boolean = false;
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(private assessmentRecordService :  AssessmentTemplateMasterdataService,
              private dialog:MatDialog,private router:Router ) {}

  ngOnInit() {
  this.assessmentRecordService.fetchAllCompanies().subscribe(data => {
    this.assessmentCompanyList = data;
    this.dataTablePopulation(this.assessmentCompanyList);
    } )
  }

   dataTablePopulation(assessmentCompanyList)
  {
    let count=0;
    this.displayedColumns=[];
    this.jsonArray =[];
    
    if(assessmentCompanyList.length > 0)
    { 
      for(let companyVal of assessmentCompanyList )
       {
          var newArray ={};
          newArray['Sno'] = ++count;
          newArray['companyCode'] = companyVal.companyCode
          newArray['companyName'] = companyVal.companyName;
          newArray['period']          = (companyVal.startDateToDisplay!=null && companyVal.endDateToDisplay!=null)?(this.formatDate(companyVal.startDateToDisplay)+" To "+this.formatDate(companyVal.endDateToDisplay)):"-";
          newArray['id']              =  companyVal.id;
          newArray['frameworkId']     = companyVal.frameworkDetailsId;
          newArray['reportedDate']    =  "-";
          newArray['assessmentStatus']    =  companyVal.assessmentStatus;
          this.jsonArray.push(newArray);

       }
       this.displayedColumns=['Sno','companyCode','companyName','period','reportedDate','Actions'];
       this.dataSource = new MatTableDataSource(this.jsonArray);
       setTimeout(()=> {
          this.dataSource.paginator = this.paginator;
       }, 1000); 
       this.dataSource.sort = this.sort;      
    }
  }
  getCompanySelected(id)
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width="80%";
    dialogConfig.data = id;
    const dialogRef = this.dialog.open(InitiateAssessmentComponent, dialogConfig);
  }
 
   formatDate(inputDate) {
    var pipe = new DatePipe('en-US'); // Use your own locale
    let newdate = new Date(inputDate).toISOString().split('T')[0];
    return pipe.transform(newdate, 'dd-MM-yyyy');

  }  

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    if (this.dataSource.filteredData.length == 0) {
      this.flag = true;
    } else {
      this.flag = false;
    }
    setTimeout(()=> {
          this.dataSource.paginator = this.paginator;
     }, 1000); 
  }
listAssessmentForCompany(companyVO)
  {
    console.log(companyVO.id)
    this.assessmentRecordService.getCompanyVO(companyVO);
    this.router.navigate(['/assessmentListData/'+companyVO.id]);
  }
}
