import { Component, OnInit,ViewChild,Inject } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators,FormControl } from '@angular/forms';
import { AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import swal from 'sweetalert2';
import {MatTableDataSource,MatPaginator,MatSort, MatDialogRef,MatDialog,MAT_DIALOG_DATA} from '@angular/material';
import { Router } from '@angular/router';
import { AddEditSuccessComponent } from '../../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';


@Component({
  selector: 'app-add-more-questions',
  templateUrl: './add-more-questions.component.html',
  styleUrls: ['./add-more-questions.component.css']
})
export class AddMoreQuestionsComponent implements OnInit {
questionSelectedObjs: Array<any> = [];
  displayedColumns: string[];
  dataSource: any;
  templateForm: FormGroup;
  factorList:any;
  subFactorList:any;
  questionList:any;
  flag : boolean=true;
  initialQuestionList:any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selectedQuestionCount:number;
  qstnIdList:any=[];
    selectedQuestionList: any = [];
  constructor(private addEditSuccessComponent: AddEditSuccessComponent,private _fb: FormBuilder,private dialogRef:MatDialogRef<AddMoreQuestionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {idList:any,selectedQuestion:any},
   private assessmentTemplateService: AssessmentTemplateMasterdataService,private router:Router) { }

  ngOnInit() {
this.qstnIdList=this.data.idList;
 this.selectedQuestionList=this.data.selectedQuestion;
    this.assessmentTemplateService.loadMasterDataForTemplate().subscribe(data =>{
      var masterData :any = data;
      this.factorList = masterData.factorVOList;
      this.subFactorList = masterData.subFactorVOList;
      this.initialQuestionList = masterData.questionVOList;
      this.fetchDataTable(this.initialQuestionList, this.qstnIdList,this.selectedQuestionList);

    }) 
    this.templateForm = this.createFormGroup();
  }

  createFormGroup(){
    return this._fb.group({
      id:[0],
      factorVOList:[''],
      subFactorVOList:['']


    })
  }

  factorChange(event){

    if(event && event.length>0)
    {
      this.assessmentTemplateService.loadSubFactorsforTheSelectedFactors(event).subscribe(data=>{
        this.subFactorList = data;

      })
    }


  }
onNoClick(): void {
  this.dialogRef.close();
}

  subFactorChange(event){
    if(event && event.length>0)
    {
      this.assessmentTemplateService.loadQuestionsforTheSelectedSubfactors(event).subscribe(data=>{
        this.questionList = data;
        this.selectedQuestionCount = 0;
        this.questionSelectedObjs =[];
        this.fetchDataTable(this.questionList,this.qstnIdList,this.selectedQuestionList)
      })
    }
    else
    {
            this.fetchDataTable(this.initialQuestionList, this.qstnIdList,this.selectedQuestionList);

    }

  }


  fetchDataTable(questionList,qstnIds,selectedQuestionList)
  {
       var jsonArray = [];
       var count =0;

         for( let i of questionList){
              
             if(qstnIds.indexOf(i.id) == -1) {
                  var newArray = {};
                  newArray['Sno'] = i.questionText;
                  newArray['questionText'] = i.questionText;
                  newArray['id'] = i.id;
                  jsonArray.push(newArray);
              }
         }
         if(jsonArray.length != 0 ) 
         {
                this.flag = true;
         }
         else
         {
            this.flag=false;
         }

          this.displayedColumns=['Sno','Question']
          this.dataSource = new MatTableDataSource(jsonArray);
          setTimeout(() => this.dataSource.paginator = this.paginator);
          this.dataSource.sort = this.sort;
    }

    applyFilter(filterValue: string) {
      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.filter = filterValue;
      if (this.dataSource.filteredData.length != 0) {
        this.flag = true;
      } 
      else {
        this.flag = false;
      }
    }

//   onChange(element:any, isChecked: any) {
//     if(isChecked.checked) {
//       element['primaryQuestionFlag']='Y';
//       element['skipLogicFlag']='N';
//       element['criticalFlag']='Y';
//       element['templateQuestionId']=null;
//       element['responseOptionVOList']=null;    
//       this.questionSelectedObjs.push(element);
//     } else {
//       let index = this.questionSelectedObjs.indexOf(element);
//       this.questionSelectedObjs.splice(index,1);
//     }

//     this.selectedQuestionCount = this.questionSelectedObjs.length;

// }
onChange(element:any, isChecked: any) {
  var assessmentTemplateQuestionVO = {};
  var viridiTreeNode = {};
 if(isChecked.checked) {
     element['primaryQuestionFlag']='Y';
     element['skipLogicFlag']='N';
     element['criticalFlag']='Y';
     assessmentTemplateQuestionVO['id']=0;
     assessmentTemplateQuestionVO['questionVO']=element;
     assessmentTemplateQuestionVO['responseOptionVO']=null;
     viridiTreeNode['nodeData']=assessmentTemplateQuestionVO;
     viridiTreeNode['childNodes']=null;
     viridiTreeNode['parentNode']=null;
   this.questionSelectedObjs.push(viridiTreeNode);
 } else {
   let index = this.questionSelectedObjs.indexOf(viridiTreeNode);
   this.questionSelectedObjs.splice(index,1);
 }

 this.selectedQuestionCount = this.questionSelectedObjs.length;

} 
onNext(){
  if(this.selectedQuestionCount)
  {
      if(this.templateForm.valid){
      let templateDetails={};
      templateDetails['templateFormValue']=JSON.stringify(this.templateForm.value);
      templateDetails['questionSelectedObjs']=JSON.stringify(this.questionSelectedObjs);
      templateDetails['refreshFlag']=false;
      //Route to the main page with the reqd details
      this.dialogRef.close(templateDetails);
 }
}
 else
 {
    let msg="Please select Atleast one question";
    var title="Error";
  //   swal({
  //   title: 'Error',
  //   text: msg,
  //   type: 'error',
  //   confirmButtonText: 'Ok',
  // })
  this.addEditSuccessComponent.showFailedSwal(msg,title);

 }



}
resetForm(){
  this.templateForm.reset();
  this.fetchDataTable(this.initialQuestionList,this.qstnIdList,this.selectedQuestionList);
  this.questionSelectedObjs =[];
  this.selectedQuestionCount =0;
}

}