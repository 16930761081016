import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-view-record-page',
  templateUrl: './view-record-page.component.html',
  styleUrls: ['./view-record-page.component.css']
})
export class ViewRecordPageComponent implements OnInit {
  assessmentId:number;
  userId:number;
  transactionName:string='';
  initiatedBy:string='';
  initiatedDate:string='';
  assessmentVO : any;
  optionarray:any;
  questionVOList :any;
  questionList: any =[];
  flag : boolean = false;
  questionArray:any=[];
  dataSource:any;
  displayedColumns : any =[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('table') table: MatTable<any>;
  constructor(public router:Router,private activatedRoute: ActivatedRoute,
    private assessementTemlateMasterService:AssessmentTemplateMasterdataService,) { }

  ngOnInit() {
    
    this.activatedRoute.queryParams.subscribe(params => {
      this.assessmentId = params.assessmentId;
      this.userId = params.userId;
     
    }) 
    let parentQuestionId = 0;
    let Sno ='';
    this.assessementTemlateMasterService.getAssessmentById( this.assessmentId,this.userId)
          .subscribe((data) => {
            console.log("view data")
            console.log(data)
           this.assessmentVO = data;
           this.transactionName = this.assessmentVO.companyVO.companyName;
           this.initiatedBy = this.assessmentVO.initiatedBy;
           this.initiatedDate = this.assessmentVO.initiatedDateString;
           this.questionVOList = this.assessmentVO.assessmentResponseVO.questionList;
           if(this.questionVOList.length==0)
           {
             this.flag =true;
           }
           else
           {
                this.dataTablePopulation(this.questionVOList,parentQuestionId,Sno);
                this.createMatTableForQuestionData();
           } 
    });
  }
  dataTablePopulation(questionVOList,parentQuestionId,Sno)
  {

      let count = 0;
      for(let question of questionVOList){
          let  questionjson = {};
          let questionId = question.id;
          if(Sno!='')
          {
             questionjson['Sno'] =   Sno+"."+ ++count;
          }
          else
          {
             questionjson['Sno'] = ++count;


          }

          questionjson['id'] = questionId;
          questionjson['questionText'] = question.questionText;
          if(question.responseOptionVO!=null)
          questionjson['response'] = question.responseOptionVO.responseName;
          this.questionArray.push(questionjson);
          if(question.responseOptionVO!=null)
          {
            let responseOptionVO = question.responseOptionVO;
            for (let responseList of question.responseOptionsVOList)
            {
              if(responseList.id == responseOptionVO.id)
              {
                let nxtLevelQuestionList =   responseList.questionVOList;
                if(nxtLevelQuestionList!=null)
                {
                   this.dataTablePopulation(nxtLevelQuestionList,questionId,questionjson['Sno']);
                }

              }
            }

          }
                  
        }
     
        
       
  }
  createMatTableForQuestionData()
  {
       this.displayedColumns = ['Sno', 'questionText' ,'response']
       this.dataSource = new MatTableDataSource(this.questionArray);
	   setTimeout(()=> {
          this.dataSource.paginator = this.paginator;
    	}, 1000);       
    	this.dataSource.sort = this.sort;
  
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    if (this.dataSource.filteredData.length == 0) {
      this.flag = true;
    } 
    else {
      this.flag = false;
    }
    setTimeout(()=> {
          this.dataSource.paginator = this.paginator;
    }, 1000);   
  }
}

