import { Injectable } from '@angular/core';
import {Http, Response , Headers, RequestOptions, URLSearchParams} from '@angular/http';
//import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Observable } from 'rxjs/Rx'
import { BaseUrlService } from '../../../shared/base-url.service';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CompanyService {

  private baseUrl = BaseUrlService.REPORTBASEURL;

  private headers = new Headers({'Content-type':'application/json',
   'Authorization': `Bearer ${sessionStorage.getItem('token')}`
});
  private options = new RequestOptions({headers:this.headers});
  private params = new URLSearchParams();
  private company:any;
  
  constructor(private _http:Http) { }

  getAllCompanies(){
    return this._http.get(this.baseUrl+'/getAllCompanies',this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  fetchLandingPageCompanies(){
    return this._http.get(this.baseUrl+'/companies',this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  getCompanyDetails(id:number){
    return this._http.get(this.baseUrl+'/companyDetails/'+id,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  getCompanyEsgHistory(id:number){
    return this._http.get(this.baseUrl+'/companyEsgHistory/'+id,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  getCompanyCompanyList(id:number){
    return this._http.get(this.baseUrl+'/companyCompanyList/'+id,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  getCompanySelectedIndicators(id:number){
    return this._http.get(this.baseUrl+'/companyIndicatorList/'+id,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  getSelectedIndicators(esgType:number,companyId:number,indList:any){
    return this._http.get(this.baseUrl+'/companySelectedIndicators/'+esgType+'/'+companyId+'/'+indList,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  getDrillDown(year:number,indicatorId:number,companyId:number){
    return this._http.get(this.baseUrl+'/companyDrillDown/'+year+'/'+indicatorId+'/'+companyId,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  getCompaniesPageWise(pageNo:number){
    return this._http.get(this.baseUrl+'/companies/'+pageNo,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }
  
  getCompanyDetailsWithoutTransaction(id:number){
     this.params.set("query","{getCompany(id:"+id+"){companyName,esgColorStatus,revenue,overallEsgQuotient,pastOverallFunding,environment,social,governance,overallEsgQuotient,companyImgFileName,companyParameterMappingReportSet{parameterLevel1{levelName,parameters{parameterName}},parameterLevel2{levelName,parameters{parameterName}}}}}");
    this.options.params = this.params;
    return this._http.get(this.baseUrl+'/graphql',this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  getCompanyRatingAgencyDetails(id:number){
    return this._http.get(this.baseUrl+'/getCompanyDetails/'+id,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }
  
  fetchCompanyComparisonDetails(companyId: any, ratingAgencyId: any) {
    return this._http.get(this.baseUrl+'/getIndustryComparisons/'+companyId+'/'+ratingAgencyId,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
    }
  errorHandler(error:Response){
    console.log("Error");
    return Observable.throw(error || "SERVER_ERROR");
  }

  setter(company:any){
    this.company = company;
  }

  getter(){
    return this.company;
  }
}

