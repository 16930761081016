import { Injectable } from '@angular/core';
import {Http, Response , Headers, RequestOptions} from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { BaseUrlService } from '../../../shared/base-url.service';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ImpactAssessmentService {


  private baseUrl = BaseUrlService.REPORTBASEURL;
  getBaseUrl() : string{
    return this.baseUrl;
  }
  private headers = new Headers({'Content-type':'application/json',
  'Authorization': `Bearer ${sessionStorage.getItem('token')}`
});
  private options = new RequestOptions({headers:this.headers});
  constructor(private _http:Http) { }
  
  getCapitalViewDetails(): any {
    return this._http.get(this.baseUrl+'/getCapitalViewDetails',this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }
  getCapitalViewDrillDownDetails(capitalId:number){
    return this._http.get(this.baseUrl+'/getCapitalViewDrillDownDetails/'+capitalId,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  getSDGList(){
    return this._http.get(this.baseUrl+'/getSDGViewDetails',this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  getSDGDrillDownDetails(sdgId:number){
    return this._http.get(this.baseUrl+'/getSDGDrillDownDetails/'+sdgId,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  getParameterLevelDetailsList(levelNo:number, parameterId:number, capitalId:number, sdgId:number, subCategoryId:number){
    return this._http.get(this.baseUrl+'/impactAssessmentReports/'+levelNo+ '/' +parameterId + '/'+ capitalId + '/' + sdgId + '/' + subCategoryId,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  getCompanyIndicatorDetails(level_no:number,company_id:number,company_selected_parameter_id:number,subcategoryId:number, capitalId:number, sdgId: number){
    return this._http.get(this.baseUrl+'/getImpactCompanyIndicators/'+level_no+'/'+company_id+'/'+company_selected_parameter_id+'/'+subcategoryId + '/' + capitalId + '/' +sdgId,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  getCompanyLevelDetails(level_no:number,parameter_id:number, capitalId:number, sdgId: number, subCategoryId :number){
    return this._http.get(this.baseUrl+'/getImpactCompanyDetails/'+level_no+'/'+parameter_id + '/' + capitalId + '/' +sdgId + '/' +subCategoryId,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  errorHandler(error:Response){
    console.log("Error");
    return Observable.throw(error || "SERVER_ERROR");
  }
} 
