import { Component, OnInit,ViewChild,Inject } from '@angular/core';
import {MatTableDataSource,MatPaginator,MatSort, MatDialogRef,MatDialog,MAT_DIALOG_DATA} from '@angular/material';
@Component({
  selector: 'app-country-popup',
  templateUrl: './country-popup.component.html',
  styleUrls: ['./country-popup.component.css']
})
export class CountryPopupComponent implements OnInit {

  public countryName:any;
  displayedColumns:any;
  dataSource:any;
  countryRef:any = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private dialogRef:MatDialogRef<CountryPopupComponent>, @Inject(MAT_DIALOG_DATA) public data:any) { }

  ngOnInit() {
    
    this.countryName=this.data;
    let countryDetails: any = this.data;
    var jsonArray=[];
    for (let ind of countryDetails.indicatorVOList){
      var newArray = {};
    newArray['id'] = ind.id;
    newArray['name'] = ind.indicatorName;
    newArray['target'] = ind.target;
    newArray['actual'] = ind.actual;
    newArray['unit'] = '-';
    if(ind.unitOfMeasurement != null && ind.unitOfMeasurement.length > 0){
      newArray['unit'] = ind.unitOfMeasurement;
    }
    newArray['Critical'] =ind.critical;
    jsonArray.push(newArray);
    }
    this.displayedColumns=['Critical','name','target','actual','unit']
    this.dataSource=[];
    this.dataSource = new MatTableDataSource(jsonArray);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.countryRef=[];
    this.countryRef['name'] = countryDetails.name;
    this.countryRef['company'] = countryDetails.activeCompanies;
    this.countryRef['actTrans'] = countryDetails.activeCompanies;
    this.countryRef['transAmt'] = countryDetails.totalInvestment;
    this.countryRef['companyesg'] = countryDetails.esg;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  isNumber(val) { return isNaN(+val); }
}
