import { Component, OnInit,ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl, FormBuilder, Validators ,ValidatorFn,AbstractControl} from '@angular/forms';
import {AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import swal from 'sweetalert2';
import {ListSubFactorComponent } from '../list-sub-factor/list-sub-factor.component';
import { CustomValidationsService } from '../../../../../shared/Validations/custom-validations.service';
import { AddEditSuccessComponent } from '../../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';

@Component({
  selector: 'app-add-sub-factor',
  templateUrl: './add-sub-factor.component.html',
  styleUrls: ['./add-sub-factor.component.css']
})
export class AddSubFactorComponent implements OnInit {

  addSubfactorForm : FormGroup;
  factorSelected : number;
  factorList : any;
  isSubmit : boolean;
  defaultArrayValues:any;
  topWrapper:any;
  subFactorCodeList=[];
  subFactorNameList=[];
  @ViewChild ("topWrap") topWrap;
   @ViewChild('subfactorForm') subfactorForm;

  constructor(private addEditSuccessComponent: AddEditSuccessComponent,private assessmentTemplateService : AssessmentTemplateMasterdataService, private formBuilder: FormBuilder,private customValidationsService:CustomValidationsService
  ) {
      this.addSubfactorForm = this.createFormGroup()

   }

  ngOnInit() {
    this.factorSelected = 0;
    this.isSubmit = false;
    this.fetchAllFactors();
    this.topWrapper=this.topWrap;
  }

  createFormGroup() {
    this.setSubFactorCodeAndNameList(0);
      return this.formBuilder.group({
        id : [0],
        subFactorCode : ['', [Validators.required,Validators.maxLength(25),this.uniqueCode(),this.customValidationsService.noWhitespaceValidator]],
        subFactorName : ['', [Validators.required,Validators.maxLength(100),this.uniqueName(),this.customValidationsService.noWhitespaceValidator]],
        factorVO : this.formBuilder.group({
          id   : ['', Validators.compose([Validators.required])],
          code : [],
          name : [],
        }),
      });
  }

  fetchAllFactors() {
    this.assessmentTemplateService.getFactorList().subscribe(data=>{
      this.factorList = data;
    });
  }

  resetSubFactorForm(){
    this.isSubmit = false;
    //Reset to the original-- even if after edit make it as a new entry
    // this.addSubfactorForm.reset();
    // this.addSubfactorForm.get('factorVO').get('id').setValue(0);
    this.subfactorForm.resetForm(); 
    this.setSubFactorCodeAndNameList(0);

    this.topWrap.nativeElement.classList.remove("top-form-wrapper-highlight");   
  }

  onFactorChange(){
    this.factorSelected = this.addSubfactorForm.get('factorVO').get('id').value;
  }

  onSubmit() {
    this.isSubmit = true;
    if(this.addSubfactorForm.valid){
      this.assessmentTemplateService.createSubfactor(this.addSubfactorForm.value).subscribe(data=>{
        if(this.addSubfactorForm.get('id').value > 0){
          var msg = "Successfully edited Subfactor";
        }
        else {
          var msg = "Successfully added Subfactor";
        }
        // swal({
        //     title : 'Success',
        //     text : msg,
        //     type : 'success',
        //     timer : 2000,
        //     confirmButtonText : 'Ok',
        // })
        this.addEditSuccessComponent.showSuccessSwal(msg)
        .then((value)=>{
          this.resetSubFactorForm();
           this.fetchAllFactors();
        })
      },(error)=>{
        console.log(error);
      });
    }
    else{
      this.addSubfactorForm.get('subFactorCode').markAsTouched();
      this.addSubfactorForm.get('subFactorName').markAsTouched();
      this.addSubfactorForm.get('factorVO').get('id').markAsTouched();
      return;
    }
   
  }

  defaultValues(p)
  {
    this.setSubFactorCodeAndNameList(p.idVal);
    //setting default values for the form
    this.addSubfactorForm.patchValue({
      id:p.idVal,
      subFactorCode:p.subfactorCodeVal,
      subFactorName: p.subfactorNameVal,
      factorVO:{
        id:p.factorIdVal,
      }
    });
    // this.addSubfactorForm.patchValue({factorVO:{id: p.factorIdVal}});
  }
  setSubFactorCodeAndNameList(editId)
  {
    this.subFactorCodeList=[];
    this.subFactorNameList=[];
     this.assessmentTemplateService.getSubfactorList().subscribe(data => {
        let subfactorList = data;
          for(let key in data){
                if(data[key]['id'] != editId){
                    this.subFactorCodeList.push(data[key]['subFactorCode']);
                    this.subFactorNameList.push(data[key]['subFactorName']);
                }
          }
     });

  }

  public uniqueName(): ValidatorFn {
    return (control:AbstractControl) : {[key:string]:boolean} | null => {
        if (control.value !== undefined && this.subFactorNameList.length > 0 && control.value !=null) {
          if( this.subFactorNameList.includes((control.value).trim()))
          {
             return { 'unique': true };

          }
        }
        return null;
    };
  }
   public uniqueCode(): ValidatorFn {
    return (control:AbstractControl) : {[key:string]:boolean} | null => {
        if (control.value !== undefined && this.subFactorCodeList.length > 0 && control.value !=null) {
          if( this.subFactorCodeList.includes((control.value).trim()))
          {
             return { 'unique': true };

          }
        }
        return null;
    };
  }
   
}
