import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-skip-logic-question',
  templateUrl: './skip-logic-question.component.html',
  styleUrls: ['./skip-logic-question.component.css']
})
export class SkipLogicQuestionComponent implements OnInit {
  subQuestions: any=[];
  childData:any=[];
  questionList:any=[]
  @Input() assessmentId;
  @Input() assesssmentStatus
  @Input() userId
  @Input() assessmentVO
  @Input() set questionLists(val) {
    
    if(val!=undefined){
    this.questionList = val;
    }
  }
  @Input() set childQuestions(val) {
   
    if(val!=undefined){
    this.subQuestions = val;
    }

  }
 

  constructor( private activatedRoute: ActivatedRoute,
    private assessementTemlateMasterService: AssessmentTemplateMasterdataService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    
  }
  createSubQuestionListQuantitative(node, score) {
    if (this.questionList[node.templateQuestionId].valid) {
      let responseOption;
      let children: any = [];
      let l=[]
      node["children"] = [];
      if (node.skipLogicFlag == "Y") {
        if (score != null && score.length > 0) {
          setTimeout(() => {
            this.assessementTemlateMasterService.fetchQuestionListForQuantitativeData(
              node.id, score, this.userId, this.assessmentId
              )
              .subscribe(data => {
               
                this.childData=data
                responseOption = data;
                children = this.populateDataToQuestionForm(
                  responseOption.questionVOList,
                  children
                );
                node["children"] = children;
               // this.removeDataFromQuestionList(children,node.templateQuestionId)
              });
          }, 10);
        } 
      }
    }
  }
  createSubQuestionListQualitative(question, responseOptionId) {
   
   let responseOptionList;
   let responseOption;
   let children: any = [];
   question["children"] = [];
   if (question.skipLogicFlag == "Y") {
   
     responseOptionList = question.responseOptionsVOList;
    
     responseOption = responseOptionList.filter(
       responseOption => responseOption.id == responseOptionId
     );
   
     children = this.populateDataToQuestionForm(
       responseOption[0].questionVOList,
       children
     );
   
     question["children"] = children;
     //this.removeDataFromQuestionList(children,question.templateQuestionId)
   }
   else
   {
    
     question["children"]=[]
   }
 }
 populateDataToQuestionForm(questionVOList, data) {
 
  let questionId;
  let children: any = [];
  let fileName='';
  let fileUploadName='';
  for (let question of questionVOList || []) {
    questionId = question.templateQuestionId;

    if (question.questionType == "Quantitative") {
      this.questionList[questionId] = this.createQuestionListQuantitativeForm(questionId);
    }
    else {
      this.questionList[questionId] = this.createQuestionListQualitativeForm(questionId);
    }
    if (question.responseOptionVO != null) {
      children = [];
      this.populateDataForEdit(question, children);
      fileName = question.responseOptionVO.fileName;
      fileUploadName = question.responseOptionVO.fileUploadName;
      this.questionList[question.templateQuestionId].patchValue({ responseOptionVO: { 
        id: question.responseOptionVO.id, 
        qunatitativeScore: question.responseOptionVO.responseName,
        fileName: fileName,
        fileUploadName: fileUploadName,
        remarks: question.responseOptionVO.remarks,
        verificationRemarks:question.responseOptionVO.verificationRemarks,
        verificationStatus:question.responseOptionVO.verificationStatus
      } });
    }
    data.push({
      'id': questionId,
      'templateQuestionId':questionId,
      'questionText': question.questionText,
      'primaryQuestionFlag': 'Y',
      'responseOptionsVOList': question.responseOptionsVOList,
      'questionType': question.questionType,
      'skipLogicFlag': question.skipLogicFlag,
      'fileName': fileName,
      'fileUploadName': fileUploadName,
      'children': children
    });
  }
  return data;

}
populateDataForEdit(question, children) {

  let responseOption;
  let responseOptionVO = question.responseOptionVO;
  let responseOptionList;


  if (question.questionType == "Quantitative") {
    this.assessementTemlateMasterService.fetchQuestionListForQuantitativeData(question.templateQuestionId,   responseOptionVO.responseName.trim(), this.userId, this.assessmentId)
      .subscribe((data) => {
        responseOption = data;
        this.populateDataToQuestionForm(responseOption.questionVOList, children);
     
      });
  }
  else {
    responseOptionList = question.responseOptionsVOList;
    responseOption = responseOptionList.filter(responseOption => responseOption.id == responseOptionVO.id);
    this.populateDataToQuestionForm(responseOption[0].questionVOList, children);
  }

}
createQuestionListQualitativeForm(questionId) {

  return this.formBuilder.group({
    id: [questionId],
    type: [1],
    responseOptionVO: this.formBuilder.group({
      id: ['',Validators.required],
      fileName:[],
      fileUploadName:[],
      remarks:[],
      verificationStatus:[],
      verificationRemarks:[]
    })
  });


}
createQuestionListQuantitativeForm(questionId) {

  return this.formBuilder.group({
    id: [questionId],
    type: [2],
    responseOptionVO: this.formBuilder.group({
     qunatitativeScore: [,[Validators.pattern(/^-?(0|[1-9]\d*)?$/),Validators.required]], 
      fileName:[],
      fileUploadName:[],
      remarks:[],
      verificationStatus:[],
      verificationRemarks:[]
    })
  });


}

handleFileInput(files: FileList,node) {  
  let file = files.item(0);
  let showName= file.name;
  let fileName = new Date().getTime()+"__"+file.name;
  // document.getElementById(upID).innerHTML= showName;
  const formdata: FormData = new FormData();
  formdata.append('file', file);
  formdata.append('fileName', fileName);
  formdata.append('companyCode', this.assessmentVO.companyVO.companyCode);
  formdata.append('assessmentId', this.assessmentId.toString());
  this.assessementTemlateMasterService.upload(formdata).subscribe(event => {
    if (event instanceof HttpResponse) {
      this.questionList[node.id].controls.responseOptionVO.patchValue({
        fileName: file.name,
        fileUploadName: fileName
      })
     }
   });
}
downloadAttachmentFile(templateQuestionId) {

  let fileUpload = this.questionList[templateQuestionId].controls.responseOptionVO.controls.fileUploadName.value;
  let filename = this.assessmentVO.companyVO.companyCode + "/" + this.assessmentId + "/" + fileUpload
  if (fileUpload != null && fileUpload != "") {

    window.location.href =
      this.assessementTemlateMasterService.getBaseUrl() +
      "/evidenceFiledownload?fileUploadName=" +
      encodeURIComponent(filename)
  }

}

}
