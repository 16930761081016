import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationService } from '../../configuration.service';
import { IndicatorService } from '../../../indicator/indicator.service';
import { IndicatorMasterService } from '../../../indicator-master/indicator-master.service';
import { AddEditSuccessComponent } from '../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { ReportConstantsComponent } from '../../../report/report-constants/report-constants.component';

@Component({
  selector: 'app-add-company-info',
  templateUrl: './add-company-info.component.html',
  styleUrls: ['./add-company-info.component.css']
})
export class AddCompanyInfoComponent implements OnInit {

  profitUnits: any;
  revenueUnits: any;
  currency: any;
  currentYear: number;
  unitGroups: any;
  productionUnits: any;
  infraUnits: any;
  yearList: any;
  companyId: any;
  companyAdditionalInfoForm: FormGroup;
  @ViewChild('companyInfoForm') companyInfoForm;
  constructor(private addEditSuccessComponent: AddEditSuccessComponent, private IndicatorMasterService: IndicatorMasterService, private indicatorService: IndicatorService, private configurationService: ConfigurationService, private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private router: Router) {

    this.activatedRoute.params.subscribe(params => { this.companyId = params.companyId });
    this.companyAdditionalInfoForm = this.createFormGroup();
  }

  ngOnInit() {
    this.currency = ReportConstantsComponent.CURRENCY;
    console.log(this.currency+"is the currency")
    this.onChanges();
    this.yearList = this.configurationService.getYearList();
    this.currentYear = new Date().getFullYear();
    this.IndicatorMasterService.getunitGroupList().subscribe(data => {
      this.unitGroups = data;
    });
  }
  //enabling /disabling unit group fields conditionally-based on value present or not in fields
  onChanges() {
    this.companyAdditionalInfoForm.get('totalProduction').valueChanges
      .subscribe(totalProduction => {
        if (totalProduction || this.companyAdditionalInfoForm.get('prodCapacity').value) {
          this.enableProductionUnitFields();
        }
        else {
          this.disableProductionUnitFields();
        }
      });
    this.companyAdditionalInfoForm.get('prodCapacity').valueChanges
      .subscribe(capacity => {
        if (capacity || this.companyAdditionalInfoForm.get('totalProduction').value) {
          this.enableProductionUnitFields();
        }
        else {
          this.disableProductionUnitFields();
        }
      });

    this.companyAdditionalInfoForm.get('infraBuildCapacity').valueChanges
      .subscribe(capacity => {

        if (capacity) {
          this.companyAdditionalInfoForm.get('infraUnitGroupVO').get('id').enable();
          this.companyAdditionalInfoForm.get('infraUnitVO').get('id').enable();
        } else {
          this.disableInfraUnitFields();

        }
      });

      this.companyAdditionalInfoForm.get('revenue').valueChanges
      .subscribe(capacity => {

        if (capacity) {
          this.companyAdditionalInfoForm.get('revenueUnitGroupVO').get('id').enable();
          this.companyAdditionalInfoForm.get('revenueUnitVO').get('id').enable();
        } else {
          this.disableRevenueUnitFields();

        }
      });

      this.companyAdditionalInfoForm.get('profit').valueChanges
      .subscribe(capacity => {

        if (capacity) {
          this.companyAdditionalInfoForm.get('profitUnitGroupVO').get('id').enable();
          this.companyAdditionalInfoForm.get('profitUnitVO').get('id').enable();
        } else {
          this.disableProfitUnitFields();

        }
      });
  }
  enableProductionUnitFields() {
    this.companyAdditionalInfoForm.get('productionUnitGroupVO').get('id').enable();
    this.companyAdditionalInfoForm.get('productionUnitVO').get('id').enable();
  }
  disableRevenueUnitFields() {
    this.companyAdditionalInfoForm.get('revenueUnitGroupVO').get('id').reset();
    this.companyAdditionalInfoForm.get('revenueUnitGroupVO').get('id').disable();
    this.companyAdditionalInfoForm.get('revenueUnitVO').get('id').reset();
    this.companyAdditionalInfoForm.get('revenueUnitVO').get('id').disable();
  }
  disableProfitUnitFields() {
    this.companyAdditionalInfoForm.get('profitUnitGroupVO').get('id').reset();
    this.companyAdditionalInfoForm.get('profitUnitGroupVO').get('id').disable();
    this.companyAdditionalInfoForm.get('profitUnitVO').get('id').reset();
    this.companyAdditionalInfoForm.get('profitUnitVO').get('id').disable();
  }
  disableProductionUnitFields() {
    this.companyAdditionalInfoForm.get('productionUnitGroupVO').get('id').reset();
    this.companyAdditionalInfoForm.get('productionUnitGroupVO').get('id').disable();
    this.companyAdditionalInfoForm.get('productionUnitVO').get('id').reset();
    this.companyAdditionalInfoForm.get('productionUnitVO').get('id').disable();
  }
  disableInfraUnitFields() {
    this.companyAdditionalInfoForm.get('infraUnitGroupVO').get('id').reset();
    this.companyAdditionalInfoForm.get('infraUnitGroupVO').get('id').disable();
    this.companyAdditionalInfoForm.get('infraUnitVO').get('id').reset();
    this.companyAdditionalInfoForm.get('infraUnitVO').get('id').disable();
  }
  createFormGroup() {
    return this.formBuilder.group({
      id: [0],
      companyVO: this.formBuilder.group({ id: [this.companyId] }),
      year: ['', [Validators.required]],
      revenue: ['',Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)],

      revenueUnitGroupVO: this.formBuilder.group({
        id: [{ value: '', disabled: true }]
      }),
      revenueUnitVO: this.formBuilder.group({
        id: [{ value: '', disabled: true }]
      }),

      profit: ['',Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)],

      profitUnitGroupVO: this.formBuilder.group({
        id: [{ value: '', disabled: true }]
      }),
      profitUnitVO: this.formBuilder.group({
        id: [{ value: '', disabled: true }]
      }),

      marketShare: ['',Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)],
      marketCap: ['',Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)],
      employeeCount: ['',Validators.pattern(/^-?([0-9]\d*)?$/)],
      boardCount: ['',Validators.pattern(/^-?([0-9]\d*)?$/)],
      ftes: ['',Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)],
      noOfShifts: ['',Validators.pattern(/^-?([0-9]\d*)?$/)],
      whperday: ['',Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)],
      prodCapacity: ['',Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)],
      totalProduction: ['',Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)],
      productionUnitGroupVO: this.formBuilder.group({
        id: [{ value: '', disabled: true }]
      }),
      productionUnitVO: this.formBuilder.group({
        id: [{ value: '', disabled: true }]
      }),

      infraBuildCapacity: ['',Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)],
      infraUnitGroupVO: this.formBuilder.group({
        id: [{ value: '', disabled: true }]
      }),
      infraUnitVO: this.formBuilder.group({
        id: [{ value: '', disabled: true }]
      }),


    });
  }
  resetCompanyInfoForm() {
    this.companyInfoForm.resetForm();

  }
  onSubmit() {
    console.log(this.companyAdditionalInfoForm)
console.log("valid?")
console.log(this.companyAdditionalInfoForm.valid)
    if (this.companyAdditionalInfoForm.valid) {
      this.configurationService.createOrUpdateCompanyAdditionalDetails(this.companyAdditionalInfoForm.value).subscribe(myform => {
        if (this.companyAdditionalInfoForm.get('id').value != null)
          var message = "Successfully Updated Company Details ";
        else
          var message = "Successfully Added Company Details";
        this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
          this.router.navigate(['/company']);

        });

      });
    }
  }
  onRevenueUnitGroupChange(unitGroupId) {
    if (unitGroupId > 0 && unitGroupId != null) {
      this.indicatorService.getunitOfMeasurementList(unitGroupId).subscribe(data => {
        this.revenueUnits = data;
      });
    }
  }
  onProfitUnitGroupChange(unitGroupId) {
    if (unitGroupId > 0 && unitGroupId != null) {
      this.indicatorService.getunitOfMeasurementList(unitGroupId).subscribe(data => {
        this.profitUnits = data;
      });
    }
  }
  onProductionUnitGroupChange(unitGroupId) {
    if (unitGroupId > 0 && unitGroupId != null) {
      this.indicatorService.getunitOfMeasurementList(unitGroupId).subscribe(data => {
        this.productionUnits = data;
      });
    }
  }
  onInfraUnitGroupChange(unitGroupId) {
    if (unitGroupId > 0 && unitGroupId != null) {
      this.indicatorService.getunitOfMeasurementList(unitGroupId).subscribe(data => {
        this.infraUnits = data;
      });
    }
  }
  onYearChange(year) {
    this.configurationService.getCompanyDetailsForYear(this.companyId, year).subscribe(data => {
      if (data){
        this.companyAdditionalInfoForm.patchValue(data);
        this.companyAdditionalInfoForm.controls.year.patchValue(year);
        this.companyAdditionalInfoForm.controls.companyVO.get('id').patchValue(this.companyId);
      }
        this.resetNullDropdownValues();
     
    })

  }
  resetNullDropdownValues(){

    if (this.companyAdditionalInfoForm.controls.revenueUnitGroupVO.get('id').value == 0) { this.companyAdditionalInfoForm.controls.revenueUnitGroupVO.get('id').setValue(''); }
    if (this.companyAdditionalInfoForm.controls.revenueUnitVO.get('id').value == 0) { this.companyAdditionalInfoForm.controls.revenueUnitVO.get('id').setValue(''); }
    if (this.companyAdditionalInfoForm.controls.profitUnitGroupVO.get('id').value == 0) { this.companyAdditionalInfoForm.controls.profitUnitGroupVO.get('id').setValue(''); }
    if (this.companyAdditionalInfoForm.controls.profitUnitVO.get('id').value == 0) { this.companyAdditionalInfoForm.controls.profitUnitVO.get('id').setValue(''); }
    if (this.companyAdditionalInfoForm.controls.productionUnitGroupVO.get('id').value == 0) { this.companyAdditionalInfoForm.controls.productionUnitGroupVO.get('id').setValue(''); }
    if (this.companyAdditionalInfoForm.controls.productionUnitVO.get('id').value == 0) { this.companyAdditionalInfoForm.controls.productionUnitVO.get('id').setValue(''); }
    if (this.companyAdditionalInfoForm.controls.infraUnitGroupVO.get('id').value == 0) { this.companyAdditionalInfoForm.controls.infraUnitGroupVO.get('id').setValue(''); }
    if (this.companyAdditionalInfoForm.controls.infraUnitVO.get('id').value == 0) { this.companyAdditionalInfoForm.controls.infraUnitVO.get('id').setValue(''); }
  }
}
