import { Component, OnInit, Input,ViewChild,Output,EventEmitter,ElementRef  } from '@angular/core';
import { MatTableDataSource,MatSort, MatPaginator} from '@angular/material';

@Component({
  selector: 'app-common-mat-table',
  templateUrl: './common-mat-table.component.html',
  styleUrls: ['./common-mat-table.component.css'],
  host: {
    '(document:click)': 'rowClick($event)',
  },
})
export class CommonMatTableComponent implements OnInit {

 
   // MatPaginator Inputs
   pageSize = 5;
   pageSizeOptions: number[] = [5, 10, 25, 100];
  
  @Input() jsonArray:any;
  @Input() displayedColumns:any;
  @Input() originalColumnNames:any;
  @Input() indicatorFlag:any;
  @Input() top:any;
  @Output() childEvent = new EventEmitter();
  @Output() childEvent1 = new EventEmitter();
  @Output() childEvent2 = new EventEmitter();
  dataSourceDetails:any=[];
  @ViewChild(MatSort) sort: MatSort;
  paginator:any;
  filter:string;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
    }
  selectedRowIndex: number = -1;
  elementRef: any;
  highlightFlag:any =true;
  constructor(myElement: ElementRef) {
    this.elementRef = myElement;
  }
  setDataSourceAttributes() {
    this.dataSourceDetails.paginator = this.paginator;
    this.dataSourceDetails.sort = this.sort;
  }
  ngOnInit() {
   
    if(!this.originalColumnNames){
    
      this.originalColumnNames = this.displayedColumns;
    }
   }
  
   ngOnChanges(changes: any) { 
   
    this.dataSourceDetails = new MatTableDataSource(this.jsonArray);
    }
   edit(element)
   {
    this.childEvent.emit(element);
     if(this.top && this.top.nativeElement)
        this.top.nativeElement.classList.add("top-form-wrapper-highlight");
   }

  delete(element)
  {
    this.childEvent1.emit(element);
    this.filter = '';
  }
  calculate(element)
  {
     this.childEvent2.emit(element)
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSourceDetails.filter = filterValue;
  }  


  highlight(row){
      this.selectedRowIndex = row.id;
  }
  rowClick(event) {
    var clickedComponent = event.target;
    var clickedElementId=clickedComponent.id;
    var inside = false; 
    do {
      if (clickedComponent === this.elementRef.nativeElement) {
        inside = true;
        this.highlightFlag=true;
      }
       clickedComponent = clickedComponent.parentNode;
    } while (clickedComponent);
    if ((!inside) && (clickedElementId=='cancel' || clickedElementId=='submit')) {
      let indDivs = document.querySelectorAll('.highlight');
      if(indDivs[0])
      indDivs[0].classList.remove('highlight');
      this.highlightFlag=false;
    } 
  }
}
