import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-add-edit-success',
  templateUrl: './add-edit-success.component.html',
  styleUrls: ['./add-edit-success.component.css']
})
export class AddEditSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

  showSuccessSwal(message) {
    var successSwal = swal({
      title: 'Success!',
      text: message,
      type: 'success',
      confirmButtonText: 'OK'
    });
    return successSwal;
  }

  showConfirmationSwal(message,yesMsg,noMsg) {
    var confirm = swal({
      title: '<p class="swal2-title-warning">Are You Sure?</p>',
      text: message,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: yesMsg,
      cancelButtonText: noMsg,
	   allowOutsideClick: false

    })
    return confirm;
  }
  showFailedSwal(message,title) {
    var failed=swal({
      title: title,
      text: message,
      type: 'warning',
      confirmButtonText: 'OK'
    });
    return failed;
  }
}
