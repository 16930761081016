import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IndicatorMasterService } from '../indicator-master.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { AddEditSuccessComponent } from '../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';

@Component({
  selector: 'app-indicator-keyword',
  templateUrl: './indicator-keyword.component.html',
  styleUrls: ['./indicator-keyword.component.css']
})
export class IndicatorKeywordComponent implements OnInit {

  indicatorKeywordsList: any;
  indicatorList: any;
  indicatorFormGroup: {};
  displayedColumns: any = [];
  jsonArray: any = [];
  dataSource: any = [];
  paginator: any;

  constructor(private indicatorService: IndicatorMasterService, private addEditSuccessComponent: AddEditSuccessComponent,
    private formBuilder: FormBuilder) {
    this.fetchIndicatorKeywords();
  }


  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit() {
    this.fetchIndicatorList();
  }

  fetchIndicatorList() {
    this.indicatorFormGroup = {};
    this.indicatorService.getListOfAllIndicators().subscribe(data => {
      this.indicatorList = data;
      for (let i = 0; i < this.indicatorList.length; i++) {
        this.indicatorFormGroup[this.indicatorList[i].id] = this.createFormGroup();

        if (this.indicatorKeywordsList.filter(x => x.id === this.indicatorList[i].id).length > 0) {
          let indicatorKeyword = this.indicatorKeywordsList.filter(x => x.id === this.indicatorList[i].id)
          console.log("find")
          console.log(indicatorKeyword[0].indicatorKeyword);
          let formKeyWord = {
            "indicatorKeywordId": indicatorKeyword[0].indicatorKeywordId,
            "indicatorKeyword": indicatorKeyword[0].indicatorKeyword
          }
          this.indicatorFormGroup[this.indicatorList[i].id].patchValue(formKeyWord);
        }
      }
      this.fetchDataTable();
    }, (error) => {
      console.log(error);
    });
  }

  fetchIndicatorKeywords() {
    this.indicatorService.fetchIndicatorKeywords().subscribe(data => {
      this.indicatorKeywordsList = data;
      console.log("indicatorKeywordsList")
      console.log(data)

    });
  }

  createFormGroup() {

    return this.formBuilder.group({
      indicatorKeywordId: [],
      indicatorKeyword: ['', [Validators.compose([Validators.required])]],

    });
  }


  fetchDataTable() {
    this.displayedColumns = [];
    this.jsonArray = [];
    var count = 0;
    for (let indicator of this.indicatorList) {
      var newArray = {};

      newArray['indicatorId'] = indicator.id;
      newArray['code'] = indicator.code;
      newArray['indicator'] = indicator.name;
      newArray['indicatorType'] = indicator.indicatorType.value;
      this.jsonArray.push(newArray);
    }
    this.dataSource = [];

    this.displayedColumns = ['Indicator', 'IndicatorType', 'Keyword']
    this.dataSource = new MatTableDataSource(this.jsonArray);
    this.setDataSourceAttributes();
  }

  submit() {
    let indicatorVOArray = [];
    let templateVOJson: any = {};
    let message;
    for (let i = 0; i < this.indicatorList.length; i++) {
      let indicatorVO = {};
      indicatorVO["id"] = this.indicatorList[i].id;
      indicatorVO["name"] = this.indicatorList[i].name;
      // let indicatorType = {
      //   "id": this.indicatorList[i].indicatorType.id
      // }
      indicatorVO["indicatorTypeValue"] = this.indicatorList[i].indicatorType.value
      let keywordValue = this.indicatorFormGroup[this.indicatorList[i].id].value;

      indicatorVO["indicatorKeyword"] = keywordValue.indicatorKeyword;
      indicatorVO["indicatorKeywordId"] = keywordValue.indicatorKeywordId;
      if (keywordValue.indicatorKeyword || keywordValue.indicatorKeywordId) {
        indicatorVOArray.push(indicatorVO);
      }

    }
    console.log("indicatorVOArray")
    console.log(indicatorVOArray)
    this.indicatorService.createIndicatorKeyword(indicatorVOArray).subscribe(data => {
      var message="Successfully Added Indicator Keyword";
                this.addEditSuccessComponent.showSuccessSwal(message);

              });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;

  }
}