import { Injectable } from '@angular/core';
import {Http, Response , Headers, RequestOptions} from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../../shared/base-url.service';

@Injectable({
  providedIn: 'root'
})
export class ComplianceDashboardService {

  private baseUrl = BaseUrlService.REPORTBASEURL;
  
  private headers = new Headers({'Content-type':'application/json'});
  private options = new RequestOptions({headers:this.headers});

  constructor(private http:HttpClient) { }


  getComplianceVODetails(){
    return this.http.get(this.baseUrl+'/getIndicatorComplianceFilters');
  }

  getCompanyComplianceFilters(){
    return this.http.get(this.baseUrl+'/getCompanyComplianceFilters');
  }
  
  submitIndicatorComplianceDashboard(defaultedIndicatorsFilterForm:any): Observable<any>{
  
     let body = JSON.stringify(defaultedIndicatorsFilterForm); 
     let url = this.baseUrl+'/fetchDefaultedIndicators';
     const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);
 
  
   }


  fetchParameterLevelList(parentParameterId):Observable<any>{
    return this.http.get(this.baseUrl+'/getParameterLevel2List?level1ids='+parentParameterId);
  }
  
  errorHandler(error:Response){
    return Observable.throw(error || "SERVER_ERROR");
  }

  
  submitCompanyCompliance(companyComplianceForm:any): Observable<any>{
    
    let body = JSON.stringify(companyComplianceForm); 
   
    let url = this.baseUrl+'/complianceDashboardCompanyList';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
       
      })
    };

    return this.http.post(url, body, httpOptions);
}

  retrieveQuarterlyCompanyComplianceData(complianceDashboardVO):Observable<any>{
    
    let body = JSON.stringify(complianceDashboardVO);
        let url = this.baseUrl+'/getQuarterlyCompanyComplianceDashboard';
        const httpOptions = {
            headers: new HttpHeaders({
            'Content-Type':  'application/json',
       
      })
    };

    return this.http.post(url, body, httpOptions);
  }

   retrieveMonthlyCompanyComplianceData(complianceDashboardVO):Observable<any>{
    
    let body = JSON.stringify(complianceDashboardVO);
        let url = this.baseUrl+'/getMonthlyCompanyComplianceDashboard';
        const httpOptions = {
            headers: new HttpHeaders({
            'Content-Type':  'application/json',
       
      })
    };

    return this.http.post(url, body, httpOptions);
  }
  

  retrieveQuarterlyIndicatorComplianceData(complianceVO)
  {
        let body = JSON.stringify(complianceVO);
        let url = this.baseUrl+'/getQuarterlyIndicatorComplianceDashboard';
        const httpOptions = {
            headers: new HttpHeaders({
            'Content-Type':  'application/json',
       
      })
    };

    return this.http.post(url, body, httpOptions);
  }

  retrieveMonthlyIndicatorComplianceData(complianceVO)
  {
        let body = JSON.stringify(complianceVO);
        let url = this.baseUrl+'/getMonthlyIndicatorComplianceDashboard';
        const httpOptions = {
            headers: new HttpHeaders({
            'Content-Type':  'application/json',
       
      })
    };

    return this.http.post(url, body, httpOptions);
  }
  
}
