import { Component, OnInit, ViewChild } from '@angular/core';
import { CompanyService } from '../../company.service';
import { ActivatedRoute  , Router} from '@angular/router';
import * as d3 from 'd3';
import { FormControl } from '@angular/forms';
import { ReportConstantsComponent } from '../../../report-constants/report-constants.component'
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.css']
})
export class CompanyDetailsComponent implements OnInit {


  displayedColumnsCompanyIndicators: string[];
  indArr: any[];
  jsonArray: any[];
  displayedColumns: any[];
  public dataSourcePopUp: any;
  public company_id: number;
  public companyDetails: any;
  public companyIndicatorList: any;
  public environmentIndicators: any = [];
  public socialIndicators: any = [];
  public governanceIndicators: any = [];
  public indicator = new FormControl();
  public esgHistoryChartOptions: any;
  public esgHistoryChartData: any;
  public esgHistoryChartLabels: any;
  public selectedIndicatorList1: any;
  public selectedIndicatorList2: any;
  public selectedIndicatorList3: any;
  public companyLegendList: any;
  public lineChartOpt1 = [];
  public lineChartLabels1 = [];
  public lineChartData1 = [];
  public barChartOpt2 = [];
  public barChartLabels2 = [];
  public barChartData2 = [];
  public lineChartOpt3 = [];
  public lineChartLabels3 = [];
  public lineChartData3 = [];
  public barChartDrill: any;
  public drillBarChartLabels: any;
  public drillIndicatorName: string;
  public drilledYear: number;
  public drillDownData: any;
  public target: any;
  public isTarget: any;
  public drillUnitOfMeasurement: any;
  public doughnutData: any;
  public chartColors: any;
  public chartOptions: any;
  public companyEsgHistory: any;
  public doughnutChartType = 'doughnut';
  public displayActiveClass: any = "indicator active-trans-txt underline-cls";
  public displayClosedClass: any = "indicator closed-trans-txt";
  public isDataFlag: any;
  public parmeterMap = new Map();
  public currency: string;
  public dataSourceTrans: any;
  public targetFlagCompanyIndicatorPopUp: any;
  public dataSourceCompanyIndicators:any;
  public dataSourceCompanyIndicatorsSecond:any;
  public view360=false;
  public environmentESGGauge: any;
  public socialESGGauge: any;
  public governanceESGGauge: any;
  gaugemap: any = {};
  companyDetailsPassed: any;
  name: any;
  id: number;
  sector: any;
  subSector: any;
  region: any;
  subRegion: any;
  revenue: any;
  image: any;
  environment: any;
  social: any;
  governance: any;
  overallEsg: any;
  esgColorStatus: any;

  constructor(private companyService: CompanyService, private activatedRoute: ActivatedRoute, private _router:Router) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.name=params.name;
      this.company_id=parseInt(params.id);
      this.sector=params.sector;
      this.subSector=params.subSector;
      this.region = params.region;
      this.subRegion = params.subRegion;
      this.revenue = params.revenue;
      this.image = params.image;
      this.environment=params.environment;
      this.social=params.social;
      this.governance=params.governance;
      this.overallEsg=params.overallEsgQuotient;
      this.esgColorStatus=params.esgColorStatus;
      console.log("ffffff")
      console.log(this.environment)
      if(this.environment)
      this.environmentESGGauge = Number(this.environment);
      if(this.social)
      this.socialESGGauge = Number(this.social);
      if(this.governance)
      this.governanceESGGauge = Number(this.governance);
      if(this.environment && this.social && this.governance){
        console.log("ffffff")
        console.log(this.environment)
        console.log(this.social)
        console.log(this.governance)
      
        
      }
    // let parameterLevelName: String;
    // let parameterLevelNameMap: String;
    // let parameterName: String;
  //  this.companyService.getCompanyDetailsWithoutTransaction(this.company_id).subscribe((companyDetails) => {
  //    this.companyDetails = companyDetails.data.getCompany;

   
    //  if (this.companyDetails) {
        // for (let companyParameterMapping of this.companyDetails.companyParameterMappingReportSet) {
        //   if (companyParameterMapping.parameterLevel1) {
        //     parameterName = companyParameterMapping.parameterLevel1.parameters.parameterName;
        //     parameterLevelName = companyParameterMapping.parameterLevel1.levelName;
        //     if (this.parmeterMap.has(parameterName)) {
        //       parameterLevelNameMap = this.parmeterMap.get(parameterName);
        //       parameterLevelNameMap = parameterLevelNameMap + "," + parameterLevelName;
        //       this.parmeterMap.set(parameterName, parameterLevelNameMap);
        //     }
        //     else {
        //       this.parmeterMap.set(parameterName, parameterLevelName);
        //     }
        //   }
        //   if (companyParameterMapping.parameterLevel2) {

        //     parameterName = companyParameterMapping.parameterLevel2.parameters.parameterName;
        //     parameterLevelName = companyParameterMapping.parameterLevel2.levelName;
        //     if (this.parmeterMap.has(parameterName)) {
        //       parameterLevelNameMap = this.parmeterMap.get(parameterName);
        //       parameterLevelNameMap = parameterLevelNameMap + "," + parameterLevelName;
        //       this.parmeterMap.set(parameterName, parameterLevelNameMap);
        //     }
        //     else {
        //       this.parmeterMap.set(parameterName, parameterLevelName);
        //     }
        //   }
        // }
        if(name=="SEB" || name=="Vodafone" || name=="Danske" || name=="Lufthansa" ){
          this.view360=true;
        }
        else{
          this.view360=false;
        }
     // }

   


   // });
  });
  }

  ngOnInit() {
    this.currency = ReportConstantsComponent.CURRENCY;
    this.companyService.getCompanySelectedIndicators(this.company_id).subscribe((companyIndicatorList) => {
      this.companyIndicatorList = companyIndicatorList;
      let flag=1;
      let targetFlagCompany=1;
      this.dataSourceCompanyIndicators =this.dataTablePopulationCompanyIndicators(this.companyIndicatorList.slice(0, 5), flag, targetFlagCompany)
      this.dataSourceCompanyIndicatorsSecond =this.dataTablePopulationCompanyIndicators(this.companyIndicatorList.slice(5, 10), flag, targetFlagCompany)
      this.displayedColumnsCompanyIndicators= this.displayedColumnsCompany();
      for (let companyIndicators of companyIndicatorList) {
        if (companyIndicators.esgType == 1) {
          this.environmentIndicators.push(companyIndicators);
        }
        else if (companyIndicators.esgType == 2) {
          this.socialIndicators.push(companyIndicators);
        }
        else if (companyIndicators.esgType == 3) {
          this.governanceIndicators.push(companyIndicators);
        }
      }

    }, (error) => {
      console.log(error);
    })

    this.companyService.getSelectedIndicators(1, this.company_id, -1).subscribe((selectedIndicatorList) => {
      this.selectedIndicatorList1 = selectedIndicatorList;
      this.getAllLineData(this.selectedIndicatorList1, 1);
    }, (error) => {
      console.log(error);
    })

    this.companyService.getSelectedIndicators(2, this.company_id, -1).subscribe((selectedIndicatorList) => {
      this.selectedIndicatorList2 = selectedIndicatorList;
      this.getAllLineData(this.selectedIndicatorList2, 2);
    }, (error) => {
      console.log(error);
    })

    this.companyService.getSelectedIndicators(3, this.company_id, -1).subscribe((selectedIndicatorList) => {
      this.selectedIndicatorList3 = selectedIndicatorList;
      this.getAllLineData(this.selectedIndicatorList3, 3);
    }, (error) => {
      console.log(error);
    })


    this.draw();

  }

  doughnutChartESGData(esg: number) {
    if (esg > 1)
      esg = 1;
    this.doughnutData = [esg, (1 - esg)];
    return this.doughnutData;
  }

  chartColorESG(esgColorStatus: number) {

    /* 3=> green , 2=> amber , 1 => red */
    if (esgColorStatus == 3)
      this.chartColors = [{ backgroundColor: ["#34a853", "#dddddd"] }];
    else if (esgColorStatus == 2)
      this.chartColors = [{ backgroundColor: ["#f39700", "#dddddd"] }];
    else
      this.chartColors = [{ backgroundColor: ["#ea4335", "#dddddd"] }];

    return this.chartColors;
  }

  OptionsESG(flag) {
    if (flag) {
      var per = 40;
      this.chartOptions = {
        responsive: true,
        cutoutPercentage: per,
        animation: false,
      };
    }
    else {
      var per = 75;
      this.chartOptions = {
        responsive: true,
        cutoutPercentage: per,
        animation: false,
      };
    }
    return this.chartOptions;
  }
  draw() {
    var self = this;
    var gauge = function (container, configuration) {

      var config = {
        size: 150,
        clipWidth: 100,
        clipHeight: 90,
        ringInset: 17,
        ringWidth: 17,

        pointerWidth: 9,
        pointerTailLength: 5,
        pointerHeadLengthPercent: 0.6,

        minValue: 0,
        maxValue: 1,

        minAngle: -90,
        maxAngle: 92,

        transitionMs: 850,

        majorTicks: 5,
        labelFormat: d3.format('.1f'),
        labelInset: 10,
        arcColorFn: d3.scaleOrdinal().range(['#000000', '#595959', '#bfbfbf', '#93e45b', '#3e7d14'])

      };
      var range = undefined;
      var r = undefined;
      var pointerHeadLength = undefined;
      var value = 0;

      var svg = undefined;
      var arc = undefined;
      var scale = undefined;
      var ticks = undefined;
      var tickData = undefined;
      var pointer = undefined;

      var donut = d3.pie();

      function deg2rad(deg) {
        return deg * Math.PI / 180;
      }

      function newAngle(d) {
        var ratio = scale(d);
        var newAngle = config.minAngle + (ratio * range);
        return newAngle;
      }

      function configure(configuration) {
        var prop = undefined;
        for (prop in configuration) {
          config[prop] = configuration[prop];
        }

        range = config.maxAngle - config.minAngle;
        r = config.size / 2;
        pointerHeadLength = Math.round(r * config.pointerHeadLengthPercent);

        // a linear scale this.gaugemap maps domain values to a percent from 0..1
        scale = d3.scaleLinear()
          .range([0, 1])
          .domain([config.minValue, config.maxValue]);

        ticks = scale.ticks(config.majorTicks);
        tickData = d3.range(config.majorTicks).map(function () { return 1 / config.majorTicks; });

        arc = d3.arc()
          .innerRadius(r - config.ringWidth - config.ringInset)
          .outerRadius(r - config.ringInset)
          .startAngle(function (d, i) {
            var ratio = d * i;
            if (i == 0)
              ratio = 0;
            else if (i == 1)
              ratio = 0.20
            else if (i == 2)
              ratio = 0.40
            else if (i == 3)
              ratio = 0.60
            else if (i == 4)
              ratio = 0.80
            return deg2rad(config.minAngle + (ratio * range));
          })
          .endAngle(function (d, i) {
            var ratio = d * (i + 1);
            if (i == 0)
              ratio = 0.20
            else if (i == 1)
              ratio = 0.40
            else if (i == 2)
              ratio = 0.60
            else if (i == 3)
              ratio = 0.80
            else if (i == 4)
              ratio = 1
            return deg2rad(config.minAngle - 1 + (ratio * range));
          });
      }
      self.gaugemap.configure = configure;

      function centerTranslation() {
        return 'translate(' + r + ',' + r + ')';
      }

      function isRendered() {
        return (svg !== undefined);
      }
      self.gaugemap.isRendered = isRendered;

      function render(newValue) {
        svg = d3.select(container)
          .append('svg:svg')
          .attr('class', 'gauge')
          .attr('width', config.clipWidth)
          .attr('height', config.clipHeight);

        var centerTx = centerTranslation();

        var arcs = svg.append('g')
          .attr('class', 'arc')
          .attr('transform', centerTx);

        arcs.selectAll('path')
          .data(tickData)
          .enter().append('path')
          .attr('fill', function (d, i) {
            return config.arcColorFn(d * i);
          })
          .attr('d', arc);

        var lg = svg.append('g')
          .attr('class', 'label')
          .attr('transform', centerTx);
        lg.selectAll('text')
          .data(ticks)
          .enter().append('text')
          .attr('transform', function (d) {
            var ratio = scale(d);
            var newAngle = config.minAngle + (ratio * range);
            return 'rotate(' + newAngle + ') translate(-10,' + (config.labelInset - r) + ')';
          })
          .text(config.labelFormat);

        var lineData = [[config.pointerWidth / 2, 0],
        [0, -pointerHeadLength],
        [-(config.pointerWidth / 2), 0],
        [0, config.pointerTailLength],
        [config.pointerWidth / 2, 0]];
        var pointerLine = d3.line().curve(d3.curveLinear)
        var pg = svg.append('g').data([lineData])
          .attr('class', 'pointer')
          .attr('transform', centerTx);

        pointer = pg.append('path')
          .attr('d', pointerLine/*function(d) { return pointerLine(d) +'Z';}*/)
          .attr('transform', 'rotate(' + config.minAngle + ')');

        update(newValue === undefined ? 0 : newValue);
      }
      self.gaugemap.render = render;
      function update(newValue, newConfiguration?) {
        if (newConfiguration !== undefined) {
          configure(newConfiguration);
        }
        var ratio = scale(newValue);
        var newAngle = config.minAngle + (ratio * range);
        pointer.transition()
          .duration(config.transitionMs)
          .ease(d3.easeElastic)
          .attr('transform', 'rotate(' + newAngle + ')');
      }
      self.gaugemap.update = update;

      configure(configuration);

      return self.gaugemap;
    };

    var epowerGauge = gauge('#e-power-gauge', {
      size: 150,
      clipWidth: 160,
      clipHeight: 90,
      ringWidth: 20,
      maxValue: 1,
      transitionMs: 10000,
    });
    console.log("this.environmentESGGauge")
    console.log(this.environmentESGGauge)
    epowerGauge.render(Number(this.environment));

    var spowerGauge = gauge('#s-power-gauge', {
      size: 150,
      clipWidth: 160,
      clipHeight: 90,
      ringWidth: 20,
      maxValue: 1,
      transitionMs: 10000,
    });
    console.log("this.social")
    console.log(this.social)
    spowerGauge.render(Number(this.social));

    var gpowerGauge = gauge('#g-power-gauge', {
      size: 150,
      clipWidth: 160,
      clipHeight: 90,
      ringWidth: 20,
      maxValue: 1,
      transitionMs: 10000,
    });
    console.log("this.governance")
    console.log(this.governance)
    gpowerGauge.render(Number(this.governance));

  }

  // draw() {
  //   var self = this;
  //   var gauge = function (container, configuration) {

  //     var config = {
  //       size: 710,
  //       clipWidth: 200,
  //       clipHeight: 110,
  //       ringInset: 20,
  //       ringWidth: 20,

  //       pointerWidth: 10,
  //       pointerTailLength: 5,
  //       pointerHeadLengthPercent: 0.6,

  //       minValue: 0,
  //       maxValue: 1,

  //       minAngle: -90,
  //       maxAngle: 92,

  //       transitionMs: 750,

  //       majorTicks: 3,
  //       labelFormat: d3.format('.1f'),
  //       labelInset: 10,

  //       arcColorFn: d3.interpolateHsl(d3.rgb('#ea4335'), d3.rgb('#34a853'))
  //     };
  //     var range = undefined;
  //     var r = undefined;
  //     var pointerHeadLength = undefined;
  //     var value = 0;

  //     var svg = undefined;
  //     var arc = undefined;
  //     var scale = undefined;
  //     var ticks = undefined;
  //     var tickData = undefined;
  //     var pointer = undefined;

  //     var donut = d3.pie();

  //     function deg2rad(deg) {
  //       return deg * Math.PI / 180;
  //     }

  //     function newAngle(d) {
  //       var ratio = scale(d);
  //       var newAngle = config.minAngle + (ratio * range);
  //       return newAngle;
  //     }

  //     function configure(configuration) {
  //       var prop = undefined;
  //       for (prop in configuration) {
  //         config[prop] = configuration[prop];
  //       }

  //       range = config.maxAngle - config.minAngle;
  //       r = config.size / 2;
  //       pointerHeadLength = Math.round(r * config.pointerHeadLengthPercent);

  //       // a linear scale this.gaugemap maps domain values to a percent from 0..1
  //       scale = d3.scaleLinear()
  //         .range([0, 1])
  //         .domain([config.minValue, config.maxValue]);

  //       ticks = scale.ticks(config.majorTicks);
  //       tickData = d3.range(config.majorTicks).map(function () { return 1 / config.majorTicks; });

  //       arc = d3.arc()
  //         .innerRadius(r - config.ringWidth - config.ringInset)
  //         .outerRadius(r - config.ringInset)
  //         .startAngle(function (d, i) {
  //           var ratio = d * i;
  //           if (i == 0)
  //             ratio = 0;
  //           else if (i == 1)
  //             ratio = 0.25
  //           else if (i == 2)
  //             ratio = 0.75
  //           return deg2rad(config.minAngle + (ratio * range));
  //         })
  //         .endAngle(function (d, i) {
  //           var ratio = d * (i + 1);
  //           if (i == 0)
  //             ratio = 0.25;
  //           else if (i == 1)
  //             ratio = 0.75
  //           else if (i == 2)
  //             ratio = 1
  //           return deg2rad(config.minAngle - 1 + (ratio * range));
  //         });
  //     }
  //     self.gaugemap.configure = configure;

  //     function centerTranslation() {
  //       return 'translate(' + r + ',' + r + ')';
  //     }

  //     function isRendered() {
  //       return (svg !== undefined);
  //     }
  //     self.gaugemap.isRendered = isRendered;

  //     function render(newValue) {
  //       svg = d3.select(container)
  //         .append('svg:svg')
  //         .attr('class', 'gauge')
  //         .attr('width', config.clipWidth)
  //         .attr('height', config.clipHeight);

  //       var centerTx = centerTranslation();

  //       var arcs = svg.append('g')
  //         .attr('class', 'arc')
  //         .attr('transform', centerTx);

  //       arcs.selectAll('path')
  //         .data(tickData)
  //         .enter().append('path')
  //         .attr('fill', function (d, i) {
  //           return config.arcColorFn(d * i);
  //         })
  //         .attr('d', arc);

  //       var lg = svg.append('g')
  //         .attr('class', 'label')
  //         .attr('transform', centerTx);
  //       lg.selectAll('text')
  //         .data(ticks)
  //         .enter().append('text')
  //         .attr('transform', function (d) {
  //           var ratio = scale(d);
  //           var newAngle = config.minAngle + (ratio * range);
  //           return 'rotate(' + newAngle + ') translate(-10,' + (config.labelInset - r) + ')';
  //         })
  //         .text(config.labelFormat);

  //       var lineData = [[config.pointerWidth / 2, 0],
  //       [0, -pointerHeadLength],
  //       [-(config.pointerWidth / 2), 0],
  //       [0, config.pointerTailLength],
  //       [config.pointerWidth / 2, 0]];
  //       var pointerLine = d3.line().curve(d3.curveLinear)
  //       var pg = svg.append('g').data([lineData])
  //         .attr('class', 'pointer')
  //         .attr('transform', centerTx);

  //       pointer = pg.append('path')
  //         .attr('d', pointerLine/*function(d) { return pointerLine(d) +'Z';}*/)
  //         .attr('transform', 'rotate(' + config.minAngle + ')');

  //       update(newValue === undefined ? 0 : newValue);
  //     }
  //     self.gaugemap.render = render;
  //     function update(newValue, newConfiguration?) {
  //       if (newConfiguration !== undefined) {
  //         configure(newConfiguration);
  //       }
  //       var ratio = scale(newValue);
  //       var newAngle = config.minAngle + (ratio * range);
  //       pointer.transition()
  //         .duration(config.transitionMs)
  //         .ease(d3.easeElastic)
  //         .attr('transform', 'rotate(' + newAngle + ')');
  //     }
  //     self.gaugemap.update = update;

  //     configure(configuration);

  //     return self.gaugemap;
  //   };

  //   var epowerGauge = gauge('#e-power-gauge', {
    
  //     size: 150,
  //     clipWidth: 160,
  //     clipHeight: 90,
  //     ringWidth: 20,
  //     maxValue: 1,
  //     transitionMs: 10000,
  //   });
  //   epowerGauge.render(this.companyDetails.environment);

  //   var spowerGauge = gauge('#s-power-gauge', {
     
  //     size: 150,
  //     clipWidth: 160,
  //     clipHeight: 90,
  //     ringWidth: 20,
  //     maxValue: 1,
  //     transitionMs: 10000,
  //   });
  //   spowerGauge.render(this.companyDetails.social);

  //   var gpowerGauge = gauge('#g-power-gauge', {
    
  //     size: 150,
  //     clipWidth: 160,
  //     clipHeight: 90,
  //     ringWidth: 20,
  //     maxValue: 1,
  //     transitionMs: 10000,
  //   });
  //   gpowerGauge.render(this.companyDetails.governance);

  // }

  getAllLineData(selectedIndicatorList, esgType) {

    for (let selectedIndList of selectedIndicatorList) {
      var actual = new Array();
      var year = new Array();
      for (let actualData of selectedIndList.chartAxesVO) {
        actual.push(actualData.actual);
        year.push(actualData.year);
      }

      let yAxisLabel = 'Rating Value';
      if (esgType == 1) {
        if (selectedIndList.unitOfMeasurement != null && selectedIndList.unitOfMeasurement.length > 0) {
          yAxisLabel = selectedIndList.unitOfMeasurement;
        }
        this.lineChartOpt1[selectedIndList.id] = {
          responsive: true,
          animation: false,
          scales: {
            xAxes: [{
              display: true,
              gridLines: {
                display: false,
                drawTicks: true,
              },
              scaleLabel: {
                display: true,
                labelString: 'Year'
              },
            }],
            yAxes: [{
              display: true,
              gridLines: {
                display: false,
                drawTicks: true
              },
              scaleLabel: {
                display: true,
                labelString: yAxisLabel//selectedIndList.unitOfMeasurement
              },
              ticks: {
                beginAtZero: true,
                steps: 10,
                stepValue: 5,
                min: 0
              }
            }]
          },
          title: {
            display: false,
            text: 'Chart'
          }
        };
        this.lineChartLabels1[selectedIndList.id] = year;
        this.lineChartData1[selectedIndList.id] = [{ 'dataSet': [{ data: actual }] }];
      }
      else if (esgType == 2) {
        if (selectedIndList.unitOfMeasurement != null && selectedIndList.unitOfMeasurement.length > 0) {
          yAxisLabel = selectedIndList.unitOfMeasurement;
        }
        this.barChartOpt2[selectedIndList.id] = {
          scaleShowVerticalLines: true,
          responsive: true,
          animation: false,
          scales: {
            xAxes: [{
              display: true,
              barPercentage: 0.6,
              gridLines: {
                display: false,
                drawTicks: true,
              },
              scaleLabel: {
                display: true,
                labelString: 'Year'
              }
            }],
            yAxes: [{
              display: true,
              gridLines: {
                display: false,
                drawTicks: true
              },
              scaleLabel: {
                display: true,
                labelString: yAxisLabel//selectedIndList.unitOfMeasurement
              },
              ticks: {
                beginAtZero: true,
                steps: 10,
                stepValue: 5,
                min: 0
              }
            }]
          },
          title: {
            display: false,
            text: 'chart'
          }
        };
        this.barChartLabels2[selectedIndList.id] = year;
        this.barChartData2[selectedIndList.id] = [{ 'dataSet': [{ data: actual }] }];
      }
      else if (esgType == 3) {
        if (selectedIndList.unitOfMeasurement != null && selectedIndList.unitOfMeasurement.length > 0) {
          yAxisLabel = selectedIndList.unitOfMeasurement;
        }
        this.lineChartOpt3[selectedIndList.id] = {
          responsive: true,
          animation: false,
          scales: {
            xAxes: [{
              display: true,
              gridLines: {
                display: false,
                drawTicks: true,
              },
              scaleLabel: {
                display: true,
                labelString: 'Year'
              },
            }],
            yAxes: [{
              display: true,
              gridLines: {
                display: false,
                drawTicks: true
              },
              scaleLabel: {
                display: true,
                labelString: yAxisLabel//selectedIndList.unitOfMeasurement
              },
              ticks: {
                beginAtZero: true,
                steps: 10,
                stepValue: 5,
                min: 0
              }
            }]
          },
          title: {
            display: false,
            text: 'Chart'
          }
        };
        this.lineChartLabels3[selectedIndList.id] = year;
        this.lineChartData3[selectedIndList.id] = [{ 'dataSet': [{ data: actual }] }];
      }
    }
  }
  public barChartClicked(e: any, indicatorId: any): void {
    if (e.active.length > 0) {
      this.showDrillDown(e.active[0]._model.label, indicatorId, true)
    }
    else {
      this.showDrillDown(false, indicatorId, false)
    }
  }

  addRemovedUnderline(flag) {
    if (flag == 1) {
      this.displayActiveClass = 'indicator active-trans-txt underline-cls';
      this.displayClosedClass = 'indicator closed-trans-txt';
    }
    else {
      this.displayActiveClass = 'indicator active-trans-txt';
      this.displayClosedClass = 'indicator closed-trans-txt underline-cls';
    }
  }

  // events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  showIndicatorBox(value, esgType) {
    if (value.length == 0)
      value = -1;
    this.companyService.getSelectedIndicators(esgType, this.company_id, value).subscribe((selectedIndicatorList) => {
      if (esgType == 1) {
        this.selectedIndicatorList1 = selectedIndicatorList;
        this.getAllLineData(this.selectedIndicatorList1, esgType);
      }
      else if (esgType == 2) {
        this.selectedIndicatorList2 = selectedIndicatorList;
        this.getAllLineData(this.selectedIndicatorList2, esgType);
      }
      else if (esgType == 3) {
        this.selectedIndicatorList3 = selectedIndicatorList;
        this.getAllLineData(this.selectedIndicatorList3, esgType);
      }
    }, (error) => {
      console.log(error);
    })
  }

  // drill down chart
  public drillBarChartType: string = 'bar';
  public drillBarChartLegend: boolean = false;
  public drillBarChartOptions: any;
  public drillBarChartColors: Array<any> = [
    { // green
      backgroundColor: 'rgb(22,205,185)',
      borderColor: 'rgb(22,205,185)',
      pointBackgroundColor: 'rgb(22,205,185)',
      // backgroundColor: 'rgb(32,176,141)',
      // borderColor: 'rgb(32,176,141)',
      // pointBackgroundColor: 'rgb(32,176,141)',
      pointBorderColor: 'rgb(255,255,255)',
      pointHoverBackgroundColor: 'rgb(255,255,255)',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  showDrillDown(year, indicatorId, dataFlag) {

    if (dataFlag) {

      this.isDataFlag = 1;

      this.drilledYear = year;
      this.companyService.getDrillDown(year, indicatorId, this.company_id).subscribe((drillDownData) => {
        this.drillDownData = drillDownData;
        var actual = new Array();
        var company = new Array();
        var companyLegend = new Array();
        var index = 1;
        this.isTarget = 0;

        for (let chartData of this.drillDownData) {
          actual.push(chartData.actual);
          company.push('T' + index);
          companyLegend.push('T' + index + ' : ' + chartData.company);
          this.drillIndicatorName = chartData.indicatorName;
          this.drillUnitOfMeasurement = chartData.unitOfMeasurement;
          this.target = chartData.target;
          index++;
        }

        if (this.target)
          this.isTarget = 1;

        this.drillBarChartOptions = {
          scaleShowVerticalLines: true,
          responsive: true,
          animation: false,
          scales: {
            xAxes: [{
              display: true,
              barPercentage: 0.5,
              gridLines: {
                display: false,
                drawTicks: true,
              },
              scaleLabel: {
                display: true,
                labelString: 'Company'
              }
            }],
            yAxes: [{
              display: true,
              gridLines: {
                display: false,
                drawTicks: true
              },
              scaleLabel: {
                display: true,
                labelString: this.drillUnitOfMeasurement
              },
              ticks: {
                beginAtZero: true,
                steps: 10,
                stepValue: 5,
                min: 0
              }
            }]
          },
          title: {
            display: false,
            text: 'DRILL DOWN CHART'
          }
        };
        this.drillBarChartLabels = company;
        this.companyLegendList = companyLegend;
        this.barChartDrill = [{ 'dataSet': [{ data: actual }] },
        ];

      }, (error) => {
        console.log(error);
      })

    }
    else {
      this.drillBarChartLabels = [];
      this.companyLegendList = [];
      this.barChartDrill = [];
      this.isDataFlag = 0;
    }
  }

  showCompanyESGHistory() {

    this.companyService.getCompanyEsgHistory(this.company_id).subscribe((companyEsgHistory) => {
      this.companyEsgHistory = companyEsgHistory;
      var esg = new Array();
      var year = new Array();

      for (let esgData of this.companyEsgHistory) {
        esg.push(esgData.esg);
        year.push(esgData.year);
      }

      this.esgHistoryChartOptions = {
        responsive: true,
        // animation:false,
        scales: {
          xAxes: [{
            display: true,
            gridLines: {
              display: false,
              drawTicks: true,
            },
            scaleLabel: {
              display: true,
              labelString: 'Year'
            },
          }],
          yAxes: [{
            display: true,
            gridLines: {
              display: false,
              drawTicks: true
            },
            scaleLabel: {
              display: true,
              labelString: 'ESG Quotient'
            },
            ticks: {
              beginAtZero: true,
              steps: 10,
              stepValue: 5,
              min: 0,
              max: 1
            }
          }]
        },
        title: {
          display: false,
          text: 'ESG Quotients'
        }
      };

      this.esgHistoryChartLabels = year;
      this.esgHistoryChartData = [{ 'dataSet': [{ data: esg }] }];

    }, (error) => {
      console.log(error);
    })
  }
  public esgHistoryColor: Array<any> = [
    { // green
      backgroundColor: 'rgb(217,241,226)',
      borderColor: 'rgb(64,187,110)',
      pointBackgroundColor: 'rgb(255,255,255)',
      pointBorderColor: 'rgb(32,176,141)',
      pointHoverBackgroundColor: 'rgb(255,255,255)',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
      pointRadius: 6,
    }
  ];
  public esgHistoryLegend: boolean = false;
  public esgHistoryType: string = 'line';


  public lineChartColor1: Array<any> = [
    { // green
      backgroundColor: 'rgb(22,205,185,0.5)',//line background
      // backgroundColor: 'rgb(97,194,170)',//line color
      borderColor: 'rgb(22,205,185,0.5)',
      // borderColor: 'rgb(97,194,170)',
      pointBackgroundColor: 'rgb(22,205,185)',
      // pointBackgroundColor: 'rgb(32,176,141)',
      pointBorderColor: 'rgb(255,255,255)',
      pointHoverBackgroundColor: 'rgb(255,255,255)',
      pointHoverBorderColor: 'rgb(22,205,185,0.8)',
      pointRadius: 5,
    }
  ];
  public barChartColor2: Array<any> = [
    { // green
      backgroundColor: 'rgb(22,205,185)',
      // backgroundColor: 'rgb(32,176,141)',
      borderColor: 'rgb(22,205,185)',
      // borderColor: 'rgb(32,176,141)',
      pointBackgroundColor: 'rgb(255,255,255)',
      pointBorderColor: 'rgb(22,205,185)',
      // pointBorderColor: 'rgb(32,176,141)',
      pointHoverBackgroundColor: 'rgb(255,255,255)',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    }
  ];
  public lineChartColor3: Array<any> = [
    { // green
      backgroundColor: 'rgb(22,205,185,0.3)',
      // backgroundColor: 'rgb(217,241,226)',
      borderColor: 'rgb(22,205,185)',
      // borderColor: 'rgb(64,187,110)',
      pointBackgroundColor: 'rgb(255,255,255)',
      pointBorderColor: 'rgb(22,205,185)',
      pointHoverBackgroundColor: 'rgb(22,205,185)',
      // pointBorderColor: 'rgb(32,176,141)',
      // pointHoverBackgroundColor: 'rgb(32,176,141)',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
      pointRadius: 5,

    }
  ];
  public lineChartLegend: boolean = false;
  public lineChartType: string = 'line';
  public barChartType: string = 'bar';
  public barChartLegend: boolean = false;

  goToMedia() {
    this._router.navigate(['/dashboard/mediaDashboard/' + this.company_id]);
  }
  dataTablePopulationCompanyIndicators(companyIndicatorDetails, flag, targetFlagCompany) {

    this.dataSourceTrans = [];
    this.dataSourcePopUp = [];
    this.displayedColumns = [];
    this.jsonArray = [];
    this.indArr = [];
    if (companyIndicatorDetails) {
      if (flag)
        this.indArr = companyIndicatorDetails.slice(0, 5);
      else
        this.indArr = companyIndicatorDetails;
    }
var count=0;
    for (let indicator of this.indArr) {
      let actualValue=0;
      let actualValueLatest=0;
      let actualResult=0;
      for(let actuals of indicator.chartAxesVO){
        if(actuals.year=="2019"){
          actualValueLatest=actuals.actual;
        }
        if(actuals.year=="2018"){
          actualValue=actuals.actual;
        }
      }
      if(actualValueLatest!=null)
      {
        actualResult=actualValueLatest;
      }
      else{
        actualResult=actualValue;
      }
      var newArray = {};
      count++;
      var achievement = Math.round(indicator.achievement);
      var achievementColorStatus;
 if(achievement>=80)
 {
  achievementColorStatus=3;
 }
 else if(achievement<80 && achievement>=20){
  achievementColorStatus=2;
 }
 else if(achievement<20){
  achievementColorStatus=1;
 }
      newArray['Sno'] = count;
      newArray['Id'] = indicator.id;
      newArray['Indicator'] = indicator.indicatorName;
      
        //if (indicator.targetFlag === true)
          newArray['Target'] = indicator.target;
        //else
        //  newArray['Target'] = "-";
        newArray['AchievementColorCode'] = achievementColorStatus;
  
        newArray['Achievement'] = String(achievement.toFixed(1));
     
      newArray['Actual'] = actualValue;
      newArray['Unit'] = '-';
      if (indicator.unitOfMeasurement != null && indicator.unitOfMeasurement.length > 0) {
        newArray['Unit'] = indicator.unitOfMeasurement;
      }
      newArray['Critical'] = indicator.critical;
      newArray['Year'] = indicator.year;

      this.jsonArray.push(newArray);
    }

    if (flag) {
      this.dataSourceTrans = new MatTableDataSource(this.jsonArray);
      return this.dataSourceTrans;
    }
    else {
      this.dataSourcePopUp = new MatTableDataSource(this.jsonArray);
      this.targetFlagCompanyIndicatorPopUp = targetFlagCompany;
      this.displayedColumns = this.displayedColumnsCompany();
    }
  }
  displayedColumnsCompany() {

    let displayedColumnsTransanction = [];
    
      return displayedColumnsTransanction = ['Indicator', 'Unit', 'Target', 'Actual', 'Achievement'];

  }
  doughnutChartData(percentage: number) {
    if (percentage > 100)
      percentage = 100;
    this.doughnutData = [percentage, (100 - percentage)];
    return this.doughnutData;
  }
  OptionsAchievement() {
    this.chartOptions = {
      responsive: true,
      cutoutPercentage: 40,
    };
    return this.chartOptions;
  }
  getCompanyIndicatorForPopUp() {
    this.companyService.getCompanySelectedIndicators(this.company_id).subscribe((companyIndicatorList) => {
      this.companyIndicatorList = companyIndicatorList;
      let flag=0;
      let targetFlagCompany=1;
      this.dataTablePopulationCompanyIndicators(this.companyIndicatorList, flag, targetFlagCompany)
    });
  }
}
