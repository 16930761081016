import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BaseUrlService {

  constructor(private http: HttpClient) { }


  // public static get INDICATORBASEURL(): string { return "http://10.32.9.131:5931"; };
  // public static get MASTERBASEURL(): string { return "http://10.32.9.131:5930"; };
  // public static get PERFORMANCEFRAMEWORKBASEURL(): string { return "http://10.32.9.131:5933"; };
  // public static get PARAMATERCONFIGURATIONBASEURL(): string { return "http://10.32.9.131:5932"; };
  // public static get REPORTBASEURL(): string { return "http://10.32.9.131:5901"; };
  // public static get ASSESSMENTBASEURL(): string { return "http://10.32.9.131:5934"; };
  // public static get FUNDBASEURL(): string { return "http://10.32.9.131:5935"; };    
  //public static get PERFORMANCEFRAMEWORKBASEURL(): string { return "http://10.32.9.131:5933"; }; 
//   public static get INDICATORBASEURL(): string { return "https://7wre3z3bkb.execute-api.us-east-1.amazonaws.com"; };
//   public static get MASTERBASEURL(): string { return "https://m7z0tiemz5.execute-api.us-east-1.amazonaws.com"; };
//   public static get PERFORMANCEFRAMEWORKBASEURL(): string { return "https://rwnphyra9i.execute-api.us-east-1.amazonaws.com"; };
//   public static get PARAMATERCONFIGURATIONBASEURL(): string { return "https://rgfhpidu22.execute-api.us-east-1.amazonaws.com"; };
//   public static get REPORTBASEURL(): string { return "https://5ps2f2h3q1.execute-api.us-east-1.amazonaws.com"; };
//   public static get ASSESSMENTBASEURL(): string { return "https://91k69pxf3a.execute-api.us-east-1.amazonaws.com"; };
//   public static get FUNDBASEURL(): string { return "https://hm6xevhx3g.execute-api.us-east-1.amazonaws.com"; };
 // public static get FLASKBASEURL(): string { return "https://2l2w4adevf.execute-api.us-east-1.amazonaws.com";  }; 
//New links
   public static get INDICATORBASEURL(): string { return "https://l4vrcu2g34.execute-api.us-east-1.amazonaws.com"; };
  public static get MASTERBASEURL(): string { return "https://7djkmcsll2.execute-api.us-east-1.amazonaws.com"; };
  public static get PERFORMANCEFRAMEWORKBASEURL(): string { return "https://hgznee3hog.execute-api.us-east-1.amazonaws.com"; };
  public static get PARAMATERCONFIGURATIONBASEURL(): string { return "https://koqtqbj34a.execute-api.us-east-1.amazonaws.com"; };
  public static get REPORTBASEURL(): string { return "https://lyk1jvipk0.execute-api.us-east-1.amazonaws.com"; };
  public static get ASSESSMENTBASEURL(): string { return "https://5tjab19xyh.execute-api.us-east-1.amazonaws.com"; };
  public static get FUNDBASEURL(): string { return "https://rfkuc41jhk.execute-api.us-east-1.amazonaws.com"; };
public static get FLASKBASEURL(): string { return "https://2weo3q0ijd.execute-api.us-east-1.amazonaws.com";  };

  getListOfUsers(masterRequestVO: any) {
    let body = JSON.stringify(masterRequestVO);
    let url = BaseUrlService.MASTERBASEURL + '/getMasterData';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };
    return this.http.post(url, body, httpOptions);
  }

  getListOfStandardizedSDGCodes() {
    let sdgCodeList = ['Goal 01', 'Goal 02', 'Goal 03', 'Goal 04', 'Goal 05',
      'Goal 06', 'Goal 07', 'Goal 08', 'Goal 09', 'Goal 10',
      'Goal 11', 'Goal 12', 'Goal 13', 'Goal 14', 'Goal 15',
      'Goal 16', 'Goal 17'];
    return sdgCodeList;
  }

  getListOfStandardizedPerformanceAreaCodes() {   //subcategory codes
    let performanceAreaCodesList = ['ENV', 'SOC', 'GOV', 'MFC', 'SRC', 'HUC', 'ITC', 'FNC', 'NAC'];
    return performanceAreaCodesList;
  }
}