import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { PerformanceFrameworkCompanyService } from '../performance-framework-company.service';

@Component({
  selector: 'app-list-company-for-copy',
  templateUrl: './list-company-for-copy.component.html',
  styleUrls: ['./list-company-for-copy.component.css']
})
export class ListCompanyForCopyComponent implements OnInit {

  activeCompanyIndicators: any;
  frameworkId: any;
  anyOneSelected: boolean;
  selectedCompanyIds = [];
  dataSource: any=[];
  displayedColumns: any[];
  companyId: any;
  companyTypeId:any;
  paginator:any;
  loading:boolean;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }    @ViewChild(MatSort) sort: MatSort;
  constructor(private companyFrameworkService: PerformanceFrameworkCompanyService,private router:Router, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.companyId=params.companyId,
      this.frameworkId=params.frameworkId
          }); 
    
    
    this.activatedRoute.queryParams.subscribe(params => { this.companyId = params.companyId });
   }

  ngOnInit() {
    this.loading =true;
    this.companyFrameworkService.getIndicatorsFromActiveCompany(this.companyId).subscribe(data => {
       //   For search--No records
       this.activeCompanyIndicators=data;
                      this.loading=false;

         if (data){
          this.dataTablePopulation(data);
         }
         
       }, (error) => {
         console.log(error);

       });
  }
  dataTablePopulation(activeFrameworks) {

    this.displayedColumns = [];
    let frameworkArray = [];

    for (let framework of activeFrameworks) {
      var newArray = {};
      newArray['frameworkId'] = framework.performanceframeworkVO.frameworkId;
     newArray['companyId'] = framework.id;
      newArray['CompanyCode'] = framework.companyCode;
      //check
      //newArray['CompanyType'] = framework.companyVO.companyTypeName;
      newArray['CompanyType'] = framework.companyName;
      newArray['PerformanceFramework'] = "Performance Framework";
       frameworkArray.push(newArray);
  
  }

  this.dataSource = [];
  this.displayedColumns = ['CompanyCode','CompanyType','PerformanceFramework'];
  
  this.dataSource = new MatTableDataSource(frameworkArray);
  this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
}
applyFilter(filterValue: string) {
  filterValue = filterValue.trim();
  filterValue = filterValue.toLowerCase();
  this.dataSource.filter = filterValue;

}
setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
}
toggleCheckBox(companyId){
  return (this.selectedCompanyIds.indexOf(companyId) != -1) ? true : false;
}
getSelectedTemplate(event, companyId) {
  
   
  if(event.checked) {
    this.selectedCompanyIds.push(companyId);
  } else {
    let index = this.selectedCompanyIds.indexOf(companyId);
    this.selectedCompanyIds.splice(index,1);
  }
  if(this.selectedCompanyIds.length>0)
  {
     this.anyOneSelected=true;
  }
  else
  {
    this.anyOneSelected=false;
  }
  


}

copyTemplate()
{   
  let selectedVO={};
    let indicatorList :any =[];
    let companyIndicatorCopyDetails;
    let selectedIndicatorList :any =[];
    
    if (this.selectedCompanyIds.length > 0) {
      for (let companyId of this.selectedCompanyIds){ 
        companyIndicatorCopyDetails= this.activeCompanyIndicators.find(trans => trans.id == companyId);   
    
      for(let indicator of companyIndicatorCopyDetails.performanceframeworkVO.indicatorVOList){
         // indicator.indicatorConfigurationVO={};
          indicatorList.push(indicator);
        }
       
      } 
    }
      selectedVO['indicators']=JSON.stringify(indicatorList);
      selectedVO['frameworkId']=this.frameworkId;
    this.router.navigate(['/addCompanyIndicatorsComponent'],{queryParams:selectedVO,skipLocationChange: true});
   
}
navigateToAddIndicator(){
  let companyDetails={};
  companyDetails['frameworkId']=this.frameworkId;
  this.router.navigate(['/addCompanyIndicatorsComponent'],{queryParams:companyDetails,skipLocationChange: true });
}

redirectToViewFrameworkPage(frameworkId)
{
      let frameworkDetails = {};
       frameworkDetails['frameworkId'] = frameworkId;
       frameworkDetails['redirectFromCopyCompanyPage'] =true;
       frameworkDetails['redirectFromArchievePage'] =false;

       //main company details  for which copy from company functionality is called
       frameworkDetails['companyId'] = this.companyId;
       frameworkDetails['selectedFrameworkId'] =  this.frameworkId;
     
       this.router.navigate(['/viewCompanyFramework'], { queryParams: frameworkDetails, skipLocationChange: true });
}
}
