import { Component, OnInit } from '@angular/core';
import { IndicatorService } from '../indicator.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
@Component({
  selector: 'app-search-indicator',
  templateUrl: './search-indicator.component.html',
  styleUrls: ['./search-indicator.component.css']
})
export class SearchIndicatorComponent implements OnInit {
  indicatorSearchVO:any;
  searchIndicatorForm:FormGroup;
  indicatorList:any;
  constructor(private indicatorService: IndicatorService, private formBuilder: FormBuilder) { }
  ngOnInit() {
    this.indicatorService.getIndicatorSearchVO().subscribe(data =>
    {
             this.indicatorSearchVO = data;
    },(error)=>
    {
      console.log(error);
    }
    )
    this.searchIndicatorForm = this.createFormGroup();
    this.indicatorService.getListOfAllIndicators().subscribe(data => {
     
      this.indicatorList = data;
    }, (error) => {
      console.log(error);
    });
  }
  createFormGroup() {
    return this.formBuilder.group({
      indicatorName: [''],
      indicatorType: this.formBuilder.group({id:[0]}),
      subCategoryId: [0],
      indicatorStackId: [0],
      categoryId: [0]
    })
  }
  loadSubcategory(categoryId)
  {
    this.indicatorService.getSubCategory(categoryId).subscribe(
      data=>{
        if(data && data.length==0) {
          this.searchIndicatorForm.get('subCategoryId').patchValue(0);
          
        }
        this.indicatorSearchVO.subcategoryList=data;
      }
    )
  }
  getIndicatorStack(subCategoryId)
  {
     this.indicatorService.getIndicatorStackList(subCategoryId).subscribe(
       data=>{
       	 this.searchIndicatorForm.get('indicatorStackId').patchValue(0);
         this.indicatorSearchVO.indicatorStackList=data;
       }
     )
  }
  onSubmit() {
    this.indicatorService.getIndicatorsForSearch(this.searchIndicatorForm.value).subscribe(data => {
      this.indicatorList = data;
    }
  )
  }
}
