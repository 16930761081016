import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-questions',
  templateUrl: './search-questions.component.html',
  styleUrls: ['./search-questions.component.css']
})
export class SearchQuestionsComponent implements OnInit {
  questionSelectedObjs: Array<any> = [];
  displayedColumns: string[];
  dataSource: any;
  dataTableFlag: boolean;
  templateForm: FormGroup;
  factorList:any;
  subFactorList:any;
  questionList:any;
  initialQuestionList:any;
  addedQuestionList:any =[];
  parentNode:any;
  flag:boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  id:any;
  responseOptionId:any;
  parentQuestionNode:any;
  qstnIdList:any=[];
  selectedQuestion: any = [];
  finalSelectedQuestion:any=[];
  newQuestion: any = [];
  missing:any;
  constructor(private _fb: FormBuilder,  private dialogRef:MatDialogRef<SearchQuestionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {parentQuestionNode:any,idList:any,selectedQuestion:any,index:any,responseOptionId:any},
    private assessmentTemplateService: AssessmentTemplateMasterdataService,private router:Router) { }

  ngOnInit() {
   this.parentQuestionNode= this.data.parentQuestionNode;
  this.id=this.parentQuestionNode.nodeData.questionVO.id;
  this.selectedQuestion=this.data.selectedQuestion;
  this.qstnIdList=this.data.idList;
  this.responseOptionId=this.data.responseOptionId;
  this.assessmentTemplateService.loadChildQuestionsForSelectedQuestion(this.id).subscribe(data =>{
    var masterData :any = data;
    this.factorList = masterData.factorVOList;
    this.subFactorList = masterData.subFactorVOList;
    this.initialQuestionList = masterData.questionVOList;
    this.fetchDataTable(this.initialQuestionList, this.selectedQuestion,this.qstnIdList);
  
  }) 
  this.templateForm = this.createFormGroup();
} 

applyFilter(filterValue: string) {
  filterValue = filterValue.trim();
  filterValue = filterValue.toLowerCase();
  this.dataSource.filter = filterValue;
  
  if (this.dataSource.filteredData.length == 0) {
    this.flag = true;
  } else {
    this.flag = false;
  }
}

createFormGroup(){
  return this._fb.group({
    id:[0],
    templateName:[''],
    factorVOList:[''],
    subFactorVOList:['']


  })
}
fetchDataTable(questionList,selectedQuestionList,qstnIds){

  var jsonArray = [];
  this.displayedColumns =[];
  var count =0;
   let qstnsList = questionList.map(item => item.id);

   var filteredQstnIds = qstnsList.filter(
    function(e) {
      return this.indexOf(e) < 0;
    },
    qstnIds
);
if(selectedQuestionList!=undefined){
  
   let selectedqstnsList = selectedQuestionList.map(item => item.id);
   this.missing = filteredQstnIds.filter(item => selectedqstnsList.indexOf(item) < 0);
 }
 else{
    this.missing =filteredQstnIds;
 }
  for( let i of questionList){
    if(this.missing.indexOf(i.id) !== -1) {
    var newArray = {};
    newArray['Sno'] = ++count;
    newArray['questionText'] = i.questionText;
    newArray['id'] = i.id;
    jsonArray.push(newArray);
    }
 }

  this.displayedColumns=['Sno','Question']
  this.dataSource = new MatTableDataSource(jsonArray);
  this.dataSource.paginator = this.paginator;
  this.dataSource.sort = this.sort;

}
resetForm(){
  this.templateForm.reset();
  this.newQuestion =[];
  this.fetchDataTable(this.initialQuestionList,this.selectedQuestion,this.qstnIdList);
  this.questionSelectedObjs =[];
}
onNoClick(): void {
  this.dialogRef.close(this.selectedQuestion);
}

onChange1(element:any, isChecked: any) {


    if(isChecked.checked) {
      var responseOptionVO;
      element['primaryQuestionFlag']='N';
      element['skipLogicFlag']='N';
      element['criticalFlag']='Y';
      element['name']=element['Question'];
      element['index']=this.data.index;
      responseOptionVO={id:this.data.responseOptionId};
      element['responseOptionVO'] = responseOptionVO;    
      this.newQuestion.push(element);
    } else {
      let index = this.newQuestion.indexOf(element);
      this.newQuestion.splice(index,1);
    }


}
onChange(element:any, isChecked: any) {
  var assessmentTemplateQuestionVO = {};
  var viridiTreeNode = {};
  var responseOptionVO = {};
  responseOptionVO['id']=this.responseOptionId;
 if(isChecked.checked) {
     element['primaryQuestionFlag']='N';
     element['skipLogicFlag']='N';
     element['criticalFlag']='Y';
     assessmentTemplateQuestionVO['id']=0;
     assessmentTemplateQuestionVO['questionVO']=element;
     assessmentTemplateQuestionVO['responseOptionVO']=responseOptionVO;
     viridiTreeNode['nodeData']=assessmentTemplateQuestionVO;
    // viridiTreeNode['parentNode']=this.parentQuestionNode;
   //  viridiTreeNode['parentNode']=this.parentQuestionNode;
   this.newQuestion.push(viridiTreeNode);
 } else {
  let index = this.newQuestion.indexOf(element);
      this.newQuestion.splice(index,1);
 }


} 
onNext(){
 this.finalSelectedQuestion = this.selectedQuestion.concat(this.newQuestion);
 this.dialogRef.close(this.finalSelectedQuestion);
}

}
