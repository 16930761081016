import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, AbstractControl, AbstractControlOptions, ValidationErrors, FormGroupDirective } from '@angular/forms';
import { IndicatorMasterService } from '../../indicator-master.service';
import { CustomValidationsService } from '../../../../shared/Validations/custom-validations.service';
import { AddEditSuccessComponent } from '../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import swal from 'sweetalert2';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-subcategory',
  templateUrl: './add-subcategory.component.html',
  styleUrls: ['./add-subcategory.component.css']
})
export class AddSubcategoryComponent implements OnInit {

  subcategoryForm: FormGroup;
  categories: any;
  topWrapper: any;
  categorySelected: number;
  @ViewChild("topWrap") topWrap;
  @ViewChild("subCategoryForm") subCategoryForm;
  constructor(private formBuilder: FormBuilder, private customValidationsService: CustomValidationsService, private indicatorMasterService: IndicatorMasterService, private addEditSuccessComponent: AddEditSuccessComponent) { }

  ngOnInit() {
    this.indicatorMasterService.getCategories().subscribe(data => {
      this.categories = data;
    });
    this.subcategoryForm = this.createFormGroup();
    this.topWrapper = this.topWrap;
  }

  createFormGroup() {

    return this.formBuilder.group({
      id: [],
      code: ['', [Validators.required,Validators.maxLength(50),this.customValidationsService.noWhitespaceValidator], this.uniqueCodeValidator()],
      name: ['', [Validators.required, Validators.maxLength(1000), this.customValidationsService.noWhitespaceValidator]],
      indicatorCategoryVO: this.formBuilder.group({
        name: [],
        code: [],
        id: ['', Validators.required],
      })
    }, { updateOn: 'blur' })

  }

  categoryIdChange() {

    this.categorySelected = this.subcategoryForm.get('indicatorCategoryVO').get('id').value;
  }

  uniqueCodeValidator()
  {
      return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      var id = 0
      if (this.subcategoryForm && this.subcategoryForm.controls.id.value) {
        id = this.subcategoryForm.controls.id.value;
      }
      
      return this.indicatorMasterService.checkSubcategoryCodeValidation(id, c.value).pipe(
        map(val => {
          if (val == 1) {
  
            return { 'uniqueCode': true };
          }
        })
      );
    };

  }

  uniqueNameValidator()
  {
    return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      var id = 0
      if (this.subcategoryForm && this.subcategoryForm.controls.id.value) {
        id = this.subcategoryForm.controls.id.value;
      }
      
      return this.indicatorMasterService.checkSubcategoryNameValidation(id, c.value).pipe(
        map(val => {

          console.log(val)
          if (val == 1) {
            return { 'uniqueName': true };
          }
        })
      );
    };


  }

  

  onSubmit() {

    this.categorySelected = this.subcategoryForm.get('indicatorCategoryVO').get('id').value;
    if (this.subcategoryForm.valid) {
      this.indicatorMasterService.createSubCategory(this.subcategoryForm.value).subscribe((errorFlag) => {

        if (this.subcategoryForm.get('id').value != null)
          var message = "Successfully Edited Performance Area";
        else
          var message = "Successfully Added Performance Area";

        this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
            this.resetSubcategoryForm();
        });
      }, (error) => {
        console.log(error);
      });


    }
  }

  resetSubcategoryForm() {

    this.subCategoryForm.resetForm();
    if(this.topWrap && this.topWrap.nativeElement){
      this.topWrap.nativeElement.classList.remove("top-form-wrapper-highlight");
    }

  }

  defaultValues(defaultArray) {
    //setting default values for the form
    this.subcategoryForm.patchValue({
      id: defaultArray.id,
      code: defaultArray.code,
      name: defaultArray.name,
      indicatorCategoryVO: {
        code: defaultArray.indCategoryCode,
        name: defaultArray.Category,
        id: defaultArray.indCategoryId,
      }
    });
  }

}
