import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ConfigurationService } from '../../configuration.service';
import { FormBuilder } from '@angular/forms';
import { AddPrimaryDatasourceComponent } from '../add-primary-datasource/add-primary-datasource.component';
import { AddEditSuccessComponent } from '../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import {MatPaginator} from '@angular/material';
import {MatSort, MatTableDataSource} from '@angular/material';
@Component({
  selector: 'app-view-primary-datasource',
  templateUrl: './view-primary-datasource.component.html',
  styleUrls: ['./view-primary-datasource.component.css']
})
export class ViewPrimaryDatasourceComponent implements OnInit {

 
  displayedColumns: any;
  originalColumnNames : any;
  colIndex: number = 0;
  jsonArray: any=[];
  top:any;
  paginator:any;
  dataSource: any ;
  @ViewChild('matSort') sort: MatSort;
  displayedColumnsFordataAttributes: any[];
  originalColumnNamesFordataAttributes: any[];
  jsonArrayFordataAttributes: any[];
  primaryDataSourceDataList:any[];
  uploadedFileName:any;
  @ViewChild('MatPaginator') set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
}
  @Input() set topWrapper(val: any) {
    this.top=val;
  }
  @Input() set primaryDataSourceList(val:any) {
    this.primaryDataSourceDataList=val;
    this.dataTablePopulation()
  }
  @Output() primaryDataSourceComponent = new EventEmitter();

  ngOnInit(): void {
    // console.log("primaryDataSourceDataList")
    // console.log(this.primaryDataSourceDataList)
  // this.dataTablePopulation()dataOb
  
}
  constructor(private configurationService: ConfigurationService,private addPrimaryDatasourceComponent :  AddPrimaryDatasourceComponent, private addEditSuccessComponent: AddEditSuccessComponent) { }


  edit(dataObj: any) {
    console.log("editing")
    console.log(dataObj)
    this.primaryDataSourceComponent.emit({
      id: dataObj.id,
      name:dataObj.sourceName,
      data: dataObj.methodId,
      format: dataObj.formatId      ,
      links: dataObj.links      ,
      location: dataObj.location      ,
      key:dataObj.key,
      primaryDatasourceIdentifierVOList:dataObj.identifierIdList,
      fileName:dataObj.fileName,
      fileUploadName:dataObj.fileUploadName
    });
  }
 deletePrimarydataSource(dataObj)
 {
  var confirmMessage = "You Want To Delete Primary Data Source";
  this.addEditSuccessComponent.showConfirmationSwal(confirmMessage,'Yes','No').then((isConfirm) => {
    if (isConfirm.value) {
      
      this.configurationService.deletePrimaryDataSource(dataObj.id).subscribe((data) => {
        var deleteSuccess = 'Deleted Primary Data Source';
          this.addEditSuccessComponent.showSuccessSwal(deleteSuccess)
            .then((value) => {
          this.addPrimaryDatasourceComponent.fetchPrimaryDataSourceData();
          this.addPrimaryDatasourceComponent.resetPrimaryDataForm();
        });
      },
        (error) => { 
          console.log(error);
         });


    }


  });
 }
  dataTablePopulation() {
    this.displayedColumns = [];
    this.originalColumnNames = [];
    this.jsonArray = [];
    var count = 0;
    for (let primaryDataSource of this.primaryDataSourceDataList) {
      var newObj = {};
      newObj['id']=primaryDataSource.id;
      newObj['Sno'] = ++count;
      newObj['method']=primaryDataSource.constantsVOData.value
      newObj["methodId"]=primaryDataSource.constantsVOData.id
      newObj['sourceName'] =primaryDataSource.name;
      newObj['format'] = primaryDataSource.constantsVOFormat.value;
      newObj['formatId'] = primaryDataSource.constantsVOFormat.id;
      newObj['links'] = primaryDataSource.links;
      newObj['key']=primaryDataSource.key;
      newObj['dataAttributes'] = '';
      newObj["fileName"]=primaryDataSource.fileName;
      newObj["fileUploadName"]=primaryDataSource.fileUploadName
      
      let identifierIdList=[]
      let identifierNameList=[]
      for(let identifier of primaryDataSource.primaryDatasourceIdentifierVOList)
      {
        identifierIdList.push(identifier.constantsVO.id)
        identifierNameList.push(identifier.constantsVO.value)
      }
      newObj['identifiers'] =identifierNameList
      newObj["identifierIdList"]=identifierIdList
      newObj['location']=primaryDataSource.location;
      newObj['statusFlag']=primaryDataSource.statusFlag
      this.jsonArray.push(newObj);
   }
    this.displayedColumns = ['Sno','sourceName','method', 'format','links', 'location','dataAttributes','identifiers','statusFlag','Actions']
    this.originalColumnNames = ['Sl.No','Source Name','Method of Data Ingesion', 'Format','API Links','Location / Filename','Data Attributes','Identifiers','Status Flag', 'Actions']
    this.dataSource=[];
    this.dataSource = new MatTableDataSource(this.jsonArray);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }  
  download(){

    let fileName = this.uploadedFileName;
     window.location.href = this.configurationService.downloadFile() + '/primaryDataAttributedownload?fileUploadName=' + encodeURIComponent(fileName)


  }
  setDataAttributesDetails(element)
  {    
    this.uploadedFileName=element.fileUploadName;
    var primaryId = element.id;
    this.displayedColumnsFordataAttributes = [];
    this.originalColumnNamesFordataAttributes = [];
    this.jsonArrayFordataAttributes=[]
    this.configurationService.listPrimaryDataAttribute(primaryId).subscribe((dataAttributesArray) => {
      var count=0;
      console.log("dataAttributesArray")
      console.log(dataAttributesArray)
      for (let i of dataAttributesArray) {
        var newArray = {};
        newArray['Sno'] = ++count;
        newArray['dataAttributeName'] =i.name;
        newArray['dataAttributeType'] = i.type;
        newArray['attributeDetails'] = i.details;
  
       this.jsonArrayFordataAttributes.push(newArray)
      }
      this.originalColumnNamesFordataAttributes= ['Sl.No',"Data Attribute Name","Data Attribute Type","Data Attribute Details"];
      this.displayedColumnsFordataAttributes= ['Sno',"dataAttributeName","dataAttributeType","attributeDetails"];
    
    },
      (error) => { 
        console.log(error);
       });   
  }

}


