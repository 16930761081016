import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import * as d3 from 'd3';
import { CompanyService } from '../../company.service';

@Component({
  selector: 'app-sray-rating-agency',
  templateUrl: './sray-rating-agency.component.html',
  styleUrls: ['./sray-rating-agency.component.css']
})
export class SrayRatingAgencyComponent implements OnInit {

  public overallESGData: any = [];
  public doughnutChartType = 'doughnut';
  public overallESGChartColor: Array<any> = [];
  chartColors: { backgroundColor: string[]; }[];
  chartOptions: any;
  public gaugemap: any = {};
  epowerGauge: any;
  dataSource: any;  
  displayedColumns: any;
  datajson: any=[];
  ratingAgencyName: any;
  companyId: any;
  companyName: any;
  ratingAgencyId: any;
  country:any;
  sector:any;
  industry:any;
  companyRatingmappingDetails: any;
  governance: any;
  social: any;
  environment: any;
  gcIndPillar1Name: any;
  gcIndPillar2Name: any;
  gcIndPillar3Name: any;
  gcIndPillar4Name: any;
  gcIndPillar5Name: any;
  gcIndPillar1Value: any;
  gcIndPillar2Value: any;
  gcIndPillar3Value: any;
  gcIndPillar4Value: any;
  gcIndPillar5Value: any;
  nearTerm: any;
  longTerm: any;
  tempScoreNearTermValue: any;
  tempScoreLongTermValue: any;
  dataReport: any[];
  overallESGDataValue: any;
  gcIndPillar1Color: string;
  gcIndPillar2Color: string;
  gcIndPillar3Color: string;
  gcIndPillar4Color: string;
  gcIndPillar5Color: string;
  nearTermColor: string;
  longTermColor: string;
  lastUpdatedDate: any;

  constructor(private companyService: CompanyService, private dialogRef: MatDialogRef<SrayRatingAgencyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
 

  ngOnInit() {
      this.ratingAgencyName = this.data.ratingAgencyName;
      this.companyId = this.data.companyId;
      this.companyName = this.data.companyName;
      this.ratingAgencyId = this.data.ratingAgencyId;
      this.lastUpdatedDate=this.data.lastUpdatedDate;
      this.fetchCompanyRatingAgencingMappingDetails();
  }

  fetchCompanyRatingAgencingMappingDetails(){
    this.companyService.fetchCompanyComparisonDetails(this.companyId, this.ratingAgencyId).subscribe(data => {
      this.companyRatingmappingDetails = data.companyRatingAgencyDetails;
      if(this.companyRatingmappingDetails){
      this.country=this.companyRatingmappingDetails.country;
      this.sector=this.companyRatingmappingDetails.sector;
      this.industry=this.companyRatingmappingDetails.industry;
      this.environment=this.companyRatingmappingDetails.environment;
      this.social=this.companyRatingmappingDetails.social;
      this.governance=this.companyRatingmappingDetails.governance;
      this.overallESGChartColor = this.chartColorESG(this.companyRatingmappingDetails.overallEsg);
      this.overallESGData = [this.companyRatingmappingDetails.overallEsg, (100 - this.companyRatingmappingDetails.overallEsg)];
      this.overallESGDataValue=this.companyRatingmappingDetails.overallEsg;
      if(this.environment && this.social && this.governance)
      {
        this.draw();
      }
      this.gcIndPillar1Name=this.companyRatingmappingDetails.gcIndPillar1Name;
      this.gcIndPillar2Name=this.companyRatingmappingDetails.gcIndPillar2Name;
      this.gcIndPillar3Name=this.companyRatingmappingDetails.gcIndPillar3Name;
      this.gcIndPillar4Name=this.companyRatingmappingDetails.gcIndPillar4Name;
      this.gcIndPillar5Name=this.companyRatingmappingDetails.gcIndPillar5Name;
      this.gcIndPillar1Value=this.companyRatingmappingDetails.gcIndPillar1Value;
      this.gcIndPillar2Value=this.companyRatingmappingDetails.gcIndPillar2Value;
      this.gcIndPillar3Value=this.companyRatingmappingDetails.gcIndPillar3Value;
      this.gcIndPillar4Value=this.companyRatingmappingDetails.gcIndPillar4Value;
      this.gcIndPillar5Value=this.companyRatingmappingDetails.gcIndPillar5Value;

      if(this.gcIndPillar1Value<=25){
        this.gcIndPillar1Color="global-color-0 global-legend-rect-box";
      }
      else if(this.gcIndPillar1Value>=26 && this.gcIndPillar1Value<50){
        this.gcIndPillar1Color="global-color-1 global-legend-rect-box";
      }
      else if(this.gcIndPillar1Value >=50 && this.gcIndPillar1Value<75){
        this.gcIndPillar1Color="global-color-2 global-legend-rect-box";
      }
      else {
        this.gcIndPillar1Color="global-color-3 global-legend-rect-box";
      }

      if(this.gcIndPillar2Value<=25){
        this.gcIndPillar2Color="global-color-0 global-legend-rect-box";
      }
      else if(this.gcIndPillar2Value>=26 && this.gcIndPillar2Value<50){
        this.gcIndPillar2Color="global-color-1 global-legend-rect-box";
      }
      else if(this.gcIndPillar2Value >=50 && this.gcIndPillar2Value<75){
        this.gcIndPillar2Color="global-color-2 global-legend-rect-box";
      }
      else {
        this.gcIndPillar2Color="global-color-3 global-legend-rect-box";
      }

      if(this.gcIndPillar3Value<=25){
        this.gcIndPillar3Color="global-color-0 global-legend-rect-box";
      }
      else if(this.gcIndPillar3Value>=26 && this.gcIndPillar3Value<50){
        this.gcIndPillar3Color="global-color-1 global-legend-rect-box";
      }
      else if(this.gcIndPillar3Value >=50 && this.gcIndPillar3Value<75){
        this.gcIndPillar3Color="global-color-2 global-legend-rect-box";
      }
      else {
        this.gcIndPillar3Color="global-color-3 global-legend-rect-box";
      }

      if(this.gcIndPillar4Value<=25){
        this.gcIndPillar4Color="global-color-0 global-legend-rect-box";
      }
      else if(this.gcIndPillar4Value>=26 && this.gcIndPillar4Value<50){
        this.gcIndPillar4Color="global-color-1 global-legend-rect-box";
      }
      else if(this.gcIndPillar4Value >=50 && this.gcIndPillar4Value<75){
        this.gcIndPillar4Color="global-color-2 global-legend-rect-box";
      }
      else {
        this.gcIndPillar4Color="global-color-3 global-legend-rect-box";
      }

      if(this.gcIndPillar5Value<=25){
        this.gcIndPillar5Color="global-color-0 global-legend-rect-box";
      }
      else if(this.gcIndPillar5Value>=26 && this.gcIndPillar5Value<50){
        this.gcIndPillar5Color="global-color-1 global-legend-rect-box";
      }
      else if(this.gcIndPillar5Value >=50 && this.gcIndPillar5Value<75){
        this.gcIndPillar5Color="global-color-2 global-legend-rect-box";
      }
      else {
        this.gcIndPillar5Color="global-color-3 global-legend-rect-box";
      }

      this.nearTerm=this.companyRatingmappingDetails.nearTerm;
      this.longTerm=this.companyRatingmappingDetails.longTerm;
      this.tempScoreLongTermValue=parseFloat(this.companyRatingmappingDetails.tempScoreLongTermValue).toFixed(2);
      this.tempScoreNearTermValue=parseFloat(this.companyRatingmappingDetails.tempScoreNearTermValue).toFixed(2);
      if(this.tempScoreLongTermValue>2.7){
        this.nearTermColor="color-score red-bg";
      }
      else if(this.tempScoreNearTermValue>1.5 && this.tempScoreNearTermValue<=2.7){
        this.nearTermColor="color-score amber-bg";
      }
      else if(this.tempScoreNearTermValue<=1.5){
        this.nearTermColor="color-score green-bg";
      }
    

      if(this.tempScoreLongTermValue>2.7){
        this.longTermColor="color-score red-bg";
      }
      else if(this.tempScoreLongTermValue>1.5 && this.tempScoreLongTermValue<=2.7){
        this.longTermColor="color-score amber-bg";
      }
      else if(this.tempScoreLongTermValue<=1.5){
        this.longTermColor="color-score green-bg";
      }
      
     
      this.populateTable(this.companyRatingmappingDetails);
    }

    });
  }
  chartColorESG(esg: number) {
    if (esg >= 80) {
      this.chartColors = [{ backgroundColor: ["#3e7d14", "#ebebeb"] }];
    }
    else if (esg >= 60 && esg <= 79) {
      this.chartColors = [{ backgroundColor: ["#93e45b", "#ebebeb"] }];
    }
    else if (esg >= 40 && esg <= 59) {
      this.chartColors = [{ backgroundColor: ["#bfbfbf", "#ebebeb"] }];
    }
    else if (esg >= 20 && esg <= 39) {
      this.chartColors = [{ backgroundColor: ["#595959", "#ebebeb"] }];
    }
    else if (esg >= 0 && esg <= 19) {
      this.chartColors = [{ backgroundColor: ["#000000", "#ebebeb"] }];
    }
    return this.chartColors;
  }
  OptionsESG() {
    var per = 60;
    this.chartOptions = {
      responsive: true,
      cutoutPercentage: per,
      animation: false,
    };
    return this.chartOptions;
  }

  closePopup(): void {
    this.dialogRef.close();
  }


  // chart not working
  draw() {
    var self = this;
    var gauge = function (container, configuration) {

      var config = {
        size: 150,
        clipWidth: 100,
        clipHeight: 90,
        ringInset: 17,
        ringWidth: 17,

        pointerWidth: 9,
        pointerTailLength: 5,
        pointerHeadLengthPercent: 0.6,

        minValue: 0,
        maxValue: 1,

        minAngle: -90,
        maxAngle: 92,

        transitionMs: 850,

        majorTicks: 5,
        labelFormat: d3.format('.1f'),
        labelInset: 10,
        arcColorFn: d3.scaleOrdinal().range(['#000000', '#595959', '#bfbfbf', '#93e45b', '#3e7d14'])

      };
      var range = undefined;
      var r = undefined;
      var pointerHeadLength = undefined;
      var value = 0;

      var svg = undefined;
      var arc = undefined;
      var scale = undefined;
      var ticks = undefined;
      var tickData = undefined;
      var pointer = undefined;

      var donut = d3.pie();

      function deg2rad(deg) {
        return deg * Math.PI / 180;
      }

      function newAngle(d) {
        var ratio = scale(d);
        var newAngle = config.minAngle + (ratio * range);
        return newAngle;
      }

      function configure(configuration) {
        var prop = undefined;
        for (prop in configuration) {
          config[prop] = configuration[prop];
        }

        range = config.maxAngle - config.minAngle;
        r = config.size / 2;
        pointerHeadLength = Math.round(r * config.pointerHeadLengthPercent);

        // a linear scale this.gaugemap maps domain values to a percent from 0..1
        scale = d3.scaleLinear()
          .range([0, 1])
          .domain([config.minValue, config.maxValue]);

        ticks = scale.ticks(config.majorTicks);
        tickData = d3.range(config.majorTicks).map(function () { return 1 / config.majorTicks; });

        arc = d3.arc()
          .innerRadius(r - config.ringWidth - config.ringInset)
          .outerRadius(r - config.ringInset)
          .startAngle(function (d, i) {
            var ratio = d * i;
            if (i == 0)
              ratio = 0;
            else if (i == 1)
              ratio = 0.20
            else if (i == 2)
              ratio = 0.40
            else if (i == 3)
              ratio = 0.60
            else if (i == 4)
              ratio = 0.80
            return deg2rad(config.minAngle + (ratio * range));
          })
          .endAngle(function (d, i) {
            var ratio = d * (i + 1);
            if (i == 0)
              ratio = 0.20
            else if (i == 1)
              ratio = 0.40
            else if (i == 2)
              ratio = 0.60
            else if (i == 3)
              ratio = 0.80
            else if (i == 4)
              ratio = 1
            return deg2rad(config.minAngle - 1 + (ratio * range));
          });
      }
      self.gaugemap.configure = configure;

      function centerTranslation() {
        return 'translate(' + r + ',' + r + ')';
      }

      function isRendered() {
        return (svg !== undefined);
      }
      self.gaugemap.isRendered = isRendered;

      function render(newValue) {
        svg = d3.select(container)
          .append('svg:svg')
          .attr('class', 'gauge')
          .attr('width', config.clipWidth)
          .attr('height', config.clipHeight);

        var centerTx = centerTranslation();

        var arcs = svg.append('g')
          .attr('class', 'arc')
          .attr('transform', centerTx);

        arcs.selectAll('path')
          .data(tickData)
          .enter().append('path')
          .attr('fill', function (d, i) {
            return config.arcColorFn(d * i);
          })
          .attr('d', arc);

        var lg = svg.append('g')
          .attr('class', 'label')
          .attr('transform', centerTx);
        lg.selectAll('text')
          .data(ticks)
          .enter().append('text')
          .attr('transform', function (d) {
            var ratio = scale(d);
            var newAngle = config.minAngle + (ratio * range);
            return 'rotate(' + newAngle + ') translate(-10,' + (config.labelInset - r) + ')';
          })
          .text(config.labelFormat);

        var lineData = [[config.pointerWidth / 2, 0],
        [0, -pointerHeadLength],
        [-(config.pointerWidth / 2), 0],
        [0, config.pointerTailLength],
        [config.pointerWidth / 2, 0]];
        var pointerLine = d3.line().curve(d3.curveLinear)
        var pg = svg.append('g').data([lineData])
          .attr('class', 'pointer')
          .attr('transform', centerTx);

        pointer = pg.append('path')
          .attr('d', pointerLine/*function(d) { return pointerLine(d) +'Z';}*/)
          .attr('transform', 'rotate(' + config.minAngle + ')');

        update(newValue === undefined ? 0 : newValue);
      }
      self.gaugemap.render = render;
      function update(newValue, newConfiguration?) {
        if (newConfiguration !== undefined) {
          configure(newConfiguration);
        }
        var ratio = scale(newValue);
        var newAngle = config.minAngle + (ratio * range);
        pointer.transition()
          .duration(config.transitionMs)
          .ease(d3.easeElastic)
          .attr('transform', 'rotate(' + newAngle + ')');
      }
      self.gaugemap.update = update;

      configure(configuration);

      return self.gaugemap;
    };

    this.epowerGauge = gauge('#e-power-gauge', {
      size: 130,
      clipWidth: 160,
      clipHeight: 90,
      ringWidth: 20,
      maxValue: 100,
      transitionMs: 10000,
    });
    this.epowerGauge.render(this.environment);
    var spowerGauge = gauge('#s-power-gauge', {
      size: 130,
      clipWidth: 160,
      clipHeight: 90,
      ringWidth: 20,
      maxValue: 100,
      transitionMs: 10000,
    });
    spowerGauge.render(this.social);

    var gpowerGauge = gauge('#g-power-gauge', {
      size: 130,
      clipWidth: 160,
      clipHeight: 90,
      ringWidth: 20,
      maxValue: 100,
      transitionMs: 10000,
    });
    gpowerGauge.render(this.governance);


  }

  populateTable(data){
    this.dataReport = [];
    if(data.indPillar1Name!=undefined){
    this.dataReport['indPillar1Name'] = data.indPillar1Name;
    this.dataReport['indPillar1Value'] = data.indPillar1Value;
    this.dataReport['indPillar2Name'] = data.indPillar2Name;
    this.dataReport['indPillar2Value'] = data.indPillar2Value;
    this.dataReport['indPillar3Name'] = data.indPillar3Name;
    this.dataReport['indPillar3Value'] = data.indPillar3Value;
    this.dataReport['indPillar4Name'] = data.indPillar4Name;
    this.dataReport['indPillar4Value'] = data.indPillar4Value;
    this.dataReport['indPillar5Name'] = data.indPillar5Name;
    this.dataReport['indPillar5Value'] = data.indPillar5Value;
    this.dataReport['indPillar6Name'] = data.indPillar6Name;
    this.dataReport['indPillar6Value'] = data.indPillar6Value;
    this.dataReport['indPillar7Name'] = data.indPillar7Name;
    this.dataReport['indPillar7Value'] =data.indPillar7Value;
    this.dataReport['indPillar8Name'] = data.indPillar8Name;
    this.dataReport['indPillar8Value'] = data.indPillar8Value;
    this.dataReport['indPillar9Name'] = data.indPillar9Name;
    this.dataReport['indPillar9Value'] = data.indPillar9Value;
    this.dataReport['indPillar10Name'] = data.indPillar10Name;
    this.dataReport['indPillar10Value'] = data.indPillar10Value;
    this.dataReport['indPillar11Name'] = data.indPillar11Name;
    this.dataReport['indPillar11Value'] = data.indPillar11Value;
    this.dataReport['indPillar12Name'] = data.indPillar12Name;
    this.dataReport['indPillar12Value'] = data.indPillar12Value;
    this.dataReport['indPillar13Name'] = data.indPillar13Name;
    this.dataReport['indPillar13Value'] = data.indPillar13Value;
    this.dataReport['indPillar14Name'] = data.indPillar14Name;
    this.dataReport['indPillar14Value'] = data.indPillar14Value;
    this.dataReport['indPillar15Name'] = data.indPillar15Name;
    this.dataReport['indPillar15Value'] = data.indPillar15Value;
    this.dataReport['indPillar16Name'] = data.indPillar16Name;
    this.dataReport['indPillar16Value'] = data.indPillar16Value;
    this.dataReport['indPillar17Name'] = data.indPillar17Name;
    this.dataReport['indPillar17Value'] = data.indPillar17Value;
    this.dataReport['indPillar18Name'] = data.indPillar18Name;
    this.dataReport['indPillar18Value'] = data.indPillar18Value;
    this.dataReport['indPillar19Name'] = data.indPillar19Name;
    this.dataReport['indPillar19Value'] = data.indPillar19Value;
    this.dataReport['indPillar20Name'] = data.indPillar20Name;
    this.dataReport['indPillar20Value'] = data.indPillar20Value;
    this.dataReport['indPillar21Name'] = data.indPillar21Name;
    this.dataReport['indPillar21Value'] = data.indPillar21Value;
    this.dataReport['indPillar22Name'] = data.indPillar22Name;
    this.dataReport['indPillar22Value'] = data.indPillar22Value;
    this.datajson.push(this.dataReport);
    }
    this.displayedColumns = ['business', 'capital', 'corporate', 'transparency', 'emissions', 'envmanagement', 'waste','stewardship','forensic','compensation','diversity','empquality']
    this.dataSource = new MatTableDataSource(this.datajson);
  }


}
