import { Component, OnInit } from '@angular/core';
import { TableExtractService } from '../company-service/table-extract.service';
import { ParagraphExtractService } from '../company-service/paragraph-extract.service';
import { PdfToTextService } from '../company-service/pdf-to-text.service';
import * as XLSX from 'xlsx';
import { ConfigurationService } from '../../configuration.service';
type AOA = any[][];

@Component({
  selector: 'app-subsidiary-company',
  templateUrl: './subsidiary-company.component.html',
  styleUrls: ['./subsidiary-company.component.css']
})
export class SubsidiaryCompanyComponent implements OnInit {
  selectedFile : any="";
  fileExt = "";
  pdfUploaded = true;
  previewText = "preview text content";
  showPreviewText = false;
  showPreviewExcel = false;
  showPreviewPdf = false;
  selectedPages = false;
  convertedFile = "";
  selectedConversionMethod = "excel";
  tableLoading:boolean;
  conversionMethods: Array<any> = [
    { name: "Convert to Excel File", value: "excel" },
    { name: "Convert to Text File", value: "text" }
  ];
  buttonClick = 0; //debug
  buttonClickText = ""; //debug
  outputFileName = "";
  outputType = "";
  downloadable = !false;
  button2Click = 0; //debug
  button2ClickText = ""; //debug
  button3Click = 0; //debug
  button3ClickText = ""; //debug
  button4Click = 0; //debug
  button4ClickText = ""; //debug

  // for excel previewer
  fileName: string = "SheetJS.xlsx";
  data: any;
  headData: any // excel row header
  headlessData: any
  selectedCompanyId: any =1 ;
  // for pdf previews
  pdfSrc: any;
  performanceRecordVO: any;
  companyVOList: any;
  uploadedFile : any;
  pageNumber : any;
  exportdata: any;
  onPageload: boolean=true;
  
  constructor(private tableExtractService: TableExtractService,
              private paragraphExtractService: ParagraphExtractService,
              private pdfToTextService: PdfToTextService,private configurationService:ConfigurationService) { };


  ngOnInit() {
    this.onPageload=true;

    
      this.configurationService.getListOfCompanies().subscribe(data => {
        this.companyVOList = data;
         console.log("datasss")
      console.log(this.companyVOList)
      }, (error) => {
        console.log(error);
      });
    
    // this.paragraphExtractService.fetchPerformanceRecord().subscribe((data) => {
    //   this.performanceRecordVO = data;
    //   console.log("datasss")
    //   console.log(this.performanceRecordVO)
    //   this.companyVOList = this.performanceRecordVO.companyVOList;
    // });
   
  }
  newFileUpload(evt: any): void {
    console.log("filesss")
    console.log(evt)
    this.onPageload=false;
   console.log(evt.target.files.item(0))
    this.uploadedFile=evt.target.files.item(0);
    this.fileExt = this.selectedFile.split(".")[1];
    if (this.fileExt == "pdf") {
      this.pdfUploaded = true;
      this.showPreviewPdf = true;
      let $img: any = document.querySelector('#file');

      if (typeof (FileReader) !== 'undefined') {
        let reader = new FileReader();

        reader.onload = (e: any) => {
          this.pdfSrc = e.target.result;
        };

        reader.readAsArrayBuffer($img.files[0]);
      }
    } else {
      this.pdfUploaded = false;
      this.showPreviewPdf = false;
    }
  }

  upload(): void {
    this.tableLoading=true;
    //uploads the input pdf or text-file
    console.log("inside upload function")
    console.log(this.uploadedFile);
    this.onPageload=false;
    //return this.http.get(this.url+'/pdf_to_text_conversion?filename='+fileName+'&pageno='+pageNo+'&file='+file,
    const formdata: FormData = new FormData();
    formdata.append('filename', this.uploadedFile.name);
    formdata.append('parent_id', this.selectedCompanyId);
    if(!this.pageNumber){
      this.pageNumber=0;
    }
    formdata.append('pageno', this.pageNumber);
    formdata.append('file',this.uploadedFile)
    
    if (this.fileExt == "txt") {
      this.paragraphExtractService.paragraphExtract(formdata).subscribe( 
        (data) => { 
          this.readExcelData(data);
          this.showPreviewText = false;
          this.showPreviewExcel = true;
          this.showPreviewPdf = false;
          this.downloadable = !true;
          this.outputFileName = this.selectedFile.split(".")[0].split("\\").at(-1)! + ".xlsx";
          this.outputType = "xlsx";
          this.tableLoading=false;
        },
        ()=>{
        }
      )
    } else if (this.fileExt == "pdf" && this.selectedConversionMethod == "excel") {
      this.paragraphExtractService.tableExtract(formdata).subscribe( 
        (data) => { 
        //   console.log(data, "response blob");

        // const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        //  FileSaver.saveAs(blob, "test.xlsx");
          this.readExcelData(data);
          this.showPreviewText = false;
          this.showPreviewPdf = false;
          this.showPreviewExcel = true;
          this.downloadable = !true;
          this.outputFileName = this.selectedFile.split(".")[0].split("\\").at(-1)! + ".xlsx";
          this.outputType = "xlsx";
          this.tableLoading=false;
        },
        ()=>{
        }
      )
    } else if (this.fileExt == "pdf" && this.selectedConversionMethod == "text") {
      this.paragraphExtractService.pdfToText(formdata).subscribe( 
        (data) => { 
          this.previewText = data;
          this.showPreviewText = true;
          this.showPreviewExcel = false;
          this.showPreviewPdf = false;
          this.downloadable = !true;
          this.outputFileName = this.selectedFile.split(".")[0].split("\\").at(-1)! + ".txt";
          this.outputType = "text";
          this.tableLoading=false;
        },
        ()=>{
        }
      )
    }
    return;
  }

  view(): void {
    //This button generates the preview
    this.button2Click += 1;
    this.button2ClickText = "The View Button has been clicked " + this.button2Click + " times.";
    return;
  }

  convert(): void {
    //Sends the input file to the endpoint for conversion
    this.button3Click += 1;
    this.button3ClickText = "The Convert Button has been clicked " + this.button3Click + " times.";
    return;
  }

  download(): void {
    //Downloads the converted file
    this.button4Click += 1;
    this.button4ClickText = "The Download Button has been clicked " + this.button4Click + " times.";
    return;
  }

  /* CODE OBTAINED FROM https://stackblitz.com/edit/angular-preview-excel?file=src%2Fapp%2Fapp.component.html 
     IMPLEMENTED ON 7/25/2023 */

  /* <input type="file" (change)="onFileChange($event)" multiple="false" /> */
  /* ... (within the component class definition) ... */
  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, {header: 1, raw: false, range: 10}));
      console.log(this.data[1]);

      this.headData = this.data[0];
      this.data = this.data.slice(1); // remove first header record

      const ws2: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[1]];
      this.readDataSheet(ws2, 10);
    };
    reader.readAsBinaryString(target.files[0]);
  }

  private readExcelData(excel: any) {
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, {header: 1, raw: false}));
      this.exportdata = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1, raw: false });
      //console.log(this.data, "data");
      
      //this.data[0].splice(0, 1, "Company Name");
      //this.headData = this.data[0];
      // let headData = this.data[0];

      // headData.forEach((subArr: any)=> subArr.shift());

      // this.headData = headData;

    
      //removing first row of array
      // this.data.shift();
      // let modifiedData = this.data;
      // // removing first Element of modifed array
      // modifiedData.forEach((subArr: any) => subArr.shift());
      // console.log(modifiedData[1], " modified final");
      // this.headlessData = modifiedData;
      this.data[0].splice(0, 1, "S.NO");

      this.headData = this.data[0];

      console.log(this.headData, "headData");

      this.headlessData = this.data.splice(1);
      this.headlessData.forEach((element:any,i:any)=>{
        this.headlessData[i][0]= Number(element[0])+1
      })
      console.log(this.headlessData,'headless');
      console.log(this.headData, "headData");
      const ws2: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[1]];

      // this.headData = this.data[0];
      // console.log(this.headData);
      // this.headlessData = this.data.slice(1); // remove first header record

      // const ws2: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[1]];
      // this.readDataSheet(ws2, 1);
    };
    reader.readAsBinaryString(excel);
  }

  private readDataSheet(ws: XLSX.WorkSheet, startRow: number) {
    /* save data */
    let datas = <AOA>(XLSX.utils.sheet_to_json(ws, {header: 1, raw: false}));
    let headDatas = datas[0];
    datas = datas.slice(1); // remove first header record

    for (let i = 0; i < this.data.length; i++) {
      this.data[i][this.headData.length] = datas.filter(x => x[12] == this.data[i][0])
    }
  }

  export(): void {
    if (this.outputType == "xlsx") {
      /* generate worksheet */
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.exportdata);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */
      XLSX.writeFile(wb, this.outputFileName);
    } else if (this.outputType == "text") {
      let outputData = new Blob([this.previewText], { type: 'text/plain' });

      let url = window.URL.createObjectURL(outputData);

      let a = document.createElement('a');
      document.body.appendChild(a);

      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = this.outputFileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
	}



onCompanyChange(companyId:any){
  this.selectedCompanyId=companyId;
console.log("companyid ")
console.log(this.selectedCompanyId)
console.log(companyId)
  }

}
