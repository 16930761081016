import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ImpactAssessmentService } from '../../../impact-assessment.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { ReportConstantsComponent } from '../../../../report-constants/report-constants.component'
@Component({
  selector: 'app-capital-view-drill-down',
  templateUrl: './capital-view-drill-down.component.html',
  styleUrls: ['./capital-view-drill-down.component.css']
})
export class CapitalViewDrillDownComponent implements OnInit {

  capitalVO: any;
  capitalImg: any;
  sectorVO: any;
  capitalName: string;
  jsonArrayCapitalList = [];
  jsonArraySectorList = [];
  jsonArraySectorIndicatorList = [];
  displayedColumnsSectorList = [];
  displayedColumnsSectorIndicators = [];
  displayedColumnsCapitalIndicator = [];
  dataSourceCapitalIndicator: any = [];
  dataSourceSectorIndicators: any = [];
  dataSourceSectorIndicatorsPopup: any = [];
  dataSourceCapitalIndicatorPopUp: any = [];
  dataSourceSector: any = [];
  dataSourceSectorPopUp: any = [];
  displayColSector = [];
  sectorId: number;
  sectorData = [];
  doughnutData: any;
  chartOptions: any;
  chartColors: any;
  doughnutChartType: string;
  flag: boolean;
  selectedTab: any;
  noCapitalDataFlag: boolean;
  noSectorDataFlag: boolean;
  capitalId: any;
  selectedSubCategoryCode: any;
  loading: boolean;
  public currency: string;
  public locale: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  constructor(private _impactAssessmentService: ImpactAssessmentService, private _router: Router, private activatedRoute: ActivatedRoute) {
    this.displayedColumnsSectorList = ['sector'];
    this.doughnutChartType = 'doughnut';
    this.selectedTab = 1;
    this.noCapitalDataFlag = false;
    this.noSectorDataFlag = false;
    this.loading = false;
    this.capitalId = this.activatedRoute.snapshot.queryParamMap.get('capitalId');
    this.selectedSubCategoryCode = this.activatedRoute.snapshot.queryParamMap.get('subCategoryCode');

  }

  ngOnInit() {
    this.loading = true;
    this.currency = ReportConstantsComponent.CURRENCY;
    this.locale = ReportConstantsComponent.LOCALE;
    this._impactAssessmentService.getCapitalViewDrillDownDetails(this.capitalId).subscribe((data) => {
      if (data) {
        this.capitalVO = data.capitalViewVO;
        this.sectorVO = data.sectorVOList;
        this.selectedTab = this.selectedSubCategoryCode;
        if (this.capitalVO) {
          this.dataPopulateCapitalIndicator(0);
          this.capitalName = this.capitalVO.subCategoryName;
          this.capitalImg = this.capitalVO.capitalImgFileName;
        }
        else {
          this.noCapitalDataFlag = true;
        }
        if (this.sectorVO) {
          this.dataTablePopulationSectorList();
        }
        else {
          this.noSectorDataFlag = true;
        }
      }
      this.loading = false;
    })
  }

  dataPopulateCapitalIndicator(flag) {

    let indicatorListCapital = this.capitalVO.indicatorVOList;
    this.jsonArrayCapitalList = [];
    this.dataSourceCapitalIndicatorPopUp = [];
    let index = 1;
    if (flag == 0) {
      if (Object.keys(indicatorListCapital).length > 0) {
        indicatorListCapital = indicatorListCapital.slice(0, 3);
      }
    }
    for (let capitalInd of indicatorListCapital) {
      let newArray = {};
      newArray['id'] = capitalInd.id;
      newArray['Indicator'] = capitalInd.indicatorName;
      newArray['Actual'] = capitalInd.actual;
      newArray['UnitOfmeasurement'] = '-';
      if (capitalInd.unitOfMeasurement != null && capitalInd.unitOfMeasurement.length > 0) {
        newArray['UnitOfmeasurement'] = capitalInd.unitOfMeasurement;
      }
      newArray['Sno'] = index;
      newArray['Critical'] = capitalInd.critical;
      // newArray['Year']=capitalInd.year;
      index++;
      this.jsonArrayCapitalList.push(newArray);
    }
    if (flag == 0) {
      this.dataSourceCapitalIndicator = new MatTableDataSource(this.jsonArrayCapitalList);
    }
    else {
      this.dataSourceCapitalIndicatorPopUp = new MatTableDataSource(this.jsonArrayCapitalList);
      this.dataSourceCapitalIndicatorPopUp.paginator = this.paginator;
    }

    this.displayedColumnsCapitalIndicator = ['SDGCritical', 'SDGIndicator', 'SDGUnitOfmeasurement', 'SDGActual']
  }

  dataPopulateSectorIndicator(indicatorListSector, targetFlag, sectorId, popupFlag) {
    this.jsonArraySectorIndicatorList = [];
    this.sectorId = sectorId;
    let indArr = [];
    let index = 1;
    if (indicatorListSector) {
      if (popupFlag == 0)
        indArr = indicatorListSector.slice(0, 4);
      else
        indArr = indicatorListSector;
    }

    for (let indicator of indArr) {
      var newArray = {};
      newArray['Sno'] = index;
      newArray['Indicator'] = indicator.indicatorName;
      newArray['Actual'] = indicator.actual;
      newArray['UnitOfmeasurement'] = '-';
      if (indicator.unitOfMeasurement != null && indicator.unitOfMeasurement.length > 0) {
        newArray['UnitOfmeasurement'] = indicator.unitOfMeasurement;
      }
      newArray['Critical'] = indicator.critical;
      newArray['Year'] = indicator.year;
      if (targetFlag) {
        if (indicator.achievement == null || indicator.achievement == null)
          newArray['Achievement'] = 0;
        else
          newArray['Achievement'] = String(indicator.achievement);
        newArray['AchievementColorCode'] = indicator.achievementColorStatus;
        if (indicator.targetFlag === true)
          newArray['Target'] = indicator.target;
        else
          newArray['Target'] = "-";
      }

      this.jsonArraySectorIndicatorList.push(newArray);
      index++;
    }

    if (popupFlag == 0) {
      let dataSourceList = new MatTableDataSource(this.jsonArraySectorIndicatorList);
      return dataSourceList;
    }
    else {
      this.dataSourceSectorPopUp = new MatTableDataSource(this.jsonArraySectorIndicatorList);
      this.displayColSector = this.displayedColumnsSector(targetFlag);
    }
  }

  getSectorIndicatorForPopUp(sectorId, targetFlagCheck) {
    this.dataPopulateSectorIndicator(this.sectorData[sectorId], targetFlagCheck, sectorId, 1);
  }

  sectorAvailable: boolean = false;
  dataTablePopulationSectorList() {

    let sectorDetails = this.sectorVO;
    this.jsonArraySectorList = [];
    let index = 1;

    for (let sectors of sectorDetails) {

      
      let sectorIndicatorDetails = [];
      sectorIndicatorDetails = sectors.indicatorVOReportList;
      this.sectorData[sectors.sectorId] = sectorIndicatorDetails;
      this.dataSourceSectorIndicators[sectors.sectorId] = this.dataPopulateSectorIndicator(sectorIndicatorDetails, sectors.targetFlag, sectors.sectorId, 0);
      this.displayedColumnsSectorIndicators[sectors.sectorId] = this.displayedColumnsSector(sectors.targetFlag);
      if (Object.keys(sectorIndicatorDetails) != null && Object.keys(sectorIndicatorDetails).length) {
        this.sectorAvailable = true;
        var newArray = {};
        newArray['sno'] = index;
        newArray['sectorIndSize'] = Object.keys(sectorIndicatorDetails).length;
        newArray['sectorId'] = sectors.sectorId;
        newArray['sectorName'] = sectors.sectorName;
        newArray['countryCount'] = sectors.noOfCountries;
        newArray['companyCount'] = sectors.noOfActiveCompanies;
        newArray['companyCount'] = sectors.noOfActiveCompanies;
        newArray['investment'] = sectors.totalInvestment;
        newArray['targetFlagSector'] = sectors.targetFlag;
        this.jsonArraySectorList.push(newArray);
        index++;
      }
    }
    this.dataSourceSector = new MatTableDataSource(this.jsonArraySectorList);
    
  }

  displayedColumnsSector(targetCheck) {

    let dispColSector = [];
    if (targetCheck) {
      return dispColSector = ['Critical', 'Indicator', 'UnitOfmeasurement', 'Target', 'Actual', 'Achievement', 'Year']
    }
    else {
      return dispColSector = ['Critical', 'Indicator', 'UnitOfmeasurement', 'Actual', 'Year']
    }
  }

  doughnutChartData(percentage: number) {
    if (percentage > 100)
      percentage = 100;
    this.doughnutData = [percentage, (100 - percentage)];
    return this.doughnutData;
  }

  OptionsAchievement() {
    this.chartOptions = {
      responsive: true,
      cutoutPercentage: 40,
    };
    return this.chartOptions;
  }

  chartColor(colorStatus: number) {

    /* 3=> green , 2=> amber , 1 => red */
    if (colorStatus == 3)
      this.chartColors = [{ backgroundColor: ["#34a853", "#dddddd"] }];
    else if (colorStatus == 2)
      this.chartColors = [{ backgroundColor: ["#f39700", "#dddddd"] }];
    else
      this.chartColors = [{ backgroundColor: ["#ea4335", "#dddddd"] }];

    return this.chartColors;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSourceSector.filter = filterValue;
    if (this.dataSourceSector.filteredData.length == 0) {
      this.flag = true;
    } else {
      this.flag = false;
    }
  }

  getParameterDrillDownDetails(levelNo: number, parameterId: number) {
    let paramData = {};
    paramData['levelNo'] = levelNo;
    paramData['parameterId'] = parameterId;
    paramData['capitalId'] = this.capitalId;
    paramData['sdgId'] = 0;
    paramData['impactFlag'] = 1;
    paramData['impactName'] = this.capitalName;
    paramData['subCategoryId'] = 2;   // takes impact indicators
    this._router.navigate(['/impactAssessment/dashboard/parameter-wise-drilldown'], { queryParams: paramData, skipLocationChange: true });
  }

  isNumber(val) { return isNaN(+val); }

}