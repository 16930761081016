import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup,FormArray, Validators, NgForm, AbstractControl, AbstractControlOptions, ValidationErrors, FormGroupDirective } from '@angular/forms';
import swal from 'sweetalert2';
import { CustomValidationsService } from '../../../shared/Validations/custom-validations.service';
import { FundService } from '../fund.service';
import { AddEditSuccessComponent } from '../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { GenerateSchedulesService } from '../../../modules/performance-framework/generate-schedules.service';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-add-fund',
  templateUrl: './add-fund.component.html',
  styleUrls: ['./add-fund.component.css']
})
export class AddFundComponent implements OnInit {

  addFundForm: FormGroup;
  companyList: any = [];
  fundDetailsList: any = [];
  invalidSelection: any = [];
  invalidSelectionYear: any = [];
  yearList: any = [];
  esgCheckFlag:boolean=false;
  @ViewChild('fundForm') fundForm;

  constructor(private formBuilder: FormBuilder, private customValidationsService: CustomValidationsService, private fundService: FundService, private addEditSuccessComponent: AddEditSuccessComponent, private generateSchedulesService: GenerateSchedulesService) {
    this.addFundForm = this.createFormGroup();
  }

  ngOnInit() {
    this.getFundList();
    this.getCompanyList();
    this.formArray.push(this.getCompanyListVO());
    this.annualReturnFormArray.push(this.getAnnualReturnListVO());
    this.yearList = this.generateSchedulesService.getYearList();

  }

  createFormGroup() {
    return this.formBuilder.group({
      id: [0],
      name: ['', [Validators.required, Validators.maxLength(250), this.customValidationsService.noWhitespaceValidator]],
      isinCode: ['', [Validators.required, Validators.maxLength(12), this.customValidationsService.noWhitespaceValidator],this.uniqueCodeValidator()],
      investmentAmount: ['', [Validators.required, Validators.pattern(/^[0-9]\d{0,9}(\.\d{1,2})?%?$/)]],
      currency: ['', [Validators.required, Validators.pattern('^[a-zA-Z]+$')]],
      companyVOList: this.formBuilder.array([]),
      annualReturnDetailsVOList: this.formBuilder.array([]),
      esgFlag:['']
    }, { updateOn: 'blur' });
  }
  getCompanyListVO() {
    return this.formBuilder.group({
      id: ['', [Validators.required]],
      investmentPercentage: ['', [Validators.required, Validators.pattern(/^[0-9]\d{0,2}(\.\d{1,2})?%?$/)]]
    });
  }
  getAnnualReturnListVO() {
    return this.formBuilder.group({
      year: ['', [Validators.required]],
      annualReturn: ['', [Validators.required, Validators.pattern(/^-?[0-9]\d{0,3}(\.\d{1,2})?%?$/)]]
    });
  }

  getFundList() {
    this.fundService.getFundList().subscribe((data) => {
      this.fundDetailsList = data;
    }, (error) => {
      console.log(error);
    });
  }

  getCompanyList() {
    let parameterRequestVO = {};
    parameterRequestVO['companyList'] = true;
    this.fundService.fetchCompanyList(parameterRequestVO).subscribe(data => {
      let parameterData: any = data;
      this.companyList = parameterData.companyVOList;
    }, (error) => {
      console.log(error);
    })
  }

  addNewRow() {
    const controls = this.formArray;
    this.formArray.push(this.getCompanyListVO());
  }
  deleteRow(index: number) {
    this.formArray.removeAt(index);
  }
  get formArray() {
    return this.addFundForm.get('companyVOList') as FormArray;
  }

  get annualReturnFormArray() {
    return this.addFundForm.get('annualReturnDetailsVOList') as FormArray;

  }
  addNewAnnualReturnRow() {
    this.annualReturnFormArray.push(this.getAnnualReturnListVO());
  }
  deleteAnnualReturnRow(index: number) {
    this.annualReturnFormArray.removeAt(index);
  }

  onCompanyListChange(id, index) {
    this.invalidSelection[index] = false;
    let companyVOList = this.addFundForm.controls.companyVOList.value;
    let filteredVal = companyVOList.filter(company => company.id == id);
    if (filteredVal.length > 1) {
      this.invalidSelection[index] = true;
      const selectedValFromGrp = this.formArray.controls[index] as FormGroup;
      selectedValFromGrp.controls['id'].setValue('');
      selectedValFromGrp.controls['id'].updateValueAndValidity();
    }
  }

  onYearListChange(year, index) {
    this.invalidSelectionYear[index] = false;
    let annualReturnDetailsVOList = this.addFundForm['controls'].annualReturnDetailsVOList.value;
    let filteredVal = annualReturnDetailsVOList.filter(returnObj => returnObj.year == year);
    if (filteredVal.length > 1) {
      this.invalidSelectionYear[index] = true;
      const selectedValFromGrp = this.annualReturnFormArray.controls[index] as FormGroup;
      selectedValFromGrp.controls['year'].setValue('');
      selectedValFromGrp.controls['year'].updateValueAndValidity();
    }
  }

  onSubmit() {
    let message;
    if (this.addFundForm.valid) {
      let fundVO = {};
      fundVO = this.addFundForm.value;
      fundVO['companyVOList'] = [];
      let totalInvestmentPercentage = 0;
      let companyVOList = this.addFundForm.controls.companyVOList.value;
      for (let companyVO of companyVOList) {
        let companyVal = this.companyList.find(x => x.id == companyVO.id);
        totalInvestmentPercentage += parseFloat(companyVO.investmentPercentage);
        companyVO['companyName'] = companyVal.companyName;
        fundVO['companyVOList'].push(companyVO);
      }
      if (Math.round(totalInvestmentPercentage) == 100) {
        this.fundService.createOrUpdateFundDetails(fundVO).subscribe(data => {
          if ((this.addFundForm.get('id').value) && (this.addFundForm.get('id').value != 0))
            message = "Successfully Edited Fund";
          else
            message = "Successfully Created Fund";
          this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
            this.resetFundForm();
            this.getFundList();
          });
        }, (error) => {
        });
      }
      else {
        this.addEditSuccessComponent.showFailedSwal('Total Investment Percentage should be 100', 'Error').then((value) => {

        })
      }
    }
  }
  resetFundForm() {
    this.fundForm.resetForm();
    this.addFundForm.removeControl('companyVOList');
    this.addFundForm.addControl('companyVOList', this.formBuilder.array([]));
    this.addNewRow();
    this.addFundForm.removeControl('annualReturnDetailsVOList');
    this.addFundForm.addControl('annualReturnDetailsVOList', this.formBuilder.array([]));
    this.addNewAnnualReturnRow();

  }
  editFund(fundObj) {
    this.addFundForm.patchValue({
      id: fundObj.id,
      name: fundObj.name,
      investmentAmount: fundObj.investmentAmount,
      currency: fundObj.currency,
      isinCode: fundObj.isinCode,
      esgFlag:fundObj.esgFlag
    });
    this.addFundForm.removeControl('companyVOList');
    this.addFundForm.addControl('companyVOList', this.formBuilder.array([]));
    for (let company of fundObj.companyVOList) {
      this.addNewRow();
    }
    this.formArray.patchValue(fundObj.companyVOList);
    this.addFundForm.removeControl('annualReturnDetailsVOList');
    this.addFundForm.addControl('annualReturnDetailsVOList', this.formBuilder.array([]));
    fundObj.annualReturnDetailsVOList.sort((a, b) => parseInt(a.year) - parseInt(b.year));

    for (let annualReturn of fundObj.annualReturnDetailsVOList) {
      this.addNewAnnualReturnRow();
    }
    this.annualReturnFormArray.patchValue(fundObj.annualReturnDetailsVOList);
  }

  uniqueCodeValidator()
  {
      return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      var id = 0
      if (this.addFundForm && this.addFundForm.controls.id.value) {
        id = this.addFundForm.controls.id.value;
      }
      
      return this.fundService.validateIsinCode(id, c.value).pipe(
        map(val => {
          if (val == 1) {
            return { 'uniqueCode': true };
          }
        })
      );
    };

  }

  uniqueNameValidator()
  {
    return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      var id = 0
      if (this.addFundForm && this.addFundForm.controls.id.value) {
        id = this.addFundForm.controls.id.value;
      }      
      return this.fundService.validateFundName(id, c.value).pipe(
        map(val => {
          console.log(val)
          if (val == 1) {
            return { 'uniqueName': true };
          }
        })
      );
    };


  }


}
