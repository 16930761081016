import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanydetailsService } from '../../comapnydetails.service';
import { TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { ReportConstantsComponent } from '../../../report-constants/report-constants.component'
import { CompanyService } from '../../../CompanyDashboard/company.service';


@Component({
  selector: 'app-companylevel',
  templateUrl: './companylevel.component.html',
  styleUrls: ['./companylevel.component.css']
})
export class CompanylevelComponent implements OnInit {

  public companyDetails: any;
  public companyParameterIndicatorDetails: any;
  public level_no: any;
  public company_selected_parameter_id: any;
  public listTransIndicators: any;
  public listTransIndicatorsSize = [];
  public doughnutChartType;
  public doughnutData: any;
  public chartColors: any;
  public chartOptions: any;
  public modalRef: BsModalRef;
  public indArr: any;
  public dataSourceParam: any;
  public dataSourcePopUp: any;
  public displayedColumns: any;
  public displayedColumnsCompanyIndicators = [];
  public colIndex: number;
  public jsonArray: any;
  public levelName: any;
  public targetFlagTrans = [];
  public targetFlagLevelIndicator: any;
  public targetFlagCompanyIndicatorPopUp: any;
  public dataSourceCompanyIndicators = [];
  public dataSourceTrans: any;
  public companyIndicatorList: any;
  public company_id: any;
  public flag: boolean;
  public jsonArrayCompanyList: any;
  public dataSourceCompanyList: any;
  public displayedColumnsCompanyList: any;
  public displayedColumnsParam: any;
  public dataSourcePopUpParam: any;
  public currency: string;
  public locale: string;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  dataSourcePopUpAsset : any;
  displayedColumnsAsset : any;
  public jsonArrayAsset: any;
  public level_name:any;
  public assetLevelFinanceEmission = 0;
  public showIcons=false;
  public parameterLevelName;
  public datasourceMO : any;
  public displayedColumnDS :  any;
  public datasourceCRE : any;
  public displayedColumnsCRE :  any;
  public datasourceSD : any;
  public displayedColumnsSD :  any;
  public commonDatas;
  public dataSourceCommon: any;
  noOfValue: string;
  jsonArrayForCountry: any[];
  displayedColumnsForCountry: any[];
  originalColumnNamesForCountry: any[];
  public indicatorMatDataTable = "indicatorMatDataTable";
  constructor(private companyDataService:CompanyService,private _companyService: CompanydetailsService, private _router: Router, private activatedRoute: ActivatedRoute, private modalService: BsModalService) {
    this.activatedRoute.params.subscribe(params => { this.level_no = params.level_no, this.company_selected_parameter_id = params.company_selected_parameter_id });
    this.doughnutChartType = 'doughnut';
    this.targetFlagLevelIndicator = false;
    this.targetFlagCompanyIndicatorPopUp = false;
    this.levelName = null;
    this.listTransIndicators = 0;
    this.colIndex = 0;
    this.displayedColumnsCompanyList = ['companyDetails'];
    
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  ngOnInit() {
    this.currency = ReportConstantsComponent.CURRENCY;
    this.locale = ReportConstantsComponent.LOCALE;

    this._companyService.getCompanyLevelDetails(this.level_no, this.company_selected_parameter_id).subscribe((data) => {
console.log("ffff")
console.log(data)
console.log(data.parameterLevelVOList[0].levelName)
   if(data.parameterLevelVOList[0].levelName=="Mortgages")
    {
      this.parameterLevelName="MO";
      this.dataTablePopulationDS(1);
      this.indicatorMatDataTable="indicatorMatDataTableForAssets";
    }
    else if(data.parameterLevelVOList[0].levelName=="Commercial Real Estate"){
      this.parameterLevelName="CRE";
      this.dataTablePopulationDS(2);
      this.indicatorMatDataTable="indicatorMatDataTableForAssets";
    }
    else if(data.parameterLevelVOList[0].levelName=="Sovereign Debt"){
      this.parameterLevelName="SD";
      this.dataTablePopulationDS(3);
      this.indicatorMatDataTable="indicatorMatDataTableForAssets";
    }
    else{
      console.log("Not asset class");
    }
      this.companyDetails = data.companyVOList;
      this.dataTablePopulationCompanyList(this.companyDetails);
      this.companyParameterIndicatorDetails = data.parameterLevelVOList;
      this.dataTablePopulationLevelIndicator(this.companyParameterIndicatorDetails, 1);
      this.levelName=this.companyParameterIndicatorDetails[0].parametersVO[0].levelName;
    
    })
   
  }

  doughnutChartData(percentage: number) {
    if (percentage > 100)
      percentage = 100;
    this.doughnutData = [percentage, (100 - percentage)];
    return this.doughnutData;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSourceCompanyList.filter = filterValue;

    if (this.dataSourceCompanyList.filteredData.length == 0) {
      this.flag = true;
    } else {
      this.flag = false;
    }
  }

  OptionsESG() {
    this.chartOptions = {
      responsive: true,
      cutoutPercentage: 70,
    };
    return this.chartOptions;
  }

  OptionsAchievement() {
    this.chartOptions = {
      responsive: true,
      cutoutPercentage: 40,
    };
    return this.chartOptions;
  }

  doughnutChartESGData(esg: number) {
    if (esg > 1)
      esg = 1;
    this.doughnutData = [esg, (1 - esg)];
    return this.doughnutData;
  }

  chartColor(colorStatus: number) {

    /* 3=> green , 2=> amber , 1 => red */
    if (colorStatus == 3)
      this.chartColors = [{ backgroundColor: ["#34a853", "#dddddd"] }];
    else if (colorStatus == 2)
      this.chartColors = [{ backgroundColor: ["#f39700", "#dddddd"] }];
    else
      this.chartColors = [{ backgroundColor: ["#ea4335", "#dddddd"] }];

    return this.chartColors;
  }

  goToClick(id: any) {
    // this.companyDataService.getCompanyRatingAgencyDetails(id).subscribe((result)=>{
    // let companyRegionNames;
    // let companySectorNames;
    // let companyCountryNames;
    // let companySubSectorNames;
    // for (let companyParameters of result.parameterVO) {
    //   var str = companyParameters.parameterList; 
    //   str = str.replace(/"/g, '');
    //   str = str.replace(/{/g, '');
    //   str = str.replace(/}/g, '');
    //   if(companyParameters.parameterName == "Region")
    //     companyRegionNames = str;
    //   if(companyParameters.parameterName == "Sector")
    //     companySectorNames = str;
    //   if(companyParameters.parameterName == "Country")
    //     companyCountryNames = str;
    //     if(companyParameters.parameterName == "Sub Sector")
    //     companySubSectorNames = str;
    // }
    // this._router.navigate(['/performanceManagement/dashboard/companyDetails'], {
    //   queryParams: {
    //     "name" :result.companyName,
    //     "id" :result.id,
    //     "image" :result.companyImgFileName,
    //     "sector" :companySectorNames,
    //     "subSector" :companySubSectorNames,
    //     "region" :companyRegionNames,
    //     "subRegion" :companyCountryNames,
    //     "environment" :result.environment,
    //     "social" :result.social,
    //     "governance" :result.governance,
    //     "overallEsgQuotient" :result.overallEsgQuotient,
    //     "esgColorStatus":result.esgColorStatus,
    //     "revenue" : result.revenue},
    //   skipLocationChange: true
    // });
    // });
    this._router.navigate(['/performanceManagement/dashboard/integratedCompany/'+id]);
  }

  dataTablePopulationCompanyList(companyDetails) {
    this.jsonArrayCompanyList = [];
    for (let company of companyDetails) {
      this.company_id = company.id;
      let companyIndicatorDetails = company.indicatorVO;
      this.dataSourceCompanyIndicators[company.id] = this.dataTablePopulationCompanyIndicators(companyIndicatorDetails, 1, company.targetFlag);
      this.displayedColumnsCompanyIndicators[company.id] = this.displayedColumnsCompany(company.targetFlag,0);
      this.targetFlagTrans[company.id] = company.targetFlag;
      if (companyIndicatorDetails) {
        this.listTransIndicatorsSize[company.id] = Object.keys(companyIndicatorDetails).length;
      }
      else {
        this.listTransIndicatorsSize[company.id] = 0;
      }

      let countryName = '-';
      let subSectorName = '-';
      var newArray = {};
      newArray['sno'] = company.sno;
      newArray['companyId'] = company.id;
      newArray['companyName'] = company.companyName;
      for (let companyParameters of company.parameterVO) {
        var str = companyParameters.parameterList;
        str = str.replace(/"/g, '');
        str = str.replace(/{/g, '');
        str = str.replace(/}/g, '');
        if (companyParameters.parameterName == "Country") {
          countryName = str;
        }
        if (companyParameters.parameterName == "Sub Sector") {
          subSectorName = str;
        }
      }
      newArray['companyCountryNames'] = countryName;
      newArray['companySubSectorNames'] = subSectorName;
      newArray['companyName'] = company.companyName;
      newArray['esg'] = company.esg;
      newArray['esgColorStatus'] = company.esgColorStatus;
      newArray['assetDetailsOfCompany']=company.assetDetailsOfCompanyVO;
      newArray['marketValueSum']=((company.marketValueSum)/1000000).toFixed(2);
      newArray['financialEmissionSum']=company.financialEmissionSum + ' tCo2e';
      this.assetLevelFinanceEmission=this.assetLevelFinanceEmission+parseInt(company.financialEmissionSum);
      this.jsonArrayCompanyList.push(newArray);
    }
    this.dataSourceCompanyList = new MatTableDataSource(this.jsonArrayCompanyList);
    // this.dataSourceCompanyList.paginator = this.paginator.toArray()[0];
  }

  dataTablePopulationCompanyIndicators(companyIndicatorDetails, flag, targetFlagCompany) {

    this.dataSourceTrans = [];
    this.dataSourcePopUp = [];
    this.displayedColumns = [];
    this.jsonArray = [];
    this.indArr = [];

    if (companyIndicatorDetails) {
      if (flag)
        this.indArr = companyIndicatorDetails.slice(0, 5);
      else
        this.indArr = companyIndicatorDetails;
    }

    for (let indicator of this.indArr) {
      var newArray = {};
      newArray['Sno'] = indicator.sno;
      newArray['Id'] = indicator.id;
      newArray['Indicator'] = indicator.indicatorName;
      if (targetFlagCompany) {
        if (indicator.targetFlag === true)
          newArray['Target'] = indicator.target;
        else
          newArray['Target'] = "-";
        newArray['AchievementColorCode'] = indicator.achievementColorStatus;
        newArray['Achievement'] = String(indicator.achievement);
      }
      newArray['Actual'] = indicator.actual;
      newArray['UnitOfmeasurement'] = '-';
      if (indicator.unitOfMeasurement != null && indicator.unitOfMeasurement.length > 0) {
        newArray['UnitOfmeasurement'] = indicator.unitOfMeasurement;
      }
      newArray['Critical'] = indicator.critical;
      newArray['Year'] = indicator.year;

      this.jsonArray.push(newArray);
    }

    if (flag) {
      this.dataSourceTrans = new MatTableDataSource(this.jsonArray);
      return this.dataSourceTrans;
    }
    else {
      this.dataSourcePopUp = new MatTableDataSource(this.jsonArray);
      this.targetFlagCompanyIndicatorPopUp = targetFlagCompany;
      this.displayedColumns = this.displayedColumnsCompany(targetFlagCompany,0);
    }
  }

  dataTablePopulationLevelIndicator(companyParameterIndicatorDetails, flag) {

    this.dataSourcePopUpParam = [];
    this.displayedColumnsParam = [];
    this.jsonArray = [];
    this.indArr = [];
    for (let parameterDetails of companyParameterIndicatorDetails) {
      this.levelName = parameterDetails.levelName;
      this.targetFlagLevelIndicator = parameterDetails.targetFlag;

      if (parameterDetails.indicatorVO.length > 0) {
        if (flag)
          this.indArr = parameterDetails.indicatorVO.slice(0, 5);
        else
          this.indArr = parameterDetails.indicatorVO;
      }


      for (let indicator of this.indArr) {

        var newArray = {};
        newArray['Sno'] = indicator.sno;
        newArray['Id'] = indicator.id;
        newArray['Indicator'] = indicator.indicatorName;
        if (this.targetFlagLevelIndicator) {
          if (indicator.targetFlag === true)
            newArray['Target'] = indicator.target;
          else
            newArray['Target'] = "-";
          newArray['Achievement'] = String(indicator.achievement);
          newArray['AchievementColorCode'] = indicator.achievementColorStatus;
        }
        newArray['Actual'] = indicator.actual;
        newArray['UnitOfmeasurement'] = '-';
        if (indicator.unitOfMeasurement != null && indicator.unitOfMeasurement.length > 0) {
          newArray['UnitOfmeasurement'] = indicator.unitOfMeasurement;
        }
        newArray['Critical'] = indicator.critical;
        newArray['Year'] = indicator.year;

        this.jsonArray.push(newArray);
      }
    }
    if (flag) {
      this.dataSourceParam = new MatTableDataSource(this.jsonArray);
    }
    else {
      this.dataSourcePopUpParam = new MatTableDataSource(this.jsonArray);
      this.dataSourcePopUpParam.paginator = this.paginator.toArray()[0];
    }
    this.displayedColumnsParam = this.displayedColumnsCompany(this.targetFlagLevelIndicator,1);
  }

  displayedColumnsCompany(targetCheck,flag) {

    let displayedColumnsTransanction = [];
    if(flag){
      if (targetCheck) {
        return displayedColumnsTransanction = ['Critical', 'Indicator', 'UnitOfmeasurement', 'Target', 'Actual', 'Achievement']
      }
      else {
        return displayedColumnsTransanction = ['Critical', 'Indicator', 'UnitOfmeasurement', 'Actual']
      }
    }
    else{
      if (targetCheck) {
        return displayedColumnsTransanction = ['Critical', 'Indicator', 'UnitOfmeasurement', 'Target', 'Actual', 'Achievement', 'Year']
      }
      else {
        return displayedColumnsTransanction = ['Critical', 'Indicator', 'UnitOfmeasurement', 'Actual', 'Year']
      }
    }
  
  }

  getCompanyIndicatorForPopUp(transDetailsId, targetFlagCheck) {
    this._companyService.getCompanyIndicatorDetails(this.level_no, transDetailsId, this.company_selected_parameter_id, 1).subscribe((companyIndicatorList) => {
      this.dataTablePopulationCompanyIndicators(companyIndicatorList, 0, targetFlagCheck);
    })
  }

  getLevelIndicatorForPopUp() {
    this.dataTablePopulationLevelIndicator(this.companyParameterIndicatorDetails, 0);
  }

  isNumber(val) { return isNaN(+val); }

  getAssetForCompanyPopUp(assetDetailsOfCompany: any){
    console.log("assetDetailsOfCompany");
    console.log(assetDetailsOfCompany);
    this.dataSourcePopUpAsset = [];
    this.displayedColumnsAsset =[];
    this.jsonArrayAsset=[];
    //console.log("compnayid"+companyId);
    this.displayedColumnsAsset = ['metlife_assetId',  'core_sector_level1Code', 'core_sector_level2Code','description','domicile_country_code','risk_country_code','market_value_USD','financed_emission','data_quality','year','equator_principal','msff']
    let sno = 1;
    for (let asset of assetDetailsOfCompany) {
      var newArray = {};
      newArray['Sno'] = sno;
      newArray['metlife_assetId'] = asset.metlife_assetid;
      newArray['cusip'] = asset.cusip;
      newArray['isin'] = asset.isin;
      newArray['sedol'] = asset.sedol;
      newArray['asset_desc'] = asset.asset_desc;
      newArray['core_sector_level1Code'] = asset.core_sector_level1code;
      newArray['core_sector_level2Code'] = asset.core_sector_level2code;
      newArray['description'] = asset.description;
      newArray['domicile_country_code'] = asset.domicile_country_code;
      newArray['risk_country_code'] = asset.risk_country_code;
      newArray['market_value_USD'] = asset.market_value_usd;
      newArray['financed_emission']=asset.financed_emission;
      newArray['data_quality']=asset.data_quality;
      newArray['year']=asset.year;
      newArray['equator_principal']=asset.equator_principal;
      if(asset.metlife_assetid=="247361ZN1")
      newArray['msff']='Yes';
      else{
        newArray['msff']='No';
      }
      
 
      this.jsonArrayAsset.push(newArray);
      console.log("json->")
      console.log(this.jsonArrayAsset)
      sno++;
    }
    console.log("json->======")
    console.log(this.jsonArrayAsset)
    this.dataSourcePopUpAsset = new MatTableDataSource(this.jsonArrayAsset);
  }
  getIUX(){
    this._companyService.getiuxFinance().subscribe((datas)=>{
      console.log("IUSX___>");
      console.log(datas)
     })
  }
  gotoIUXData()
  {
    let url="http://44.216.216.215:8089/superset/dashboard/6/?native_filters_key=MQwstiO_j-zUDhgYEUHY7e2Ncs9VwgHgEBtQ3_zP4pD6-0cUbF4chUwz0Ixeco34"
  window.open(url,"_blank");
  }
  openMSFFPopup(){
    this.showIcons=!this.showIcons;
  }
  dataTablePopulationDS(flag:any) {
    this.datasourceMO = [];
    if(flag==1){
      this.displayedColumnDS = ['region','country','noOfAssets','marketValue','financialEmission','year'];
      this.commonDatas=[{region:'NA',country:'US',noOfAssets:'20',marketValue:'6,671,002',financialEmission:'127',year:'2020'}];
      this.noOfValue="Number of Mortgages"
    }
    if(flag==2 || flag==3){
      this.noOfValue="Number of Assets"
      this.displayedColumnDS = ['region','country','noOfAssets','marketValue','financialEmission','year'];
      if(flag=2){
        this.commonDatas=[{region:'UK',country:'Ireland',noOfAssets:'1',marketValue:'84,863,116',financialEmission:'44',year:'2022'},
                          {region:'US',country:'Jersey',noOfAssets:'2',marketValue:'112,190,118',financialEmission:'70',year:'2022'},
                          {region:'EU',country:'Nertherlands',noOfAssets:'5',marketValue:'251,346,208',financialEmission:'310',year:'2022'},
                          {region:'UK',country:'United Kingdom',noOfAssets:'12',marketValue:'952,202,530',financialEmission:'419',year:'2022'}];
      }
      else if(flag==3){
        this.commonDatas=[
        {region:'MiddleEast',country:'AE',noOfAssets:'36',marketValue:'962,109,439',financialEmission:'337,095',year:'2022'},
        {region:'NA',country:'CA',noOfAssets:'25',marketValue:'75,620,003',financialEmission:'28,413',year:'2022'},
        {region:'Africa',country:'CI',noOfAssets:'4',marketValue:'4,409,194',financialEmission:'NA',year:'2022'},
        {region:'APAC',country:'HK',noOfAssets:'8',marketValue:'5,719,704',financialEmission:'1,178',year:'2022'},
        {region:'NA',country:'PA',noOfAssets:'4',marketValue:'73,441,264',financialEmission:'13,354',year:'2022'},
        {region:'EU',country:'SE',noOfAssets:'1',marketValue:'6,764,553',financialEmission:'77',year:'2022'}];
      }
      else{
      }
    }
    
    this.jsonArray = [];
    for (let values of this.commonDatas) {
      var newArray = {};
      newArray['region'] = values.region;
      newArray['country'] = values.country;
      newArray['noOfAssets'] = values.noOfAssets;
      newArray['marketValue'] = values.marketValue;
      newArray['financialEmission'] = values.financialEmission;
      newArray['year'] = values.year;
      this.jsonArray.push(newArray);
    }
      this.dataSourceCommon = new MatTableDataSource(this.jsonArray);
     
    }
    getCountryDetails()
    {
      console.log(this.parameterLevelName)
       this.jsonArrayForCountry=[]
        this.displayedColumnsForCountry = []
        this.originalColumnNamesForCountry=[]
      if(this.parameterLevelName=='MO'){
        this.jsonArrayForCountry=[
        {'Sno':1,'MetlifeAssetId':'LN_0000016126','MarketValue':'624,925','FinancedEmission':11,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':2,'MetlifeAssetId':'LN_0000016145','MarketValue':'673,344','FinancedEmission':11,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':3,'MetlifeAssetId':'LN_0000016147','MarketValue':'738,306','FinancedEmission':13,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':4,'MetlifeAssetId':'LN_0000016158','MarketValue':'213,458','FinancedEmission':8,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':5,'MetlifeAssetId':'LN_0000016163','MarketValue':'394,916','FinancedEmission':6,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':6,'MetlifeAssetId':'LN_0000016170','MarketValue':'294,672','FinancedEmission':10,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':7,'MetlifeAssetId':'LN_0000016173','MarketValue':'216,251','FinancedEmission':3,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':8,'MetlifeAssetId':'LN_0000016175','MarketValue':'317,454','FinancedEmission':11,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':9,'MetlifeAssetId':'LN_0000016176','MarketValue':'277,302','FinancedEmission':5,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':10,'MetlifeAssetId':'LN_0000016181','MarketValue':'372,862','FinancedEmission':8,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':11,'MetlifeAssetId':'LN_0000016183','MarketValue':'537,014','FinancedEmission':6,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':12,'MetlifeAssetId':'LN_0000016348','MarketValue':'279,795','FinancedEmission':13,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':13,'MetlifeAssetId':'LN_0000016357','MarketValue':'587,305','FinancedEmission':7,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':14,'MetlifeAssetId':'LN_0000016366','MarketValue':'615,187','FinancedEmission':6,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':15,'MetlifeAssetId':'LN_0000016391','MarketValue':'316,586','FinancedEmission':9,'DQ':5,'EPC':'NA','year':2022},]
        this.displayedColumnsForCountry = ['Sno','MetlifeAssetId','MarketValue','FinancedEmission','DQ','EPC','year']
        this.originalColumnNamesForCountry= ['Sl.No',"Asset ID","Exposure (USD)","Financed Emission (tCo2e)","DQ Score","EPC","Year"]
      }
      else if(this.parameterLevelName=='CRE')
      {
        this.jsonArrayForCountry=[
        {'Sno':1,'MetlifeAssetId':'LN_0000510163','MarketValue':'33,577,082','FinancedEmission':35,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':2,'MetlifeAssetId':'LN_0000510170','MarketValue':'72,538,218','FinancedEmission':35,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':3,'MetlifeAssetId':'LN_0000510177','MarketValue':'67,220,854','FinancedEmission':35,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':4,'MetlifeAssetId':'LN_0000510180','MarketValue':'71,946,453','FinancedEmission':35,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':5,'MetlifeAssetId':'LN_0000510183','MarketValue':'195,843,608','FinancedEmission':35,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':6,'MetlifeAssetId':'LN_0000510185','MarketValue':'48,091,704','FinancedEmission':35,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':7,'MetlifeAssetId':'LN_0000510186','MarketValue':'197,368,863','FinancedEmission':35,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':8,'MetlifeAssetId':'LN_0000510187','MarketValue':'73,359,643','FinancedEmission':35,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':9,'MetlifeAssetId':'LN_0000510188','MarketValue':'17,936,274','FinancedEmission':35,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':10,'MetlifeAssetId':'LN_0000510189','MarketValue':'9,273,142','FinancedEmission':35,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':11,'MetlifeAssetId':'LN_0000510190','MarketValue':'101,310,674','FinancedEmission':35,'DQ':5,'EPC':'NA','year':2022},
        {'Sno':12,'MetlifeAssetId':'LN_0000510192','MarketValue':'63,736,015','FinancedEmission':35,'DQ':5,'EPC':'NA','year':2022}]
        this.displayedColumnsForCountry = ['Sno','MetlifeAssetId','MarketValue','FinancedEmission','DQ','epc','year']
        this.originalColumnNamesForCountry= ['Sl.No',"Asset ID","Exposure (USD)","Financed Emission (tCo2e)","DQ Score","EPC","Year"]
      }
      else if(this.parameterLevelName=='SD')
      {
        this.jsonArrayForCountry=[
        {'Sno':1,'MetlifeAssetId':'00946AAJ3','MarketValue':'942,629','FinancedEmission':'NA','year':2022,'DQ':4},
        {'Sno':2,'MetlifeAssetId':'00946AAJ3','MarketValue':'942,629','FinancedEmission':297,'year':2022,'DQ':4},
        {'Sno':3,'MetlifeAssetId':'00946AAJ3','MarketValue':'942,629','FinancedEmission':'NA','year':2022,'DQ':4},
        {'Sno':4,'MetlifeAssetId':'00946AAJ3','MarketValue':'942,629','FinancedEmission':297,'year':2022,'DQ':4},
        {'Sno':5,'MetlifeAssetId':'00946AAH7','MarketValue':'487,297','FinancedEmission':'NA','year':2022,'DQ':4},
        {'Sno':6,'MetlifeAssetId':'00946AAH7','MarketValue':'487,297','FinancedEmission':292,'year':2022,'DQ':4},
        {'Sno':7,'MetlifeAssetId':'00946AAH7','MarketValue':'487,297','FinancedEmission':'NA','year':2022,'DQ':4},
        {'Sno':8,'MetlifeAssetId':'00946AAH7','MarketValue':'487,297','FinancedEmission':292,'year':2022,'DQ':4}]
        this.displayedColumnsForCountry = ['Sno','MetlifeAssetId','MarketValue','FinancedEmission','year','DQ']
        this.originalColumnNamesForCountry= ['Sl.No',"Asset ID","Exposure (USD)","Financed Emission (tCo2e)",'Year',"DQ"]
      }
    }
  //     getAssetForCompanyPopUp(assetDetailsOfCompany: any){
  //   console.log("assetDetailsOfCompany");
  //   console.log(assetDetailsOfCompany);
  //   this.dataSourcePopUpAsset = [];
  //   this.displayedColumnsAsset =[];
  //   this.jsonArrayAsset=[];
  //   //console.log("compnayid"+companyId);
  //   this.displayedColumnsAsset = ['metlife_assetId',  'core_sector_level1Code', 'core_sector_level2Code','description','domicile_country_code','risk_country_code','market_value_USD','financed_emission','data_quality','year','equator_principal','msff']
  //   let sno = 1;
  //   for (let asset of assetDetailsOfCompany) {
  //     var newArray = {};
  //     newArray['Sno'] = sno;
  //     newArray['metlife_assetId'] = asset.metlife_assetid;
  //     newArray['cusip'] = asset.cusip;
  //     newArray['isin'] = asset.isin;
  //     newArray['sedol'] = asset.sedol;
  //     newArray['asset_desc'] = asset.asset_desc;
  //     newArray['core_sector_level1Code'] = asset.core_sector_level1code;
  //     newArray['core_sector_level2Code'] = asset.core_sector_level2code;
  //     newArray['description'] = asset.description;
  //     newArray['domicile_country_code'] = asset.domicile_country_code;
  //     newArray['risk_country_code'] = asset.risk_country_code;
  //     newArray['market_value_USD'] = asset.market_value_usd;
  //     newArray['financed_emission']=asset.financed_emission;
  //     newArray['data_quality']=asset.data_quality;
  //     newArray['year']=asset.year;
  //     newArray['equator_principal']=asset.equator_principal;
  //     if(asset.metlife_assetid=="247361ZN1")
  //     newArray['msff']='Yes';
  //     else{
  //       newArray['msff']='No';
  //     }
      
 
  //     this.jsonArrayAsset.push(newArray);
  //     console.log("json->")
  //     console.log(this.jsonArrayAsset)
  //     sno++;
  //   }
  //   console.log("json->======")
  //   console.log(this.jsonArrayAsset)
  //   this.dataSourcePopUpAsset = new MatTableDataSource(this.jsonArrayAsset);
  // }
    
  }

  

