import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, AbstractControl, AbstractControlOptions, ValidationErrors, FormGroupDirective } from '@angular/forms';

import { IndicatorMasterService } from '../../../indicator-master/indicator-master.service';
import { CustomValidationsService } from '../../../../shared/Validations/custom-validations.service';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-add-unitgroup',
  templateUrl: './add-unit-group.component.html',
  styleUrls: ['./add-unit-group.component.css']
})
export class AddUnitGroupComponent implements OnInit {
  addUnitGroupForm: FormGroup;
  defaultValuesArray:any;
  unitgroupList :any=[];
  topWrapper:any;
  @ViewChild('unitgroupForm') unitgroupForm;
  @ViewChild ("topWrap") topWrap;
  constructor(private IndicatorMasterService: IndicatorMasterService, private formBuilder: FormBuilder,private customValidationsService:CustomValidationsService, private addEditSuccessComponent: AddEditSuccessComponent) {

    this.addUnitGroupForm = this.createFormGroup();
    
   }

  ngOnInit() {
    this.topWrapper=this.topWrap;
    this.getUnitGroupsList();
  }

  getUnitGroupsList()
  {
    this.IndicatorMasterService.getunitGroupList().subscribe(data => {      
      this.unitgroupList = data;
    },(error)=>{
    });
  }

  createFormGroup() {
    return this.formBuilder.group({
      code: ['', [Validators.required,Validators.maxLength(50),this.customValidationsService.noWhitespaceValidator], this.uniqueCodeValidator()],
      name: ['', [Validators.required,Validators.maxLength(1000),this.customValidationsService.noWhitespaceValidator]],
      id:[],
    }, { updateOn: 'blur' });
 
  }
  uniqueCodeValidator()
  {
      return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      var id = 0
      if (this.addUnitGroupForm && this.addUnitGroupForm.controls.id.value) {
        id = this.addUnitGroupForm.controls.id.value;
      }
      
      return this.IndicatorMasterService.checkUnitGrpCodeValidation(id, c.value).pipe(
        map(val => {

          console.log(val)
          if (val == 1) {
            return { 'uniqueCode': true };
          }
        })
      );
    };

  }

  uniqueNameValidator()
  {
    return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      var id = 0
      if (this.addUnitGroupForm && this.addUnitGroupForm.controls.id.value) {
        id = this.addUnitGroupForm.controls.id.value;
      }
      
      return this.IndicatorMasterService.checkUnitGrpNameValidation(id, c.value).pipe(
        map(val => {

          console.log(val)
          if (val == 1) {
            return { 'uniqueName': true };
          }
        })
      );
    };


  }

  defaultValues(val)
  {
    this.defaultValuesArray = val;
    this.addUnitGroupForm.patchValue(val);
   
  }


  onSubmit(){  
    if(this.addUnitGroupForm.valid)
    {
      this.IndicatorMasterService.createUnitGroup(this.addUnitGroupForm.value).subscribe((myform)=>{
          
        if(this.addUnitGroupForm.get('id').value)
          var message="Successfully Edited Unit Group";
        else
          var message = "Successfully Added Unit Group";
          this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
            this.resetUnitGroupForm();
            this.getUnitGroupsList();
          });
            
      },(error)=>{
      });
    }
     
  }

  resetUnitGroupForm() {
    this.topWrap.nativeElement.classList.remove("top-form-wrapper-highlight");
    this.unitgroupForm.resetForm();
     
  }

}
