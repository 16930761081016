import { Component, OnInit } from '@angular/core';
import { GpsDashboardService } from "../../gps-dashboard.service";
import { map } from 'rxjs/operators/map';
@Component({
  selector: 'app-gps-dashbord',
  templateUrl: './gps-dashbord.component.html',
  styleUrls: ['./gps-dashbord.component.css']
})
export class GpsDashbordComponent implements OnInit {

  mapDataList : any = [];

  public colors: Array<any> = [];
  colorList:any;
  colorCodeList:Array<String> = [];
  constructor(private gpsDashboardService : GpsDashboardService) { }

  ngOnInit() {
    this.gpsDashboardService.fetchMapDetails().subscribe(data=>{
      this.mapDataList = data;
      let colorMap = new Map();
      for(let esg of this.mapDataList){
        let desc=esg.esg_desc.substring(0,1)
        colorMap.set(desc , desc);
  }
    colorMap.forEach((val, key) => {
      this.colors.push(
        val
      );
    });

this.colorList=this.colors.sort();

for(let color of this.colorList ){
  if(color==1){
    this.colorCodeList.push("#3e7d14");
  }
  else if(color==2){
    this.colorCodeList.push("#93e45b");
  }
  else if(color==3){
    this.colorCodeList.push("#bfbfbf");
  }
  else if(color==4){
    this.colorCodeList.push("#595959");
  }
  else if(color==5){
    this.colorCodeList.push("#000000");
  }
}
  });
  }

  defaultValues(element){

  }

}
