import { Component, OnInit, EventEmitter, Output,ViewChild ,Input} from '@angular/core';
import { IndicatorMasterService } from '../../../indicator-master/indicator-master.service';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import {AddUnitGroupComponent} from '../add-unit-group/add-unit-group.component'
@Component({
  selector: 'app-list-unit-group',
  templateUrl: './list-unit-group.component.html',
  styleUrls: ['./list-unit-group.component.css']
})
export class ListUnitGroupComponent implements OnInit {

  unitGroups: any;

  displayedColumns : any;
  originalColumnNames : any;
  colIndex:number=0;
  jsonArray:any;
  top:any;
  unitgroupList:any=[];

  @Input() set topWrapper(val){
    this.top=val;
  }


  @Input() set unitgroupDataList(val) {
    this.unitgroupList = val;
    this.fetchDataTable(this.unitgroupList);
   }

  @Output() unitGroup = new EventEmitter();

  constructor(private IndicatorMasterService:IndicatorMasterService,private addEditSuccessComponent: AddEditSuccessComponent,private addUnitGroupComponent:AddUnitGroupComponent) { }

  ngOnInit() { 
    this.getUnitGroupsList();
   }

  getUnitGroupsList()
  {
    this.IndicatorMasterService.getunitGroupList().subscribe(data => {      
      this.unitGroups = data;  
   this.fetchDataTable(this.unitGroups);
  },(error)=>{
 
  });
  }

  fetchDataTable(unitGroups)
  {
    this.jsonArray = [];
    this.originalColumnNames = [];
    this.displayedColumns =[];
    var count =0;
      for( let i of unitGroups){

        var newArray = {};
        newArray['Sno'] = ++count;
        newArray['id'] = i.id;
        newArray['Code'] = i.code;
        newArray['Name'] = i.name;
        this.jsonArray.push(newArray);
        
       }
  
      this.displayedColumns=['Sno','Code','Name','Actions']
      this.originalColumnNames =['Sl.No','Code','Name','Actions']
  }

  edit(unitGroupObj){
      this.unitGroup.emit({ id:unitGroupObj.id, code: unitGroupObj.Code, name: unitGroupObj.Name });
  }
  
//   delete(unitGroupObj) {
//     var confirmMessage = "You Want To Delete Unit Group";
//     this.addEditSuccessComponent.showConfirmationSwal(confirmMessage,'Yes','No').then((isConfirm) => {
//       if (isConfirm.value) {
//         this.IndicatorMasterService.deleteUnitGroup(unitGroupObj.id).subscribe((data) => {
//           var deleteSuccess = 'Deleted Unit Group';
//           this.addEditSuccessComponent.showSuccessSwal(deleteSuccess)
//             .then((value) => {
//               this.addUnitGroupComponent.getUnitGroupsList;
//               this.addUnitGroupComponent.resetUnitGroupForm;
              
//               });
//         },(error)=>{   
//         });
//       }
//     });
  
// }
delete(unitGroupObj) {
  var confirmMessage = "You Want To Delete Unit Group";
  this.addEditSuccessComponent.showConfirmationSwal(confirmMessage,'Yes','No').then((isConfirm) => {
    if (isConfirm.value) {
      this.IndicatorMasterService.deleteUnitGroup(unitGroupObj.id).subscribe((data) => {
          var deleteSuccess = 'Deleted Unit Group';
          this.addEditSuccessComponent.showSuccessSwal(deleteSuccess)
            .then((value) => {
            this.addUnitGroupComponent.getUnitGroupsList();
            this.addUnitGroupComponent.resetUnitGroupForm();
            });
      },(error)=>{
      
      });
    }
  });
}

}
