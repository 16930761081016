import { Component, OnInit } from '@angular/core';
import {AssessmentTemplateMasterdataService} from '../../../assessment-template-masterdata.service';
import { ActivatedRoute,Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddEditSuccessComponent } from '../../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import swal from 'sweetalert2';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-assessment-consolidated-view',
  templateUrl: './assessment-consolidated-view.component.html',
  styleUrls: ['./assessment-consolidated-view.component.css']
})
export class AssessmentConsolidatedViewComponent implements OnInit {

  initiatedBy: any;
  initiatedOn: any;
  panel: any;
  template: any;
  transactionId:number;
  assessmentId:number;
  companyId:number;
  assessmentDetails :  any;
  jsonArray:any;
  assessmentFormGroupArray: any;
  dataPointVOListFormArray: FormArray;
  dataPointVOList: any;
  questionList: any = [];
  isError:any=[];
  factorVOList:any=[]
  currentFactorId:any;
  panelOpenState: any = [];
  selectedFactor:any;
  finalScore:any;
  displayedColumnsForHistory:any=[]
  originalColumnNamesForHistory:any=[]
  jsonArrayForHistory:any=[];
  companyAssessmentPanelMemberDetailsId:any;
  questionVOList:any;
  user:any;
  assessmentStatus:any
  companyName:any;
  companyCode:any;
  constructor(private assessmentRecordService :  AssessmentTemplateMasterdataService,
    private addEditSuccessComponent: AddEditSuccessComponent,
    private activatedRoute: ActivatedRoute,private router:Router,private formBuilder: FormBuilder ) {
     
   }

  ngOnInit() {
    this.questionVOList=[]
    this.activatedRoute.queryParams.subscribe(params => {
      this.template=params.template;
      this.panel=params.panel;
      this.initiatedOn=params.initiatedOn;
      this.initiatedBy=params.initiatedBy;
      this.assessmentId = params.assessmentId;
      this.transactionId = params.transactionId;
      this.companyId=params.companyId;
      this.companyName=params.companyName;
      this.companyCode=params.companyCode;
    }) 
     console.log(this.assessmentId)
    // this.activatedRoute.params.subscribe(params => { this.assessmentId = params.assessmentId });
    this.assessmentRecordService.viewAssessmentDetails(this.assessmentId).subscribe(data => {
      console.log("assessment details")
      console.log(data)
      this.factorVOList = data.factorVOList
      this.selectedFactor = this.factorVOList[0];
      this.currentFactorId = this.factorVOList[0].id;
      this.finalScore=data.aggregatedUserScore;
      this.user=data.userVOList
      this.assessmentStatus=data.assessmentStatus
      this.companyAssessmentPanelMemberDetailsId=data.companyAssessmentPanelMemberDetailsId
      for(let factor of data.factorVOList)
      {
        for(let subFactor of factor.subFactorVOList)
        {
       
           console.log(this.populateDataToQuestionForm(subFactor.questionVOList,data))
           subFactor.questionVOList= this.populateDataToQuestionForm(subFactor.questionVOList,data);

        }
    
      }
      this.assessmentDetails = data;
     
  })
  }
  loadfactors(factorId) {
    this.currentFactorId = factorId;
    this.factorVOList = {};
    this.factorVOList = this.assessmentDetails.factorVOList.filter(
      factorVO => factorVO.id == factorId
    );
    for (let i = 0; i < this.factorVOList[0].subFactorVOList.length; i++) {
      this.panelOpenState[this.factorVOList[0].subFactorVOList[i].subFactorCode] = false
    }
    this.selectedFactor = this.factorVOList[0];
  }
  downloadAttachmentFile(fileUploadName) {
   console.log(fileUploadName)
   console.log(this.companyCode)
    let filename=this.companyCode+"/"+this.assessmentId+"/"+fileUploadName
    
    window.location.href =this.assessmentRecordService.getBaseUrl() +"/evidenceFiledownload?fileUploadName="+encodeURIComponent(filename) 
  
  
  }
  populateDataToQuestionForm(questionVOList, data)
    {
     let questionList=[]
      for(let question of questionVOList)
      {
        // console.log(question.responseOptionsVOList)
        // console.log(question.responseOptionsVOList.length>0)
        if(question.responseOptionsVOList.length>0)
        {
          questionList.push(question)
          this.questionVOList.push(question)
          let questionId=question.id
          this.questionList[questionId] = this.createQuestionListForm(questionId);

          //Modifying the code -> to show as verified on load; Kept verificationRemarks field as verified and actions field  verified selected by default

          this.questionList[questionId].patchValue({
            verificationRemarks:question.responseOptionsVOList[0].verificationStatus ==="D"? "verified":question.responseOptionsVOList[0].verificationRemarks ,
            actions:question.responseOptionsVOList[0].verificationStatus ==="D"?"V":"D"
          })
        }
      
      }
      console.log("questionList")
      console.log(questionList)
      return questionList;
    }

   
    createQuestionListForm(questionId) {
      this.isError[questionId]=false
      return this.formBuilder.group({
        id: [questionId],
        verificationRemarks:['',Validators.required],
        actions:['']
      });
    }

  
  download(fileName){ 
 window.location.href= this.assessmentRecordService.getBaseUrl() + '/downloadAssessment/' + fileName;
 
 
}
verficationStatusAction(event,question)
{
//  console.log("questionnn")
// //  console.log(question);
//  console.log(event==="A");

 //Modified the code -> when action additional info clicked and asks the input from the user  in verifcationremarks
 this.questionList[question.id].patchValue({
  verificationRemarks: event==="A"?'':'verified'
})
  this.isError[question.id]=false
}
verifyReassessmentFunction(flag)
{
  console.log(this.questionList)
  var isError=false
  var isVerified=true;
  for(let question of this.questionVOList)
  {
    console.log(this.questionList[question.id].controls.actions.value=="")

    console.log(this.questionList[question.id].controls.actions.value)
    if(this.questionList[question.id].controls.verificationRemarks.value=="" ||this.questionList[question.id].controls.verificationRemarks.value==null )
    {
      isError=true;
      this.questionList[question.id].controls.verificationRemarks.markAsTouched();
      this.questionList[question.id].controls.verificationRemarks.markAsDirty();      
    }
    if(this.questionList[question.id].controls.actions.value=="" || this.questionList[question.id].controls.actions.value=='D')
    {
      isError=true;
      this.isError[question.id]=true;     
    }
    if(flag=='V'&& this.questionList[question.id].controls.actions.value=='A')
    {
      isVerified=false;
    }
    question.responseOptionsVOList[0].verificationRemarks=this.questionList[question.id].controls.verificationRemarks.value
    question.responseOptionsVOList[0].verificationStatus=this.questionList[question.id].controls.actions.value
  }
  if(flag=='R')
  {
    var message="Sent Back for Reassessment"
  }
  else{
    var message="Verified Successfully"
  }
  var AssessmentResponseVO:any={}
  AssessmentResponseVO["assessmentId"]=this.assessmentId
  AssessmentResponseVO["completedStatus"]=flag
  AssessmentResponseVO["userId"]=this.user[0].id
  AssessmentResponseVO["questionList"]=this.questionVOList
  console.log(AssessmentResponseVO)
  if(!isError && isVerified){
  this.assessmentRecordService.verifyReassessmentStatus(AssessmentResponseVO).subscribe((data) =>
  {
    swal({
      title: 'Success!',
      text: message,
      type: 'success',
      confirmButtonText: 'Ok'
    }).then(value =>
      {
        this.router.navigate(["/listAssessmentCompanies"])
      })
  })

}
if(!isVerified)
{
  var message = "Please Verify all the Questions";
  var title="Not Verified";
              swal({
                title: title,
                text: message,
                type: 'warning',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK',
                
              })
}
}

showHistory(question)
{
   
    this.displayedColumnsForHistory = [];
    this.originalColumnNamesForHistory = [];
    this.jsonArrayForHistory=[]
    this.assessmentRecordService.getDetailsForHistoryPopUp(this.companyAssessmentPanelMemberDetailsId,question.id).subscribe((data:any) => {
      var count=0;
    console.log("history details")
      console.log(data)
      for (let i of data) {
        var newArray = {};
        newArray['Sno'] = ++count;
        newArray['response'] =i.responseText;
        newArray['remarks'] = i.remarks;
        newArray['filename'] = i.fileName==null?'---':i.fileName;
        newArray['verificationRemarks'] =i.verificationRemarks==null?"---":i.verificationRemarks;
        newArray['verificationStatus'] = i.verificationStatus=='D'?'---':(i.verificationStatus=='V'?"Verified":"Additional Info");
       this.jsonArrayForHistory.push(newArray)
      }
      this.originalColumnNamesForHistory= ['S.No',"Response","Remarks","Filename","Verification Remarks","Verification Status"];
      this.displayedColumnsForHistory= ['Sno',"response","remarks","filename","verificationRemarks","verificationStatus"];
    
    },
      (error) => { 
        console.log(error);
       }); 
}
}
