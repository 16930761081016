import { Component, OnInit, ViewChild } from '@angular/core';
import { Router , ActivatedRoute } from '@angular/router';
import { IndicatordetailsService } from '../../indicatordetails.service';
import {MatTableDataSource} from '@angular/material';
import {MatPaginator} from '@angular/material';
import {MatSort} from '@angular/material';
import {ReportConstantsComponent} from '../../../report-constants/report-constants.component'

@Component({
  selector: 'app-indicatorschedule',
  templateUrl: './indicatorschedule.component.html',
  styleUrls: ['./indicatorschedule.component.css']
})
export class IndicatorscheduleComponent implements OnInit {

  public show:boolean;
  public indicatorSchedule:any;
  public tab:any;
  public doughnutChartType = 'doughnut';
  public doughnutData:any;
  public chartColors: any;
  public chartOptions: any;
  public targetFlag:any = false;
  public dataSource:any;
  public displayedColumns : any;
  public colIndex:number=0;
  public jsonArray:any;
  public locale:string;
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private _indicatorDetailsService:IndicatordetailsService , private _router:Router , private activatedRoute: ActivatedRoute) {
      this.activatedRoute.params.subscribe(params => {this.tab = params.id }); 
      this.show = true;
   }

  ngOnInit() {
    this.locale =ReportConstantsComponent.LOCALE;
    this._indicatorDetailsService.getIndicatorScheduledetails(this.tab).subscribe((indicatorSchedule)=>{
        this.indicatorSchedule = indicatorSchedule;
        this.dataTablePopulation(indicatorSchedule);
      },(error)=>{
        console.log(error);
    })
  }

  doughnutChartData(percentage:number){
	if(percentage > 100)
		percentage = 100;
    this.doughnutData = [percentage,(100-percentage)];
    return this.doughnutData;
  }

  chartColor(colorStatus: number) {

    /* 3=> green , 2=> amber , 1 => red */
    if (colorStatus == 3)
      this.chartColors = [{ backgroundColor: ["#34a853", "#dddddd"] }];
    else if (colorStatus == 2)
      this.chartColors = [{ backgroundColor: ["#f39700", "#dddddd"] }];
    else
      this.chartColors = [{ backgroundColor: ["#ea4335", "#dddddd"] }];  

    return this.chartColors;
  }

  OptionsAchievement(){
    this.chartOptions = {
      responsive: true,
      cutoutPercentage: 40,
    };
    return this.chartOptions;
  }

  dataTablePopulation(indicatorSchedule){

    this.displayedColumns=[];
    this.jsonArray =[];
    this.targetFlag = true;
    for( let indicator of indicatorSchedule){
        var newArray = {};
        if(indicator.schedule == null){
          newArray['Indicator'] = indicator.indicatorName;
        }
        else{
          newArray['Indicator'] = '';
        }
        if(indicator.schedule != null){
          newArray['Schedule'] = indicator.schedule;
        }
        else{
          newArray['Schedule'] = '';
        }
        if(indicator.targetFlag == true){
          newArray['Target'] = indicator.target;
          newArray['Achievement']=indicator.achievement;
          newArray['AchievementColorCode'] = indicator.achievementColorStatus;
        }
        else{
          newArray['Target'] = '';
          newArray['Achievement']=0;
          newArray['AchievementColorCode'] = 1;
        }
        newArray['Actual'] = indicator.actual;
        
        this.jsonArray.push(newArray);
      }
      if(this.targetFlag){
        this.displayedColumns=['Indicator','Schedule','Target','Actual','Achievement'];
      }
      else{
        this.displayedColumns=['Indicator','Schedule','Actual'];
      }
      this.dataSource = new MatTableDataSource(this.jsonArray);
  }

}
