import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
 sidePanelOpen:boolean=false;
 navLinkState:any={firstHeader:false,secondHeader:false,thirdHeader:false,fourthHeader:false}

  constructor() { }

  ngOnInit() {
  }
  openNavPanel(){
this.sidePanelOpen=!this.sidePanelOpen;
console.log(this.sidePanelOpen);

  }
}

// <div class="sideNavPanel" *ngIf="sidePanelOpen">
//   <div class="main-menu-container">
//     <div class="main-menu-heading">Administration</div>
//     <div class="divider"></div>
//     <!-- <div class="sub-title">
//             <span class="menu-arrow-right"></span>Configurations</div> -->
//     <ul>
//       <li>
//         <a href="companyTypes">Industry Groups</a>
//       </li>
//       <li>
//         <a href="parameterConfiguration">Parameters</a>
//       </li>
//       <li>
//         <a href="performanceRecordConfiguration">Record Configuration</a>
//       </li>
//       <li>
//         <a href="homogeneityParity">Homogenity/Parity</a>
//       </li>

//       <li>
//         <a href="dataLevelScores">Data Levels</a>
//       </li>

//     </ul>
//       <div class="main-menu-heading">Data Management</div>
//     <div class="divider"></div>
    
//     <ul>
//       <li>
//         <a href="https://3.214.138.242:7080/cip/CIP-Portal/data-management/secured/viewDataSource">Data source</a>
//       </li>
   
//     </ul>
//   </div>
// </div>
