import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { PerformanceTemplateService } from '../performance-template.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AddEditSuccessComponent } from '../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';

@Component({
  selector: 'app-list-performance-template',
  templateUrl: './list-performance-template.component.html',
  styleUrls: ['./list-performance-template.component.css']
})
export class ListPerformanceTemplateComponent implements OnInit {


  selectedTemplateIds: any = [];
  archiveSelectionCount: number;
  displayedColumnsPopup: any[];
  dataSourcePopUp: any=[];
  templateIndicatorDetailsArray: any;
  createdDate: any;
  status: any;
  CompanyTypeName: any;
  templateName: any;
  templateDetails: any;
  jsonArray: any[];
  displayedColumns: any[];
  dataSource: any;
  modalRef: BsModalRef;
  canCreate: boolean = false;
  paginator:any;
  paginatorPopup:any;
  loading: boolean;
  @ViewChild('matSort') sort: MatSort;
  @ViewChild('MatPaginator') set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  setDataSourceAttributes() {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
  }

  @ViewChild('matSort1') sort1: MatSort;
  @ViewChild('MatPaginator1') set matPaginator1(mp: MatPaginator) {
    this.paginatorPopup = mp;
    this.setDataSourceAttributes1();
  }
  setDataSourceAttributes1() {
      this.dataSourcePopUp.paginator = this.paginatorPopup;
      this.dataSourcePopUp.sort = this.sort1;
  }
 

  @Input() set templateList(val) {
    let templateListfromSearch = [];
    templateListfromSearch = val;
    this.dataTablePopulation(templateListfromSearch);
  }

  constructor(private templateService: PerformanceTemplateService, private modalService: BsModalService, private router: Router, private addEditSuccessComponent: AddEditSuccessComponent) { 
    this.loading = false;
    this.getListOfTemplates();
  }

  ngOnInit() {
    // this.getListOfTemplates();
  }

  getListOfTemplates() {
    let allTemplates = [];
    this.loading = true;
    this.templateService.getListOfTemplates().subscribe(data => {
      allTemplates = data;
      this.dataTablePopulation(allTemplates);
      this.templateService.createNewTemplateCheck().subscribe(isCreatePossible => {
        this.canCreate = isCreatePossible;
        this.loading = false;
      }, (error) => {
        console.log(error);
      });
    }, (error) => {
      console.log(error);
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  dataTablePopulation(templates) {

    this.archiveSelectionCount = 0;
    this.selectedTemplateIds = [];
    this.displayedColumns = [];
    this.jsonArray = [];
    for (let i of templates) {

      var newArray = {};
      newArray['id'] = i.id;
      newArray['CheckBoxSelection'] = i.id;
      if (i.companyTypeVO) {
        newArray['CompanyType'] = i.companyTypeVO.name;
      }
      else {
        newArray['CompanyType'] = '-';
      }
      newArray['PerformanceTemplate'] = i.name;
      if (i.status === 'A') {
        newArray['Status'] = "Active";
      }
      else if (i.status === 'R') {
        newArray['Status'] = "Archived";
      }
      else {
        newArray['Status'] = "Draft";
      }
      newArray['currentTemplateStatus'] = i.status;
      newArray['CreatedOn'] = i.createdDate;
      if (i.status === 'A')
        newArray['disableFlag'] = true;
      else
        newArray['disableFlag'] = false;

      if (i.editableFlag === 'Y')
        newArray['nonEditable'] = false;
      else
        newArray['nonEditable'] = true;
      this.jsonArray.push(newArray);

    }
    this.displayedColumns = ['CheckBoxSelection', 'CompanyType', 'PerformanceTemplate', 'Status', 'CreatedOn', 'Actions']
    this.dataSource = [];
    this.dataSource = new MatTableDataSource(this.jsonArray);
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  
  }

  editTemplate(templateDetailsObj) {
    this.router.navigate(['/loadtemplateCreation/'], { queryParams: { "templateId": templateDetailsObj.id, "currentVersionStatus": templateDetailsObj.currentTemplateStatus }, skipLocationChange: true });
  }

  getTemplateIndicatorDetails(id, createdDate, CompanyTypeName, status) {
    this.templateService.getTemplateIndicatorDetails(id).subscribe(data => {
      this.templateDetails = data;
      this.dataTableDetailsForPopup(this.templateDetails, createdDate, CompanyTypeName, status)

    }, (error) => {
      console.log(error);

    });
  }
  dataTableDetailsForPopup(templateDetails, createdDate, CompanyTypeName, status) {

    this.templateName = templateDetails.name;
    if(CompanyTypeName)
    this.CompanyTypeName = CompanyTypeName;
    else
    this.CompanyTypeName = templateDetails.companyTypeVO.name;
    this.status = status;
    this.createdDate = createdDate;
    this.templateIndicatorDetailsArray = templateDetails.indicatorVOList;

    //data table for indicators
    this.displayedColumnsPopup = [];
    let jsonArrayPopup = [];
    for (let indicatorVO of this.templateIndicatorDetailsArray) {
      var newArray = {};
      newArray['indicatorCode'] = indicatorVO.code;
      newArray['indicatorName'] = indicatorVO.name;

      newArray['indicatorType'] = indicatorVO.indicatorType.value;
      if (indicatorVO.indicatorDetailsVO && indicatorVO.indicatorDetailsVO.ratingGroupVO && indicatorVO.indicatorDetailsVO.ratingGroupVO.name != null) {
        newArray['RatingScaleOrUnit'] = indicatorVO.indicatorDetailsVO.ratingGroupVO.name;
      }
      else if (indicatorVO.indicatorDetailsVO && indicatorVO.indicatorDetailsVO.unitOfMeasurementVO && indicatorVO.indicatorDetailsVO.unitOfMeasurementVO.name != null) {
        newArray['RatingScaleOrUnit'] = indicatorVO.indicatorDetailsVO.unitOfMeasurementVO.name;
      }
      else{
        newArray['RatingScaleOrUnit'] = '-';
      }
      if (indicatorVO.indicatorConfigurationVO != null && indicatorVO.indicatorConfigurationVO.frequency != null)
        newArray['frequencyOfMeasurement'] = indicatorVO.indicatorConfigurationVO.frequency.value;
      else
        newArray['frequencyOfMeasurement'] = '-';
      if (indicatorVO.indicatorConfigurationVO != null && indicatorVO.indicatorConfigurationVO.source != null) {
        newArray['source'] = indicatorVO.indicatorConfigurationVO.source.value;
      }
      else {
        newArray['source'] = '-';
      }
      newArray['weightage']=indicatorVO.indicatorConfigurationVO.weightage;
      jsonArrayPopup.push(newArray);
    }
    this.displayedColumnsPopup = ['indicatorCode', 'indicatorName', 'indicatorType', 'RatingScaleOrUnit', 'frequencyOfMeasurement', 'source','weightage']
    this.dataSourcePopUp = [];
    this.dataSourcePopUp = new MatTableDataSource(jsonArrayPopup);

  }

  applyFilterPopup(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSourcePopUp.filter = filterValue;
  }

  getSelectedTemplate(event, templateId) {

    if (event.checked){
      ++this.archiveSelectionCount;
      this.selectedTemplateIds.push(templateId);
    }
    else{
      --this.archiveSelectionCount;
      let index = this.selectedTemplateIds.indexOf(templateId)
      this.selectedTemplateIds.splice(index, 1);
    }
  }

  directArchive() {
    this.templateService.archiveSelectedTemplates(this.selectedTemplateIds).subscribe(data => {
      var message = "Successfully Archived Selected Templates";
      this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
        this.getListOfTemplates();
        
      });
    }, (error) => {
      console.log(error);
    });
  }

  copyTemplate(templateDetailsObj) {
    this.router.navigate(['/loadtemplateCreation/copy/'], { queryParams: { "copyTemplateId": templateDetailsObj.id }, skipLocationChange: true });
  }
}
