import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TableExtractService {

  //private readonly url = "http://10.32.9.131:6503/table_extract?filename=saudi-aramco-ara-2022-english.pdf&pageno=217";
  private readonly url = "http://10.32.9.131:6503";

  constructor(private http: HttpClient) { };

  tableExtract (fileName: string, pageNo: string , file : any): Observable<any> {
   // return this.http.get(this.url, { responseType: 'blob' });
    return this.http.get(this.url+'/table_extract?filename='+fileName+'&pageno='+pageNo+'&parent_id='+1+'&file='+file, { responseType: 'blob' });
  };
}
