import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CategoryKeywordService } from '../category-keyword.service';
import { AddCategoryKeywordComponent } from '../add-category-keyword/add-category-keyword.component';

import swal from 'sweetalert2';
import { AddEditSuccessComponent } from '../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';

@Component({
  selector: 'app-list-category-keyword',
  templateUrl: './list-category-keyword.component.html',
  styleUrls: ['./list-category-keyword.component.css']
})
export class ListCategoryKeywordComponent implements OnInit {
  categoryKeywordList: any[];
  top: any;
  jsonArray: any[]
  displayedColumns: any;
  originalColumnNames: any;
  @Output() addCategoryKeywordListener = new EventEmitter<any>();
  @Input() set topWrapper(val) {
    this.top = val;
  }
  @Input() set categoryKeywordDataList(val) {
    this.categoryKeywordList = val;
    this.createJsonArray(this.categoryKeywordList)
  }
  get categoryKeywordDataList() {
    return this.categoryKeywordList;
  }
  constructor(private addEditSuccessComponent: AddEditSuccessComponent,private categoryKeywordService: CategoryKeywordService, private addCategoryKeywordComponent: AddCategoryKeywordComponent) { }

  ngOnInit() {
    this.createJsonArray(this.categoryKeywordList);
  }
  createJsonArray(categoryKeywordList) {
    this.displayedColumns = [];
    this.originalColumnNames = [];
    this.jsonArray = [];
    var i = 0;
    for (let categoryKeyword of categoryKeywordList) {
      var categoryJson = {}
      var keywordArray:any=[];
      categoryJson['slno'] = ++i;
      categoryJson['id'] = categoryKeyword.id;
      categoryJson['categoryName'] = categoryKeyword.categoryName;
      for(let keywords of categoryKeyword.categoryKeywordVOList)
      keywordArray.push(keywords.keyword);
      var keywordString =keywordArray.join(",");

      categoryJson['keywordList'] = keywordString;
      categoryJson['categoryKeywordVOList'] = categoryKeyword.categoryKeywordVOList;
      this.jsonArray.push(categoryJson);
    }
    this.displayedColumns = ['slno', 'categoryName', 'keywordList', 'Actions']
    this.originalColumnNames = ['Sl.No', 'Category', 'Keywords', 'Actions']
  }
  editCategoryKeyword(categoryKeyword) {
    console.log(categoryKeyword);
    var categoryJson = { id: categoryKeyword.id, categoryName: categoryKeyword.categoryName, categoryKeywordVOList: categoryKeyword.categoryKeywordVOList };
    // this.categoryKeywordService
    //   .deleteCategoryKeyword(categoryKeyword.id)
    //   .subscribe(event => {
    //     console.log('inside edit in list')
    //     this.addCategoryKeywordComponent.getCategoryKeywordList();
    //     console.log(this.addCategoryKeywordComponent.getCategoryKeywordList())
    //   });
    console.log(categoryJson);
    this.addCategoryKeywordListener.emit(categoryJson);
  }

  deleteCategory(categoryKeyword) {
    var categoryJson = { id: categoryKeyword.id, categoryName: categoryKeyword.categoryName,categoryKeywordVOList: categoryKeyword.categoryKeywordVOList  };
    swal({
      title: '<p class="swal2-title-warning">Are You Sure?</p>',
      text: "You Want To Delete Category",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.categoryKeywordService.deleteCategory(categoryKeyword.id).subscribe((data) => {
          // swal({
          //   title: 'Success!',
          //   text: 'Deleted Category',
          //   type: 'success',
          //   timer: 2000,
          //   confirmButtonText: 'Ok'
          // })
          var msg="Deleted Category";
          this.addEditSuccessComponent.showSuccessSwal(msg)
          .then((value) => {
            this.addCategoryKeywordComponent.getCategoryKeywordList();
          });
        }, (error) => {
          console.log(error);
        });
      }
    });
  }
}
