import { Component, OnInit,ViewChild } from '@angular/core';
import { FormControl,FormGroup,FormBuilder,Validators} from '@angular/forms';
import { ComplianceDashboardService} from '../../compliance-dashboard.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-company-compliance-monthly-drilldown',
  templateUrl: './company-compliance-monthly-drilldown.component.html',
  styleUrls: ['./company-compliance-monthly-drilldown.component.css']
})
export class CompanyComplianceMonthlyDrilldownComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  companyDrilldownMonthlyForm:FormGroup;
  companyId:number;
  selectedPeriod=[];
  regionName:string;
  sectorName:string;
  countryName:string;
  subSectorName:string;
  companyName:string;
  periodData:any=[];
  yearArray:any=[];
  companyData:any=[];
  quarterconstant:string;
  monthConstant:string;
  loading:boolean;
  toggleList:boolean;
  emptyFlag:boolean;
  displayedColumns:any;
  dataSource:any;
  pageNumber:number;
  noOfPages:number;
  isSubmit: boolean;
  presentYear:number;
  previousSelectedYear:any = [];
  previouslySelectedPeriod:any = [];

  constructor(private formBuilder: FormBuilder,private complianceDashboardService:ComplianceDashboardService,
              private activatedRoute: ActivatedRoute,private router:Router) { 
    
    this.loading = false;
    this.toggleList = false;
    this.isSubmit = true;
    this.quarterconstant ="QUARTERLY";
    this.monthConstant = "MONTHLY";
    this.regionName     =   this.activatedRoute.snapshot.queryParamMap.get('region');
    this.sectorName     =   this.activatedRoute.snapshot.queryParamMap.get('sector');
    this.countryName    =   this.activatedRoute.snapshot.queryParamMap.get('country');
    this.subSectorName  =   this.activatedRoute.snapshot.queryParamMap.get('subSector');
    this.companyId  =   parseInt(this.activatedRoute.snapshot.queryParamMap.get('companyId'));
    this.companyName = this.activatedRoute.snapshot.queryParamMap.get('companyName');
    this.presentYear    =   parseInt(this.activatedRoute.snapshot.queryParamMap.get('year'));
    this.selectedPeriod.push(this.presentYear);
    this.companyDrilldownMonthlyForm = this.formBuilder.group({
      period:[this.selectedPeriod,Validators.required]
    })
    
    this.previousSelectedYear.push(parseInt(this.activatedRoute.snapshot.queryParamMap.get('year')));

    let yearListArray=[];
    for(let year of this.activatedRoute.snapshot.queryParamMap.getAll('yearList')){
      yearListArray.push(parseInt(year));
    }
    this.previouslySelectedPeriod = yearListArray;    
    
  }

  ngOnInit() {
    
    this.emptyFlag = false;
    this.fetchCompanyMonthlyDrilldownData(1);
    this.noOfPages = this.selectedPeriod.length;
  }

  fetchCompanyMonthlyDrilldownData(noPage)
  {
        let complianceDashboardVO:any = {};
        complianceDashboardVO.companyId = this.companyId;
        complianceDashboardVO.frequencyView ="MONTHLY";
        complianceDashboardVO.yearIds =this.selectedPeriod;
        this.loading = true;
        this.complianceDashboardService.retrieveMonthlyCompanyComplianceData(complianceDashboardVO).subscribe(data => {
            this.processCompanyMonthlyCompliance(data,noPage);
            this.periodData = data.yearList;
            this.loading = false;
        });
  }

  processCompanyMonthlyCompliance(data,noPage)
  {
      let company_data = data.esgList;
  
      if(data.yearArray.length > 0)
      {
        this.yearArray = data.yearArray;
      }
      if(company_data.length > 0)
      {
        let performanceAreaName:string='';
        let headingCheckForPA =0;
        this.companyData =[];
        let indicatorStackList,companyIndicatorData,companyIndicatorList;
        for(let i=0;i < company_data.length;i++)
        {
            performanceAreaName = company_data[i].esgTypeName;
            indicatorStackList=[];
            companyIndicatorData=[];

            if(company_data[i].indicatorStackList!=null && company_data[i].indicatorStackList.length > 0)
            {
              indicatorStackList = company_data[i].indicatorStackList;
              for(let j=0;j < indicatorStackList.length;j++)
              {
                companyIndicatorList =[];

                if(indicatorStackList[j].indicatorList!=null && indicatorStackList[j].indicatorList.length > 0)
                {
                  let indicatorList = indicatorStackList[j].indicatorList;
                  indicatorList[0]['stackHeading'] = true;
                  if(headingCheckForPA == 0)
                  {
                    indicatorList[0]['paHeading'] = true;
                    headingCheckForPA = 1;
                  }
                  else
                    indicatorList[0]['paHeading'] = false;
              
                  companyIndicatorList= this.processPeriodDetails(indicatorList);
                  
                
                  companyIndicatorData.push({
                    'indicatorStackName':indicatorStackList[j].indicatorStackName,
                    'indicatorList': companyIndicatorList
                  })
                }
              }
          }
          this.companyData.push({
            'performanceArea':performanceAreaName,
            'indicatordata': companyIndicatorData
          })
          headingCheckForPA =0;

        }
      }
      this.getDataTablePopulation(noPage);
  }

  processPeriodDetails(indicatorList)
  {
    let yearwiseList=[];
    let frequency='';
    for(let k=0;k<indicatorList.length;k++)
    {
        yearwiseList  =  indicatorList[k].yearwiseReportedDetails;
        frequency     =  indicatorList[k].frequency;
        if(frequency==="QUARTERLY")
        {
            for(let z=0;z< yearwiseList.length;z++)
            {
                  let status = yearwiseList[z].reportedStatusQuarterly;
                  let yearArray=[];
                  
                  //quarterly means 4 quarters
                  for(let index = 1 ; index <= 4 ; index++){
                    if(index in status){
                      yearArray.push({"key": index,"value":status[index]});
                    }
                    else{
                      yearArray.push({"key": index,"value":"NA"});
                    }
                  }

                  yearwiseList[z].reportedStatusQuarterly = yearArray;  
            }

          }
          else if(frequency==="MONTHLY")
          {
            for(let z=0;z< yearwiseList.length;z++)
            {
                  let status = yearwiseList[z].reportedStatusMonthly;
                  let yearArray=[];
                  //monthly means 12 months
                  for(let index = 1 ; index <= 12 ; index++){
                    if(index in status){
                      yearArray.push({"key": index,"value":status[index]});
                    }
                    else{
                      yearArray.push({"key": index,"value":"NA"});
                    }
                  }
                  yearwiseList[z].reportedStatusMonthly = yearArray;  
            }

          }
      }
      return indicatorList;
  }

  onSubmit(noPage:number)
  {
    this.selectedPeriod = this.companyDrilldownMonthlyForm.get('period').value;
    if(this.companyDrilldownMonthlyForm.valid && this.selectedPeriod.length > 0 )
    {
        this.isSubmit = true;
        this.emptyFlag = false;
        this.fetchCompanyMonthlyDrilldownData(noPage);
        this.noOfPages = this.selectedPeriod.length;
    }
  }

  resetListView(){
    this.isSubmit=false;
  }

  reset(){

    this.selectedPeriod = this.previousSelectedYear;
    this.fetchCompanyMonthlyDrilldownData(1);
    this.emptyFlag = false;
    this.noOfPages = this.selectedPeriod.length;
    let valueArray = [];
    for(let i of this.previousSelectedYear)
    {
      valueArray.push(i);
    }
    this.companyDrilldownMonthlyForm.get("period").patchValue(valueArray);
    this.isSubmit = true;
  }

  selectAll()
  {
    this.toggleList= !this.toggleList;
    let valueArray = [];
    if(this.toggleList)
    {
      for(let i of this.periodData)
      {
        valueArray.push(i);
      }
      
    }
    this.companyDrilldownMonthlyForm.get("period").patchValue(valueArray);
  }


  getDataTablePopulation(pageNo:number) {

    let periodDataList = [];
    this.presentYear = this.selectedPeriod[pageNo-1];
    periodDataList.push(this.selectedPeriod[pageNo-1])
    this.pageNumber = pageNo;
    this.displayedColumns=[];
    let dashboardArray = [];  
    if(this.companyData.length == 0){
      this.emptyFlag = true;
    }
    for( let fetchCompData of this.companyData){
      var paFlag=false;    
      for(let fetchIndicatorData of fetchCompData.indicatordata ){
        var indicatorStackFlag=false;
        for(let indicatorData of fetchIndicatorData.indicatorList ){

          var newArray = {};
          this.emptyFlag = false;
          newArray['paFlag'] = false;  
          if(paFlag==false) {
            newArray['paFlag'] = true; 
            paFlag=true; 
          }
          newArray['performanceAreaName'] = fetchCompData.performanceArea;
          newArray['indicatorStackFlag'] = false;  
          if(indicatorStackFlag==false) {
            newArray['indicatorStackFlag'] = true; 
            indicatorStackFlag=true; 
          }
          newArray['indicatorStackName'] = fetchIndicatorData.indicatorStackName;
          newArray['indicatorId'] = indicatorData.id;
          newArray['frequency'] = indicatorData.frequency;
          newArray['indicatorName'] =indicatorData.indicatorName;


          if(indicatorData.frequency==="QUARTERLY")
          {
            newArray['frequencyCount'] = 4;
          }
          else if(indicatorData.frequency==="MONTHLY")
          {
            newArray['frequencyCount'] = 12;
          }
          
          for(let yearwiseReported of indicatorData.yearwiseReportedDetails ){
            if(periodDataList.includes(yearwiseReported.year)){
                
                if(indicatorData.frequency==="QUARTERLY")
                {
                  for(let data of yearwiseReported.reportedStatusQuarterly ){
                    newArray['quarterwiseData_'+yearwiseReported.year+'_'+data.key] = data.value;
                  }
                }
                else if(indicatorData.frequency==="MONTHLY")
                {
                  for(let data of yearwiseReported.reportedStatusMonthly ){
                    newArray['monthwiseData_'+yearwiseReported.year+'_'+data.key] = data.value;
                  }
                }
            }
          }
         
          dashboardArray.push(newArray);
        }
      }
      }
      
      this.displayedColumns=['PerformanceArea','IndicatorStack','Indicator','yearData','Action','Error']
      this.dataSource = new MatTableDataSource(dashboardArray);
      this.dataSource.paginator = this.paginator;
     
  }

  goBack(){

    let companyDetails={};
    companyDetails['region']=this.regionName;
    companyDetails['sector']=this.sectorName;
    companyDetails['country']=this.countryName;
    companyDetails['subSector']=this.subSectorName;
    companyDetails['companyId']=this.companyId;
    companyDetails['companyName']=this.companyName;
    companyDetails['selectedYears'] = this.previouslySelectedPeriod;
    this.router.navigate(['dashboard/compliance/companyComplianceDrillDown'],{queryParams:companyDetails, skipLocationChange: true});
  }

}
