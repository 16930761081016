import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IndicatorService } from '../indicator.service';
import { log } from 'console';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { AddEditSuccessComponent } from '../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';

@Component({
  selector: 'app-link-indicator',
  templateUrl: './link-indicator.component.html',
  styleUrls: ['./link-indicator.component.css']
})
export class LinkIndicatorComponent implements OnInit {
   indicatorList:any;
   selectedIndicator:any;
   indicatorId:any;
   displayedColumns: any[];
   indicatorJsonArray: any[];
  dataSource: MatTableDataSource<any>;
  displayedColumnsForModal: any[];
  indicatorJsonArrayForModal:any[];
  dataSourceForModal:any=[];
  public indicatorFormula:any={}
  isError:any=[];
  isErrorWithSymbol:any=[]
  isParanthesisBalanced:any=[]
  paginator: any;
  loading:any;
  guidance:any="Example:\nIF('IND12'=2,1,IF('IND13'=5,2,3))\nIF('IND8'="+'"No",1,IF(AND('+"'IND9'="+'"Yes",'+"'IND10'="+'"No"),2,3))'
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort) sortForModal: MatSort;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
    }
    setDataSourceAttributes() {
      this.dataSourceForModal.paginator = this.paginator;
      this.dataSourceForModal.sort = this.sortForModal;
    }
  constructor(private addEditSuccessComponent:AddEditSuccessComponent,private activatedRoute: ActivatedRoute,private indicatorService:IndicatorService,) { }

  ngOnInit() {
    this.loading=true;
    this.activatedRoute.queryParams.subscribe((params) =>
    {
      this.indicatorId=params.indicatorId
    })
    this.indicatorService.getListOfAllIndicators().subscribe((data) =>
    {
      console.log(this.indicatorId)
      this.indicatorList=data
      for(let indicator of this.indicatorList)
      {
        if(indicator.id==this.indicatorId)
        {
          this.selectedIndicator=indicator;
          break;
        }
      }
      this.dataTablePopulation(this.selectedIndicator)
      this.loading=false;
      console.log(this.indicatorList);
      console.log(this.selectedIndicator)
    })
  }

  dataTablePopulation(data)
  {
    this.indicatorJsonArray=[]
    let indicatorJson={}
    indicatorJson["id"]=data.id
    indicatorJson['Code']=data.code
    indicatorJson['Indicator']=data.name
    this.isError[0]=false;
    this.isErrorWithSymbol[0]=false
    this.isParanthesisBalanced[0]=false
    this.indicatorJsonArray.push(indicatorJson);
    this.indicatorFormula[0]=""
    this.displayedColumns = ['Code', 'Indicator', 'Formula',]
    this.dataSource = new MatTableDataSource(this.indicatorJsonArray);

    this.dataSource.sort = this.sort;

  }
  dataTablePopulationForModal()
  {
    this.indicatorJsonArrayForModal=[]
    var i=1
    for(let indicator of this.indicatorList)
    {
      let indicatorJson={}
      indicatorJson["Sno"]=i++;
      indicatorJson['Code']=indicator.code
      indicatorJson['Indicator']=indicator.name
      this.indicatorJsonArrayForModal.push(indicatorJson)
    }
    this.displayedColumnsForModal = ['Sno', 'Indicator']
    this.dataSourceForModal = new MatTableDataSource(this.indicatorJsonArrayForModal);
    this.dataSourceForModal.sort = this.sortForModal;
    this.dataSourceForModal.paginator = this.paginator;
  }
  applyFilter(filterValue: string,flag) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    if(flag=="N")
    {
      this.dataSource.filter = filterValue;
    }
    else{
      this.dataSourceForModal.filter = filterValue;
    }
 

  }
  onSubmit()
  {
    if(this.indicatorFormula[0]=="")
    {
      this.isError[0]=true;
    }
    else{
    var message = "Successfully Submitted"; 
    this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
      
    });
  }
  }
  resetForm()
  {
    this.isError[0]=false;
    this.indicatorFormula[0]=""
  }

}
