import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, AbstractControl, AbstractControlOptions, ValidationErrors, FormGroupDirective } from '@angular/forms';
import { IndicatorMasterService } from '../../../indicator-master/indicator-master.service';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { CustomValidationsService } from '../../../../shared/Validations/custom-validations.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-add-datapoint',
  templateUrl: './add-datapoint.component.html',
  styleUrls: ['./add-datapoint.component.css']
})
export class AddDatapointComponent implements OnInit {

  addDataPointForm: FormGroup;
  dataPointsList: any = [];
  topWrapper: any;
  @ViewChild("topWrap") topWrap;
  @ViewChild('datapointForm') datapointForm;
  constructor(private formBuilder: FormBuilder, private indicatorMasterDataService: IndicatorMasterService, private customValidationsService: CustomValidationsService, private addEditSuccessComponent: AddEditSuccessComponent) {
    this.addDataPointForm = this.createFormGroup();
  }

  ngOnInit() {
    this.getDataPointList();
    this.topWrapper = this.topWrap;
  }
  createFormGroup() {
    return this.formBuilder.group({
      id: [],
      datapointCode: ['', [Validators.required, Validators.maxLength(45), this.customValidationsService.noWhitespaceValidator]],
      name: ['', [Validators.required, Validators.maxLength(1000), this.customValidationsService.noWhitespaceValidator]]

    }, { updateOn: 'blur' })
  }
  uniqueNameValidator() {
    return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      var id = 0
      if (this.addDataPointForm && this.addDataPointForm.controls.id.value) {
        id = this.addDataPointForm.controls.id.value;
      }

      return this.indicatorMasterDataService.checkDatapointNameValidation(id, c.value).pipe(
        map(val => {

          if (val == 1) {
            return { 'uniqueName': true };
          }
        })
      );
    };


  }

  onSubmit() {
    if (this.addDataPointForm.valid) {
      var message;
      this.indicatorMasterDataService.createDataPoint(this.addDataPointForm.value).subscribe(data => {
        this.getDataPointList();

        if (this.addDataPointForm.get('id').value != null)
          message = "Successfully Edited Data Point";
        else
          message = "Successfully Created Data Point";
        this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
          this.resetDataPointForm();
        });
      }, (error) => {

      });
    }

  }
  resetDataPointForm() {
    this.topWrap.nativeElement.classList.remove("top-form-wrapper-highlight");
    this.datapointForm.resetForm();
  }
  getDataPointList() {
    this.indicatorMasterDataService.getListOfDataPoints().subscribe((data) => {
      this.dataPointsList = data;
    })
  }
  defaultValues(p) {
    this.addDataPointForm.patchValue(p);
  }

}
