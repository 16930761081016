import { Component, OnInit, ViewChild, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import swal from 'sweetalert2';
import { AddSubFactorComponent } from '../add-sub-factor/add-sub-factor.component';
import { AddEditSuccessComponent } from '../../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';

@Component({
  selector: 'app-list-sub-factor',
  templateUrl: './list-sub-factor.component.html',
  styleUrls: ['./list-sub-factor.component.css']
})
export class ListSubFactorComponent implements OnInit {

  dataTableFlag: boolean;
  subfactorList: any;
  originalColumnNames : any;
  jsonArray: any;
  displayedColumns: any;
  selectedFactorId: any;
  top:any;
  @Input() set topWrapper(val){
    this.top=val;
  }


  @Output() subfactorComponent = new EventEmitter();

  @Input() set factorIdSelected(val) {
    this.selectedFactorId = val;

  }

  constructor(private addEditSuccessComponent: AddEditSuccessComponent,private assessmentTemplateService: AssessmentTemplateMasterdataService,
    private addSubFactorComponent: AddSubFactorComponent) { }

  ngOnInit() {
    this.getSubfactorList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['factorIdSelected'] && changes['factorIdSelected'].currentValue > 0) {
      this.getSubfactorListByFactorId(changes['factorIdSelected'].currentValue)
    }
    else {
      this.getSubfactorList();
    }


  }

  getSubfactorList() {
    this.assessmentTemplateService.getSubfactorList().subscribe(data => {
      this.subfactorList = data;
      //         this.flag = false;

      // if(this.subfactorList.length == 0) 
      //   this.flag = true;
      this.dataTablePopulation(this.subfactorList);
    });
  }

  getSubfactorListByFactorId(factorId) {
    this.assessmentTemplateService.getSubfactorListByFactorId(factorId).subscribe(data => {
      this.subfactorList = data;

      this.dataTablePopulation(this.subfactorList);
    });
  }

  dataTablePopulation(subfactorList) {


    this.displayedColumns = [];
    this.originalColumnNames = [];
    this.jsonArray = [];
    var count = 0;

    for (let i of subfactorList) {

      var newArray = {};
      newArray['Sno'] = ++count;
      newArray['id'] = i.id;
      newArray['Code'] = i.subFactorCode;
      newArray['Name'] = i.subFactorName;
      newArray['factorCode'] = i.factorVO.code;
      newArray['factorId'] = i.factorVO.id;
      newArray['Factor'] = i.factorVO.name;
      newArray['editDelete'] =i.editDelete;
      this.jsonArray.push(newArray);

    }
    this.displayedColumns = ['Sno', 'Factor', 'Code', 'Name', 'Actions'];
    this.originalColumnNames = ['Sl.No', 'Factor', 'Code', 'Name', 'Actions'];
    }



  editSubfactor(subfactorObj) {
    this.subfactorComponent.emit({ idVal: subfactorObj.id, subfactorCodeVal: subfactorObj.Code, subfactorNameVal: subfactorObj.Name, factorIdVal: subfactorObj.factorId })
  }

  deleteSubfactor(subfactorObj) {
    swal({
      title: '<p class="swal2-title-warning">Are You Sure?</p>',
      text: "You Want To Delete Subfactor",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'

    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.assessmentTemplateService.deleteSubfactor(subfactorObj.id).subscribe((data) => {
          // swal({
          //   title: 'Success!',
          //   text: 'Deleted Subfactor',
          //   type: 'success',
          //   timer:2000,
          //   confirmButtonText: 'Ok'
          // })
          let msg="Deleted Subfactor";
          this.addEditSuccessComponent.showSuccessSwal(msg)
          .then((value) => {
            this.getSubfactorList();
            this.addSubFactorComponent.resetSubFactorForm();
          });

        }, (error) => {
          console.log(error);
        });
      }
    });
  }
}
