import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { CompanyService } from '../../company.service';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import {ReportConstantsComponent} from '../../../report-constants/report-constants.component';

import { FormControl } from '@angular/forms'
@Component({
  selector: 'app-companydashboard',
  templateUrl: './companydashboard.component.html',
  styleUrls: ['./companydashboard.component.css']
})
export class CompanydashboardComponent implements OnInit {

  public company_id:any;
  public doughnutChartType = 'doughnut';
  public doughnutData: any;
  public chartColors: any;
  public chartOptions: any;
  public companyDetails:any;
  public companyCompanyList:any;
  public companyCompanyIndicatorList:any;
  public companyIndicatorList:any;
  public dataSource:any;
  public jsonArray:any;
  public modalRef: BsModalRef;
  public barChartData:any;
  public barChartLabels:any;
  public environmentIndicators:any = [];
  public esgTypeEName:string;
  public socialIndicators:any = [];
  public esgTypeSName:string;
  public governanceIndicators:any = [];
  public esgTypeGName:string;
  public selectedIndicatorList1:any;
  public selectedIndicatorList2:any;
  public selectedIndicatorList3:any;
  public drillDownData : any;
  public displayActiveClass:any ="indicator active-trans-txt underline-cls";
  public displayClosedClass:any ="indicator closed-trans-txt";
  public barChartDrill:any;
  public drillBarChartLabels:any;
  public drillIndicatorName:string;
  public drilledYear:number;
  public target:any;
  public companyLegendList:any;
  public drillUnitOfMeasurement:any;
  public dataSourcePopUp: MatTableDataSource<any>;
  public showMore: any = true;
  public companyEsgHistory:any;
  public isTarget:any;
  public esgHistoryChartOptions:any;
  public esgHistoryChartData:any;
  public esgHistoryChartLabels:any;
  public indicator = new FormControl();
  public companyName:any;
  public lineChartOpt1 = [];
  public lineChartLabels1 = [];
  public lineChartData1 = [];
  public barChartOpt2 = [];
  public barChartLabels2 = [];
  public barChartData2 = [];
  public lineChartOpt3 = [];
  public lineChartLabels3 = [];
  public lineChartData3 = [];
  public paramRegionList = [];
  public paramSectorList = [];
  public companyRegionNames:any = "-";
  public companySectorNames:any = "-";
  public companyCountryNames:any = "-";
  public isDataFlag:any;
  public currency:string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public displayedColumns = ['Sno', 'Company', 'Region', 'Sector', 'ESG Quotient'];
  public displayedColumnsPopup = ['Sno', 'Company', 'Region', 'Sector', 'ESG Quotient'];

  constructor(private _companyService:CompanyService, private modalService: BsModalService, private _router: Router, private activatedRoute: ActivatedRoute) { 
    this.activatedRoute.params.subscribe(params => { this.company_id = params.id });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  dataTablePopulationPopUp(companyCompanyDetails,targetFlag){
    this.jsonArray =[];
    var sno = 1;
    
      for (let companyDetails of companyCompanyDetails) {
          var newArray = {};
          if(companyDetails.isActive == targetFlag){
              newArray['sno'] = sno;
              newArray['companyName'] = companyDetails.companyName;
              var regionList;
              var sectorList;
              regionList = this.paramRegionList[companyDetails.id];
              sectorList = this.paramSectorList[companyDetails.id];
              newArray['region'] = regionList;
              newArray['sector'] = sectorList;
              newArray['esg'] = companyDetails.esg;
              newArray['esgColorStatus'] = companyDetails.esgColorStatus;
              this.jsonArray.push(newArray);
              sno = sno + 1;
          }
          
      }
      this.dataSourcePopUp = new MatTableDataSource(this.jsonArray);
      this.dataSourcePopUp.paginator = this.paginator
      // setTimeout(() => this.dataSource.paginator = this.paginator);
  }

  dataTablePopulation(companyCompanyDetails,targetFlag){
    this.jsonArray =[];
    var sno = 1;
      for (let companyDetails of companyCompanyDetails) {
          var newArray = {};
          if(companyDetails.isActive == targetFlag){
              var regionList;
              var sectorList;
              newArray['sno'] = sno;
              newArray['companyName'] = companyDetails.companyName;

              if (companyDetails.id in this.paramRegionList) {
                regionList = this.paramRegionList[companyDetails.id];
              }
              if (companyDetails.id in this.paramSectorList) {
                sectorList = this.paramSectorList[companyDetails.id];
              }
              
              newArray['region'] = regionList;
              newArray['sector'] = sectorList;
              newArray['esg'] = companyDetails.esg;
              newArray['esgColorStatus'] = companyDetails.esgColorStatus;
              this.jsonArray.push(newArray);
              sno = sno + 1;
              if(sno > 5)
                break;
          }
          
      }
      this.dataSource = new MatTableDataSource(this.jsonArray);
  }

  getList(flag){
    this.dataTablePopulation(this.companyCompanyList,flag);
  }

  ngOnInit() {
    this.currency = ReportConstantsComponent.CURRENCY;
    this.esgTypeEName = "Environmental";
    this.esgTypeSName = "Social";
    this.esgTypeGName = "Governance";
    this._companyService.getCompanyDetails(this.company_id).subscribe((companyDetails)=>{
      this.companyDetails = companyDetails;
      if(this.companyDetails.length > 0)
        this.companyName = this.companyDetails[0].companyName;
        for (let companyDetailsListing of companyDetails) {
          for (let companyParameters of companyDetailsListing.parameterVO) {
            var str = companyParameters.parameterList; 
            str = str.replace(/"/g, '');
            str = str.replace(/{/g, '');
            str = str.replace(/}/g, '');
            if(companyParameters.parameterName == "Region")
              this.companyRegionNames = str;
            if(companyParameters.parameterName == "Sector")
              this.companySectorNames = str;
            if(companyParameters.parameterName == "Country")
              this.companyCountryNames = str;
          }
          break;
        }
    },(error)=>{
      console.log(error);
    })
  
    this._companyService.getCompanyCompanyList(this.company_id).subscribe((companyCompanyList)=>{
      this.companyCompanyList = companyCompanyList;

      for (let companyTransListing of companyCompanyList) {
        for (let companyCompany of companyTransListing.parameterVO) {
          var id = companyCompany.id;
          var str = companyCompany.parameterList; 
          str = str.replace(/"/g, '');
          str = str.replace(/{/g, '');
          str = str.replace(/}/g, '');
          // var splitted = str.split(","); 
          if(companyCompany.parameterName == "Region")
            this.paramRegionList[id] = str;

          if(companyCompany.parameterName == "Sector")
            this.paramSectorList[id] = str;
        }
        break;
      }

      this.dataTablePopulation(this.companyCompanyList,true);

    },(error)=>{
      console.log(error);
    })

    this._companyService.getCompanySelectedIndicators(this.company_id).subscribe((companyIndicatorList)=>{
      this.companyIndicatorList = companyIndicatorList;
      
      for (let companyIndicators of companyIndicatorList) {
        if(companyIndicators.esgType == 1){
          this.environmentIndicators.push(companyIndicators);
        }
        else if(companyIndicators.esgType == 2){
          this.socialIndicators.push(companyIndicators);
        }
        else if(companyIndicators.esgType == 3){
          this.governanceIndicators.push(companyIndicators);
        }
      }
       
    },(error)=>{
      console.log(error);
    })

    this._companyService.getSelectedIndicators(1,this.company_id,-1).subscribe((selectedIndicatorList)=>{
        this.selectedIndicatorList1 = selectedIndicatorList;
        this.getAllLineData(this.selectedIndicatorList1,1);
    },(error)=>{
      console.log(error);
    })

    this._companyService.getSelectedIndicators(2,this.company_id,-1).subscribe((selectedIndicatorList)=>{
        this.selectedIndicatorList2 = selectedIndicatorList;
        this.getAllLineData(this.selectedIndicatorList2,2);
    },(error)=>{
      console.log(error);
    })

    this._companyService.getSelectedIndicators(3,this.company_id,-1).subscribe((selectedIndicatorList)=>{
      this.selectedIndicatorList3 = selectedIndicatorList;
      this.getAllLineData(this.selectedIndicatorList3,3);
    },(error)=>{
      console.log(error);
    })
   
  }

  OptionsESG(flag) {
    if(flag){
      var per = 40;
      this.chartOptions = {
        responsive: true,
        cutoutPercentage: per,
        animation:false,
      };
    }
    else{
      var per = 75;
      this.chartOptions = {
        responsive: true,
        cutoutPercentage: per,
        animation:false,
      };
    }
    return this.chartOptions;
  }

  doughnutChartESGData(esg: number) {
  	if(esg > 1)
      esg = 1;
    this.doughnutData = [esg, (1 - esg)];
    return this.doughnutData;
  }

  chartColorESG(esgColorStatus: number) {

    /* 3=> green , 2=> amber , 1 => red */
    if (esgColorStatus == 3)
      this.chartColors = [{ backgroundColor: ["#34a853", "#dddddd"] }];
    else if (esgColorStatus == 2)
      this.chartColors = [{ backgroundColor: ["#f39700", "#dddddd"] }];
    else
      this.chartColors = [{ backgroundColor: ["#ea4335", "#dddddd"] }];  

    return this.chartColors;
  }

  public barChartClicked(e:any,indicatorId:any):void {
    if(e.active.length > 0){
      this.showDrillDown(e.active[0]._model.label,indicatorId,true)
    }
    else{
      this.showDrillDown(false,indicatorId,false)
    }
  } 

  addRemovedUnderline(flag) {
    if(flag == 1){
      this.displayActiveClass = 'indicator active-trans-txt underline-cls';
      this.displayClosedClass = 'indicator closed-trans-txt';
    }
    else{
      this.displayActiveClass = 'indicator active-trans-txt';
      this.displayClosedClass = 'indicator closed-trans-txt underline-cls';
    }
  }

  // events
  public chartClicked(e:any):void {
    console.log(e);
  }

  public chartHovered(e:any):void {
    console.log(e);
  }

  showIndicatorBox(value,esgType){
    if(value.length == 0)
      value = -1;
    this._companyService.getSelectedIndicators(esgType,this.company_id,value).subscribe((selectedIndicatorList)=>{
      if(esgType ==1){
        this.selectedIndicatorList1 = selectedIndicatorList;
        this.getAllLineData(this.selectedIndicatorList1,esgType);
      }
      else if(esgType == 2){
        this.selectedIndicatorList2 = selectedIndicatorList;
        this.getAllLineData(this.selectedIndicatorList2,esgType);
      }
      else if(esgType == 3){
        this.selectedIndicatorList3 = selectedIndicatorList;
        this.getAllLineData(this.selectedIndicatorList3,esgType);
      }
    },(error)=>{
      console.log(error);
    })
  }

  // drill down chart
  public drillBarChartType:string = 'bar';
  public drillBarChartLegend:boolean = false;
  public drillBarChartOptions:any;
  public drillBarChartColors:Array<any> = [
    { // green
      backgroundColor: 'rgb(32,176,141)',
      borderColor: 'rgb(32,176,141)',
      pointBackgroundColor: 'rgb(32,176,141)',
      pointBorderColor: 'rgb(255,255,255)',
      pointHoverBackgroundColor: 'rgb(255,255,255)',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  showDrillDown(year,indicatorId,dataFlag){

    if(dataFlag){

      this.isDataFlag = 1;

      this.drilledYear = year;
      this._companyService.getDrillDown(year,indicatorId,this.company_id).subscribe((drillDownData)=>{
          this.drillDownData = drillDownData;
          var actual = new Array();
          var company = new Array();
          var companyLegend = new Array();
          var index = 1;
          this.isTarget = 0;

          for (let chartData of this.drillDownData) {
            actual.push(chartData.actual);
            company.push('T'+index);
            companyLegend.push('T'+index+' : '+chartData.company);
            this.drillIndicatorName = chartData.indicatorName;
            this.drillUnitOfMeasurement = chartData.unitOfMeasurement;
            this.target = chartData.target;
            index++;
          }

          if(this.target)
              this.isTarget = 1;

          this.drillBarChartOptions = {
            scaleShowVerticalLines: true,
            responsive: true,
            animation:false,
            scales: {
              xAxes: [{
                      display: true,
                      barPercentage: 0.5,
                      gridLines: {
                        display:false,
                        drawTicks:true,
                      },
                      scaleLabel: {
                          display: true,
                          labelString: 'Company'
                      }
                  }],
              yAxes: [{
                      display: true,
                      gridLines: {
                        display:false,
                        drawTicks:true
                      },
                      scaleLabel: {
                        display: true,
                        labelString: this.drillUnitOfMeasurement
                      },
                      ticks: {
                          beginAtZero: true,
                          steps: 10,
                          stepValue: 5,
                          min: 0
                      }
                  }]
            },
            title: {
                display: false,
                text: 'DRILL DOWN CHART'
            }
          };
          this.drillBarChartLabels = company;
          this.companyLegendList = companyLegend;
          this.barChartDrill = [{'dataSet' : [{data: actual}]},
          ];

      },(error)=>{
        console.log(error);
      })

    }
    else{
          this.drillBarChartLabels = [];
          this.companyLegendList = [];
          this.barChartDrill = [];
          this.isDataFlag = 0;
    }
  }

  showCompanyESGHistory(){

    this._companyService.getCompanyEsgHistory(this.company_id).subscribe((companyEsgHistory)=>{
        this.companyEsgHistory = companyEsgHistory;
        var esg = new Array();
        var year = new Array();

        for (let esgData of this.companyEsgHistory) {
          esg.push(esgData.esg);
          year.push(esgData.year);
        }

        this.esgHistoryChartOptions = {
          responsive: true,
          // animation:false,
          scales: {
            xAxes: [{
                    display: true,
                    gridLines: {
                      display:false,
                      drawTicks:true,
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Year'
                    },
                }],
            yAxes: [{
                    display: true,
                    gridLines: {
                      display:false,
                      drawTicks:true
                    },
                    scaleLabel: {
                      display: true,
                      labelString: 'ESG Quotient'
                    },
                    ticks: {
                        beginAtZero: true,
                        steps: 10,
                        stepValue: 5,
                        min: 0,
                        max: 1
                    }
                }]
          },
          title: {
              display: false,
              text: 'ESG Quotients'
          }
        };

        this.esgHistoryChartLabels = year;
        this.esgHistoryChartData = [{'dataSet' : [{data: esg}]}];

    },(error)=>{
      console.log(error);
    })
  }
  public esgHistoryColor:Array<any> = [
    { // green
      backgroundColor: 'rgb(217,241,226)',
      borderColor: 'rgb(64,187,110)',
      pointBackgroundColor: 'rgb(255,255,255)',
      pointBorderColor: 'rgb(32,176,141)',
      pointHoverBackgroundColor: 'rgb(255,255,255)',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
      pointRadius:6,
    }
  ];
  public esgHistoryLegend:boolean = false;
  public esgHistoryType:string = 'line';
  
  getAllLineData(selectedIndicatorList,esgType){

      for (let selectedIndList of selectedIndicatorList) {
        var actual = new Array();
        var year = new Array();
        for (let actualData of selectedIndList.chartAxesVO) {
            actual.push(actualData.actual);
            year.push(actualData.year);
        }

        if(esgType == 1){
          this.lineChartOpt1[selectedIndList.id] = {
            responsive: true,
            animation:false,
            scales: {
              xAxes: [{
                      display: true,
                      gridLines: {
                        display:false,
                        drawTicks:true,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: 'Year'
                      },
                  }],
              yAxes: [{
                      display: true,
                      gridLines: {
                        display:false,
                        drawTicks:true
                      },
                      scaleLabel: {
                        display: true,
                        labelString: selectedIndList.unitOfMeasurement
                      },
                      ticks: {
                        beginAtZero: true,
                        steps: 10,
                        stepValue: 5,
                        min: 0
                      }
                  }]
            },
            title: {
                display: false,
                text: 'Chart'
            }
          };
          this.lineChartLabels1[selectedIndList.id] = year;
          this.lineChartData1[selectedIndList.id] = [{'dataSet' : [{data: actual}]}];
        }
        else if(esgType == 2){
          this.barChartOpt2[selectedIndList.id] = {
            scaleShowVerticalLines: true,
            responsive: true,
            animation:false,
            scales: {
              xAxes: [{
                      display: true,
                      barPercentage: 0.6,
                      gridLines: {
                        display:false,
                        drawTicks:true,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: 'Year'
                      }
                  }],
              yAxes: [{
                      display: true,
                      gridLines: {
                        display:false,
                        drawTicks:true
                      },
                      scaleLabel: {
                        display: true,
                        labelString: selectedIndList.unitOfMeasurement
                      },
                      ticks: {
                        beginAtZero: true,
                        steps: 10,
                        stepValue: 5,
                        min: 0
                      }
                  }]
            },
            title: {
                display: false,
                text: 'chart'
            }
          };
          this.barChartLabels2[selectedIndList.id] = year;
          this.barChartData2[selectedIndList.id] = [{'dataSet' : [{data: actual}]}];
        }
        else if(esgType == 3){
          this.lineChartOpt3[selectedIndList.id] = {
            responsive: true,
            animation:false,
            scales: {
              xAxes: [{
                      display: true,
                      gridLines: {
                        display:false,
                        drawTicks:true,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: 'Year'
                      },
                  }],
              yAxes: [{
                      display: true,
                      gridLines: {
                        display:false,
                        drawTicks:true
                      },
                      scaleLabel: {
                        display: true,
                        labelString: selectedIndList.unitOfMeasurement
                      },
                      ticks: {
                        beginAtZero: true,
                        steps: 10,
                        stepValue: 5,
                        min: 0
                      }
                  }]
            },
            title: {
                display: false,
                text: 'Chart'
            }
          };
          this.lineChartLabels3[selectedIndList.id] = year;
          this.lineChartData3[selectedIndList.id] = [{'dataSet' : [{data: actual}]}];
        }
      }
  }

  public lineChartColor1:Array<any> = [
    { // green
      backgroundColor: 'rgb(97,194,170)',
      borderColor: 'rgb(97,194,170)',
      pointBackgroundColor: 'rgb(32,176,141)',
      pointBorderColor: 'rgb(255,255,255)',
      pointHoverBackgroundColor: 'rgb(255,255,255)',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
      pointRadius:5,
    }
  ];
  public barChartColor2:Array<any> = [
    { // green
      backgroundColor: 'rgb(32,176,141)',
      borderColor: 'rgb(32,176,141)',
      pointBackgroundColor: 'rgb(255,255,255)',
      pointBorderColor: 'rgb(32,176,141)',
      pointHoverBackgroundColor: 'rgb(255,255,255)',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    }
  ];
  public lineChartColor3:Array<any> = [
    { // green
      backgroundColor: 'rgb(217,241,226)',
      borderColor: 'rgb(64,187,110)',
      pointBackgroundColor: 'rgb(255,255,255)',
      pointBorderColor: 'rgb(32,176,141)',
      pointHoverBackgroundColor: 'rgb(32,176,141)',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
      pointRadius:5,
      
    }
  ];
  public lineChartLegend:boolean = false;
  public lineChartType:string = 'line';
  public barChartType:string = 'bar';
  public barChartLegend:boolean = false;

}