import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import {Http, Response , Headers, RequestOptions} from '@angular/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseUrlService } from '../../shared/base-url.service';
@Injectable({
  providedIn: 'root'
})
export class AssessmentTemplateMasterdataService {
 
  private baseUrl = BaseUrlService.ASSESSMENTBASEURL;
  companyVO=new BehaviorSubject<any>({})
  constructor(private http: HttpClient) { }

  getBaseUrl() : string{
    return this.baseUrl;
  }

  createFactor(addFactorForm : any){
    let body = JSON.stringify(addFactorForm);
    let url = this.baseUrl+'/createOrUpdateFactor';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);
  }

  getFactorList() {
    return this.http.get(`${this.baseUrl}/getFactorDetails`);
  }
  getCompanyVO(companyDetails)
  {

       this.companyVO.next(companyDetails)
   
  }
  deleteFactor(id) {

    return this.http.delete(`${this.baseUrl}/factor/delete/` + id, { responseType: 'text' });
  }
  
  createSubfactor(subfactorForm : any) {
    let body= JSON.stringify(subfactorForm);
    let url = this.baseUrl+'/createOrUpdateSubFactor';
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type' : 'application/json',
      })
    };

    return this.http.post(url,body,httpOptions);
  }

  getSubfactorList() {
    return this.http.get(`${this.baseUrl}/getSubFactorDetails`);
  }

  getSubfactorListByFactorId(factorId) {
    return this.http.get(`${this.baseUrl}/getSubFactorDetails/`+factorId);
  }
  verifyReassessmentStatus(assessmentVO,) {
    let body= JSON.stringify(assessmentVO);
    let url = this.baseUrl+'/verifyOrReassessment';
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type' : 'application/json',
      })
    };

    return this.http.post(url,body,httpOptions);
  }

  deleteSubfactor(id) {
    return this.http.delete(`${this.baseUrl}/subFactor/delete/`+ id, { responseType: 'text' });
  }
  
  getQuestionsList() {
    return this.http.get(`${this.baseUrl}/listQuestions`);
  }
  createQuestion(questionForm: any) {
    let body = JSON.stringify(questionForm);
    let url = this.baseUrl + '/createOrUpdateQuestion';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })

    };

    return this.http.post(url, body, httpOptions);
  }
  
  createResponseOptions(responseOptionForm :any){

    let body = JSON.stringify(responseOptionForm);
    let url = this.baseUrl + '/addResponseOptions';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })

    };

    return this.http.post(url, body, httpOptions);
  }

  loadResponseOptions()
  {
    return this.http.get(`${this.baseUrl}/listResponseOptions`);
  }

  deleteResponseOption(id){
    return this.http.delete(`${this.baseUrl}/deleteResponseOption/`+id);
  }

  getAlluserList():Observable<any> {
    return this.http.get(`${this.baseUrl}/listAllUsers`);
  }
  createPanel(addPanelForm : any){
    let body = JSON.stringify(addPanelForm);
    let url = this.baseUrl+'/createOrUpdatePanel';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);
  }
  getPanelList(){
    return this.http.get(`${this.baseUrl}/listPanels`);
  }
  deletePanel(id): Observable<any> {

    return this.http.delete(`${this.baseUrl}/deletePanel/` + id, { responseType: 'text' });
  }
  
   fetchSubFactorUserMapping(subFactorId)
  {
        return this.http.get(`${this.baseUrl}/listSubfactorUserMapping/`+ subFactorId);

  }

  createSubFactorUserMapping(subFactorVO: any) {
    let body = JSON.stringify(subFactorVO);
    let url = this.baseUrl + '/tagSubFactorToUsers';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })

    };

    return this.http.post(url, body, httpOptions);
  }
  
getAssessmentsOfUser(userId){
    return this.http.get(`${this.baseUrl}/listAssessmentOfUser/` + userId,);
  }
  
  getQualitativeResponseOptions(): Observable<any> {
    return this.http.get(`${this.baseUrl}/getQualitativeResponseOptions`);
  }
  getResponseOptionValuesForResponseOption(selectedResponseOption: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/getResponseOptionValuesForResponseOption?responseOptionName=` + selectedResponseOption);
  }
  deleteQuestion(questionId:number):Observable<any>{
    return this.http.delete(`${this.baseUrl}/deleteQuestion/`+questionId,{ responseType: 'text' });
  }
    loadDataForQuestion(): Observable<any> {
    return this.http.get(`${this.baseUrl}/loadDataForQuestion`);
  }
  fetchCompanyAssessmentInitiationDetails(companyId)
  {
    return this.http.get(`${this.baseUrl}/getDetailsForCompanyAssessmentInitiation/`+companyId);
  }
  
   fetchCompanyAssessmentListDetails(companyId) {
    return this.http.get(`${this.baseUrl}/getAssessmentListForCompanyId/`+companyId);
  }

  viewAssessmentDetails(assessmentId): any {
    return this.http.get(`${this.baseUrl}/getAssessmentDetailsForCompany/`+assessmentId);
  }

  initiateAssessment(assessmentVO:any)
  {
     let body = JSON.stringify(assessmentVO);
    
    let url = this.baseUrl + '/initiateCompanyAssessment';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })

    };

    return this.http.post(url, body, httpOptions);

  }
  
  fetchAllCompanies() {
    return this.http.get(`${this.baseUrl}/listAllCompanies`);
  }
  loadMasterDataForTemplate()
  {
    return this.http.get(`${this.baseUrl}/getMasterDataForTemplate`);
  }
  loadSubFactorsforTheSelectedFactors(factorIds)
  {
    
    let body = factorIds;
    let url = this.baseUrl + '/getSubfactorsForFactors';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);
  }

  loadQuestionsforTheSelectedSubfactors(subFactorIds)
  {
    
    let body = subFactorIds;
    let url = this.baseUrl + '/getQuestionsTaggedToSubFactors';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);
  }

  createTemplate(templateForm :any){

    let url = this.baseUrl + '/addTemplate';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })

    };

    return this.http.post(url, templateForm, httpOptions);
  }
  
  getAssessmentById(assessmentId,userId){
    return this.http.get(`${this.baseUrl}/fetchAllTaggedQuestions/`+assessmentId+`/`+userId);
  }
  
  submitPanelMemberEvaluation(assessmentResponse :any){
    let body = JSON.stringify(assessmentResponse);
    let url = this.baseUrl + '/submitPanelMemberEvaluation';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })

    };

    return this.http.post(url, body, httpOptions);
  }
  getResponseOptionsForQuestion(questionId:number){
    return this.http.get(`${this.baseUrl}/responseOptionsForQuestion/`+questionId);
  }
  getQuestionResponseOption(questionId:number){
    return this.http.get(`${this.baseUrl}/questionResponseOptions/`+questionId);
  }
  getTemplateList(): any {
    return this.http.get(`${this.baseUrl}/listTemplates`);
  }
  getTemplateListById(templateId){
    return this.http.get(`${this.baseUrl}/getTemplateDetailsById/`+templateId);
    
  }

  getTemplateSettings(templateId){
    return this.http.get(`${this.baseUrl}/templateSettingsData/`+templateId);
    
  }
  addTemplateConfiguration(factorSubFactorQuestionListVO:any){
   
    let body = JSON.stringify(factorSubFactorQuestionListVO);
    let url = this.baseUrl + '/addFactorWeightage';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })

    };

    return this.http.post(url, body, httpOptions);
  }
  
  getChildQuestions(parentQuestionId:any,templateId:any){
    return this.http.get(`${this.baseUrl}/getChildQuestions/`+parentQuestionId+`/`+templateId);
  }
  
  loadChildQuestionsForSelectedQuestion(questionId)
  {
    return this.http.get(`${this.baseUrl}/getApplicableChildQuestions/`+questionId);
  }
  
  fetchQuestionListForQuantitativeData(questionTemplateId,inputValue,userId,assessmentId)
  {
       return this.http.get(`${this.baseUrl}/getQuestionListForQuantitativeData/`+questionTemplateId+'/'+inputValue+'/'+userId+'/'+assessmentId);

  }
  editTemplateDetailsById(templateId:any){
    return this.http.get(`${this.baseUrl}/getTemplateDetailsWithSkip/`+templateId);
  }
   deleteTemplateQuestion(templateId:number,questionId:number):Observable<any>{
    return this.http.delete(`${this.baseUrl}/deleteTempateQuestion/`+templateId+'/'+questionId,{ responseType: 'text' });
  }

  upload(formdata): Observable<any>{
  
    const req = new HttpRequest('POST', this.baseUrl+'/uploadEvidenceFile', formdata, {
      reportProgress: true,
      responseType: 'text',
      headers: new HttpHeaders({
        "Content-Type": "multipart/form-data"
        })
    });

    return this.http.request(req);
 
  }
    createTemplatePrimaryQuestion(templateForm :any){

    let url = this.baseUrl + '/addPrimaryQuestion';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })

    };

    return this.http.post(url, templateForm, httpOptions);
  }
   createSkipQuestion(templateForm :any){

    let url = this.baseUrl + '/addSkipForQuestion';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })

    };
    return this.http.post(url, templateForm, httpOptions);
  }
   getChildQuestionsOnTemplateQuestionId(parentTemplateQuestionId:any){
    return this.http.get(`${this.baseUrl}/getChildQuestionsOnTemplateQuestionId/`+parentTemplateQuestionId);
  }
  deleteTemplate(templateId) {

    return this.http.delete(`${this.baseUrl}/deleteTemplateDetails/` + templateId, { responseType: 'text' });
  }
  
  createSubQuestion(templateForm){
    let url = this.baseUrl + '/saveSubQuestion';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })

    };
    return this.http.post(url, templateForm, httpOptions);
  }
  getDetailsForHistoryPopUp(PanelDetailsId,assessmentId)
  {
    return this.http.get(`${this.baseUrl}/getCompanyAssessmentResponseHistory/${PanelDetailsId}/${assessmentId}`);

  }
  
}
