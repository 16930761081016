import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { PeerCompanyService } from '../../peer-company.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { CompanyService } from '../../../CompanyDashboard/company.service';
import { FormControl } from '@angular/forms'
import { map } from 'rxjs/operators/map';
import { ReportConstantsComponent } from '../../../report-constants/report-constants.component';
import {
  bounceOnEnterAnimation,
  bounceInOnEnterAnimation,
  bounceOutOnLeaveAnimation,
  bounceInUpOnEnterAnimation,
  bounceOutDownOnLeaveAnimation,
  bounceInDownOnEnterAnimation,
  bounceOutUpOnLeaveAnimation,
  bounceInLeftOnEnterAnimation,
  bounceInRightOnEnterAnimation,
  bounceOutLeftOnLeaveAnimation,
  bounceOutRightOnLeaveAnimation,
} from 'angular-animations';
@Component({
  selector: 'app-peer-company-dashboard',
  templateUrl: './peer-company-dashboard.component.html',
  styleUrls: ['./peer-company-dashboard.component.css'],
  animations: [
    bounceInDownOnEnterAnimation({ anchor: 'enter3' }),
    bounceInDownOnEnterAnimation({ anchor: 'enter2', delay: 100 }),
    bounceInUpOnEnterAnimation({ anchor: 'enter1', delay: 500, animateChildren: 'none' }),
    bounceOnEnterAnimation(),
    bounceInOnEnterAnimation(),
    bounceInUpOnEnterAnimation(),
    bounceOutOnLeaveAnimation(),
    bounceOutDownOnLeaveAnimation(),
    bounceInDownOnEnterAnimation(),
    bounceOutUpOnLeaveAnimation(),
    bounceInLeftOnEnterAnimation(),
    bounceInRightOnEnterAnimation(),
    bounceOutLeftOnLeaveAnimation(),
    bounceOutRightOnLeaveAnimation()]
})

export class PeerCompanyDashboardComponent implements OnInit {

  public peer_company_id: any;
  public companyList: any = [];
  public peerCompanyDetails: any;
  public peerCompanyIndicatorList: any;
  public doughnutChartType = 'doughnut';
  public doughnutData: any;
  public chartColors: any;
  public chartOptions: any;
  public barChartOptEsg: any;
  public barChartColorsForEsg: Array<any> = [];
  public activeBarChartOptEsg: any;
  public label: any;
  public barChartLabelsEsg: any;
  public barChartDataEsg: any = [];
  public indLineChartLabels = [];
  public indLineChartData = [];
  public indLineSocChartLabels = [];
  public indLineSocChartData = [];
  public indLineGovChartLabels = [];
  public indLineGovChartData = [];
  public esgBarData = [];
  public esgBarLabel = [];
  public esgBarColor = [];
  public esgColorVal: any;
  public transLabel = [];
  public activeTransData = [];
  public closedTransData = [];
  public presentTransData = [];
  public pastTransData = [];
  public presentPastAmtData = [];
  public transBarChartData = [];
  public transBarChartLabels = [];
  public presentPastLabel = [];
  public presentPastChartOptEsg: any;
  public presentPastChartLabel = [];
  public currentYear: any;
  public previousYear: any;
  public selectedIndicatorListEnv: any = [];
  public selectedIndicatorListSoc: any = [];
  public selectedIndicatorListGov: any = [];
  public indBarChartOpt = [];
  public indBarChartLabels = [];
  public indBarChartData = [];
  public indBarSocChartLabels = [];
  public indBarSocChartData = [];
  public indBarGovChartLabels = [];
  public indBarGovChartData = [];
  public calendarIconShow: any = {};
  public userIconShow: any = {};
  public barChartShow: any = {};
  public lineChartShow: any = {};
  public lineChartOpt = [];
  public barEnv: any;
  public lineEnv: any;
  public barSoc: any;
  public lineSoc: any;
  public barGov: any;
  public lineGov: any;
  public chartTypeFlag: any = 0;
  public count: any;
  public yearCount: any = 2015;
  public lineChartMap: Map<any[], string> = new Map();
  public companyMap: Map<string, any[]> = new Map();
  public lineMapList: Array<{ data: any[], label: string }> = [];
  public alllineMapList: any = [];
  public chartData: any = [];
  public chartLabels: any = [];
  public linechartOptions = [];
  public datasets: Array<any> = [{}];
  public environmentIndicators: any = [];
  public esgTypeENV: any;
  public socialIndicators: any = [];
  public esgTypeSOC: any;
  public governanceIndicators: any = [];
  public esgTypeGOV: any;
  public yearList: any = [];
  public companyRegionNames = [];
  public companySectorNames = [];
  public lastColumn: number;
  public totalCompanyCount: number;
  public displayedColumns: any;
  public dataSource: any;
  public emptyFlag: boolean;
  public currency: string;
  public emptyBoxCount: number;
  public emptyBoxesArr = [];
  public flag = [];
  public envSelectedId = [-1];
  public socSelectedId = [-1];
  public govSelectedId = [-1];
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private companyDataService :CompanyService ,private _peerCompanyService: PeerCompanyService, private _router: Router, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(params => { this.peer_company_id = params.id });
  }

  ngOnInit() {

    this.currentYear = (new Date()).getFullYear();
    this.previousYear = this.currentYear - 1;
    this.currency = ReportConstantsComponent.CURRENCY;
    this.barEnv = true;
    this.barSoc = true;
    this.barGov = true;
    this.lineEnv = false;
    this.lineSoc = false;
    this.lineGov = false;

    this._peerCompanyService.getPeerCompanyDetails(this.peer_company_id).subscribe((peerCompanyDetails) => {

      this.peerCompanyDetails = peerCompanyDetails;
      this.totalCompanyCount = peerCompanyDetails.length;
      if (this.totalCompanyCount < 6) {
        this.emptyBoxCount = 6 - this.totalCompanyCount;
      }
      else {
        this.emptyBoxCount = 6 - (this.totalCompanyCount % 6);
      }

      let newArray = {};
      this.displayedColumns = ['column'];
      let dashboardArray = [];
      let data = [];
      this.emptyFlag = true;
      let columnNo = 1;
      let count = 0;
      for (let companyDetailsListing of peerCompanyDetails) {
        this.emptyFlag = false;
        this.companyRegionNames[companyDetailsListing.id] = '-';
        this.companySectorNames[companyDetailsListing.id] = '-';

        for (let companyParameters of companyDetailsListing.parameterVO) {
          var str = companyParameters.parameterList;
          str = str.replace(/"/g, '');
          str = str.replace(/{/g, '');
          str = str.replace(/}/g, '');
          if (companyParameters.parameterName == "Region") {
            this.companyRegionNames[companyDetailsListing.id] = str;
          }
          if (companyParameters.parameterName == "Sector") {
            this.companySectorNames[companyDetailsListing.id] = str;
          }
        }

        newArray = {}
        newArray['companyId'] = companyDetailsListing.id;
        newArray['companyName'] = companyDetailsListing.companyName;
        newArray['overallESGQuotient'] = companyDetailsListing.overallEsgQuotient;
        newArray['esgColorStatus'] = companyDetailsListing.esgColorStatus;
        newArray['imageFileName'] = companyDetailsListing.companyImgFileName;
        newArray['columnNo'] = columnNo;
        newArray['empty'] = false;
        data.push(newArray);
        count++;
        if (columnNo % 6 == 0) {
          dashboardArray.push(data);
          columnNo = 1;
          data = [];
        }
        else {
          if (count == this.totalCompanyCount) {
            for (let i = 0; i < this.emptyBoxCount; i++) {
              newArray = {}
              newArray['empty'] = true;
              data.push(newArray);
            }
            dashboardArray.push(data);
          }
          else {
            columnNo++;
          }
        }
      }
      this.dataSource = new MatTableDataSource(dashboardArray);
      this.dataSource.paginator = this.paginator;
      this.getEsgBarChartData(this.peerCompanyDetails);
      this.getCompanyTransChart(this.peerCompanyDetails);
      this.getTransAmtChart(this.peerCompanyDetails);

    })

    this._peerCompanyService.getPeerCompanyIndicatorList(this.peer_company_id).subscribe((peerCompanyIndicatorList) => {

      this.peerCompanyIndicatorList = peerCompanyIndicatorList;
      console.log("resultttt")
      console.log(this.peerCompanyIndicatorList)
      for (let indicators of this.peerCompanyIndicatorList) {
        this.userIconShow[1] = false;
        this.calendarIconShow[1] = true;
        this.userIconShow[2] = false;
        this.calendarIconShow[2] = true;
        this.userIconShow[3] = false;
        this.calendarIconShow[3] = true;

        if (indicators.esgType == 1) {
          this.environmentIndicators.push(indicators);
          this.selectedIndicatorListEnv.push(indicators);
          this.esgTypeENV = indicators.esgTypeName;
          this.getAllIndChartData(this.environmentIndicators, 1);
        }
        else if (indicators.esgType == 2) {
          this.socialIndicators.push(indicators);
          this.selectedIndicatorListSoc.push(indicators);
          this.esgTypeSOC = indicators.esgTypeName;
          this.getAllIndChartData(this.socialIndicators, 2);
        }
        else if (indicators.esgType == 3) {
          this.governanceIndicators.push(indicators);
          this.selectedIndicatorListGov.push(indicators);
          this.esgTypeGOV = indicators.esgTypeName;
          this.getAllIndChartData(this.governanceIndicators, 3);
        }
      }

    })

  }
  goToCompanyDashboard(id: any) {
    this._router.navigate(['/performanceManagement/dashboard/companyDashboard/' + id]);
  }
  userIconToggle(esgType) {
    var value = [-1];
    this.userIconShow[esgType] = !this.userIconShow[esgType];
    this.calendarIconShow[esgType] = !this.calendarIconShow[esgType];
    if (esgType == 1) { value = this.envSelectedId; }
    else if (esgType == 2) { value = this.socSelectedId; }
    else if (esgType == 3) { value = this.govSelectedId; }
    this._peerCompanyService.getPeerCompanySelectedIndicators(this.peer_company_id, esgType, value, 2).subscribe((selectedIndicatorList) => {
console.log("Line Data")
console.log(selectedIndicatorList)
      if (esgType == 1) {
        this.lineEnv = true;
        this.barEnv = false;
        this.environmentIndicators = selectedIndicatorList;
        this.getIndLineData(selectedIndicatorList, 1);
      }
      else if (esgType == 2) {
        this.lineSoc = true;
        this.barSoc = false;
        this.socialIndicators = selectedIndicatorList;
        this.getIndLineData(selectedIndicatorList, 2);
      }
      else if (esgType == 3) {
        this.lineGov = true;
        this.barGov = false;
        this.governanceIndicators = selectedIndicatorList;
        this.getIndLineData(selectedIndicatorList, 3);
      }

    })

  }

  calendarIconToggle(esgType) {

    this.userIconShow[esgType] = !this.userIconShow[esgType];
    this.calendarIconShow[esgType] = !this.calendarIconShow[esgType];
    var value = [-1];
    if (esgType == 1) { value = this.envSelectedId; }
    else if (esgType == 2) { value = this.socSelectedId; }
    else if (esgType == 3) { value = this.govSelectedId; }
    this._peerCompanyService.getPeerCompanySelectedIndicators(this.peer_company_id, esgType, value, 1).subscribe((selectedIndicatorList) => {
      console.log("Line Data")
console.log(selectedIndicatorList)
      if (esgType == 1) {
        this.lineEnv = false;
        this.barEnv = true;
        this.environmentIndicators = selectedIndicatorList;
        this.getAllIndChartData(this.selectedIndicatorListEnv, 1);
      }
      else if (esgType == 2) {
        this.lineSoc = false;
        this.barSoc = true;
        this.socialIndicators = selectedIndicatorList;
        this.getAllIndChartData(this.socialIndicators, 2);
      }
      else if (esgType == 3) {
        this.lineGov = false;
        this.barGov = true;
        this.governanceIndicators = selectedIndicatorList;
        this.getAllIndChartData(this.governanceIndicators, 3);
      }
    })
  }

  OptionsESG(flag) {
    var per = 50;
    this.chartOptions = {
      responsive: true,
      cutoutPercentage: per,
      animation: false,
    };
    return this.chartOptions;
  }

  doughnutChartESGData(esg: number) {

    if (esg > 1)
      esg = 1;

    this.doughnutData = [esg, (1 - esg)];
    return this.doughnutData;
  }

  chartColorESG(esgColorStatus: number) {

    /* 3=> green , 2=> amber , 1 => red */
    if (esgColorStatus == 3)
      this.chartColors = [{ backgroundColor: ["#34a853", "#dddddd"] }];
    else if (esgColorStatus == 2)
      this.chartColors = [{ backgroundColor: ["#f39700", "#dddddd"] }];
    else
      this.chartColors = [{ backgroundColor: ["#ea4335", "#dddddd"] }];

    return this.chartColors;
  }

  // public barChartColorsForEsg:Array<any>=[
  //   { //yellow,green,red
  //     backgroundColor: [
  //       '#F3A01E',
  //       '#40BA6D',
  //       '#EA4E4F',
  //       '#F3A01E',
  //       '#40BA6D',
  //       '#EA4E4F',
  //     ],

  //   }

  // ];
  public barChartColorEsg: Array<any> = [
    { // green
      backgroundColor: 'rgb(32,176,141)',
      borderColor: 'rgb(32,176,141)',
      pointBackgroundColor: 'rgb(255,255,255)',
      pointBorderColor: 'rgb(32,176,141)',
      pointHoverBackgroundColor: 'rgb(255,255,255)',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    }
  ];
  public barChartColorTrans: Array<any> = [
    {
      backgroundColor: '#22B2A6',
      borderColor: '#22B2A6',
      pointBackgroundColor: 'rgb(255,255,255)',
      pointBorderColor: '#22B2A6',
      pointHoverBackgroundColor: 'rgb(255,255,255)',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },

    {
      backgroundColor: '#47605E',
      borderColor: '#47605E',
      pointBackgroundColor: 'rgb(255,255,255)',
      pointBorderColor: '#47605E',
      pointHoverBackgroundColor: 'rgb(255,255,255)',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    }

  ];
  public barChartColorPrePast: Array<any> = [

    {
      backgroundColor: 'rgb(32,176,141)',
      borderColor: 'rgb(32,176,141)',
      pointBackgroundColor: 'rgb(255,255,255)',
      pointBorderColor: 'rgb(32,176,141)',
      pointHoverBackgroundColor: 'rgb(255,255,255)',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
    {
      backgroundColor: '#2E7060',
      borderColor: '#2E7060',
      pointBackgroundColor: 'rgb(255,255,255)',
      pointBorderColor: '#2E7060',
      pointHoverBackgroundColor: 'rgb(255,255,255)',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    }


  ];
  lineColors = [
    {
      backgroundColor: 'rgba(103, 58, 183, .01)',
      borderColor: 'rgb(103, 58, 183)',
      pointBackgroundColor: 'rgb(103, 58, 183)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(103, 58, 183, .8)'
    },
    {
      backgroundColor: 'rgba(97, 194, 170, .01)',
      borderColor: 'rgb(97, 194, 170)',
      pointBackgroundColor: 'rgb(97, 194, 170)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(97, 194, 170, .8)'
    },
    {
      backgroundColor: 'rgba(255, 20, 147,.01)',
      borderColor: 'rgb(255, 20, 147)',
      pointBackgroundColor: 'rgb(255, 20, 147)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(255, 20 , 147, .8)'
    },
    {
      backgroundColor: 'rgba(255, 191, 0,.01)',
      borderColor: 'rgb(255, 191, 0)',
      pointBackgroundColor: 'rgb(255, 191, 0)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(255, 191, 0,.8)'
    },
    {
      backgroundColor: 'rgba(0, 191, 255, .01)',
      borderColor: 'rgb(0, 191, 255)',
      pointBackgroundColor: 'rgb(0, 191, 255)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(0, 191, 255, .8)'
    },
    {
      backgroundColor: 'rgba(0, 255, 0,.01)',
      borderColor: 'rgb(0, 255, 0)',
      pointBackgroundColor: 'rgb(0, 255, 0)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(0, 255, 0,.8)'
    }


  ];


  labels: Array<any> = [];
  public lineChartLegend: boolean = true;
  public lineChartType: string = 'line';
  public barChartType: string = 'bar';
  public horizontalBarChartType: string = 'horizontalBar';
  public barChartLegend: boolean = true;
  public barChartEsgLegend: boolean = false;

  getEsgBarChartData(selectedCompanyList) {
    for (let selectedCompany of selectedCompanyList) {

      this.esgBarData.push(selectedCompany.overallEsgQuotient);
      this.esgBarLabel.push(selectedCompany.companyName);
      if (selectedCompany.esgColorStatus == 3)
        this.esgBarColor.push("#34a853");
      else if (selectedCompany.esgColorStatus == 2)
        this.esgBarColor.push("#f39700");
      else
        this.esgBarColor.push("#ea4335");
    }
    this.barChartOptEsg = {
      scaleShowVerticalLines: true,
      responsive: true,
      animation: {
        onComplete: function () {
          var chartInstance = this.chart, ctx = chartInstance.ctx;
          ctx.fillStyle = '#000';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';
          this.data.datasets.forEach(function (datasets, i) {
            var meta = chartInstance.controller.getDatasetMeta(i);
            meta.data.forEach(function (bar, index) {
              var data = datasets.data[index];
              ctx.fillText(data, bar._model.x -15, bar._model.y + 6);
            })
          })
        }
      },
      scales: {
        xAxes: [{

          display: true,
          gridLines: {
            display: false,
            drawTicks: true,
          },
          scaleLabel: {
            display: true,
            labelString: 'Company'
          },
          ticks: {
            beginAtZero: true,
            callback: function (label, index, labels) {
              if (/\s/.test(label)) {
                return label.split(" ");
              } else {
                return label;
              }
            }
          }
        }],
        yAxes: [{
          display: true,
          barPercentage: 0.4,
          barThickness: 25,
          maxBarThickness: 25,
          minBarLength: 6,
          gridLines: {
            display: false,
            drawTicks: true
          },
          scaleLabel: {
            display: true,
            labelString: 'ESG'
          },
          ticks: {
            beginAtZero: true,
            steps: 50,
            stepValue: 0.02,
            min: 0
          }
        }]
      },
      title: {
        display: false,
        text: 'chart'
      }
    };

    this.barChartColorsForEsg = [{ backgroundColor: this.esgBarColor }];
    this.barChartLabelsEsg = this.esgBarLabel;
    this.barChartDataEsg = [{ 'dataSet': [{ data: this.esgBarData }] }];

  }
  getCompanyTransChart(selectedCompanyList) {

    for (let selectedCompany of selectedCompanyList) {
      this.activeTransData.push(selectedCompany.noActiveTransactions);
      this.closedTransData.push(selectedCompany.noClosedTransactions);
      this.transLabel.push(selectedCompany.companyName);
    }
    this.activeBarChartOptEsg = {
      scaleShowVerticalLines: true,
      responsive: true,
      legend: {
        display: true,
        position: "top",
        labels: {
          boxWidth: 1
        }
      },
      animation: {
        onComplete: function () {
          var chartInstance = this.chart, ctx = chartInstance.ctx;
          ctx.fillStyle = '#000';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';
          this.data.datasets.forEach(function (datasets, i) {
            var meta = chartInstance.controller.getDatasetMeta(i);
            meta.data.forEach(function (bar, index) {
              var data = datasets.data[index];
              ctx.fillText(data, bar._model.x, bar._model.y + 2);
            })
          })
        }
      },
      scales: {
        xAxes: [{
          display: true,
          barPercentage: 1,
          barThickness: 25,
          maxBarThickness: 25,
          minBarLength: 15,
          gridLines: {
            display: false,
            drawTicks: true,
          },
          scaleLabel: {
            display: true,
            labelString: 'Company'
          },
          ticks: {
            callback: function (label, index, labels) {
              if (/\s/.test(label)) {
                return label.split(" ");
              } else {
                return label;
              }
            }
          }
        }],
        yAxes: [{
          display: true,
          gridLines: {
            display: false,
            drawTicks: true
          },
          scaleLabel: {
            display: true,
            labelString: "Transactions"
          },
          ticks: {
            beginAtZero: true,
            steps: 10,
            stepValue: 5,
            min: 0
          }
        }]
      },
      title: {
        display: false,
        text: 'chart'
      }
    };

    this.transBarChartLabels = this.transLabel;
    this.transBarChartData = [{ 'dataSet': [{ label: 'Active', data: this.activeTransData }, { label: 'Closed', data: this.closedTransData }] }];



  }

  getTransAmtChart(selectedCompanyList) {

    for (let selectedCompany of selectedCompanyList) {
      this.presentTransData.push(selectedCompany.activeOverallFunding);
      this.pastTransData.push(selectedCompany.pastOverallFunding);
      this.presentPastLabel.push(selectedCompany.companyName);
    }

    this.presentPastChartOptEsg = {
      scaleShowVerticalLines: true,
      responsive: true,
      legend: {
        display: true,
        position: "top",
        labels: {
          boxWidth: 1
        }
      },
      animation: {
        onComplete: function () {
          var chartInstance = this.chart, ctx = chartInstance.ctx;
          ctx.fillStyle = '#000';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';
          this.data.datasets.forEach(function (datasets, i) {
            var meta = chartInstance.controller.getDatasetMeta(i);
            meta.data.forEach(function (bar, index) {
              var data = datasets.data[index];
              ctx.fillText(data, bar._model.x, bar._model.y + 2);
            })
          })
        }
      },
      scales: {
        xAxes: [{
          display: true,
          barPercentage: 1,
          barThickness: 25,
          maxBarThickness: 25,
          minBarLength: 15,
          gridLines: {
            display: false,
            drawTicks: true,
          },
          scaleLabel: {
            display: true,
            labelString: 'Company'
          },
          ticks: {
            callback: function (label, index, labels) {
              if (/\s/.test(label)) {
                return label.split(" ");
              } else {
                return label;
              }
            }
          }
        }],
        yAxes: [{
          display: true,
          gridLines: {
            display: false,
            drawTicks: true
          },
          scaleLabel: {
            display: true,
            labelString: "Transaction Amount (" + this.currency + ")"
          },
          ticks: {
            beginAtZero: true,
            steps: 10,
            stepValue: 5,
            min: 0
          }
        }]
      },
      title: {
        display: false,
        text: 'chart'
      }
    };

    this.presentPastChartLabel = this.presentPastLabel;
    this.presentPastAmtData = [{ 'dataSet': [{ label: 'Present', data: this.presentTransData }, { label: 'Past', data: this.pastTransData }] }];

  }

  showIndicatorCharts(value, esgType) {
    if (value.length == 0)
      value = -1;
    if (this.barEnv == true || this.barSoc == true || this.barGov == true) {
      this.chartTypeFlag = 1;
    }
    else {
      this.chartTypeFlag = 2;
    }

    this._peerCompanyService.getPeerCompanySelectedIndicators(this.peer_company_id, esgType, value, this.chartTypeFlag).subscribe((selectedIndicatorList) => {
      if (esgType == 1) {
        this.envSelectedId = value;
        this.environmentIndicators = selectedIndicatorList;
        if (this.chartTypeFlag == 1)
          this.getAllIndChartData(this.environmentIndicators, 1);
        else
          this.getIndLineData(this.environmentIndicators, 1);
      }
      else if (esgType == 2) {
        this.socSelectedId = value;
        this.socialIndicators = selectedIndicatorList;
        if (this.chartTypeFlag == 1)
          this.getAllIndChartData(this.socialIndicators, 2);
        else
          this.getIndLineData(this.socialIndicators, 2);
      }
      else if (esgType == 3) {
        this.govSelectedId = value;
        this.governanceIndicators = selectedIndicatorList;
        if (this.chartTypeFlag == 1)
          this.getAllIndChartData(this.governanceIndicators, 3);
        else
          this.getIndLineData(this.governanceIndicators, 3);
      }
    })
  }

  getAllIndChartData(selectedIndicatorList, esgType) {

    for (let selectedIndList of selectedIndicatorList) {
      var actual = new Array();
      var companyName = new Array();
      if (selectedIndList.chartAxesVO != null) {
        let yAxisLabel = 'Rating Value';
        if (selectedIndList.unitOfMeasurement != null && selectedIndList.unitOfMeasurement.length > 0) {
          yAxisLabel = selectedIndList.unitOfMeasurement;
        }
        this.indBarChartOpt[selectedIndList.id] = {
          scaleShowVerticalLines: true,
          responsive: true,
          animation: false,
          scales: {
            xAxes: [{
              display: true,
              barPercentage: 0.3,
              gridLines: {
                display: false,
                drawTicks: false,
              },
              scaleLabel: {
                display: true,
                labelString: 'Company'
              },
              ticks: {
                callback: function (label, index, labels) {
                  if (/\s/.test(label)) {
                    return label.split(" ");
                  } else {
                    return label;
                  }
                }
              }
            }],
            yAxes: [{
              display: true,
              gridLines: {
                display: false,
                drawTicks: true
              },
              scaleLabel: {
                display: true,
                labelString: yAxisLabel//selectedIndList.unitOfMeasurement
              },
              ticks: {
                beginAtZero: true,
                steps: 10,
                stepValue: 5,
                min: 0
              }
            }]
          }

        };
        let flag = false;
        for (let actualData of selectedIndList.chartAxesVO) {
          if (actualData.actual) {
            actual.push(actualData.actual);
            flag = true;
          }
          else{
            actual.push(0);
          }
          companyName.push(actualData.companyName);
        }
        this.flag[selectedIndList.id] = flag;
        if (esgType == 1) {
          if (flag) {
            this.indBarChartLabels[selectedIndList.id] = companyName;
            this.indBarChartData[selectedIndList.id] = [{ 'dataSet': [{ data: actual }] }];
          }
        }
        else if (esgType == 2) {
          if (flag) {
            this.indBarSocChartLabels[selectedIndList.id] = companyName;
            this.indBarSocChartData[selectedIndList.id] = [{ 'dataSet': [{ data: actual }] }];
          }
        }
        else if (esgType == 3) {
          if (flag) {
            this.indBarGovChartLabels[selectedIndList.id] = companyName;
            this.indBarGovChartData[selectedIndList.id] = [{ 'dataSet': [{ data: actual }] }];
          }
        }
      }
    }
  }

  getIndLineData(selectedIndicatorList, esgType) {
    this.alllineMapList = [];
    for (let selectedIndList of selectedIndicatorList) {
      // if(typeof selectedIndList.chartAxesVO.yearlist!='undefined' && selectedIndList.chartAxesVO.yearlist ){
      let yAxisLabel = 'Rating Value';
      if (selectedIndList.unitOfMeasurement != null && selectedIndList.unitOfMeasurement.length > 0) {
        yAxisLabel = selectedIndList.unitOfMeasurement;
      }
      this.linechartOptions[selectedIndList.id] = {
        responsive: true,
        legend: {
          display: true,
          position: "top",
          labels: {
            boxWidth: 2
          }
        },
        scales: {
          xAxes: [{
            display: true,
            gridLines: {
              display: false,
              drawTicks: true,
            },
            scaleLabel: {
              display: true,
              labelString: 'Year'
            },
            ticks: {
              callback: function (label, index, labels) {
                if (/\s/.test(label)) {
                  return label.split(" ");
                } else {
                  return label;
                }
              }
            }
          }],
          yAxes: [{
            display: true,
            gridLines: {
              display: false,
              drawTicks: true
            },
            scaleLabel: {
              display: true,
              labelString: yAxisLabel//selectedIndList.unitOfMeasurement
            },
            ticks: {
              beginAtZero: true,
              steps: 10,
              stepValue: 5,
              min: 0
            }
          }],


        }
      };

      for (let lineData of selectedIndList.chartAxesVO) {

        this.yearList = lineData.yearList.sort();
        this.companyMap = lineData.lineChartData;
        this.lineMapList = [];
        Object.keys(lineData.lineChartData).map(key => {
          this.lineMapList.push({ data: this.companyMap[key], label: key });
        });

        if (esgType == 1) {
          this.indLineChartLabels[selectedIndList.id] = this.yearList;
          this.indLineChartData[selectedIndList.id] = this.lineMapList;
        }
        else if (esgType == 2) {
          this.indLineSocChartLabels[selectedIndList.id] = this.yearList;
          this.indLineSocChartData[selectedIndList.id] = this.lineMapList;
        }
        else if (esgType == 3) {
          this.indLineGovChartLabels[selectedIndList.id] = this.yearList;
          this.indLineGovChartData[selectedIndList.id] = this.lineMapList;
        }


      }
    }
  }

  goToClick(id: any) {
    this.companyDataService.getCompanyRatingAgencyDetails(id).subscribe((result)=>{
      let companyRegionNames;
      let companySectorNames;
      let companyCountryNames;
      let companySubSectorNames;
      for (let companyParameters of result.parameterVO) {
        var str = companyParameters.parameterList; 
        str = str.replace(/"/g, '');
        str = str.replace(/{/g, '');
        str = str.replace(/}/g, '');
        if(companyParameters.parameterName == "Region")
          companyRegionNames = str;
        if(companyParameters.parameterName == "Sector")
          companySectorNames = str;
        if(companyParameters.parameterName == "Country")
          companyCountryNames = str;
          if(companyParameters.parameterName == "Sub Sector")
          companySubSectorNames = str;
      }
      this._router.navigate(['/performanceManagement/dashboard/companyDetails'], {
        queryParams: {
          "name" :result.companyName,
          "id" :result.id,
          "image" :result.companyImgFileName,
          "sector" :companySectorNames,
          "subSector" :companySubSectorNames,
          "region" :companyRegionNames,
          "subRegion" :companyCountryNames,
          "environment" :result.environment,
          "social" :result.social,
          "governance" :result.governance,
          "overallEsgQuotient" :result.overallEsgQuotient,
          "esgColorStatus":result.esgColorStatus,
          "revenue" : result.revenue},
        skipLocationChange: true
      });
      });
  }

}
