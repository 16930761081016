import { Component, OnInit, Output, Input, ViewChild,EventEmitter } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { AddDatasourceComponent } from '../add-datasource/add-datasource.component';
import { DataQualityMasterService } from '../../data-quality-master.service';
import { AddEditSuccessComponent } from '../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';

@Component({
  selector: 'app-list-datasource',
  templateUrl: './list-datasource.component.html',
  styleUrls: ['./list-datasource.component.css']
})
export class ListDatasourceComponent implements OnInit {

  dataSourceList: any = [];
  displayedColumns: any = [];
  jsonArray: any = [];
  
  dataSource: any = [];
  displayedCompanyColumns: any;
  originalColumnNames: any;
  displayedAnnualReturnColumns: any;
  originalAnnualReturnColumnNames: any;
  paginator: any;

  constructor(private dataQualityMasterService: DataQualityMasterService, private addEditSuccessComponent: AddEditSuccessComponent, 
    private addDatasourceComponent: AddDatasourceComponent) { }

  @Input() set sourceList(val) {
    this.dataSourceList = val;
    this.fetchDataTable(this.dataSourceList);
  }
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  @Output() dataQltySource = new EventEmitter();

  ngOnInit() {
  }
  
  fetchDataTable(dataList) {
    this.displayedColumns = [];
    this.jsonArray = [];
    var count = 0;
    for (let i of dataList) {
      var newArray = {};
      newArray['sno'] = ++count;
      newArray['id'] = i.id;
      newArray['dataQualityLevel'] = i.constantsVO.value;
      newArray['dataSource'] = i.dataSource;
      newArray['score'] = i.score;
      newArray['constantsVO'] = i.constantsVO;
      this.jsonArray.push(newArray);
    }
    this.displayedColumns = ['Sno', 'DataQualityLevel', 'DataSource', 'Score', 'Actions']
    this.dataSource = new MatTableDataSource(this.jsonArray);
  }

  editDataSource(dataSourceObj) {
    let dataSourceObjList = [];
    dataSourceObjList.push({
        'id': dataSourceObj.id,
        'dataQualityLevels': dataSourceObj.constantsVO,
        'dataSource' : dataSourceObj.dataSource
      })
     this.dataQltySource.emit({
      dataSourceList: dataSourceObjList
     })
  }

  deleteDataSource(dataSourceId) {
    let message;
    message = "You Want To Delete Data Source Details";
    this.addEditSuccessComponent.showConfirmationSwal(message, "Yes", "No").then((isConfirm) => {
      if (isConfirm.value) {
        this.dataQualityMasterService.deleteDataSource(dataSourceId).subscribe((data) => {
          message = 'Deleted Data Source Details';
          this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
            this.addDatasourceComponent.resetSourceForm();
            this.addDatasourceComponent.fetchSourceList();
          });
        }, (error) => {

        });
      }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;

  }
}
