import { Component, OnInit } from '@angular/core';
import { FundDashboardService } from '../../fund-dashboard.service';
import { ReportConstantsComponent } from '../../../report-constants/report-constants.component';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as d3 from 'd3';
import { CompanyService } from '../../../CompanyDashboard/company.service';
@Component({
  selector: 'app-fund-view-drilldown',
  templateUrl: './fund-view-drilldown.component.html',
  styleUrls: ['./fund-view-drilldown.component.css']
})
export class FundViewDrilldownComponent implements OnInit {

  public doughnutChartType = 'doughnut';
  public doughnutData: any;
  public chartColors: any;
  public doughnutInvestedShareData: any;
  public chartColorInvestedShare: any;
  public chartOptions: any;
  public topBottomFlag: boolean = true;
  public displayTop5Class: any = 'topbottom-underline-cls chart-header-text cursor-highlight';
  public displayBottom5Class: any = 'topbottom-no-underline-cls chart-header-text cursor-highlight';
  public currency: string;
  public fundData;
  public topArr = [];
  public btmArr = [];
  public companyBoxData = [];
  public fundId: number;
  public fundDetails: any = [];
  public averageEsgTop5: number = 0;
  public averageEsgBottom5: number = 0;
  public isAnnualReturnAvailable: boolean = false;
  public isESGHistoryAvailable: boolean = false;
  public lineChartLegend: boolean = false;
  public lineChartType: string = 'line';
  public barChartType: string = 'bar';
  public barChartLegend: boolean = false;
  public annualReturnChartOption: any;
  public annualReturnChartLabel: any;
  public annualReturnChartData: any;
  public esgHistoryChartOption: any;
  public esgHistoryChartLabels: any;
  public esgHistoryChartData: any;
  public gaugemap: any = {};
  public environmentESGGauge: any;
  public socialESGGauge: any;
  public governanceESGGauge: any;
  public rangepointer: any = 1;
  public overallESGChartColor: Array<any> = [];
  public annualReturnChartColor: Array<any> = [];
  public esgHistoryChartColor: Array<any> = [];
  public emptyTopBoxesArr = [];
  public emptyBottomBoxesArr = [];
  public overallESGData: any = [];
  public lastUpdatedFundDate: any;
  constructor(private companyDataService : CompanyService,private fundDashboardService: FundDashboardService, private activatedRoute: ActivatedRoute, private _router: Router) {
    let fundIdVal = this.activatedRoute.snapshot.queryParamMap.get('fundId');
    this.fundId = +fundIdVal;
    this.currency = ReportConstantsComponent.CURRENCY;
  }

  ngOnInit() {
    this.getFundDetails();
  }

  getFundDetails() {
    this.fundDashboardService.getFundDrilldownDetails(this.fundId).subscribe(result => {
      this.fundData = result;
      this.lastUpdatedFundDate = this.formatDate(this.fundData.lastUpdatedFundDate);
      this.fundDetails['name'] = this.fundData['name'];
      this.fundDetails['isinCode'] = this.fundData['isinCode'];
      this.fundDetails['latestESGQuotient'] = Number(this.fundData['latestESGQuotient']);
      this.fundDetails['investmentAmount'] = this.fundData['investmentAmount'];
      this.fundDetails['latestAnnualReturn'] = this.fundData['latestAnnualReturn'];
      this.fundDetails['companyCount'] = this.fundData['companyCount'];

      if (this.fundDetails['latestESGQuotient'] > 1) { this.fundDetails['latestESGQuotient'] = 1; }
      this.overallESGData = [this.fundDetails['latestESGQuotient'], (1 - this.fundDetails['latestESGQuotient'])];
      this.overallESGChartColor = this.chartColorESG(this.fundDetails['latestESGQuotient']);

      this.chartColorInvestedShare = [{ backgroundColor: ["#01d4e8", "#dddddd"] }];

      if (this.fundDetails['latestAnnualReturn'] >= 0 && this.fundDetails['latestAnnualReturn'] <= 8) {
        this.rangepointer = 1;
      }
      else if (this.fundDetails['latestAnnualReturn'] > 8 && this.fundDetails['latestAnnualReturn'] <= 16) {
        this.rangepointer = 2;
      }
      else if (this.fundDetails['latestAnnualReturn'] > 16 && this.fundDetails['latestAnnualReturn'] <= 24) {
        this.rangepointer = 3;
      }
      else if (this.fundDetails['latestAnnualReturn'] >= 25) {
        this.rangepointer = 4;
      }

      this.environmentESGGauge = Number(this.fundData['latestEnvironmentQuotient']);
      this.socialESGGauge = Number(this.fundData['latestSocialQuotient']);
      this.governanceESGGauge = Number(this.fundData['latestGovernanceQuotient']);

      if (this.fundData['companyVOList'] != null && this.fundData['companyVOList'].length > 0) {
        let topValArr = this.fundData['companyVOList'].slice(0, 5);
        let totalCountryCount = this.fundData['companyVOList'].length;
        let fundDashboardArray = [];
        let top5Avg = 0;
        let topValArrCount = topValArr.length;
        if (topValArrCount < 5) {
          let blankTopBoxCount = 5 - topValArrCount;
          for (let i = topValArrCount + 1; i <= 5; i++) {
            this.emptyTopBoxesArr.push(i);
          }
        }
        for (let top of topValArr) {
          var tops = {};
          tops['name'] = top.companyName;
          tops['esg'] = Number(top.overallEsgQuotient);
          tops['holding'] = (top.investmentPercentage) + "%";
          if (top.overallEsgQuotient >= 0.8) {
            tops['esgColor'] = "esg-color-step-1";
          }
          else if (top.overallEsgQuotient >= 0.6 && top.overallEsgQuotient <= 0.79) {
            tops['esgColor'] = "esg-color-step-2";
          }
          else if (top.overallEsgQuotient >= 0.4 && top.overallEsgQuotient <= 0.59) {
            tops['esgColor'] = "esg-color-step-3";
          }
          else if (top.overallEsgQuotient >= 0.2 && top.overallEsgQuotient <= 0.39) {
            tops['esgColor'] = "esg-color-step-4";
          }
          else if (top.overallEsgQuotient >= 0 && top.overallEsgQuotient <= 0.19) {
            tops['esgColor'] = "esg-color-step-5";
          }
          this.topArr.push(tops);
          top5Avg += Number(top.overallEsgQuotient);
        }


        let reverseCal = totalCountryCount - 5;
        if (reverseCal < 0) {
          reverseCal = 0;
        }
        let reverseFund = this.fundData['companyVOList'].slice(reverseCal, totalCountryCount);
        let bottom5Avg = 0;
        let bottomValArrCount = reverseFund.length;
        if (bottomValArrCount < 5) {
          let blankBottomBoxCount = 5 - bottomValArrCount;
          for (let i = bottomValArrCount + 1; i <= 5; i++) {
            this.emptyBottomBoxesArr.push(i);
          }
        }
        for (let btm of reverseFund) {
          var btms = {};
          btms['name'] = btm.companyName;
          btms['esg'] = Number(btm.overallEsgQuotient);
          btms['holding'] = (btm.investmentPercentage) + "%";
          if (btm.overallEsgQuotient >= 0.8) {
            btms['esgColor'] = "esg-color-step-1";
          }
          else if (btm.overallEsgQuotient >= 0.6 && btm.overallEsgQuotient <= 0.79) {
            btms['esgColor'] = "esg-color-step-2";
          }
          else if (btm.overallEsgQuotient >= 0.4 && btm.overallEsgQuotient <= 0.59) {
            btms['esgColor'] = "esg-color-step-3";
          }
          else if (btm.overallEsgQuotient >= 0.2 && btm.overallEsgQuotient <= 0.39) {
            btms['esgColor'] = "esg-color-step-4";
          }
          else if (btm.overallEsgQuotient >= 0 && btm.overallEsgQuotient <= 0.19) {
            btms['esgColor'] = "esg-color-step-5";
          }
          this.btmArr.push(btms);
          bottom5Avg += Number(btm.overallEsgQuotient);
        }
        if (this.topArr.length > 0) {
          this.averageEsgTop5 = Number(top5Avg) / Number(this.topArr.length);
        }
        if (this.btmArr.length > 0) {
          this.averageEsgBottom5 = Number(bottom5Avg) / Number(this.btmArr.length);
        }
        this.getCompanyList();
      }
      if (this.fundData['fundYearWiseDetailsVOList'] != null && this.fundData['fundYearWiseDetailsVOList'].length > 0) {
        this.getchartData(this.fundData['fundYearWiseDetailsVOList']);
        this.isAnnualReturnAvailable = true;
        this.isESGHistoryAvailable = true;
      }
      this.draw();
      document.getElementById("toggleE").classList.add('selectedTab');
    });
  }

  getchartData(fundYearWiseDetailsVOList) {

    var annualReturn = new Array();
    var year = new Array();
    var annualRtrnColor = new Array();
    var esgChartColor = new Array();
    var esgOverallQuotient = new Array();

    for (let fundYearData of fundYearWiseDetailsVOList) {
      annualReturn.push(fundYearData.annualReturn);
      year.push(fundYearData.year);
      esgOverallQuotient.push(fundYearData.averageESGQuotient);
       // if(fundYearData.year=='2019'){
      //   esgOverallQuotient.push(this.fundDetails['latestESGQuotient']);
      // }
      //if(fundYearData.year=='2018'){
       // esgOverallQuotient.push(fundYearData.averageESGQuotient);
      // }
      // else{
      //   esgOverallQuotient.push(0);

      // }
      
      if (fundYearData.annualReturn >= 0 && fundYearData.annualReturn <= 8) {
        annualRtrnColor.push("#dab96a");
      }
      else if (fundYearData.annualReturn > 8 && fundYearData.annualReturn <= 16) {
        annualRtrnColor.push("#ebe1a6");
      }
      else if (fundYearData.annualReturn > 16 && fundYearData.annualReturn <= 24) {
        annualRtrnColor.push("#9aa0bd");
      }
      else if (fundYearData.annualReturn >= 25) {
        annualRtrnColor.push("#8499e9");
      }

      if (fundYearData.averageESGQuotient >= 0 && fundYearData.averageESGQuotient <= 0.19) {
        esgChartColor.push("#000000");
      }
      else if (fundYearData.averageESGQuotient >= 0.2 && fundYearData.averageESGQuotient <= 0.39) {
        esgChartColor.push("#595959");
      }
      else if (fundYearData.averageESGQuotient >= 0.4 && fundYearData.averageESGQuotient <= 0.59) {
        esgChartColor.push("#bfbfbf");
      }
      else if (fundYearData.averageESGQuotient >= 0.6 && fundYearData.averageESGQuotient <= 0.79) {
        esgChartColor.push("#93e45b");
      }
      else if (fundYearData.averageESGQuotient >= 0.8 && fundYearData.averageESGQuotient <= 1) {
        esgChartColor.push("#3e7d14");
      }
    }

    this.annualReturnChartOption = {
      responsive: true,
      animation: false,
      scales: {
        xAxes: [{
          display: true,
          barPercentage: 0.3,
          gridLines: {
            display: false,
            drawTicks: true,
          },
          scaleLabel: {
            display: true,
            labelString: 'Year'
          },
        }],
        yAxes: [{
          display: true,
          gridLines: {
            display: false,
            drawTicks: true
          },
          scaleLabel: {
            display: true,
            labelString: 'Annual Return (%)'
          },
          ticks: {
            beginAtZero: true,
            steps: 10,
            stepValue: 5,
            min: 0,
          }
        }]
      },
      title: {
        display: false,
        text: 'Annual Return (%)'
      }
    };

    this.annualReturnChartLabel = year;
    this.annualReturnChartColor = [{ backgroundColor: annualRtrnColor }];
    this.annualReturnChartData = [{ 'dataSet': [{ data: annualReturn }] }];

    this.esgHistoryChartOption = {
      responsive: true,
      animation: false,
      scales: {
        xAxes: [{
          display: true,
          gridLines: {
            display: false,
            drawTicks: true,
          },
          scaleLabel: {
            display: true,
            labelString: 'Year'
          },
        }],
        yAxes: [{
          display: true,
          gridLines: {
            display: false,
            drawTicks: true
          },
          scaleLabel: {
            display: true,
            labelString: 'ESG'
          },
          ticks: {
            beginAtZero: true,
            steps: 10,
            stepValue: 5,
            min: 0
          }
        }]
      },
      title: {
        display: false,
        text: 'Chart'
      }
    };
    this.esgHistoryChartLabels = year;
    this.esgHistoryChartColor = [{
      backgroundColor: 'rgb(217,241,226)',
      borderColor: 'rgb(64,187,110)',
      pointBackgroundColor: esgChartColor,
      pointBorderColor: 'rgb(255,255,255)',
      pointHoverBackgroundColor: esgChartColor,
      pointHoverBorderColor: esgChartColor,
      pointRadius: 7,
    }];
    this.esgHistoryChartData = [{ 'dataSet': [{ data: esgOverallQuotient }] }];
  }

  getCompanyList() {
    this.companyBoxData = [];
    let count = 1;
    for (let companyDetails of this.fundData['companyVOList']) {
      var company = {};
      company['id'] = companyDetails.id;
      company['companyName'] = companyDetails.companyName;
      company['investmentAmount'] = companyDetails.investmentAmount;
      company['investmentPercentage'] = companyDetails.investmentPercentage;
      company['overallEsgQuotient'] = companyDetails.overallEsgQuotient;
      company['companyImgFileName'] = companyDetails.companyImgFileName;
      company['columnNo'] = count;
      company['sector'] = '-';
      for (let companyParameters of companyDetails.parameterVO) {
        var str = companyParameters.parameterList;
        str = str.replace(/"/g, '');
        str = str.replace(/{/g, '');
        str = str.replace(/}/g, '');
        if (companyParameters.parameterName == "Sector") {
          company['sector'] = str;
        }
      }
      this.companyBoxData.push(company);
      count++;
    }
  }

  goToCompany(id) {
    this.companyDataService.getCompanyRatingAgencyDetails(id).subscribe((result)=>{
      let companyRegionNames;
      let companySectorNames;
      let companyCountryNames;
      let companySubSectorNames;
      for (let companyParameters of result.parameterVO) {
        var str = companyParameters.parameterList; 
        str = str.replace(/"/g, '');
        str = str.replace(/{/g, '');
        str = str.replace(/}/g, '');
        if(companyParameters.parameterName == "Region")
          companyRegionNames = str;
        if(companyParameters.parameterName == "Sector")
          companySectorNames = str;
        if(companyParameters.parameterName == "Country")
          companyCountryNames = str;
          if(companyParameters.parameterName == "Sub Sector")
          companySubSectorNames = str;
      }
      this._router.navigate(['/performanceManagement/dashboard/companyDetails'], {
        queryParams: {
          "name" :result.companyName,
          "id" :result.id,
          "image" :result.companyImgFileName,
          "sector" :companySectorNames,
          "subSector" :companySubSectorNames,
          "region" :companyRegionNames,
          "subRegion" :companyCountryNames,
          "environment" :result.environment,
          "social" :result.social,
          "governance" :result.governance,
          "overallEsgQuotient" :result.overallEsgQuotient,
          "esgColorStatus":result.esgColorStatus,
          "revenue" : result.revenue},
        skipLocationChange: true
      });
      });
  }

  addRemovedUnderline(flag) {
    if (flag == 1) {
      this.topBottomFlag = true;  //top is set
      this.displayTop5Class = 'topbottom-underline-cls chart-header-text cursor-highlight';
      this.displayBottom5Class = 'topbottom-no-underline-cls chart-header-text cursor-highlight';
    }
    else {
      this.topBottomFlag = false;  //bottom is set
      this.displayTop5Class = 'topbottom-no-underline-cls chart-header-text cursor-highlight';
      this.displayBottom5Class = 'topbottom-underline-cls chart-header-text cursor-highlight';
    }

  }

  toggleESG(flagVal) {
    this.fundDashboardService.getFundDrilldownDetails(this.fundId).subscribe(result => {
      this.fundData = result;
      let fundDetails = [];
      this.topArr = [];
      if (flagVal == 1) {
        document.getElementById("toggleE").classList.add('selectedTab');
        document.getElementById("toggleH").classList.remove('selectedTab');
        fundDetails = this.fundData['companyVOList'].sort((a, b) => parseInt(b.overallEsgQuotient) - parseInt(a.overallEsgQuotient));
      }
      else {
        document.getElementById("toggleE").classList.remove('selectedTab');
        document.getElementById("toggleH").classList.add('selectedTab');
        fundDetails = this.fundData['companyVOList'].sort((a, b) => parseInt(b.investmentPercentage) - parseInt(a.investmentPercentage));
      }
      let topValArr = fundDetails.slice(0, 5);
      let totalCountryCount = this.fundData['companyVOList'].length;
      let fundDashboardArray = [];
      let top5Avg = 0;
      let topValArrCount = topValArr.length;
      for (let top of topValArr) {
        var tops = {};
        tops['name'] = top.companyName;
        tops['esg'] = Number(top.overallEsgQuotient);
        tops['holding'] = (top.investmentPercentage) + "%";
        if (top.overallEsgQuotient >= 0.8) {
          tops['esgColor'] = "esg-color-step-1";
        }
        else if (top.overallEsgQuotient >= 0.6 && top.overallEsgQuotient <= 0.79) {
          tops['esgColor'] = "esg-color-step-2";
        }
        else if (top.overallEsgQuotient >= 0.4 && top.overallEsgQuotient <= 0.59) {
          tops['esgColor'] = "esg-color-step-3";
        }
        else if (top.overallEsgQuotient >= 0.2 && top.overallEsgQuotient <= 0.39) {
          tops['esgColor'] = "esg-color-step-4";
        }
        else if (top.overallEsgQuotient >= 0 && top.overallEsgQuotient <= 0.19) {
          tops['esgColor'] = "esg-color-step-5";
        }
        this.topArr.push(tops);
        top5Avg += Number(top.overallEsgQuotient);
      }
    });

  }
  public chartHovered(e: any): void {
  }

  OptionsESG() {
    var per = 60;
    this.chartOptions = {
      responsive: true,
      cutoutPercentage: per,
      animation: false,
    };
    return this.chartOptions;
  }

  doughnutChartESGData(esg: number) {
    if (esg > 1)
      esg = 1;
    this.doughnutData = [esg, (1 - esg)];
    return this.doughnutData;
  }

  doughnutChartInvestmentData(investmentShare: number) {
    if (investmentShare > 100) {
      investmentShare = 100;
    }
    this.doughnutInvestedShareData = [investmentShare, (100 - investmentShare)];
    return this.doughnutInvestedShareData;
  }

  chartColorESG(esg: number) {
    if (esg >= 0.8) {
      this.chartColors = [{ backgroundColor: ["#3e7d14", "#ebebeb"] }];
    }
    else if (esg >= 0.6 && esg <= 0.79) {
      this.chartColors = [{ backgroundColor: ["#93e45b", "#ebebeb"] }];
    }
    else if (esg >= 0.4 && esg <= 0.59) {
      this.chartColors = [{ backgroundColor: ["#bfbfbf", "#ebebeb"] }];
    }
    else if (esg >= 0.2 && esg <= 0.39) {
      this.chartColors = [{ backgroundColor: ["#595959", "#ebebeb"] }];
    }
    else if (esg >= 0 && esg <= 0.19) {
      this.chartColors = [{ backgroundColor: ["#000000", "#ebebeb"] }];
    }
    return this.chartColors;
  }

  draw() {
    var self = this;
    var gauge = function (container, configuration) {

      var config = {
        size: 150,
        clipWidth: 100,
        clipHeight: 90,
        ringInset: 17,
        ringWidth: 17,

        pointerWidth: 9,
        pointerTailLength: 5,
        pointerHeadLengthPercent: 0.6,

        minValue: 0,
        maxValue: 1,

        minAngle: -90,
        maxAngle: 92,

        transitionMs: 850,

        majorTicks: 5,
        labelFormat: d3.format('.1f'),
        labelInset: 10,
        arcColorFn: d3.scaleOrdinal().range(['#000000', '#595959', '#bfbfbf', '#93e45b', '#3e7d14'])

      };
      var range = undefined;
      var r = undefined;
      var pointerHeadLength = undefined;
      var value = 0;

      var svg = undefined;
      var arc = undefined;
      var scale = undefined;
      var ticks = undefined;
      var tickData = undefined;
      var pointer = undefined;

      var donut = d3.pie();

      function deg2rad(deg) {
        return deg * Math.PI / 180;
      }

      function newAngle(d) {
        var ratio = scale(d);
        var newAngle = config.minAngle + (ratio * range);
        return newAngle;
      }

      function configure(configuration) {
        var prop = undefined;
        for (prop in configuration) {
          config[prop] = configuration[prop];
        }

        range = config.maxAngle - config.minAngle;
        r = config.size / 2;
        pointerHeadLength = Math.round(r * config.pointerHeadLengthPercent);

        // a linear scale this.gaugemap maps domain values to a percent from 0..1
        scale = d3.scaleLinear()
          .range([0, 1])
          .domain([config.minValue, config.maxValue]);

        ticks = scale.ticks(config.majorTicks);
        tickData = d3.range(config.majorTicks).map(function () { return 1 / config.majorTicks; });

        arc = d3.arc()
          .innerRadius(r - config.ringWidth - config.ringInset)
          .outerRadius(r - config.ringInset)
          .startAngle(function (d, i) {
            var ratio = d * i;
            if (i == 0)
              ratio = 0;
            else if (i == 1)
              ratio = 0.20
            else if (i == 2)
              ratio = 0.40
            else if (i == 3)
              ratio = 0.60
            else if (i == 4)
              ratio = 0.80
            return deg2rad(config.minAngle + (ratio * range));
          })
          .endAngle(function (d, i) {
            var ratio = d * (i + 1);
            if (i == 0)
              ratio = 0.20
            else if (i == 1)
              ratio = 0.40
            else if (i == 2)
              ratio = 0.60
            else if (i == 3)
              ratio = 0.80
            else if (i == 4)
              ratio = 1
            return deg2rad(config.minAngle - 1 + (ratio * range));
          });
      }
      self.gaugemap.configure = configure;

      function centerTranslation() {
        return 'translate(' + r + ',' + r + ')';
      }

      function isRendered() {
        return (svg !== undefined);
      }
      self.gaugemap.isRendered = isRendered;

      function render(newValue) {
        svg = d3.select(container)
          .append('svg:svg')
          .attr('class', 'gauge')
          .attr('width', config.clipWidth)
          .attr('height', config.clipHeight);

        var centerTx = centerTranslation();

        var arcs = svg.append('g')
          .attr('class', 'arc')
          .attr('transform', centerTx);

        arcs.selectAll('path')
          .data(tickData)
          .enter().append('path')
          .attr('fill', function (d, i) {
            return config.arcColorFn(d * i);
          })
          .attr('d', arc);

        var lg = svg.append('g')
          .attr('class', 'label')
          .attr('transform', centerTx);
        lg.selectAll('text')
          .data(ticks)
          .enter().append('text')
          .attr('transform', function (d) {
            var ratio = scale(d);
            var newAngle = config.minAngle + (ratio * range);
            return 'rotate(' + newAngle + ') translate(-10,' + (config.labelInset - r) + ')';
          })
          .text(config.labelFormat);

        var lineData = [[config.pointerWidth / 2, 0],
        [0, -pointerHeadLength],
        [-(config.pointerWidth / 2), 0],
        [0, config.pointerTailLength],
        [config.pointerWidth / 2, 0]];
        var pointerLine = d3.line().curve(d3.curveLinear)
        var pg = svg.append('g').data([lineData])
          .attr('class', 'pointer')
          .attr('transform', centerTx);

        pointer = pg.append('path')
          .attr('d', pointerLine/*function(d) { return pointerLine(d) +'Z';}*/)
          .attr('transform', 'rotate(' + config.minAngle + ')');

        update(newValue === undefined ? 0 : newValue);
      }
      self.gaugemap.render = render;
      function update(newValue, newConfiguration?) {
        if (newConfiguration !== undefined) {
          configure(newConfiguration);
        }
        var ratio = scale(newValue);
        var newAngle = config.minAngle + (ratio * range);
        pointer.transition()
          .duration(config.transitionMs)
          .ease(d3.easeElastic)
          .attr('transform', 'rotate(' + newAngle + ')');
      }
      self.gaugemap.update = update;

      configure(configuration);

      return self.gaugemap;
    };

    var epowerGauge = gauge('#e-power-gauge', {
      size: 150,
      clipWidth: 160,
      clipHeight: 90,
      ringWidth: 20,
      maxValue: 1,
      transitionMs: 10000,
    });
    epowerGauge.render(this.environmentESGGauge);

    var spowerGauge = gauge('#s-power-gauge', {
      size: 150,
      clipWidth: 160,
      clipHeight: 90,
      ringWidth: 20,
      maxValue: 1,
      transitionMs: 10000,
    });
    spowerGauge.render(this.socialESGGauge);

    var gpowerGauge = gauge('#g-power-gauge', {
      size: 150,
      clipWidth: 160,
      clipHeight: 90,
      ringWidth: 20,
      maxValue: 1,
      transitionMs: 10000,
    });
    gpowerGauge.render(this.governanceESGGauge);

  }

  formatDate(inputDate) {
    var pipe = new DatePipe('en-US'); // Use your own locale
    let newdate = new Date(inputDate).toISOString().split('T')[0];
    return pipe.transform(newdate, 'dd-MM-yyyy');

  }
  /* FOR DEMO CODE BELOW*/
  //for demo purpose - begin
  // constructor(public _router: Router) { }
  // ngOnInit() {
  // }
  // goToCompanyDemo() {
  //   this._router.navigate(['/companyReportDashboard']);
  // }
  // demo purpose - end
}