import { Component, OnInit,ViewChild } from '@angular/core';
import { AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Router, NavigationExtras } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-list-assessment',
  templateUrl: './list-assessment.component.html',
  styleUrls: ['./list-assessment.component.css']
})
export class ListAssessmentComponent implements OnInit {
  usersList:any;
  assesmentList:any;
  userId:number;
  assessmentjsonArray:any;
  dataSource: any;  
  displayedColumns: any;
  colIndex: number = 0;
  isVisible:any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
    @ViewChild('listAssessmentForm') listAssessmentForm: NgForm;
  constructor(private assessementTemlateMasterService:AssessmentTemplateMasterdataService,
    private router:Router) { }

  ngOnInit() {
    this.assessmentjsonArray = [];
    this.isVisible = false;
    this.assessementTemlateMasterService.getAlluserList().subscribe(userList=>{
    this.usersList  = userList;
    });
  }
  userChanged(userIdEntered){
    this.userId = userIdEntered;
  }
  getAssessmentsOfUser(){
  if(this.listAssessmentForm.valid && this.userId!=undefined)
    {
      this.assessementTemlateMasterService.getAssessmentsOfUser(this.userId).subscribe((data) => {
        console.log(data)
      this.assesmentList = data;
      this.dataSource = []; 
      var count = 0;
      this.assessmentjsonArray = []
        for(let assessment of this.assesmentList ){
            var assessmentjson = {};
            assessmentjson['Sno'] = ++count;
            assessmentjson['id'] = assessment.id;
            assessmentjson['initiatedBy'] = assessment.initiatedBy;
            assessmentjson['initiatedDate'] = assessment.initiatedDateString;
            assessmentjson['status'] = assessment.completedStatus;
            assessmentjson['companyId'] = assessment.companyVO.id;
            console.log(assessment.completedStatus)
            console.log(assessment.companyVO.companyName)
            if(assessment.companyVO){
              assessmentjson['transactionName'] = assessment.companyVO.companyName
              ;
            }
            else{
              assessmentjson['transactionName'] = '-';
            }
            if(assessment.panelVO){
              assessmentjson['panelName'] = assessment.panelVO.panelName;
            }
            else{
              assessmentjson['panelName'] = '-';
            }
            if(assessment.templateVO){
              assessmentjson['templateName'] = assessment.templateVO.templateName;
            }
            else{
              assessmentjson['templateName'] = '-';
            }
            this.assessmentjsonArray.push(assessmentjson);
          }
          this.displayedColumns = ['Sno', 'transactionName', 'panelName', 'templateName', 'initiatedBy', 'initiatedDate', 'Actions']
          this.dataSource = new MatTableDataSource(this.assessmentjsonArray);
          setTimeout(() => this.dataSource.paginator = this.paginator);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isVisible = true;
         
  });
   }
    else
    {
      this.listAssessmentForm.controls['userId'].markAsTouched();
      this.listAssessmentForm.controls['userId'].markAsDirty();

    }
  }
  getRecordAssessmentPageData(assessmentIds){
 this.router.navigate(['/recordAssessmentPageData/'+ assessmentIds+"/"+this.userId]); 
  // let navigationExtras: NavigationExtras = {
  //   queryParams: {"assessmentId" :assessmentIds,
  //                 "userId" : this.userId},
  //   skipLocationChange: true
  // };
  // let assessmentId=btoa(assessmentIds.toString())
  // let userId=btoa(this.userId.toString())
  //   this.router.navigate(['/recordAssessmentPageData/'+assessmentId+'/'+userId]);

  //this.router.navigate(['/recordAssessmentPageData'],navigationExtras)
  
   
  }

  listAssessmentForCompany(companyId)
  {
    console.log(companyId)
    this.router.navigate(['/assessmentListData/'+companyId]);
  }
  
  viewAssesmentPageData(assessmentId)
  {
   // this.router.navigate(['/viewRecordAssessmentData/'+ assessmentId+"/"+this.userId]);
    let navigationExtras: NavigationExtras = {
      queryParams: {"assessmentId" :assessmentId,
                    "userId" : this.userId},
      skipLocationChange: true
    };
      this.router.navigate(['/viewRecordAssessmentData'],navigationExtras);
  
  }
  
}
