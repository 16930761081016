import { Component, OnInit ,Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator } from '@angular/material';
import { MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import * as d3 from 'd3';
import * as topojson from 'topojson';
import { GpsDashbordComponent } from "../GpsDashbord/component/gps-dashbord/gps-dashbord.component";
import { CountryPopupComponent } from "../GpsDashbord/component/country-popup/country-popup.component";
import { GpsDashboardService } from "../GpsDashbord/gps-dashboard.service";
@Component({
  selector: 'app-world-map',
  templateUrl: './world-map.component.html',
  styleUrls: ['./world-map.component.css']
})
export class WorldMapComponent implements OnInit {

  public countryId;
  private showDetails;
  @Input() mapDataList:any;
  @Input() colorCodes:any;
  colorCodeList:any;
  private popupDetails:any;
  constructor(private http : HttpClient,private dialog: MatDialog , private gpsDashboardService : GpsDashboardService ) {

  }

  ngOnInit() {
    'use strict';
  const loadAndProcessData = () => 
    Promise
      .all([
        d3.json('/assets/110m.json')
      ])
      .then(([topoJSONdata]) => {
       let jsonData =this.mapDataList;
         const rowById = jsonData.reduce((accumulator, d) => {
          accumulator[d.iso_n3] = d;
          return accumulator;
        }, {});

        const countries = topojson.feature(topoJSONdata, topoJSONdata.objects.countries);
        
        countries.features.forEach(d => {
          Object.assign(d.properties, rowById[d.id]);
        });

        return countries;
      });
  
    const colorLegend = (selection, props) => {
      const {                      
        colorScale,                
        circleRadius,
        spacing,                   
        textOffset,
        backgroundRectWidth,
        onClick,
        selectedColorValue
      } = props;                   
      
      const backgroundRect = selection.selectAll('rect')
        .data([null]);             
      const n = colorScale.domain().length; 
      backgroundRect.enter().append('rect')
        .merge(backgroundRect)
          .attr('x', -circleRadius * 2)   
          .attr('y', -circleRadius * 2)   
          .attr('rx', circleRadius * 2)   
          .attr('width', backgroundRectWidth)
          .attr('height', spacing * n + circleRadius * 2) 
          .attr('fill', '#fff')
          .attr('opacity', 0.3);
  
      const groups = selection.selectAll('.tick')
        .data(colorScale.domain());
      const groupsEnter = groups
        .enter().append('g')
          .attr('class', 'tick');
      groupsEnter
        .merge(groups)
          .attr('transform', (d, i) =>  
            `translate(0, ${i * spacing})`  
          )
          .attr('opacity', d =>
            (!selectedColorValue || d === selectedColorValue)
              ? 0.8
              : 0.2
          )
          .on('click', d => onClick(
            d === selectedColorValue
              ? null
              : d
          ));
      groups.exit().remove();
      
      groupsEnter.append('circle')
        .merge(groups.select('circle')) 
          .attr('r', circleRadius)
          .attr('fill', colorScale);      
      
      groupsEnter.append('text')
        .merge(groups.select('text')) 
        
        .text(d => d.substring(2,25))
        .attr('dy', '0.32em')
        .attr('x', textOffset);
         //    .text(legendText)
        //  .text(d => d.properties.esg_desc)
        //   .attr('dy', '0.32em')
        //   .attr('x', textOffset);
    };
  
    const projection = d3.geoNaturalEarth1();
    const pathGenerator = d3.geoPath().projection(projection);
  
    const choroplethMap = (selection, props) => {
      const {
        features,
        colorScale,
        colorValue,
        selectedColorValue
      } = props;
      
      const gUpdate = selection.selectAll('g').data([null]);
      const gEnter = gUpdate.enter().append('g');
      const g = gUpdate.merge(gEnter);
      
      gEnter
        .append('path')
          .attr('class', 'world')
          .attr('fill',"#fff")
          .attr('d', pathGenerator({type: 'Sphere'}))
        .merge(gUpdate.select('.world'))
          .attr('opacity', selectedColorValue ? 0.05 : 0.8);
  
      selection.call(d3.zoom().on('zoom', () => {
        g.attr('transform', d3.event.transform);
      }));
      var esg = null;
      const countryPaths = g.selectAll('.country')
        .data(features);
      const countryPathsEnter = countryPaths
        .enter().append('path')
          .attr('class', 'country')
          .on('click', d => countryClick(
             esg =d.properties

          ));
      countryPaths
        .merge(countryPathsEnter)
          .attr('d', pathGenerator)
          .attr('fill', d => colorScale(colorValue(d)))
          .attr('opacity', d =>
            (!selectedColorValue || selectedColorValue === colorValue(d))
              ? 0.8
              : 0.1
          )
          .classed('highlighted', d =>
            selectedColorValue && selectedColorValue === colorValue(d)
          );
      
      countryPathsEnter.append('title')
          .text(d => d.properties.name + ': ' + d.properties.esg);
        
    };
  
    const svg = d3.select('svg');
    const choroplethMapG = svg.append('g');
    const colorLegendG = svg.append('g')
        .attr('transform', `translate(1000,310)`);
    const colorScale = d3.scaleOrdinal();
    const colorValue = d => d.properties.esg_desc;
    
    let selectedColorValue;
    let features;
    const onClick = d => {
      selectedColorValue = d;
      render();
    };
    const countryClick = d => {
      this.populateCountryDetails(d);
    }
    loadAndProcessData().then(countries => {
      features = countries.features;
      render();
    });
    const render = () => {
      colorScale
        .domain(features.map(colorValue))
        .domain(colorScale.domain().sort())
         .range(this.colorCodes);
      colorLegendG.call(colorLegend, {
        colorScale,
        circleRadius: 8,
        spacing: 20,
        textOffset: 12,
        backgroundRectWidth: 235,
        onClick,
        selectedColorValue
      });
      
      choroplethMapG.call(choroplethMap, {
        features,
        colorScale,
        colorValue,
        selectedColorValue
      });
    };
  }
  populateCountryDetails(countryDetails){
  this.countryId=countryDetails.id;
  this.gpsDashboardService.fetchCountryIndDetails(this.countryId).subscribe(data=>{
    this.popupDetails = data;
    this.showCountryPopup(this.popupDetails);
  });
  
  }
  showCountryPopup(countryData){
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.width = "80%";
  dialogConfig.data = countryData;
  const dialogRef = this.dialog.open(CountryPopupComponent,dialogConfig);
   dialogRef.afterClosed().subscribe(data => {


   });
  }
}