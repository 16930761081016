import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { IndicatorService } from '../../../indicator/indicator.service';
import { IndicatorMasterService } from '../../indicator-master.service';
import swal from 'sweetalert2';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';

@Component({
  selector: 'app-indicator-datapoint-mapping',
  templateUrl: './indicator-datapoint-mapping.component.html',
  styleUrls: ['./indicator-datapoint-mapping.component.css']
})
export class IndicatorDatapointMappingComponent implements OnInit {

  dataPointsList: any;
  indicatorList: any;
  toggleList: boolean = false;
  indicatorSelected: any;
  indicatorDataPointForm: any;
  dataPointsMapping: any = [];
  searchIndicatorForm: FormGroup;
  @ViewChild('indicatorDataPointMapping') indicatorDataPointMapping;
  selectText="Select All";
  constructor(private indicatorService: IndicatorService, private indicatorMasterService: IndicatorMasterService, private formBuilder: FormBuilder, private addEditSuccessComponent: AddEditSuccessComponent) {
    this.indicatorDataPointForm = this.createFormGroup();
  }

  ngOnInit() {
    this.fetchIndicatorDetails();
    this.fetchDataPointDetails();
    this.getIndicatorDataPointMappingList();
  }
  createFormGroup() {
    return this.formBuilder.group({
      indicator: ['', Validators.compose([Validators.required])],
      taggedDataPoints: ['', Validators.compose([Validators.required])],

    });
  }
  fetchIndicatorDetails() {
    let indicatorSearchVO: any = {};
    indicatorSearchVO.deriveFlag = 1; // only considering direct indicators ( Yes - 1 )
    this.indicatorService.getIndicatorsForSearch(indicatorSearchVO).subscribe(data => {
      this.indicatorList = data;
    })

  }
  fetchDataPointDetails() {
    this.indicatorMasterService.getListOfDataPoints().subscribe(data => {
      this.dataPointsList = data;
    })

  }
  changeIndicator() {
    this.indicatorSelected = this.indicatorDataPointForm.get('indicator').value;
    var valueArray = [];
    Object.keys(this.dataPointsMapping).map(key => {
      if (key == this.indicatorSelected) {
        let dpLeng = this.dataPointsMapping[key].datapointVOList.length;
        for (let j = 0; j < dpLeng; j++) {
          valueArray.push(this.dataPointsMapping[key].datapointVOList[j].id);
        }
      }
      this.indicatorDataPointForm.controls.taggedDataPoints.patchValue(valueArray);
    });
  }

  onSubmit() {
    if (this.indicatorDataPointForm.valid) {
      let indicatorVO: any = {};
      indicatorVO.id = this.indicatorDataPointForm.get('indicator').value;
      let taggedDataPointsList: any = [];
      let selectedDataPoints = this.indicatorDataPointForm.get('taggedDataPoints').value;
      for (let dataPointId of selectedDataPoints) {
        taggedDataPointsList.push({
          "id": dataPointId
        })

      }
      indicatorVO.datapointVOList = taggedDataPointsList;
      this.indicatorMasterService.tagIndicatorToDataPoints(indicatorVO).subscribe(data => {
        this.indicatorSelected = 0;

        var message = "Successfully Tagged Indicator to Data Point(s)";
        this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
          this.resetIndicatorDataPoint();
        });
      }, (error) => {
        console.log(error);
      });
    }
  }
  resetIndicatorDataPoint() {
    this.indicatorDataPointMapping.resetForm();
    this.getIndicatorDataPointMappingList();
  }
  selectAll(element: any, event: any) {
    this.toggleList = !this.toggleList;
    var valueArray = [];
    if (this.toggleList) {
      for (let i of element) {
        valueArray.push(i.id);
      }
      this.selectText="Deselect All";
    }
    else{
      this.selectText="Select All";
    }
    this.indicatorDataPointForm.controls.taggedDataPoints.patchValue(valueArray);
  }

  getIndicatorDataPointMappingList() {
    this.indicatorMasterService.getTaggedDataPointsForIndicator().subscribe(data => {
      this.dataPointsMapping = data;
    });
  }
}
