import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseUrlService } from '../../../../../shared/base-url.service';
import { AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import swal from 'sweetalert2';
import { AddEditSuccessComponent } from '../../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';

@Component({
  selector: 'app-subfactor-user-mapping',
  templateUrl: './subfactor-user-mapping.component.html',
  styleUrls: ['./subfactor-user-mapping.component.css']
})
export class SubfactorUserMappingComponent implements OnInit {

  subFactorUserMappingForm: FormGroup;
  isSubmit: boolean;
  subFactorList: any;
  userList: any;
  response:any;
  users:any;
  toggleList:boolean = false;
  subFactorSelected: number;
  subFactorUserMappingData:any=[];
  selectText="Select All";
  @ViewChild('subfactorUserMapping') subfactorUserMapping;
  constructor(private addEditSuccessComponent: AddEditSuccessComponent,private assessmentTemplateService: AssessmentTemplateMasterdataService,
    private formBuilder: FormBuilder,private baseUrlService: BaseUrlService) {
    this.subFactorUserMappingForm = this.createFormGroup();

  }
  createFormGroup() {
    return this.formBuilder.group({

     subFactor: ['', Validators.compose([Validators.required])],
     taggedUsers: ['', Validators.compose([Validators.required])],

    });
  }

  ngOnInit() {
    this.isSubmit = false;
    this.subFactorSelected = 0;
    this.fetchSubFactorDetails();
    this.fetchUserDetails();
  }

  fetchSubFactorDetails() {
    this.assessmentTemplateService.getSubfactorList().subscribe(data => {
      this.subFactorList = data;
    })
  }
  
  fetchUserDetails() {

    let masterRequestVO;
    masterRequestVO={
      'userList':true
    };
    this.baseUrlService.getListOfUsers(masterRequestVO).subscribe(data => {
      this.response = data;
      this.userList= this.response.userVOList;
    }, (error) => {
      console.log(error);
    });
  }

  changeSubFactor() {
    this.subFactorSelected = this.subFactorUserMappingForm.get('subFactor').value;
    this.populateSelectedUsers(this.subFactorSelected);  
  }


  populateSelectedUsers(subFactorId)
  {
      let userVOList=[];
      this.assessmentTemplateService.fetchSubFactorUserMapping(subFactorId).subscribe(data => {
      this.subFactorUserMappingData = data;
      if(this.subFactorUserMappingData.length>0)
      {
        userVOList=this.subFactorUserMappingData[0].userVOList;
      }
        this.patchSubFactor(userVOList);
    }, (error) => {
      console.log(error);
    })
  }

  onSubmit() {
    this.isSubmit = true;

  
    if (this.subFactorUserMappingForm.valid) {

      let subFactorVO: any = {};
      subFactorVO.id = this.subFactorUserMappingForm.get('subFactor').value;
      let taggedUserList: any = [];
      let selectedUsers = this.subFactorUserMappingForm.get('taggedUsers').value;
      for (let userId of selectedUsers) {
        taggedUserList.push({
          "id": userId
        })

      }
      subFactorVO.userVOList = taggedUserList;
      this.assessmentTemplateService.createSubFactorUserMapping(subFactorVO).subscribe(data => {
        this.subFactorSelected = 0;

        var message = "Successfully Tagged Sub Factor to User(s)";
        // swal({
        //   title: 'Success!',
        //   text: message,
        //   type: 'success',
        //   timer : 2000,
        //   confirmButtonText: 'Ok'
        // })
        this.addEditSuccessComponent.showSuccessSwal(message)
        .then((value) => {
          this.resetSubfactorUserMappingForm();
        });
      }, (error) => {
        console.log(error);
      });
    }
    else{
      this.subFactorUserMappingForm.get('subFactor').markAsTouched();
      this.subFactorUserMappingForm.get('taggedUsers').markAsTouched();
      return;
    }

  }

  resetSubfactorUserMappingForm() {
    this.subfactorUserMapping.resetForm();
	this.subFactorSelected = 0;
	
  }

  // selectAll(users:any,event){  
  //   this.toggleList = !this.toggleList;
  //   if(this.toggleList)
  //   {
  //     this.patchSubFactor(users);
  //   }
  // }

  selectAll(element:any,event: any){
  
    this.toggleList = !this.toggleList;
    var valueArray = [];
    if(this.toggleList)
    {
      for(let i of element)
      {
        valueArray.push(i.id);
      }
      this.selectText="Deselect All";
    }
    else{
      this.selectText="Select All";
    }
    this.subFactorUserMappingForm.controls.taggedUsers.patchValue(valueArray);
  }
 patchSubFactor(taggedUsers){

  var taggedUsersArray=[]
     for(let i of taggedUsers)
      {
        taggedUsersArray.push(i.id);
      }
      this.subFactorUserMappingForm.controls.taggedUsers.patchValue(taggedUsersArray);

 }



}
