import { Component, OnInit,ViewChild,Inject } from '@angular/core';
import { FormBuilder, FormGroup,AbstractControl,FormControl,FormsModule} from '@angular/forms';
import {MatTableDataSource,MatPaginator,MatSort, MatDialogRef,MatDialog,MAT_DIALOG_DATA} from '@angular/material';
import { IndicatorService } from '../indicator/indicator.service';
import {IndicatorMasterService} from '../indicator-master/indicator-master.service';
@Component({
  selector: 'app-search-indicator-common',
  templateUrl: './search-indicator-common.component.html',
  styleUrls: ['./search-indicator-common.component.css']
})
export class SearchIndicatorCommonComponent implements OnInit {

  indicatorCategoryList: any;
  indicatorTypeList:any;
  searchIndicatorForm:FormGroup; 
  indicatorList:any; 
  jsonArray:any;
  displayedColumns:any;
  dataSource:any;
  flag:any;
  fetchedIndicatorList:any =[];
  indicatorSelectedObjs:Array<any> = [];
  parameterDataList:any =[];
  parameterCompareDataListSubmit:any =[];
  parameterJsonArray:any = [];
  paramaterArrayById:any = [];
  parameterFinalSubmitArray: any = [];
  parameterDataSubmitList:any = [];
  parameterVOList:any = [];
  toggleList:any =[];
  i:number;
  loading: boolean;
  nonMandatoryFlag : boolean;
  parameterList: any = [];
  toggleParameterList: any = [];
  selectText = {};
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private indicatorService: IndicatorService,
              private formBuilder: FormBuilder,
              private dialogRef:MatDialogRef<SearchIndicatorCommonComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private indicatorMasterdataService:IndicatorMasterService, 

  ){
  
  	this.loading=false;
    this.searchIndicatorForm=this.createFormGroup();
  }

  ngOnInit() {
   
    this.fetchIndicatorList();
    this.fetchSubCategories();
    this.loadParameterData();
 
  }
  loadParameterData() {

    this.indicatorService.fetchParameterDataApplicableToIndicator(0).subscribe(data => {
      let parameterData: any = data;
      this.parameterList = parameterData;
      var abstractControl: AbstractControl = this.searchIndicatorForm.controls.parameterList;
      for (let parameter of this.parameterList) {
        if (abstractControl instanceof FormGroup) {
          (<FormGroup>abstractControl).addControl(parameter.id, new FormControl(''));
        }
        this.toggleParameterList[parameter.id] = false;
        this.selectText[parameter.id] ="Select All";
      }


    })
  }
  createFormGroup() {
    return this.formBuilder.group({
      indicatorName: [''],
      subCategoryId: [0],
      indicatorTypeId: [0],
      indicatorDetailsVO:this.formBuilder.group({
        mandatory:['N'],

      }),
      parameterList:this.formBuilder.group({}),

    })
  }

  setFormControlsForParameter(parameterVO){
 
    if(parameterVO.childParameterVO)
    {
      this.parameterVOList.push(parameterVO.childParameterVO);
      this.paramaterArrayById[parameterVO.childParameterVO.id] = parameterVO.childParameterVO;
      this.setFormControlsForParameter(parameterVO.childParameterVO);
      
    }
    
    this.parameterDataList[parameterVO.id] = parameterVO.parameterLevelVOList;
    this.toggleList[parameterVO.id] = false;
    //setting for comparing on submit(since the value gets changed on "On change" function)
    this.parameterCompareDataListSubmit[parameterVO.id] = parameterVO.parameterLevelVOList;
    /*
    FormGroup extended class from AbstractControl has addControl and this method is not part of parent class AbstractControl. So when you use the method get, the returned element is an AbstractControl, not FormGroup, so you should ensure the returned element is a FormGroup and cast it properly to use addControl method
    */
    var abstractControl : AbstractControl = this.searchIndicatorForm.controls.masterParameterVO;
    if(abstractControl instanceof FormGroup){
      (<FormGroup>abstractControl).addControl(parameterVO.id,new FormControl(''));
    }
    
  }

  dataTableList(dataTableList){
    
    if(dataTableList.length == 0)
      this.flag = 1;
    else
      this.flag = 0;
     
  this.jsonArray=[];
  for( let i of dataTableList){
    var newArray = {};
   
    newArray['id'] = i.id;
    newArray['code'] = i.code;
    newArray['name'] = i.name;
    newArray['type'] = i.indicatorType.value;
    newArray['indicatorTypeId'] = i.indicatorType.id;

    if(i.indicatorDetailsVO!=null && i.indicatorDetailsVO.ratingGroupVO && i.indicatorDetailsVO.ratingGroupVO.name!=null){
    newArray['ratingScale'] = i.indicatorDetailsVO.ratingGroupVO.name;
    }
    else
      newArray['ratingScale'] = '-';
    if(i.indicatorDetailsVO!=null && i.indicatorDetailsVO.unitOfMeasurementVO && i.indicatorDetailsVO.unitOfMeasurementVO.name!=null){
      newArray['unitOfMeasurement'] = i.indicatorDetailsVO.unitOfMeasurementVO.name;
    }
    else
      newArray['unitOfMeasurement'] = '-';

  
    newArray['indicatorStack'] = i.indicatorStackVO.name;
    if(i.subcategoryVO !=null && i.subcategoryVO.name!=null){
      newArray['performanceArea'] = i.subcategoryVO.name;
    }
    if (i.indicatorDetailsVO.mandatory == 1)
      newArray['mandatory'] = true;
    else
      newArray['mandatory'] = false;
    if (i.indicatorDetailsVO.critical == 1)
      newArray['critical'] = true;
    else
      newArray['critical'] = false;
    this.jsonArray.push(newArray);
  }

  this.displayedColumns=['Indicator Code','Indicator Name','indicatorStack','Type','performanceArea','Unit Of Measurement','Rating Scale']
  this.dataSource = new MatTableDataSource(this.jsonArray);
  setTimeout(()=> {
     this.dataSource.paginator = this.paginator;
   }, 1000); 
   this.dataSource.sort = this.sort;


  }

  fetchIndicatorList(){

     
    this.indicatorService.getIndicators(this.data.nonMandatoryFlag).subscribe(data => {
	this.loading=true;
      this.indicatorList = data;  
      this.loading=false;
      this.dataTableList(this.indicatorList);
    },(error)=>{
      console.log(error);
    });
  }

  fetchSubCategories(){
    this.indicatorMasterdataService.getSubCategories(0).subscribe(data => {
        this.indicatorCategoryList = data;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onChange(element:any, isChecked: any) {

    if(isChecked.checked) {
      this.indicatorSelectedObjs.push(element);
      
    } else {
      let index = this.indicatorSelectedObjs.indexOf(element);
      this.indicatorSelectedObjs.splice(index,1);
    }

    
}

toggleCheckBox(element){
  return (this.indicatorSelectedObjs.indexOf(element) != -1) ? true : false;
}

selectAll(selectedParameter: any, event: any) {
  let parameterDataVOList = selectedParameter.parameterDataVOList;
  this.toggleParameterList[selectedParameter.id] = !this.toggleParameterList[selectedParameter.id];
  let selectedParameterDataArray = [];
  if (this.toggleParameterList[selectedParameter.id]) {
    selectedParameterDataArray = parameterDataVOList;
    this.selectText[selectedParameter.id] ="Deselect All";
  }
  else{
    this.selectText[selectedParameter.id] ="Select All";
  }
  let selectedParameterId = selectedParameter.id.toString();
  this.searchIndicatorForm.controls.parameterList.get(selectedParameterId).patchValue(selectedParameterDataArray);
}

onSubmit()
{


  if (this.searchIndicatorForm.valid) {
    let indicatorSearchVO = this.searchIndicatorForm.value;

    let tempParameterList = [];
    let submitParameterArray = [];
    tempParameterList = this.parameterList;
    let parameterJsonArray = this.searchIndicatorForm.controls.parameterList.value;
    for (let parameter in parameterJsonArray) {
      let selectedParameterArray = (parameterJsonArray[parameter] != "") ? parameterJsonArray[parameter] : [];
      if (selectedParameterArray.length > 0) {
        let selectedParameterVO: any;
        let submitParameterVO: any = {};
        selectedParameterVO = tempParameterList.filter(obj => obj.id == parameter);
        submitParameterVO['id'] = parameter;
        submitParameterVO['levelNumber'] = selectedParameterVO[0].levelNumber;
        submitParameterVO['parameterDataVOList'] = parameterJsonArray[parameter];
        submitParameterArray.push(submitParameterVO);
      }

    }
    indicatorSearchVO.parameterList = submitParameterArray;

    this.parameterJsonArray = [];
    this.parameterFinalSubmitArray = [];
    this.indicatorList = [];
  
    this.parameterJsonArray.push(this.searchIndicatorForm.controls.parameterList.value);
    indicatorSearchVO.parameterList = submitParameterArray;
   	this.loading=true;
    this.indicatorService.getIndicatorsForSearch(indicatorSearchVO).subscribe(data=>{
    
    if(data)
    {
      this.flag = 0;
      this.indicatorList=data;
      this.dataTableList(this.indicatorList);
       this.loading=false;
      
    }
  })
  
  
}
}
parameterDataChange(param,isChecked) {
  
 
  //set child multi select data
  this.setChildValues(param, this.searchIndicatorForm.controls.masterParameterVO.get(param.id.toString()).value);
  

}
setChildValues(parameterArray, parameterSelected) {

  var childParameterSelected = null;

  if (parameterArray.childParameterVO != null) {

    var parameterLevelJsonArray = {};
    parameterLevelJsonArray['id'] = parameterArray.childParameterVO.id;
    parameterLevelJsonArray['levelId'] = parameterArray.childParameterVO.levelNumber;
    var selectedParamArray = [];
    selectedParamArray = parameterSelected;
    parameterLevelJsonArray['selectedParamArray'] = selectedParamArray;
    if(selectedParamArray.length>0)
    {
      this.indicatorService.getChildParameterData(parameterLevelJsonArray).subscribe(data => {
      childParameterSelected = data;
      this.setNewValues(parameterArray, childParameterSelected);


    });
  }
  else{
    this.indicatorService.getParameterData(parameterArray.childParameterVO.id,parameterArray.childParameterVO.levelNumber).subscribe((data)=>{
      childParameterSelected = data;
      this.setNewValues(parameterArray,childParameterSelected);
    });
  }
  }

}

setNewValues(parameterArray, childParameterSelected) {
  var childParameterSelectedString = '';
  for (this.i = 0; this.i < childParameterSelected.length; this.i++) {

    if (this.i != (childParameterSelected.length - 1))
      childParameterSelectedString = childParameterSelectedString + childParameterSelected[this.i].id + ",";
    else
      childParameterSelectedString = childParameterSelectedString + childParameterSelected[this.i].id;
  }

  this.parameterDataList[parameterArray.childParameterVO.id] = childParameterSelected;
}

onSelect(indicatorSelectedObjs){

  this.dialogRef.close(indicatorSelectedObjs);

}

applyFilter(filterValue: string) {
  filterValue = filterValue.trim(); 
  filterValue = filterValue.toLowerCase(); 
  this.dataSource.filter = filterValue;

  if(this.dataSource.filteredData.length==0){
    this.flag = true;
  }else{
    this.flag = false;
  }
  setTimeout(()=> {
     this.dataSource.paginator = this.paginator;
  }, 1000); 
}
  

}
