import { Component, OnInit, Input, AfterViewInit, OnChanges, SimpleChanges } from "@angular/core";
import { FormGroup, ValidatorFn, AbstractControl } from "@angular/forms";
import { FormBuilder, Validators, FormControl, ValidationErrors } from "@angular/forms";
import { IndicatorService } from "../indicator.service";
import swal from "sweetalert2";
import { Router, ActivatedRoute } from "@angular/router";
import { AddEditSuccessComponent } from "../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component";
import { CustomValidationsService } from "../../../shared/Validations/custom-validations.service";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Component({
  selector: "app-add-indicator",
  templateUrl: "./add-indicator.component.html",
  styleUrls: ["./add-indicator.component.css"],
})
export class AddIndicatorComponent implements OnInit{
  weightageList: any = [];
  customFlag: any;
  redirectFlag: any;
  companyTypeId: number;
  frameworkDetailsId: number;
  companyId: number;
  indicatorId: number = 0;
  addIndicatorForm: FormGroup;
  masterData: any = [];
  selectedIndicatorTypeId: any;
  selectedIndicatorTypeCode: any;
  indicatorDetailsVO: any = [];
  unitGroups = [];
  parameterList: any = [];
  toggleParameterList: any = [];
  unitOfMeasurementList: any = [];
  unitOfMeasurement: any;
  unitGroup: any;
  targetType: any;
  ratingGroupVO: any;
  achievementFormula: any;
  aggregationLogic: any;
  missingAction: any;
  public disableFlag = false;
  selectText = {};
  showESGIndicator: boolean=true;
  showOtherIndicator: boolean;
  modalFormGroup: any;
  dataSourceArray: any = [
    { id: 1, name: "MSCI" },
    { id: 2, name: "BvD" },
    { id: 3, name: "S&P" },
  ];
  sourcePriorityArray: any = [1, 2];
  dataQualityScoreArray: any = [1, 2, 3, 4, 5];
  modalTableDataArray: any = [];
  originalColumnNames: string[];
  displayedColumns: string[];
  jsonArray: any;
  count: number;
  modalTableOtherDataArray: any = [];
  commonMatTableArray: any = [];
  dataAttributeArray: any[];
  constructor(
    private formBuilder: FormBuilder,
    private indicatorService: IndicatorService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private customValidationsService: CustomValidationsService,
    private addEditSuccessComponent: AddEditSuccessComponent
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (Object.keys(params).length > 0) {
        this.indicatorId = params.id;
      }
    });
    this.activatedRoute.queryParams.subscribe(params => {
      (this.companyId = params.companyId),
        (this.companyTypeId = params.companyTypeId),
        (this.frameworkDetailsId = params.frameworkId),
        (this.redirectFlag = params.routingFromPerformanceFramework),
        (this.customFlag = params.customFlag);
    });
    this.addIndicatorForm = this.createFormGroup();
    this.modalFormGroup = this.createModalForm();
    console.log(this.modalFormGroup);
    
  }

  createFormGroup() {
    return this.formBuilder.group(
      {
        id: [this.indicatorId],
        code: [
          "",
          [Validators.required, this.customValidationsService.noWhitespaceValidator],
          this.uniqueCodeValidator(),
        ],
        name: ["", [Validators.required, this.customValidationsService.noWhitespaceValidator]],

        sdgIds: ["", Validators.required],
        categoryVO: this.formBuilder.group({
          id: ["", Validators.required],
        }),
        subcategoryVO: this.formBuilder.group({
          id: ["", Validators.required],
        }),
        indicatorStackVO: this.formBuilder.group({
          id: ["", Validators.required],
        }),
        indicatorType: this.formBuilder.group({
          id: ["", Validators.required],
        }),
        indicatorDetailsVO: this.formBuilder.group({
          id: [""],
          weightage: [5, Validators.required],
          unitGroupVO: this.formBuilder.group({
            id: [""],
          }),
          unitOfMeasurementVO: this.formBuilder.group({
            id: [""],
          }),
          targetType: this.formBuilder.group({
            id: [""],
          }),
          achievementFormula: this.formBuilder.group({
            id: [""],
          }),
          aggregationLogic: this.formBuilder.group({
            id: [""],
          }),
          ratingGroupVO: this.formBuilder.group({
            id: [""],
          }),
          missingAction: this.formBuilder.group({
            id: [""],
          }),
          directIndicator: [1, Validators.required],
          mandatory: [2, Validators.required],
          critical: [2, Validators.required],
        }),
        parameterList: this.formBuilder.group({}),
      },
      { updateOn: "blur" }
    );
  }
  ngOnInit() {
    this.loadMasterData();
    this.loadParameterData();
    this.setWeightageList();
    this.esgIndicator();
  }

  setWeightageList() {
    for (let i = 1; i <= 10; i++) {
      this.weightageList.push(i);
    }
  }
  loadMasterData() {
    this.indicatorService.getMasterDataOnLoad(this.indicatorId).subscribe(data => {
      this.masterData = data;
      this.fetchIndicatorTypeFormControls();
      if (this.indicatorId > 0 && Object.entries(this.masterData).length > 0) {
        this.selectedIndicatorTypeId = this.masterData.indicatorType.id;
        this.setIndicatorDataToForm();
        this.disableFlag = true;
      }
    });
  }
  fetchIndicatorTypeFormControls() {
    this.unitOfMeasurement = this.addIndicatorForm["controls"].indicatorDetailsVO["controls"].unitOfMeasurementVO[
      "controls"
    ]["id"];
    this.unitGroup = this.addIndicatorForm["controls"].indicatorDetailsVO["controls"].unitGroupVO["controls"]["id"];
    this.targetType = this.addIndicatorForm["controls"].indicatorDetailsVO["controls"].targetType["controls"]["id"];
    this.ratingGroupVO = this.addIndicatorForm["controls"].indicatorDetailsVO["controls"].ratingGroupVO["controls"][
      "id"
    ];
    this.achievementFormula = this.addIndicatorForm["controls"].indicatorDetailsVO["controls"].achievementFormula[
      "controls"
    ]["id"];
    this.aggregationLogic = this.addIndicatorForm["controls"].indicatorDetailsVO["controls"].aggregationLogic[
      "controls"
    ]["id"];
    this.missingAction = this.addIndicatorForm["controls"].indicatorDetailsVO["controls"].missingAction["controls"][
      "id"
    ];
  }
  setIndicatorDataToForm() {
    this.fetchMasterDataByIndicatorType();
    let indicatorDetailsVO = this.masterData.indicatorDetailsVO;
    this.selectedIndicatorTypeCode = this.masterData.indicatorType.code;
    this.addIndicatorForm.patchValue({
      id: this.indicatorId,
      code: this.masterData.code,
      name: this.masterData.name,

      sdgIds: this.masterData.sdgIds,
      categoryVO: {
        id: this.masterData.categoryVO.id,
      },
      subcategoryVO: {
        id: this.masterData.subcategoryVO.id,
      },
      indicatorStackVO: {
        id: this.masterData.indicatorStackVO.id,
      },
      indicatorType: {
        id: this.masterData.indicatorType.id,
      },
      indicatorDetailsVO: {
        directIndicator: indicatorDetailsVO.directIndicator,
        mandatory: indicatorDetailsVO.mandatory,
        critical: indicatorDetailsVO.critical,
        weightage: indicatorDetailsVO.weightage,
      },
    });
    if (this.selectedIndicatorTypeCode == "QR" || this.selectedIndicatorTypeCode == "QU") {
      if (indicatorDetailsVO.achievementFormula) {
        this.achievementFormula.patchValue(indicatorDetailsVO.achievementFormula.id);
      }
      if (indicatorDetailsVO.aggregationLogic) {
        this.aggregationLogic.patchValue(indicatorDetailsVO.aggregationLogic.id);
      }
      if (indicatorDetailsVO.missingAction) {
        this.missingAction.patchValue(indicatorDetailsVO.missingAction.id);
      }
      if (this.selectedIndicatorTypeCode == "QU") {
        if (indicatorDetailsVO.unitGroupVO) {
          this.unitGroup.patchValue(indicatorDetailsVO.unitGroupVO.id);
        }
        if (indicatorDetailsVO.unitOfMeasurementVO) {
          this.unitOfMeasurement.patchValue(indicatorDetailsVO.unitOfMeasurementVO.id);
        }
        if (indicatorDetailsVO.targetType) {
          this.targetType.patchValue(indicatorDetailsVO.targetType.id);
        }
      }
      if (this.selectedIndicatorTypeCode == "QR") {
        if (indicatorDetailsVO.ratingGroupVO) {
          this.ratingGroupVO.patchValue(indicatorDetailsVO.ratingGroupVO.id);
        }
      }
    }
  }
  loadParameterData() {
    this.indicatorService.fetchParameterDataApplicableToIndicator(this.indicatorId).subscribe(data => {
      let parameterData: any = data;
      this.parameterList = parameterData;
      let parameterControl: AbstractControl = this.addIndicatorForm.controls.parameterList;

      for (let parameter of this.parameterList) {
        if (parameterControl instanceof FormGroup) {
          (<FormGroup>parameterControl).addControl(parameter.id, new FormControl(""));
        }
        this.toggleParameterList[parameter.id] = false;
        this.selectText[parameter.id] = "Select All";
      }
      if (this.indicatorId > 0) {
        this.setParameterValuesOnEdit();
      }
    });
  }
  setParameterValuesOnEdit() {
    let filteredParameterDataObj;
    let selectedParameterDataArray;
    let selectedParameterDataIds;
    let parameterId;
    let parameterControl: AbstractControl = this.addIndicatorForm.controls.parameterList;
    for (let parameter of this.parameterList) {
      selectedParameterDataIds = [];
      selectedParameterDataArray = [];
      selectedParameterDataIds = parameter.parameterDataIdList;
      for (let parameterDataId of selectedParameterDataIds) {
        filteredParameterDataObj = parameter.parameterDataVOList.filter(obj => obj.id == parameterDataId);
        selectedParameterDataArray.push(filteredParameterDataObj[0]);
      }
      parameterId = parameter.id.toString();
      parameterControl.get(parameterId).patchValue(selectedParameterDataArray);
    }
  }

  onCategoryChange(categoryId) {
    if (categoryId) {
      this.indicatorService.getSubCategory(categoryId).subscribe(data => {
        this.masterData.subcategoryVOList = data;
      });
    }
    this.addIndicatorForm["controls"].indicatorStackVO["controls"].id.patchValue("");
    this.addIndicatorForm["controls"].subcategoryVO["controls"].id.patchValue("");
  }

  onSubCategoryChange(subCategoryId) {
    if (subCategoryId) {
      this.addIndicatorForm["controls"].indicatorStackVO["controls"].id.patchValue("");

      this.indicatorService.getIndicatorStackList(subCategoryId).subscribe(data => {
        this.masterData.indicatorStackVOList = data;
      });
    }
  }

  selectAll(selectedParameter: any, event: any) {
    let parameterDataVOList = selectedParameter.parameterDataVOList;
    this.toggleParameterList[selectedParameter.id] = !this.toggleParameterList[selectedParameter.id];
    let selectedParameterDataArray = [];
    if (this.toggleParameterList[selectedParameter.id]) {
      selectedParameterDataArray = parameterDataVOList;
      this.selectText[selectedParameter.id] = "Deselect All";
    } else {
      this.selectText[selectedParameter.id] = "Select All";
    }
    let selectedParameterId = selectedParameter.id.toString();
    this.addIndicatorForm.controls.parameterList.get(selectedParameterId).patchValue(selectedParameterDataArray);
  }

  parameterDataChange(parameter, selectedParameterData) {
    if (parameter.parameterVO != null && parameter.parameterVO.id > 0) {
      let parameterRequest: any = {};
      let parameterDataVO = {};
      let parameterVO = {};
      parameterVO["id"] = parameter.parameterVO.id;
      parameterDataVO["levelNumber"] = parameter.parameterVO.levelNumber;
      parameterDataVO["parameterVO"] = parameterVO;
      parameterDataVO["parameterDataIds"] = [];
      for (let parameterData of selectedParameterData) {
        parameterDataVO["parameterDataIds"].push(parameterData.id);
      }
      parameterRequest["parameterDataVO"] = parameterDataVO;
      parameterRequest["childParameterDataRequired"] = true;
      this.indicatorService.getChildParameterData(parameterRequest).subscribe(data => {
        let parameterWrapperData: any = data;
        let childParameterDataVOList = parameterWrapperData.parameterDataVOList;
        if (childParameterDataVOList) {
          let childParameter = this.parameterList.filter(obj => obj.id == parameterVO["id"]);
          childParameter[0].parameterDataVOList = childParameterDataVOList;
        }
      });
    }
  }

  onIndicatorTypeChange(selectedIndicatorTypeId) {
    this.selectedIndicatorTypeId = selectedIndicatorTypeId;
    let selectedIndicatorType = this.masterData.indicatorTypeList.filter(obj => obj.id == selectedIndicatorTypeId);
    this.selectedIndicatorTypeCode = selectedIndicatorType[0].code;
    if (Object.entries(this.masterData).length > 0) {
      this.fetchMasterDataByIndicatorType();
    }
    this.unitOfMeasurement = this.addIndicatorForm["controls"].indicatorDetailsVO["controls"].unitOfMeasurementVO[
      "controls"
    ]["id"];
    this.unitGroup = this.addIndicatorForm["controls"].indicatorDetailsVO["controls"].unitGroupVO["controls"]["id"];
    this.targetType = this.addIndicatorForm["controls"].indicatorDetailsVO["controls"].targetType["controls"]["id"];
    this.ratingGroupVO = this.addIndicatorForm["controls"].indicatorDetailsVO["controls"].ratingGroupVO["controls"][
      "id"
    ];
    this.achievementFormula = this.addIndicatorForm["controls"].indicatorDetailsVO["controls"].achievementFormula[
      "controls"
    ]["id"];
    this.aggregationLogic = this.addIndicatorForm["controls"].indicatorDetailsVO["controls"].aggregationLogic[
      "controls"
    ]["id"];
    this.missingAction = this.addIndicatorForm["controls"].indicatorDetailsVO["controls"].missingAction["controls"][
      "id"
    ];
    this.unitOfMeasurement.setValue("");
    this.unitOfMeasurement.clearValidators();
    this.unitOfMeasurement.markAsUntouched();
    this.unitGroup.setValue("");
    this.unitGroup.clearValidators();
    this.unitGroup.markAsUntouched();
    this.targetType.setValue("");
    this.targetType.clearValidators();
    this.targetType.markAsUntouched();
    this.ratingGroupVO.setValue("");
    this.ratingGroupVO.clearValidators();
    this.ratingGroupVO.markAsUntouched();
    this.achievementFormula.setValue("");
    this.achievementFormula.clearValidators();
    this.achievementFormula.markAsUntouched();
    this.aggregationLogic.setValue("");
    this.aggregationLogic.clearValidators();
    this.aggregationLogic.markAsUntouched();
    this.missingAction.setValue("");
    this.missingAction.clearValidators();
    this.missingAction.markAsUntouched();

    if (this.selectedIndicatorTypeCode == "QU") {
      this.unitGroup.setValidators(Validators.required);
      this.unitOfMeasurement.setValidators(Validators.required);
      this.targetType.setValidators(Validators.required);
      this.achievementFormula.setValidators(Validators.required);
      this.aggregationLogic.setValidators(Validators.required);
      this.missingAction.setValidators(Validators.required);
    } else if (this.selectedIndicatorTypeCode == "QR") {
      this.ratingGroupVO.setValidators(Validators.required);
      this.achievementFormula.setValidators(Validators.required);
      this.aggregationLogic.setValidators(Validators.required);
      this.missingAction.setValidators(Validators.required);
    }
    this.unitGroup.updateValueAndValidity();
    this.unitOfMeasurement.updateValueAndValidity();
    this.targetType.updateValueAndValidity();
    this.ratingGroupVO.updateValueAndValidity();
    this.achievementFormula.updateValueAndValidity();
    this.aggregationLogic.updateValueAndValidity();
    this.missingAction.updateValueAndValidity();
  }
  onUnitGroupChange(unitGroupId) {
    if (unitGroupId > 0 && unitGroupId != null) {
      this.indicatorService.getunitOfMeasurementList(unitGroupId).subscribe(
        data => {
          this.unitOfMeasurementList = data;
        },
        error => {
          console.log(error);
        }
      );
    }
    this.addIndicatorForm["controls"].indicatorDetailsVO["controls"].unitOfMeasurementVO["controls"].id.patchValue(0);
  }

  onSubmit() {
    if (this.addIndicatorForm.valid && this.showESGIndicator) {
      let indicatorVO = this.addIndicatorForm.value;
      let tempParameterList = [];
      let submitParameterArray = [];
      tempParameterList = this.parameterList;
      let parameterJsonArray = this.addIndicatorForm.controls.parameterList.value;
      for (let parameter in parameterJsonArray) {
        let selectedParameterArray = parameterJsonArray[parameter] != "" ? parameterJsonArray[parameter] : [];
        if (selectedParameterArray.length > 0) {
          let selectedParameterVO: any;
          let submitParameterVO: any = {};
          selectedParameterVO = tempParameterList.filter(obj => obj.id == parameter);
          submitParameterVO["id"] = parameter;
          submitParameterVO["levelNumber"] = selectedParameterVO[0].levelNumber;
          submitParameterVO["parameterDataVOList"] = parameterJsonArray[parameter];
          submitParameterArray.push(submitParameterVO);
        }
      }
      indicatorVO.parameterList = submitParameterArray;
      this.indicatorService.createIndicator(indicatorVO).subscribe(indicatorId => {
        let message = "";
        if (this.indicatorId > 0) {
          message = "Successfully Edited Indicator";
        } else {
          message = "Successfully Added Indicator";
        }
        this.addEditSuccessComponent.showSuccessSwal(message).then(value => {
          if (this.redirectFlag) {
            let companyDetails = {};
            companyDetails["companyId"] = this.companyId;
            companyDetails["frameworkId"] = this.frameworkDetailsId;
            companyDetails["companyTypeId"] = this.companyTypeId;
            companyDetails["customIndicatorId"] = indicatorId;
            this.router.navigate(["/addCompanyIndicatorsComponent"], {
              queryParams: companyDetails,
              skipLocationChange: true,
            });
          } else this.redirectToListIndicatorPage();
        });
      });
    } else {
      let indicatorVO = this.addIndicatorForm.value;
      console.log(indicatorVO);
    }
  }
  fetchMasterDataByIndicatorType() {
    this.indicatorService.getMasterDataByIndicatorType(this.selectedIndicatorTypeId).subscribe(data => {
      this.indicatorDetailsVO = data;
      let unitOfMeasurementList = this.indicatorDetailsVO.unitOfMeasurementVOList;
      if (unitOfMeasurementList) {
        this.unitOfMeasurementList =
          this.masterData.indicatorDetailsVO != null &&
          this.masterData.indicatorDetailsVO.unitGroupVO != null &&
          this.masterData.indicatorDetailsVO.unitGroupVO.id > 0
            ? unitOfMeasurementList.filter(
                obj => obj.unitGroupVO.id == this.masterData.indicatorDetailsVO.unitGroupVO.id
              )
            : unitOfMeasurementList;
      }
    });
  }

  redirectToListIndicatorPage() {
    this.router.navigate(["/indicator"]);
  }
  navigateToAddIndicator() {
    let companyDetails = {};

    companyDetails["companyId"] = this.companyId;
    companyDetails["frameworkId"] = this.frameworkDetailsId;
    companyDetails["companyTypeId"] = this.companyTypeId;

    this.router.navigate(["/addCompanyIndicatorsComponent"], { queryParams: companyDetails, skipLocationChange: true });
  }

  uniqueCodeValidator() {
    return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      var id = 0;
      if (this.addIndicatorForm && this.addIndicatorForm.controls.id.value) {
        id = this.addIndicatorForm.controls.id.value;
      }

      return this.indicatorService.validateIndicatorCode(id, c.value).pipe(
        map(val => {
          if (val == 1) {
            return { uniqueCode: true };
          }
        })
      );
    };
  }

  uniqueNameValidator() {
    return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      var id = 0;
      if (this.addIndicatorForm && this.addIndicatorForm.controls.id.value) {
        id = this.addIndicatorForm.controls.id.value;
      }
      return this.indicatorService.validateIndicatorName(id, c.value).pipe(
        map(val => {
          console.log(val);
          if (val == 1) {
            return { uniqueName: true };
          }
        })
      );
    };
  }


  /**
   * Switching tabs for esg and other indicators
   */
  esgIndicator() {
    this.showESGIndicator = true;
    this.showOtherIndicator = false;
  }
  otherIndicator() {
    this.showESGIndicator = false;
    this.showOtherIndicator = true;
  }

  /*
   * Modal Popup Form Group
   */
  createModalForm() {
    return  this.formBuilder.group({
      dataSource: ["", [Validators.required]],
      dataAttribute: ["", [Validators.required]],
      sourcePriority: ["", [Validators.required]],
      dataQualityScore: ["", [Validators.required]],
    });
  }

  /**
   * Modal Popup Form handler
   */
  onSubmitModalForm(formDirective) {
    if (this.modalFormGroup.valid) {     
      console.log(this.modalFormGroup.value,"modal val");
      console.log(this.modalFormGroup);
      
     this.commonMatTableArray = [];
      if (this.showESGIndicator) {
        this.jsonArray = this.modalFormGroup.value;
        this.jsonArray.sno = this.modalTableDataArray.length + 1;
        this.modalTableDataArray.push(this.jsonArray);
        this.commonMatTableArray = [...this.modalTableDataArray];
      } else if (this.showOtherIndicator) {
        this.jsonArray = this.modalFormGroup.value;
        this.jsonArray.sno = this.modalTableOtherDataArray.length + 1;
        this.modalTableOtherDataArray.push(this.jsonArray);
        this.commonMatTableArray = [...this.modalTableOtherDataArray];
      }
      formDirective.resetForm();
      this.modalFormGroup.reset();
    }
  }

  /**
   * Resets the modal form
   */
  resetModalForm() {
    this.modalFormGroup.reset();
  }

  /**
   * data populate helper for modalform table
   */
  dataPopulate() {
    this.commonMatTableArray = [];
    if (this.showESGIndicator) {
      this.modalTableDataArray = [
        {
          dataSource: "MSCI",
          dataAttribute: "TOTL_ENRGY_CONSUMP_YEAR",
          sourcePriority: 1,
          dataQualityScore: 1,
          sno: 1,
        },
        {
          dataSource: "BvD",
          dataAttribute: "ISSUER_ISIN",
          sourcePriority: 2,
          dataQualityScore: 4,
          sno: 2,
        },
        {
          dataSource: "S&P",
          dataAttribute: "ISSUER_NAME",
          sourcePriority: 1,
          dataQualityScore: 4,
          sno: 3,
        },
      ];
      this.commonMatTableArray = [...this.modalTableDataArray];
    } else if (this.showOtherIndicator) {
      this.modalTableOtherDataArray = [
        {
          dataSource: "MSCI",
          dataAttribute: "TOTL_ENRGY_CONSUMP_YEAR",
          sourcePriority: 1,
          dataQualityScore: 3,
          sno: 1,
        },
        {
          dataSource: "BvD",
          dataAttribute: "ISSUER_ISIN",
          sourcePriority: 2,
          dataQualityScore: 5,
          sno: 2,
        },
        {
          dataSource: "S&P",
          dataAttribute: "ISSUER_NAME",
          sourcePriority: 1,
          dataQualityScore: 5,
          sno: 3,
        },
      ];
      this.commonMatTableArray = [...this.modalTableOtherDataArray];
    }
    this.originalColumnNames = ["Sl.No", "Data Source", "Data Attribute", "Source Priority", "Data Quality Score"];
    this.displayedColumns = ["sno", "dataSource", "dataAttribute", "sourcePriority", "dataQualityScore"];
  }

  // getters for form controls
  get dataSource() {
    return this.modalFormGroup.get("dataSource");
  }
  get dataAttribute() {
    return this.modalFormGroup.get("dataAttribute");
  }
  get sourcePriority() {
    return this.modalFormGroup.get("sourcePriority");
  }
  get dataQualityScore() {
    return this.modalFormGroup.get("dataQualityScore");
  }

  onDSChange(event){
    this.dataAttributeArray=[]
   let attributeList=["TOTL_ENRGY_CONSUMP_YEAR","CARBON_EMISSIONS_SCOPE_1","CARBON_EMISSIONS_SCOPE_2","CARBON_EMISSIONS_SCOPE_12","CARBON_EMISSIONS_SCOPE_3","CARBON_EMISSIONS_SCOPE_1_KEY","CARBON_EMISSIONS_SCOPE_2_KEY","CARBON_EMISSIONS_TARGET_REDUCT_PCT","CARBON_EMISSIONS_TARGET_REDUCT_DESC","CARBON_EMISSIONS_TARGET_YEAR",
  "CARBON_EMISSIONS_TARGET_BASE_YEAR","ENV_PILLAR_SCORE","SOC_PILLAR_SCORE","GOV_PILLAR_SCORE","OVERALL_PILLAR_SCORE"]
  for(let i=0 ; i< attributeList.length;i++){
         let newObj={}
         newObj["id"]=1;
         newObj["name"]=attributeList[i];
         this.dataAttributeArray.push(attributeList[i]);
  }
       console.log("countries")
       console.log(this.dataAttributeArray)
       
     

  }
}
