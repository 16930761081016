import { Component, OnInit,ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl, FormBuilder, Validators,ValidatorFn,AbstractControl } from '@angular/forms';
import {AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import { CustomValidationsService } from '../../../../../shared/Validations/custom-validations.service';
import { AddEditSuccessComponent } from '../../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
@Component({
  selector: 'app-add-factor',
  templateUrl: './add-factor.component.html',
  styleUrls: ['./add-factor.component.css']
})
export class AddFactorComponent implements OnInit {

  addFactorForm : FormGroup;
  factorList :any=[];
  topWrapper:any;
  factorCodeList=[];
  factorNameList=[];
  @ViewChild ("topWrap") topWrap;
  @ViewChild('factorForm') factorForm;

  constructor(private assessmentTemplateService: AssessmentTemplateMasterdataService, private addEditSuccessComponent: AddEditSuccessComponent, private formBuilder: FormBuilder,private customValidationsService:CustomValidationsService) {
    this.addFactorForm = this.createFormGroup();
    

   }

  ngOnInit() {
    this.getFactorList();
    this.topWrapper=this.topWrap;
  }

  createFormGroup(){
    return this.formBuilder.group({
      id : [0],
      code: ['', [Validators.required,Validators.maxLength(25),this.uniqueCode(),this.customValidationsService.noWhitespaceValidator,Validators.pattern(/^[^<>'\"/;`]*$/)]],
      name: ['', [Validators.required,Validators.maxLength(100),this.uniqueName(),this.customValidationsService.noWhitespaceValidator,Validators.pattern(/^[^<>'\"/;`]*$/)]],
      
    });
  }

  getFactorList() {
    this.assessmentTemplateService.getFactorList().subscribe((data) => {
        this.factorList = data;
       this.setFactorCodeandNameList(0);
    }, (error) => {
      console.log(error);
    });
  }

  defaultValues(p) {
  
    // setting default values for the form
     this.setFactorCodeandNameList(p.id);
    this.addFactorForm.patchValue({
      id: p.id,
      code: p.factorCode,
      name: p.factorName

    });

  }
  onSubmit(){
    if(this.addFactorForm.valid){
      var message;
        this.assessmentTemplateService.createFactor(this.addFactorForm.getRawValue()).subscribe((data) => {
           this.getFactorList();
              if(this.addFactorForm.get('id').value > 0) {
                 message = "Successfully edited factor";
              }
              else {
                 message = "Successfully added factor";
              }
              this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
                this.resetfactorForm();
                this.getFactorList();
                
              });
        }, (error) => {
          console.log(error);
        });
    }
    else{
      this.addFactorForm.get('code').markAsTouched();
      this.addFactorForm.get('name').markAsTouched();
      return;
    }
  }


   resetfactorForm() {
    this.topWrap.nativeElement.classList.remove("top-form-wrapper-highlight");
    this.factorForm.resetForm();
     this.setFactorCodeandNameList(0);

  }

  setFactorCodeandNameList(editId)
  {
    let factorCode='';
    let factorName='';
    this.factorCodeList=[];
    this.factorNameList=[];
    for(let factorVal of this.factorList)
    {
        if(factorVal.id!= editId)
        {
          factorCode = factorVal.code.trim();
          factorName = factorVal.name.trim();
          this.factorCodeList.push(factorCode);
          this.factorNameList.push(factorName);
        }
    }

  }
   public uniqueName(): ValidatorFn {
    return (control:AbstractControl) : {[key:string]:boolean} | null => {
        if (control.value !== undefined && this.factorNameList.length > 0 && control.value !=null) {
          if( this.factorNameList.includes((control.value).trim()))
          {
             return { 'unique': true };

          }
        }
        return null;
    };
  }
   public uniqueCode(): ValidatorFn {
    return (control:AbstractControl) : {[key:string]:boolean} | null => {
        if (control.value !== undefined && this.factorCodeList.length > 0 && control.value !=null) {
          if( this.factorCodeList.includes((control.value).trim()))
          {
             return { 'unique': true };

          }
        }
        return null;
    };
  }
}
