import { Component, OnInit, ViewChild } from '@angular/core';
import { PerformanceFrameworkCompanyService } from '../performance-framework-company.service';
import {Router, ActivatedRoute } from '@angular/router';

import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';

@Component({
  selector: 'app-view-company-framework-archive',
  templateUrl: './view-company-framework-archive.component.html',
  styleUrls: ['./view-company-framework-archive.component.css']
})
export class ViewCompanyFrameworkArchiveComponent implements OnInit {
  companyId: number;
  dataSource: any = [];
  displayedColumns: any = [];
  flag: boolean = true;
  paginator: any;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatSort) sort: MatSort;

  constructor(private performanceFrameworkCompanyService: PerformanceFrameworkCompanyService, private activatedRoute: ActivatedRoute,private router:Router) {
    this.activatedRoute.params.subscribe(params => {
      this.companyId = params.companyId;
    })
  }

  ngOnInit() {
    this.performanceFrameworkCompanyService.getArchievedFrameworkforCompany(this.companyId).subscribe(data => {
      this.dataTablePopulation(data);
    })
  }
  dataTablePopulation(archievedData) {
    let jsonArray = [];
    let count = 0;
    for (let archieveVal of archievedData) {
      let newArray = {};
      newArray['Sno'] = ++count;
      newArray['versionNumber'] = archieveVal.frameworkVersion;
      newArray['startDate'] = (archieveVal.createdDateString) ? archieveVal.createdDateString : "-";
      newArray['archivedDate'] = (archieveVal.lastUpdatedDate) ? archieveVal.lastUpdatedDate : "-";
      newArray['frameworkId'] = archieveVal.frameworkId;
      jsonArray.push(newArray);
    }

    this.displayedColumns = ['Sno', 'versionNumber', 'startDate', 'archivedDate', 'Actions']
    this.dataSource = new MatTableDataSource(jsonArray);

  }
  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
   redirectToViewFrameworkPage(frameworkId)
   {
       let frameworkDetails = {};
       frameworkDetails['frameworkId'] = frameworkId;
       frameworkDetails['redirectFromCopyCompanyPage'] =false;
       frameworkDetails['redirectFromArchievePage'] =true;
       frameworkDetails['companyId'] = this.companyId;

       this.router.navigate(['/viewCompanyFramework'], { queryParams: frameworkDetails, skipLocationChange: true });

   }


}
