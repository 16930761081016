import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class HttpErrorService implements HttpInterceptor {
  contentType: any;
  updatedRequest: any;
  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = sessionStorage.getItem('token');
    //how to update the request Parameters
    if (request.headers.has("Content-Type")) {
      this.contentType = request.headers.get("Content-Type");
    }
    // console.log("inside interceptor************************", this.contentType);

    if (this.contentType == null || this.contentType == undefined || this.contentType != "multipart/form-data") {
      this.updatedRequest = request.clone({
        headers: request.headers.set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${​​token}`)
      });
    }
    else {
      this.updatedRequest = request.clone({
        headers: request.headers.delete('Content-Type')
      });
    }
    //logging the updated Parameters to browser's console
    // console.log("Before making api call : ", this.updatedRequest);
    return next.handle(this.updatedRequest).pipe(
      tap(
        event => {
          //logging the http response to browser's console in case of a success
          if (event instanceof HttpResponse) {
            // console.log("api call success :", event);
          }
        },
        error => {
          //logging the http response to browser's console in case of a failure
          if (error) {
            console.log("api call error :", error);
          }
        }
      )
    );
  }
}


