import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { AddEditSuccessComponent } from '../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTableDataSource, MAT_SELECTION_LIST_VALUE_ACCESSOR } from '@angular/material';
import { HttpResponse } from '@angular/common/http';
import { FundService } from '../fund.service';
import { AddFundComponent } from '../add-fund/add-fund.component';

@Component({
  selector: 'app-list-fund',
  templateUrl: './list-fund.component.html',
  styleUrls: ['./list-fund.component.css']
})
export class ListFundComponent implements OnInit {

  fundDetailsList: any = [];
  displayedColumns: any = [];
  jsonArray: any = [];
  jsonArrayForCompanyDetails: any = [];
  jsonArrayForAnnualReturn: any = [];
  dataSource: any = [];
  displayedCompanyColumns: any;
  originalColumnNames: any;
  displayedAnnualReturnColumns: any;
  originalAnnualReturnColumnNames: any;
  paginator: any;

  constructor(private fundService: FundService, private addEditSuccessComponent: AddEditSuccessComponent, private addFundComponent: AddFundComponent) { }

  @Input() set fundDataList(val) {
    this.fundDetailsList = val;
    this.fetchDataTable(this.fundDetailsList);
  }
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  @Output() fundComponent = new EventEmitter();

  ngOnInit() {
  }
  fetchDataTable(fundDetailsList) {
    this.displayedColumns = [];
    this.jsonArray = [];
    var count = 0;
    for (let i of fundDetailsList) {
      var newArray = {};
      newArray['sno'] = ++count;
      newArray['id'] = i.id;
      newArray['fundName'] = i.name;
      newArray['istinNumber'] = i.isinCode;
      newArray['investmentAmount'] = i.investmentAmount;
      newArray['currency'] = i.currency;
      newArray['esgFlag'] = i.esgFlag;
      newArray['companyVOList'] = i.companyVOList;
      newArray['annualReturnDetailsVOList'] = i.annualReturnDetailsVOList;
      this.jsonArray.push(newArray);
    }
    this.displayedColumns = ['Sno', 'FundName', 'IstinNumber', 'InvestmentAmount', 'Currency', 'Actions']
    this.dataSource = new MatTableDataSource(this.jsonArray);
  }
  editFund(fundObj) {
    let companyVOList = [];
    for (let companyVal of fundObj.companyVOList) {
      companyVOList.push({
        'id': companyVal.id,
        'investmentPercentage': companyVal.investmentPercentage
      })
    }
    this.fundComponent.emit({
      id: fundObj.id,
      name: fundObj.fundName,
      isinCode: fundObj.istinNumber,
      investmentAmount: fundObj.investmentAmount,
      currency: fundObj.currency,
      companyVOList: companyVOList,
      annualReturnDetailsVOList: fundObj.annualReturnDetailsVOList,
      esgFlag:fundObj.esgFlag
    })
  }
  deleteFund(fundId) {
    let message;
    message = "You Want To Delete Fund Details";
    this.addEditSuccessComponent.showConfirmationSwal(message, "Yes", "No").then((isConfirm) => {
      if (isConfirm.value) {
        this.fundService.deleteFund(fundId).subscribe((data) => {
          message = 'Deleted Fund Details';
          this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
            this.addFundComponent.resetFundForm();
            this.addFundComponent.getFundList();
          });
        }, (error) => {

        });
      }
    });
  }

  viewFundCompanyDetails(companyVOList) {
    this.jsonArrayForCompanyDetails = [];
    for (let company of companyVOList) {
      let newArray = {};
      newArray['companyName'] = company.companyName;
      newArray['investmentPercentage'] = company.investmentPercentage;
      this.jsonArrayForCompanyDetails.push(newArray);
    }
    this.displayedCompanyColumns = ['companyName', 'investmentPercentage'];
    this.originalColumnNames = ['Company Name', 'Investment Percentage'];
  }
  viewFundAnnualReturnDetails(annualReturnDetailsVOList) {
    this.jsonArrayForAnnualReturn = [];
    annualReturnDetailsVOList.sort((a, b) => parseInt(a.year) - parseInt(b.year));
    this.jsonArrayForAnnualReturn = annualReturnDetailsVOList;
    this.displayedAnnualReturnColumns = ['year', 'annualReturn'];
    this.originalAnnualReturnColumnNames = ['Year', 'Annual Return'];

  }

    applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;

  }
}
