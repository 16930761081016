import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gamified-drilldown',
  templateUrl: './gamified-drilldown.component.html',
  styleUrls: ['./gamified-drilldown.component.css']
})
export class GamifiedDrilldownComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
