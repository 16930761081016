import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IndicatorMasterService } from '../../indicator-master.service';
import {  FormBuilder, Validators } from '@angular/forms';
import { AddConstantsComponent } from '../add-constants/add-constants.component';
import swal from 'sweetalert2';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';

@Component({
  selector: 'app-list-constants',
  templateUrl: './list-constants.component.html',
  styleUrls: ['./list-constants.component.css']
})
export class ListConstantsComponent implements OnChanges {

  constantValues: any;
  displayedColumns: any;
  originalColumnNames : any;
  colIndex: number = 0;
  jsonArray: any=[];
  constantsList: any;
  constantTypeId: number;
  top:any;

  @Input() set topWrapper(val){
    this.top=val;
  }
  @Input() constantOutputComponent;

  @Input() set constantSelected(val) {    
    this.constantTypeId = val;
  }

  @Output() constantOutComponent = new EventEmitter();

  constructor(private indicatorMasterService: IndicatorMasterService, private formBuilder: FormBuilder,private constantComponent :  AddConstantsComponent, private addEditSuccessComponent: AddEditSuccessComponent) { }

   ngOnChanges(changes: SimpleChanges) {
    if (changes['constantSelected']) {
      this.getConstantsValueList(changes['constantSelected'].currentValue);
    }
  }

  delete(constantType) {
    var confirmMessage = "You Want To Delete This Constant Value";
    this.addEditSuccessComponent.showConfirmationSwal(confirmMessage,'Yes','No').then((isConfirm) => {
      if (isConfirm.value) {
       this.indicatorMasterService.deleteConstant(constantType.id).subscribe((data) => {
            var deleteSuccess = 'Deleted Constant Value';
            this.addEditSuccessComponent.showSuccessSwal(deleteSuccess)
              .then((value) => {
              this.getConstantsValueList(0);
              this.constantComponent.resetConstantForm();
              });
        },(error)=>{
          console.log(error);
        });
      }
    });
  }

  edit(constantType) {
    this.constantOutComponent.emit({
      id: constantType.id,
      constantType: constantType.constantType,
      value: constantType.value,
      code: constantType.code,
    });
  }

  getConstantsValueList(constantType) {
    if (constantType == undefined || constantType == 0) {
      this.indicatorMasterService.getConstantsList().subscribe((data) => {
        this.constantsList = data;
        this.dataTablePopulation(this.constantsList);
      })
    }
    else {
      this.indicatorMasterService.getConstantsListByConstantType(constantType).subscribe((data) => {
        this.constantsList = data;
        this.dataTablePopulation(this.constantsList);
      })
    }
  }
  dataTablePopulation(constantValues) {
    this.displayedColumns = [];
    this.originalColumnNames = [];
    this.jsonArray = [];

    var count = 0;
    for (let i of constantValues) {
      var newArray = {};
      newArray['Sno'] = ++count;
      newArray['id'] = i.id;
      newArray['constantType'] = i.constantType;
      newArray['value'] = i.value;
      newArray['code'] = i.code;
      this.jsonArray.push(newArray);
    }
    this.displayedColumns = ['Sno', 'constantType', 'value','code', 'Actions']
    this.originalColumnNames = ['Sl.No', 'Constant Type', 'Constant Value','Constant Code', 'Actions']
  }
}