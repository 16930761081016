import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import {  MatTableDataSource } from '@angular/material';
import swal from 'sweetalert2';
import { AddQuestionComponent } from '../add-question/add-question.component';
import { AddEditSuccessComponent } from '../../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';


@Component({
  selector: 'app-list-question',
  templateUrl: './list-question.component.html',
  styleUrls: ['./list-question.component.css']
})
export class ListQuestionComponent implements OnInit {

  displayedColumns: any;
  originalColumnNames : any;
  top:any;
  jsonArray:any = [];
  
  @Input() set topWrapper(val){
    this.top=val;
  }
  @Output() question = new EventEmitter();
  @Input() set questionsList(val) {

    var jsonArray;
    var allQuestions = val;
  
    if (allQuestions) {
      this.jsonArray = this.generateDataTableDetails(allQuestions);
    }
    else
    this.jsonArray = [];
    this.dataTablePopulation();
  }

  constructor(private addEditSuccessComponent: AddEditSuccessComponent,private assessmentService: AssessmentTemplateMasterdataService,private addComponent:AddQuestionComponent) { }

  ngOnInit() {

  }
  generateDataTableDetails(allQuestions) {
 
    this.displayedColumns = [];
    this.originalColumnNames = [];
    var jsonArrayForDataTable = [];
    var count = 0;
    for (let i of allQuestions) {
      var newArray = {};
      newArray['Sno'] = ++count;
      newArray['id'] = i.id;
      newArray['Factor'] = i.factorVO.name;
      newArray['SubFactor'] = i.subFactorVO.subFactorName;
      newArray['Question'] = i.questionText;
      newArray['editDelete'] =i.editDelete; 
      newArray['subFactorVO'] = i.subFactorVO;
      newArray['factorVO'] = i.factorVO;
      newArray['responseOptionsVOList'] = i.responseOptionsVOList;
      newArray['responseName'] = i.responseName;
      newArray['questionTypeId'] = i.questionTypeId;

      jsonArrayForDataTable.push(newArray);

    }
    return jsonArrayForDataTable;

  }
  dataTablePopulation() {

    this.displayedColumns = ['Sno', 'Factor', 'SubFactor', 'Question', 'Actions']
    this.originalColumnNames = ['Sl.No', 'Factor', 'Subfactor', 'Question', 'Actions']
   
  }
 
  editQuestion(questionObj) {
    this.question.emit({ id: questionObj.id, factorVO:questionObj.factorVO, subFactorVO: questionObj.subFactorVO, questionTypeId: questionObj.questionTypeId, questionText: questionObj.Question, responseName: questionObj.responseName, responseOptionsVOList: questionObj.responseOptionsVOList });
  }

  deleteQuestion(questionObj) {
   this.addComponent.resetForm();
    swal({
      title: '<p class="swal2-title-warning">Are You Sure?</p>',
      text: "You Want To Delete Question",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.assessmentService.deleteQuestion(questionObj.id).subscribe(data => {
          // swal({
          //   title: 'Success!',
          //   text: 'Deleted Question',
          //   type: 'success',
          //   timer : 2000,
          //   confirmButtonText: 'Ok'
          // })
          let msg="Deleted Question";
          this.addEditSuccessComponent.showSuccessSwal(msg)
          .then((value) => {
            this.refreshDataTable();
          });

        });

      }
    });
  }
  refreshDataTable() {
    this.assessmentService.getQuestionsList().subscribe(questions => {
      var jsonArrayForDataTable = [];
      this.jsonArray = this.generateDataTableDetails(questions);
      this.dataTablePopulation();
    });
  }
}