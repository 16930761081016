import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router'

import { ParametersComponent } from './modules/report/ParameterDashboard/components/parameters/parameters.component';
import { SubParametersComponent } from './modules/report/ParameterDashboard/components/subparameters/subparameters.component';
import { ParameterlevelComponent } from './modules/report/ParameterDashboard/components/parameterlevel/parameterlevel.component';
import { IndicatorscheduleComponent } from './modules/report/ParameterDashboard/components/indicatorschedule/indicatorschedule.component';
import { IndicatordetailsComponent } from './modules/report/ParameterDashboard/components/indicatordetails/indicatordetails.component';
import { CompanylevelComponent } from './modules/report/ParameterDashboard/components/companylevel/companylevel.component';
import { CompanydashboardComponent } from './modules/report/CompanyDashboard/components/companydashboard/companydashboard.component';
import { CompanyListDetailsComponent } from './modules/report/CompanyDashboard/components/company-list-details/company-list-details.component';
import { PeerCompanyDashboardComponent } from './modules/report/peer-company-dashboard/components/peerdashboard/peer-company-dashboard.component';
import { PeergrouplistComponent } from './modules/report/peer-company-dashboard/components/peergrouplist/peergrouplist.component';
import { CompanyComplianceDashboardComponent } from './modules/report/ComplianceDashboard/components/company-compliance-dashboard/company-compliance-dashboard.component';
import { CompanyComplianceDrilldownComponent } from './modules/report/ComplianceDashboard/components/company-compliance-drilldown/company-compliance-drilldown.component';
import { IndicatorComplianceDashboardComponent } from './modules/report/ComplianceDashboard/components/indicator-compliance-dashboard/indicator-compliance-dashboard.component';
import { IndicatorComplianceDrilldownComponent } from './modules/report/ComplianceDashboard/components/indicator-compliance-drilldown/indicator-compliance-drilldown.component';
import { CompanyDetailsComponent } from './modules/report/CompanyDashboard/components/company-details/company-details.component';
import { CompanyComplianceMonthlyDrilldownComponent } from './modules/report/ComplianceDashboard/components/company-compliance-monthly-drilldown/company-compliance-monthly-drilldown.component';
import { IndicatorComplianceMonthlyDrilldownComponent } from './modules/report/ComplianceDashboard/components/indicator-compliance-monthly-drilldown/indicator-compliance-monthly-drilldown.component';
import { CapitalViewComponent } from './modules/report/ImpactDashboard/components/CapitalView/capital-view/capital-view.component';
import { CapitalViewDrillDownComponent } from './modules/report/ImpactDashboard/components/CapitalView/capital-view-drill-down/capital-view-drill-down.component';
import { SdgViewComponent } from './modules/report/ImpactDashboard/components/SDGView/sdg-view/sdg-view.component';
import { SdgViewDrillDownComponent } from './modules/report/ImpactDashboard/components/SDGView/sdg-view-drill-down/sdg-view-drill-down.component';
import { AddQuestionComponent } from './modules/assessment/Question/components/add-question/add-question.component';
import { AddFactorComponent } from './modules/assessment/Factor/components/add-factor/add-factor.component';
import { AddResponseOptionsComponent } from './modules/assessment/Response/components/add-response-options/add-response-options.component';
import { AddPanelComponent } from './modules/assessment/panel/components/add-panel/add-panel.component';
import { ListAssessmentComponent } from './modules/assessment/AssessmentRecord/components/list-assessment-by-user/list-assessment.component';
import { AddSubFactorComponent } from './modules/assessment/SubFactor/components/add-sub-factor/add-sub-factor.component';
import { InitiateAssessmentComponent } from './modules/assessment/AssessmentRecord/components/initiate-assessment/initiate-assessment.component';
import { AddAssessmentTemplateComponent } from './modules/assessment/AssessmentTemplate/components/add-assessment-template/add-assessment-template.component';
import { ListAssessmentCompaniesComponent } from './modules/assessment/AssessmentRecord/components/list-assessment-companies/list-assessment-companies.component';
import { LoadRecordPageComponent } from './modules/assessment/AssessmentRecord/components/load-record-page/load-record-page.component';
import { SubfactorUserMappingComponent } from './modules/assessment/SubFactor/components/subfactor-user-mapping/subfactor-user-mapping.component';
import { ListAssessmentTemplateQuestionsComponent } from './modules/assessment/AssessmentTemplate/components/list-assessment-template-questions/list-assessment-template-questions.component';
import { ListTemplateComponent } from './modules/assessment/AssessmentTemplate/components/list-template/list-template.component';
import { TemplateSettingsComponent } from './modules/assessment/AssessmentTemplate/components/template-settings/template-settings.component';
import { ParameterLevelDrillDownComponent } from './modules/report/ImpactDashboard/components/ImpactLevelDrillDown/parameter-level-drill-down/parameter-level-drill-down.component';
import { CompanyLevelDrillDownComponent } from './modules/report/ImpactDashboard/components/ImpactLevelDrillDown/company-level-drill-down/company-level-drill-down.component';
import { EditAssessmentTemplateQuestionsComponent } from './modules/assessment/AssessmentTemplate/components/edit-assessment-template-questions/edit-assessment-template-questions.component';
import { ViewRecordPageComponent } from './modules/assessment/AssessmentRecord/components/view-record-page/view-record-page.component';
import { AssessmentConsolidatedViewComponent } from './modules/assessment/AssessmentRecord/components/assessment-consolidated-view/assessment-consolidated-view.component';
import { ListAssessmentsComponent } from './modules/assessment/AssessmentRecord/components/list-assessments/list-assessments.component';
import { WorldMapComponent } from './modules/report/world-map/world-map.component';
import { AddSubcategoryComponent } from './modules/indicator-master/subcategory/add-subcategory/add-subcategory.component';
import { SearchPerformanceTemplateComponent } from './modules/performance-template/search-performance-template/search-performance-template.component';
import { AddPerformanceTemplateConfComponent } from './modules/performance-template/add-performance-template-conf/add-performance-template-conf.component';
import { AddSdgComponent } from './modules/indicator-master/sdg/add-sdg/add-sdg.component';
import { AddConstantsComponent } from './modules/indicator-master/constants/add-constants/add-constants.component';
import { AddIndicatorStackComponent } from './modules/indicator-master/indicator-stack/add-indicator-stack/add-indicator-stack.component';
import { AddRatingGroupComponent } from './modules/indicator-master/rating-group/add-rating-group/add-rating-group.component';
import { AddUnitOfMeasurementComponent } from './modules/indicator-master/unit-of-measurement/add-unit-of-measurement/add-unit-of-measurement.component';
import { ListIndicatorComponent } from './modules/indicator/list-indicator/list-indicator.component';
import { SearchIndicatorComponent } from './modules/indicator/search-indicator/search-indicator.component';
import { AddDatapointComponent } from './modules/indicator-master/datapoint/add-datapoint/add-datapoint.component'
import { IndicatorDatapointMappingComponent } from './modules/indicator-master/datapoint/indicator-datapoint-mapping/indicator-datapoint-mapping.component'
import { FrameworkConfigurationComponent } from './modules/configuration/framework-configuration/framework-configuration.component';
import { AddCompanyComponent } from './modules/configuration/company/add-company/add-company.component';
import { AddPerformanceTemplateComponent } from './modules/performance-template/add-performance-template/add-performance-template.component';
import { AddIndicatorComponent } from './modules/indicator/add-indicator/add-indicator.component';
import { ListCompanyFrameworkComponent } from './modules/performance-framework/companyLevel/list-company-framework/list-company-framework.component';
import { ListParameterDataFrameworkComponent } from './modules/performance-framework/higherLevel/list-parameter-data-framework/list-parameter-data-framework.component';
import { AddCompanyTypeComponent } from './modules/configuration/company-type/add-company-type/add-company-type.component';
import { ListCompanyTypeComponent } from './modules/configuration/company-type/list-company-type/list-company-type.component';
import { ViewPerformanceFrameworkComponent } from './modules/performance-framework/higherLevel/view-performance-framework/view-performance-framework.component';
import { AddUnitGroupComponent } from './modules/indicator-master/unit-group/add-unit-group/add-unit-group.component';
import { ViewArchivesComponent } from './modules/performance-framework/higherLevel/view-archives/view-archives.component';
import { AddCompanyIndicatorConfigurationsComponent } from './modules/performance-framework/companyLevel/add-company-indicator-configurations/add-company-indicator-configurations.component';
import { ViewCompanyFrameworkComponent } from './modules/performance-framework/companyLevel/view-company-framework/view-company-framework.component';
import { ListCompanyForCopyComponent } from './modules/performance-framework/companyLevel/list-company-for-copy/list-company-for-copy.component';
import { AddFrameworkIndicatorsComponent } from './modules/performance-framework/higherLevel/add-framework-indicators/add-framework-indicators.component';
import { PerformanceRecordConfigurationComponent } from './modules/performance-record/performance-record-configuration/performance-record-configuration.component';
import { LoadPerformanceRecordIndicatorListComponent } from './modules/performance-record/load-performance-record-indicator-list/load-performance-record-indicator-list.component';
import { ViewCompanyFrameworkArchiveComponent } from './modules/performance-framework/companyLevel/view-company-framework-archive/view-company-framework-archive.component';
import { ListActivePerformanceFrameworkTemplatesComponent } from './modules/performance-framework/higherLevel/list-active-performance-framework-templates/list-active-performance-framework-templates.component';
import { GpsDashbordComponent } from './modules/report/GpsDashbord/component/gps-dashbord/gps-dashbord.component';
import { AddFundComponent } from './modules/fund/add-fund/add-fund.component';
import { FundViewComponent } from './modules/report/FundDashboard/components/fund-view/fund-view.component';
import { FundViewDrilldownComponent } from './modules/report/FundDashboard/components/fund-view-drilldown/fund-view-drilldown.component';
import { InvestorViewComponent } from './modules/report/InvestorDashboard/components/investor-view/investor-view.component';
import { AssessmentQuestionTemplateComponent } from './modules/assessment/AssessmentTemplate/components/assessment-question-template/assessment-question-template.component';
import { AddCompanyInfoComponent } from './modules/configuration/company/add-company-info/add-company-info.component';
import { AddParityHomogeneityLevelsComponent } from './modules/data-quality-master/parity-homogeneity-levels/add-parity-homogeneity-levels/add-parity-homogeneity-levels.component';
import { ListParityHomogeneityLevelsComponent } from './modules/data-quality-master/parity-homogeneity-levels/list-parity-homogeneity-levels/list-parity-homogeneity-levels.component';
import { AddDatasourceComponent } from './modules/data-quality-master/datasource/add-datasource/add-datasource.component';
import { AddDatalevelScoresComponent } from './modules/data-quality-master/datalevel-scores/add-datalevel-scores/add-datalevel-scores.component';
import { IndicatorKeywordComponent } from './modules/indicator-master/indicator-keyword/indicator-keyword.component';
import { RelationshipDashboardComponent } from './modules/report/relationship-dashboard/relationship-dashboard.component';
import { LikeMindedBandProfileComponent } from './modules/report/like-minded-band-profile/like-minded-band-profile.component';
import { CompanyReportComponent } from './modules/report/company-report/company-report.component';
import { AddCategoryKeywordComponent } from './modules/categoryKeyword/add-category-keyword/add-category-keyword.component';
import { MediaViewComponent} from './modules/report/MediaDashboard/components/media-view/media-view.component';
import { ScheduleWiseRecordComponent } from './modules/performance-record/schedule-wise-record/schedule-wise-record.component';
import { CompanyReportDetailsComponent } from './modules/configuration/company/company-report-details/company-report-details.component';
import { AddRatingAgencyComponent } from './modules/configuration/rating-agency/add-rating-agency/add-rating-agency.component';

import { SustainanalyticRatingAgencyComponent } from './modules/report/CompanyDashboard/components/sustainanalytic-rating-agency/sustainanalytic-rating-agency.component';

import { OwlanalyticsRatingAgencyComponent } from './modules/report/CompanyDashboard/components/owlanalytics-rating-agency/owlanalytics-rating-agency.component';
import { IntegratedDashboardComponent } from './modules/report/CompanyDashboard/components/integrated-dashboard/integrated-dashboard.component';
import { SrayRatingAgencyComponent } from './modules/report/CompanyDashboard/components/sray-rating-agency/sray-rating-agency.component';
import { HomeComponent } from './modules/home/home.component';
import { InvestorDashboardComponent } from './modules/report/InvestmentDashboard/components/investor-dashboard/investor-dashboard.component';
import { BankerDashboardComponent } from './modules/report/banker-dashboard/components/banker-dashboard.component';
import { AsessmentDashboardComponent } from './modules/report/assessment-dashboard/components/asessment-dashboard.component';
import { GamifiedDashboardComponent } from './modules/report/gamified-dashboard/gamified-dashboard.component';
import { GamifiedDrilldownComponent } from './modules/report/gamified-drilldown/gamified-drilldown.component';
import { AddAlternateDatasourceComponent } from './modules/configuration/alternate datasource/add-alternate-datasource/add-alternate-datasource.component';
import { AddPrimaryDatasourceComponent } from './modules/configuration/primary-datasource/add-primary-datasource/add-primary-datasource.component';
import { ListCompanyViewComponent } from './modules/configuration/list-company-view/list-company-view.component';
import { SubsidiaryCompanyComponent } from './modules/configuration/company/subsidiary-company/subsidiary-company.component';
import { LinkIndicatorComponent } from './modules/indicator/link-indicator/link-indicator.component';
import { AlternateDetailsComponent } from './modules/configuration/alternate datasource/alternate-details/alternate-details.component';
import { ReportFrameworkStandardsComponent } from './modules/report/ImpactDashboard/components/ReportFramework/report-framework-standards/report-framework-standards.component';
const routes: Routes = [

  //only for demo purpose => FOR DEMO CODE BELOW
  { path: 'companyReportDashboard',component:CompanyReportComponent},
  //till here


  //World map
  { path: 'WorldMapComponent', component: WorldMapComponent },

  //Report Service Dashboard  Routes
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: 'performanceManagement/dashboard/parameters', component: ParametersComponent },
  { path: 'performanceManagement/dashboard/subParameters/:id', component: SubParametersComponent },
  { path: 'performanceManagement/dashboard/listReports/:level_no/:parameter_id', component: ParameterlevelComponent },
  { path: 'performanceManagement/dashboard/indicatorSchedules/:id', component: IndicatorscheduleComponent },
  { path: 'performanceManagement/dashboard/indicator/:id', component: IndicatordetailsComponent },
  { path: 'performanceManagement/dashboard/companyDetails/:level_no/:company_selected_parameter_id', component: CompanylevelComponent },

  //client Dashboard
  { path: 'performanceManagement/dashboard/companies', component: CompanyListDetailsComponent },
  { path: 'performanceManagement/dashboard/companyDashboard/:id', component: CompanydashboardComponent },
  { path: 'performanceManagement/dashboard/companyDetails', component: CompanyDetailsComponent },
  { path: 'performanceManagement/dashboard/integratedCompany/:id', component: IntegratedDashboardComponent },
  { path: 'performanceManagement/dashboard/sustainAnalytics/:id', component: SustainanalyticRatingAgencyComponent },
  { path: 'performanceManagement/dashboard/owlAnalytics/:id', component: OwlanalyticsRatingAgencyComponent },
  { path: 'performanceManagement/dashboard/sRay/:id', component: SrayRatingAgencyComponent },

  //Impact Dashboard
  { path: 'impactAssessment/dashboard/capitalView', component: CapitalViewComponent },
  { path: 'impactAssessment/dashboard/capitalViewDrillDown', component: CapitalViewDrillDownComponent },
  { path: 'impactAssessment/dashboard/sdgView', component: SdgViewComponent },
  { path: 'impactAssessment/dashboard/sdgViewDrillDown', component: SdgViewDrillDownComponent },
  { path: 'impactAssessment/dashboard/parameter-wise-drilldown', component: ParameterLevelDrillDownComponent },
  { path: 'impactAssessment/dashboard/company-wise-drilldown', component: CompanyLevelDrillDownComponent },
  { path: 'impactAssessment/dashboard/regReport', component: ReportFrameworkStandardsComponent },

  //Peer Group Clients Dashboard
  { path: 'performanceManagement/dashboard/peerGroup', component: PeergrouplistComponent },
  { path: 'performanceManagement/dashboard/peerCompanyDashboard/:id', component: PeerCompanyDashboardComponent },

  //Compliance Dashboard
  { path: 'dashboard/compliance/companyCompliance', component: CompanyComplianceDashboardComponent },
  { path: 'dashboard/compliance/companyComplianceDrillDown', component: CompanyComplianceDrilldownComponent },
  { path: 'dashboard/compliance/indicatorCompliance', component: IndicatorComplianceDashboardComponent },
  { path: 'dashboard/compliance/indicatorComplianceDrilldown', component: IndicatorComplianceDrilldownComponent },
  { path: 'dashboard/compliance/companyComplianceMonthlyDrillDown', component: CompanyComplianceMonthlyDrilldownComponent },
  { path: 'dashboard/compliance/indicatorComplianceMonthlyDrilldown', component: IndicatorComplianceMonthlyDrilldownComponent },

  { path: 'subcategory', component: AddSubcategoryComponent },
  { path: 'reportingFrameworksStandards', component: AddSdgComponent },

  //GPS Dashboard
  { path: 'dashboard/gps', component: GpsDashbordComponent },

  //Fund Dashboard
  { path: 'dashboard/fund', component: FundViewComponent },
  { path: 'dashboard/fundDrilldown', component: FundViewDrilldownComponent },
 //gamified dashboard
 { path: 'dashboard/gamified', component: GamifiedDashboardComponent },
 { path: 'dashboard/gamified-drilldown', component: GamifiedDrilldownComponent },
  //Investor Dashboard
  { path: 'dashboard/investor', component: InvestorViewComponent },

  //Relationship Dashboard
  { path: 'dashboard/relationshipDashboard', component: RelationshipDashboardComponent },

  //like minded band profile
  { path: 'dashboard/likeMindedBandProfile', component: LikeMindedBandProfileComponent },

  //Media Dashboard
  { path: 'dashboard/mediaDashboard' , component: MediaViewComponent},
  
  //Indicator
  { path: 'listIndicator', component: ListIndicatorComponent },
  { path: 'indicator', component: SearchIndicatorComponent },
  { path: 'addIndicator', component: AddIndicatorComponent },
  { path: 'addIndicator/:id', component: AddIndicatorComponent },
  {path:'linkIndicator',component:LinkIndicatorComponent},

  //Performance Template Routes
  { path: 'performanceTemplates', component: SearchPerformanceTemplateComponent },
  { path: 'addPerformanceTemplate', component: AddPerformanceTemplateComponent },
  { path: 'performanceTemplateIndicatorConfiguration', component: AddPerformanceTemplateConfComponent },
  { path: 'loadtemplateCreation', component: AddPerformanceTemplateComponent },
  { path: 'loadtemplateCreation/copy', component: AddPerformanceTemplateComponent },

  //factor
  { path: 'factor', component: AddFactorComponent },


  //unitgroup
  { path: 'unitGroup', component: AddUnitGroupComponent },

  //Subfactor
  { path: 'subfactor', component: AddSubFactorComponent },

  //sustainability assessment routes
  { path: 'questions', component: AddQuestionComponent },
  { path: 'responses', component: AddResponseOptionsComponent },
  { path: 'panel', component: AddPanelComponent },
  { path: 'assessments', component: ListAssessmentComponent },
  { path: 'tagSubFactortoUsers', component: SubfactorUserMappingComponent },
  { path: 'assessmentTemplate', component: AddAssessmentTemplateComponent },
  { path: 'recordAssessmentPageData/:assessmentId/:userId', component: LoadRecordPageComponent },
  { path: 'listAssessmentCompanies', component: ListAssessmentCompaniesComponent },
  { path: 'listassessmentTemplatequestions', component: ListAssessmentTemplateQuestionsComponent },
  { path: 'listTemplate', component: ListTemplateComponent },
  { path: 'templateSettingsData/:templateId', component: TemplateSettingsComponent },
  { path: 'editTemplateData/:templateId', component: EditAssessmentTemplateQuestionsComponent },
  { path: 'viewRecordAssessmentData', component: ViewRecordPageComponent },
  // {path:'consolidatedView' , component:AssessmentConsolidatedViewComponent},
  { path: 'assessmentListData/:companyId', component: ListAssessmentsComponent },
  { path: 'consolidatedAssessmentDetails', component: AssessmentConsolidatedViewComponent },

  //constants
  { path: 'constant', component: AddConstantsComponent },

  //Indicator Master data Service Routes
  { path: 'indicatorStack', component: AddIndicatorStackComponent },
  { path: 'ratingGroup', component: AddRatingGroupComponent },
  { path: 'unitOfMeasurement', component: AddUnitOfMeasurementComponent },
  { path: 'dataPoint', component: AddDatapointComponent },
  { path: 'indicatorDataPointMapping', component: IndicatorDatapointMappingComponent },
  { path: 'addIndicatorKeyword', component: IndicatorKeywordComponent },

  //parameter configuration
  { path: 'parameterConfiguration', component: FrameworkConfigurationComponent },

  //Company Type  
  { path: 'addCompanyType', component: AddCompanyTypeComponent },
  { path: 'companyTypes', component: ListCompanyTypeComponent },


  //company
  { path: 'company', component: AddCompanyComponent },
  { path: 'listCompanyView', component: ListCompanyViewComponent },
  {path:'subsidiary',component:SubsidiaryCompanyComponent},

  //performance framework
  { path: 'searchCompaniesFramework', component: ListCompanyFrameworkComponent },
  { path: 'viewCompanyFramework', component: ViewCompanyFrameworkComponent },
  { path: 'copyActiveIndicators', component: ListCompanyForCopyComponent },
  { path: 'addCompanyIndicatorsComponent', component: AddCompanyIndicatorConfigurationsComponent },
  { path: 'viewCompanyFrameworkArchives/:companyId', component: ViewCompanyFrameworkArchiveComponent },

  //performance framework higher
  { path: 'performanceFrameworkHigherLevel', component: ListParameterDataFrameworkComponent },
  { path: 'viewFrameworkDetails', component: ViewPerformanceFrameworkComponent },
  { path: 'performanceFrameworkArchive', component: ViewArchivesComponent },
  { path: 'performanceFrameworkIndicators', component: AddFrameworkIndicatorsComponent },
  { path: 'listActiveTemplates', component: ListActivePerformanceFrameworkTemplatesComponent },

  //performance record Routes
  { path: 'companyPerformanceRecord', component: LoadPerformanceRecordIndicatorListComponent },
  //performance record
  { path: 'performanceRecordConfiguration', component: PerformanceRecordConfigurationComponent },
  //fund
  { path: 'fund', component: AddFundComponent },
  { path: 'assessmentTemplateAdd', component: AssessmentQuestionTemplateComponent },
  //data acquistion routes
  { path: 'addCompanyInfo/:companyId', component: AddCompanyInfoComponent },
//category Keyword
  {path:'categoryKeyword',component:AddCategoryKeywordComponent},
  //rating Agency
  { path: 'ratingAgency', component: AddRatingAgencyComponent },

    //Primary DataSource

    { path: 'primarydataSource', component: AddPrimaryDatasourceComponent },
    { path: 'alternatedataSource', component: AddAlternateDatasourceComponent },
    { path: 'alternateDetails', component: AlternateDetailsComponent },
  //Data Quality Master
  
  { path: 'homogeneityParity', component: AddParityHomogeneityLevelsComponent },
  { path: 'dataSource', component: AddDatasourceComponent },
  { path: 'dataLevelScores', component: AddDatalevelScoresComponent },
  { path: 'companyPerformanceRecordScheduleWise' , component: ScheduleWiseRecordComponent},
  { path: 'companyReportDetails' , component:CompanyReportDetailsComponent},
  { path: 'investmentDashboard' , component:InvestorDashboardComponent},
  { path: 'dashboard/bankerDashboard' , component:BankerDashboardComponent},
  { path: 'dashboard/assessmentDashboard' , component:AsessmentDashboardComponent},
  { path: '**', redirectTo: 'performanceManagement/dashboard/parameters'},

  //Data Quality Master


  
]
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }