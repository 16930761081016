import { Component, OnInit, ViewChild } from '@angular/core';
import { PerformanceTemplateService } from '../performance-template.service';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator } from '@angular/material';
import { MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidationsService } from '../../../shared/Validations/custom-validations.service';
import { SearchIndicatorCommonComponent } from '../../search-indicator-common/search-indicator-common.component';
import swal from 'sweetalert2';
import { AddEditSuccessComponent } from '../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
@Component({
  selector: 'app-add-performance-template',
  templateUrl: './add-performance-template.component.html',
  styleUrls: ['./add-performance-template.component.css']
})
export class AddPerformanceTemplateComponent implements OnInit {

  companyTypeName: any;
  addTemplateForm: FormGroup;
  companyTypes: any;
  mandatoryIndicatorList: any;
  fullIndicatorList: any = {};
  jsonArray: any;
  displayedColumns: any;
  show: boolean = false;
  dataSource: any=[];
  submitJson: any;
  submitIndicatorList: any = {};
  submitIndicatorJsonList: any;
  templateVO: any = [];
  templateDetailsId: any;
  templateName: any;
  companyTypeId: any;
  copyTemplate: any;
  isSave: boolean;
  currentVersionStatus: any;
  paginator:any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  setDataSourceAttributes() {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
  }
  constructor(private performanceTemplateService: PerformanceTemplateService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router, private customValidationsService: CustomValidationsService,
    private addEditSuccessComponent: AddEditSuccessComponent) {

    this.addTemplateForm = this.createFormGroup();
    this.currentVersionStatus = this.activatedRoute.snapshot.queryParamMap.get('currentVersionStatus');
    this.templateDetailsId = this.activatedRoute.snapshot.queryParamMap.get('templateId');
    this.copyTemplate = this.activatedRoute.snapshot.queryParamMap.get('copyTemplateId');
  }




  ngOnInit() {
    this.getCompanyTypes();
    if (!this.templateDetailsId && !this.copyTemplate) {
      //get the list of mandatory Indicators
      this.getLoadDataForPerformanceTemplateCreation();
    }
    else {
      if(this.copyTemplate){ this.templateDetailsId = this.copyTemplate; }
      this.getPerformanceTemplateDetailById();
    }
  }


  createFormGroup() {

    return this.formBuilder.group({
      id: [this.templateDetailsId],
      name: ['', [Validators.required, this.customValidationsService.noWhitespaceValidator]],
      companyTypeVO: this.formBuilder.group({
        id: ['', Validators.required],
        code: [],
        name: [],
      }),
    })
  }


  getCompanyTypes() {
    this.performanceTemplateService.getCompanyTypes().subscribe(data => {
      this.companyTypes = data;
    });
  }

  getLoadDataForPerformanceTemplateCreation() {
    this.performanceTemplateService.getLoadDataForPerformanceTemplateCreation().subscribe(data => {
      this.mandatoryIndicatorList = data.indicatorVOList;
      this.indicatorListDataTable();
    });
  }

  resetTemplateForm() {
    //Reset to the original-- even if after edit make it as a new entry
    this.addTemplateForm.reset();
    this.addTemplateForm.get('companyTypeVO').get('id').setValue(0);
  }


  indicatorListDataTable() {

    this.displayedColumns = [];
    this.jsonArray = [];
    for (let indicator of this.mandatoryIndicatorList) {
      var newArray = {};
      newArray['id'] = indicator.id;
      newArray['code'] = indicator.code;
      newArray['name'] = indicator.name;
      newArray['type'] = indicator.indicatorType.value;
      if (indicator.indicatorDetailsVO != null && indicator.indicatorDetailsVO.ratingGroupVO && indicator.indicatorDetailsVO.ratingGroupVO.name != null) {
        newArray['ratingScale'] = indicator.indicatorDetailsVO.ratingGroupVO.name;
      }
      if (indicator.indicatorDetailsVO != null && indicator.indicatorDetailsVO.unitOfMeasurementVO && indicator.indicatorDetailsVO.unitOfMeasurementVO.name != null) {
        newArray['unitOfMeasurement'] = indicator.indicatorDetailsVO.unitOfMeasurementVO.name;
      }
      newArray['indicatorStack'] = indicator.indicatorStackVO.name;
      newArray['performanceArea'] = indicator.subcategoryVO.name;
      if (indicator.indicatorDetailsVO.mandatory == 1)
        newArray['mandatory'] = true;
      else
        newArray['mandatory'] = false;
      if (indicator.indicatorDetailsVO.critical == 1)
        newArray['critical'] = true;
      else
        newArray['critical'] = false;
      newArray['savedFlag'] = false;
      this.jsonArray.push(newArray);

      this.generateDataTable();
    }
  }

  generateDataTable() {

    this.dataSource = [];
    this.displayedColumns = ['mandatory','code', 'name', 'performanceArea', 'indicatorStack', 'type', 'unitOfMeasurement', 'ratingScale', 'Action']
    this.dataSource = new MatTableDataSource(this.jsonArray);
    this.setDataSourceAttributes();
  }

  deleteIndicator(indicator) {
    
    if (indicator != '') {
      if (!indicator.savedFlag) {
        const index = this.jsonArray.indexOf(this.jsonArray.find(x => x.id == indicator.id), 0);
        if (index > -1) {
          this.jsonArray.splice(index, 1);
          this.generateDataTable();
        }
      }
      else {
        var confirmMessage = "Can indicator be permanently removed from this template?";
        this.addEditSuccessComponent.showConfirmationSwal(confirmMessage, 'Yes', 'No').then((isConfirm) => {
          if (isConfirm.value) {
            this.performanceTemplateService.deleteIndicator(this.templateDetailsId, indicator.id).subscribe(data => {
              const index = this.jsonArray.indexOf(this.jsonArray.find(x => x.id == indicator.id), 0);
              if (index > -1) {
                this.jsonArray.splice(index, 1);
                this.generateDataTable();
              }
            });
          }
        });
      }
    }
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;

  }


  submitForm(isSave) {

    this.submitIndicatorJsonList = [];
    this.submitJson = this.addTemplateForm.value;
    for (let j of this.jsonArray) {
      var submitIndicatorList = {};
      submitIndicatorList['id'] = j.id;
      submitIndicatorList['indicatorConfigurationVO']=j.indicatorConfigurationVO;
      this.submitIndicatorJsonList.push(submitIndicatorList);
    }
    let transTypeName = this.companyTypeName;
    let selectedCompanyTypeId = this.addTemplateForm.get('companyTypeVO').get('id').value;
    if(!this.companyTypeName && this.companyTypes.find(x => x.id == selectedCompanyTypeId)){
      transTypeName = this.companyTypes.find(x => x.id == selectedCompanyTypeId).name;
      this.companyTypeName = transTypeName;
    }
    this.submitJson['companyTypeVO']['name'] = transTypeName;
    this.submitJson['indicatorVOList'] = this.submitIndicatorJsonList;
    this.submitJson['status'] = this.currentVersionStatus;

    if (this.addTemplateForm.valid) {
      this.performanceTemplateService.createTemplate(this.submitJson).subscribe(templateVOData => {
        if (isSave) {
          var message = "Successfully saved the Template Details";
          this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
          this.templateDetailsId =templateVOData['id'] ;
          this.templateName = templateVOData['name']
          this.currentVersionStatus=templateVOData['status'];
          this.addTemplateForm.get('id').setValue(this.templateDetailsId);
          this.router.navigate(['/loadtemplateCreation/'], { queryParams: { "templateId": this.templateDetailsId ,"currentVersionStatus": this.currentVersionStatus}, skipLocationChange: true });
            
          });
        }
        else {
          this.router.navigate(['/performanceTemplateIndicatorConfiguration/'], { queryParams: { "templateVO": JSON.stringify(templateVOData) }, skipLocationChange: true });
        }
      }, (error) => {
        console.log(error);
      });
    }
    else
    {
      this.addTemplateForm.get('name').markAsTouched();
      this.addTemplateForm.get('companyTypeVO').get('id').markAsTouched();
    }
      

  }


  showIndicatorPopup() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = "80%";
    dialogConfig.data = { 'nonMandatoryFlag': true };

    const dialogRef = this.dialog.open(SearchIndicatorCommonComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {

      if (data) {
        for (let j of data) {

          var newIndicatorList = {};


          if (!this.jsonArray.find(x => x.id == j.id)) {

            newIndicatorList['id'] = j.id;
            newIndicatorList['code'] = j.code;
            newIndicatorList['name'] = j.name;
            newIndicatorList['type'] = j.type;
            if (j.ratingScale != null)
              newIndicatorList['ratingScale'] = j.ratingScale;
            if (j.unitOfMeasurement != null)
              newIndicatorList['unitOfMeasurement'] = j.unitOfMeasurement;
            if (j.indicatorStack != null)
              newIndicatorList['indicatorStack'] = j.indicatorStack;
            if (j.performanceArea != null)
              newIndicatorList['performanceArea'] = j.performanceArea;
            newIndicatorList['mandatory'] = false;
            newIndicatorList['savedFlag'] = false;
            this.jsonArray.push(newIndicatorList);
          }
        }
      }

      this.generateDataTable();

    });
  }

  getPerformanceTemplateDetailById() {
    this.performanceTemplateService.getPerformanceTemplateDetailById(this.templateDetailsId).subscribe(
      data => {

        this.fullIndicatorList = data.indicatorVOList;
        this.templateName = data.name;
        if (data.companyTypeVO) {
          this.companyTypeId = data.companyTypeVO.id;
          this.companyTypeName = data.companyTypeVO.name;
        }
        this.indicatorListDataTableEdit();
        if (!this.copyTemplate)//if copyng no need to set the templatename,id etc.
        {
          this.addTemplateForm.get('name').setValue(this.templateName);
          this.addTemplateForm.get('companyTypeVO').get('id').setValue(this.companyTypeId);
          this.addTemplateForm.get('id').setValue(this.templateDetailsId);
        }
        else {
          this.templateDetailsId = 0;
        }
      });

  }

  indicatorListDataTableEdit() {

    this.displayedColumns = [];
    this.jsonArray = [];
    let ratingScaleVal;
    let unitMeasurementVal;
    for (let indicator of this.fullIndicatorList) {
      let indicatorConfigurationVO={};
      var newArray = {};
      newArray['id'] = indicator.id;
      newArray['code'] = indicator.code;
      newArray['name'] = indicator.name;
      newArray['type'] = indicator.indicatorType.value;
      ratingScaleVal = '-';
      if (indicator.indicatorDetailsVO != null && indicator.indicatorDetailsVO.ratingGroupVO != null) {
        ratingScaleVal = indicator.indicatorDetailsVO.ratingGroupVO.name;
      }
      newArray['ratingScale'] = ratingScaleVal;
      unitMeasurementVal = '-';
      if (indicator.indicatorDetailsVO != null && indicator.indicatorDetailsVO.unitOfMeasurementVO != null) {
        unitMeasurementVal = indicator.indicatorDetailsVO.unitOfMeasurementVO.name;
      }
      newArray['unitOfMeasurement'] = unitMeasurementVal;
      
      newArray['indicatorStack'] = indicator.indicatorStackVO.name;
      newArray['performanceArea'] = indicator.subcategoryVO.name;
      if (indicator.indicatorConfigurationVO.mandatory == 1)
        newArray['mandatory'] = true;
      else
        newArray['mandatory'] = false;
      if (indicator.indicatorConfigurationVO.critical == 1)
        newArray['critical'] = true;
      else
        newArray['critical'] = false;
      newArray['savedFlag'] = true;
      newArray['indicatorConfigurationVO']=indicator.indicatorConfigurationVO;
      this.jsonArray.push(newArray);
    }
    this.generateDataTable();


  }

  checkIfTemplateExists(event) {
    if (event != '') {
      this.companyTypeId = event;
      this.performanceTemplateService.getTemplatesByCompanyType(this.companyTypeId).subscribe(data => {

        if (data != '') {
          var message = "Templates Already Exists In This Industry Group:" + "(" + data + ")";
          var title = "Please Select Another Industry Group!";
          this.addEditSuccessComponent.showFailedSwal(message,title).then((value) => {
            this.addTemplateForm.get('companyTypeVO').get('id').setValue('');
            //swal.close();
          });
        }
      }, (error) => {
        console.log(error);
      });

    }
  }
}
