import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import {Http, Response , Headers, RequestOptions} from '@angular/http';
import { Observable } from 'rxjs';
import { BaseUrlService } from '../../shared/base-url.service';

@Injectable({
  providedIn: 'root'
})
export class DataQualityMasterService {

  
  private baseUrl = BaseUrlService.PARAMATERCONFIGURATIONBASEURL;
  private indicatorWrapperUrl = BaseUrlService.INDICATORBASEURL;
  
  constructor(private http: HttpClient) { }

  getBaseUrl() : string{
    return this.baseUrl;
  }



  addParityHomogeneityConstants(addParityHomogeneityForm :any){

    let body = JSON.stringify(addParityHomogeneityForm);
    let url = this.indicatorWrapperUrl + '/addParityHomogeneityConstants';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })

    };

    return this.http.post(url, body, httpOptions);
  }

  fetchParityHomogeneityConstants():Observable<any> {
    return this.http.get(`${this.indicatorWrapperUrl}/fetchParityHomogeneityConstants`);
  }

  createOrUpdateDataSource(dataSourceList: any) {

    let body = JSON.stringify(dataSourceList);
    let url = this.baseUrl + '/dataSource';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(url, body, httpOptions);

  }
  fetchDataSourceList():Observable<any>
  {
    return this.http.get(`${this.baseUrl}/listDataSource`);
  }

  deleteDataSource(id): Observable<any> { 
	  let url = this.baseUrl + '/deleteDataSource/'+id;
	  const httpOptions = {
	    headers: new HttpHeaders({
	      'Content-Type': 'application/json',
	
	    })
	  };
	  return this.http.post(url, null, httpOptions);
  }
  createOrUpdateDataLevelScore(dataLevelScoreList: any) {

    let body = JSON.stringify(dataLevelScoreList);
    let url = this.baseUrl + '/dataLevelScores';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(url, body, httpOptions);

  }

  fetchDataLevelScore():Observable<any>
  {
    return this.http.get(`${this.baseUrl}/listDataLevelScores`);
  } 
}
