import { Injectable } from '@angular/core';
import { BaseUrlService } from '../../shared/base-url.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class CategoryKeywordService {
  private baseUrl = BaseUrlService.REPORTBASEURL;
  constructor(private http: HttpClient) { }

  createCategoryKeyword(addCategoryKeywordForm: any) {
    console.log(addCategoryKeywordForm);
    let url = this.baseUrl + '/categoryKeyword';
    let body = JSON.stringify(addCategoryKeywordForm);
    console.log(body);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })

    let headerOption = {
      'headers': headers
    }
    console.log(body);
    return this.http.post(url, body, headerOption);
  }
  getcategoryKeywordList(): Observable<any> {
    let url = this.baseUrl + '/categoryKeyword';
    return this.http.get(url);
  }
  checkCategoryNameValidation(id, categoryName): Observable<any> {
    let url = this.baseUrl + '/categoryKeyword' + '/validateCategoryName/' + id + '/' + categoryName;
    return this.http.get(url);
  }

  deleteCategory(categoryId) {
    let url = this.baseUrl + '/deletecategory/' + categoryId;
    return this.http.delete(url);
  }
  deleteCategoryKeyword(categoryKeywordId) {
    let url = this.baseUrl + '/deletecategoryKeyword/' + categoryKeywordId;
    return this.http.delete(url);
  }
}
