import { Component, OnInit ,SimpleChanges , Input} from '@angular/core';
import { IndicatorMasterService } from '../../indicator-master.service';
@Component({
  selector: 'app-list-indicator-datapoint-mapping',
  templateUrl: './list-indicator-datapoint-mapping.component.html',
  styleUrls: ['./list-indicator-datapoint-mapping.component.css']
})
export class ListIndicatorDatapointMappingComponent implements OnInit {

  dataPointsMappingData: any = [];
  dataTableFlag: boolean;
  jsonArray: any[];
  originalColumnNames: any[];
  displayedColumns: any[];
  indicatorSelectedId: any;
  @Input() set indicatorSelected(val) {
    this.indicatorSelectedId = val;
  }
  
  @Input() set dataPointsMapping(val) {
    this.dataPointsMappingData = val;
    this.dataTablePopulation();
  }
  
  constructor(private indicatorMasterService: IndicatorMasterService) { }

  ngOnInit() {
    this.getIndicatorDataPointMappingList();
  }

  getIndicatorDataPointMappingList() {
    this.indicatorMasterService.getTaggedDataPointsForIndicator().subscribe(data => {
      this.dataPointsMappingData = data;
      this.dataTablePopulation();
    });

  }
  dataTablePopulation() {
    this.displayedColumns = [];
    this.originalColumnNames = [];
    this.jsonArray = [];
    let count = 0;
    Object.keys(this.dataPointsMappingData).map( key => {
        let taggedDataPoints: string = '';
        let newArray = {};
        newArray['Sno'] = ++count;
        newArray['id'] = key; 
        newArray['Indicator'] = this.dataPointsMappingData[key].indicatorVO.name;
        let dpLeng=this.dataPointsMappingData[key].datapointVOList.length;
        for (let j = 0; j < dpLeng; j++) {
           if (j != dpLeng - 1)
            taggedDataPoints += this.dataPointsMappingData[key].datapointVOList[j].name + ',';
           else
             taggedDataPoints += this.dataPointsMappingData[key].datapointVOList[j].name;
        }
        newArray['TaggedDataPoints'] = taggedDataPoints;
        this.jsonArray.push(newArray);
  });  
    this.displayedColumns = ['Sno', 'Indicator', 'TaggedDataPoints'];
    this.originalColumnNames = ['Sl.No', 'Indicator', 'Tagged Data Points'];
  }

}
