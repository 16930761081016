import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { PeerCompanyService } from '../../peer-company.service';
import { MatPaginator, MatTableDataSource ,MatSort } from '@angular/material';

@Component({
  selector: 'app-peergrouplist',
  templateUrl: './peergrouplist.component.html',
  styleUrls: ['./peergrouplist.component.css']
})
export class PeergrouplistComponent implements OnInit {

  public peerGroupList:any;
  dataSource:any;
  displayedColumns : any;
  colIndex:number=0;
  jsonArray:any;
  flag:boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
   @ViewChild(MatSort) sort: MatSort;
  constructor(private _peerCompanyService:PeerCompanyService) { }

  ngOnInit() {
    this.flag=false;
    
    this._peerCompanyService.getPeerCompanyList().subscribe((peerGroupList)=>{
      this.peerGroupList = peerGroupList;
      if( this.peerGroupList.length != 0)
      
        this.dataTablePopulation(this.peerGroupList);
         },(error)=>{
        console.log(error);
});
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); 
    filterValue = filterValue.toLowerCase(); 
    this.dataSource.filter = filterValue;

    if(this.dataSource.filteredData.length==0){
      this.flag = true;
    }else{
      this.flag = false;
    }
  }
  dataTablePopulation(peerGroupList){
  
  
    this.displayedColumns=[];
    this.jsonArray =[];
    var count =0;
  
    for( let i of peerGroupList){
      
      var newArray = {};
      newArray['Sno'] = ++count;
      newArray['id'] = i.id;
      newArray['Name'] = i.peerGroupName;
      newArray['Company'] = i.noOfCompanies;
      this.jsonArray.push(newArray);
      
      }
  
      
      this.displayedColumns=['Sno','Name','Company','Actions'];
      this.dataSource = new MatTableDataSource(this.jsonArray);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
  
        
  }

  showPeerDashboard(peerGroupId){

   alert("this"+peerGroupId);
  }
}
