import { Component, OnInit,ViewChild } from '@angular/core';
import { FormControl,FormGroup,FormBuilder,Validators} from '@angular/forms';
import { ComplianceDashboardService} from '../../compliance-dashboard.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';


@Component({
  selector: 'app-indicator-compliance-drilldown',
  templateUrl: './indicator-compliance-drilldown.component.html',
  styleUrls: ['./indicator-compliance-drilldown.component.css']
})
export class IndicatorComplianceDrilldownComponent implements OnInit {

  periodData =[];
  indicatorDashboardForm:FormGroup;
  selectedPeriod=[];
  yearList=[];
  toggleList:boolean;
  isSubmit:boolean;
  isRegion:boolean;
  indicatorComplianceData=[];
  parameterNames : any =[];
  regionIds : any =[];
  sectorIds : any =[];
  yearIds : any =[];
  frequencyView: any;
  indicatorId:any;
  indicatorName:any;
  regionFlag:any;
  loading :boolean;
  emptyFlag:boolean;
  firstYear:string;
  secondYear:string;
  thirdYear:string;
  pageNumber:number;
  noOfPages:number;
  displayedColumns:any;
  dataSource:any;
  presentYearCount:number;
  initialPeriods : any = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor( private formBuilder: FormBuilder,private complianceDashboardService:ComplianceDashboardService,
              private activatedRoute: ActivatedRoute,private router:Router) {

      this.loading = false;
      this.toggleList=false;
      this.isSubmit=true;
      this.isRegion=false;
      this.firstYear = null;
      this.secondYear = null;
      this.thirdYear = null;
      this.indicatorId = this.activatedRoute.snapshot.queryParamMap.get('indicatorId');
      this.parameterNames = (this.activatedRoute.snapshot.queryParamMap.get('parameterName').length > 0)? this.activatedRoute.snapshot.queryParamMap.get('parameterName'):'-';
      this.indicatorName = (this.activatedRoute.snapshot.queryParamMap.get('indicatorName').length > 0)?this.activatedRoute.snapshot.queryParamMap.get('indicatorName'):'-';
      this.regionFlag = (this.activatedRoute.snapshot.queryParamMap.get('regionFlag').length > 0)?this.activatedRoute.snapshot.queryParamMap.get('regionFlag'):'-';
      if(this.regionFlag === "true"){
        this.regionIds = (this.activatedRoute.snapshot.queryParamMap.getAll('regionIds').length > 0)?this.activatedRoute.snapshot.queryParamMap.getAll('regionIds'):'-';
        this.isRegion=true;
      }        
      else{
        this.sectorIds = (this.activatedRoute.snapshot.queryParamMap.getAll('sectorIds').length > 0)?this.activatedRoute.snapshot.queryParamMap.getAll('sectorIds'):'-';
      }
      this.frequencyView = (this.activatedRoute.snapshot.queryParamMap.get('frequencyView').length > 0)?this.activatedRoute.snapshot.queryParamMap.get('frequencyView'):'-';

      this.yearIds = this.activatedRoute.snapshot.queryParamMap.getAll('yearIds');
      let yearListArray=[];
      for(let year of this.yearIds){
        yearListArray.push(parseInt(year));
      }
      this.selectedPeriod = yearListArray;
      this.initialPeriods = yearListArray;
      this.indicatorDashboardForm = this.formBuilder.group({
        period:[yearListArray,Validators.required]
      })
  }

  ngOnInit() {
      this.fetchDetailsQuarterlyIndicatorCompliance(1);
      this.noOfPages = Math.ceil(this.selectedPeriod.length / 3);
  }

  resetListView(){
    this.emptyFlag = true;
    this.isSubmit = false;
  }

  reset(){
      
      this.selectedPeriod = this.initialPeriods;
      this.fetchDetailsQuarterlyIndicatorCompliance(1);
      this.noOfPages = Math.ceil(this.selectedPeriod.length / 3);
      let valueArray = [];
      for(let i of this.initialPeriods)
      {
        valueArray.push(i);
      }
      this.indicatorDashboardForm.get("period").patchValue(valueArray);
      this.isSubmit = true;
  }

  fetchDetailsQuarterlyIndicatorCompliance(noPage:number){

      let complianceDashboardVO:any = {};
      complianceDashboardVO.indicatorId = this.indicatorId;
      complianceDashboardVO.regionFlag =this.regionFlag;
      complianceDashboardVO.regionIds=this.regionIds;
      complianceDashboardVO.sectorIds = this.sectorIds;
      complianceDashboardVO.frequencyView="QUARTERLY";
      complianceDashboardVO.yearIds = this.selectedPeriod;
      this.loading = true;
      this.complianceDashboardService.retrieveQuarterlyIndicatorComplianceData(complianceDashboardVO).subscribe(data => {
          this.indicatorComplianceData=[];
          this.processIndicatorComplianceData(data,noPage);
          this.loading = false;
      });
  }

  selectAll()
  {
    this.toggleList= !this.toggleList;
    let valueArray = [];
    if(this.toggleList)
    {
      for(let i of this.periodData)
      {
        valueArray.push(i);
      }
    }
    this.indicatorDashboardForm.controls.period.patchValue(valueArray);
  }

  onSubmit(noPage:number)
  {
      this.selectedPeriod = this.indicatorDashboardForm.get('period').value;
      if(this.indicatorDashboardForm.valid &&  this.selectedPeriod.length > 0 )
      {
        this.isSubmit = true;
        this.fetchDetailsQuarterlyIndicatorCompliance(noPage);
        this.noOfPages = Math.ceil(this.selectedPeriod.length / 3);
      }
  }

  processIndicatorComplianceData(data,noPage)
  {
    this.periodData = data.completeYearList;
    if(Object.keys(data).length > 0  )
    {
      let regionOrSectorName:string='', complianceList=[];

      if(data.yearList.length > 0)
      {
        this.yearList = data.yearList;
      }
      if(data.complainceDashboardCompanyListVOList!=null && data.complainceDashboardCompanyListVOList.length > 0)
      {
          complianceList = data.complainceDashboardCompanyListVOList;
          let headingCheckForFilterName =0;

          for(let i=0;i< complianceList.length;i++)
          {
            let companyVOList=[],indicatorComplianceList=[];
            if(this.isRegion)
            {
                regionOrSectorName = complianceList[i].region;
            }
            else
            {
                regionOrSectorName = complianceList[i].sector;
            }
            companyVOList = this.processCompanyList(complianceList[i].companyVOList);
            if(companyVOList!=null && companyVOList.length > 0)
            {
              let companyDetailsList=[];
                  for(let j=0;j< companyVOList.length;j++)
                  {
                    let  indicatorReportingComplianceList=[];

                    companyDetailsList = companyVOList[j].companyDetails;
                    if(headingCheckForFilterName == 0)
                    {
                      companyDetailsList[0]['filterHeading'] = true;
                      headingCheckForFilterName = 1;
                    }
                    else
                        companyDetailsList[0]['filterHeading'] = false;

                      companyDetailsList[0]['companyHeading'] = true;
                    indicatorReportingComplianceList =  this.processReportedDetails(companyDetailsList);
                
                    indicatorComplianceList.push({
                      'companyName':companyVOList[j].company,
                      'companyDetails':indicatorReportingComplianceList
                    })

                  }
            }
            this.indicatorComplianceData.push({
              'filterName':regionOrSectorName,
              'companyList':indicatorComplianceList
            })
            headingCheckForFilterName =0;
          }
      }
    }
      this.getDataTablePopulation(noPage);

  }

  processReportedDetails(companyList)
  {
    for(let y=0;y < companyList.length;y++)
    {
      let yearwiseList = companyList[y].yearwiseReportedDetails;

      for(let z=0;z< yearwiseList.length;z++)
      {
            let status = yearwiseList[z].reportedStatusQuarterly;
            let yearArray=[];

            //quarterly means 4 quarters
            for(let index = 1 ; index <= 4 ; index++){
              
                if(index in status){
                  yearArray.push({"key": index,"value":status[index]});
                }
                else{
                  yearArray.push({"key": index,"value":"NA"});
                }
            }
            yearwiseList[z].reportedStatusQuarterly = yearArray;   
    }
  }
    return companyList;
  }

  processCompanyList(companyVOList)
  {
    let groupByCompanyName = this.groupBy(companyVOList, 'companyName');
    let finalArray=[];
    Object.keys(groupByCompanyName).map(function(key){            
          finalArray.push({"company": key,"companyDetails":groupByCompanyName[key]});
    })
    return finalArray;
  }

  groupBy(objectArray, property) {
    return objectArray.reduce(function (acc, obj) {
  
        var key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});
  }

  viewMonthlyDrilldownDetails(year){

    let complianceDashboardMonthlyVO:any = {};
    complianceDashboardMonthlyVO.indicatorId = this.indicatorId;
    complianceDashboardMonthlyVO.indicatorName = this.indicatorName;
    complianceDashboardMonthlyVO.regionFlag =this.regionFlag;
    complianceDashboardMonthlyVO.regionIds=this.regionIds;
    complianceDashboardMonthlyVO.sectorIds = this.sectorIds;
    complianceDashboardMonthlyVO.year =year;
    complianceDashboardMonthlyVO.parameterName = this.parameterNames;
    complianceDashboardMonthlyVO.frequencyView ="MONTHLY";
    complianceDashboardMonthlyVO.yearList=this.selectedPeriod;
    this.router.navigate(['dashboard/compliance/indicatorComplianceMonthlyDrilldown'],{queryParams:complianceDashboardMonthlyVO, skipLocationChange: true});
  }

   getDataTablePopulation(pageNo:number) {

    let periodDataList = [];
    let startIndex = 3 * (pageNo - 1);
    let endIndex;

    if(pageNo == this.noOfPages){
      endIndex = this.selectedPeriod.length % 3;
       if(endIndex == 0)
        endIndex = 3;
    }
    else{
      endIndex = 3;
    }

    for(let i = startIndex ; i < (startIndex + endIndex); i++){
      periodDataList.push(this.selectedPeriod[i])
    }
    this.pageNumber = pageNo;
    this.displayedColumns=[];
    let dashboardArray = [];  
    this.emptyFlag = true;
    for( let indicatorComplianceData of  this.indicatorComplianceData){
      var paFlag=false;    
      for(let fetchCompanyData of indicatorComplianceData.companyList ){
        var companyNameFlag=false;
        for(let companydtl of fetchCompanyData.companyDetails ){

          var newArray = {};
          this.emptyFlag = false;
          newArray['filterNameFlag'] = false;  
          if(paFlag==false) {
            newArray['filterNameFlag'] = true; 
            paFlag=true; 
          }
          newArray['sectorOrRegionName'] = indicatorComplianceData.filterName;
          newArray['companyNameFlag'] = false;  
          if(companyNameFlag==false) {
            newArray['companyNameFlag'] = true; 
            companyNameFlag=true; 
          }
          newArray['companyName'] = fetchCompanyData.companyName;
          newArray['companyName'] = companydtl.companyName;
          newArray['frequency'] = companydtl.frequency;
          newArray['frequencyCount'] = 4;
          let yearIndex = 1;
          for(let yearwiseReported of companydtl.yearwiseReportedDetails ){
            if(periodDataList.includes(yearwiseReported.year)){
                if(yearIndex == 1){
                  this.firstYear = yearwiseReported.year;
                  yearIndex++;
                }
                else if(yearIndex == 2){
                  this.secondYear = yearwiseReported.year;
                  yearIndex++;
                }
                else if(yearIndex == 3){
                  this.thirdYear = yearwiseReported.year;
                  yearIndex++;
                }
                
                for(let data of yearwiseReported.reportedStatusQuarterly ){
                  newArray['yearwiseData_'+yearwiseReported.year+'_'+data.key] = data.value;
                }

            }
          }
          if(yearIndex==4){
            this.presentYearCount=3;
          }
          else if(yearIndex==3){
            this.presentYearCount=2;
          }
          else{
            this.presentYearCount=1;
          }
          dashboardArray.push(newArray);
        }
      }
      }

      if(periodDataList.length == 1){
      
        this.displayedColumns=[ 'FilterName','Company','FirstYear']
      }
      else if(periodDataList.length == 2){
        this.displayedColumns=['FilterName','Company','FirstYear','SecondYear']
      }
      else if(periodDataList.length >= 3){
        this.displayedColumns=['FilterName','Company','FirstYear','SecondYear','ThirdYear']
      }

      this.dataSource = new MatTableDataSource(dashboardArray);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
  }

}