import { Component, OnInit, ViewChild } from '@angular/core';
import { PerformanceFrameworkHigherService } from '../performance-framework-higher.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-performance-framework',
  templateUrl: './view-performance-framework.component.html',
  styleUrls: ['./view-performance-framework.component.css']
})
export class ViewPerformanceFrameworkComponent implements OnInit {

  levelId: any;
  parameterId: any;
  originalColumnNames: any[];
  unitOfMeasurement: string;
  flag: boolean;
  jsonArraySch: any;
  dataSourceSch: any;
  displayedColumnsSch: any[];
  versionId:any;
  frameworkIndicatorVOList: any;
  levelName:any;
  jsonArrayPopup: any[];
  displayedColumns: any[];
  dataSource: any;
  indicatorSchedules:any=[];
  startDate:any;
  endDate:any;
  redirectFromArchievePage:any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(private frameworkService: PerformanceFrameworkHigherService,private router:Router,private activatedRoute: ActivatedRoute) {
    // this.activatedRoute.params.subscribe(params => { this.versionId = params.versionId
    //  this.redirectFromArchievePage = params.redirectFromArchievePage;
    // });
    this.activatedRoute.queryParams.subscribe(params => {
      this.versionId = params.versionId;
      this.parameterId=params.parameterId;
      this.levelId=params.levelId;
      this.levelName=params.levelName;
      this.redirectFromArchievePage = params.redirectFromArchievePage;

    });
   }

  ngOnInit() {

      this.frameworkService.getFrameworkDetails(this.versionId).subscribe(data => {
    
      this.dataTableDetails(data)
     
    }, (error) => {
      console.log(error);

    });

  }
  redirectToArchievePage()
  {
    let parameterDetails = {};
    parameterDetails['parameterId'] = this.parameterId;
    parameterDetails['levelId'] = this.levelId;
    parameterDetails['levelName'] = this.levelName;
    this.router.navigate(['/performanceFrameworkArchive'], { queryParams: parameterDetails, skipLocationChange: true });

  }

  dataTableDetails(framework) {
    let unitOfMeasurement="";
    if (framework.indicatorVOList.length > 0)
    this.flag = true;
  else
    this.flag = false;
    this.levelName = framework.parameterLevelName;
    this.frameworkIndicatorVOList =  framework.indicatorVOList ;
    var count=0;

    if (this.frameworkIndicatorVOList.length == 0)
         
      //data table for indicators
      this.displayedColumns = [];
      this.jsonArrayPopup = [];

      for (let indicatorVO of this.frameworkIndicatorVOList) {
        if(indicatorVO.indicatorDetailsVO && indicatorVO.indicatorDetailsVO.unitOfMeasurementVO)
        this.unitOfMeasurement=indicatorVO.indicatorDetailsVO.unitOfMeasurementVO.code;

        var newArray = {};
        newArray['Slno'] = ++count;
        newArray['indicatorId'] = indicatorVO.id;
        newArray['indicatorCode'] = indicatorVO.code;
        newArray['indicatorName'] = indicatorVO.name;
        newArray['indicatorTypeId'] = indicatorVO.indicatorType.id;
        newArray['indicatorType'] = indicatorVO.indicatorType.value;
        newArray['indicatorTypeCode'] = indicatorVO.indicatorType.code;

        if (indicatorVO.indicatorConfigurationVO.frequency != null && indicatorVO.indicatorConfigurationVO.frequency.value != null)
            newArray['frequencyOfMeasurement'] = indicatorVO.indicatorConfigurationVO.frequency.value;
        else
            newArray['frequencyOfMeasurement'] = "-";


        newArray['mandatoryFlag'] = indicatorVO.indicatorDetailsVO.mandatory;
        newArray['criticalFlag'] = indicatorVO.indicatorDetailsVO.critical;


        if (indicatorVO.indicatorConfigurationVO.source != null && indicatorVO.indicatorConfigurationVO.source.value != null)
          newArray['source'] = indicatorVO.indicatorConfigurationVO.source.value;
        else
          newArray['source'] = "-";

        if (indicatorVO.indicatorConfigurationVO.responsibleUser != null)
          newArray['responsibleUser'] = indicatorVO.indicatorConfigurationVO.responsibleUser;
        else
          newArray['responsibleUser'] = "-";
        if (indicatorVO.indicatorConfigurationVO.aligned != null) {
          if (indicatorVO.indicatorConfigurationVO.aligned == 'Y')
            newArray['aligned'] = 'Yes';
          else
            newArray['aligned'] = 'No';
        }
        else
          newArray['aligned'] = "-";

        if (indicatorVO.indicatorConfigurationVO.finalTarget != null) {
          if (indicatorVO.indicatorType.code == "QR")
            newArray['finalTarget'] = indicatorVO.indicatorConfigurationVO.finalTargetValueRating;
          else if (indicatorVO.indicatorType.code == "QU")
            newArray['finalTarget'] = indicatorVO.indicatorConfigurationVO.finalTarget +"("+ this.unitOfMeasurement+")";
          else 
            newArray['finalTarget'] = indicatorVO.indicatorConfigurationVO.finalTarget;
        }
        else
          newArray['finalTarget'] = "-";




        newArray['startDate'] = indicatorVO.indicatorConfigurationVO.indicatorStartdate;
        newArray['endDate'] = indicatorVO.indicatorConfigurationVO.indicatorEnddate ;
        
        if( newArray['startDate']!=null){
          var date = new Date(indicatorVO.indicatorConfigurationVO.indicatorStartdate),
          mnth = ("0" + (date.getMonth()+1)).slice(-2),
          day  = ("0" + date.getDate()).slice(-2);
          newArray['startDate']= [day,mnth, date.getFullYear()].join("-");
        }
        else
          newArray['startDate'] ="-";
        
        if( newArray['endDate']!=null){
          var date = new Date(indicatorVO.indicatorConfigurationVO.indicatorEnddate),
          mnth = ("0" + (date.getMonth()+1)).slice(-2),
          day  = ("0" + date.getDate()).slice(-2);
          newArray['endDate']= [day,mnth, date.getFullYear()].join("-");
        }
        else
         newArray['endDate'] = "-";
       
    if(newArray['startDate'] != "-" && newArray['endDate']!="-")
        newArray['timeLine'] = newArray['startDate']+" To "+newArray['endDate'];
    else
    newArray['timeLine'] ="-";
    
        newArray['frameworkIndicatorDetailId']=indicatorVO.indicatorConfigurationVO.frameworkIndicatorDetailId;
        newArray['intermediateTarget'] = indicatorVO.indicatorConfigurationVO.intermediateTarget;
        if(indicatorVO.indicatorDetailsVO && indicatorVO.indicatorDetailsVO.unitOfMeasurementVO){
         unitOfMeasurement=indicatorVO.indicatorDetailsVO.unitOfMeasurementVO.code;
          
          newArray['unitOfMeasurement']="("+unitOfMeasurement+")";
          
        }
        else
          newArray['unitOfMeasurement']="";
          
        
        this.jsonArrayPopup.push(newArray);
    }
    this.displayedColumns = ['Slno','indicatorCode','indicatorName','indicatorType','frequencyOfMeasurement','source','responsibleUser','aligned','finalTarget','startDate','measurementSchedule'];
    this.dataSource=[];
    this.dataSource = new MatTableDataSource(this.jsonArrayPopup);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getSchedules(indicatorDetailId,element ){
  
    this.displayedColumnsSch = [];
    this.jsonArraySch = [];
    let count = 0;
    let intermediateTarget = element.intermediateTarget;
    let indicatorTypeCode = element.indicatorTypeCode;
    let startDate=element.startDate;
    let endDate=element.endDate;
    this.unitOfMeasurement=element.unitOfMeasurement;
    this.frameworkService.getSchedulesForIndicator(indicatorDetailId,element.indicatorTypeId).subscribe(schedules => {
     this.indicatorSchedules =  schedules;
   
//schedule data table
for (let i of schedules) {
 
  let newArray = {};
  newArray['id'] = ++count;
  newArray['Schedule'] = i.scheduleStartDate+" To "+i.scheduleEndDate;
  if(intermediateTarget && indicatorTypeCode != "QR"){
    // if(indicatorTypeCode=="QT")
    // newArray['Target'] = i.targetValue+"("+this.unitOfMeasurement+")" ;
    // else
    newArray['Target'] = i.targetValue ;
}
else if(intermediateTarget && indicatorTypeCode == "QR"){
  newArray['Target'] = i.targetValueRating ;
}
else{
  newArray['Target'] = '-';
}

 this.jsonArraySch.push(newArray);
}
this.originalColumnNames = [];
if(intermediateTarget){
  this.displayedColumnsSch = ['Schedule', 'Target'];
  this.originalColumnNames = ['Schedule', "Target"+this.unitOfMeasurement];
}

 else{
  this.displayedColumnsSch = ['Schedule'];
  this.originalColumnNames = ['Schedule'];

 }
 
 


this.startDate = (element.startDate != null) ? element.startDate : "-";
this.endDate = (endDate != null) ? endDate : "-";

//schedule data table

    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;  
    if (this.dataSource.filteredData.length == 0) {
      this.flag = false;
    } else {
      this.flag = true;
    }
  }


}
