import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup, ValidationErrors } from '@angular/forms';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { CustomValidationsService } from '../../../../shared/Validations/custom-validations.service';
import swal from 'sweetalert2';
import { ConfigurationService } from '../../configuration.service';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { IndicatorMasterService } from '../../../indicator-master/indicator-master.service';
import { document } from 'ngx-bootstrap';
import { map } from 'rxjs/operators';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Component({
  selector: 'app-add-primary-datasource',
  templateUrl: './add-primary-datasource.component.html',
  styleUrls: ['./add-primary-datasource.component.css']
})
export class AddPrimaryDatasourceComponent implements OnInit {

  topWrapper: any;
  addPrimaryDataForm: FormGroup;
  dataIngestionList=[];
  formatList:any=[]
  identifiersList=[]
  @ViewChild("topWrap") topWrap;
  @ViewChild("primaryDataForm") primaryDataForm;
  primaryDataSourceList:any=[]
  public totalfiles: [];
  isFileError:boolean=false;
  formatListForAPI:any=[]
  formatListForSFTPandManual:any=[];
  showAPIKey:boolean=false;
  showFileLocation:boolean=false;
  uniqueNameError: boolean=false;

  constructor(private configurationService: ConfigurationService, private formBuilder: FormBuilder, private customValidationsService: CustomValidationsService, 
    private addEditSuccessComponent: AddEditSuccessComponent,private indicatorMasterService: IndicatorMasterService,) {
    this.addPrimaryDataForm = this.createFormGroup();
  }

  ngOnInit() {
    this.topWrapper = this.topWrap;
    this.fetchAllPrimaryDataSourceConstants("PRIMARY_DATA_SOURCE_IDENTIFIER");
    this.fetchAllPrimaryDataSourceConstants("PRIMARY_DATA_SOURCE_METHOD");
    this.fetchAllPrimaryDataSourceConstants("PRIMARY_DATA_SOURCE_FORMAT");
    this.fetchPrimaryDataSourceData()

   
    
  }
  createFormGroup() {
    
    return this.formBuilder.group({
      id: [0],
      name: ['',[Validators.required]],
      data: ['',[Validators.required]],
      format: ['',[Validators.required]],
      links: ['',[Validators.required]],
      location: [''],
      key: [''],
      identifierIds:[],
      fileName:[''],
      fileUploadName:[]
    });
  }


  fetchAllPrimaryDataSourceConstants(constantType)
  {
    this.indicatorMasterService.getConstantsListByConstantType(constantType).subscribe((data) =>
    {
        if(constantType=="PRIMARY_DATA_SOURCE_IDENTIFIER")
        {
           this.identifiersList=data
        }
        else if(constantType=="PRIMARY_DATA_SOURCE_METHOD")
        {
       this.dataIngestionList=data
        }
        else if(constantType=="PRIMARY_DATA_SOURCE_FORMAT")
        {
          console.log("formatlist")
          console.log(data)
         this.formatListForSFTPandManual=data
          for(let format of data)
          {
            if(format.value==='JSON' || format.value==='XML')
            {
              this.formatListForAPI.push(format)
            }
          }
          console.log(this.formatListForAPI)
        }
    })
  }

  fetchPrimaryDataSourceData()
  {
    console.log("fetching...")
    this.configurationService.fetchAllPrimaryDataSources().subscribe((data) =>
    {
      console.log("backend data")
      console.log(data)
      this.primaryDataSourceList=data
    }
    ,(error) =>
    {
      console.log("error")
    })
  }

  defaultValues(editValue) {
    this.uniqueNameError=false;
    this.isFileError=false
    document.getElementById("uploadFile").innerHTML=""
    document.getElementById("uploadFile").innerHTML =editValue.fileName
    this.addPrimaryDataForm.patchValue({
      id: editValue.id,
      name:editValue.name,
      data: editValue.data,
      format: editValue.format,
      links: editValue.links,
      location: editValue.location,
      key:editValue.key,
      identifierIds:editValue.primaryDatasourceIdentifierVOList,
      fileName:editValue.fileName,
      fileUploadName:editValue.fileUploadName
    });
    console.log("default values")
    console.log(this.addPrimaryDataForm.value)
    // if(this.addPrimaryDataForm.value.id){
    // this.addPrimaryDataForm.controls.name.setValidators([this.UniqueSourceNameValidator()]);
    // this.addPrimaryDataForm.controls.name.updateValueAndValidity();
    // }
   

  }
  onSubmit() {
    console.log("submitt")
    if(this.addPrimaryDataForm.controls['fileName'].value=='' || this.addPrimaryDataForm.controls['fileName'].value==null)
   {
    this.isFileError=true;
   }
    const data=this.UniqueSourceNameValidator(this.addPrimaryDataForm.controls['id'].value,this.addPrimaryDataForm.controls['name'].value)
    data.then((result)=>
    {
      console.log(!(result!=null && result.length>0))
       if(!(result!=null && result.length>0))
       {
        if(this.addPrimaryDataForm.valid &&  !this.isFileError ){
          var message;
            this.configurationService.createOrUpdatePrimaryDataSource(this.addPrimaryDataForm.getRawValue()).subscribe((data) => {
                  if(this.addPrimaryDataForm.get('id').value > 0) {
                     message = "Successfully edited Primary Data Source";
                  }
                  else {
                     message = "Successfully added Primary Data Source";
                  }
                  this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
                  
                    this.resetPrimaryDataForm();
                    this.fetchPrimaryDataSourceData();
                    document.getElementById("uploadFile").innerHTML = ""
                  
                  });
            }, (error) => {
              console.log(error);
            });
        }
        else{
          this.addPrimaryDataForm.get('name').markAsTouched();
          this.addPrimaryDataForm.get('data').markAsTouched();
          this.addPrimaryDataForm.get('format').markAsTouched();
          this.addPrimaryDataForm.get('links').markAsTouched();
          this.addPrimaryDataForm.get('location').markAsTouched();
          this.addPrimaryDataForm.get('key').markAsTouched();
          this.addPrimaryDataForm.get('identifierIds').markAsTouched();
          return;
        }
       }
       else 
       {
       
       
        this.uniqueNameError=true;
       }
    })



  }


  resetPrimaryDataForm() {
    //Reset to the original-- even if after edit make it as a new entry
    this.primaryDataForm.resetForm();
    this.topWrap.nativeElement.classList.remove("top-form-wrapper-highlight");
    document.getElementById("uploadFile").innerHTML = ""
    this.isFileError=false
    this.uniqueNameError=false;
    console.log(this.formatList)
  }


 

  downloadTemplate(){
    let link = document.createElement("a");
          link.download = "Data Attribute Template.xls";
          link.href = "assets/Template/excelTemplate.xls";
          link.click();
          link.remove();
  }
  public handleFileInput(fileInput: any,event:any) {
    this.totalfiles = fileInput.item(0);
    console.log("this.totalfiles")
    console.log(this.totalfiles)
    document.getElementById("uploadFile").innerHTML = fileInput.item(0).name
    let uploadName =  fileInput.item(0).name.split(" ").join("_");
    let uploadFileName= new Date().getTime()+'_' + uploadName;
    this.addPrimaryDataForm.patchValue({
      fileName:  fileInput.item(0).name,
      fileUploadName:uploadFileName
    });
   
    this.addPrimaryDataForm.controls.fileName.setValidators([this.customValidationsService.excelFileExtension,this.customValidationsService.imageFileSize]);
    this.addPrimaryDataForm.controls.fileName.updateValueAndValidity();
    this.addPrimaryDataForm.controls.fileName.markAsTouched();
  
      const formData: any = new FormData();
        formData.append('file',fileInput.item(0));
        formData.append('fileUploadName', uploadFileName);
        this.isFileError=false;
        if(this.addPrimaryDataForm.controls.fileName.valid)
      this.configurationService.uploadPrimaryDataSourceFile(formData).subscribe(() => {
      });
      event.target.value = null
    
  }
  

  //1306--->API ID
  //1307 ---> SFTP ID
  //1311 --> Manual Id
  dataIngestionChange(id){
 
    var methodName=this.dataIngestionList.filter(obj => obj.id == id);
    console.log(methodName)
    if(methodName.length>0){
    var selectedMethodName=methodName[0].value;
    if(selectedMethodName=='API')
    {
      this.formatList=this.formatListForAPI
      this.showAPIKey=true;
      this.showFileLocation=false;
      this.addPrimaryDataForm.controls.key.setValidators([Validators.required]);
      this.addPrimaryDataForm.controls.key.updateValueAndValidity();
      this.addPrimaryDataForm.controls.location.clearValidators();
      this.addPrimaryDataForm.controls.location.updateValueAndValidity();
    }
    else if(selectedMethodName=='SFTP'){
      this.showAPIKey=false;
      this.showFileLocation=true;
       this.formatList=this.formatListForSFTPandManual
       this.addPrimaryDataForm.controls.key.clearValidators();
       this.addPrimaryDataForm.controls.key.updateValueAndValidity();
       this.addPrimaryDataForm.controls.location.setValidators([Validators.required]);
       this.addPrimaryDataForm.controls.location.updateValueAndValidity();
    }
    else if(selectedMethodName=='Manual'){
      this.formatList=this.formatListForSFTPandManual
      this.showAPIKey=false;
      this.showFileLocation=false;
      this.formatList=this.formatListForSFTPandManual
      this.addPrimaryDataForm.controls.location.clearValidators();
      this.addPrimaryDataForm.controls.location.updateValueAndValidity();
      this.addPrimaryDataForm.controls.key.clearValidators();
      this.addPrimaryDataForm.controls.key.updateValueAndValidity();
   
   }
    else {
      this.formatList=[];
      this.showAPIKey=false;
      this.showFileLocation=false
      this.addPrimaryDataForm.controls.location.clearValidators();
      this.addPrimaryDataForm.controls.location.updateValueAndValidity();
      this.addPrimaryDataForm.controls.key.clearValidators();
      this.addPrimaryDataForm.controls.key.updateValueAndValidity();
    }
  }
  }

async UniqueSourceNameValidator(id,name){
  id=id==null?0:id
  if(name!=""){
 const data=await this.configurationService.checkPrimarySourceNameValidation(id, name).toPromise();
 return data;
  }return;
 
}
}
