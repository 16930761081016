import { Component, OnInit, ViewChild } from '@angular/core';
import { PerformanceFrameworkHigherService } from '../performance-framework-higher.service';
import { FormBuilder, AbstractControl, FormGroup, FormControl } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AddEditSuccessComponent } from '../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';

@Component({
  selector: 'app-list-parameter-data-framework',
  templateUrl: './list-parameter-data-framework.component.html',
  styleUrls: ['./list-parameter-data-framework.component.css']
})
export class ListParameterDataFrameworkComponent implements OnInit {

  selectedParameter: any;
  dataSource: any = [];
  checkBoxSelectionForm: FormGroup;
  displayedColumns: any[];
  flag: boolean;
  parameterConfiguration: any;
  parameterSelectionForm: FormGroup;
  frameworkParams: any;
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild(MatSort) sort: MatSort;
  public loading = false;
  paginator: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    
  }
  constructor(private activatedRoute: ActivatedRoute,private higherLevelService: PerformanceFrameworkHigherService, private formBuilder: FormBuilder,private router:Router,private addEditSuccessComponent: AddEditSuccessComponent) {
    this.parameterSelectionForm = this.createFormGroup();
    this.checkBoxSelectionForm = this.createFormGroup();
    this.activatedRoute.queryParams.subscribe(params => {

      this.selectedParameter=params.selectedParameter;
       
       

    })
  }

  ngOnInit() {
    this.getFrameworkApplicableLevels();

  }
  createFormGroup() {
    return this.formBuilder.group({
      id: [],
    });

  }
  getFrameworkApplicableLevels() {
   this.loading=true;
    this.higherLevelService.getFrameworkApplicableParameters().subscribe(data => {
      this.frameworkParams = data;
     this.loading=false;
     if(this.selectedParameter)
     this.parameterSelectionForm.get('id').setValue(this.selectedParameter)
     else
      this.parameterSelectionForm.get('id').setValue(this.frameworkParams[0].id + '@' + this.frameworkParams[0].levelNumber);
      this.parameterConfiguration = this.frameworkParams[0].parameterConfigurationVO;

      this.dataTablePopulation(this.frameworkParams[0]);
    });



  }
  dataTablePopulation(parameter) {
    let frameworkVersionId;
    let frameworkStatus;
    if (parameter.parameterDataVOList.length > 0)
      this.flag = true;
    else
      this.flag = false;
    this.displayedColumns = [];
    let jsonArray = [];
    for (let parameterDataVO of parameter.parameterDataVOList) {


      var newArray = {};
      newArray['id'] = parameterDataVO.id;

      newArray['name'] = parameterDataVO.name;

      newArray['levelNumber'] = parameter.levelNumber;
      if (parameterDataVO.performanceFrameworkVO != null) {
        newArray['versionId'] = parameterDataVO.performanceFrameworkVO.frameworkId;
        frameworkVersionId = parameterDataVO.performanceFrameworkVO.frameworkId;
       frameworkStatus= parameterDataVO.performanceFrameworkVO.frameworkStatus;
	    newArray['frameworkStatus']=frameworkStatus;
       
      }

      else{
        newArray['frameworkStatus']="";
        newArray['versionId'] = 0;
        frameworkVersionId=0;
      }
        
        var abstractControl: AbstractControl = this.checkBoxSelectionForm;
        if (abstractControl instanceof FormGroup) {
          (<FormGroup>abstractControl).addControl(frameworkVersionId, new FormControl({ value: '', disabled: true }));

        }
      newArray['parameterId'] = parameter.id;

      newArray['checkBoxSelection'] = false;
      if (frameworkVersionId > 0) {
        if (frameworkStatus === "A") {
          this.checkBoxSelectionForm.controls[frameworkVersionId].enable();
          newArray['nonEditable'] = false;
        }
        else if (frameworkStatus === "D") {
          newArray['nonEditable'] = false;
          this.checkBoxSelectionForm.controls[frameworkVersionId].disable();
        }
        else {
          this.checkBoxSelectionForm.controls[frameworkVersionId].disable();
          newArray['nonEditable'] = true;
        }
      }
      else
      newArray['nonEditable'] = true;
      jsonArray.push(newArray);


    }
    this.displayedColumns = ['checkBoxSelection', 'name', 'Performance Framework', 'Archives', 'Actions']
    this.dataSource = [];
    this.dataSource = new MatTableDataSource(jsonArray);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;


  }
  noneSelected() {

    var formValueArray = this.checkBoxSelectionForm.value;

    var noneSelectedFlag = true;
    for (var i in formValueArray) {
      if (formValueArray[i] == true)
        noneSelectedFlag = false;
    }
    return noneSelectedFlag;


  }
  populateParameterDataList(event) {
   this.loading=true;
    let parameterId, parameterLevelId;
    var splitResult = event.split("@");
    parameterId = splitResult[0];
    parameterLevelId = splitResult[1];

    this.selectedParameter=event;
    

this.higherLevelService.getConfigurationAndDataForParameter(parameterId).subscribe(data=>{
  this.dataTablePopulation(data);
  this.loading=false;
  this.parameterConfiguration = data.parameterConfigurationVO;
})

  }
  editFramework(element){
    let parameterDetails = {};
    parameterDetails['name'] = element.name;
    parameterDetails['parameterId'] = element.parameterId;
    parameterDetails['levelId'] = element.id;
    parameterDetails['frameworkId'] = element.versionId;
   parameterDetails['frameworkStatus']=element.frameworkStatus;
   parameterDetails['selectedParameter']=this.selectedParameter;

    this.router.navigate(['/performanceFrameworkIndicators'], { queryParams: parameterDetails, skipLocationChange: true });
  }
  copyTemplate(element){
    //include this in the server call to find if there are any templates active- check length and if it is >0 add these
    var message="Would you like to use the existing templates?";
    this.addEditSuccessComponent.showConfirmationSwal(message,'Yes','No').then((isConfirm)=>
  {
      if (isConfirm.value) {

        let parameterDetails = {};
        parameterDetails['parameterId'] = element.parameterId;
        parameterDetails['levelId'] = element.id;
       parameterDetails['frameworkId'] = element.versionId;
        parameterDetails['name'] = element.name;
        parameterDetails['frameworkStatus']=element.frameworkStatus;
        parameterDetails['selectedParameter']=this.selectedParameter;
    
        this.router.navigate(['/listActiveTemplates'], { queryParams: parameterDetails, skipLocationChange: true });

      }
      else {
       

let parameterDetails = {};
parameterDetails['parameterId'] = element.parameterId;
parameterDetails['levelId'] = element.id;
parameterDetails['frameworkId'] = element.versionId;
parameterDetails['name'] = element.name;
parameterDetails['frameworkStatus']=element.frameworkStatus;
parameterDetails['selectedParameter']=this.selectedParameter;


this.router.navigate(['/performanceFrameworkIndicators'], { queryParams: parameterDetails, skipLocationChange: true });
      }

    });
  
    
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    if (this.dataSource.filteredData.length == 0) {
      this.flag = false;
    } else {
      this.flag = true;
    }
  }
  viewArchives(element) {
    let parameterDetails = {};
    parameterDetails['parameterId'] = element.parameterId;
    parameterDetails['levelId'] = element.id;
    parameterDetails['levelName'] = element.name;
    this.router.navigate(['/performanceFrameworkArchive'], { queryParams: parameterDetails, skipLocationChange: true });
  }

  onSubmit(){
	  var selectedFrameworksIds: any = [];
    var formValueArray = this.checkBoxSelectionForm.value;
    for (var i in formValueArray) {
      if (formValueArray[i] == true)
        selectedFrameworksIds.push(i);
    }

    this.higherLevelService.archiveSelectedFrameworks(selectedFrameworksIds).subscribe(data => {
      var message = "Successfully Archived Selected Frameworks";
      this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
        location.reload();
      });
  });
    
  }
  redirectToViewFrameworkPage(frameworkId)
  {
      let frameworkDetails = {};
      frameworkDetails['versionId'] =frameworkId;
      frameworkDetails['redirectFromArchievePage'] =false;
      this.router.navigate(['/viewFrameworkDetails'], { queryParams: frameworkDetails, skipLocationChange: true });

  }

}
