import { Component, OnInit ,ViewChild , Input, Output, EventEmitter} from '@angular/core';
import {MatTableDataSource,MatPaginator,MatSort} from '@angular/material';
import { CustomValidationsService } from '../../../../shared/Validations/custom-validations.service';
import { FormGroup,FormControl, FormBuilder, Validators } from '@angular/forms';
import { IndicatorMasterService } from '../../indicator-master.service';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component'; 
@Component({
  selector: 'app-list-rating-group',
  templateUrl: './list-rating-group.component.html',
  styleUrls: ['./list-rating-group.component.css']
})
export class ListRatingGroupComponent implements OnInit {

  displayedColumns : any;
  jsonArray:any;
  dataSource:any=[];
  isInitial: boolean;
  ratingGroups: any;
  ratingGroupObjId: number;
  ratingGroupValueId : number;
  groupIdSelected:any;
  i:number;
  addRatingGroupValue : any;
  totalGroupCount:number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Output() ratingGroupComponent = new EventEmitter();
  top:any;
  @Input() set topWrapper(val){
    this.top=val;
  }

  constructor(private addEditSuccessComponent: AddEditSuccessComponent,private masterService: IndicatorMasterService, private formBuilder: FormBuilder,private customValidationsService:CustomValidationsService) { 
  }

  ngOnInit() {
    this.displayedColumns=['column'];
    this.isInitial = true;
    this.ratingGroupObjId = undefined;
    this.getRatingGroupList(this.isInitial,this.ratingGroupObjId); 
    this.jsonArray =[];
    this.dataSource =[];
      
  }

  createFormGroup(ratingGroupId){
    return this.formBuilder.group({
        
      description: ['', [Validators.required, Validators.maxLength(50), this.customValidationsService.noWhitespaceValidator]],
      value: [, [Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/),Validators.maxLength(10)]],
      id:[],
      ratingGroupVO:this.formBuilder.group({
        id:[ratingGroupId],
        name:[],
      
      })
    });
    
  }

  deleteRatingGroup(ratingGroupId){
      
    var message ="You Want To Delete Rating Group";
    this.addEditSuccessComponent.showConfirmationSwal(message,"Yes","No").then((isConfirm) => {
      if (isConfirm.value) {

        this.masterService.deleteRatingGroup(ratingGroupId).subscribe((data)=>{
          var message =  'Deleted Rating Group';
        this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
            location.reload();
          });
        },(error)=>{
          
        });
      }
    });
  }

  editRatingGroup(ratingGroupId, ratingGroupName){
    if(this.top)
      this.top.nativeElement.classList.add("top-form-wrapper-highlight");
    window.scroll(0,0);  
    this.ratingGroupComponent.emit({ id:ratingGroupId, name: ratingGroupName });
  } 

  deleteRatingGroupValue(ratingGroupValueObj){

    var message ="You Want To Delete Rating Group Value";
    this.addEditSuccessComponent.showConfirmationSwal(message,"Yes","No").then((isConfirm) => {
      if (isConfirm.value) {
      this.masterService.deleteRatingGroupValue(ratingGroupValueObj.id).subscribe((data)=>{
      

            //Open the rating group values list for the group after addition
            this.isInitial = false;

            //Reload the values in an ajax way
            this.getRatingGroupList(this.isInitial,ratingGroupValueObj);

            //resetting so that wont replace if the former one was an ajax call
            this.ratingGroupValueId = undefined;
            this.groupIdSelected = undefined;
            this.addEditSuccessComponent.showSuccessSwal( 'Deleted Rating Group Value')

        },(error)=>{
          
        });
      }
    });
    
    
  }  

  editRatingGroupValue(ratingGroupValueObj,groupId){
    
    this.ratingGroupValueId = ratingGroupValueObj.id;
    this.groupIdSelected = groupId;
    //setting default values for the form
    this.addRatingGroupValue[groupId].patchValue({
      id:ratingGroupValueObj.id,
      description:ratingGroupValueObj.description,
      value: ratingGroupValueObj.value,
      
      ratingGroupVO:{
        id:groupId,

      }
    });
  
  } 

  onSubmit(ratingGroupId){

    this.addRatingGroupValue[ratingGroupId].controls['ratingGroupVO'].controls['id'].patchValue(ratingGroupId)
    if (this.addRatingGroupValue[ratingGroupId].valid)
    {
      this.masterService.createRatingGroupValue(this.addRatingGroupValue[ratingGroupId].value
      ).subscribe((myform)=>{

          //Open the rating group values list for the group after addition
          this.isInitial = false;
          

          //Reload the values in an ajax way
          this.getRatingGroupList(this.isInitial,ratingGroupId);

          //resetting so that wont replace if the former one was an ajax call
          this.ratingGroupValueId = undefined;
          this.groupIdSelected = undefined;
          
          if(this.addRatingGroupValue[ratingGroupId].get('id').value!=null)
            var message ="Successfully edited the Rating Group Value";
          else
            var message ="Successfully added the Rating Group Value"        
             this.addEditSuccessComponent.showSuccessSwal(message)
      },(error)=>{
        
      });
    }
   
  } 

  reset(ratingGroupId){

    this.ratingGroupValueId = undefined;
    this.groupIdSelected = undefined;
    this.addRatingGroupValue[ratingGroupId].reset();
    this.addRatingGroupValue[ratingGroupId].controls['ratingGroupVO'].controls['id'].patchValue(ratingGroupId)

  }

  getRatingGroupList(isInitial,ratingGroupObjId){

    this.masterService.getListOfRatingGroups().subscribe(data => {
        this.ratingGroups = data;  
        this.addRatingGroupValue = {};
        for (this.i = 0; this.i <this.ratingGroups.length; this.i++) {

            //Creating form for each Rating Group
            this.addRatingGroupValue[this.ratingGroups[this.i].id] = this.createFormGroup(this.ratingGroups[this.i].id);

        }

        this.dataTablePopulation();
      },
      (error)=>{
     
    }); 
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  dataTablePopulation(){
    let newArray = {};
    let index = 0;
    this.jsonArray=[];
    let dashboardArray= [];
    this.totalGroupCount = this.ratingGroups.length;
    let columnNo = 1;
    let count = 0;
    let data = [];
     for( let i of this.ratingGroups){
        newArray = {};
        newArray['id'] = i.id;
        newArray['name'] = i.name;
        newArray['ratingGroupValuesVOList'] = i.ratingGroupValuesVOList;
        data.push(newArray);
        count++;
        if(columnNo % 2 == 0){
          dashboardArray.push(data);
          columnNo = 1;
          data = [];
        }
        else{
          if(count == this.totalGroupCount){
            dashboardArray.push(data);
          }
          else{
            columnNo++;
          }
        }
           this.jsonArray.push(newArray);
      
    }
    this.dataSource = new MatTableDataSource(dashboardArray);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}
