import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ParagraphExtractService {
  private readonly url = "http://44.194.154.102:6503";
  public readonly awsUrl = "https://rwnphyra9i.execute-api.us-east-1.amazonaws.com";

  constructor(private http: HttpClient) {}

  paragraphExtract(formdata: any): Observable<any> {
    let url = this.url + "/paragraph_extract";
    console.log("service paragraph_extract+" + JSON.stringify(formdata));
    return this.http.post(url, formdata, { responseType: "blob" });

    //return this.http.get(this.url+'/paragraph_extract?filename='+fileName+'&file='+file+'&parent_id='+1, { responseType: 'blob' });
  }

  pdfToText(formdata: any): Observable<any> {
    let url = this.url + "/pdf_to_text_conversion";
    console.log("service pdf_to_text_conversion+" + JSON.stringify(formdata));
    console.log(url);

    return this.http.post(url, formdata, {
      headers: new HttpHeaders({
        "Content-Type": "multipart/form-data",
      }),
      responseType: "text",
    });

    // return this.http.get(this.url, { responseType: 'text' });
    //return this.http.get(this.url+'/pdf_to_text_conversion?filename='+fileName+'&pageno='+pageNo+'&file='+file, { responseType: 'blob' });
  }
  tableExtract(formdata: any): Observable<any> {
    let url = this.url + "/table_extract";
    console.log("+service table_extract");
    console.log(JSON.stringify(formdata));
    console.log(formdata.getAll("filename"));
    console.log(formdata.getAll("pageno"));
    console.log(formdata.getAll("file"));
    console.log(formdata.getAll("parent_id"));
    console.log(url);
    return this.http.post(url, formdata, {
      headers: new HttpHeaders({
        "Content-Type": "multipart/form-data",
      }),
      responseType: "blob",
    });

    // return this.http.get(this.url, { responseType: 'blob' });
    // return this.http.get(this.url+'/table_extract?filename='+fileName+'&pageno='+pageNo+'&file='+file+'&parent_id='+1, { responseType: 'blob' });
  }
  fetchPerformanceRecord() {
    let performanceRecordDataVO: any = {};
    performanceRecordDataVO["responsibleUser"] = "Tony George";
    let body = JSON.stringify(performanceRecordDataVO);
    let url = this.awsUrl + "/getPerformanceRecord";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http.post(url, body, httpOptions);
  }
}
