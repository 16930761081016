import { Injectable } from '@angular/core';
import { BaseUrlService } from '../../shared/base-url.service';
import { HttpHeaders } from '@angular/common/http';
import {Http, Response , Headers, RequestOptions} from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class IndicatorService {

  
  constructor(private http: HttpClient) { }
  private baseUrl = BaseUrlService.INDICATORBASEURL;
  private parameterBaseUrl = BaseUrlService.PARAMATERCONFIGURATIONBASEURL;
  private headers = new Headers({'Content-type':'application/json'});
  private options = new RequestOptions({headers:this.headers});
  
  getListOfAllIndicators(): Observable<any> {
    return this.http.get(`${this.baseUrl}/indicator`);
  }
  getSubCategory(categoryId): Observable<any> {
    
    return this.http.get(`${this.baseUrl}/subcategoryBycategory/`+categoryId);
  }
  getIndicatorSearchVO(): Observable<any>{
    return this.http.get(`${this.baseUrl}/searchindicator`);
  }
   getIndicatorStackList(subCatgoryId):Observable<any>{
    return this.http.get(`${this.baseUrl}/stackBySubcategory/`+subCatgoryId)
  }
  getIndicatorsForSearch(searchIndicatorForm:any): Observable<any>{
   
    let body = JSON.stringify(searchIndicatorForm); 
    let url = this.baseUrl+'/searchindicator';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
       
      })
    };

    return this.http.post(url, body, httpOptions);
  }
  deleteIndicator(indicatorId): Observable<any> {
   
    return this.http.delete(`${this.baseUrl}/indicator/`+indicatorId,{ responseType: 'text' });
  }
  tagIndicatorToDataPoints(indicatorDataPointForm: any): Observable<any> {

    let body = JSON.stringify(indicatorDataPointForm);
    let url = this.baseUrl + '/tagDataPointsToIndicator';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',

      })
    };

    return this.http.post(url, body, httpOptions);
  }
  getTaggedDataPointsForIndicator(indicatorId: any):Observable<any> {
    return this.http.get(`${this.baseUrl}/getIndicatorDataPointMappings/`+indicatorId);
  }
  
  getMasterDataOnLoad(indicatorId): Observable<any> {
       return this.http.get(`${this.baseUrl}/indicator/`+indicatorId);
  }

  getunitGroupList(): Observable<any>{
    return this.http.get(`${this.baseUrl}/listUnitGroups`);
  }
  getMasterDataByIndicatorType(indicatorTypeId): Observable<any> {    
    return this.http.get(`${this.baseUrl}/fetchMasterDataByIndicatorType/`+indicatorTypeId);
  } 
  getunitOfMeasurementList(unitGroupId): Observable<any> {   
     if(unitGroupId==0 || unitGroupId == undefined)
      return this.http.get(`${this.baseUrl}/unitOfMeasurement`); 
      else
      return this.http.get(`${this.baseUrl}/unitOfMeasurement/`+unitGroupId);
  } 


  fetchParameterDataApplicableToIndicator(indicatorId){
         return this.http.get(`${this.baseUrl}/fetchIndicatorsApplicableParameterByindicatorId/`+indicatorId);
  }
  getIndicatorList():Observable<any>{
   return this.http.get(`${this.baseUrl}/indicator`);

  }  
  createIndicator(indicatorForm:any){
    let body = JSON.stringify(indicatorForm); 
    let url = this.baseUrl+'/indicator';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
       
      })
    };

    	return this.http.post(url, body, httpOptions);
  	}
  	getIndicatorParameters(indicatorId):Observable<any> {
    	if(indicatorId == null || indicatorId ==undefined)
      		indicatorId=0;
      	return this.http.get(`${this.baseUrl}/getIndicatorParameters/`+indicatorId);
    }
    getIndicators(nonMandatoryflag): Observable<any> {
  
      return this.http.get(`${this.baseUrl}/getIndicators?isNonmandatory=`+nonMandatoryflag);
    }

     getChildParameterData(jsonArray:any){
      let body=JSON.stringify(jsonArray);
      let url=this.parameterBaseUrl+'/getParameterData';
      const httpOptions={
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };
      return this.http.post(url,body,httpOptions);
    }
    
 	getParameterData(id,levelNumber):Observable<any>{
  		return this.http.get(`${this.baseUrl}/getDataForParameter/`+id+'/'+levelNumber);
 	} 

   validateIndicatorCode(id, indicatorCode):Observable<any>{
     	return this.http.get(`${this.baseUrl}/indicator/validateCode/`+id+'/'+indicatorCode);
   }

   validateIndicatorName(id, indicatorName):Observable<any>{
     	return this.http.get(`${this.baseUrl}/indicator/validateName/`+id+'/'+indicatorName);
   }
}
