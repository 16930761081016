import { Component, OnInit,ViewChild } from '@angular/core';
import { FormControl,FormGroup,FormBuilder,Validators} from '@angular/forms';
import { ComplianceDashboardService} from '../../compliance-dashboard.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-indicator-compliance-monthly-drilldown',
  templateUrl: './indicator-compliance-monthly-drilldown.component.html',
  styleUrls: ['./indicator-compliance-monthly-drilldown.component.css']
})
export class IndicatorComplianceMonthlyDrilldownComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  periodData:any=[];
  indicatorDashboardForm:FormGroup;
  selectedPeriod=[];
  yearList=[];
  isSubmit:boolean;
  isRegion:boolean;
  toggleList:boolean;
  indicatorComplianceData=[];
  parameterNames : any =[];
  regionIds : any =[];
  sectorIds : any =[];
  year:any;
  frequencyView: any;
  indicatorId:any;
  indicatorName:any;
  regionFlag:any;
  quarterconstant:string;
  monthConstant:string;
  loading :boolean;

  emptyFlag:boolean;
  displayedColumns:any;
  dataSource:any;
  pageNumber:number;
  noOfPages:number;
  presentYear:number;
  FilterName:string;
  previousSelectedYear:any = [];
  previouslySelectedPeriod:any = [];

  constructor( private formBuilder: FormBuilder,private complianceDashboardService:ComplianceDashboardService,
              private activatedRoute: ActivatedRoute,private router:Router) {

      this.loading = false;
      this.quarterconstant ="QUARTERLY";
      this.monthConstant = "MONTHLY";
      this.isSubmit=true;
      this.isRegion=true;
      this.toggleList=false;
      this.indicatorId = this.activatedRoute.snapshot.queryParamMap.get('indicatorId');
      this.parameterNames = (this.activatedRoute.snapshot.queryParamMap.get('parameterName') != null)? this.activatedRoute.snapshot.queryParamMap.get('parameterName'):'-';
      this.indicatorName = (this.activatedRoute.snapshot.queryParamMap.get('indicatorName') != null)?this.activatedRoute.snapshot.queryParamMap.get('indicatorName'):'-';
      this.regionFlag = this.activatedRoute.snapshot.queryParamMap.get('regionFlag');
      if(this.regionFlag){
        this.regionIds = this.activatedRoute.snapshot.queryParamMap.getAll('regionIds');
        this.FilterName = "Region";
      }
      else{
        this.sectorIds = this.activatedRoute.snapshot.queryParamMap.getAll('sectorIds');
        this.FilterName = "Sector";
      }
      this.presentYear    =   parseInt(this.activatedRoute.snapshot.queryParamMap.get('year'));
      this.selectedPeriod.push(this.presentYear);
      this.frequencyView = (this.activatedRoute.snapshot.queryParamMap.get('frequencyView').length > 0)?this.activatedRoute.snapshot.queryParamMap.get('frequencyView'):'MONTHLY';

      this.indicatorDashboardForm = this.formBuilder.group({
        period:[this.selectedPeriod,Validators.required]

      })

      this.previousSelectedYear.push(parseInt(this.activatedRoute.snapshot.queryParamMap.get('year')));

      let yearListArray=[];
      for(let year of this.activatedRoute.snapshot.queryParamMap.getAll('yearList')){
        yearListArray.push(parseInt(year));
      }
      this.previouslySelectedPeriod = yearListArray;
  }

  resetListView(){
    this.emptyFlag = true;
    this.isSubmit = false;
  }

  reset(){

    this.selectedPeriod = this.previousSelectedYear;
    this.isSubmit = true;
    this.emptyFlag = false;
    this.fetchDetailsMonthlyIndicatorCompliance(1);
    this.noOfPages = this.selectedPeriod.length;
    let valueArray = [];
    for(let i of this.previousSelectedYear)
    {
      valueArray.push(i);
    }
    this.indicatorDashboardForm.get("period").patchValue(valueArray);
  }

  ngOnInit() {
    
    this.fetchDetailsMonthlyIndicatorCompliance(1);
    this.noOfPages = this.selectedPeriod.length;
  }

  fetchDetailsMonthlyIndicatorCompliance(noPage){

    let complianceDashboardVO:any = {};
    complianceDashboardVO.indicatorId = this.indicatorId;
    complianceDashboardVO.regionFlag =this.regionFlag;
    complianceDashboardVO.regionIds = this.regionIds;
    complianceDashboardVO.sectorIds = this.sectorIds;
    complianceDashboardVO.frequencyView = "MONTHLY";
    complianceDashboardVO.yearIds = this.selectedPeriod;
    this.loading = true;
    this.complianceDashboardService.retrieveMonthlyIndicatorComplianceData(complianceDashboardVO).subscribe(data => {
        this.indicatorComplianceData=[];
        this.processIndicatorComplianceData(data,noPage);
        this.loading = false;
    });
  }

  selectAll()
  {

    this.toggleList= !this.toggleList;
    let valueArray = [];
    if(this.toggleList)
    {
      for(let i of this.periodData)
      {
        valueArray.push(i);
      }
    }
    this.indicatorDashboardForm.controls.period.patchValue(valueArray);
  }

  onSubmit(noPage:number)
  {
      this.isSubmit = true;
      this.selectedPeriod = this.indicatorDashboardForm.get('period').value;
      if(this.indicatorDashboardForm.valid &&  this.selectedPeriod.length > 0 )
      {
        this.fetchDetailsMonthlyIndicatorCompliance(noPage);
        this.noOfPages = this.selectedPeriod.length;
      }
  }

  processIndicatorComplianceData(data,noPage)
  {
    this.periodData = data.completeYearList;
    if(Object.keys(data).length > 0  )
    {
      let regionOrSectorName:string='', complianceList=[];

      if(data.yearList.length > 0)
      {
        this.yearList = data.yearList;
      }
      if(data.complainceDashboardCompanyListVOList!=null && data.complainceDashboardCompanyListVOList.length > 0)
      {
          complianceList = data.complainceDashboardCompanyListVOList;
          let headingCheckForFilterName =0;

          for(let i=0;i< complianceList.length;i++)
          {
            let companyVOList=[],indicatorComplianceList=[];
            if(this.isRegion)
            {
                regionOrSectorName = complianceList[i].region;
            }
            else
            {
                regionOrSectorName = complianceList[i].sector;
            }
            companyVOList = this.processCompanyList(complianceList[i].companyVOList);
            if(companyVOList!=null && companyVOList.length > 0)
            {
                  let companyDetailsList=[];
                  for(let j=0;j< companyVOList.length;j++)
                  {
                    let  indicatorReportingComplianceList=[];

                    companyDetailsList = companyVOList[j].companyDetails;
                    if(headingCheckForFilterName == 0)
                    {
                      companyDetailsList[0]['filterHeading'] = true;
                      headingCheckForFilterName = 1;
                    }
                    else
                        companyDetailsList[0]['filterHeading'] = false;

                    companyDetailsList[0]['companyHeading'] = true;
                    indicatorReportingComplianceList =  this.processReportedDetails(companyDetailsList);
                    indicatorComplianceList.push({
                      'companyName':companyVOList[j].company,
                      'companyDetails':indicatorReportingComplianceList
                    })

                  }

            }
            this.indicatorComplianceData.push({
              'filterName':regionOrSectorName,
              'companyList':indicatorComplianceList
            })
            headingCheckForFilterName =0;
          }

      }
    }
    this.getDataTablePopulation(noPage);
  }

  processReportedDetails(companyList)
  {
    for(let y=0;y < companyList.length;y++)
    {
      let yearwiseList = companyList[y].yearwiseReportedDetails;
      let frequency  =  companyList[y].frequency;

      if(frequency==="QUARTERLY")
      {
        for(let z=0;z< yearwiseList.length;z++)
        {
              let status = yearwiseList[z].reportedStatusQuarterly;
              let yearArray=[];
              
              //quarterly means 4 quarters
              for(let index = 1 ; index <= 4 ; index++){
                if(index in status){
                  yearArray.push({"key": index,"value":status[index]});
                }
                else{
                  yearArray.push({"key": index,"value":"NA"});
                }
              }

              yearwiseList[z].reportedStatusQuarterly = yearArray;  
        }

      }
      else if(frequency==="MONTHLY")
      {
        for(let z=0;z< yearwiseList.length;z++)
        {
              let status = yearwiseList[z].reportedStatusMonthly;
              let yearArray=[];
              //monthly means 12 months
              for(let index = 1 ; index <= 12 ; index++){
                if(index in status){
                  yearArray.push({"key": index,"value":status[index]});
                }
                else{
                  yearArray.push({"key": index,"value":"NA"});
                }
              }
              yearwiseList[z].reportedStatusMonthly = yearArray;  
        }

      }
  }
    return companyList;
  }

  processCompanyList(companyVOList)
  {
    let groupByCompanyName = this.groupBy(companyVOList, 'companyName');
    let finalArray=[];
    Object.keys(groupByCompanyName).map(function(key){            
          finalArray.push({"company": key,"companyDetails":groupByCompanyName[key]});
    })
    return finalArray;
  }

  groupBy(objectArray, property) {
    return objectArray.reduce(function (acc, obj) {
  
        var key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});
  }

  getDataTablePopulation(pageNo:number) {

    let periodDataList = [];
    this.pageNumber = pageNo;
    this.presentYear = this.selectedPeriod[pageNo-1];
    periodDataList.push(this.selectedPeriod[pageNo-1])
    this.displayedColumns=[];
    let dashboardArray = [];  
    this.emptyFlag = true;
    for( let indicatorComplianceData of  this.indicatorComplianceData){
      var paFlag=false;    
      for(let fetchCompanyData of indicatorComplianceData.companyList ){
        var companyNameFlag=false;
        for(let compDetails of fetchCompanyData.companyDetails ){

          var newArray = {};
          this.emptyFlag = false;
          newArray['filterNameFlag'] = false;  
          if(paFlag==false) {
            newArray['filterNameFlag'] = true; 
            paFlag=true; 
          }
          newArray['sectorOrRegionName'] = indicatorComplianceData.filterName;
          newArray['companyNameFlag'] = false;  
          if(companyNameFlag==false) {
            newArray['companyNameFlag'] = true; 
            companyNameFlag=true; 
          }
          newArray['companyName'] = fetchCompanyData.companyName;
          newArray['companyName'] = compDetails.companyName;
          newArray['frequency'] = compDetails.frequency;
          if(compDetails.frequency==="QUARTERLY")
          {
            newArray['frequencyCount'] = 4;
          }
          else if(compDetails.frequency==="MONTHLY")
          {
            newArray['frequencyCount'] = 12;
          }

          for(let yearwiseReported of compDetails.yearwiseReportedDetails ){
            if(periodDataList.includes(yearwiseReported.year)){
              if(compDetails.frequency==="QUARTERLY")
              {
                for(let data of yearwiseReported.reportedStatusQuarterly ){
                  newArray['quarterwiseData_'+yearwiseReported.year+'_'+data.key] = data.value;
                }
              }
              else if(compDetails.frequency==="MONTHLY")
              {
                for(let data of yearwiseReported.reportedStatusMonthly ){
                  newArray['monthwiseData_'+yearwiseReported.year+'_'+data.key] = data.value;
                }
              }
            }
          }
          dashboardArray.push(newArray);
        }
      }
    }
    this.displayedColumns=['FilterName','Company','yearData']
    this.dataSource = new MatTableDataSource(dashboardArray);

    this.dataSource.paginator = this.paginator;
  }

  goBack() {
    let indicatorComplianceDetails = {};
    indicatorComplianceDetails['indicatorId'] = this.indicatorId;
    indicatorComplianceDetails['indicatorName'] = this.parameterNames;
    indicatorComplianceDetails['regionIds'] = this.regionIds;
    indicatorComplianceDetails['sectorIds'] = this.sectorIds;
    indicatorComplianceDetails['regionFlag'] = this.regionFlag;
    indicatorComplianceDetails['yearIds'] = this.previouslySelectedPeriod;
    indicatorComplianceDetails['frequencyView'] = "QUARTERLY";

    indicatorComplianceDetails['parameterName'] = this.parameterNames;

    this.router.navigate(['dashboard/compliance/indicatorComplianceDrilldown'], { queryParams: indicatorComplianceDetails , skipLocationChange: true});
  }

}
