import { Component, OnInit,ViewChild,Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
@Component({
  selector: 'app-company-complaince-dashboard-view',
  templateUrl: './company-complaince-dashboard-view.component.html',
  styleUrls: ['./company-complaince-dashboard-view.component.css']
})
export class CompanyComplainceDashboardViewComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dashboardDataArrayInside:any;
  displayedColumns:any;
  dataSource:any;
  dashboardCompanyArray=[];
  emptyFlag:boolean;
  
  @Input()  set dashboardDataArray(val){
    
    this.dashboardDataArrayInside = val;
    this.displayedColumns=[];
    let dashboardArray = [];  
    this.emptyFlag=true;
    if(this.dashboardDataArrayInside.length > 0 && this.dashboardDataArrayInside != undefined) {
      this.emptyFlag=false;
      for( let complainceDashboardDrillDownVO of this.dashboardDataArrayInside){
        var indexFlag=false;
        for(let company of complainceDashboardDrillDownVO.companyVOList ){
          var newArray = {};
          
          newArray['indexFlag'] = false;  
          if(indexFlag==false) {
            newArray['indexFlag'] = true; 
            indexFlag=true; 
          }
          newArray['region'] = complainceDashboardDrillDownVO.region;
          newArray['sector'] = complainceDashboardDrillDownVO.sector;
          newArray['country'] = complainceDashboardDrillDownVO.country;
          newArray['subSector'] = complainceDashboardDrillDownVO.subSector;
          newArray['companyName'] =company.companyName;
          newArray['companyId'] =company.id;
          newArray['company']=company.companyName;
          newArray['selectedYears']=company.yearList;
          newArray['environmental']=company.environmentalDefaultCount;
          newArray['social']=company.socialDefaultCount;
          newArray['governance']=company.governanceDefaultCount;
          newArray['error']='';
          dashboardArray.push(newArray);
        }
    
      }
    }
    this.displayedColumns=['region','sector','companyName','company','environmental','social','governance','Actions','error']
    this.dataSource = new MatTableDataSource(dashboardArray);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  constructor(private router:Router) { 
  }

  ngOnInit() {
  }

  viewCompany(region,sector,country,subSector,companyId,companyName,selectedYears) {
    let companyDetails={};
    companyDetails['region']=region;
    companyDetails['sector']=sector;
    companyDetails['country']=country;
    companyDetails['subSector']=subSector;
    companyDetails['companyId']=companyId;
    companyDetails['companyName']=companyName;
    companyDetails['selectedYears'] = selectedYears;
    this.router.navigate(['dashboard/compliance/companyComplianceDrillDown'],{queryParams:companyDetails, skipLocationChange: true});
  }
}
