import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators,ValidationErrors } from '@angular/forms';
import { ConfigurationService } from '../../configuration.service';
import { AddEditSuccessComponent } from '../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { CustomValidationsService } from '../../../../shared/Validations/custom-validations.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-add-rating-agency',
  templateUrl: './add-rating-agency.component.html',
  styleUrls: ['./add-rating-agency.component.css']
})
export class AddRatingAgencyComponent implements OnInit {

  addRatingAgencyForm : FormGroup;
  ratingAgencyList :any=[];
  topWrapper:any;
  onSubmision:boolean;
  // factorCodeList=[];
  // factorNameList=[];
  @ViewChild ("topWrap") topWrap;
  @ViewChild('ratingAgencyForm') ratingAgencyForm;
  @ViewChild("fileUploader")
  ratingAgencyFile: any;
  ratingAgencyId:any;
  ratingAgencyCodeValue: string;
  public totalfiles: File;
  updateRatingAgency:boolean;
  fileUploader:File;
  constructor(private configurationService: ConfigurationService, private addEditSuccessComponent: AddEditSuccessComponent, private formBuilder: FormBuilder,private customValidationsService:CustomValidationsService) {
    this.addRatingAgencyForm = this.createFormGroup();
   }

  ngOnInit() {
    this.getRatingAgencyList();
    this.topWrapper=this.topWrap;
    this.onSubmision=false;
  }

  createFormGroup(){
    return this.formBuilder.group({
      id : [0],
      ratingType: ['', [Validators.required,Validators.maxLength(25),this.customValidationsService.noWhitespaceValidator]],
      name: ['', [Validators.required,Validators.maxLength(100),this.customValidationsService.noWhitespaceValidator]],
      filename:['',this.customValidationsService.fileUpload]
    });
  }
  
   deleteRow(ratingAgencyId:any) {
    document.getElementById("uploadFile").innerHTML = null;
    this.fileUploader=null;
    this.addRatingAgencyForm.controls.filename.setValue(null);
    this.totalfiles= null;
    
    if (ratingAgencyId!= 0) {
     
       
    }
    
  }


  public handleratingAgencyFileInput(fileInput: any) {
    console.log(fileInput);
    document.getElementById("uploadFile").innerHTML = fileInput.item(
      0
    ).name;
    console.log(fileInput.item(0));
    this.addRatingAgencyForm.controls.filename.setValue(fileInput.item(0));

    this.addRatingAgencyForm.controls.filename.setValidators([
      this.customValidationsService.imageFileExtension,
      this.customValidationsService.imageFileSize
    ]);
    this.addRatingAgencyForm.controls.filename.updateValueAndValidity();
    console.log(this.updateRatingAgency);
    if (this.updateRatingAgency)
      document
        .getElementById("uploadedFile")
        .classList.add("display-none");
    this.totalfiles= fileInput.item(0);
  }

  downloadRatingAgencyImageFile(fileUpload) {
    let fileName =fileUpload;
    window.location.href =
     this.configurationService.downloadFile() +
       "/downloadRatingAgencyFile?filename=" +
       encodeURIComponent(fileName) +
       "&actualName=" +
       encodeURIComponent(fileUpload);
  }

  onSubmit() {
    this.onSubmision=true;
    this.addRatingAgencyForm.controls.filename.setValidators([
      this.customValidationsService.fileUpload,
      this.customValidationsService.imageFileExtension,
      this.customValidationsService.imageFileSize
    ]);
    this.addRatingAgencyForm.controls.filename.updateValueAndValidity();
    if(this.addRatingAgencyForm.valid){
      if (this.addRatingAgencyForm.value.filename!= null) 
      {
         if (this.totalfiles) 
         {
                const formdata: FormData = new FormData();
                this.ratingAgencyForm.value.filename= this.totalfiles.name;
                let uploadName = this.ratingAgencyForm.value.filename.split(' ').join('_');
              this.ratingAgencyForm.value.filename=new Date().getTime()+"_" + uploadName;
                formdata.append("file", this.totalfiles);
                formdata.append("fileName",this.ratingAgencyForm.value.filename);
                this.configurationService.uploadRatingAgencyFile(formdata).subscribe(event => {});
          }
        }
      
      this.configurationService.createRatingAgency(this.addRatingAgencyForm.value).subscribe(data => {
      if (this.addRatingAgencyForm.get('id').value > 0) {
        var msg = "Successfully edited Rating Agency";
      }
      else {
        var msg = "Successfully added Rating Agency";
      }

      swal({
        title: 'Success!',
        text: msg,
        type: 'success',
        timer: 2000,
        confirmButtonText: 'Ok'
      }).then((value) => {
        this.getRatingAgencyList();
        this.resetRatingAgencyForm();
       
      })
    }, (error) => {
      console.log(error);
    });
  }
  else{
    this.addRatingAgencyForm.get('ratingType').markAsTouched();
    this.addRatingAgencyForm.get('name').markAsTouched();
  }
  }


  getRatingAgencyList() {
    this.configurationService.getRatingAgency().subscribe(data => {
      this.ratingAgencyList = data;
    })
  }

  setRatingAgency(ratingAgency) {
    console.log(ratingAgency);
    this.onSubmision=false;
    document.getElementById("uploadFile").innerHTML = null;    
     this.addRatingAgencyForm.controls.filename.setValidators(null);
     this.addRatingAgencyForm.controls.filename.updateValueAndValidity();
    this.updateRatingAgency = true;
    this.addRatingAgencyForm.patchValue({
      id: ratingAgency.id,
      ratingType: ratingAgency.ratingType,
      name: ratingAgency.name,
      filename:ratingAgency.filename
    })
  }

  resetRatingAgencyForm() {
    document.getElementById("uploadFile").innerHTML = null;
    this.totalfiles= null;
    this.ratingAgencyForm.resetForm();
    this.ratingAgencyFile.nativeElement.value = "";
    this.updateRatingAgency=false;
    this.onSubmision=false;
    // this.addRatingAgencyForm.controls.filename.reset;
    if (this.topWrapper && this.topWrapper.nativeElement)
      this.topWrapper.nativeElement.classList.remove("top-form-wrapper-highlight");
  }

 


 

}
