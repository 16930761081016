import { Pipe, PipeTransform } from '@angular/core';
import {ReportConstantsComponent} from './report-constants/report-constants.component'

@Pipe({
  name: 'decimalFormatterPipe'
})
export class DecimalFormatterPipePipe implements PipeTransform {

  transform(value : string): string {
    let locale,result;
    let suffix = '';
    if(isNaN(Number(value))){
      return value;
    }
    else{
      result = value;
      if(result >= 1000000 && result <= 999999999){
        result = (result / 1000000);
        suffix = 'M'
      }
      else if(result >= 1000000000 && result <= 999999999999){
        result = (result / 1000000000);
        suffix = 'B'
      }
      else if(result >= 1000000000000){
        result = (result / 1000000000000);
        suffix = 'T'
      }
      locale = ReportConstantsComponent.LOCALE;
      result = new Intl.NumberFormat(locale,{minimumFractionDigits: 2, maximumFractionDigits : 2}).format(Number(result));      
      return result+suffix;
    }
  }

}
