import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigurationService } from '../../configuration.service';
import { Router } from '@angular/router';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-company-report-details',
  templateUrl: './company-report-details.component.html',
  styleUrls: ['./company-report-details.component.css']
})
export class CompanyReportDetailsComponent implements OnInit {

  companywithLatestFiles: any = [];
  recentYears: string;
  public numOfyears = 4;
  displayedColumnswithLatestfiles: any;
  dataSourcewithLatestfiles: any = [];
  jsonArraywithLatestfiles: any;
  public loading = false;

  constructor(private configurationService: ConfigurationService,private router:Router) { }
  paginator: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('MatPaginator') set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSourcewithLatestfiles.paginator = this.paginator;
    this.dataSourcewithLatestfiles.sort = this.sort;
  }
  ngOnInit() {
    this.loading = true;
    this.recentYears = this.getLastYears(this.numOfyears);
    this.configurationService.getFiledetailsOfCompanies().subscribe(data => {
      console.log(data);
      this.loading = false;
      this.companywithLatestFiles = data;
      this.fetchDataTableForFiles(this.companywithLatestFiles);
    }, (error) => {
    });
  }
  getLastYears(num) {
    let years: any = [];
    let currentYear = (new Date()).getFullYear();
    for (let i = 0; i < num; i++) {
      years[i] = currentYear - i;
    }
    return years;
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSourcewithLatestfiles.filter = filterValue;
  }

  fetchDataTableForFiles(companies) {
    this.displayedColumnswithLatestfiles = [];
    this.dataSourcewithLatestfiles = [];
    let jsonArray = [];
    this.displayedColumnswithLatestfiles = ['Sno', 'Code', 'Name'];
    for (let k = 0; k < this.numOfyears; k++) {
      this.displayedColumnswithLatestfiles.push(String(this.recentYears[k]))
    }
    // this.displayedColumnswithLatestfiles.push("Record");
    var slCount = 1;
    for (let i of companies) {
      console.log(i.companyTypeVO)
      var newArray = {};
      newArray['Sno'] = slCount;
      newArray['id'] = i.id;
      newArray['Code'] = i.companyCode;
      newArray['Name'] = i.companyName;
      for (let k = 0; k < this.numOfyears; k++) {
        newArray[String(this.recentYears[k])] = i.yearWiseFile[this.recentYears[k]];
        if(i.yearWiseData)
        newArray['Data' + String(this.recentYears[k])] = i.yearWiseData[this.recentYears[k]];
        else
        newArray['Data' + String(this.recentYears[k])] = "N";
        if (i.yearWiseFile[this.recentYears[k]] != null)
          newArray[String(this.recentYears[k])] = (i.yearWiseFile[this.recentYears[k]]).join(',');
      }
      // newArray['Record'] = i.companyName;
      jsonArray.push(newArray);
      slCount++;
    }

    this.dataSourcewithLatestfiles = new MatTableDataSource(jsonArray);
  }
  // gotoPerformanceRecord(companyId){
  //   let company = {};
  //   company['id'] =companyId;
  //   this.router.navigate(['/companyPerformanceRecord'], { queryParams:company , skipLocationChange: true });
  // }
  gotoRecordOnSchedule(year,companyId,name){
    let companyDetails = {};
    companyDetails['id'] =companyId;
    companyDetails['year'] =year;
    companyDetails['name'] =name;
    this.router.navigate(['/companyPerformanceRecordScheduleWise'], { queryParams:companyDetails , skipLocationChange: true });
  }
}

