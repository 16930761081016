import { Component, OnInit,ViewChild,Inject } from '@angular/core';
import {AssessmentTemplateMasterdataService} from '../../../assessment-template-masterdata.service';
import {MatTableDataSource,MatDialog,MatDialogConfig,MAT_DIALOG_DATA} from '@angular/material';
import {MatPaginator,MatSort} from '@angular/material';
import swal from 'sweetalert2';
import { ActivatedRoute,Router, NavigationExtras } from '@angular/router';
import {DatePipe} from '@angular/common';
@Component({
  selector: 'app-list-assessments',
  templateUrl: './list-assessments.component.html',
  styleUrls: ['./list-assessments.component.css']
})
export class ListAssessmentsComponent implements OnInit {

  assessmentCompanyList :  any;
  jsonArray:any;
  dataSource: any = [];
  displayedColumns : any;
  flag : boolean = false;
  companyId:number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  companyVO:any;
  constructor(private assessmentRecordService :  AssessmentTemplateMasterdataService,
     private activatedRoute: ActivatedRoute,private router:Router ) {
      
    }

ngOnInit() {
  this.activatedRoute.params.subscribe(params => {
     this.companyId = params.companyId
     }); 
     this.assessmentRecordService.companyVO.subscribe((data) =>
     {
       console.log("initiatedOn")
       console.log(this.companyVO)
       this.companyVO=data
     })
  this.assessmentRecordService.fetchCompanyAssessmentListDetails(this.companyId).subscribe(data => {
    this.assessmentCompanyList = data;
    console.log(data)
	if (this.assessmentCompanyList.length == 0)
    	this.flag = true;
	this.dataTablePopulation(this.assessmentCompanyList);
} )
}

applyFilter(filterValue: string) {
  filterValue = filterValue.trim();
  filterValue = filterValue.toLowerCase();
  this.dataSource.filter = filterValue;
  
  if (this.dataSource.filteredData.length == 0) {
    this.flag = true;
  } else {
    this.flag = false;
  }
}

dataTablePopulation(assessmentCompanyList)
{
  let count=0;
  this.displayedColumns=[];
  this.jsonArray =[];
  if(assessmentCompanyList.length > 0)
  { 
     for(let assessmentVal of assessmentCompanyList )
     {
        var newArray ={};
        newArray['template'] = assessmentVal.templateVO.templateName;
        newArray['panel'] = assessmentVal.panelVO.panelName;
        newArray['initiatedOn']          = (assessmentVal.intiatedDate!=null)?(this.formatDate(assessmentVal.intiatedDate)):"-";
        newArray['initiatedBy']              =  assessmentVal.initiatedBy;
        newArray['assessmentId']              =  assessmentVal.id;
        this.jsonArray.push(newArray);

     }
     this.displayedColumns=['template','panel','initiatedOn','initiatedBy','viewDetails'];
     this.dataSource = [];
     this.dataSource = new MatTableDataSource(this.jsonArray);
     
     this.dataSource.paginator = this.paginator;
     this.dataSource.sort = this.sort;      
  }
 
}
formatDate(inputDate) {
  var pipe = new DatePipe('en-US'); // Use your own locale
  let newdate = new Date(inputDate).toISOString().split('T')[0];
  return pipe.transform(newdate, 'dd-MM-yyyy');

}
viewAssessmentForCompany(element)
  {

  //  this.router.navigate(['/consolidatedAssessmentDetails/'+assessmentId]);

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "initiatedBy" :element.initiatedBy,
        "initiatedOn" :element.initiatedOn,
        "panel" :element.panel,
        "template" :element.template,
        "transaction" :element.assessmentId,
        "assessmentId" :element.assessmentId,
        "companyId" : this.companyId,
        "companyName":this.companyVO.companyName,
        "companyCode":this.companyVO.companyCode

      }
        ,
      skipLocationChange: true
    };
      this.router.navigate(['/consolidatedAssessmentDetails'],navigationExtras);
  
  } 
}
