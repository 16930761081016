import { Component, OnInit, ViewChild } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { CustomValidationsService } from '../../../../shared/Validations/custom-validations.service';
import swal from 'sweetalert2';
import { ConfigurationService } from '../../configuration.service';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { IndicatorMasterService } from '../../../indicator-master/indicator-master.service';
import { map } from 'rxjs/operators';
import { MatOption } from '@angular/material';

@Component({
  selector: 'app-add-alternate-datasource',
  templateUrl: './add-alternate-datasource.component.html',
  styleUrls: ['./add-alternate-datasource.component.css']
})
export class AddAlternateDatasourceComponent implements OnInit {

  topWrapper: any;
  addAlternateDataForm: FormGroup;
  dataIngestionList=[];
  formatList:any=[]
  identifiersList=[]
  @ViewChild("topWrap") topWrap;
  @ViewChild("alternateDataForm") alternateDataForm;
  sectorList: any=[];
  countryList: any=[];
  subSectorList:any=[]
  regionList: any;
  showAPIUrl:any;
  totalfiles:any;
  @ViewChild('allSubSector') allSubSector:MatOption
  @ViewChild('allCountries') allCountries:MatOption
alternateDataSourceList:any=[]
isFileError:boolean=false;  formatListForAPI:any=[]
formatListForSFTPandManual:any=[];
showAPIKey:boolean=false;
showFileLocation:boolean=false;
sectorIds:any;
subSectorIds:any;
regionIds:any;
countryIds:any;
selectTextForSector:any="Select All"
selectTextForSubSector:any="Select All"
selectTextForRegion:any="Select All"
selectTextForCountry:any="Select All"
uniqueNameError:boolean=false
  constructor(private configurationService: ConfigurationService, private formBuilder: FormBuilder, 
    private customValidationsService: CustomValidationsService,
     private addEditSuccessComponent: AddEditSuccessComponent,
     private indicatorMasterService: IndicatorMasterService) {
    this.addAlternateDataForm = this.createFormGroup();
  }

  ngOnInit() {
    this.topWrapper = this.topWrap;
    this.getSectorList();
    this.getRegionList();
    this.fetchAllAlternateDataSourceConstants("ALTERNATE_DATA_SOURCE_IDENTIFIER");
    this.fetchAllAlternateDataSourceConstants("PRIMARY_DATA_SOURCE_METHOD");
    this.fetchAllAlternateDataSourceConstants("PRIMARY_DATA_SOURCE_FORMAT");
    this.fetchAlternateDataSourceData()

   // this.getCountryList();
    
  }
  fetchAllAlternateDataSourceConstants(constantType)
  {
    this.indicatorMasterService.getConstantsListByConstantType(constantType).subscribe((data) =>
    {
        if(constantType=="ALTERNATE_DATA_SOURCE_IDENTIFIER")
        {
           this.identifiersList=data
        }
        else if(constantType=="PRIMARY_DATA_SOURCE_METHOD")
        {
          
       this.dataIngestionList=data
        }
        else if(constantType=="PRIMARY_DATA_SOURCE_FORMAT")
        {
          
       console.log(data)
      this.formatListForSFTPandManual=data
       for(let format of data)
       {
         if(format.value==='JSON' || format.value==='XML')
         {
           this.formatListForAPI.push(format)
         }
       }
       console.log(this.formatListForAPI)
        }
    })
  }
  fetchAlternateDataSourceData()
  {
    console.log("fetching...")
    this.configurationService.fetchAllAlternateDataSources().subscribe((data) =>
    {
      console.log("backend data")
      console.log(data)
      this.alternateDataSourceList=data
    }
    ,(error) =>
    {
      console.log("error")
    })
  }


  public handleFileInput(fileInput: any,event:any) {
    this.totalfiles = fileInput.item(0);
    console.log("this.totalfiles")
    console.log(this.totalfiles)
    document.getElementById("uploadFile").innerHTML = fileInput.item(0).name
    let uploadName =  fileInput.item(0).name.split(" ").join("_");
    let uploadFileName= new Date().getTime()+'_' + uploadName;
    this.addAlternateDataForm.patchValue({
      fileName:  fileInput.item(0).name,
      fileUploadName:uploadFileName
    });

    this.addAlternateDataForm.controls.fileName.setValidators([this.customValidationsService.excelFileExtension,this.customValidationsService.imageFileSize]);
    this.addAlternateDataForm.controls.fileName.updateValueAndValidity();
    console.log("this.totalfiles")
    console.log(this.totalfiles)
    this.isFileError=false
      const formData: any = new FormData();
        formData.append('file',fileInput.item(0));
        formData.append('fileUploadName', uploadFileName);
    if(this.addAlternateDataForm.controls.fileName.valid){
      this.configurationService.uploadAlternateDataSourceFile(formData).subscribe(() => {
      });
    }
      event.target.value = null
    
  }

  defaultValues(editValue) {
    this.isFileError=false;
    this.uniqueNameError=false;
    document.getElementById("uploadFile").innerHTML = ""
    document.getElementById("uploadFile").innerHTML = editValue.fileName
    this.addAlternateDataForm.patchValue({
      id: editValue.id,
      name:editValue.name,
      data: editValue.data,
      format: editValue.format,
      links: editValue.links,
      location: editValue.location,
      key:editValue.key,
      identifierIds:editValue.identifierIdList,
      sectorIds:editValue. sectorIds,
      subsectorIds:editValue.subsectorIds,
      regionIds:editValue.regionIds,
      countryIds:editValue.countryIds,
      fileName:editValue.fileName,
      fileUploadName:editValue.fileUploadName
    });

  }

 
  onSubmit() {
   
   if(this.addAlternateDataForm.controls['fileName'].value=='' || this.addAlternateDataForm.controls['fileName'].value==null)
   {
    this.isFileError=true;
   }
   const data=this.UniqueSourceNameValidator(this.addAlternateDataForm.controls['id'].value,this.addAlternateDataForm.controls['name'].value)
   data.then((result)=>
   {
    if(!(result!=null && result.length>0))
    {
      if(this.addAlternateDataForm.valid && !this.isFileError){
        var message;
          this.configurationService.createOrUpdateAlternateDataSource(this.addAlternateDataForm.getRawValue()).subscribe((data) => {
                if(this.addAlternateDataForm.get('id').value > 0) {
                   message = "Successfully edited Alternate Data Source";
                }
                else {
                   message = "Successfully added Alternate Data Source";
                }
                this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
                  this.resetAlternateDataForm();
                  this.fetchAlternateDataSourceData();
                  document.getElementById("uploadFile").innerHTML = ""
                });
          }, (error) => {
            console.log(error);
          });
      }
      else{
        this.addAlternateDataForm.get('name').markAsTouched();
        this.addAlternateDataForm.get('data').markAsTouched();
        this.addAlternateDataForm.get('format').markAsTouched();
        this.addAlternateDataForm.get('links').markAsTouched();
        this.addAlternateDataForm.get('location').markAsTouched();
        this.addAlternateDataForm.get('key').markAsTouched();
        this.addAlternateDataForm.get('identifierIds').markAsTouched();
        this.addAlternateDataForm.get('sectorIds').markAsTouched();
        this.addAlternateDataForm.get('subsectorIds').markAsTouched();
        this.addAlternateDataForm.get('regionIds').markAsTouched();
        this.addAlternateDataForm.get('countryIds').markAsTouched();
        return;
      }
    }
    else 
    {
     this.uniqueNameError=true;
    }

   })

  



  }


  resetAlternateDataForm() {
    console.log("alternate")
    //Reset to the original-- even if after edit make it as a new entry
    document.getElementById("uploadFile").innerHTML = ""
    this.isFileError=false;
    this.uniqueNameError=false
    this.alternateDataForm.resetForm();
    this.topWrap.nativeElement.classList.remove("top-form-wrapper-highlight");
   
  }


  createFormGroup() {
    return this.formBuilder.group({
      id: [0],
      name: ['',[Validators.required]],
      data: ['',[Validators.required]],
      format: ['',[Validators.required]],
      links: ['',[Validators.required]],
      location: [''],
      key: [''],
      identifierIds:['',[Validators.required]],
      sectorIds:['',[Validators.required]],
      subsectorIds:['',[Validators.required]],
      regionIds:['',[Validators.required]],
      countryIds:['',[Validators.required]],
      fileName:[""],
      fileUploadName:[""]
    });
  }
  getSectorList()
  {
    console.log("sector level")
    this.configurationService.getSubParameters(1).subscribe((parameterLevel)=>{
      console.log(parameterLevel)
      this.sectorList=parameterLevel
      console.log(this.sectorList)
      this.sectorIds=[]
      for(let sector of this.sectorList)
      {
          this.sectorIds.push(sector.id)
      }
    },(error)=>{
      console.log(error);
  })
  }
  getRegionList()
  {
    console.log("refgion level")
    this.configurationService.getSubParameters(2).subscribe((parameterLevel)=>{
  
      this.regionList=parameterLevel
      this.regionIds=[]
      for(let region of this.regionList)
      {
        this.regionIds.push(region.id)
      }
      
    },(error)=>{
      console.log(error);
  })
  }
  downloadTemplate(){
    let link = document.createElement("a");
          link.download = "Data Attribute Template.xls";
          link.href = "assets/Template/excelTemplate.xls";
          link.click();
          link.remove();
  }
  getCountryList()
  {
    this.configurationService.getListOfCountries().subscribe(data => {
      console.log(data)
      this.countryList = data;
    }, (error) => {
      console.log(error);
    });
  }
  onSectorChange(parameterId)
  {
   this.subSectorList=[]
    if(parameterId!=null){
    this.configurationService.getParameterLevelDetails(1,parameterId).subscribe((parameterLevel)=>{
      console.log(parameterLevel)
      this.subSectorList=parameterLevel
      this.subSectorIds=[]
      for(let subsector of this.subSectorList)
      {
        this.subSectorIds.push(subsector.id)
      }
    },(error)=>{
      console.log(error);
  })
}
  }
  onRegionChange(parameterId)

  {

   this.countryList=[]

   console.log("paremtersss regio s")

    console.log(parameterId)

    if(parameterId!=null){

    this.configurationService.getParameterLevelDetails(2,parameterId).subscribe((parameterLevel)=>{

      console.log("countries")

      console.log(parameterLevel)
      this.countryIds=[]
      
      this.countryList=parameterLevel
      for(let country of this.countryList){
        this.countryIds.push(country.id)

      }

     

     

    },(error)=>{

      console.log(error);

  })
}

  }

//1306--->API ID
  //1307 ---> SFTP ID
  //1311 --> Manual Id
  dataIngestionChange(id){
   
    var methodName=this.dataIngestionList.filter(obj => obj.id == id);
    if(methodName.length>0){
    var selectedMethodName=methodName[0].value;
    if(selectedMethodName=='API')
    {
      this.formatList=this.formatListForAPI
      this.showAPIKey=true;
      this.showFileLocation=false;
      this.addAlternateDataForm.controls.key.setValidators([Validators.required]);
      this.addAlternateDataForm.controls.key.updateValueAndValidity();
      this.addAlternateDataForm.controls.location.clearValidators();
      this.addAlternateDataForm.controls.location.updateValueAndValidity();
    }
    else if(selectedMethodName=='SFTP'){
      this.showAPIKey=false;
      this.showFileLocation=true;
       this.formatList=this.formatListForSFTPandManual
       this.addAlternateDataForm.controls.location.setValidators([Validators.required]);
       this.addAlternateDataForm.controls.location.updateValueAndValidity();
       this.addAlternateDataForm.controls.key.clearValidators();
       this.addAlternateDataForm.controls.key.updateValueAndValidity();
    }
    else if(selectedMethodName=='Manual'){
      this.formatList=this.formatListForSFTPandManual
      this.showAPIKey=false;
      this.showFileLocation=false;
      this.formatList=this.formatListForSFTPandManual
      this.addAlternateDataForm.controls.location.clearValidators();
      this.addAlternateDataForm.controls.location.updateValueAndValidity();
      this.addAlternateDataForm.controls.key.clearValidators();
      this.addAlternateDataForm.controls.key.updateValueAndValidity();
   }
    else {
      this.formatList=[];
      this.showAPIKey=false;
      this.showFileLocation=false
      this.addAlternateDataForm.controls.location.clearValidators();
      this.addAlternateDataForm.controls.location.updateValueAndValidity();
      this.addAlternateDataForm.controls.key.clearValidators();
      this.addAlternateDataForm.controls.key.updateValueAndValidity();
    }
  }
  }

  selectAllSectors(option)
   {
 
     if(option._selected)
     {
      
       this.addAlternateDataForm.patchValue({
           sectorIds:this.sectorIds
       })
       option._selected=true;
       this.selectTextForSector="Deselect All"
     }
     else
     {
      this.addAlternateDataForm.patchValue({
       sectorIds:[],
       subsectorIds:[]
    })
    this.selectTextForSector="Select All"
    this.allSubSector.deselect()
     }
   }
   selectAllRegions(option)
   {
 
     if(option._selected)
     {
      
       this.addAlternateDataForm.patchValue({
        regionIds:this.regionIds
       })
       option._selected=true;
       this.selectTextForRegion="Deselect All"
     }
     else
     {
      this.addAlternateDataForm.patchValue({
      regionIds:[],
      countryIds:[]
    })
    this.selectTextForRegion="Select All"
    this.allCountries.deselect()
     }
   }
   selectAllSubSectors(option)
   {
  
     if(option._selected)
     {
      
       this.addAlternateDataForm.patchValue({
        subsectorIds:this.subSectorIds
       })
       option._selected=true;
       this.selectTextForSubSector="Deselect All"
     }
     else
     {
      this.addAlternateDataForm.patchValue({
       subsectorIds:[]
    })
    this.selectTextForSubSector="Select All"
   
     }
   }
   selectAllCountries(option)
   {
  
     if(option._selected)
     {
      
       this.addAlternateDataForm.patchValue({
        countryIds:this.countryIds
       })
       option._selected=true;
       this.selectTextForCountry="Deselect All"
     }
     else
     {
      this.addAlternateDataForm.patchValue({
        countryIds:[]
    })
    this.selectTextForCountry="Select All"
   
     }
   }
   async UniqueSourceNameValidator(id,name){
    id=id==null?0:id
    if(name!=""){
   const data=await this.configurationService.checkAlternateSourceNameValidation(id, name).toPromise();
   return data;
    }return;
   
  }
}

