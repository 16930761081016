import { Injectable } from '@angular/core';
import {Http, Response , Headers, RequestOptions} from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../../shared/base-url.service';


@Injectable()
export class IndicatordetailsService {

  private baseUrl = BaseUrlService.REPORTBASEURL;
  
  private headers = new Headers({'Content-type':'application/json',
 'Authorization': `Bearer ${sessionStorage.getItem('token')}`
});
  private options = new RequestOptions({headers:this.headers});
  private indicator:any;
  constructor(private _http:Http) { }

  getIndicatorScheduledetails(id:number){
    return this._http.get(this.baseUrl+'/indicatorScheduleDetails/'+id,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  getIndicatorDetails(id:number){
    return this._http.get(this.baseUrl+'/indicatorDetails/'+id,this.options).map((response:Response)=>response.json()).catch(this.errorHandler);
  }

  errorHandler(error:Response){
    console.log("Error occurred!");
    return Observable.throw(error || "SERVER_ERROR");
  }

  setter(indicator:any){
    this.indicator = indicator;
  }

  getter(){
    return this.indicator;
  }

}
