import { Component, OnInit ,ViewChild} from '@angular/core';
import { MediaService } from '../media.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as d3 from 'd3';
import { CompanyService } from '../../../CompanyDashboard/company.service';
import { DatePipe } from '@angular/common';
import {AddEditSuccessComponent} from '../../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component'
@Component({
  selector: 'app-media-view',
  templateUrl: './media-view.component.html',
  styleUrls: ['./media-view.component.css'],
  providers: [DatePipe]
})
export class MediaViewComponent implements OnInit {

  @ViewChild ("fromInput") fromInput;
  @ViewChild("toInput") toInput;
  public company_id: number;
  public sentimentGauge: any = [];
  public gaugemap: any = {};
  public newsData: any = [];
  public latestNews: any =[];
  public companyDetails: any = [];
  public parmeterMap = new Map();
  public newsDetails: any = [];
  public newsMonthly: any = [];
  public linechartOptions: any;
  public barchartOptions: any;
  public newsTrendsData = [];
  public lineChartType: string = 'line';
  public lineData: any;
  public lineChartLabels: any;
  public periodChangeData: any =[];
  public sentimentScore:any;
  public date:any;
  public oldDate:any;
  public positiveThumbsUp = [];
  public positiveThumbsDown = [];
  public negativeThumbsUp = [];
  public negativeThumbsDown = [];
  lineColors: { backgroundColor: string; borderColor: string; pointBackgroundColor: string; pointBorderColor: string; pointHoverBackgroundColor: string; pointHoverBorderColor: string; }[];
  chartOptions: { responsive: boolean; scales: { xAxes: { gridLines: { offsetGridLines: boolean; }; }[]; }; };
  labels: string[];
  chartData: { label: string; data: number[]; }[];
  colors: { backgroundColor: string; }[];
  name: any;
  id: any;
  sector: any="";
  subSector: any="";
  subRegion: any="";
  region: any="";
  revenue: any;
  image: any;
  constructor( private activatedRoute: ActivatedRoute,private addEditSuccessComponent: AddEditSuccessComponent,private datePipe: DatePipe,private mediaDashboardService: MediaService, private _router: Router, private companyService: CompanyService) { 
  }


  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.name=params.name;
      this.id=parseInt(params.id);
      this.sector=params.sector;
      this.subSector=params.subSector;
      this.region = params.region;
      this.subRegion = params.subRegion;
      this.revenue = params.revenue;
      this.image = params.image;
    if(this.id){
    this.companyService.getCompanyDetailsWithoutTransaction(this.id).subscribe((companyDetails) => {
      this.companyDetails = companyDetails.data.getCompany;
      if (this.companyDetails) {
        let parameterLevelName: String;
        let parameterLevelNameMap: String;
        let parameterName: String;
        console.log("dataaaaaaaaaaa")
        console.log(this.companyDetails.companyParameterMappingReportSet)
        for (let companyParameterMapping of this.companyDetails.companyParameterMappingReportSet) {
          if (companyParameterMapping.parameterLevel1) {
            parameterName = companyParameterMapping.parameterLevel1.parameters.parameterName;
            parameterLevelName = companyParameterMapping.parameterLevel1.levelName;
            if (this.parmeterMap.has(parameterName)) {
              parameterLevelNameMap = this.parmeterMap.get(parameterName);
              parameterLevelNameMap = parameterLevelNameMap + "," + parameterLevelName;
              this.parmeterMap.set(parameterName, parameterLevelNameMap);
            }
            else {
              this.parmeterMap.set(parameterName, parameterLevelName);
            }
          }
          if (companyParameterMapping.parameterLevel2) {
            parameterName = companyParameterMapping.parameterLevel2.parameters.parameterName;
            parameterLevelName = companyParameterMapping.parameterLevel2.levelName;
            if (this.parmeterMap.has(parameterName)) {
              parameterLevelNameMap = this.parmeterMap.get(parameterName);
              parameterLevelNameMap = parameterLevelNameMap + "," + parameterLevelName;
              this.parmeterMap.set(parameterName, parameterLevelNameMap);
            }
            else {
              this.parmeterMap.set(parameterName, parameterLevelName);
            }
          }
        }
      }
    });
  }
  }) 
    this.linechartOptions = {
      responsive: true,
      scales: {
        xAxes: [{
          display: true,
          gridLines: {
            display: false,
            drawTicks: true,
          },
          scaleLabel: {
            display: false,
            labelString: 'Year'
          },
          ticks: {
            callback: function (label, index, labels) {
              if (/\s/.test(label)) {
                return label.split(" ");
              } else {
                return label;
              }
            }
          }
        }],
        yAxes: [{
          display: true,
          gridLines: {
            display: false,
            drawTicks: true
          },
          scaleLabel: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            stepSize: 3,
            min: 0,
            max:15
          }
        }],
      }
    };
    this.barchartOptions = {
      responsive: true,
      scales: {
        xAxes: [{
          display: true,
          gridLines: {
            display: false,
            drawTicks: true,
          },
          scaleLabel: {
            display: false,
            labelString: 'Year'
          },
          ticks: {
            callback: function (label, index, labels) {
              if (/\s/.test(label)) {
                return label.split(" ");
              } else {
                return label;
              }
            }
          }
        }],
        yAxes: [{
          display: true,
          gridLines: {
            display: false,
            drawTicks: true
          },
          scaleLabel: {
            display: false,
          },
          ticks: {
            beginAtZero: true
          }
        }],
      }
    };
    this.date = new Date();
    //this.date.setFullYear(2022,0,31);
   this.date = this.datePipe.transform(this.date, 'yyyy-MM-dd');
   this.oldDate= new Date();
   this.oldDate.setFullYear(2023,7,1);
   //this.oldDate.setMonth(new Date().getMonth()-6);
   this.oldDate = this.datePipe.transform(this.oldDate, 'yyyy-MM-dd');
   this.dateChange(1);
    // this.mediaDashboardService.fetchNewsDetails(this.id).subscribe(data => {
    //   this.newsData = data;
    //   console.log("Newsssssssss")
    //   console.log(this.newsData )
    //   //Get latest news
    //   this.latestNewsList(this.newsData.latestNews , this.newsData.latestNewsLinks)
    //   this.sentimentScore=this.newsData.score;
    //    //Sentiment Score
    //   this.drawSentimentArc();
    //   this.getLineData(this.newsData.newsVOList)
    //   this.getBonusPenalties(this.newsData.positiveNews,this.newsData.negativeNews);
    // });
    this.mediaDashboardService.fetchMonthlyReportedNews(this.id).subscribe(result => {
      this.newsMonthly = result;

      //Monthly Chart
      this.drawMonthlyChart(this.newsMonthly);
    });

   
  }

  drawSentimentArc() {
    document.querySelector('div#sentiment-gauge').innerHTML = "";
    var self = this;
    var gauge = function (container, configuration) {

      var config = {
        size: 150,
        clipWidth: 100,
        clipHeight: 90,
        ringInset: 17,
        ringWidth: 17,

        pointerWidth: 9,
        pointerTailLength: 5,
        pointerHeadLengthPercent: 0.6,

        minValue: -3,
        maxValue: 3,

        minAngle: -90,
        maxAngle: 92,

        transitionMs: 850,

        majorTicks: 6,
        labelFormat: d3.format('.1f'),
        labelInset: 10,
        arcColorFn: d3.scaleOrdinal().range(['#f36750', '#da8368', '#da8368', '#49e2e7', '#3cbbe4', '#788de6'])

      };
      var range = undefined;
      var r = undefined;
      var pointerHeadLength = undefined;
      var value = 0;

      var svg = undefined;
      var arc = undefined;
      var scale = undefined;
      var ticks = undefined;
      var tickData = undefined;
      var pointer = undefined;

      var donut = d3.pie();

      function deg2rad(deg) {
        return deg * Math.PI / 180;
      }

      function newAngle(d) {
        var ratio = scale(d);
        var newAngle = config.minAngle + (ratio * range);
        return newAngle;
      }

      function configure(configuration) {
        var prop = undefined;
        for (prop in configuration) {
          config[prop] = configuration[prop];
        }

        range = config.maxAngle - config.minAngle;
        r = config.size / 2;
        pointerHeadLength = Math.round(r * config.pointerHeadLengthPercent);

        // a linear scale this.gaugemap maps domain values to a percent from 0..1
        scale = d3.scaleLinear()
          .range([0, 1])
          .domain([config.minValue, config.maxValue]);

        ticks = scale.ticks(config.majorTicks);
        tickData = d3.range(config.majorTicks).map(function () { return 1 / config.majorTicks; });

        arc = d3.arc()
          .innerRadius(r - config.ringWidth - config.ringInset)
          .outerRadius(r - config.ringInset)
          .startAngle(function (d, i) {
            var ratio = d * i;
            if (i == 0)
            ratio = 0;
            else if (i == 1)
            ratio = 0.1667
            else if (i == 2)
            ratio = 0.334
            else if (i == 3)
            ratio = 0.501
            else if (i == 4)
            ratio = 0.668
            else if (i == 5)
            ratio = 0.835
            return deg2rad(config.minAngle + (ratio * range));
            })
            .endAngle(function (d, i) {
            var ratio = d * (i + 1);
            if (i == 0)
            ratio = 0.1667
            else if (i == 1)
            ratio =0.334
            else if (i == 2)
            ratio = 0.501
            else if (i == 3)
            ratio = 0.668
            else if (i == 4)
            ratio = 0.835
            else if (i == 5)
            ratio = 1
            return deg2rad(config.minAngle - 1 + (ratio * range));
            });
      }
      self.gaugemap.configure = configure;

      function centerTranslation() {
        return 'translate(' + r + ',' + r + ')';
      }

      function isRendered() {
        return (svg !== undefined);
      }
      self.gaugemap.isRendered = isRendered;

      function render(newValue) {
        svg = d3.select(container)
          .append('svg:svg')
          .attr('class', 'gauge')
          .attr('width', config.clipWidth)
          .attr('height', config.clipHeight);

        var centerTx = centerTranslation();

        var arcs = svg.append('g')
          .attr('class', 'arc')
          .attr('transform', centerTx);

        arcs.selectAll('path')
          .data(tickData)
          .enter().append('path')
          .attr('fill', function (d, i) {
            return config.arcColorFn(d * i);
          })
          .attr('d', arc);

        var lg = svg.append('g')
          .attr('class', 'label')
          .attr('transform', centerTx);
        lg.selectAll('text')
          .data(ticks)
          .enter().append('text')
          .attr('transform', function (d) {
            var ratio = scale(d);
            var newAngle = config.minAngle + (ratio * range);
            return 'rotate(' + newAngle + ') translate(-10,' + (config.labelInset - r) + ')';
          })
          .text(config.labelFormat);

        var lineData = [[config.pointerWidth / 2, 0],
        [0, -pointerHeadLength],
        [-(config.pointerWidth / 2), 0],
        [0, config.pointerTailLength],
        [config.pointerWidth / 2, 0]];
        var pointerLine = d3.line().curve(d3.curveLinear)
        var pg = svg.append('g').data([lineData])
          .attr('class', 'pointer')
          .attr('transform', centerTx);

        pointer = pg.append('path')
          .attr('d', pointerLine/*function(d) { return pointerLine(d) +'Z';}*/)
          .attr('transform', 'rotate(' + config.minAngle + ')');

        update(newValue === undefined ? 0 : newValue);
      }
      self.gaugemap.render = render;
      function update(newValue, newConfiguration?) {
        if (newConfiguration !== undefined) {
          configure(newConfiguration);
        }
        var ratio = scale(newValue);
        var newAngle = config.minAngle + (ratio * range);
        pointer.transition()
          .duration(config.transitionMs)
          .ease(d3.easeElastic)
          .attr('transform', 'rotate(' + newAngle + ')');
      }
      self.gaugemap.update = update;

      configure(configuration);

      return self.gaugemap;
    };

    var sentiGauge = gauge('#sentiment-gauge', {
      size: 250,
      clipWidth: 250,
      clipHeight: 135,
      ringWidth: 20,
      maxValue: 3,
      transitionMs: 10000,
    });
    let sentimentVal=this.sentimentScore;
    if(sentimentVal)
      this.sentimentScore= parseFloat(sentimentVal).toFixed(2);
      else
      this.sentimentScore=0;
      sentiGauge.render(this.sentimentScore);
    
    
  }
  drawMonthlyChart(data) {
    var posCount = [];
    var negCount = [];
    var months = [];
    this.colors = [
      {
        backgroundColor: '#788de6'
      },
      {
        backgroundColor: '#f36c58'
      }
    ]
    for (let chartData of data.yearlyReportedNews) {
      months.push(chartData.year);
      posCount.push(chartData.positiveNewsCount);
      negCount.push(chartData.negativeNewsCount);
    }
    this.labels = months;
    this.chartData = [
      {
        label: '+ve',
        data: posCount
      },
      {
        label: '-ve',
        data: negCount
      }
    ];
  }

  getLineData(lineChartData) {
    this.newsTrendsData=[];
    this.lineColors = [
      {
        backgroundColor: 'rgba(120, 141, 230, .01)',
        borderColor: 'rgb(120, 141, 230)',
        pointBackgroundColor: 'rgb(120, 141, 230)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(120, 141, 230, .8)'
      },
      {
        backgroundColor: 'rgba(243, 108, 88, .01)',
        borderColor: 'rgb(243, 108, 88)',
        pointBackgroundColor: 'rgb(243, 108, 88)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(243, 108, 88, .8)'
      }]
      let count = 1;
    for (let lineData of lineChartData) {
      var newsTrends = [];
      var yearsArr = [];
      var positiveArr = [];
      var negativeArr = [];
      var lineChartDataArr = [];
      var lineChartLbl = [];
      newsTrends["category"] = lineData.categoryName;
      newsTrends["positive"] = lineData.positiveNewsCount;
      newsTrends["negative"] = lineData.negativeNewsCount;
      newsTrends["count"] = count;

      for (let ldata of lineData.newsTrendVOList) {
        yearsArr.push(ldata.yearMonth);
        positiveArr.push(ldata.positiveNewsCount);
        negativeArr.push(ldata.negativeNewsCount);
      }
      lineChartDataArr = [
        { data: positiveArr, label: 'Positive' },
        { data: negativeArr, label: 'Negative' },
      ];
      lineChartLbl = yearsArr;
      newsTrends['lineData'] = lineChartDataArr;
      newsTrends['lineChartLabels'] = lineChartLbl;
      this.newsTrendsData.push(newsTrends);
      count++;
    }
  }
  getBonusPenalties(periodChangeData){
    this.positiveThumbsUp = [];
    this.positiveThumbsDown = [];
    this.negativeThumbsUp = [];
    this.negativeThumbsDown = [];
    var positiveNews=periodChangeData.positiveNews;
     var negativeNews=periodChangeData.negativeNews;
     var positiveNewsLink=periodChangeData.positiveNewsLinks;
     var negativeNewsLink=periodChangeData.negativeNewsLinks;
     var positiveNewsStatus=periodChangeData.positiveNegativeStatus;
     var negativeNewsStatus=periodChangeData.negativeNegativeStatus;
     var positiveNewsId=periodChangeData.latestPositiveNewsId;
     var negativeNewsId=periodChangeData.latestNegativeNewsId;
     this.newsDetails=[];
     var posNewsLen = positiveNews.length - 1;
     var negNewsLen = negativeNews.length - 1;
     for (let i = 0; i < 5; i++) {
       var newsArr = [];
       if (posNewsLen >= i)
       {
         newsArr['bonus'] = positiveNews[i];
         newsArr['bonusLink']=positiveNewsLink[i];
         newsArr['positiveNewsId']=positiveNewsId[i];
          if(positiveNewsStatus[i]==="D")
          {
            this.positiveThumbsUp[positiveNewsId[i]]=false;
            this.positiveThumbsDown[positiveNewsId[i]]=false
          }
          else if(positiveNewsStatus[i]==="Y")
          {
            this.positiveThumbsUp[positiveNewsId[i]]=true;
            this.positiveThumbsDown[positiveNewsId[i]]=false
          }
          else if(positiveNewsStatus[i]==="N")
          {
            this.positiveThumbsUp[positiveNewsId[i]]=false;
            this.positiveThumbsDown[positiveNewsId[i]]=true
          }
       }
       else
       {
         newsArr['bonus'] = "-";
         newsArr['bonusLink']="no link";
         newsArr['positiveNewsId']=0;
       }
       if (negNewsLen >= i)
       {
         newsArr['penalty'] = negativeNews[i];
         newsArr['penaltyLink']=negativeNewsLink[i];
         newsArr['negativeNewsId']=negativeNewsId[i];
         if(negativeNewsStatus[i]==="D")
         {
           this.negativeThumbsUp[negativeNewsId[i]]=false;
           this.negativeThumbsDown[negativeNewsId[i]]=false
         }
         else if(negativeNewsStatus[i]==="Y")
         {
           this.negativeThumbsUp[negativeNewsId[i]]=true;
           this.negativeThumbsDown[negativeNewsId[i]]=false
         }
         else if(negativeNewsStatus[i]==="N")
         {
           this.negativeThumbsUp[negativeNewsId[i]]=false;
           this.negativeThumbsDown[negativeNewsId[i]]=true
         }
       }
       else
       {
         newsArr['penalty'] = "-";
         newsArr['penaltyLink']="no link";
         newsArr['negativeNewsId']=0;
       }
       this.newsDetails.push(newsArr);
     }
   }
   latestNewsList(titles,links){
     this.latestNews=[];
    //  this.latestNewsStatus=[];
    //  this.latestThumbsUp=[];
    //  this.latestThumbsDown=[];
     for (let i = 0; i < 5; i++) {
       var newsListArr = [];
       newsListArr['title'] = titles[i];
       newsListArr['link'] = links[i];
       //newsListArr['status'] = status[i];
      //  newsListArr['id'] = id[i];
      //  if(status[i]==="D")
      //  {
      //    this.latestThumbsUp[id[i]]=false;
      //    this.latestThumbsDown[id[i]]=false
      //  }
      //  else if(status[i]==="Y")
      //  {
      //    this.latestThumbsUp[id[i]]=true;
      //    this.latestThumbsDown[id[i]]=false
      //  }
      //  else if(status[i]==="N")
      //  {
      //    this.latestThumbsUp[id[i]]=false;
      //    this.latestThumbsDown[id[i]]=true
      //  }
       //this.latestNewsStatus[id[i]]=status[i];
       this.latestNews.push(newsListArr);
     }
   }
  dateChange(flag) {
     if(flag==1){
       var startDate = this.datePipe.transform( this.oldDate, 'yyyy-MM-dd');
       var endDate = this.datePipe.transform( this.date, 'yyyy-MM-dd');
     }
     if(flag==2){
       var startDate = this.datePipe.transform( this.fromInput.nativeElement.value, 'yyyy-MM-dd');
       var endDate = this.datePipe.transform( this.toInput.nativeElement.value, 'yyyy-MM-dd');
     }
     
     if(startDate && endDate){
       if(startDate<endDate)
       {
     this.mediaDashboardService.fetchTimelineBasedNews(this.id,startDate,endDate).subscribe(details =>{
       this.periodChangeData=details;
       if(this.periodChangeData){
         this.latestNewsList(this.periodChangeData.latestNews , this.periodChangeData.latestNewsLinks)
         //this.latestNewsList(this.periodChangeData.latestNews , this.periodChangeData.latestNewsLinks,this.periodChangeData.latestNegativeStatus,this.periodChangeData.latestNewsID)

         let sentimentVal=this.periodChangeData.score;
         if(sentimentVal)
         this.sentimentScore= parseFloat(sentimentVal).toFixed(2);
         else
         this.sentimentScore=0;
          //Sentiment Score
         this.drawSentimentArc();
         this.getLineData(this.periodChangeData.newsVOList);
         this.getBonusPenalties(this.periodChangeData);
       }
       else{
         this.addEditSuccessComponent.showFailedSwal('Please select valid StartDate and EndDate', 'Error').then((value) => {
         })
       }
     })
   }
   else{this.addEditSuccessComponent.showFailedSwal('Please select valid StartDate and EndDate', 'Error').then((value) => {
   })}
     }
     
   }

   showUpStatus(id,flag)
{
  if(flag=="P"){
    this.positiveThumbsUp[id]=!this.positiveThumbsUp[id];
    if(this.positiveThumbsUp[id]===true){
      this.positiveThumbsDown[id]=false
     this.mediaDashboardService.postNewsStatus(id,"Y").subscribe((data)=>
     {
      
     })
    }
  }else{
    this.negativeThumbsUp[id]=!this.negativeThumbsUp[id];
    if(this.negativeThumbsUp[id]===true){
      this.negativeThumbsDown[id]=false
     this.mediaDashboardService.postNewsStatus(id,"Y").subscribe((data)=>
     {
      
     })
    }
  }
}
showDownStatus(id,flag)
{
  if(flag=="P"){
    this.positiveThumbsDown[id]=!this.positiveThumbsDown[id];
    if(this.positiveThumbsDown[id]===true){
      this.positiveThumbsUp[id]=false
     this.mediaDashboardService.postNewsStatus(id,"N").subscribe((data)=>
     {
      
     })
    }
  }else{
    this.negativeThumbsDown[id]=!this.negativeThumbsDown[id];
    if(this.negativeThumbsDown[id]===true){
      this.negativeThumbsUp[id]=false
     this.mediaDashboardService.postNewsStatus(id,"N").subscribe((data)=>
     {
      
     })
    }
  }
}
 }
 