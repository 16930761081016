import { Component, OnInit, ViewChild, Injectable } from '@angular/core';
import { FundDashboardService } from '../../fund-dashboard.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-fund-view',
  templateUrl: './fund-view.component.html',
  styleUrls: ['./fund-view.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class FundViewComponent implements OnInit {

  public fundData;
  public fundDetails: any = [];
  public sectorWiseESG: any = [];
  public topArr = [];
  public btmArr = [];
  public fundBoxData = [];
  jsonArrayForAnnualReturn: any = [];
  displayedColumns: any;
  dataSourceAllValues: any = [];
  doughnutChartType = 'doughnut';
  doughnutData: any;
  chartColors: any;
  chartOptions: any;
  flag: boolean;
  dataSrc: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public dataSource: any = [];
  public topAverage = 0;
  public topAnnualAverage = 0;
  public bottomAverage = 0;
  public topFundAverage: any = 0;
  public topAnnualReturnAverage: any = 0;
  public bottomFundAverage;
  emptyFlag: boolean = false;
  public emptyTopBoxesArr = [];
  public emptyBottomBoxesArr = [];
  public loading = false;
  public barChartOptEsg: any;
  public barChartColorsForEsg: Array<any> = [];
  public barChartLabelsEsg: any;
  public barChartDataEsg: any = [];
  public esgBarData = [];
  public esgBarLabel = [];
  public esgBarColor = [];
  public barChartEsgLegend: boolean = false;
  public barChartType: string = 'bar';
  public barChartLegend: boolean = true;
  public lastBatchRunDate: any;
  public selectedValue = 0;
  constructor(private fundDashboardService: FundDashboardService, private _router: Router) { }

  ngOnInit() {
    this.loading = true;
    this.fundDashboardService.getFundDetails().subscribe(result => {
      this.displayedColumns = ['column'];
      this.fundData = result;

      this.fetchTopData(1);
      this.toggleESGFunds(1);

    })
    this.fundDashboardService.getSectorESG().subscribe(sectors => {
      this.sectorWiseESG = sectors;
      this.getEsgBarChartData(this.sectorWiseESG.slice(0, 5));
    });
  }
  fetchTopData(esgToggleFlag) {
    let fundDetails = [];
    this.emptyTopBoxesArr = [];
    if (esgToggleFlag == 2) {
      document.getElementById("toggleE").classList.remove('selectedTab');
      document.getElementById("toggleA").classList.add('selectedTab');
      fundDetails = this.fundData.sort((a, b) => parseFloat(b.latestAnnualReturn) - parseFloat(a.latestAnnualReturn));
    }
    else {
      document.getElementById("toggleA").classList.remove('selectedTab');
      document.getElementById("toggleE").classList.add('selectedTab');
      fundDetails = this.fundData.sort((a, b) => parseFloat(b.latestESGQuotient) - parseFloat(a.latestESGQuotient));
    }
    this.lastBatchRunDate = this.formatDate(fundDetails[0].lastBatchRunDate);
    let totalFundCount = fundDetails.length;
    let topValArr = fundDetails.slice(0, 5);
    let topValArrCount = topValArr.length;
    if (topValArrCount < 5) {
      let blankTopBoxCount = 5 - topValArrCount;
      for (let i = topValArrCount + 1; i <= 5; i++) {
        this.emptyTopBoxesArr.push(i);
      }
    }

    this.topAnnualAverage = 0;
    this.topAverage = 0;
    this.topAnnualReturnAverage = 0;
    this.topFundAverage = 0;
    this.topArr = [];
    for (let top of topValArr) {
      var tops = {};
      tops['name'] = top.name;
      tops['isin'] = top.isinCode;
      tops['esg'] = top.latestESGQuotient;
      if (top.latestAnnualReturn > 0) {
        tops['annual'] = top.latestAnnualReturn + "%";
      }
      else {
        tops['annual'] = top.latestAnnualReturn;
      }

      if (top.latestESGQuotient >= 0.8) {
        tops['esgColor'] = "esg-color-step-1";
      }
      else if (top.latestESGQuotient >= 0.6 && top.latestESGQuotient <= 0.79) {
        tops['esgColor'] = "esg-color-step-2";
      }
      else if (top.latestESGQuotient >= 0.4 && top.latestESGQuotient <= 0.59) {
        tops['esgColor'] = "esg-color-step-3";
      }
      else if (top.latestESGQuotient >= 0.2 && top.latestESGQuotient <= 0.39) {
        tops['esgColor'] = "esg-color-step-4";
      }
      else if (top.latestESGQuotient >= 0 && top.latestESGQuotient <= 0.19) {
        tops['esgColor'] = "esg-color-step-5";
      }
      this.topAverage = this.topAverage + top.latestESGQuotient;
      this.topAnnualAverage = this.topAnnualAverage + top.latestAnnualReturn;

      this.topArr.push(tops);
    }
    if (this.topArr.length > 0) {
      this.topFundAverage = (this.topAverage / this.topArr.length).toFixed(2);
      this.topAnnualReturnAverage = (this.topAnnualAverage / this.topArr.length).toFixed(2);
      if (this.topAnnualReturnAverage != 0) {
        this.topAnnualReturnAverage = this.topAnnualReturnAverage + "%";
      }
    }
    this.loading = false;
  }
  OptionsESG(flag) {
    var per = 60;
    this.chartOptions = {
      responsive: true,
      cutoutPercentage: per,
      animation: false,
    };
    return this.chartOptions;
  }

  doughnutChartESGData(esg: number) {
    if (esg > 1)
      esg = 1;
    this.doughnutData = [esg, (1 - esg)];
    return this.doughnutData;
  }

  chartColorESG(esgColorStatus: number) {

    if (esgColorStatus >= 0.8) {
      this.chartColors = [{ backgroundColor: ["#3e7d14", "#ebebeb"] }];
    }
    else if (esgColorStatus >= 0.6 && esgColorStatus <= 0.79) {
      this.chartColors = [{ backgroundColor: ["#93e45b", "#ebebeb"] }];
    }
    else if (esgColorStatus >= 0.4 && esgColorStatus <= 0.59) {
      this.chartColors = [{ backgroundColor: ["#bfbfbf", "#ebebeb"] }];
    }
    else if (esgColorStatus >= 0.2 && esgColorStatus <= 0.39) {
      this.chartColors = [{ backgroundColor: ["#595959", "#ebebeb"] }];
    }
    else if (esgColorStatus >= 0 && esgColorStatus <= 0.19) {
      this.chartColors = [{ backgroundColor: ["#000000", "#ebebeb"] }];
    }

    return this.chartColors;
  }
  applyFilter(filterValue: string) {
    let dataSrc = [];
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSourceAllValues.filter = filterValue;
    if (this.dataSourceAllValues.filteredData.length != 0) {
      dataSrc.push(this.dataSourceAllValues.filteredData)
      this.generateDataTable(dataSrc, 1);
    }
    else {
      this.dataSource = [];
      this.dataSource = new MatTableDataSource(this.dataSource);
    }
  }
  sortChange(val) {

    this.fundDetails = this.fundData;
    this.jsonArrayForAnnualReturn = [];
    if (val == 1) {
      this.fundDetails.sort((a, b) => parseFloat(b.latestESGQuotient) - parseFloat(a.latestESGQuotient));
      this.jsonArrayForAnnualReturn = this.fundDetails;
    }
    else if (val == 2) {
      this.fundDetails.sort((a, b) => parseFloat(a.latestAnnualReturn) - parseFloat(b.latestAnnualReturn));
      this.jsonArrayForAnnualReturn = this.fundDetails.reverse();
    }
    else {
      this.fundDetails.sort((a, b) => (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) ? 1 : ((b.name.trim().toLowerCase() > a.name.trim().toLowerCase()) ? -1 : 0));
      this.jsonArrayForAnnualReturn = this.fundDetails;
    }
    this.generateDataTable(this.jsonArrayForAnnualReturn, 0);
    // });
  }
  generateDataTable(dataArrray, filterFlag) {
    this.dataSource = [];
    this.displayedColumns = ['column'];
    let columnNo = 1;
    let count = 1;
    let dataArr = [];
    let totalFundCount = dataArrray.length;
    if (filterFlag == 0) {
      for (let funds of dataArrray) {
        var funding = {};
        funding['id'] = funds.id;
        funding['fundname'] = funds.name;
        funding['fundisin'] = funds.isinCode;
        funding['annual'] = funds.latestAnnualReturn;
        funding['esg'] = funds.latestESGQuotient;
        funding['columnNo'] = columnNo;
        funding['count'] = count;
        if (funds.latestAnnualReturn >= 0 && funds.latestAnnualReturn <= 8) {
          funding['rangepointer'] = 1;
        }
        else if (funds.latestAnnualReturn > 8 && funds.latestAnnualReturn <= 16) {
          funding['rangepointer'] = 2;
        }
        else if (funds.latestAnnualRetusortrn > 16 && funds.latestAnnualReturn <= 24) {
          funding['rangepointer'] = 3;
        }
        else if (funds.latestAnnualReturn >= 25) {
          funding['rangepointer'] = 4;
        }
        dataArr.push(funding);
        count++;
      }
      this.dataSource = [];
      this.dataSourceAllValues = new MatTableDataSource(dataArr);
      this.dataSource = new MatTableDataSource(Array.of(dataArr));
    }
    else {
      this.dataSource = [];
      this.dataSource = new MatTableDataSource(dataArrray);
    }
  }
  getFundDrillDownDetails(fundId: number) {
    let fundVO = {};
    fundVO['fundId'] = fundId;
    this._router.navigate(['/dashboard/fundDrilldown'], { queryParams: fundVO, skipLocationChange: true });
  }
  toggleESGFunds(val) {
    this.fundData = [];
    this.fundDashboardService.getFundDetails().subscribe(result => {
      this.fundData = result;
      if (val == 2) {
        let fundVals = [];
        document.getElementById("esgHeading").classList.add('selectedTab');
        document.getElementById("otherHeading").classList.remove('selectedTab');
        for (let fund of this.fundData) {
          var newArr = {};
          if (fund.esgFund == true) {
            fundVals.push(fund);
          }
        }
        this.fundData = fundVals;
        this.fetchTopData(1);
      }
      else {
        document.getElementById("esgHeading").classList.remove('selectedTab');
        document.getElementById("otherHeading").classList.add('selectedTab');
        this.fetchTopData(1);
      }
      this.sortChange(0);
      this.generateDataTable(this.fundData, 0);
    })
  }
  getEsgBarChartData(sectorESGData) {
    for (let sectorEsg of sectorESGData) {
      this.esgBarData.push((sectorEsg.averageESG).toFixed(2));
      this.esgBarLabel.push(sectorEsg.sectorName);
      if (sectorEsg.averageESG >= 0.8) {
        this.esgBarColor.push("#3e7d14");
      }
      else if (sectorEsg.averageESG >= 0.6 && sectorEsg.averageESG <= 0.79) {
        this.esgBarColor.push("#93e45b");
      }
      else if (sectorEsg.averageESG >= 0.4 && sectorEsg.averageESG <= 0.59) {
        this.esgBarColor.push("#bfbfbf");
      }
      else if (sectorEsg.averageESG >= 0.2 && sectorEsg.averageESG <= 0.39) {
        this.esgBarColor.push("#595959");
      }
      else if (sectorEsg.averageESG >= 0 && sectorEsg.averageESG <= 0.19) {
        this.esgBarColor.push("#000000");
      }
    }
    this.barChartOptEsg = {
      scaleShowVerticalLines: true,
      responsive: true,
      animation: {
        onComplete: function () {
          var chartInstance = this.chart, ctx = chartInstance.ctx;
          ctx.fillStyle = '#000';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';
          this.data.datasets.forEach(function (datasets, i) {
            var meta = chartInstance.controller.getDatasetMeta(i);
            meta.data.forEach(function (bar, index) {
              var data = datasets.data[index];
              ctx.fillText(data, bar._model.x, bar._model.y);
            })
          })
        }
      },
      scales: {
        xAxes: [{
          display: true,
          barPercentage: 0.5,
          barThickness: 25,
          maxBarThickness: 25,
          minBarLength: 6,
          gridLines: {
            display: false,
            drawTicks: true,
            drawBorder: true
          },
          scaleLabel: {
            display: true,
            labelString: ''
          },
          ticks: {
            beginAtZero: true,
            lineHeight: 1.2,
            fontColor: '#666',
            callback: function (label, index, labels) {
              if (/\s/.test(label)) {
                return label.split(" ");
              } else {
                return label;
              }
            }
          }
        }],
        yAxes: [{
          display: true,
          gridLines: {
            display: false,
            drawTicks: true,
            drawBorder: true
          },
          scaleLabel: {
            display: true,
            labelString: 'ESG'
          },
          ticks: {
            beginAtZero: true,
            steps: 50,
            stepValue: 0.02,
            min: 0,
            max: 1
          }
        }]
      },
      title: {
        display: false,
        text: 'chart'
      }
    };

    this.barChartColorsForEsg = [{ backgroundColor: this.esgBarColor }];
    this.barChartLabelsEsg = this.esgBarLabel;

    this.barChartDataEsg = [{ 'dataSet': [{ data: this.esgBarData }] }];
  }
  formatDate(inputDate) {
    var pipe = new DatePipe('en-US'); // Use your own locale
    let newdate = new Date(inputDate).toISOString().split('T')[0];
    return pipe.transform(newdate, 'dd-MM-yyyy');

  }
}