import { Component, OnInit, Input } from '@angular/core';
import { ConfigurationService } from '../../configuration.service';
import swal from 'sweetalert2';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';

@Component({
  selector: 'app-list-company-type',
  templateUrl: './list-company-type.component.html',
  styleUrls: ['./list-company-type.component.css']
})
export class ListCompanyTypeComponent implements OnInit {

  originalColumnNames: string[];
  displayedColumns: any;
  colIndex: number = 0;
  top: any;
  jsonArray = [];
  @Input() set topWrapper(val) {
    this.top = val;
  }
  constructor(private configurationService: ConfigurationService, private addEditSuccessComponent: AddEditSuccessComponent) { }

  ngOnInit() {
    this.getListOfCompanyTypes();
  }

  getListOfCompanyTypes(){
    this.configurationService.getListOfCompanyTypes().subscribe(data => {
      this.dataTableDetails(data);
    }, (error) => {
      console.log(error);
    });
  }

  dataTableDetails(companyTypes) {
    this.displayedColumns = [];
    this.jsonArray =[];
    var count = 0;
    for (let i of companyTypes) {
      var newArray = {};
      newArray['Sno'] = ++count;
      newArray['id'] = i.id;
      newArray['Code'] = i.code;
      newArray['Name'] = i.name;
      newArray['editDelete'] = 'D';

      this.jsonArray.push(newArray);

    }
    this.displayedColumns = ['Sno', 'Code', 'Name', 'Actions'],
    this.originalColumnNames = ['Sl.No', 'Code', 'Name', 'Actions']
  }

  delete(companyType) {
    var confirmMessage = "You Want To Delete Industry Group";
    this.addEditSuccessComponent.showConfirmationSwal(confirmMessage, 'Yes', 'No').then((isConfirm) => {
      if (isConfirm.value) {
        this.configurationService.deleteCompanyType(companyType.id).subscribe((data) => {
          var deleteSuccess = 'Deleted Industry Group';
          this.addEditSuccessComponent.showSuccessSwal(deleteSuccess)
            .then((value) => {
              this.getListOfCompanyTypes();
            });
        }, (error) => {
          console.log(error);
        });
      }
    });
  }

  
}
