import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-datasource-quality-levels',
  templateUrl: './list-datasource-quality-levels.component.html',
  styleUrls: ['./list-datasource-quality-levels.component.css']
})
export class ListDatasourceQualityLevelsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
