import { Component, OnInit ,ViewChild, AfterViewInit } from '@angular/core';
import { Router , ActivatedRoute } from '@angular/router';
import { ParametersService } from '../../parameters.service';
import { TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { PlatformLocation } from '@angular/common';
import { RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material';
import {MatPaginator} from '@angular/material';
import {MatSort} from '@angular/material';
import {ReportConstantsComponent} from '../../../report-constants/report-constants.component'

@Component({
  selector: 'app-parameterlevel',
  templateUrl: './parameterlevel.component.html',
  styleUrls: ['./parameterlevel.component.css']
})
export class ParameterlevelComponent implements OnInit {

  public parameterLevel:any;
  public level_no:any;
  public parameter_id:any;
  public doughnutChartType = 'doughnut';
  public doughnutData:any;
  public chartColors: any;
  public chartOptions: any;
  public indArr:any;
  public modalRef: BsModalRef;
  public dataSource:any;
  public dataSourcePopUp:any;
  public displayedColumns : any;
  public colIndex:number=0;
  public jsonArray:any;
  public levelName:any = null;
  public nextLevelName:any = null;
  public targetFlag:any = false;
  public currency:string;
  public locale:string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private _parametersService:ParametersService , private _router:Router , private activatedRoute: ActivatedRoute ,
              private modalService: BsModalService) {
      this.activatedRoute.params.subscribe(params => {this.level_no = params.level_no , this.parameter_id = params.parameter_id}); 
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  ngOnInit() {
    this.currency = ReportConstantsComponent.CURRENCY;
    this.locale =ReportConstantsComponent.LOCALE;
    this.parameterLevel = [];
    this._parametersService.getParameterLevelDetails(this.level_no,this.parameter_id).subscribe((parameterLevel)=>{
        this.parameterLevel = parameterLevel;
        this.dataTablePopulation(this.parameterLevel,1);
      },(error)=>{
        console.log(error);
    })
  }

  drillDown(levelNo:number,id:number,noSubParameters:number){
    if(noSubParameters > 0){
      this._router.navigate(['/performanceManagement/dashboard/listReports/'+levelNo+'/'+id]);
      this.parameterLevel = [];
      this._parametersService.getParameterLevelDetails((levelNo+1),id).subscribe((parameterLevel)=>{
        this.parameterLevel = parameterLevel;
        this.dataTablePopulation(this.parameterLevel,1);
      },(error)=>{
      console.log(error);
      })
    }
    else{
      this._router.navigate(['/performanceManagement/dashboard/companyDetails/'+levelNo+'/'+id]);
    }
  }

  doughnutChartData(percentage:number){
  	if(percentage > 100)
      percentage = 100;
    this.doughnutData = [percentage,(100-percentage)];
    return this.doughnutData;
  }

  doughnutChartESGData(esg:number){
  	if(esg > 1)
      esg = 1;
    this.doughnutData = [esg,(1-esg)];
    return this.doughnutData;
  }

  chartColor(colorStatus: number) {

    /* 3=> green , 2=> amber , 1 => red */
    if (colorStatus == 3)
      this.chartColors = [{ backgroundColor: ["#34a853", "#dddddd"] }];
    else if (colorStatus == 2)
      this.chartColors = [{ backgroundColor: ["#f39700", "#dddddd"] }];
    else
      this.chartColors = [{ backgroundColor: ["#ea4335", "#dddddd"] }];  

    return this.chartColors;
  }

  OptionsESG(){
    this.chartOptions = {
      responsive: true,
      cutoutPercentage: 70,
    };
    return this.chartOptions;
  }

  OptionsAchievement(){
    this.chartOptions = {
      responsive: true,
      cutoutPercentage: 40,
    };
    return this.chartOptions;
  }

  dataTablePopulation(parameterLevel,flag){
    this.displayedColumns=[];
    this.jsonArray =[];
    
    for( let parameterDetails of parameterLevel){
      this.levelName = parameterDetails.levelName;
      this.nextLevelName = parameterDetails.nextLevelName;
      this.targetFlag = parameterDetails.targetFlag;
      if(flag)
        this.indArr=parameterDetails.indicatorVO.slice(0,5);
      else
        this.indArr=parameterDetails.indicatorVO;
      
      for(let indicator of this.indArr ){
        var newArray = {};
        newArray['Sno'] = indicator.sno;
        newArray['Id'] = indicator.id;
        newArray['Indicator'] = indicator.indicatorName;
        if(this.targetFlag){
          if(indicator.targetFlag === true)
            newArray['Target'] = indicator.target;
          else
            newArray['Target'] = "-";
          newArray['Achievement']= String(indicator.achievement);
          newArray['AchievementColorCode'] = indicator.achievementColorStatus;
        }
        newArray['Critical'] = indicator.critical;
        newArray['Actual'] = indicator.actual;
        newArray['UnitOfmeasurement']='-';
        if(indicator.unitOfMeasurement!= null && indicator.unitOfMeasurement.length > 0){
          newArray['UnitOfmeasurement']=indicator.unitOfMeasurement;
        }
        newArray['Year']=indicator.year;
        
        this.jsonArray.push(newArray);
      }
    }
    
      if(this.targetFlag){
      this.displayedColumns=['Critical','Indicator','UnitOfmeasurement','Target','Actual','Achievement']
    }
    else{
      this.displayedColumns=['Critical','Indicator','UnitOfmeasurement','Actual']
    }
    if(flag){
      this.dataSource = new MatTableDataSource(this.jsonArray);
      this.dataSourcePopUp = new MatTableDataSource(this.jsonArray);
    }
    else{
      this.dataSourcePopUp = new MatTableDataSource(this.jsonArray);
      this.dataSourcePopUp.paginator = this.paginator;
    }
  }

  getList(){
    this.dataTablePopulation(this.parameterLevel,0);
  }

  isNumber(val) { return isNaN(+val); }
}
