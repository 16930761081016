import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-constants',
  templateUrl: './report-constants.component.html',
  styleUrls: ['./report-constants.component.css']
})
export class ReportConstantsComponent  {

    public static get CURRENCY(): string { return "$"; };
    public static get LOCALE(): string { return "sg"; };
    
}
