import { Component, OnInit, Output,EventEmitter, Input, ViewChild, OnChanges, SimpleChanges  } from '@angular/core';
import { Router } from '@angular/router';
import { AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import swal from 'sweetalert2';
import { AddEditSuccessComponent } from '../../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
@Component({
  selector: 'app-list-template',
  templateUrl: './list-template.component.html',
  styleUrls: ['./list-template.component.css']
})
export class ListTemplateComponent implements OnInit {
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  templateList :any;
  templateListjson:any;
  dataSource: any;
  displayedColumns: any;
  colIndex: number = 0;
  templateArray: any;
  flag : boolean = false;
  canCreate = true;
  anyOneSelected = true;
  templateName: any;
  transactionTypeName:any;
  status:any;
  createdDate:any;
  indFlag: boolean = true;
  displayedColumnsPopup : any = [];
  dataSourcePopUp: any = [];
  templateVO:any;
  public loading=false;
  constructor(private addEditSuccessComponent: AddEditSuccessComponent,private assessementTemlateMasterService:AssessmentTemplateMasterdataService ,private router:Router) { }

  ngOnInit() {

    this.getTemplateList();
   
  }
  directArchive(){

  }
  applyFilterPopup(event){
    
  }
  getTemplateList() {
    this.loading=true;
    this.assessementTemlateMasterService.getTemplateList().subscribe((data) => {
        this.templateList = data;
        this.loading=false;
        this.dataTablePopulation();
    }, (error) => {
      console.log(error);
    });
  
  }
  dataTablePopulation() {

    this.displayedColumns = [];
    this.templateArray = [];
    var count = 0;
    for(let template of this.templateList){
      var templateJson= {};
      templateJson['Sno'] = ++ count;
      templateJson['id'] = template.id;
      templateJson['templateName'] =  template.templateName;
      templateJson['createdBy'] = template.createdBy;
      templateJson['createdDate'] = template.createdDateString;
      templateJson['editDelete'] = template.editDelete;
      this.templateArray.push(templateJson);
    }

    this.displayedColumns = ['Sno', 'templateName', 'createdBy','createdDate', 'Actions']
    this.dataSource = new MatTableDataSource(this.templateArray);
	setTimeout(()=> {
     this.dataSource.paginator = this.paginator;
   	}, 3000); 
    this.dataSource.sort = this.sort;
  }
  getTemplateDetails(templateId){
 
    
    this.router.navigate(['/templateSettingsData/'+templateId]);

  }
  
    editTemplateDetails(templateId){
      let  templateDetails={}
      this.assessementTemlateMasterService.getTemplateListById(templateId).subscribe(templateVO => {
        this.templateVO=templateVO
       // templateDetails['templateId']= this.templateData.id; 
       templateDetails['id']=this.templateVO.id;
       templateDetails['templateName']=this.templateVO.templateName;
       templateDetails['templateQuestionNode']=JSON.stringify(this.templateVO.templateQuestionNode);
        this.router.navigate(['/assessmentTemplateAdd'], { queryParams:templateDetails,skipLocationChange: true });
      
      
      
      })     
  }
     deleteTemplate(templateId){
    swal({
      title: '<p class="swal2-title-warning">Are You Sure?</p>',
      text: "You Want To Delete Template",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'

    }).then((isConfirm) => {
      if (isConfirm.value) {

        
        this.assessementTemlateMasterService.deleteTemplate(templateId).subscribe((data) => {
          let msg="Deleted Template";
          // swal({
          //   title: 'Success!',
          //   text: 'Deleted Template',
          //   timer: 2000,
          //   type: 'success',
          //   confirmButtonText: 'Ok'
          // })
this.addEditSuccessComponent.showSuccessSwal(msg)
          .then((value) => {
           this.loading=true;
            this.assessementTemlateMasterService.getTemplateList().subscribe((data)=>{
              this.templateList = data;
              this.dataTablePopulation();
              this.loading=false;
               });
          });
        });


      }


    });

  }
  
  

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    if (this.dataSource.filteredData.length == 0) {
      this.flag = true;
    } else {
      this.flag = false;
    }
  setTimeout(()=> {
     this.dataSource.paginator = this.paginator;
   }, 3000); 
  }
}
