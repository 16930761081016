import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { DataQualityMasterService } from '../../data-quality-master.service';
import { DataAcqCommonService } from '../../data-acq-common.service';
import { AddEditSuccessComponent } from '../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';

@Component({
  selector: 'app-add-datasource',
  templateUrl: './add-datasource.component.html',
  styleUrls: ['./add-datasource.component.css']
})
export class AddDatasourceComponent implements OnInit {
  sourceList: any = [];
  dataSourceForm: FormGroup;
  dataQualityLevelList: any;
  topWrapper: any;
  dataSource: string[];
  editFlag: Boolean = false;
  @ViewChild('sourceForm') sourceForm;
  @ViewChild("topWrap") topWrap;

  constructor(private dataQualityService: DataQualityMasterService, private addEditSuccessComponent: AddEditSuccessComponent,
    private formBuilder: FormBuilder, private dataAcqCommonService: DataAcqCommonService) {
    this.dataSourceForm = this.createFormGroup();
  }

  ngOnInit() {
    this.getDataQualityLevelList();
    this.topWrapper = this.topWrap;
    this.fetchSourceList();
  }

  getDataQualityLevelList() {

    let dataQltyLevel = this.dataAcqCommonService.getDataQltyLevels();
    this.dataAcqCommonService.getDataAcqMasterData(dataQltyLevel).subscribe(data => {
      this.dataQualityLevelList = data.constantsVOList;

    })

  }

  createFormGroup() {
    return this.formBuilder.group({
      dataSourceList: this.formBuilder.array([this.getDataSourceList()]),
    });
  }

  getDataSourceList() {
    return this.formBuilder.group({
      id: [],
      dataQualityLevels: ['', [Validators.required]],
      dataSourceName: ['', [Validators.required]]
    });
  }

  addNewRow() {
    const controlsUrl = this.formArray;
    this.formArray.push(this.getDataSourceList());
  }

  deleteRow(index: number) {
    this.formArray.removeAt(index);
  }

  get formArray() {
    return this.dataSourceForm.get('dataSourceList') as FormArray;
  }

  onSubmit() {
    if (this.dataSourceForm.valid) {
      let dataSourceList = [];
      for (let dataSourceValue of this.dataSourceForm.value.dataSourceList) {
        let dataAcqMasterVO = {
          "levelId": dataSourceValue.dataQualityLevels,
          "dataSource": dataSourceValue.dataSourceName
        }
        if (dataSourceValue.id) {
          dataAcqMasterVO["id"] = dataSourceValue.id
        }
        else {
          dataAcqMasterVO["id"] = 0
        }
        dataSourceList.push(dataAcqMasterVO)
      }

      this.dataQualityService.createOrUpdateDataSource(dataSourceList).subscribe(data => {
        var message;
        if (this.editFlag) {
          message = "Successfully Updated DataSource";
        }
        else {
          message = "Successfully Added DataSource";
        }

        this.addEditSuccessComponent.showSuccessSwal(message);
        this.resetSourceForm()
        this.fetchSourceList();

      });
    }
  }
  fetchSourceList() {
    this.dataQualityService.fetchDataSourceList().subscribe((data) => {
      this.sourceList = data;
    });
  }

  resetSourceForm() {
    this.editFlag = false;
    this.sourceForm.resetForm();
  }
  editDataSource(dataSourceObj) {
    this.editFlag = true;
    this.dataSourceForm.removeControl('dataSourceList');
    this.dataSourceForm.addControl('dataSourceList', this.formBuilder.array([this.getDataSourceList()]));
    let dataSourceListObj = [];
    dataSourceObj.dataSourceList.forEach(parameter => {

      dataSourceListObj.push({
        "id": parameter.id,
        "dataSourceName": parameter.dataSource,
        "dataQualityLevels": parameter.dataQualityLevels.id
      });

    })
    dataSourceObj.dataSourceList = dataSourceListObj;
    this.formArray.patchValue(dataSourceObj.dataSourceList);
  }
}

