import { Component, OnInit , ViewChild} from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators,FormControl } from '@angular/forms';
import { AssessmentTemplateMasterdataService } from '../../../assessment-template-masterdata.service';
import {Observable, concat} from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import swal from 'sweetalert2';
import { callLifecycleHooksChildrenFirst } from '@angular/core/src/view/provider';
import { CustomValidationsService } from '../../../../../shared/Validations/custom-validations.service';
import { AddEditSuccessComponent } from '../../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
@Component({
  selector: 'app-add-response-options',
  templateUrl: './add-response-options.component.html',
  styleUrls: ['./add-response-options.component.css']
})
export class AddResponseOptionsComponent implements OnInit {

    responseOptionsForm: FormGroup;
    responseOptionList:any = [];
    responseNameList :any =[];
    filteredOptions: Observable<string[]>;
    isEdit :boolean =false;
    topWrapper:any;
    @ViewChild ("topWrap") topWrap;
    @ViewChild('responseForm') responseForm;

    constructor(private addEditSuccessComponent: AddEditSuccessComponent,private _fb: FormBuilder, private assessmentService: AssessmentTemplateMasterdataService,private customValidationsService:CustomValidationsService) {
      
    }

    ngOnInit() {

      this.loadReponseOptions();
      this.topWrapper=this.topWrap;
      this.responseOptionsForm = this._fb.group({
        optionRows: this._fb.array([this.initOptionRows()])
      });

      this.manageNameControl(0);

    
    }

    get formArray() {
      return this.responseOptionsForm.get('optionRows') as FormArray;
    }

    initOptionRows() {

    
      return this._fb.group({
        id:[0],
        responseName:['',[Validators.maxLength(100),this.customValidationsService.noWhitespaceValidator]],
        option:['',[Validators.maxLength(100),this.customValidationsService.noWhitespaceValidator]],
        score:['',Validators.compose([Validators.required,Validators.max(100),Validators.min(-100),Validators.pattern(/^-?(0|[1-9]\d*)?$/) ])]

      });
    }

    addNewRow() {
      const controls = this.formArray;
      
      this.formArray.push(this.initOptionRows());
      this.manageNameControl(controls.length-1)
    }

    deleteRow(index: number) {
      this.formArray.removeAt(index);
      this.manageNameControl(0)
    }

    refreshList()
    {
      this.manageNameControl(0)
    }

    manageNameControl(index) {
      var arrayControl = this.responseOptionsForm.get('optionRows') as FormArray;
      this.filteredOptions = arrayControl.at(index).get('responseName').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );

    }

    private _filter(value: any) {

      let filterValue = '';
      if(value){
        value = value.toString();
        filterValue = value.toLowerCase();
      }
     
      return this.responseNameList.filter(option => option.toLowerCase().includes(filterValue));
      
    }

    onSubmit(){
    
      if(this.responseOptionsForm.valid )
      {
        this.assessmentService.createResponseOptions(this.responseOptionsForm.get('optionRows').value).subscribe((myform)=>{

         
          if(this.isEdit)
            var message = "Successfully Edited Response Option";
          else
            var message = "Successfully Added Response Option";
          // swal({
          //     title: 'Success!',
          //     text: message,
          //     type: 'success',
          //     timer : 2000,
          //     confirmButtonText: 'Ok'
          //   })
          this.addEditSuccessComponent.showSuccessSwal(message)
          .then((value) => {
             this.resetResponseForm();
             this.loadReponseOptions();
             this.manageNameControl(0);

            });
      },(error)=>{
            console.log(error);
          });
        }
        else{
          
          let formArrayLastIndex = this.formArray.length;
          let index = 0;
          while (index !== formArrayLastIndex) {
            this.responseOptionsForm.get('optionRows').get(index.toString()).get('responseName').markAsTouched();
            this.responseOptionsForm.get('optionRows').get(index.toString()).get('option').markAsTouched();
            this.responseOptionsForm.get('optionRows').get(index.toString()).get('score').markAsTouched();
            index++;
          }
          return;
        }
    }

    defaultValues(defaultArray)
    {
      this.isEdit = true;
      var formArrayLastIndex = this.formArray.length-1;
      while (formArrayLastIndex !== 0) {
        this.formArray.removeAt(formArrayLastIndex);
        formArrayLastIndex--;
      }
      this.formArray.at(0).patchValue(defaultArray);
      
    }

    resetResponseForm() {

      this.isEdit = false;
      var formArrayLastIndex = this.formArray.length-1;
      while (formArrayLastIndex !== 0) {
        this.formArray.removeAt(formArrayLastIndex);
        formArrayLastIndex--;
      }
      this.responseForm.resetForm();
      this.topWrap.nativeElement.classList.remove("top-form-wrapper-highlight");
    }

    loadReponseOptions(){
      this.assessmentService.loadResponseOptions().subscribe(data =>{
   this.responseNameList=[];
        this.responseOptionList = data;
       //Setting the response names
        for( let i of this.responseOptionList){
          let index =this.responseNameList.indexOf(i.responseName);
          if(index<0)
            this.responseNameList.push(i.responseName);
        }
  
      });
    
    }

}
