import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PerformanceFrameworkCompanyService } from '../performance-framework-company.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-company-framework',
  templateUrl: './view-company-framework.component.html',
  styleUrls: ['./view-company-framework.component.css'],
  providers: [DatePipe]
})
export class ViewCompanyFrameworkComponent implements OnInit {

  endDate: any;
  startDate: any;
  displayedScheduleColumns: any[];
  indicatorDataSource: any = [];
  displayedColumns: any[];
  frameworkId: any;
  frameworkDetails: any;
  paginator: any;
  jsonArray: any=[];
  schedulePopup:boolean =false;
  redirectFromCopyCompanyPage:any='';
  companyId:any;
  selectedFrameworkId:any;
  redirectFromArchievePage:any;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  @ViewChild(MatSort) sort: MatSort;
  constructor(private datePipe: DatePipe,private router:Router,private activatedRoute: ActivatedRoute, private companyFrameworkService: PerformanceFrameworkCompanyService) {
   // this.activatedRoute.params.subscribe(params => { this.frameworkId = params.frameworkId,this.redirectFromCopyCompanyPage = params.redirectFromCopyCompanyPage });
    this.activatedRoute.queryParams.subscribe(params => {
      this.frameworkId=params.frameworkId,
      this.redirectFromCopyCompanyPage = params.redirectFromCopyCompanyPage;
      this.redirectFromArchievePage = params.redirectFromArchievePage;
      this.companyId = params.companyId;

      if( this.redirectFromCopyCompanyPage=="true")
      {
          this.selectedFrameworkId = params.selectedFrameworkId;
        
      }

    
       }); 
    
}

  ngOnInit() {

    this.companyFrameworkService.fetchFrameworkDetails(this.frameworkId).subscribe(data => {
      this.frameworkDetails = data;
      this.dataTableDetails(this.frameworkDetails.performanceframeworkVO);
    }, (error) => {
      console.log(error);

    });


  }

  dataTableDetails(performanceframeworkVO) {
    let count = 0;
    this.displayedColumns = [];
    let indicatorArrayList = [];

    if (performanceframeworkVO != null && performanceframeworkVO.indicatorVOList.length > 0) {
      for (let frameworkIndicatorDetails of performanceframeworkVO.indicatorVOList) {
        let newArray = {};
        newArray['Slno'] = ++count;
        newArray['indicatorId'] = frameworkIndicatorDetails.id;
        newArray['indicatorCode'] = frameworkIndicatorDetails.code;
        newArray['indicatorName'] = frameworkIndicatorDetails.name;
        newArray['indicatorType'] = frameworkIndicatorDetails.indicatorType.value;

        if (frameworkIndicatorDetails.indicatorConfigurationVO.frequency)
          newArray['frequencyOfMeasurement'] = frameworkIndicatorDetails.indicatorConfigurationVO.frequency.value;
        else
          newArray['frequencyOfMeasurement'] = "-";

        newArray['mandatoryFlag'] = frameworkIndicatorDetails.indicatorConfigurationVO.mandatory;
        newArray['criticalFlag'] = frameworkIndicatorDetails.indicatorConfigurationVO.critical;

        if (frameworkIndicatorDetails.indicatorConfigurationVO.source.value != null)
          newArray['source'] = frameworkIndicatorDetails.indicatorConfigurationVO.source.value;
        else
          newArray['source'] = "-";

       

        newArray['indicatorTypeId'] = frameworkIndicatorDetails.indicatorType.id;
        newArray['indicatorTypeCode'] = frameworkIndicatorDetails.indicatorType.code;
        

        if (frameworkIndicatorDetails.indicatorType.code != "QR") {
          if (frameworkIndicatorDetails.indicatorConfigurationVO.finalTarget != null && frameworkIndicatorDetails.indicatorConfigurationVO.finalTarget.length > 0) {
            newArray['finalTarget'] = frameworkIndicatorDetails.indicatorConfigurationVO.finalTarget;
          }
          else {
            newArray['finalTarget'] = "-";
          }
        }
        else {
          if (frameworkIndicatorDetails.indicatorConfigurationVO.finalTarget != null && frameworkIndicatorDetails.indicatorConfigurationVO.finalTarget.length > 0)
            //need to be checked  
            newArray['finalTarget'] = frameworkIndicatorDetails.indicatorConfigurationVO.finalTargetValueRating;
          else
            newArray['finalTarget'] = "-";
        }

        newArray['intermediateTarget'] = frameworkIndicatorDetails.indicatorConfigurationVO.intermediateTarget;
        if(frameworkIndicatorDetails.indicatorConfigurationVO.indicatorStartdate && frameworkIndicatorDetails.indicatorConfigurationVO.indicatorEnddate)
        {
             newArray['startDateToDisplay'] = new Date(frameworkIndicatorDetails.indicatorConfigurationVO.indicatorStartdate);
            newArray['endDateToDisplay'] = new Date(frameworkIndicatorDetails.indicatorConfigurationVO.indicatorEnddate);
            newArray['start'] =this.datePipe.transform( newArray['startDateToDisplay'], 'dd-MMM-yyyy');
            newArray['end'] =this.datePipe.transform( newArray['endDateToDisplay'], 'dd-MMM-yyyy');
        }
        newArray['frameworkIndicatorDetailId']=frameworkIndicatorDetails.indicatorConfigurationVO.frameworkIndicatorDetailId;

        newArray['schedules'] = frameworkIndicatorDetails.indicatorConfigurationVO.scheduleVOList;
        indicatorArrayList.push(newArray);

      }
    }

    this.indicatorDataSource = [];
    this.displayedColumns = ['Slno', 'indicatorCode', 'indicatorName', 'indicatorType', 'frequencyOfMeasurement', 'source', 'finalTarget', 'start', 'measurementSchedule'];
    this.indicatorDataSource = new MatTableDataSource(indicatorArrayList);

  }
  getSchedules(indicator) {
    this.schedulePopup = true;
    this.startDate = (indicator.startDateToDisplay) ? indicator.startDateToDisplay : "-";
    this.endDate = (indicator.endDateToDisplay) ? indicator.endDateToDisplay : "-";
    this.displayedScheduleColumns = [];
    this.jsonArray = [];
    let count = 0;
    let indicatorSchedules: any = [];
    this.companyFrameworkService.fetchSchedulesForIndicator(indicator.frameworkIndicatorDetailId, indicator.indicatorTypeId).subscribe(schedules => {
      indicatorSchedules = schedules;

      for (let i of indicatorSchedules) {
        let newArray = {};
        newArray['Schedule'] = (i.scheduleStartDate && i.scheduleEndDate) ? i.scheduleStartDate + " To " + i.scheduleEndDate : "-";

        if (indicator.intermediateTarget && indicator.indicatorTypeCode != "QR") {
          newArray['Target'] = i.targetValue;
        }
        else if (indicator.intermediateTarget && indicator.indicatorTypeCode == "QR") {
          newArray['Target'] = i.targetValueRating;
        }
        else {
          newArray['Target'] = '-';
        }
        this.jsonArray.push(newArray);
      }
      if (indicator.intermediateTarget)
        this.displayedScheduleColumns = ['Schedule', 'Target'];
      else
        this.displayedScheduleColumns = ['Schedule'];


    });

  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.indicatorDataSource.filter = filterValue;

  }
  setDataSourceAttributes() {
    this.indicatorDataSource.paginator = this.paginator;
    this.indicatorDataSource.sort = this.sort;
  }
  redirectToCopyCompanyPage()
  {
      let companyDetails = {};
          companyDetails['companyId'] =this.companyId;
          companyDetails['frameworkId'] = this.selectedFrameworkId;

          this.router.navigate(['/copyActiveIndicators'], { queryParams: companyDetails, skipLocationChange: true });
  }
  redirectToArchievePage()
  {
       this.router.navigate(['/viewCompanyFrameworkArchives/'+this.companyId]);
  }
}
