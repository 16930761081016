import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType,RadialChartOptions } from 'chart.js';
import { ActivatedRoute } from '@angular/router';
import { AssessmentTemplateMasterdataService } from '../../../assessment/assessment-template-masterdata.service';
import { FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-asessment-dashboard',
  templateUrl: './asessment-dashboard.component.html',
  styleUrls: ['./asessment-dashboard.component.css']
})
export class AsessmentDashboardComponent implements OnInit {
  
  barChartData: ChartDataSets[];
  radarChartData: ChartDataSets[];
  fillSections:any=[]
  companyName:any;
  public barChartLabels = ["Company Documents","Environmental","Social","Governance"
  ]
  public barChartLegend = false;
  public barChartType: ChartType = 'bar';
  public colors = [
    {
      backgroundColor:["#70d6de","#70d6de","#70d6de","#70d6de","#70d6de"]
    },
    {
      backgroundColor:["#d8e64d","#d8e64d","#d8e64d","#d8e64d","#d8e64d"]
    },
    
  ];
  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio:false,
    scales: { xAxes: [{
     ticks:
      {
        beginAtZero:true,
        maxRotation:0,
        minRotation:0,
      },
    gridLines:{
      display:false
    }
    }], yAxes: [{
      scaleLabel: {
        display: true,
        labelString:'Score'
       },
       ticks:
       {
         beginAtZero:true,
       },
    }] },
  }
  public radarColors = [
    {
      backgroundColor: ["#83d483", "#83d483", "#83d483", "#83d483"]
    },
  ];
  public radarChartType: ChartType = 'radar'
  public radarChartOptions: RadialChartOptions = {
    responsive: false,
    // onClick:this.chartClicked,
    scale: {
      gridLines: {
        color: '#b3b4b4'
      },
      pointLabels: {
        fontSize: 13,
        fontColor: 'black'
      },
    },
  }
  assessmentId:any;
  factorVOList:any;
  factorId:any;
  questionList: any = [];
  remarksCheckBox:any=[];
  assessmentDetails:any;
  constructor(private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private assessmentRecordService :  AssessmentTemplateMasterdataService,) { }

  ngOnInit() {
    this.assessmentId=160;
    this.assessmentRecordService.viewAssessmentDetails(this.assessmentId).subscribe(data => {
      console.log("assessment details")
      console.log(data)
      this.factorVOList=data.factorVOList
      this.factorId=data.factorVOList[0].id
      for(let factor of data.factorVOList)
      {
        
        for(let subFactor of factor.subFactorVOList)
        {
           this.populateDataToQuestionForm(subFactor.questionVOList,data);
        }
    
      }
      this.assessmentDetails = data;
  })
    this.activatedRoute.queryParams.subscribe(params => {
     console.log(params.queryParams)
      this.companyName=params.companyName
    })
    this.fillSections=[true,false,false,false]
    this.barChartData = [
      { data: [23,90,49,23,43],label:'Industry Mean Score ',type:'bar',barThickness:16,barPercentage:0.5,},
      { data:  [18.97,9.62,17.5,18.18],label:'Company Score',type:'bar',barThickness:16,barPercentage:0.5},
      ];
      this.radarChartData = [
        { data: [18.97,9.62,17.5,18.18],label: 'Assessment Score', borderWidth: 3, pointRadius: 6, pointBorderColor: 'white', borderColor: '#17b417'},
        ];
      
  }
  populateDataToQuestionForm(questionVOList, data)
  {
    console.log("questionVOList")
    console.log(questionVOList)
    for(let question of questionVOList)
    {
      let questionId=question.id
      this.remarksCheckBox[questionId]=true
      this.questionList[questionId] = this.createQuestionListForm(questionId);
      console.log(question)
    }
  }


  checkCheckBoxvalue(event,id)
  {
    this.remarksCheckBox[id]=event.checked
  }
  createQuestionListForm(questionId) {

    return this.formBuilder.group({
      id: [questionId],
      verificationRemarks:['']
    });
  }
  changeSection(factorId,index)
  {
    this.factorId=factorId
    // console.log(id)
    console.log(index)
    if(index==0)
    {
      this.fillSections=[true,false,false,false]
    }
    else if(index==1)
    {
      this.fillSections=[false,true,false,false]
    }
    else if(index==2)
    {
      this.fillSections=[false,false,true,false]
    }
    else if(index==3)
    {
      this.fillSections=[false,false,false,true]
    }
  }
}
