import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpHeaders
} from "@angular/common/http";
import { BaseUrlService } from "../../shared/base-url.service";
@Injectable({
  providedIn: "root"
})
export class PerformanceRecordService {
  
  private baseUrl = BaseUrlService.PERFORMANCEFRAMEWORKBASEURL;
  private flaskUrl = BaseUrlService.FLASKBASEURL;
  private parameterBaseUrl=BaseUrlService.PARAMATERCONFIGURATIONBASEURL;
  constructor(private http: HttpClient) {}

   /*  fetchPerformanceRecord(userName: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/getPerformanceRecord/` + userName);
  } */
  fetchPerformanceRecord(performanceRecordDataVO: any) {
    let body = JSON.stringify(performanceRecordDataVO);
    let url = this.baseUrl + "/getPerformanceRecord";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };

    return this.http.post(url, body, httpOptions);
  }
  fetchPerformanceRecordBycompanyId(performanceRecordDataVO: any) {
    let body = JSON.stringify(performanceRecordDataVO);
    let url = this.baseUrl + "/getActiveCompanyFrameworkIndicators";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };

    return this.http.post(url, body, httpOptions);
  }
  /* fetchPerformanceRecordBycompanyId(
    companyId: number,
    userName: string
  ): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/getActiveCompanyFrameworkIndicators/` +
        companyId +
        `/` +
        userName
    );
  } */
  getperformanceRecordConfiguration(): Observable<any> {
    return this.http.get(`${this.baseUrl}/performanceRecordConfiguration`);
  }

  createRecordConfiguration(recordConfigurationForm: any) {
    let body = JSON.stringify(recordConfigurationForm);
    let url = this.baseUrl + "/performanceRecordConfiguration";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };

    return this.http.post(url, body, httpOptions);
  }
  createIndicatorSchedules(indicatorVO) {
    let body = JSON.stringify(indicatorVO);

    let url = this.baseUrl + "/createOrUpdatePerformanceRecord";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };

    return this.http.post(url, body, httpOptions);
  }
  createIndecatorScheduleWise(indicatorVO) {
    let body = JSON.stringify(indicatorVO);

    let url = this.baseUrl + "/createOrUpdatePerformanceRecordSchedulewise";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };

    return this.http.post(url, body, httpOptions);
  }

  fetchDocumentsByindicatorId(code: string, year: string): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/getImageLocation/` + code + "/" + year
    );
  }

  upload(formdata): Observable<any> {
    const req = new HttpRequest("POST", this.baseUrl + "/upload", formdata, {
      reportProgress: true,
      responseType: "text",
      headers: new HttpHeaders({
        "Content-Type": "multipart/form-data"
        })
    });

    return this.http.request(req);
  }

  downloadFile(): string {
    return this.baseUrl;
  }
   fetchFilenames(id, year): any {
    return this.http.get(
      this.baseUrl + "/getCompanyFileDetails/" + id + "/" + year
    );
    
  //fetchFilenames(code: string, year: string): any {
    //return this.http.get(
    //  this.flaskUrl + "/get_all_pdf?code=" + code + "&year=" + year
    //);
    // return this.http.get(this.flaskUrl+"/get_all_pdf?code=bvc mmnnn&year=2018");
  }
  getIndicatorCrawledData(scrapyRequestVO): Observable<any> {
    let body = JSON.stringify(scrapyRequestVO);
    let url = this.flaskUrl + "/search_indicator";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.http.post(url, body, httpOptions);
  }

  fetchHighlightedImages(code, year, name) {
    return (
      `${this.flaskUrl}/get_image?code=` +
      code +
      `&year=` +
      year +
      `&name=` +
      name
    );
  }
  fetchIndicatorResult(code: string, year: string, name: string): any {
    return this.http.get(
      this.flaskUrl +
        "/get_text?code=" +
        code +
        "&year=" +
        year +
        "&name=" +
        name
    );
  }
  fetchHighlightedImage(imagePath): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/getHighlightedImage?imagePath=` +
        encodeURIComponent(imagePath)
    );
  }
  fetchIndicatorMatches(
    indicatorId: string,
    year: string,
    companyId: string,
    offset: number
  ): Observable<any> {
    return this.http.get(
      this.baseUrl +
        "/getIndicatorMacthes/" +
        indicatorId +
        "/" +
        year +
        "/" +
        companyId +
        "/" +
        offset
    );
  }
  getApprotionedIndicatorValue(indicatorCalcVO): Observable<any> {
    let body = JSON.stringify(indicatorCalcVO);
    let url = this.baseUrl + "/apportionIndValue";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.http.post(url, body, httpOptions);
  }
 /*  fetchPerformanceRecordBySchedule(userName: string,companyId: number,year: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/fetchPerformanceRecordDetails/` +userName +`/` +companyId+`/`+year);
  } */
 fetchPerformanceRecordBySchedule(performanceRecordDataVO) {
    let body = JSON.stringify(performanceRecordDataVO);

    let url = this.baseUrl + "/fetchPerformanceRecordDetails";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };

    return this.http.post(url, body, httpOptions);
  }
  uploadPerfomanceRecordFile(formdata): Observable<any> {
    const req = new HttpRequest("POST", this.baseUrl + "/uploadPerfomanceRecordFile", formdata, {
      reportProgress: true,
      responseType: "text",
      headers: new HttpHeaders({
        "Content-Type": "multipart/form-data"
        })
       
    });
    return this.http.request(req);
  }
  downloadPerformanceRecordFile(): string {
    return this.baseUrl;
  }
  getCalculatedActualsFromDatapoint(indicatorVO) {
    let body = JSON.stringify(indicatorVO);
    let url = this.baseUrl + "/calculate";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.http.post(url, body, httpOptions);
  }
  getEncodedHighlightedImage(path)
  {
    return this.http.get(
      `${this.baseUrl}/highlightedImage/` +path
    );
  }
  createOrDeleteFileDetails(fileDetailVO)
  {
    let body = JSON.stringify(fileDetailVO);
    let url = this.baseUrl + "/fileDetails";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.http.post(url, body, httpOptions);

  }
  fetchDatasourceMatches(
    indicatorId: string,
    year: string,
    companyId: string
  ): Observable<any> {
    return this.http.get(
      this.baseUrl +
        "/getDatasourceMatches/" +
        indicatorId +
        "/" +
        year +
        "/" +
        companyId
    );
  }
  createOrDeleteFileDetailsDataSource(fileDetailsVO: any) {
    let body = JSON.stringify(fileDetailsVO);
    let url = this.baseUrl + "/fileDetailsDatasources";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return this.http.post(url, body, httpOptions);
  }
}


