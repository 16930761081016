import { Injectable } from '@angular/core';
import { BaseUrlService } from '../../../shared/base-url.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InvestorDashBoardServiceService {
  private baseUrl = BaseUrlService.REPORTBASEURL;

  constructor(private http: HttpClient) { }
  fetchInvestorDetailsById(investorId: number) {
    return this.http.get(this.baseUrl + '/getInvestorDetailsById/' + investorId);
  }
}
