import { Component, OnInit,ViewChild } from '@angular/core';
import { FormControl,FormGroup,FormBuilder,Validators} from '@angular/forms';
import { ComplianceDashboardService} from '../../compliance-dashboard.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-company-compliance-drilldown',
  templateUrl: './company-compliance-drilldown.component.html',
  styleUrls: ['./company-compliance-drilldown.component.css']
})
export class CompanyComplianceDrilldownComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dashboardDataArrayInside:any;
  displayedColumns:any;
  dataSource:any;
  companyDashboardForm:FormGroup;
  companyId:number;
  companyName:string;
  regionName:string;
  sectorName:string;
  countryName:string;
  subSectorName:string;
  periodData:any=[];
  companyIndicatorData:any=[];
  companyData:any=[];
  yearArray:any=[];
  initialPeriods:any = [];
  yearCount:number;
  companyIndicatorList:any=[];
  toggleList:boolean;
  isSubmit: boolean;
  selectedPeriod =[];
  loading:boolean;
  emptyFlag:boolean;
  firstYear:string;
  secondYear:string;
  thirdYear:string;
  pageNumber:number;
  noOfPages:number;
  presentYearCount:number;

  constructor( private formBuilder: FormBuilder,private complianceDashboardService:ComplianceDashboardService,
              private activatedRoute: ActivatedRoute,private router:Router) { 
  
    this.loading = false;
    this.toggleList = false;
    this.isSubmit = true;
    this.yearCount = 0;
    this.firstYear = null;
    this.secondYear = null;
    this.thirdYear = null;
    this.regionName = (this.activatedRoute.snapshot.queryParamMap.get('region').length > 0)? this.activatedRoute.snapshot.queryParamMap.get('region'):'-';
    this.sectorName = (this.activatedRoute.snapshot.queryParamMap.get('sector').length > 0)?this.activatedRoute.snapshot.queryParamMap.get('sector'):'-',
    this.countryName = (this.activatedRoute.snapshot.queryParamMap.get('country').length > 0)?this.activatedRoute.snapshot.queryParamMap.get('country'):'-',
    this.subSectorName = (this.activatedRoute.snapshot.queryParamMap.get('subSector').length > 0)?this.activatedRoute.snapshot.queryParamMap.get('subSector'):'-';
    this.companyId = parseInt(this.activatedRoute.snapshot.queryParamMap.get('companyId'));
    this.companyName = this.activatedRoute.snapshot.queryParamMap.get('companyName');
    let yearListArray=[];
    for(let year of this.activatedRoute.snapshot.queryParamMap.getAll('selectedYears')){
      yearListArray.push(parseInt(year));
    }
    this.selectedPeriod = yearListArray;
    this.initialPeriods = yearListArray;
    this.companyDashboardForm = this.formBuilder.group({
      period:[this.selectedPeriod,Validators.required]
    }) 
  }

  ngOnInit() {

      this.fetchQuarterlyCompanyComplianceDetails(1);
      this.noOfPages = Math.ceil(this.selectedPeriod.length / 3);
  }

  fetchQuarterlyCompanyComplianceDetails(noPage:number){
    let complianceDashboardVO:any = {};
    complianceDashboardVO.companyId = this.companyId;
    complianceDashboardVO.frequencyView ="QUARTERLY";
    complianceDashboardVO.yearIds =this.selectedPeriod;
    this.loading = true;
    this.complianceDashboardService.retrieveQuarterlyCompanyComplianceData(complianceDashboardVO).subscribe(data => {
        this.processCompanyDrilldownDetails(data,noPage);
        this.periodData = data.yearList;
        this.loading = false;
    });
  }

  reset(){
    
    this.selectedPeriod = this.initialPeriods;
    this.fetchQuarterlyCompanyComplianceDetails(1);
    this.noOfPages = Math.ceil(this.selectedPeriod.length / 3);
    let valueArray = [];
    for(let i of this.initialPeriods)
    {
      valueArray.push(i);
    }
    this.companyDashboardForm.get("period").patchValue(valueArray);
    this.isSubmit = true;
  }

  resetListView(){
    this.isSubmit=false;
  }

  onSubmit(noPage:number)
  {
    this.selectedPeriod = this.companyDashboardForm.get('period').value;
    if(this.companyDashboardForm.valid &&  this.selectedPeriod.length > 0 )
    {
      this.isSubmit = true;
      this.fetchQuarterlyCompanyComplianceDetails(noPage);
      this.noOfPages = Math.ceil(this.selectedPeriod.length / 3);
    }
  }
   
  processCompanyDrilldownDetails(data,noPage)
  {
    let company_data = data.esgList;
 
    if(data.yearArray.length > 0)
    {
      this.yearArray = data.yearArray;
      this.yearCount =  this.yearArray.length -1;
    }
    if(company_data.length > 0)
    {
      let performanceAreaName:string='';
      let headingCheckForPA =0;
      this.companyData =[];
      for(let i=0;i < company_data.length;i++)
      {
        performanceAreaName = company_data[i].esgTypeName;
        let indicatorStackList:any=[];
        this.companyIndicatorData=[];

        if(company_data[i].indicatorStackList!=null && company_data[i].indicatorStackList.length > 0)
        {
          indicatorStackList = company_data[i].indicatorStackList;
          for(let j=0;j < indicatorStackList.length;j++)
          {
            this.companyIndicatorList =[];

            if(indicatorStackList[j].indicatorList!=null && indicatorStackList[j].indicatorList.length > 0)
            {
              let indicatorList = indicatorStackList[j].indicatorList;
              indicatorList[0]['stackHeading'] = true;
              if(headingCheckForPA == 0)
              {
                indicatorList[0]['paHeading'] = true;
                headingCheckForPA = 1;
              }
              else
                indicatorList[0]['paHeading'] = false;
          
              this.companyIndicatorList= this.processPeriodDetails(indicatorList);
              
            
              this.companyIndicatorData.push({
                'indicatorStackName':indicatorStackList[j].indicatorStackName,
                'indicatorList': this.companyIndicatorList
              })
            }
          }
      }
      this.companyData.push({
        'performanceArea':performanceAreaName,
        'indicatordata': this.companyIndicatorData
      })
      headingCheckForPA =0;
    }
  }
  this.getDataTablePopulation(noPage);
}

processPeriodDetails(indicatorList)
{
  for(let k=0;k<indicatorList.length;k++)
  {
        let yearwiseList  =  indicatorList[k].yearwiseReportedDetails;
        for(let z=0;z< yearwiseList.length;z++)
        {
            let status = yearwiseList[z].reportedStatusQuarterly;
            let yearArray=[];

            //quarterly means 4 quarters
            for(let index = 1 ; index <= 4 ; index++){
              
                if(index in status){
                  yearArray.push({"key": index,"value":status[index]});
                }
                else{
                  yearArray.push({"key": index,"value":"NA"});
                }
            }
            yearwiseList[z].reportedStatusQuarterly = yearArray;  
        }
    }
    return indicatorList;
  }
  
  selectAll()
  {
    this.toggleList= !this.toggleList;
    let valueArray = [];
    if(this.toggleList)
    {
      for(let i of this.periodData)
      {
        valueArray.push(i);
      }
      
    }
    this.companyDashboardForm.get("period").patchValue(valueArray);
  }

  viewMonthlyDrilldownDetails(year)
  {
    let monthlyDrilldownDetails = {};
    monthlyDrilldownDetails['year'] = year;
    monthlyDrilldownDetails['companyId'] = this.companyId;
    monthlyDrilldownDetails['companyName'] = this.companyName;
    monthlyDrilldownDetails['region']=this.regionName;
    monthlyDrilldownDetails['sector']=this.sectorName;
    monthlyDrilldownDetails['country']=this.countryName;
    monthlyDrilldownDetails['subSector']=this.subSectorName;
    monthlyDrilldownDetails['yearList']=this.selectedPeriod;

    this.router.navigate(['dashboard/compliance/companyComplianceMonthlyDrillDown'],{queryParams:monthlyDrilldownDetails, skipLocationChange: true});

  }

  
  getDataTablePopulation(pageNo:number) {

    let periodDataList = [];
    let startIndex = 3 * (pageNo - 1);
    let endIndex;

    if(pageNo == this.noOfPages){
      endIndex = this.selectedPeriod.length % 3;
      if(endIndex == 0)
        endIndex = 3;
    }
    else{
      endIndex = 3;
    }

    for(let i = startIndex ; i < (startIndex + endIndex); i++){
      periodDataList.push(this.selectedPeriod[i])
    }

    this.pageNumber = pageNo;

    this.displayedColumns=[];
    let dashboardArray = [];  
    this.emptyFlag = true;
    for( let fetchCompData of this.companyData){
      var paFlag=false;    
      for(let fetchIndicatorData of fetchCompData.indicatordata ){
        var indicatorStackFlag=false;
        for(let indicatorData of fetchIndicatorData.indicatorList ){

          var newArray = {};
          this.emptyFlag = false;
          newArray['paFlag'] = false;  
          if(paFlag==false) {
            newArray['paFlag'] = true; 
            paFlag=true; 
          }
          newArray['performanceAreaName'] = fetchCompData.performanceArea;
          newArray['indicatorStackFlag'] = false;  
          if(indicatorStackFlag==false) {
            newArray['indicatorStackFlag'] = true; 
            indicatorStackFlag=true; 
          }
          newArray['indicatorStackName'] = fetchIndicatorData.indicatorStackName;
          newArray['indicatorId'] = indicatorData.id;
          newArray['frequency'] = indicatorData.frequency;
          newArray['indicatorName'] =indicatorData.indicatorName;
          newArray['frequencyCount'] = 4;
          let yearIndex = 1;
          for(let yearwiseReported of indicatorData.yearwiseReportedDetails ){
            if(periodDataList.includes(yearwiseReported.year)){
                if(yearIndex == 1){
                  this.firstYear = yearwiseReported.year;
                  yearIndex++;
                }
                else if(yearIndex == 2){
                  this.secondYear = yearwiseReported.year;
                  yearIndex++;
                }
                else if(yearIndex == 3){
                  this.thirdYear = yearwiseReported.year;
                  yearIndex++;
                }
                
                for(let data of yearwiseReported.reportedStatusQuarterly ){
                  newArray['yearwiseData_'+yearwiseReported.year+'_'+data.key] = data.value;
                }

            }
          }
          if(yearIndex==4){
            this.presentYearCount=3;
          }
          else if(yearIndex==3){
            this.presentYearCount=2;
          }
          else{
            this.presentYearCount=1;
          }
          dashboardArray.push(newArray);
        }
      }
      }
      if(periodDataList.length == 1){
        this.displayedColumns=['PerformanceArea','IndicatorStack','Indicator','FirstYear','Action','Error']
      }
      else if(periodDataList.length == 2){
        this.displayedColumns=['PerformanceArea','IndicatorStack','Indicator','FirstYear','SecondYear','Action','Error']
      }
      else if(periodDataList.length >= 3){
        this.displayedColumns=['PerformanceArea','IndicatorStack','Indicator','FirstYear','SecondYear','ThirdYear','Action','Error']
      }

      this.dataSource = new MatTableDataSource(dashboardArray);
      this.dataSource.paginator = this.paginator;
     
  }
}