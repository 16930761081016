import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { PerformanceFrameworkCompanyService } from '../performance-framework-company.service';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormBuilder, Validators, FormArray, AbstractControl, FormGroup } from '@angular/forms';
import { SearchIndicatorCommonComponent } from '../../../search-indicator-common/search-indicator-common.component';
import { GenerateSchedulesService } from '../../generate-schedules.service';
import { CustomValidationsService } from '../../../../shared/Validations/custom-validations.service';
import { AddEditSuccessComponent } from '../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { BaseUrlService } from '../../../../shared/base-url.service';
import swal from 'sweetalert2';
import { PerformanceFrameworkHigherService } from '../../higherLevel/performance-framework-higher.service';

@Component({
  selector: 'app-add-company-indicator-configurations',
  templateUrl: './add-company-indicator-configurations.component.html',
  styleUrls: ['./add-company-indicator-configurations.component.css']
})
export class AddCompanyIndicatorConfigurationsComponent implements OnInit {

  baselineValueFlag: boolean;
  baselineYearList: any;
  baselineFormGroup: FormGroup;
  formulaTypeFlag: string;
  currentYear: boolean;
  valueList: any = [];

  valid: boolean;
  selectedIndicatorList: any = [];
  customIndicatorId: number;
  indicatorList: any = [];
  companyVO: any;
  scheduleForm: any = {};
  finalTargetFormGroup: FormGroup;
  schedulesList: any;
  frequencyList: any;
  companyDetails: any;
  performanceFramework: any;
  displayedColumns: any;
  dataSource: any = [];
  jsonArray: any = [];
  isWebservice = [];
  userList: any = [];
  currentRowId: any;
  indicatorSelected: any = {};
  left_ind_box = 'col-lg-12';
  view_schedule: string;
  ind_inner_box: string;
  view_webservice: string;
  addScheduleForm: FormGroup;
  dateList: any;
  endDateList: any;
  startYearList: any;
  endList: any;
  endYearList: any;
  sourceList: any;
  frameworkId: any;
  viewWebservice: boolean;
  viewSchedule: boolean = false;
  frameworkIndicatorFormGroup: any = {};
  webserviceForm: FormGroup;
  webservice: any;
  paginator: any;
  filterValue: string = '';
  public yearSelect: boolean = false;
  selectFlag: boolean = false;
  public basePlaceholder = "Baseline Value";
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  @ViewChild("addScheduleFormRef") addScheduleFormRef;
  @ViewChild("webServiceFormRef") webServiceFormRef;
  @ViewChild("baseModal") baseModal: ElementRef;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('baseChildren') baseChildren: ElementRef;
  public loading = false;
  constructor(private renderer: Renderer2, private elementRef: ElementRef, private dialog: MatDialog, private formBuilder: FormBuilder, private baseUrlService: BaseUrlService,
    private performanceFrameworkCompanyService: PerformanceFrameworkCompanyService,
    private performanceFrameworkHigherService: PerformanceFrameworkHigherService,
    private activatedRoute: ActivatedRoute, private router: Router, private generateSchedulesService: GenerateSchedulesService,
    private customValidationsService: CustomValidationsService, private addEditSuccessComponent: AddEditSuccessComponent) {


    this.renderer.listen('window', 'click', (e: Event) => {

      if (this.toggleButton) {
        if (e.target !== this.toggleButton.nativeElement && e.target !== this.baseModal.nativeElement) {
          if ((e.srcElement && e.srcElement.classList.contains("baseChildren")) == false &&
            this.selectFlag == false && document.getElementById("baseline-modal")) {
            document.getElementById("baseline-modal").classList.remove("base-display");
          }
        }
        this.selectFlag = false;
      }
    });
    this.activatedRoute.queryParams.subscribe(params => {
      this.companyDetails = params;
      this.frameworkId = parseInt(this.companyDetails.frameworkId)

    })
    this.customIndicatorId = parseInt(this.activatedRoute.snapshot.queryParamMap.get('customIndicatorId'));
    this.selectedIndicatorList = this.activatedRoute.snapshot.queryParamMap.get('indicators');
    this.frameworkId = this.activatedRoute.snapshot.queryParamMap.get('frameworkId');

    this.selectedIndicatorList = JSON.parse(this.selectedIndicatorList);
  }

  toggleBaseline() {
    if (document.getElementById("baseline-modal"))
      document.getElementById("baseline-modal").classList.add("base-display");
  }
  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    let indDivs = document.querySelectorAll('.selected-arrow');
    if (indDivs && indDivs.length) {
      for (let i = 0; i < indDivs.length; i++) {
        indDivs[i].classList.remove('selected-arrow');
      }
    }
    if (this.viewSchedule == true || this.viewWebservice == true) {
      this.selectedRow();
    }
  }

  ngOnInit() {
    this.getAllUsers();
    this.startYearList = this.generateSchedulesService.getYearList();
    let sourceRequestVO = this.generateSchedulesService.getSourceRequestVO();
    this.generateSchedulesService.getIndicatorMasterData(sourceRequestVO).subscribe(data => {
      this.sourceList = data.constantsVOList;

    })
    let frequencyRequestVo = this.generateSchedulesService.getFrequencyRequestVO();
    this.generateSchedulesService.getIndicatorMasterData(frequencyRequestVo).subscribe(data => {
      this.frequencyList = data.constantsVOList;

    })
    if (this.companyDetails.frameworkId == 0 || this.companyDetails.frameworkStatus == "R") {
      let saveStatus = 'D';
      this.performanceFrameworkCompanyService.fetchIndicatorbyCompanyType(this.companyDetails.id, saveStatus).subscribe(data => { // changed companytypeid to company id
        //returns indicator list

        this.companyVO = data;
        this.indicatorList = data.performanceframeworkVO.indicatorVOList;
        this.indicatorList.forEach(indicator => {
          this.setframeworkIndicatorFormGroup(indicator);

        })
        this.addCustomToList();
        this.fetchDataTable(this.indicatorList);
      })
    } else {
      this.fetchFrameworkDetails();

    }
  }
  fetchFrameworkDetails() {
    this.performanceFrameworkCompanyService.fetchFrameworkDetails(this.frameworkId).subscribe(data => {
      this.companyVO = data;
      this.indicatorList = data.performanceframeworkVO.indicatorVOList;
      this.indicatorList.forEach(indicator => {
        this.setframeworkIndicatorFormGroup(indicator);

      })
      this.addCustomToList();
      this.fetchDataTable(this.indicatorList);
    })
  }
  addCustomToList() {
    if (this.customIndicatorId > 0) {
      let indicatorRequestVO = {};
      indicatorRequestVO['indicatorId'] = this.customIndicatorId;
      indicatorRequestVO['indicatorvo'] = true;
      this.performanceFrameworkCompanyService.getIndicatorDetails(indicatorRequestVO).subscribe(data => {
        if (data != null) {
          this.indicatorList.push(data.indicatorVO);
        }
        this.indicatorList.forEach(indicator => {
          this.frameworkIndicatorFormGroup[indicator.id] = this.createIndicatorDetailsFormGroup(indicator);
          this.setframeworkIndicatorFormGroup(indicator);
        })
        this.fetchDataTable(this.indicatorList);
      });
    }
    if (this.selectedIndicatorList) {
      let popupDetails: boolean = false;
      this.checkIndicatorAlreadyAdded(this.selectedIndicatorList, popupDetails)

      this.fetchDataTable(this.indicatorList);
    }
  }

  public fetchDataTable(data) {

    this.displayedColumns = [];
    this.jsonArray = [];

    for (let i of data) {
      var newArray = {};
      newArray['indicatorCode'] = i.code;
      newArray['indicatorName'] = i.name;
      if (i.subcategoryVO != null)
        newArray['performanceArea'] = i.subcategoryVO.name;
      if (i.indicatorConfigurationVO) {
        newArray['source'] = i.indicatorConfigurationVO.source;
          newArray['responsibleUser'] = i.indicatorConfigurationVO.responsibleUser;
        newArray['indicatorConfigurationVO'] = i.indicatorConfigurationVO;
        newArray['frameworkIndicatorDetailId'] = i.indicatorConfigurationVO.frameworkIndicatorDetailId;
        if (i.indicatorConfigurationVO.mandatory == 1)
          newArray['mandatory'] = true;
        else
          newArray['mandatory'] = false;
        if (i.indicatorConfigurationVO.critical == 1)
          newArray['critical'] = true;
        else
          newArray['critical'] = false;
      }
      if ((!i.indicatorConfigurationVO) && i.indicatorDetailsVO) {
        if (i.indicatorDetailsVO.mandatory == 1)
          newArray['mandatory'] = true;
        else
          newArray['mandatory'] = false;
        if (i.indicatorDetailsVO.critical == 1)
          newArray['critical'] = true;
        else
          newArray['critical'] = false;

      }
      newArray['schedule'] = '';
      newArray['id'] = i.id;
      newArray['indicatorType'] = i.indicatorType;
      this.jsonArray.push(newArray);
    }
    this.generateDataTable();

  }
  createIndicatorDetailsFormGroup(indicator) {
   
    return this.formBuilder.group({
      id: [indicator.id],
      source: ['', [Validators.required]],
      responsibleUser: ['', [Validators.required]],
    })
  }
  getAllUsers() {
    let masterRequestVO = {
      'userList': true
    };
    this.baseUrlService.getListOfUsers(masterRequestVO).subscribe(data => {
      let userdata: any = data;
      this.userList = userdata.userVOList;
    });

  }
  //function to change the class from style1 to style2 when clicked
  toggle_class(toggleFlg: any) {
    if (toggleFlg == "webservice" || toggleFlg == "schedule") {
      if (this.left_ind_box == "col-lg-12") {
        this.left_ind_box = "col-lg-8 col-md-8";
        this.view_schedule = "col-lg-4 col-md-4 sch no-pad-right";
        this.view_webservice = "col-lg-4 col-md-4 web no-pad-right";
        this.ind_inner_box = "ind-table-wrap";
      }
      else {
        if (toggleFlg == "schedule") {
          this.view_webservice = "display-none";
        }
        else {
          this.view_schedule = "display-none";
        }
      }
    }
    else {
      this.left_ind_box = "col-lg-12";
      this.view_webservice = "display-none";
      this.view_schedule = "display-none";
      let indDivs = document.querySelectorAll('.selected-arrow');
      if (indDivs && indDivs.length) {
        for (let i = 0; i < indDivs.length; i++) {
          indDivs[i].classList.remove('selected-arrow');
        }
      }
    }

  }
  removeArrow(rowId) {
    this.currentRowId = rowId;
  }
  applyFilter(filterValue: string) {
    this.filterValue = filterValue;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;

  }



  selectedRow() {
    if (this.currentRowId && document.getElementById("indMatRow" + this.currentRowId))
      document.getElementById("indMatRow" + this.currentRowId).classList.add("selected-arrow");
  }
  showSchedule(indicatorSelected, saveStatus) {
    this.viewWebservice = false;
    if (this.addScheduleFormRef) {
      this.addScheduleFormRef.resetForm();
    }
    let yearList = this.generateSchedulesService.getYearList();
    this.startYearList = yearList;
    this.endYearList = yearList;
    //this.fetchFrameworkDetails();
    if (this.frameworkIndicatorFormGroup[indicatorSelected.id].valid) {
      this.indicatorSelected = this.indicatorList.find(e => e.id === indicatorSelected.id);

      if (this.indicatorSelected) {
        if (!(this.indicatorSelected.indicatorConfigurationVO)) {
          this.indicatorSelected.indicatorConfigurationVO = {};
        }
        this.indicatorSelected.indicatorConfigurationVO.source = this.frameworkIndicatorFormGroup[this.indicatorSelected.id].controls['source'].value;
        this.indicatorSelected.indicatorConfigurationVO.responsibleUser = this.frameworkIndicatorFormGroup[this.indicatorSelected.id].controls['responsibleUser'].value;
      }
      this.viewSchedule = true;
      this.toggle_class('schedule');
      this.schedulesList = null;
      this.addScheduleForm = this.createScheduleGenerationFormGroup(this.indicatorSelected);

      if (!this.indicatorSelected.indicatorConfigurationVO.intermediateTarget) {

        this.finalTargetFormGroup = this.createFinalTargetFormGroup();

        if (this.indicatorSelected.indicatorConfigurationVO.target == 'M') {
          if (this.indicatorSelected.indicatorType.code == "QR")//qualitative
          {
            this.finalTargetFormGroup.controls['finalTarget'].setValidators([Validators.required]);
          }

          else if (this.indicatorSelected.indicatorType.code == "QT")//textual
          {
            this.finalTargetFormGroup.controls['finalTarget'].setValidators([Validators.required, this.customValidationsService.noWhitespaceValidator]);
          }

          else//quantitative
          {
            this.finalTargetFormGroup.controls['finalTarget'].setValidators([Validators.required, this.customValidationsService.noWhitespaceValidator, Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)]);
          }
        }
        else {
          if (this.indicatorSelected.indicatorType.code == "QU")
            this.finalTargetFormGroup.controls['finalTarget'].setValidators([Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)]);
        }
      }
      if (this.indicatorSelected.indicatorConfigurationVO.scheduleVOList && this.indicatorSelected.indicatorConfigurationVO.scheduleVOList.length > 0) {
        this.setScheduleValues();
      }
      else {
        let customIndicator = 'S';
        this.createCompanyFrameworkConfiguration(false, saveStatus, customIndicator);
      }

    }
    else {
      this.viewSchedule = false;
      if (!this.frameworkIndicatorFormGroup[indicatorSelected.id].value.source) {
        this.frameworkIndicatorFormGroup[indicatorSelected.id].controls['source'].setValue('');
        this.frameworkIndicatorFormGroup[indicatorSelected.id].get('source').markAsTouched();
      }

      if (!this.frameworkIndicatorFormGroup[indicatorSelected.id].value.responsibleUser) {
        this.frameworkIndicatorFormGroup[indicatorSelected.id].controls['responsibleUser'].setValue('');
        this.frameworkIndicatorFormGroup[indicatorSelected.id].get('responsibleUser').markAsTouched();
      }

    }
  }
  setScheduleValues() {
    let sYear = new Date(this.indicatorSelected.indicatorConfigurationVO.indicatorStartdate);
    let eYear = new Date(this.indicatorSelected.indicatorConfigurationVO.indicatorEnddate);
    let startYear = Number(sYear.getFullYear());
    let endYear = Number(eYear.getFullYear());
    if (this.indicatorSelected.indicatorConfigurationVO.indicatorStartdate != null)
      this.addScheduleForm.get('startYear').setValue(startYear);
    else
      this.addScheduleForm.get('startYear').setValue('');
    if (this.indicatorSelected.indicatorConfigurationVO.indicatorEnddate != null)
      this.addScheduleForm.get('endYear').setValue(endYear);
    else
      this.addScheduleForm.get('endYear').setValue('');
    if (this.indicatorSelected.indicatorConfigurationVO.frequency) {
      this.addScheduleForm.get('frequency').setValue(this.indicatorSelected.indicatorConfigurationVO.frequency);

    }
    this.populateDate(this.indicatorSelected.indicatorConfigurationVO.frequency);
    // get schedule details to populate with new id
    this.performanceFrameworkCompanyService.fetchSchedulesForIndicator(this.indicatorSelected.indicatorConfigurationVO.frameworkIndicatorDetailId, this.indicatorSelected.indicatorType.id).subscribe(data => {
      this.schedulesList = data; //this.indicatorSelected.indicatorConfigurationVO.scheduleVOList;
      let i = 0;
      this.schedulesList.forEach(schedule => {

        this.scheduleForm[i++] = this.createScheduleFormGroup(schedule);
        if (this.indicatorSelected.indicatorConfigurationVO.intermediateTarget) {
          var abstractControl: AbstractControl = this.scheduleForm[i - 1];

          if (abstractControl instanceof FormGroup) {

            (<FormGroup>abstractControl).addControl('targetValue', new FormControl({ value: '' }));
            if (this.indicatorSelected.indicatorConfigurationVO.target == 'M') {
              if (this.indicatorSelected.indicatorType.code == "QR")//qualitative
                this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.required]);
              else if (this.indicatorSelected.indicatorType.code == "QT")//textual
                this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.required, this.customValidationsService.noWhitespaceValidator]);
              else //quantitative case
                this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.required, this.customValidationsService.noWhitespaceValidator, Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)]);
            }
            else {
              if (this.indicatorSelected.indicatorType.code == "QU")
                this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)]);
            }
            // set values to schedule form
            if (this.indicatorSelected.indicatorType.code == "QR") {
              this.scheduleForm[i - 1].controls['targetValue'].setValue(Number(schedule.targetValue));
            }
            else {
              this.scheduleForm[i - 1].controls['targetValue'].setValue(schedule.targetValue);
            }
          }
        }
        else {
          this.scheduleForm[i - 1].removeControl('targetValue');
          if (this.indicatorSelected.indicatorType.code == "QR")
            this.finalTargetFormGroup.controls['finalTarget'].setValue(Number(this.indicatorSelected.indicatorConfigurationVO.finalTarget));
          else
            this.finalTargetFormGroup.controls['finalTarget'].setValue(this.indicatorSelected.indicatorConfigurationVO.finalTarget);
        }
      })
      //this.selectedRow();

    })
  }

  setFormValidations() {
    let validFlag = true;
    for (let indicator of this.indicatorList) {
      if (!this.frameworkIndicatorFormGroup[indicator.id].value.source) {
        this.frameworkIndicatorFormGroup[indicator.id].controls['source'].setValue('');
        this.frameworkIndicatorFormGroup[indicator.id].get('source').markAsTouched();
        validFlag = false;
      }
      if (!this.frameworkIndicatorFormGroup[indicator.id].value.responsibleUser) {
        this.frameworkIndicatorFormGroup[indicator.id].controls['responsibleUser'].setValue('');
        this.frameworkIndicatorFormGroup[indicator.id].get('responsibleUser').markAsTouched();
        validFlag = false;
      }
    }
    return validFlag;

  }
  createCompanyFrameworkConfiguration(clickFromSubmit: boolean, saveStatus, addIndicatorClick): any {
    let validFlag = true;
    console.log(clickFromSubmit + " :: " + saveStatus + " :: " + addIndicatorClick)
    this.companyVO.performanceframeworkVO.indicatorVOList = [];
    //    this.companyVO.performanceframeworkVO.frameworkStatus = saveStatus;
    //this.performanceFrameworkCompanyService.fetchFrameworkDetails(this.frameworkId).subscribe(data => {
    // this.companyVO = data;
    //this.indicatorList = data.performanceframeworkVO.indicatorVOList;
    if (!clickFromSubmit) {
      if (this.companyVO.performanceframeworkVO.frameworkStatus == 'A') {
        // this.indicatorList = this.indicatorList.filter(obj => obj.id != this.indicatorSelected.id);
        // this.indicatorList.push(this.indicatorSelected);
        for (let indicator of this.indicatorList) {
          if (this.frameworkIndicatorFormGroup[indicator.id].controls['source'].value) {

            indicator.indicatorConfigurationVO.source = this.frameworkIndicatorFormGroup[indicator.id].controls['source'].value;
          }
          if (this.frameworkIndicatorFormGroup[indicator.id].controls['responsibleUser'].value) {

            indicator.indicatorConfigurationVO.responsibleUser = this.frameworkIndicatorFormGroup[indicator.id].controls['responsibleUser'].value;
          }
          this.companyVO.performanceframeworkVO.indicatorVOList.push(indicator);
        }
        //  this.companyVO.performanceframeworkVO.indicatorVOList.push(this.indicatorList);
      }
      else {

        this.companyVO.performanceframeworkVO.indicatorVOList.push(this.indicatorSelected);
      }
    }
    else {
      if (saveStatus == 'A') {
        validFlag = this.setFormValidations();
      }
      for (let indicator of this.indicatorList) {
        if (!indicator.indicatorConfigurationVO) { indicator.indicatorConfigurationVO = {}; }
        if (this.frameworkIndicatorFormGroup[indicator.id].controls['source'].value) {

          indicator.indicatorConfigurationVO.source = this.frameworkIndicatorFormGroup[indicator.id].controls['source'].value;
        }
        if (this.frameworkIndicatorFormGroup[indicator.id].controls['responsibleUser'].value) {

          indicator.indicatorConfigurationVO.responsibleUser = this.frameworkIndicatorFormGroup[indicator.id].controls['responsibleUser'].value;
        }
        this.companyVO.performanceframeworkVO.indicatorVOList.push(indicator);
      }
    }
    this.companyVO.performanceframeworkVO.frameworkStatus = saveStatus;
    if (validFlag) {

      this.loading = true;
      this.performanceFrameworkCompanyService.createOrUpdateCompanyFrameworkConfiguration(this.companyVO).subscribe(data => {

        this.frameworkId = data.frameworkId;
        if (addIndicatorClick == 'C') {
          let companyDetails = {};
          companyDetails['companyId'] = this.companyVO.id;
          companyDetails['frameworkId'] = this.frameworkId;
          companyDetails['companyTypeId'] = this.companyVO.companyTypeVO.id;
          companyDetails['customFlag'] = true;
          companyDetails['routingFromPerformanceFramework'] = true;

          this.router.navigate(['/addIndicator'], { queryParams: companyDetails, skipLocationChange: true });
        }
        else if (addIndicatorClick == 'I') {
          let companyDetails = {};
          companyDetails['companyId'] = this.companyVO.id;
          companyDetails['frameworkId'] = this.frameworkId;

          this.router.navigate(['/copyActiveIndicators'], { queryParams: companyDetails, skipLocationChange: true });
        }
        else if (saveStatus == 'D' && !clickFromSubmit) {
          let newPerformanceFrameworkDetails: any = data;
          this.companyVO.performanceframeworkVO = {};
          this.companyVO.performanceframeworkVO = data;
          let savedIndicatorList = newPerformanceFrameworkDetails.indicatorVOList;
          this.indicatorSelected = savedIndicatorList.find(e => e.id == this.indicatorSelected.id);

          //   this.indicatorSelected = newPerformanceFrameworkDetails.indicatorVOList[0];
          // this.indicatorList = this.indicatorList.filter(obj => obj.id != this.indicatorSelected.id);
          // this.indicatorList.push(this.indicatorSelected);
          const index = this.indicatorList.indexOf(this.indicatorList.find(x => x.id == this.indicatorSelected.id), 0);
          if (index > -1) {

            this.indicatorList.splice(index, 1, this.indicatorSelected);
          }
          this.fetchDataTable(this.indicatorList);
          if (this.filterValue && this.filterValue.length > 0) {
            this.applyFilter(this.filterValue);
          }

          if (this.viewSchedule) {

            this.setScheduleValues();

          }

          // get schedule details to populate with new id ends
          //get web service details with new detail id
          if (this.viewWebservice) {
            this.performanceFrameworkCompanyService.getWebserviceForIndicator(this.indicatorSelected.indicatorConfigurationVO.frameworkIndicatorDetailId).subscribe(data => {
              let indicatorConfigurationVO = data;
              this.webservice = indicatorConfigurationVO.webServiceVO;
              if (this.webservice != null && this.webservice.webserviceParameterVOList != null) {
                if (this.webservice.webserviceParameterVOList.length > 0) {
                  this.deleteRow(0);
                }
                this.webservice.webserviceParameterVOList.forEach(parameter => {
                  this.addNewRow();
                })
                this.webserviceForm.patchValue(this.webservice);
              }
            })
          }
          this.loading = false;
        }
        else {
          var message;
          if (saveStatus == 'D' && clickFromSubmit) {
            message = "Successfully Saved Company Framework";
            // this.indicatorList = [];
            // this.indicatorList.push(data.indicatorVOList[0])
            this.addEditSuccessComponent.showSuccessSwal(message);
            this.performanceFrameworkCompanyService.fetchFrameworkDetails(this.frameworkId).subscribe(data => {
              this.companyVO = data;
              this.indicatorList = data.performanceframeworkVO.indicatorVOList;
              this.indicatorList.forEach(indicator => {
                this.setframeworkIndicatorFormGroup(indicator);

              })
              this.fetchDataTable(this.indicatorList);
            })
            //    this.router.navigate(['/searchCompaniesFramework']);

          }
          else if (saveStatus == 'A' && clickFromSubmit) {
            message = "Successfully Submitted Company Framework";
            this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
              this.router.navigate(['/searchCompaniesFramework']);
            });
          }
        }
      });
    }
    this.loading = false;
    // })


  }
  createScheduleGenerationFormGroup(indicator) {
    return this.formBuilder.group({
      frequency: ['', [Validators.required]],
      startYear: ['', [Validators.required]],
      endYear: ['', [Validators.required]],

    })
  }

  createFinalTargetFormGroup() {
    return this.formBuilder.group({
      finalTarget: [],

    })
  }
  populateDate(frequencyOfMeasurement) {
    this.scheduleForm = {};
    this.schedulesList = null;
    if (frequencyOfMeasurement) {
      this.dateList = this.generateSchedulesService.getDateList(frequencyOfMeasurement.code);
    }
    else {
      this.dateList = null;
    }
    this.endDateList = this.dateList;
    if (this.dateList != null) {
      let startFrequency = this.indicatorSelected.indicatorConfigurationVO.start.toString();
      let endFrequency = this.indicatorSelected.indicatorConfigurationVO.end.toString();
      var abstractControl: AbstractControl = this.addScheduleForm;
      if (abstractControl instanceof FormGroup) {
        (<FormGroup>abstractControl).addControl('start', new FormControl({ value: '' }, Validators.required));
        (<FormGroup>abstractControl).addControl('end', new FormControl({ value: '' }, Validators.required));
      }
      if (this.indicatorSelected.indicatorConfigurationVO.frequency) {
        if (frequencyOfMeasurement.id == this.indicatorSelected.indicatorConfigurationVO.frequency.id) {
          this.addScheduleForm.controls['start'].setValue(startFrequency);
          this.addScheduleForm.controls['end'].setValue(endFrequency);

        }
        else {
          this.addScheduleForm.controls['start'].setValue('');
          this.addScheduleForm.controls['end'].setValue('');
        }
      }
    }
    else {
      this.addScheduleForm.removeControl('start');
      this.addScheduleForm.removeControl('end');
    }

  }
  ascOrder = (a, b) => {
    return Number(a.key) < Number(b.key) ? -1 : 1;
  }

  generateYearChange(value) {
    let selectedStartYearIndex = this.startYearList.indexOf(value);
    this.endYearList = this.startYearList.slice(selectedStartYearIndex);
    this.addScheduleForm.get('endYear').reset();

  }

  showIndicatorPopup() {
    this.viewSchedule = false;
    this.viewWebservice = false;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = "80%";
    dialogConfig.data = { 'nonMandatoryFlag': true };

    const dialogRef = this.dialog.open(SearchIndicatorCommonComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      let popUpdetails: boolean = true
      this.checkIndicatorAlreadyAdded(data, popUpdetails);

    });
  }
  checkIndicatorAlreadyAdded(data, popUpdetails) {
    if (data != undefined) {
      for (let indicator of data) {
        var newIndicator = [];
        if (!this.indicatorList.find(ind => ind.id == indicator.id)) {
          //  if (popUpdetails) {
          let indicatorVO = {};
          indicatorVO['code'] = indicator.code;
          indicatorVO['name'] = indicator.name;

          indicatorVO['id'] = indicator.id;
          let indicatorType = {};
          if (indicator.indicatorTypeId) {
            indicatorType['id'] = indicator.indicatorTypeId;
            indicatorVO['indicatorType'] = indicatorType;
          }
          else if (indicator.indicatorType) {
            indicatorVO['indicatorType'] = indicator.indicatorType;
          }
          let indicatorConfigurationVO = {};
          let subcategoryVO = {};
          if (indicator.performanceArea) {
            subcategoryVO['name'] = indicator.performanceArea;
          }
          else {
            subcategoryVO['name'] = indicator.subcategoryVO.name;
          }
          indicatorVO['subcategoryVO'] = subcategoryVO;
          if (indicator.indicatorConfigurationVO && indicator.indicatorConfigurationVO.mandatory) {
            indicatorConfigurationVO['mandatory'] = indicator.indicatorConfigurationVO.mandatory;
          }
          else if (indicator.mandatory == true) {
            indicatorConfigurationVO['mandatory'] = 1;
          }
          else {
            indicatorConfigurationVO['mandatory'] = 2;
          }
          if (indicator.indicatorConfigurationVO && indicator.indicatorConfigurationVO.critical) {
            indicatorConfigurationVO['critical'] = indicator.indicatorConfigurationVO.critical;
          }
          else if (indicator.critical == true) {
            indicatorConfigurationVO['critical'] = 1;
          }
          else {
            indicatorConfigurationVO['critical'] = 2;
          }

          indicatorVO['indicatorConfigurationVO'] = indicatorConfigurationVO;

          newIndicator.push(indicatorVO)
          this.indicatorList.push(newIndicator[0]);
          // }
          // else {
          //   this.indicatorList.push(indicator);
          // }
          this.setIndicatorFormGroupForNew(indicator)
          //  this.setframeworkIndicatorFormGroup(indicator);
        }
      }
    }
    this.fetchDataTable(this.indicatorList);
    console.log(this.indicatorList)

  }
  setIndicatorFormGroupForNew(indicator) {
    console.log(indicator)
    this.frameworkIndicatorFormGroup[indicator.id] = this.createIndicatorDetailsFormGroup(indicator);
    this.isWebservice[indicator.id] = 0;
    this.frameworkIndicatorFormGroup[indicator.id].controls['responsibleUser'].setValue(this.companyVO.responsibleUser.name);

  }
  setframeworkIndicatorFormGroup(indicator) {

    this.frameworkIndicatorFormGroup[indicator.id] = this.createIndicatorDetailsFormGroup(indicator);
    if (indicator.indicatorConfigurationVO) {
      if (indicator.indicatorConfigurationVO.source) {
        this.isWebservice[indicator.id] = indicator.indicatorConfigurationVO.source.code;
        this.frameworkIndicatorFormGroup[indicator.id].controls['source'].setValue(indicator.indicatorConfigurationVO.source);

      }
      if(indicator.indicatorConfigurationVO.responsibleUser){
        this.frameworkIndicatorFormGroup[indicator.id].controls['responsibleUser'].setValue(indicator.indicatorConfigurationVO.responsibleUser);
      }
      else{
        this.frameworkIndicatorFormGroup[indicator.id].controls['responsibleUser'].setValue(this.companyVO.responsibleUser.name);
      }
    }
    else {
      this.isWebservice[indicator.id] = 0;
      this.frameworkIndicatorFormGroup[indicator.id].controls['responsibleUser'].setValue(this.companyVO.responsibleUser.name);
    }
  }

  generateDataTable() {

    this.dataSource = [];
    this.displayedColumns = ['mandatory', 'indicatorCode', 'indicatorName', 'performanceArea', 'source', 'responsibleUser', 'schedule', 'actions'];
    this.dataSource = new MatTableDataSource(this.jsonArray);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  populateWebservice(source, indicator) {
    this.toggle_class('otherSource');
    this.isWebservice[indicator.id] = source.code;
    if (source.code == "WS") {
      this.viewWebservice = true;
      this.viewSchedule = false;
      this.toggle_class('webservice');
      this.indicatorSelected = this.indicatorList.find(e => e.id === indicator.id);
      this.webserviceForm = this.createWebserviceFormGroup();
      if (this.indicatorSelected.indicatorConfigurationVO && this.indicatorSelected.indicatorConfigurationVO.frameworkIndicatorDetailId > 0) {
        this.performanceFrameworkCompanyService.getWebserviceForIndicator(this.indicatorSelected.indicatorConfigurationVO.frameworkIndicatorDetailId).subscribe(data => {
          this.webservice = data.webServiceVO;
          if (this.webservice != null) {
            if (this.webservice.webserviceParameterVOList != null && this.webservice.webserviceParameterVOList.length > 0) {
              this.deleteRow(0);
            }
            this.webservice.webserviceParameterVOList.forEach(parameter => {
              this.addNewRow();
            })
            this.webserviceForm.patchValue(this.webservice);
          }
          let indDivs = document.querySelectorAll('.selected-arrow');
          if (indDivs && indDivs.length) {
            for (let i = 0; i < indDivs.length; i++) {
              indDivs[i].classList.remove('selected-arrow');
            }
          }
          this.selectedRow();
        })
      }
      else {
        if (!(this.indicatorSelected.indicatorConfigurationVO)) {
          this.indicatorSelected.indicatorConfigurationVO = {};

        }
        this.indicatorSelected.indicatorConfigurationVO.source = source;
        this.createCompanyFrameworkConfiguration(false, 'D', 'S');
      }
    }
    else {
      this.toggle_class('otherSource');
      this.viewWebservice = false;
    }
  }

  createWebserviceFormGroup() {
    return this.formBuilder.group({
      id: [0],
      url: ['', [Validators.required, this.customValidationsService.noWhitespaceValidator]],
      username: ['', [Validators.required, this.customValidationsService.noWhitespaceValidator]],
      password: ['', [Validators.required, this.customValidationsService.noWhitespaceValidator]],
      indicatorDetailsId: this.indicatorSelected.companyIndicatorDetailsId,
      webserviceParameterVOList: this.formBuilder.array([this.initParameterRows()])
    })
  }
  initParameterRows() {
    return this.formBuilder.group({
      parameterName: ['', [Validators.required, this.customValidationsService.noWhitespaceValidator]],
      parameterValue: ['', [Validators.required, this.customValidationsService.noWhitespaceValidator]]
    });
  }
  addNewRow() {
    const controls = this.formArray;
    this.formArray.push(this.initParameterRows());
  }

  deleteRow(index: number) {
    this.formArray.removeAt(index);
  }

  get formArray() {
    return this.webserviceForm.get('webserviceParameterVOList') as FormArray;
  }

  submitWebservice() {

    if (this.webserviceForm.valid) {
      let indicatorConfigurationVO = {};
      indicatorConfigurationVO['frameworkIndicatorDetailId'] = this.indicatorSelected.indicatorConfigurationVO.frameworkIndicatorDetailId;
      indicatorConfigurationVO['webServiceVO'] = this.webserviceForm.value;
      this.performanceFrameworkCompanyService.submitWebservice(indicatorConfigurationVO).subscribe(data => {
        var message = "Successfully Saved Webservice Configuration";
        this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
        });
        this.webServiceFormRef.resetForm();
        this.toggle_class('otherSource');
      })
    }
  }
  generateSchedules() {
    this.scheduleForm = {};
    this.schedulesList = [];

    if (this.addScheduleForm.valid) {
      let startDateVal = Number(this.addScheduleForm.value.start);
      let endDateVal = Number(this.addScheduleForm.value.end);
      if ((this.addScheduleForm.value.startYear == this.addScheduleForm.value.endYear) && (endDateVal < startDateVal)) {
        var failMessage = " Please Select Valid Start And End";
        this.addEditSuccessComponent.showFailedSwal(failMessage, 'Failed');
      }

      else {
        this.generateSchedulesService.generateSchedules(this.addScheduleForm.value).subscribe(data => {
          this.schedulesList = data.scheduleVOList;
          let i = 0;
          this.schedulesList.forEach(schedule => {
            this.scheduleForm[i++] = this.createScheduleFormGroup(schedule);
            if (this.indicatorSelected.indicatorConfigurationVO.intermediateTarget) {
              var abstractControl: AbstractControl = this.scheduleForm[i - 1];
              if (abstractControl instanceof FormGroup) {
                (<FormGroup>abstractControl).addControl('targetValue', new FormControl(''));
                if (this.indicatorSelected.indicatorConfigurationVO.target == 'M') {
                  if (this.indicatorSelected.indicatorType.code == "QR") {//qualitative
                    this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.required]);
                  }
                  else if (this.indicatorSelected.indicatorType.code == "QT")//textual
                  {
                    this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.required, this.customValidationsService.noWhitespaceValidator]);
                    if (schedule.targetValue) {
                      this.scheduleForm[i - 1].controls['targetValue'].setValue(schedule.targetValue);
                    }
                  }
                  else //quantitative case
                  {
                    this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.required, this.customValidationsService.noWhitespaceValidator, Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)]);
                    if (schedule.targetValue) {
                      this.scheduleForm[i - 1].controls['targetValue'].setValue(schedule.targetValue);
                    }
                  }
                }
                else {
                  if (this.indicatorSelected.indicatorType.code == "QU")
                    this.scheduleForm[i - 1].controls['targetValue'].setValidators([Validators.pattern(/^-?([0-9]\d*(\.\d+)?)?$/)]);
                }
              }
            }
          })
        })
      }
    }

  }
  createScheduleFormGroup(schedule) {
    return this.formBuilder.group({
      scheduleStartDate: [schedule.scheduleStartDate],
      scheduleEndDate: [schedule.scheduleEndDate],

    })
  }
  copyFromCompany() {

    let addCustomIndicatorClick = 'I';
    this.createCompanyFrameworkConfiguration(true, 'D', addCustomIndicatorClick);

  }

  setCustomIndicator() {

    let addCustomIndicatorClick = 'C';
    this.createCompanyFrameworkConfiguration(true, 'D', addCustomIndicatorClick);


  }

  saveIndicatorSchedule() {
    let invalid = false;
    let i = 0;
    if (!this.indicatorSelected.indicatorConfigurationVO.intermediateTarget) {
      if (!this.finalTargetFormGroup.valid) {
        invalid = true;
        this.finalTargetFormGroup.get('finalTarget').markAsTouched();
      }
      this.indicatorSelected.indicatorConfigurationVO.finalTarget = this.finalTargetFormGroup.value.finalTarget;
    }
    if (this.indicatorSelected.indicatorConfigurationVO.intermediateTarget) {
      this.schedulesList.forEach(schedule => {
        schedule.targetValue = this.scheduleForm[i++].value.targetValue;
        if (!this.scheduleForm[i - 1].valid) {
          invalid = true;
          this.scheduleForm[i - 1].get('targetValue').markAsTouched();
        }
      })
    }
    this.indicatorSelected.indicatorConfigurationVO.scheduleVOList = this.schedulesList;
    this.indicatorSelected.indicatorConfigurationVO.frequencyId = this.addScheduleForm.value.frequency.id;
    this.indicatorSelected.indicatorConfigurationVO.start = this.addScheduleForm.value.start;
    this.indicatorSelected.indicatorConfigurationVO.end = this.addScheduleForm.value.end;
    if (!invalid) {

      this.performanceFrameworkCompanyService.saveCompanyIndicatorSchedules(this.indicatorSelected.indicatorConfigurationVO).subscribe(data => {
        this.fetchFrameworkDetails();
        var message = "Successfully saved Indicator Targets against schedules";
        this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {
        });
      })
    }
  }
  deleteIndicator(indicator) {
    var confirmMessage = "You Want To Delete This Indicator From Framework";
    this.addEditSuccessComponent.showConfirmationSwal(confirmMessage, 'Yes', 'No').then((isConfirm) => {
      if (isConfirm.value) {
        this.paginator.pageIndex = 0;
        if (this.companyVO.performanceframeworkVO.frameworkId > 0 && indicator.frameworkIndicatorDetailId > 0) {
          this.performanceFrameworkCompanyService.deleteSeletedIndicator(indicator.frameworkIndicatorDetailId).subscribe(data => {
            const index = this.jsonArray.indexOf(this.jsonArray.find(x => x.id == indicator.id), 0);
            if (index > -1) {
              this.jsonArray.splice(index, 1);
              this.indicatorList = this.indicatorList.filter(x => x.id != indicator.id);
              this.generateDataTable();
            }
            this.isWebservice.splice(index, 1);

          });
        }
        else {
          const index = this.jsonArray.indexOf(this.jsonArray.find(x => x.id == indicator.id), 0);
          if (index > -1) {
            this.jsonArray.splice(index, 1);
            this.indicatorList = this.indicatorList.filter(x => x.id != indicator.id);
            this.generateDataTable();
          }
        }

      }
    });
    //} 
    this.toggle_class('otherSource');
  }

  compareFn(t1, t2): boolean {
    return t1 && t2 ? t1.id === t2.id : t1 === t2;
  }

  baselineConfig() {
    this.baselineFormGroup = this.createBaselineFormGroup();
    let yearList = [];
    this.currentYear = false;
    let currentYear = (new Date()).getFullYear();
    let startYear = currentYear - 10;
    for (let i = startYear; i <= currentYear; i++) {
      yearList.push(String(i));

    }
    yearList.push("CY");
    this.baselineYearList = yearList;
    this.setValueList();
    if (this.indicatorSelected.indicatorConfigurationVO.baselineFormula) {
      this.baselineFormGroup.controls['year'].setValue(this.indicatorSelected.indicatorConfigurationVO.baselineFormula.year);
      if (this.indicatorSelected.indicatorConfigurationVO.baselineFormula.formulaType == "C") {
        this.currentYear = true;
        this.baselineFormGroup.controls['data'].setValue(Number(this.indicatorSelected.indicatorConfigurationVO.baselineFormula.value));
      }
    }
    if (this.indicatorSelected.indicatorConfigurationVO.baseLine) {
      this.baselineValueFlag = true;
      this.baselineFormGroup.controls['baselineValue'].setValue(this.indicatorSelected.indicatorConfigurationVO.baseLine);
    }

  }

  setValueList() {
    for (let i = 1; i <= 10; i++) {
      this.valueList.push(i);
    }

  }
  yearChanged(event, flag) {
    this.selectFlag = true;
    if (flag == 0) {
      this.currentYear = false;
      this.baselineValueFlag = false;
      this.formulaTypeFlag = "D";
      if (event.value == "CY") {
        this.currentYear = true;
        this.formulaTypeFlag = "C";
      }
    }
  }
  baselineSelect(event) {
    if (event.target)
      event.target.classList.add("baseChildren");
  }
  createBaselineFormGroup() {
    return this.formBuilder.group({
      year: [],
      data: [],
      baselineValue: ['', [Validators.required]]
    })
  }
  saveBaselineConfiguration() {
    this.setBaslineValues();

    this.performanceFrameworkCompanyService.saveBaselineForIndicator(this.indicatorSelected.indicatorConfigurationVO).subscribe(data => {

      var message = "Successfully saved BaseLine";
      this.addEditSuccessComponent.showSuccessSwal(message).then((value) => {

      });
    })

  }
  setBaslineValues() {
    var baselineFormula = new Object();
    baselineFormula["formulaType"] = this.formulaTypeFlag;
    baselineFormula["year"] = this.baselineFormGroup.value.year.toString();
    if (this.formulaTypeFlag == "C") {
      baselineFormula["value"] = this.baselineFormGroup.value.data.toString();
      baselineFormula["operator"] = "-";

    }

    this.indicatorSelected.indicatorConfigurationVO.baseLine = this.baselineFormGroup.value.baselineValue;
    this.indicatorSelected.indicatorConfigurationVO.baselineFormula = baselineFormula;
  }
  getBaselineValue() {

    this.setBaslineValues();
    this.performanceFrameworkCompanyService.getBaselineValue(this.indicatorSelected).subscribe(data => {
      this.baselineValueFlag = true;
      if (data != null) {
        this.baselineFormGroup.controls['baselineValue'].setValue(data);
      }
      else {

        this.baselineFormGroup.controls['baselineValue'].setValue(null);
        this.basePlaceholder = "Baseline value not available";

      }
    })
  }
  changeErrorMsg() {
    this.basePlaceholder = "Baseline Value";
  }

}