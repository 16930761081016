import { Component, OnInit, Inject } from '@angular/core';
import { CompanyService } from '../../company.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import * as d3 from 'd3';
@Component({
  selector: 'app-sustainanalytic-rating-agency',
  templateUrl: './sustainanalytic-rating-agency.component.html',
  styleUrls: ['./sustainanalytic-rating-agency.component.css']
})
export class SustainanalyticRatingAgencyComponent implements OnInit {
  public doughnutData: any;
  public doughnutChartType = 'doughnut';
  public chartColors: any;
  public chartOptions: any;
  public overallESGChartColor: Array<any> = [];
  loading: boolean;
  companyRatingmappingDetails: any;
  jsonArray: any[];
  dataSource: any;
  displayedColumns: string[];
  industryRank: any;
  universalRank: any;
  esgScore: any;
  ratingAgencyName: any;
  companyId: any;
  ratingAgencyId: any;
  esgBorderFlag = 1;
  companyName: any;

  public gaugemap: any = {};
  public overallESGData: any = [];
  level1TotalCount: any;
  level2TotalCount: any;
  rankLevel1: any;
  rankLevel2: any;
  environmentESG: any;
  socialESG: any;
  governanceESG: any;
  rankLevelName1: any;
  rankLevelName2: any;
  environmentESGVal: any;
  socialESGVal: any;
  governanceESGVal: any;
  eSGChartColor: any;
  eSGChartColorENV: any;
  eSGChartColorSOC: any;
  eSGChartColorGOV: any;
  imageFileName: any;
  constructor(private companyService: CompanyService, private dialogRef: MatDialogRef<SustainanalyticRatingAgencyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.loading = false;
  }
  ngOnInit() {
    this.ratingAgencyName = this.data.ratingAgencyName;
    this.companyId = this.data.companyId;
    this.companyName = this.data.companyName;
    this.ratingAgencyId = this.data.ratingAgencyId;
    this.fetchCompanyRatingAgencingMappingDetails();
  }

  fetchCompanyRatingAgencingMappingDetails() {

    this.companyService.fetchCompanyComparisonDetails(this.companyId, this.ratingAgencyId).subscribe(data => {
      this.companyRatingmappingDetails = data.companyRatingAgencyDetails;
      this.imageFileName=data.ratingAgencyVO.imgFileName;
      console.log("this.companyRatingmappingDetails data")
      console.log(data)
      this.esgScore = this.companyRatingmappingDetails.esgScore;
      if (this.esgScore <= 10) {
        this.esgBorderFlag = 1;
      }
      if (this.esgScore > 10 && this.esgScore <= 20) {
        this.esgBorderFlag = 2;
      }
      if (this.esgScore > 20 && this.esgScore <= 30) {
        this.esgBorderFlag = 3;
      }
      if (this.esgScore > 30 && this.esgScore <= 40) {
        this.esgBorderFlag = 4;
      }
      if (this.esgScore > 40) {
        this.esgBorderFlag = 5;
      }
      this.jsonArray = [];
      var companyOne = {};
      companyOne['companyName'] = this.companyRatingmappingDetails.companyOneName;
      companyOne['esgScore'] = this.companyRatingmappingDetails.companyOneEsg;
      companyOne['industryRank'] = this.companyRatingmappingDetails.companyOneIndustryRank;
      let ratingImg = "zero.png";
      let ratingtype = "Zero";
      if (this.companyRatingmappingDetails.companyOneEsg <= 10) {
        ratingImg = "negl.png";
        ratingtype = "Negl";
      }
      if (this.companyRatingmappingDetails.companyOneEsg > 10 && this.companyRatingmappingDetails.companyOneEsg <= 20) {
        ratingImg = "low.png";
        ratingtype = "Low";
      }
      if (this.companyRatingmappingDetails.companyOneEsg > 20 && this.companyRatingmappingDetails.companyOneEsg <= 30) {
        ratingImg = "med.png";
        ratingtype = "Med";
      }
      if (this.companyRatingmappingDetails.companyOneEsg > 30 && this.companyRatingmappingDetails.companyOneEsg <= 40) {
        ratingImg = "high.png";
        ratingtype = "High";
      }
      if (this.companyRatingmappingDetails.companyOneEsg > 40) {
        ratingImg = "severe.png";
        ratingtype = "Severe";
      }
      companyOne['ratingImage'] = ratingImg;
      companyOne['ratingType'] = ratingtype;
      companyOne['totalRank'] = this.companyRatingmappingDetails.industryRankCount;
      this.jsonArray.push(companyOne);
      var companyTwo = {};
      companyTwo['companyName'] = this.companyRatingmappingDetails.companyTwoName;
      companyTwo['esgScore'] = this.companyRatingmappingDetails.companyTwoEsg;
      companyTwo['industryRank'] = this.companyRatingmappingDetails.companyTwoIndustryRank;
      ratingImg = "zero.png";
      ratingtype = "Zero";
      if (this.companyRatingmappingDetails.companyTwoEsg <= 10) {
        ratingImg = "negl.png";
        ratingtype = "Negl";
      }
      if (this.companyRatingmappingDetails.companyTwoEsg > 10 && this.companyRatingmappingDetails.companyTwoEsg <= 20) {
        ratingImg = "low.png";
        ratingtype = "Low";
      }
      if (this.companyRatingmappingDetails.companyTwoEsg > 20 && this.companyRatingmappingDetails.companyTwoEsg <= 30) {
        ratingImg = "med.png";
        ratingtype = "Med";

      }
      if (this.companyRatingmappingDetails.companyTwoEsg > 30 && this.companyRatingmappingDetails.companyTwoEsg <= 40) {
        ratingImg = "high.png";
        ratingtype = "High";
      }
      if (this.companyRatingmappingDetails.companyTwoEsg > 40) {
        ratingImg = "severe.png";
        ratingtype = "Severe";
      }
      companyTwo['ratingImage'] = ratingImg;
      companyTwo['ratingType'] = ratingtype;
      companyTwo['totalRank'] = this.companyRatingmappingDetails.industryRankCount;
      this.jsonArray.push(companyTwo);
      console.log(companyTwo);
      console.log(this.jsonArray);
      var companyThree = {};
      companyThree['companyName'] = this.companyRatingmappingDetails.companyThreeName;
      companyThree['esgScore'] = this.companyRatingmappingDetails.companyThreeEsg;
      companyThree['industryRank'] = this.companyRatingmappingDetails.companyThreeIndustryRank;
      ratingImg = "zero.png";
      ratingtype = "zero";
      if (this.companyRatingmappingDetails.companyThreeEsg <= 10) {
        ratingImg = "negl.png";
        ratingtype = "Negl.";
      }
      if (this.companyRatingmappingDetails.companyThreeEsg > 10 && this.companyRatingmappingDetails.companyThreeEsg <= 20) {
        ratingImg = "low.png";
        ratingtype = "Low";
      }
      if (this.companyRatingmappingDetails.companyThreeEsg > 20 && this.companyRatingmappingDetails.companyThreeEsg <= 30) {
        ratingImg = "med.png";
        ratingtype = "Med";
      }
      if (this.companyRatingmappingDetails.companyThreeEsg > 30 && this.companyRatingmappingDetails.companyThreeEsg <= 40) {
        ratingImg = "high.png";
        ratingtype = "High";
      }
      if (this.companyRatingmappingDetails.companyThreeEsg > 40) {
        ratingImg = "severe.png";
        ratingtype = "Severe"
      }
      companyThree['ratingImage'] = ratingImg;
      companyThree['ratingType'] = ratingtype;
      companyThree['totalRank'] = this.companyRatingmappingDetails.industryRankCount;
      this.jsonArray.push(companyThree);
      var companyfour = {};
      companyfour['companyName'] = this.companyRatingmappingDetails.companyFourName;
      companyfour['esgScore'] = this.companyRatingmappingDetails.companyFourEsg;
      companyfour['industryRank'] = this.companyRatingmappingDetails.companyFourIndustryRank;
      ratingImg = "zero.png";
      ratingtype = "Zero"
      if (this.companyRatingmappingDetails.companyFourEsg <= 10) {
        ratingImg = "negl.png";
        ratingtype = "Negl.";
      }
      if (this.companyRatingmappingDetails.companyFourEsg > 10 && this.companyRatingmappingDetails.companyFourEsg <= 20) {
        ratingImg = "low.png";
        ratingtype = "Low";
      }
      if (this.companyRatingmappingDetails.companyFourEsg > 20 && this.companyRatingmappingDetails.companyFourEsg <= 30) {
        ratingImg = "med.png";
        ratingtype = "Med";
      }
      if (this.companyRatingmappingDetails.companyFourEsg > 30 && this.companyRatingmappingDetails.companyFourEsg <= 40) {
        ratingImg = "high.png";
        ratingtype = "High";
      }
      if (this.companyRatingmappingDetails.companyFourEsg > 40) {
        ratingImg = "severe.png";
        ratingtype = "Severe";
      }
      companyfour['ratingImage'] = ratingImg;
      companyfour['ratingType'] = ratingtype;
      companyfour['totalRank'] = this.companyRatingmappingDetails.industryRankCount;
      this.jsonArray.push(companyfour);
      var companyfive = {};
      companyfive['companyName'] = this.companyRatingmappingDetails.companyFiveName;
      companyfive['esgScore'] = this.companyRatingmappingDetails.companyFiveEsg;
      companyfive['industryRank'] = this.companyRatingmappingDetails.companyFiveIndustryRank;
      ratingImg = "zero.png";
      ratingtype = "Zero";
      if (this.companyRatingmappingDetails.companyFiveEsg <= 10) {
        ratingImg = "negl.png";
        ratingtype = "Negl.";
      }
      if (this.companyRatingmappingDetails.companyFiveEsg > 10 && this.companyRatingmappingDetails.companyFiveEsg <= 20) {
        ratingImg = "low.png";
        ratingtype = "Low";
      }
      if (this.companyRatingmappingDetails.companyFiveEsg > 20 && this.companyRatingmappingDetails.companyFiveEsg <= 30) {
        ratingImg = "med.png";
        ratingtype = "Med";
      }
      if (this.companyRatingmappingDetails.companyFiveEsg > 30 && this.companyRatingmappingDetails.companyFiveEsg <= 40) {
        ratingImg = "high.png";
        ratingtype = "High";
      }
      if (this.companyRatingmappingDetails.companyFiveEsg > 40) {
        ratingImg = "severe.png";
        ratingtype = "Severe";
      }
      companyfive['ratingImage'] = ratingImg;
      companyfive['ratingType'] = ratingtype;
      companyfive['totalRank'] = this.companyRatingmappingDetails.industryRankCount;
      this.jsonArray.push(companyfive);
      this.displayedColumns = ['Company', 'EsgRiskRating', 'IndustryRank']
      this.dataSource = new MatTableDataSource(this.jsonArray);
    }, (error) => {
      console.log(error);

    });
  }
  closePopup(): void {
    this.dialogRef.close();
  }
}
