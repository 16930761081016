import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import swal from 'sweetalert2';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ComplianceDashboardService } from '../../compliance-dashboard.service';
import { AddEditSuccessComponent } from '../../../../commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
@Component({
  selector: 'app-indicator-compliance-dashboard',
  templateUrl: './indicator-compliance-dashboard.component.html',
  styleUrls: ['./indicator-compliance-dashboard.component.css']
})
export class IndicatorComplianceDashboardComponent implements OnInit {

  filterDetails: any = [];
  parameterNames: string;
  regionList: any = [];
  regionValList: any = [];
  regionName: any;
  regionId: any;
  sectorList: any = [];
  sectorValList: any = [];
  sectorName: any;
  sectorId: any;
  esgTypeList: any = [];
  esgs: any = [];
  yearList: any = [];
  isSubmit: boolean;
  toggleList: boolean;
  esgName: any;
  indicatorDashboardArray: any = [];
  regionHeading: any = [];
  sectorHeading: any = [];
  topHeadingCol: any;
  indComplianceData: any = [];
  indicatorDetails: any = [];
  indDefaultedArr: any = [];
  indicatorComplianceDetails: any = [];
  defaultedIndicatorsFilterForm: FormGroup;
  loading:boolean;
  firstColumn:string;
  secondColumn:string;
  thirdColumn:string;
  fourthColumn:string;
  pageNumber:number;
  noOfPages:number;
  displayedColumns:any;
  dataSource:any;
  regionFlag:string;
  selectedRegionSector=[];
  parameterDetailsList = [];
  emptyFlag:boolean;
  presentColumnCount:number;
  
  toggleSectorList : boolean;
  toggleEsgList : boolean;
  toggleYearList : boolean;
  selectReg = "Select All";
  selectSec = "Select All";
  selectPerformance = "Select All";
  selectPeriod="Overall";
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  constructor(private addEditSuccessComponent: AddEditSuccessComponent,private _complianceDashboardService: ComplianceDashboardService, private _router: Router, private formBuilder: FormBuilder) {
    
    this.isSubmit = false;
    this.toggleList = false;
    this.toggleSectorList = false;
    this.toggleEsgList = false;
    this.toggleYearList = false;
    this.loading = false;
    this.firstColumn = null;
    this.secondColumn = null;
    this.thirdColumn = null;
    this.fourthColumn = null;

    this.defaultedIndicatorsFilterForm = this.formBuilder.group({
      regionIds: [],
      sectorIds: [],
      yearIds: ['', Validators.required],
      esgs: ['', Validators.required],
      regionFlag: ['true', Validators.required]
    });
  }

  ngOnInit() {
    this.getComplianceVODetails();
    this.emptyFlag = true;
  }

  reset(){
    this.emptyFlag = true;
    this.defaultedIndicatorsFilterForm.reset();
  }

  resetListView(){
    this.emptyFlag = true;
    this.isSubmit = false;
  }

  onSubmit() {

    this.selectedRegionSector = [];
    this.parameterDetailsList = [];
    this.regionFlag = "true";
    let error = false;
    if(this.defaultedIndicatorsFilterForm.valid)
    {
      this.regionFlag = this.defaultedIndicatorsFilterForm.value.regionFlag;
        if(this.regionFlag === "true"){
          this.selectedRegionSector = this.defaultedIndicatorsFilterForm.value.regionIds;
          if(this.selectedRegionSector == null){
            // swal({
            //   title: 'Error!',
            //   text: 'No Region has been selected',
            //   type: 'error',
            //   confirmButtonText: 'Ok'
            // });
            let msg="No Region has been selected";
            let title="Error!"
            this.addEditSuccessComponent.showFailedSwal(msg,title);
            error = true;
          }
          else{
            if(this.selectedRegionSector.length > 0){
              for(let index = 0 ; index < this.selectedRegionSector.length; index++){
                if(this.regionList.find(e => e.id === this.selectedRegionSector[index])){
                  var paramObj = this.regionList.find(e => e.id === this.selectedRegionSector[index]);
                  this.parameterDetailsList.push(paramObj.name);
                }
              }
            }
            else{
              // swal({
              //   title: 'Error!',
              //   text: 'No Region has been selected',
              //   type: 'error',
              //   confirmButtonText: 'Ok'
              // });
              let msg="No Region has been selected";
              let title="Error!"
              this.addEditSuccessComponent.showFailedSwal(msg,title);
              error = true;
            }
          }
        }
        else{
          this.selectedRegionSector = this.defaultedIndicatorsFilterForm.value.sectorIds;
          if(this.selectedRegionSector == null){
            // swal({
            //   title: 'Error!',
            //   text: 'No Sector has been selected',
            //   type: 'error',
            //   confirmButtonText: 'Ok'
            // });
            let msg="No Sector has been selected";
            let title="Error!"
            this.addEditSuccessComponent.showFailedSwal(msg,title);
            error = true;
          }
          else{
            if(this.selectedRegionSector.length > 0){
              for(let index = 0 ; index < this.selectedRegionSector.length; index++){
                if(this.sectorList.find(e => e.id === this.selectedRegionSector[index])){
                  var paramObj = this.sectorList.find(e => e.id === this.selectedRegionSector[index]);
                  this.parameterDetailsList.push(paramObj.name);
                }
              }
            }
            else{
              // swal({
              //   title: 'Error!',
              //   text: 'No Sector has been selected',
              //   type: 'error',
              //   confirmButtonText: 'Ok'
              // });
              let msg="No Sector has been selected";
              let title="Error!"
              this.addEditSuccessComponent.showFailedSwal(msg,title);
              error = true;
            }
          }
        }
        if(!error){
          this.noOfPages = Math.ceil(this.selectedRegionSector.length / 4);
          this.loading = true;
          let jsonData = {};
          if (this.defaultedIndicatorsFilterForm.value.regionIds == null) {
            jsonData['regionIds'] = [];
          }
          else{
            jsonData['regionIds'] = this.defaultedIndicatorsFilterForm.value.regionIds;
          }
          if (this.defaultedIndicatorsFilterForm.value.sectorIds == null) {
            jsonData['sectorIds'] = [];
          }
          else{
            jsonData['sectorIds'] = this.defaultedIndicatorsFilterForm.value.sectorIds;
          }
          if (this.defaultedIndicatorsFilterForm.value.esgs == null) {
            jsonData['esgs'] = [];
          }
          else{
            jsonData['esgs'] = this.defaultedIndicatorsFilterForm.value.esgs;
          }
          jsonData['regionFlag'] = this.defaultedIndicatorsFilterForm.value.regionFlag;
          jsonData['yearIds'] = this.defaultedIndicatorsFilterForm.value.yearIds;
          this._complianceDashboardService.submitIndicatorComplianceDashboard(jsonData).subscribe((data) => {
                this.isSubmit = true;    
                this.indicatorDashboardArray = data;
                this.getDataTablePopulation(1);
                this.loading = false;
          });
        }
    }
  }

  getDataTablePopulation(pageNo:number) {

    let parameterDataList = [];
    this.pageNumber = pageNo;

    let startIndex = 4 * (pageNo - 1);
    let endIndex;

    if(pageNo == this.noOfPages){
      endIndex = this.parameterDetailsList.length % 4;
      if(endIndex == 0)
        endIndex = 4;
    }
    else{
      endIndex = 4;
    }

    for(let i = startIndex ; i < (startIndex + endIndex); i++){
      parameterDataList.push(this.parameterDetailsList[i])
    }
    this.displayedColumns=[];
    let dashboardArray = [];  
    this.emptyFlag = true;
    for( let indicatorComplianceData of this.indicatorDashboardArray){
      var paFlag=false;    
      for(let fetchIndicatorData of indicatorComplianceData.indicatorList ){

          var newArray = {};
          this.emptyFlag = false;
          newArray['performanceAreaFlag'] = false;  
          if(paFlag == false) {
            newArray['performanceAreaFlag'] = true; 
            paFlag=true; 
          }
          newArray['performanceArea'] = indicatorComplianceData.esgTypeName;
          newArray['id'] = fetchIndicatorData.id;
          newArray['indicatorName'] = fetchIndicatorData.indicatorName;
          newArray['percentageIndDefaulted'] = this.parameterDetailsList;
          for(let i = 0 ; i < 4 ; i ++ ){
            newArray[parameterDataList[i]] = fetchIndicatorData.percentageIndDefaulted[parameterDataList[i]];
          }
          dashboardArray.push(newArray);
        }
    }

    if(parameterDataList.length == 1){
      this.presentColumnCount=1;
      this.firstColumn = parameterDataList[0];
      this.displayedColumns=['PerformanceArea','Indicator','firstColumn'];
    }
    else if(parameterDataList.length == 2){
      this.presentColumnCount=2;
      this.firstColumn = parameterDataList[0];
      this.secondColumn = parameterDataList[1];
      this.displayedColumns=['PerformanceArea','Indicator','firstColumn','secondColumn'];
    }
    else if(parameterDataList.length == 3){
      this.presentColumnCount=3;
      this.firstColumn = parameterDataList[0];
      this.secondColumn = parameterDataList[1];
      this.thirdColumn = parameterDataList[2];
      this.displayedColumns=['PerformanceArea','Indicator','firstColumn','secondColumn','thirdColumn'];
    }
    else if(parameterDataList.length >= 4){
      this.presentColumnCount=4;
      this.firstColumn = parameterDataList[0];
      this.secondColumn = parameterDataList[1];
      this.thirdColumn = parameterDataList[2];
      this.fourthColumn = parameterDataList[3];
      this.displayedColumns=['PerformanceArea','Indicator','firstColumn','secondColumn','thirdColumn','fourthColumn'];
    }

    this.dataSource = new MatTableDataSource(dashboardArray);
    this.dataSource.paginator = this.paginator;
  }

  getComplianceVODetails(): any {

    this._complianceDashboardService.getComplianceVODetails().subscribe(data => {
      this.filterDetails = data;
      this.regionList = this.filterDetails.regionList;
      this.sectorList = this.filterDetails.sectorList;
      this.yearList = this.filterDetails.yearList;
      
      let esgArray = [];
      for (let i = 0; i < this.filterDetails.esgTypeList.length; i++) {
        if (this.filterDetails.esgTypeList[i] === 1) {
          esgArray.push({
            "id": this.filterDetails.esgTypeList[i],
            "value": "Environmental"
          })

        }
        if (this.filterDetails.esgTypeList[i] === 2) {
          esgArray.push({
            "id": this.filterDetails.esgTypeList[i],
            "value": "Social"
          })

        }
        if (this.filterDetails.esgTypeList[i] === 3) {
          esgArray.push({
            "id": this.filterDetails.esgTypeList[i],
            "value": "Governance"
          })

        }

      }
      this.esgTypeList = esgArray;
    });
    
  }

  getIndDetails(indicatorList) {

    let headingCheckForPA = 0;
    for (let k = 0; k < indicatorList.length; k++) {
      if (headingCheckForPA == 0) {
        indicatorList[k]['paHeading'] = true;
        headingCheckForPA = 1;
      }
      else
        indicatorList[k]['paHeading'] = false;

      let percent = indicatorList[k].percentageIndDefaulted;
      let percentArray = [];
      if (percent != undefined && Object.keys(percent).length > 0) {
        Object.keys(percent).map(function (key) {
          percentArray.push({ "key": key, "value": percent[key] });
        })
      }
      indicatorList[k].percentageIndDefaulted = percentArray;
    }
    headingCheckForPA = 0;
    return indicatorList;
  }

  selectAllRegions() {
    this.toggleList = !this.toggleList;
    let regionArray = [];
    if (this.toggleList) {
      for (let i of this.regionList) {
        regionArray.push(i.id);
      }
      this.selectReg="Deselect All";
    }
    else{
      this.selectReg="Select All";
    }
    this.defaultedIndicatorsFilterForm.get("regionIds").patchValue(regionArray);
  }

  selectAllSectors() {
    this.toggleSectorList = !this.toggleSectorList;
    let sectorArray = [];
    if (this.toggleSectorList) {
      for (let i of this.sectorList) {
        sectorArray.push(i.id);
      }
      this.selectSec="Deselect All";
    }
    else{
      this.selectSec="Select All";
    }
    this.defaultedIndicatorsFilterForm.get("sectorIds").patchValue(sectorArray);
  }

  selectAllYears() {
    this.toggleYearList = !this.toggleYearList;
    let yearArray = [];
    if (this.toggleYearList) {
      for (let i of this.yearList) {
        yearArray.push(i);
      }
      this.selectPeriod="Deselect All";
    }
    else{
      this.selectPeriod="Select All";
    }
    this.defaultedIndicatorsFilterForm.get("yearIds").patchValue(yearArray);
  }

  selectAllEsgs() {
    this.toggleEsgList = !this.toggleEsgList;
    let esgArray = [];
    if (this.toggleEsgList) {
      for (let i of this.esgTypeList) {
        esgArray.push(i.id);
      }
      this.selectPerformance="Deselect All";
    }
    else{
      this.selectPerformance="Select All";
    }
    this.defaultedIndicatorsFilterForm.get("esgs").patchValue(esgArray);
  }

  indicatorDrillDownFunction(indId, indName, paramNameArray) {
    this.indicatorComplianceDetails = {};
    this.indicatorComplianceDetails['indicatorId'] = indId;
    this.indicatorComplianceDetails['indicatorName'] = indName;
    this.indicatorComplianceDetails['regionIds'] = this.defaultedIndicatorsFilterForm.value.regionIds;
    this.indicatorComplianceDetails['sectorIds'] = this.defaultedIndicatorsFilterForm.value.sectorIds;
    this.indicatorComplianceDetails['regionFlag'] = this.defaultedIndicatorsFilterForm.value.regionFlag;
    this.indicatorComplianceDetails['yearIds'] = this.defaultedIndicatorsFilterForm.value.yearIds;
    this.indicatorComplianceDetails['frequencyView'] = "QUARTERLY";
    this.parameterNames = '';
    for (let param = 0; param < paramNameArray.length; param++) {
      this.parameterNames = this.parameterNames + ',' + paramNameArray[param];
    }
    this.indicatorComplianceDetails['parameterName'] = this.parameterNames.substr(1, this.parameterNames.length);
    this._router.navigate(['dashboard/compliance/indicatorComplianceDrilldown'], { queryParams: this.indicatorComplianceDetails , skipLocationChange: true});
  }


}
