import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gamified-dashboard',
  templateUrl: './gamified-dashboard.component.html',
  styleUrls: ['./gamified-dashboard.component.css']
})
export class GamifiedDashboardComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
  }
  navigateToDrillDown()
  {

     this.router.navigate(['/dashboard/gamified-drilldown'])
  }
}
