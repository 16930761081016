import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http'
import { RouterModule, Routes } from '@angular/router';
import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
/* Add Amplify imports */
//import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
//import Amplify from 'aws-amplify';
//import Amplify, { Auth } from 'aws-amplify'
//import awsconfig from './aws-exports';


import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import {
  ReactiveFormsModule,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/sg';
registerLocaleData(localeNl, 'sg');
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './/app-routing.module';
import { HeaderComponent } from './shared/layout/header/header.component';
import { FooterComponent } from './shared/layout/footer/footer.component';
import { ParametersComponent } from './modules/report/ParameterDashboard/components/parameters/parameters.component';
import { ParametersService } from './modules/report/ParameterDashboard/parameters.service';
import { SubParametersComponent } from './modules/report/ParameterDashboard/components/subparameters/subparameters.component';
import { ParameterlevelComponent } from './modules/report/ParameterDashboard/components/parameterlevel/parameterlevel.component';
import { TooltipModule } from 'ngx-bootstrap';
import { IndicatorscheduleComponent } from './modules/report/ParameterDashboard/components/indicatorschedule/indicatorschedule.component';
import { IndicatordetailsService } from './modules/report/ParameterDashboard/indicatordetails.service';
import { IndicatordetailsComponent } from './modules/report/ParameterDashboard/components/indicatordetails/indicatordetails.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  MatCardModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatPaginatorModule,
  MatTableModule,
  MatSelectModule,
  MatSortModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTreeModule,
  MatFormFieldModule,
} from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SidebarComponent } from './shared/layout/sidebar/sidebar.component';
import { BaseUrlService } from './shared/base-url.service';
import { ChartsModule } from 'ng2-charts';
import { MatRadioModule } from '@angular/material/radio';
import { CompanylevelComponent } from './modules/report/ParameterDashboard/components/companylevel/companylevel.component';
import { CompanydetailsService } from './modules/report/ParameterDashboard/comapnydetails.service';
import { CompanydashboardComponent } from './modules/report/CompanyDashboard/components/companydashboard/companydashboard.component';
import { CompanyService } from './modules/report/CompanyDashboard/company.service';
import { CompanyListDetailsComponent } from './modules/report/CompanyDashboard/components/company-list-details/company-list-details.component';
import { PeerCompanyService } from './modules/report/peer-company-dashboard/peer-company.service';
import { PeergrouplistComponent } from './modules/report/peer-company-dashboard/components/peergrouplist/peergrouplist.component';
import { PeerCompanyDashboardComponent } from './modules/report/peer-company-dashboard/components/peerdashboard/peer-company-dashboard.component';
import { CompanyComplianceDashboardComponent } from './modules/report/ComplianceDashboard/components/company-compliance-dashboard/company-compliance-dashboard.component';
import { CompanyComplianceDrilldownComponent } from './modules/report/ComplianceDashboard/components/company-compliance-drilldown/company-compliance-drilldown.component';
import { IndicatorComplianceDashboardComponent } from './modules/report/ComplianceDashboard/components/indicator-compliance-dashboard/indicator-compliance-dashboard.component';
import { IndicatorComplianceDrilldownComponent } from './modules/report/ComplianceDashboard/components/indicator-compliance-drilldown/indicator-compliance-drilldown.component';
import { CompanyDetailsComponent } from './modules/report/CompanyDashboard/components/company-details/company-details.component';
import { ComplianceDashboardService } from './modules/report/ComplianceDashboard/compliance-dashboard.service';
import { CompanyComplainceDashboardViewComponent } from './modules/report/ComplianceDashboard/components/company-complaince-dashboard-view/company-complaince-dashboard-view.component';
import { CompanyComplianceMonthlyDrilldownComponent } from './modules/report/ComplianceDashboard/components/company-compliance-monthly-drilldown/company-compliance-monthly-drilldown.component';
import { IndicatorComplianceMonthlyDrilldownComponent } from './modules/report/ComplianceDashboard/components/indicator-compliance-monthly-drilldown/indicator-compliance-monthly-drilldown.component';
import { HttpErrorService } from './shared/Exception/http-error.interceptor';
import { AddFactorComponent } from './modules/assessment/Factor/components/add-factor/add-factor.component';
import { ListFactorComponent } from './modules/assessment/Factor/components/list-factor/list-factor.component';
import { AddSubFactorComponent } from './modules/assessment/SubFactor/components/add-sub-factor/add-sub-factor.component';
import { ListSubFactorComponent } from './modules/assessment/SubFactor/components/list-sub-factor/list-sub-factor.component';
import { CapitalViewComponent } from './modules/report/ImpactDashboard/components/CapitalView/capital-view/capital-view.component';
import { CapitalViewDrillDownComponent } from './modules/report/ImpactDashboard/components/CapitalView/capital-view-drill-down/capital-view-drill-down.component';
import { SdgViewComponent } from './modules/report/ImpactDashboard/components/SDGView/sdg-view/sdg-view.component';
import { SdgViewDrillDownComponent } from './modules/report/ImpactDashboard/components/SDGView/sdg-view-drill-down/sdg-view-drill-down.component';
import { AddQuestionComponent } from './modules/assessment/Question/components/add-question/add-question.component';
import { ListQuestionComponent } from './modules/assessment/Question/components/list-question/list-question.component';
import { AddPanelComponent } from './modules/assessment/panel/components/add-panel/add-panel.component';
import { ListPanelComponent } from './modules/assessment/panel/components/list-panel/list-panel.component';
import { AddResponseOptionsComponent } from './modules/assessment/Response/components/add-response-options/add-response-options.component';
import { ListResponseOptionsComponent } from './modules/assessment/Response/components/list-response-options/list-response-options.component';
import { ShowErrorsComponent } from './shared/Validations/show-errors/show-errors.component';
import { ListAssessmentComponent } from './modules/assessment/AssessmentRecord/components/list-assessment-by-user/list-assessment.component'
import { InitiateAssessmentComponent } from './modules/assessment/AssessmentRecord/components/initiate-assessment/initiate-assessment.component';
import { CommonMatTableComponent } from './modules/commonFunctions/MatTableCommon/common-mat-table/common-mat-table.component';
import { AddAssessmentTemplateComponent } from './modules/assessment/AssessmentTemplate/components/add-assessment-template/add-assessment-template.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ListAssessmentCompaniesComponent } from './modules/assessment/AssessmentRecord/components/list-assessment-companies/list-assessment-companies.component';
import { LoadRecordPageComponent } from './modules/assessment/AssessmentRecord/components/load-record-page/load-record-page.component';
import { SubfactorUserMappingComponent } from './modules/assessment/SubFactor/components/subfactor-user-mapping/subfactor-user-mapping.component';
import { ListSubfactorUserMappingComponent } from './modules/assessment/SubFactor/components/list-subfactor-user-mapping/list-subfactor-user-mapping.component';
import { ReportConstantsComponent } from './modules/report/report-constants/report-constants.component';
import { ListAssessmentTemplateQuestionsComponent } from './modules/assessment/AssessmentTemplate/components/list-assessment-template-questions/list-assessment-template-questions.component';
import { ListTemplateComponent } from './modules/assessment/AssessmentTemplate/components/list-template/list-template.component';
import { TemplateSettingsComponent } from './modules/assessment/AssessmentTemplate/components/template-settings/template-settings.component';
import { DecimalFormatterPipePipe } from './modules/report/decimal-formatter-pipe.pipe';
import { SearchQuestionsComponent } from './modules/assessment/AssessmentTemplate/components/search-questions/search-questions.component';
import { ImpactAssessmentService } from './modules/report/ImpactDashboard/impact-assessment.service';
import { ParameterLevelDrillDownComponent } from './modules/report/ImpactDashboard/components/ImpactLevelDrillDown/parameter-level-drill-down/parameter-level-drill-down.component';
import { CompanyLevelDrillDownComponent } from './modules/report/ImpactDashboard/components/ImpactLevelDrillDown/company-level-drill-down/company-level-drill-down.component';
import { AddMoreQuestionsComponent } from './modules/assessment/AssessmentTemplate/components/add-more-questions/add-more-questions.component';
import { EditAssessmentTemplateQuestionsComponent } from './modules/assessment/AssessmentTemplate/components/edit-assessment-template-questions/edit-assessment-template-questions.component';
import { ViewRecordPageComponent } from './modules/assessment/AssessmentRecord/components/view-record-page/view-record-page.component';
import { AssessmentConsolidatedViewComponent } from './modules/assessment/AssessmentRecord/components/assessment-consolidated-view/assessment-consolidated-view.component';
import { CustomValidationsService } from './shared/Validations/custom-validations.service';
import { ListAssessmentsComponent } from './modules/assessment/AssessmentRecord/components/list-assessments/list-assessments.component';
import { WorldMapComponent } from './modules/report/world-map/world-map.component';
import { ListConstantsComponent } from './modules/indicator-master/constants/list-constants/list-constants.component';
import { AddConstantsComponent } from './modules/indicator-master/constants/add-constants/add-constants.component';
import { AddSdgComponent } from './modules/indicator-master/sdg/add-sdg/add-sdg.component';
import { ListSdgComponent } from './modules/indicator-master/sdg/list-sdg/list-sdg.component';
import { ListUnitGroupComponent } from './modules/indicator-master/unit-group/list-unit-group/list-unit-group.component';
import { AddUnitGroupComponent } from './modules/indicator-master/unit-group/add-unit-group/add-unit-group.component';
import { ListUnitOfMeasurementComponent } from './modules/indicator-master/unit-of-measurement/list-unit-of-measurement/list-unit-of-measurement.component';
import { AddUnitOfMeasurementComponent } from './modules/indicator-master/unit-of-measurement/add-unit-of-measurement/add-unit-of-measurement.component';
import { ListSubcategoryComponent } from './modules/indicator-master/subcategory/list-subcategory/list-subcategory.component';
import { AddSubcategoryComponent } from './modules/indicator-master/subcategory/add-subcategory/add-subcategory.component';
import { ListIndicatorStackComponent } from './modules/indicator-master/indicator-stack/list-indicator-stack/list-indicator-stack.component';
import { AddIndicatorStackComponent } from './modules/indicator-master/indicator-stack/add-indicator-stack/add-indicator-stack.component';
import { ListRatingGroupComponent } from './modules/indicator-master/rating-group/list-rating-group/list-rating-group.component';
import { AddRatingGroupComponent } from './modules/indicator-master/rating-group/add-rating-group/add-rating-group.component';
import { ListIndicatorComponent } from './modules/indicator/list-indicator/list-indicator.component';
import { AddIndicatorComponent } from './modules/indicator/add-indicator/add-indicator.component';
import { SearchIndicatorComponent } from './modules/indicator/search-indicator/search-indicator.component';
import { ListDatapointComponent } from './modules/indicator-master/datapoint/list-datapoint/list-datapoint.component';
import { AddDatapointComponent } from './modules/indicator-master/datapoint/add-datapoint/add-datapoint.component';
import { FrameworkConfigurationComponent } from './modules/configuration/framework-configuration/framework-configuration.component';
import { ListCompanyTypeComponent } from './modules/configuration/company-type/list-company-type/list-company-type.component';
import { AddCompanyTypeComponent } from './modules/configuration/company-type/add-company-type/add-company-type.component';
import { AddCompanyComponent } from './modules/configuration/company/add-company/add-company.component';
import { ListCompanyComponent } from './modules/configuration/company/list-company/list-company.component';
import { AddPerformanceTemplateComponent } from './modules/performance-template/add-performance-template/add-performance-template.component';
import { ListPerformanceTemplateComponent } from './modules/performance-template/list-performance-template/list-performance-template.component';
import { SearchPerformanceTemplateComponent } from './modules/performance-template/search-performance-template/search-performance-template.component';
import { AddPerformanceTemplateConfComponent } from './modules/performance-template/add-performance-template-conf/add-performance-template-conf.component';
import { IndicatorDatapointMappingComponent } from './modules/indicator-master/datapoint/indicator-datapoint-mapping/indicator-datapoint-mapping.component';
import { ListIndicatorDatapointMappingComponent } from './modules/indicator-master/datapoint/list-indicator-datapoint-mapping/list-indicator-datapoint-mapping.component';
import { PerformanceTemplateService } from './modules//performance-template/performance-template.service';
import { IndicatorService } from './modules/indicator/indicator.service';
import { IndicatorMasterService } from './modules/indicator-master/indicator-master.service';
import { SearchIndicatorCommonComponent } from './modules/search-indicator-common/search-indicator-common.component';
import { ListCompanyFrameworkComponent } from './modules/performance-framework/companyLevel/list-company-framework/list-company-framework.component';
import { ListParameterDataFrameworkComponent } from './modules/performance-framework/higherLevel/list-parameter-data-framework/list-parameter-data-framework.component';
import { ViewPerformanceFrameworkComponent } from './modules/performance-framework/higherLevel/view-performance-framework/view-performance-framework.component';
import { ViewArchivesComponent } from './modules/performance-framework/higherLevel/view-archives/view-archives.component';
import { AddFrameworkIndicatorsComponent } from './modules/performance-framework/higherLevel/add-framework-indicators/add-framework-indicators.component';
import { AddEditSuccessComponent } from './modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';
import { AddCompanyIndicatorConfigurationsComponent } from './modules/performance-framework/companyLevel/add-company-indicator-configurations/add-company-indicator-configurations.component';
import { LoadPerformanceRecordIndicatorListComponent } from './modules/performance-record/load-performance-record-indicator-list/load-performance-record-indicator-list.component';
import { ViewCompanyFrameworkComponent } from './modules/performance-framework/companyLevel/view-company-framework/view-company-framework.component';
import { ListCompanyForCopyComponent } from './modules/performance-framework/companyLevel/list-company-for-copy/list-company-for-copy.component';
import { PerformanceRecordService } from './modules/performance-record/performance-record.service';
import { PerformanceRecordConfigurationComponent } from './modules/performance-record/performance-record-configuration/performance-record-configuration.component'
import { ViewCompanyFrameworkArchiveComponent } from './modules/performance-framework/companyLevel/view-company-framework-archive/view-company-framework-archive.component';
import { ListActivePerformanceFrameworkTemplatesComponent } from './modules/performance-framework/higherLevel/list-active-performance-framework-templates/list-active-performance-framework-templates.component';
import { DocumentPopupComponent } from './modules/performance-record/document-popup/document-popup.component'
import { GpsDashbordComponent } from './modules/report/GpsDashbord/component/gps-dashbord/gps-dashbord.component';
import { CountryPopupComponent } from './modules/report/GpsDashbord/component/country-popup/country-popup.component';
import { GpsDashboardService } from './modules/report/GpsDashbord/gps-dashboard.service';
import 'hammerjs';
import { NgxGalleryModule } from 'ngx-gallery';
import { ListFundComponent } from './modules/fund/list-fund/list-fund.component';
import { AddFundComponent } from './modules/fund/add-fund/add-fund.component';
import { FundViewComponent } from './modules/report/FundDashboard/components/fund-view/fund-view.component';
import { FundViewDrilldownComponent } from './modules/report/FundDashboard/components/fund-view-drilldown/fund-view-drilldown.component';
import { InvestorViewComponent } from './modules/report/InvestorDashboard/components/investor-view/investor-view.component';
import { FundDashboardService } from './modules/report/FundDashboard/fund-dashboard.service';
import { AssessmentQuestionTemplateComponent } from './modules/assessment/AssessmentTemplate/components/assessment-question-template/assessment-question-template.component';
import { AddCompanyInfoComponent } from './modules/configuration/company/add-company-info/add-company-info.component';
import { AddParityHomogeneityLevelsComponent } from './modules/data-quality-master/parity-homogeneity-levels/add-parity-homogeneity-levels/add-parity-homogeneity-levels.component';
import { ListParityHomogeneityLevelsComponent } from './modules/data-quality-master/parity-homogeneity-levels/list-parity-homogeneity-levels/list-parity-homogeneity-levels.component';
import { AddDatasourceQualityLevelsComponent } from './modules/data-quality-master/add-datasource-quality-levels/add-datasource-quality-levels.component';
import { ListDatasourceQualityLevelsComponent } from './modules/data-quality-master/list-datasource-quality-levels/list-datasource-quality-levels.component';
import { AddDatasourceComponent } from './modules/data-quality-master/datasource/add-datasource/add-datasource.component';
import { ListDatasourceComponent } from './modules/data-quality-master/datasource/list-datasource/list-datasource.component';
import { AddDatalevelScoresComponent } from './modules/data-quality-master/datalevel-scores/add-datalevel-scores/add-datalevel-scores.component';
import { IndicatorKeywordComponent } from './modules/indicator-master/indicator-keyword/indicator-keyword.component';
import { RelationshipDashboardComponent } from './modules/report/relationship-dashboard/relationship-dashboard.component';
import { LikeMindedBandProfileComponent } from './modules/report/like-minded-band-profile/like-minded-band-profile.component';
import { CompanyReportComponent } from './modules/report/company-report/company-report.component';
import { AddCategoryKeywordComponent } from './modules/categoryKeyword/add-category-keyword/add-category-keyword.component';
import { ListCategoryKeywordComponent } from './modules/categoryKeyword/list-category-keyword/list-category-keyword.component';
import { CategoryKeywordService } from './modules/categoryKeyword/category-keyword.service';
import { MediaViewComponent } from './modules/report/MediaDashboard/components/media-view/media-view.component';
import { MediaService } from './modules/report/MediaDashboard/components/media.service';
import { ScheduleWiseRecordComponent } from './modules/performance-record/schedule-wise-record/schedule-wise-record.component';
import { CompanyReportDetailsComponent } from './modules/configuration/company/company-report-details/company-report-details.component';
import { AddRatingAgencyComponent } from './modules/configuration/rating-agency/add-rating-agency/add-rating-agency.component';
import { ListRatingAgencyComponent } from './modules/configuration/rating-agency/list-rating-agency/list-rating-agency.component';
import { IntegratedDashboardComponent } from './modules/report/CompanyDashboard/components/integrated-dashboard/integrated-dashboard.component';
import { SrayRatingAgencyComponent } from './modules/report/CompanyDashboard/components/sray-rating-agency/sray-rating-agency.component';
import { SustainanalyticRatingAgencyComponent } from './modules/report/CompanyDashboard/components/sustainanalytic-rating-agency/sustainanalytic-rating-agency.component';
import { OwlanalyticsRatingAgencyComponent } from './modules/report/CompanyDashboard/components/owlanalytics-rating-agency/owlanalytics-rating-agency.component';
import { HomeComponent } from './modules/home/home.component';
import { InvestorDashboardComponent } from './modules/report/InvestmentDashboard/components/investor-dashboard/investor-dashboard.component';
import { BankerDashboardComponent } from './modules/report/banker-dashboard/components/banker-dashboard.component';
import { AsessmentDashboardComponent } from './modules/report/assessment-dashboard/components/asessment-dashboard.component';
import { GamifiedDashboardComponent } from './modules/report/gamified-dashboard/gamified-dashboard.component';
import { GamifiedDrilldownComponent } from './modules/report/gamified-drilldown/gamified-drilldown.component';
import { SubsidiaryCompanyComponent } from './modules/configuration/company/subsidiary-company/subsidiary-company.component';
import { AddPrimaryDatasourceComponent } from './modules/configuration/primary-datasource/add-primary-datasource/add-primary-datasource.component';
import { ViewPrimaryDatasourceComponent } from './modules/configuration/primary-datasource/view-primary-datasource/view-primary-datasource.component';
import { AddAlternateDatasourceComponent } from './modules/configuration/alternate datasource/add-alternate-datasource/add-alternate-datasource.component';
import { ViewAlternateDatasourceComponent } from './modules/configuration/alternate datasource/view-alternate-datasource/view-alternate-datasource.component';
import { ListCompanyViewComponent } from './modules/configuration/list-company-view/list-company-view.component';


import { ParagraphExtractService } from './modules/configuration/company/company-service/paragraph-extract.service';
import { PdfToTextService } from './modules/configuration/company/company-service/pdf-to-text.service';
import { TableExtractService } from './modules/configuration/company/company-service/table-extract.service';
import { LinkIndicatorComponent } from './modules/indicator/link-indicator/link-indicator.component';
import { AlternateDetailsComponent } from './modules/configuration/alternate datasource/alternate-details/alternate-details.component';
import { SkipLogicQuestionComponent } from './modules/assessment/AssessmentRecord/components/skip-logic-question/skip-logic-question.component';
import { ReportFrameworkStandardsComponent } from './modules/report/ImpactDashboard/components/ReportFramework/report-framework-standards/report-framework-standards.component'
/* Configure Amplify resources */
//Amplify.configure(awsconfig);
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HeaderComponent,
    FooterComponent,
    ParametersComponent,
    SubParametersComponent,
    ParameterlevelComponent,
    IndicatorscheduleComponent,
    IndicatordetailsComponent,
    SidebarComponent,
    CompanylevelComponent,
    CompanydashboardComponent,
    CompanyListDetailsComponent,
    PeergrouplistComponent,
    PeerCompanyDashboardComponent,
    CompanyComplianceDashboardComponent,
    IndicatorComplianceDashboardComponent,
    CompanyComplianceDrilldownComponent,
    IndicatorComplianceDrilldownComponent,
    CompanyDetailsComponent,
    CompanyComplainceDashboardViewComponent,
    CompanyComplianceMonthlyDrilldownComponent,
    IndicatorComplianceMonthlyDrilldownComponent,
    AddFactorComponent,
    ListFactorComponent,
    AddSubFactorComponent,
    ListSubFactorComponent,
    CapitalViewComponent,
    CapitalViewDrillDownComponent,
    SdgViewComponent,
    SdgViewDrillDownComponent,
    AddQuestionComponent,
    ListQuestionComponent,
    AddResponseOptionsComponent,
    ListResponseOptionsComponent,
    AddPanelComponent,
    ListPanelComponent,
    ShowErrorsComponent,
    ListAssessmentComponent,
    InitiateAssessmentComponent,
    CommonMatTableComponent,
    AddAssessmentTemplateComponent,
    ListAssessmentCompaniesComponent,
    LoadRecordPageComponent,
    SubfactorUserMappingComponent,
    ListSubfactorUserMappingComponent,
    ReportConstantsComponent,
    ListAssessmentTemplateQuestionsComponent,
    ListTemplateComponent,
    DecimalFormatterPipePipe,
    TemplateSettingsComponent,
    TemplateSettingsComponent,
    SearchQuestionsComponent, ParameterLevelDrillDownComponent, CompanyLevelDrillDownComponent,
    AddMoreQuestionsComponent,
    EditAssessmentTemplateQuestionsComponent,
    ViewRecordPageComponent,
    AssessmentConsolidatedViewComponent,
    ListAssessmentsComponent,
    WorldMapComponent,
    ListConstantsComponent,
    AddConstantsComponent,
    AddSdgComponent,
    ListSdgComponent,
    ListUnitGroupComponent,
    AddUnitGroupComponent,
    ListUnitOfMeasurementComponent,
    AddUnitOfMeasurementComponent,
    ListSubcategoryComponent,
    AddSubcategoryComponent,
    ListIndicatorStackComponent,
    AddIndicatorStackComponent,
    ListRatingGroupComponent,
    AddRatingGroupComponent,
    ListIndicatorComponent,
    AddIndicatorComponent,
    SearchIndicatorComponent,
    ListDatapointComponent,
    AddDatapointComponent,
    FrameworkConfigurationComponent,
    ListCompanyTypeComponent,
    AddCompanyTypeComponent,
    AddCompanyComponent,
    ListCompanyComponent,
    AddPerformanceTemplateComponent,
    ListPerformanceTemplateComponent,
    SearchPerformanceTemplateComponent,
    AddPerformanceTemplateConfComponent,
    IndicatorDatapointMappingComponent,
    ListIndicatorDatapointMappingComponent,
    SearchIndicatorCommonComponent,
    ListCompanyFrameworkComponent,
    ListParameterDataFrameworkComponent,
    ViewPerformanceFrameworkComponent,
    ViewArchivesComponent,
    AddFrameworkIndicatorsComponent,
    AddEditSuccessComponent,
    AddCompanyIndicatorConfigurationsComponent,
    LoadPerformanceRecordIndicatorListComponent,
    ViewCompanyFrameworkComponent,
    ListCompanyForCopyComponent,
    PerformanceRecordConfigurationComponent,
    ViewCompanyFrameworkArchiveComponent,
    ListActivePerformanceFrameworkTemplatesComponent,
    DocumentPopupComponent,
    GpsDashbordComponent,
    CountryPopupComponent,

    AddFundComponent,
    ListFundComponent,
    FundViewComponent,
    FundViewDrilldownComponent,
    InvestorViewComponent,
    AssessmentQuestionTemplateComponent,
    AddCompanyInfoComponent,
    AddParityHomogeneityLevelsComponent,
    ListParityHomogeneityLevelsComponent,
    AddDatasourceQualityLevelsComponent,
    ListDatasourceQualityLevelsComponent,
    AddDatasourceComponent,
    ListDatasourceComponent,
    AddDatalevelScoresComponent,
    IndicatorKeywordComponent,
    RelationshipDashboardComponent,
    LikeMindedBandProfileComponent,
    CompanyReportComponent,
    AddCategoryKeywordComponent,
    ListCategoryKeywordComponent,
    MediaViewComponent,
     ScheduleWiseRecordComponent,
    CompanyReportDetailsComponent,
    AddRatingAgencyComponent,
    ListRatingAgencyComponent,
       IntegratedDashboardComponent,
    SrayRatingAgencyComponent,
    SustainanalyticRatingAgencyComponent,
    OwlanalyticsRatingAgencyComponent,
    HomeComponent,
    InvestorDashboardComponent,
    BankerDashboardComponent,
    AsessmentDashboardComponent,
    GamifiedDashboardComponent,
    GamifiedDrilldownComponent,
    SubsidiaryCompanyComponent,
    AddPrimaryDatasourceComponent,
    ViewPrimaryDatasourceComponent,
    AddAlternateDatasourceComponent,
    ViewAlternateDatasourceComponent,
    ListCompanyViewComponent,
    LinkIndicatorComponent,
    AlternateDetailsComponent,
   SkipLogicQuestionComponent,
   ReportFrameworkStandardsComponent
  ],
  imports: [
   // AmplifyUIAngularModule,
    NgxGalleryModule,
    BrowserModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatIconModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatRadioModule,
    ChartsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTreeModule,
    MatAutocompleteModule,
    DragDropModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      fullScreenBackdrop: true,
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff'
    })

  ],
  providers: [AppComponent,CategoryKeywordService,ParametersService, IndicatordetailsService, CompanydetailsService,
    CompanyService, PeerCompanyService, ComplianceDashboardService, ImpactAssessmentService, CustomValidationsService, BaseUrlService, PerformanceTemplateService,
    PerformanceRecordService, GpsDashboardService, FundDashboardService,MediaService,ParagraphExtractService,
    PdfToTextService,TableExtractService,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [InitiateAssessmentComponent, SearchQuestionsComponent, AddMoreQuestionsComponent,
    SearchIndicatorCommonComponent, DocumentPopupComponent, CountryPopupComponent,SustainanalyticRatingAgencyComponent,OwlanalyticsRatingAgencyComponent,SrayRatingAgencyComponent],

  exports: [
    SearchIndicatorCommonComponent
  ]
})
export class AppModule { }