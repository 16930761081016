import { Component, OnInit,OnChanges,EventEmitter, ViewChild, Output , Input } from '@angular/core';
import {MatPaginator} from '@angular/material';
import {MatSort, MatTableDataSource} from '@angular/material';
import swal from 'sweetalert2';
import {FormControl, FormBuilder, Validators  } from '@angular/forms';
import {FormGroup, ValidatorFn, AbstractControl} from '@angular/forms';
import { Router,ActivatedRoute} from '@angular/router';
import { ConfigurationService } from '../../configuration.service';
import { AddCompanyComponent } from '../add-company/add-company.component';
import { AddEditSuccessComponent } from '../../../../modules/commonFunctions/SwalCommon/add-edit-success/add-edit-success.component';

@Component({
  selector: 'app-list-company',
  templateUrl: './list-company.component.html',
  styleUrls: ['./list-company.component.css']
})

export class ListCompanyComponent implements OnInit {

  companies: any;
  companyList:any=[];
  displayedColumns : any;
  dataSource: any ;
  displayedColumnsforFiles : any;
  displayedColumnsforConsignor: any;
  dataSourceforFiles: any=[];
  dataSourceforConsignor: any=[];
  jsonArray:any;
  jsonArrayforFiles:any;
  jsonArrayforConsignor:any;
  top:any;
  originalColumnNames : any;
  companyId :any;
  companyCode :any;
  consignorCompanyData: any;
  sectorList:any;

  @ViewChild('companyForm') companyForm;
  
  
  @Input() set topWrapper(val) {
    this.top=val;
  }
  // @Input() set sectorsList(val) {
  //   console.log("sectorslist")
  //   console.log(val)
  //   this.sectorList=val;
  // }
  @Input() set companyDataList(val) {
    this.companyList = val;
    this.fetchDataTable(this.companyList);
   }
  constructor(private formBuilder: FormBuilder,private configurationService:ConfigurationService, private addEditSuccessComponent: AddEditSuccessComponent,
    private activatedRoute: ActivatedRoute,private addCompanyComponent: AddCompanyComponent,private router:Router) { 

      this.activatedRoute.params.subscribe(params => {
      this.companyId =params.companyId;
    })
  }
  @Output() company = new EventEmitter();

  paginator:any;
  paginatorPopup:any;
  @ViewChild('matSort') sort: MatSort;
  @ViewChild('MatPaginator') set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  setDataSourceAttributes() {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
  }

  @ViewChild('matSort1') sort1: MatSort;
  @ViewChild('MatPaginator1') set matPaginator1(mp: MatPaginator) {
    this.paginatorPopup = mp;
    this.setDataSourceAttributes1();
  }
  setDataSourceAttributes1() {
      this.dataSourceforFiles.paginator = this.paginatorPopup;
      this.dataSourceforFiles.sort = this.sort1;
  }
  setDataSourceAttributes2() {
    this.dataSourceforConsignor.paginator = this.paginatorPopup;
    this.dataSourceforConsignor.sort = this.sort1;
}
  
  ngOnInit() {

  }

  viewFileList(companyDetailsVOList,code){
     this.fetchDataTableforFiles(companyDetailsVOList,code)
  
  }
  viewConsignors(companyid){
    this.fetchDataTableforConsignor(companyid);

  }
  fetchDataTableforFiles(companyDetailsVOList,code) {
    
    this.displayedColumnsforFiles = [];
    this.jsonArrayforFiles = [];
    var count =0;

    for( let i of companyDetailsVOList) {
      var newArray = {};
       newArray['Sno'] = ++count;
       newArray['Code'] = code; 
       newArray['FileName'] = i.fileName;
       newArray['FileUploadName'] = i.fileUploadName;
       newArray['Year'] = i.year;
      this.jsonArrayforFiles.push(newArray);
    }
    this.displayedColumnsforFiles=['Sno','Year','FileName'];
     this.dataSourceforFiles  =[];
    this.dataSourceforFiles = new MatTableDataSource(this.jsonArrayforFiles);  
    }
    fetchDataTableforConsignor(companyid) {
          this.configurationService.getListOfConsignorCompanies(companyid).subscribe((data) => {
           this.consignorCompanyData=data;
           console.log("comapny consignorrr")
           console.log(companyid)
           console.log(this.consignorCompanyData)
           this.displayedColumnsforConsignor = [];
      this.jsonArrayforConsignor = [];
      var count =0;
       if(this.consignorCompanyData){
      for( let i of this.consignorCompanyData) {
        var newArray = {};
         newArray['Sno'] = ++count;
         newArray['Name'] = i[0]; 
         this.jsonArrayforConsignor.push(newArray);
      }
    
    console.log(this.jsonArrayforConsignor)
      this.displayedColumnsforConsignor=['Sno','Name'];
       this.dataSourceforConsignor  =[];
      this.dataSourceforConsignor = new MatTableDataSource(this.jsonArrayforConsignor);  
    }
        },(error)=>{
          console.log(error);
        });
      
      }
  
  createFormGroup() { 
    return this.formBuilder.group({
      year: ['', [Validators.required]]
    });
  }

  fetchDataTable(companies) {
    this.displayedColumns = [];
    let jsonArray = [];
    var count =0;
    console.log("companies Lits")
    console.log(companies)
    for( let i of companies) {
      var newArray = {};
      newArray['Sno'] = ++count;
      newArray['id'] = i.id;
      newArray['Code'] = i.companyCode;
      newArray['Name'] = i.companyName;
      newArray['StartDate'] = i.startDate;
      newArray['EndDate'] = i.endDate;
      newArray['companyUrlist']=i.companyUrlList;
      newArray['companyDetailsVOList']=i.companyDetailsVOList;
      newArray['CompanyTypeVO'] = i.companyTypeVO;
      newArray['CompanyTypeName'] = i.companyTypeVO.name;
      newArray['ResponsibleUserVO'] = i.responsibleUser;
      newArray['ResponsibleUser'] = i.responsibleUser.name;
      newArray['parentCompanyVO'] = i.parentCompanyVO;
      newArray['homogenityVO'] = i.homogenityVO;
      newArray['sectorId']=i.sectorId;
      newArray['subsectorId']=i.subsectorId;
      newArray['countryId']=i.countryId;
      newArray['regionId']=i.regionId;
      newArray['subsidiary']='-';
      newArray['lei']=i.lei;
      newArray['isin']=i.isin;
      newArray['primaryEmail']=i.primaryEmail;
      newArray['secondaryEmail']=i.secondaryEmail;
   
      newArray['sisterCompaniesVOList'] = i.sisterCompaniesVOList;
      jsonArray.push(newArray);
    }
    console.log(jsonArray)
    this.displayedColumns=['Sno','Code','Name','CompanyTypeName','ResponsibleUser','ShowFiles','subsidiary','Actions']
    this.originalColumnNames=['Sl.No','Code','Name','Company Type','Responsible User','ShowFiles','Subsidiary','Actions']
    this.dataSource=[];
    this.dataSource = new MatTableDataSource(jsonArray);
    
  
  
  }
  getSectorName(sectorId)
  {
    console.log(sectorId)
    if(sectorId==0)
    {
      return '';
    }
    else{
    for(let sector of this.sectorList)
    {
      if(sector.id==sectorId)
      {
        console.log(sector.parameterName)
        return sector.parameterName;
      }
    }
  }
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }  
  download(element){
    let fileName = element.Code+"/"+String(element.Year)+"/"+element.FileUploadName;
    window.location.href= this.configurationService.downloadFile() +'/download?filename='+encodeURIComponent( fileName)+'&actualName='+encodeURIComponent(element.FileName);
  }
  
  edit(companyObj) {
    console.log("company obj in edit is")
    console.log(companyObj)
    if(companyObj.companyDetailsVOList != null)
      this.company.emit({ id:companyObj.id, companyCode: companyObj.Code, companyName: companyObj.Name,companyTypeVO:companyObj.CompanyTypeVO,responsibleUser:companyObj.ResponsibleUserVO,companyUrlList:companyObj.companyUrlist,companyDetailsVOList:companyObj.companyDetailsVOList,parentCompanyVO:companyObj.parentCompanyVO,
        homogenityVO:companyObj.homogenityVO,
        sectorId:companyObj.sectorId,
        subsectorId:companyObj.subsectorId,
        regionId:companyObj.regionId,
        countryId:companyObj.countryId,
        lei:companyObj.lei,
        isin:companyObj.isin,
        primaryEmail:companyObj.primaryEmail,
        secondaryEmail:companyObj.secondaryEmail,
        sisterCompaniesVOList:companyObj.sisterCompaniesVOList}); 
    else
      this.company.emit({ id:companyObj.id, companyCode: companyObj.Code, companyName: companyObj.Name,companyTypeVO:companyObj.CompanyTypeVO,responsibleUser:companyObj.ResponsibleUserVO,year:'',fileName:'',companyUrlList:companyObj.companyUrlist,parentCompanyVO:companyObj.parentCompanyVO,homogenityVO:companyObj.homogenityVO,sisterCompaniesVOList:companyObj.sisterCompaniesVOList,
      sectorId:companyObj.sectorId,
      subsectorId:companyObj.subsectorId,
      regionId:companyObj.regionId,
      countryId:companyObj.countryId,
      lei:companyObj.lei,
      isin:companyObj.isin,
      primaryEmail:companyObj.primaryEmail,
      secondaryEmail:companyObj.secondaryEmail}); 

    }

  delete(companyObj) {
    var confirmMessage = "You Want To Delete Company";
    this.addEditSuccessComponent.showConfirmationSwal(confirmMessage,'Yes','No').then((isConfirm) => {
      if (isConfirm.value) {
        this.configurationService. deleteCompany(companyObj.id).subscribe((data) => {
            var deleteSuccess = 'Deleted Company';
            this.addEditSuccessComponent.showSuccessSwal(deleteSuccess)
              .then((value) => {
                this.addCompanyComponent.resetCompanyForm();
              });
        },(error)=>{
          console.log(error);
        });
      }
    });
  }

addDetails(companyObj){
  this.router.navigate(['/addCompanyInfo/'+companyObj.id]);
  
}
}